import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AdminActions, FeedErrorUserActions } from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import { selectAdminUsersFilters } from '../../selectors';

@Injectable()
export class AdminEffects {
  getEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getPersons),
      switchMap(({ page, sort, filters }) =>
        this.apiService.getEmployees(page, sort, filters).pipe(
          map(({ users, totalCount }) =>
            AdminActions.getPersonsComplete({ employees: users, totalCount })
          ),
          catchError(errorHandler(AdminActions.getPersonsError))
        )
      )
    )
  );

  // effect temporarily commented out due to a backend issue that's yet to be resolved

  getFilteredEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getFilteredPersons),
      switchMap(({ page, sort, filters }) =>
        this.apiService.getEmployees(page, sort, filters).pipe(
          map(({ users, totalCount }) =>
            AdminActions.getFilteredPersonsComplete({
              employees: users,
              totalCount,
            })
          ),
          catchError(errorHandler(AdminActions.getFilteredPersonsError))
        )
      )
    )
  );

  getFeedUsersComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedErrorUserActions.getUsersComplete, AdminActions.resetFilters),
      withLatestFrom(this.store$.pipe(select(selectAdminUsersFilters))),
      switchMap(([, { page, sort, filters }]) =>
        this.apiService.getEmployees(page, sort, filters).pipe(
          map(({ users, totalCount }) =>
            AdminActions.getPersonsComplete({ employees: users, totalCount })
          ),
          catchError(errorHandler(AdminActions.getPersonsError))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<fromDashboard.State>
  ) {}
}
