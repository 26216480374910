<div
  class="base-modal"
  *ngIf="config$ | async as config"
  [ngClass]="{ closed: !isOpen }"
>
  <div
    class="base-modal__content"
    [class.detail-modal]="
      config.modalId === 'expense-modal' || config.modalId === 'approval-modal'
    "
    [class.activity-log]="activityTabOpen"
  >
    <div class="overlay" *ngIf="loading">
      <div class="spinner-container">
        <ems-base-icon class="spinner" iconName="loading"></ems-base-icon>
      </div>
    </div>
    <header class="base-modal__header" *ngIf="config.title">
      <div class="base-modal__header-content">
        <h1 class="base-modal__title">
          {{ config.title }}
          <br/> 
          <span class="base-modal__subtitle">
            {{ config.subtitle }}
          </span>
          <span class="base-modal__label {{ config.labelClass }}">
            {{ config.label }}
          </span>
          <ems-base-tooltip
            class="base-modal__tooltip"
            *ngIf="config.fieldsComplete"
            direction="bottom"
            [content]="config.statusText"
          >
            <ems-base-button
              label="fields complete"
              buttonType="tertiary"
              variant="brand"
              [showLabel]="false"
              size="m"
            >
              <ems-base-icon
                icon-prepend
                iconName="check-circle"
              ></ems-base-icon>
            </ems-base-button>
          </ems-base-tooltip>
        </h1>
        <ems-base-stack space="s">
          <p
            *ngIf="!config.submitter; else showSubmitter"
            class="base-modal__caption"
          >
            {{ config.caption }}
          </p>
          <ng-template #showSubmitter>
            <p
              *ngIf="config.submitter && !config.proxy; else showProxy"
              class="base-modal__caption"
            >
              Last updated {{ config.date }}
            </p>
          </ng-template>

          <ng-template #showProxy>
            <p class="base-modal__caption">
              Submitted by
              <ems-user-popup
                [firstName]="config.submitter.firstName"
                [lastName]="config.submitter.lastName"
                [id]="config.submitter.id"
                [email]="config.submitter.email"
                [phone]="config.submitter.phone"
                [proxy]="config.proxy"
                type="caption"
              ></ems-user-popup>
              on {{ config.date }}
            </p>
          </ng-template>
          <ems-base-caption *ngIf="config.statusText && !config.fieldsComplete">
            <ems-base-text-style
              *ngIf="config.status === 'positive'"
              variation="positive"
              >{{ config.statusText }}</ems-base-text-style
            >
            <ems-base-text-style *ngIf="config.status !== 'positive'">{{
              config.statusText
            }}</ems-base-text-style>
          </ems-base-caption>
          <ems-base-caption *ngIf="config.receiptNotRequiredText">
            <ems-base-text-style variation="positive">
              {{ config.receiptNotRequiredText }}
            </ems-base-text-style>
          </ems-base-caption>
        </ems-base-stack>
      </div>
      <div class="base-modal__actions" [class.detail-modal]="config.modalId === 'expense-modal' || config.modalId === 'approval-modal'">
        <ng-content select="[modal-actions]"></ng-content>
      </div>
    </header>

    <div
      class="base-modal__body"
      [class.detail-modal]="
        config.modalId === 'expense-modal' ||
        config.modalId === 'approval-modal'
      "
      #modal
    >
      <ng-content></ng-content>
    </div>
    <ems-base-button
      *ngIf="config.closeable"
      (buttonClick)="close()"
      label="Dismiss"
      buttonType="tertiary"
      [showLabel]="false"
      class="base-modal__dismiss"
      variant="dismiss"
    >
      <ems-base-icon icon-prepend iconName="times"></ems-base-icon>
    </ems-base-button>
  </div>
  <div
    class="base-modal-overlay"
    (click)="close()"
    [ngClass]="{ closed: !isOpen }"
  ></div>
  <div
    *ngIf="completeOverlay"
    (click)="close()"
    class="base-modal-complete-overlay"
  ></div>
</div>
