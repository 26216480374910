import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TransactionFeedItemActions } from '../actions';
import { errorHandler } from '../error-handler';
import { selectedTransactionFeedItem, State } from '../reducers';

@Injectable()
export class TransactionFeedItemEffects {
  get$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TransactionFeedItemActions.get),
      switchMap(({ page, sort, filters }) =>
        this.apiService.getTransactionsFeedItems(page, sort, filters).pipe(
          map(({ feeds, totalCount }) =>
            TransactionFeedItemActions.getComplete({
              results: feeds,
              totalCount,
            })
          ),
          catchError(errorHandler(TransactionFeedItemActions.getError))
        )
      )
    )
  );

  getFiltered$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TransactionFeedItemActions.getFiltered),
      switchMap(({ page, sort, filters }) =>
        this.apiService.getTransactionsFeedItems(page, sort, filters).pipe(
          map(({ feeds, totalCount }) =>
            TransactionFeedItemActions.getFilteredComplete({
              results: feeds,
              totalCount,
            })
          ),
          catchError(errorHandler(TransactionFeedItemActions.getFilteredError))
        )
      )
    )
  );

  getFeedItemDownloadUrl$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TransactionFeedItemActions.getFeedItemDownload),
      switchMap(({ id }) =>
        this.apiService.getTransactionFeedDownloadUrl(id).pipe(
          map(({ url }) =>
            TransactionFeedItemActions.getFeedItemDownloadComplete({
              url,
            })
          ),
          catchError(
            errorHandler(TransactionFeedItemActions.getFeedItemDownloadError)
          )
        )
      )
    )
  );

  downloadFeedItemFile$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(TransactionFeedItemActions.downloadFeedItemFile),
      switchMap(({ url }) =>
        this.apiService.getFeedItemFile(url).pipe(
          map((file: unknown) =>
            TransactionFeedItemActions.downloadFeedItemFileComplete({
              file,
            })
          ),
          catchError(
            errorHandler(TransactionFeedItemActions.downloadFeedItemFileError)
          )
        )
      )
    )
  );

  downloadFeedItemFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TransactionFeedItemActions.downloadFeedItemFileComplete),
        withLatestFrom(this.store$.pipe(select(selectedTransactionFeedItem))),
        map(([res, item]) => {
          const filename = `${item.fileName}`;
          return this.fileSaverService.save(<Blob>res.file.body, filename);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private fileSaverService: FileSaverService,
    private store$: Store<State>
  ) {}
}
