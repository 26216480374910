import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilsService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ConvertToDollarsPipe,
  CustomValidators,
} from '@ems-gui/shared/util-core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-expense-form-itemize',
  templateUrl: './expense-form-itemize.component.html',
  styleUrls: ['./expense-form-itemize.component.scss'],
  providers: [CurrencyPipe, ConvertToDollarsPipe],
})
export class ExpenseFormItemizeComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sumEl', { static: false }) sumEl: ElementRef;
  @Input() expense;
  @Input() confirmStatus;
  @Input() dismissing;
  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() rotateReceipt: EventEmitter<any> = new EventEmitter<any>();
  unsubscribe$: Subject<void> = new Subject<any>();

  private _items;
  currentItems;
  diff = 0;
  actualDiff = 0;
  overAmount = false;
  form: FormGroup = new FormGroup({
    amounts: this.fb.array([]),
    sum: new FormControl(null),
  });

  get items(): any[] {
    return this._items;
  }

  @Input('items')
  set items(data: any[]) {
    if (data && data.length) {
      const itemsControl = <FormArray>this.form.get('amounts');
      while (itemsControl && itemsControl.length !== 0) {
        itemsControl.removeAt(0);
      }

      data.forEach((datum) => {
        this.addItem(datum);
      });
      this._items = data;
    }
  }

  constructor(
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private convertToDollarsPipe: ConvertToDollarsPipe,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !this.form.pristine && !this.dismissing),
        tap((value) => {
          const updatedForm = { ...value };
          if (updatedForm.amounts && updatedForm.amounts.length) {
            updatedForm.amounts = this.itemsToCents(updatedForm.amounts);
            this.itemsSum(updatedForm.amounts);
          }
          this.formChange.emit(updatedForm);
        })
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.statusChange.emit(valid);
        })
      )
      .subscribe();

    this.statusChange.emit(this.form.status === 'VALID');
  }

  get amounts() {
    return <FormArray>this.form.get('amounts');
  }
  get sum() {
    return this.form.get('sum');
  }

  ngAfterViewInit() {
    if (this.amounts?.length && this.sumEl?.nativeElement) {
      const amounts = this.itemsToCents(
        this.amounts.controls.map((amount) => amount.value)
      );
      this.itemsSum(amounts);
      const updatedForm = this.form.value;
          if (updatedForm.amounts && updatedForm.amounts.length) {
            updatedForm.amounts = this.itemsToCents(updatedForm.amounts);
            this.itemsSum(updatedForm);
          }
      this.formChange.emit(this.form.value);
    }
  }

  itemsSum(items) {
    if (items?.length) {
      const updatedItems = items.map((item) => +item);
      const itemsSum = updatedItems.reduce((a, b) => a + b);
      const sumInDollars = this.convertToDollarsPipe.transform(itemsSum);
      this.sumEl.nativeElement.value =
        this.currencyPipe.transform(sumInDollars);
      const total = this.expense && +this.expense.amount;
      this.diff = total - itemsSum;
      this.checkDiff();
    } else {
      return null;
    }
  }

  itemsToCents(items) {
    const updatedItems = [];
    items.forEach((item) => {
      item = this.utilsService.dollarsToCents(item);
      updatedItems.push(`${item}`);
    });
    return updatedItems;
  }

  checkDiff() {
    if (this.diff > 0) {
      this.overAmount = false;
      this.actualDiff = this.diff;
      this.sum.setErrors({
        sumNotValid: true,
      });
    } else if (this.diff < 0) {
      this.overAmount = true;
      this.actualDiff = Math.abs(this.diff);
      this.sum.setErrors({
        sumNotValid: true,
      });
    } else if (this.diff === 0) {
      this.overAmount = false;
      this.actualDiff = 0;
      this.sum.setErrors(null);
    }
  }

  addItem(item) {
    const itemsControl = <FormArray>this.form.get('amounts');
    const updatedItem = this.currencyPipe.transform(
      this.convertToDollarsPipe.transform(item)
    );
    itemsControl.push(
      this.fb.control(`${updatedItem}`, {
        validators: CustomValidators.validCompanyAmount,
      })
    );
  }

  addNewItem() {
    const itemsControl = <FormArray>this.form.get('amounts');
    itemsControl.push(
      this.fb.control(`$0.00`, { validators: CustomValidators.validCompanyAmount })
    );
  }

  updateAmounts(value) {
    const updatedForm = { ...value };
    if (updatedForm.amounts && updatedForm.amounts.length) {
      updatedForm.amounts = this.itemsToCents(updatedForm.amounts).map(
        (item) => +item
      );
    }
    return updatedForm;
  }

  deleteItem(index) {
    const itemsControl = <FormArray>this.form.get('amounts');
    itemsControl.removeAt(index);
  }

  getItemControl(index) {
    return (<FormArray>this.amounts).at(index);
  }

  onRotateReceipt({ angle }) {
    this.rotateReceipt.emit({ id: this.expense.id, angle });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
