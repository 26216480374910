import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-card-section',
  templateUrl: './base-card-section.component.html',
  styleUrls: ['./base-card-section.component.scss'],
})
export class BaseCardSectionComponent implements OnInit {
  /**
   * Title of the card section
   */
  @Input() sectionTitle = '';

  /**
   * Subtitle of the card section
   */
  @Input() subtitle = '';

  /**
   * Removes space from top and bottom for a narrower section
   */
  @Input() squish = false;

  /**
   * Uses CSS Grid to display two columns
   */
  @Input() split = false;

  /**
   * Tints the background a shade
   *
   * Accepts `normal` or `muted`
   */
  @Input() background = 'normal';

  /**
   * Classnames
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'has-squish': this.squish,
      'is-split': this.split,
      'has-title': this.sectionTitle !== '',
      'has-bg-muted': this.background === 'muted',
    };
  }

  constructor() {}

  ngOnInit() {}
}
