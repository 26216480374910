import { User } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getUser = createAction('[Auth] getUser');

export const getUserComplete = createAction(
  '[Auth] getUserComplete',
  props<{ user: User }>()
);

export const logout = createAction('[Auth] logout');

export const logoutComplete = createAction('[Auth] logoutComplete');

export const getPermissionsComplete = createAction(
  '[Auth] getPermissionsComplete',
  props<{ permissions: any }>()
);

export const getPermissionsError = createAction(
  '[Auth] getPermissionsError',
  props<{ error: string }>()
);
