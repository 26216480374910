<ems-base-modal
  id="ems-approver-fraud-warn-modal"
  [config]="modalConfig"
  dismiss="onCancel()"
  continue="onContinue()"
  class="dialog-modal"
>
  <ems-expense-fraud-approver-warn-dialog
    (continue)="onContinue()"
    (cancel)="onCancel()"
  ></ems-expense-fraud-approver-warn-dialog>
</ems-base-modal>
