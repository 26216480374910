import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UtilsService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ConvertToDollarsPipe,
  Expense,
  OnChange
} from '@ems-gui/shared/util-core';
import { ExpenseService } from "../../../../app/services/expense.service";
import {
  environment
} from '@ems-gui/expense/util-web-infrastructure';
import { Store } from '@ngrx/store';
import { State } from '@ems-gui/expense/util-web-ngrx';


@Component({
  selector: 'ems-expense-modal',
  templateUrl: './expense-modal.component.html',
  styleUrls: ['./expense-modal.component.scss'],
  providers: [CurrencyPipe, ConvertToDollarsPipe],
})
export class ExpenseModalComponent {
  url = `none`;
  method = 'put';
  @OnChange(function (this: ExpenseModalComponent, expense) {
    this.url = `${environment.apiURL}/expenses/${+expense.id}/receipt/no-ocr`;
  })

  @Input() expense;
  @Input() isDisabled;
  @Input() isApproval;
  @Input() canUploadNew;
  @Output() rotateReceipt = new EventEmitter<any>();
  @Output() tabSelected = new EventEmitter();
  @Output() receiptUploaded = new EventEmitter<any>();
  @Output() fileSizeError = new EventEmitter<any>();
  @Output() removeReceipt: EventEmitter<any> = new EventEmitter<any>();

  smallMaxScreenWidth = 767;
  screenHeight: number;
  screenWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth;
  }

  isMobileView() {
    return this.screenWidth < this.smallMaxScreenWidth;
  }

  constructor(
    private utilsService: UtilsService,
    private currencyPipe: CurrencyPipe,
    private convertToDollarsPipe: ConvertToDollarsPipe,
    private expenseService: ExpenseService,
    private store$: Store<State>
  ) {
    this.onResize();
  }

  /**
   * REFACTOR NEEDED:
   * WHY: formatDate can be called once or not withinn string interpolation
   */
  getTimestamp(date: number) {
    const beginningTimeToday = new Date(
      formatDate(new Date(), 'mediumDate', 'en-US')
    ).getTime();
    const endingTimeToday = beginningTimeToday + 86399999;
    const beginningTimeYesterday = beginningTimeToday - 86400000;
    const endingTimeYesterday = beginningTimeToday - 1;

    if (date >= beginningTimeToday && date <= endingTimeToday) {
      return `Today at ${formatDate(date, 'shortTime', 'en-US')}`;
    } else if (date >= beginningTimeYesterday && date <= endingTimeYesterday) {
      return `Yesterday at ${formatDate(date, 'shortTime', 'en-US')}`;
    } else {
      return `${formatDate(date, 'MM/dd/yyyy', 'en-US')} ${formatDate(
        date,
        'shortTime',
        'en-US'
      )}`;
    }
  }

  onReceiptUploaded(expense) {
    this.receiptUploaded.emit(expense);
  }

  onRotateReceipt({ angle }) {
    this.rotateReceipt.emit({ id: this.expense.id, angle });
  }

  getPaymentType(data) {
    if (data) {
      this.expenseService.getPaymentType(data)
    }
  }

  getFormattedDate(date) {
    return this.utilsService.formatDate(date);
  }

  getFormattedNote(item) {
    if (item.action === 'Reimbursed Expense') {
      return `${this.currencyPipe.transform(
        this.convertToDollarsPipe.transform(this.expense?.amount)
      )} has been reimbursed to ${item.author.author}.`;
    } else if (item.action !== 'Reimbursed Expense' && item.message) {
      return item.message;
    } else {
      return '';
    }
  }

  onTabSelected(tab) {
    this.tabSelected.emit(tab);
  }

}
