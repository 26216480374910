<div
  class="input-div"
  [hidden]="hasReceipt"
>
  <ems-base-icon
    iconName="upload-receipt"
    class="dropzone__icon"
  ></ems-base-icon>
  <p class="text">Click to Upload Receipt</p>
  <p class="sub-text">(or drag and drop here)</p>
  <input type="file" class="file" accept="image/png, image/jpeg, image/jpg, application/pdf">
</div>

<form id="receipt-form"
  [hidden]="!hasReceipt || !imageLoaded" (click)="onImageClick()"
  [ngClass]="{'image-clickable' : imageUrl }"
>
  <div class="pdf-container" (mouseover)="zoomIn()" (mouseout)="zoomOut()" (mousemove)="onMouseMove($event)"> 
    <canvas [hidden]="pdfSrcPresent" id="canvas"></canvas>
    <pdf-viewer 
      [hidden]="!pdfSrcPresent"
      [src]="pdfSrc"
      [rotation]="degrees"
      [original-size]="false"
      [show-all]="false"
      [autoresize]="true"
      [style]="pdfStyles"
    >
    </pdf-viewer>
  </div>

</form>
<div class="button-container">
  <button type="button" (click)="rotateImage()">
    <ems-base-icon iconName="replay" class="icon"></ems-base-icon>
    Rotate
  </button>
  <button type="button" (click)="deleteQueuedImage()">
    <ems-base-icon iconName="remove" class="icon"></ems-base-icon>
    Remove
  </button>
</div>

<div class="spinner-container" [hidden]="!hasReceipt || imageLoaded">
  <ems-base-icon class="spinner" iconName="loading"></ems-base-icon>
</div>
