import { ExpenseFilter } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const setFilters = createAction(
  '[Expense - Filters] set',
  props<{
    name: string;
    filters: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
      selectedPaymentIndex: any;
      selectedStatusIndex: any;
      startDate: any;
      endDate: any;
    };
  }>()
);

export const setFilterType = createAction(
  '[Expense - Filters] set type',
  props<{
    filterType: string;
  }>()
);

export const setExpenseTypeId = createAction(
  '[Expense - Filters] set expense type id',
  props<{
    id: any;
    filterType: string;
  }>()
);

export const setJobCodeId = createAction(
  '[Expense - Filters] set jobCode id',
  props<{
    id: any;
    filterType: string;
  }>()
);

export const setFilterCount = createAction(
  '[Expense - Filters] set count',
  props<{
    name: string;
    count: number;
  }>()
);

export const clearFilters = createAction(
  '[Expense - Filters] clear',
  props<{
    name: string;
  }>()
);
