import { FeedErrorUser } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getUsers = createAction('[Feed Error User] getUsers');

export const getUsersComplete = createAction(
  '[Feed Error User] getUsersComplete',
  props<{ users: FeedErrorUser[] }>()
);

export const getUsersError = createAction(
  '[Feed Error User] getUsersError',
  props<{ error: any }>()
);

export const addUser = createAction(
  '[Feed Error User] addUser',
  props<{ userIds: number[] }>()
);

export const addUserComplete = createAction(
  '[Feed Error User] addUserComplete',
  props<{ user: FeedErrorUser }>()
);

export const addUserError = createAction(
  '[Feed Error User] addUserError',
  props<{ error: any }>()
);

export const deleteUser = createAction(
  '[Feed Error User] deleteUser',
  props<{ id: number }>()
);

export const deleteUserComplete = createAction(
  '[Feed Error User] deleteUserComplete',
  props<{ user: FeedErrorUser }>()
);

export const deleteUserError = createAction(
  '[Feed Error User] deleteUserError',
  props<{ error: any }>()
);

export const reset = createAction('[Feed Error User] reset');
