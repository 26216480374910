import { createReducer, on } from '@ngrx/store';
import { NotificationSettingActions } from '../actions';

export interface State {
  id: number;
  createdAt: number;
  updatedAt: number;
  newCreditCardExpense: boolean;
  creditCardExpenseOverdue: boolean;
  creditCardExpenseNoMatch: boolean;
  expenseApprovedOrRejected: boolean;
  newExpenseForReview: boolean;
  expenseOverdueForReview: boolean;
  user: number;
}

export const initialState: State = {
  id: null,
  createdAt: null,
  updatedAt: null,
  newCreditCardExpense: false,
  creditCardExpenseOverdue: false,
  creditCardExpenseNoMatch: false,
  expenseApprovedOrRejected: false,
  newExpenseForReview: false,
  expenseOverdueForReview: false,
  user: null,
};

export const reducer = createReducer(
  initialState,
  on(
    NotificationSettingActions.saveOneNotificationSetting,
    NotificationSettingActions.getNotificationSetting,
    (state) => ({
      ...state,
    })
  ),
  on(
    NotificationSettingActions.saveOneNotificationSettingError,
    NotificationSettingActions.getNotificationSettingError,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    NotificationSettingActions.getNotificationSettingComplete,
    (state, { notificationSetting }) => notificationSetting
  ),
  on(
    NotificationSettingActions.saveOneNotificationSettingComplete,
    (state, { notificationSetting }) => notificationSetting
  )
);
