<button
  *ngIf="options.closeButton"
  class="toast-close-button"
  aria-label="Close"
>
  <span aria-hidden="true" class="ico icon-Asset-46"></span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }}
</div>
<div
  *ngIf="message && options.enableHtml"
  role="alert"
  aria-live="polite"
  [class]="options.messageClass"
  [innerHTML]="message"
></div>
<div
  *ngIf="message && !options.enableHtml"
  role="alert"
  aria-live="polite"
  [class]="options.messageClass"
  [attr.aria-label]="message"
>
  {{ message }}
</div>
