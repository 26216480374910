import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationSettingActions } from '../../actions';
import { errorHandler } from '../../error-handler';

@Injectable()
export class NotificationSettingEffects {
  getNotificationSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationSettingActions.getNotificationSetting),
      switchMap(() =>
        this.apiService.getNotificationSetting().pipe(
          map((notificationSetting) =>
            NotificationSettingActions.getNotificationSettingComplete({
              notificationSetting,
            })
          ),
          catchError(
            errorHandler(NotificationSettingActions.getNotificationSettingError)
          )
        )
      )
    )
  );
  updateNotificationSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationSettingActions.saveOneNotificationSetting),
      switchMap((body) =>
        this.apiService.putNotificationSetting({ body }).pipe(
          map((notificationSetting) =>
            NotificationSettingActions.saveOneNotificationSettingComplete({
              notificationSetting,
            })
          ),
          catchError(
            errorHandler(
              NotificationSettingActions.saveOneNotificationSettingError
            )
          )
        )
      )
    )
  );

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
