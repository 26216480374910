<ems-base-modal [config]="modalInput$ | async" (dismiss)="onDismiss($event)">
  <ems-base-button-group modal-actions class="new-expense-actions">
    <ng-container *ngIf="(modalType | async) === 'expense-new-modal'">
      <ems-base-button
        label="Save & Close"
        buttonType="primary"
        variant="brand"
        (click)="onSaveAndClose($event)"
        [state]="saveAndCloseButtonDisabled.size === 2 ? '' : 'disabled'"
        id="new-expense-save-and-close"
      >
      </ems-base-button>
      <ems-base-button
        label="Submit"
        buttonType="primary"
        variant="success"
        (click)="onSubmit()"
        class="new-expense-submit"
      ></ems-base-button>
    </ng-container>
    <ng-container
      *ngIf="(modalType | async) === 'expense-new-modal-company-cc'"
    >
      <ems-base-button
        label="Ready To Submit"
        buttonType="primary"
        variant="success"
        (click)="saveCompanyCreditCardExpense()"
        class="new-expense-submit"
      ></ems-base-button>
    </ng-container>
    <ems-base-dropdown
      *ngIf="autoSavedExpenseId$ | async"
      dropdownLabel="Actions"
      class="new-expense-dropdown"
    >
      <a class="base-dropdown__item" (click)="onItemizeExpense()">Itemize</a>
      <a class="base-dropdown__item" (click)="onTrashAutoSaved()">Trash</a>
    </ems-base-dropdown>
  </ems-base-button-group>
  <ems-expense-form-new
    [formId]="formId"
    [autoSavedId]="autoSavedExpenseId$ | async"
    [dismiss]="dismiss$ | async"
    [jobCodes]="jobCodes$ | async"
    [favoriteJobCodes]="favoriteJobCodes$ | async"
    [salesforceCases]="salesforceCases$ | async"
    [favoriteSalesforceCases]="favoriteSalesforceCases$ | async"
    [itemizationStatus]="itemizationStatus$ | async"
    [submitted]="submitted$ | async"
    [proxy]="proxy$ | async"
    (formChange)="onNewFormChange($event)"
    (statusChange)="onNewStatusChange($event)"
    (amountStatusChange)="onAmountStatusChange()"
    (favoriteJobCode)="onFavoriteJobCode($event)"
    (favoriteSalesforce)="onFavoriteSalesforceCase($event)"
    (receiptData)="onReceiptData($event)"
    (fileSizeError)="onFileUploadSizeError()"
    (fraudulentExpense)="onFraudulentExpense($event)"
  ></ems-expense-form-new>
</ems-base-modal>
