import { Injectable } from "@angular/core";
import { ApiService, BaseModalService } from "@ems-gui/expense/util-web-infrastructure";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SubmittedActions,LayoutActions, ActivityActions } from "../../actions";
import { switchMap, map, catchError, of, tap, withLatestFrom, mergeMap } from "rxjs";
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  MileageDraftActions,
  DraftActions,
  selectMileageExpenseType,
  State
} from '@ems-gui/expense/util-web-ngrx';
import { HttpErrorResponse } from '@angular/common/http';
import { errorHandler } from '../../error-handler';


@Injectable()
export class ExpenseSubmittedEffects {
  onRecallExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmittedActions.recallExpense),
      switchMap(({ expenseId }) =>
        this.apiService.recallExpense(expenseId).pipe(
          map((response) => SubmittedActions.recallExpenseComplete({ expense: response.expense })),
        catchError((error) => of(SubmittedActions.recallExpenseError({ error })))
        )
      )
    )
  );

  recallExpenseComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmittedActions.recallExpenseComplete),
      withLatestFrom(this.store$.pipe(select(selectMileageExpenseType))),
      mergeMap(([actionResult, mileageExpenseType]) => {
        const { expense } = actionResult; 
        this.modalService.close('recall-expense-alert');
        this.router.navigateByUrl('/expenses/unsubmitted');
        const id = expense?.id;
        const action =
          expense.type === mileageExpenseType.id
            ? MileageDraftActions.editDraft({ id })
            : DraftActions.editDraft({ id });

        this.store$.dispatch(action);
        return of(null);
      })),
      { dispatch: false }
  );

  recallExpenseError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmittedActions.recallExpenseError),
      withLatestFrom(this.store$.pipe(select(selectMileageExpenseType))),
      mergeMap(([actionResult, mileageExpenseType]) => {
        const { error } = actionResult; 
        let errorMessage = 'This expense status was changed to ';
        const id = +error.error.expenseId;
        if (error instanceof HttpErrorResponse) {
          const errorObj = error.error;
          errorMessage = errorMessage + `${errorObj.expenseStatus} by `
          + `${errorObj.author} at ${errorObj.lastModified}` +
          `Please reach out to them if changes need to be made.`;
        }
        this.modalService.close('recall-expense-alert');
        this.store$.dispatch(
          LayoutActions.globalToastErrorMessage({message: errorMessage})
        )

        // Open expense edit modal if the expense was rejected
        if(error.error.expenseStatus === 'Resubmitted') {
          const action =
            error.error.expenseType === mileageExpenseType.id
              ? MileageDraftActions.editDraft({ id })
              : DraftActions.editDraft({ id });

          this.store$.dispatch(action);
        }
        // Open expense details modal if the status is approved
        else if(error.error.expenseStatus === "Approved") {
          this.store$.dispatch(
            LayoutActions.openExpenseModal({
              id: id,
              name: 'expense-modal',
            })
          );
      
          this.store$.dispatch(ActivityActions.getSubmittedActivity({ id }));
        }
        // Open expense Trash modal if the status is
        else if(error.error.expenseStatus === 'Hide') {
          const type = error.error.expenseType;
          const modal = error.expenseType === mileageExpenseType.id
            ? 'trash-mileage-detail' : 'trash-detail';
          this.store$.dispatch(
            LayoutActions.openExpenseModal({
              id,
              name: modal,
            })
          );

          this.store$.dispatch(ActivityActions.getTrashedActivity({ id }));
        }
        return of(null);
      }),
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private router: Router,
    private modalService: BaseModalService,
    private store$: Store<State>
  ) {}
}
