import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DraftActions, JobCodeActions, NewActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
  filter: string;
  selectedId: string | number;
}

export const sortByJobCode = (a, b) => {
  return a.jobCode - b.jobCode;
};

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  sortComparer: sortByJobCode,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  filter: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(JobCodeActions.get, (state) => ({
    ...state,
    loading: true,
  })),
  on(JobCodeActions.getComplete, (state, { jobCode }) =>
    adapter.setAll(jobCode, {
      ...state,
      loading: false,
    })
  ),
  on(JobCodeActions.getError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(JobCodeActions.select, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(JobCodeActions.filter, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(JobCodeActions.resetJobCode, (state) => ({
    ...state,
    selectedId: '',
  })),
  on(DraftActions.deselectOneDraft, (state) => ({
    ...state,
    selectedId: '',
  })),
  on(
    // Note: Added to fix bug when resetting job code on New Expense
    // May need to add the other cases where job code reset is needed on save, cancel, etc.
    // for both New Expense and Edit draft
    NewActions.submitComplete,
    (state) => ({
      ...state,
      selectedId: '',
    })
  )
);

export const getSelectedJobCodeId = (state: State) => {
  return state.selectedId;
};

export const getJobCodesLoading = (state: State) => {
  return state.loading;
};
