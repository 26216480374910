import { Expense, ExpenseType } from "../models";

export function isFraudulentExpenseValidForSubmission(expense) {
    if (!expense.invalid) return true;
    if (expense.invalid?.length <= 2) {
      if (
        (expense.invalid?.length === 2 &&
          !(expense.invalid.includes('hasReceipt') && 
          expense.invalid.includes('isBillable'))
        ) ||
        (expense.invalid?.length === 1 && !(expense.invalid.includes('hasReceipt') ||  
        expense.invalid.includes('isBillable')))
        ) {
        return false;
      }
      else if(expense.invalid?.length === 1 && expense.invalid.includes('hasReceipt')) {
        return true;
      }
      else {
        return expense.transactionDate !== null && expense.fraudulent;
      }
    } else {
      return false;
    }
}
