import packageJson from '@libs/../package.json'
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { sentryInit } from './sentry'
import { SentryErrorHandler } from '@sentry/angular-ivy';
import { Environment } from '@libs/interfaces';
sentryInit({ ...window.env, version: packageJson.version });

if (window.env.production) enableProdMode()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => new SentryErrorHandler(error));

