/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import {
  BaseModalService,
  UtilsService,
} from '@ems-gui/expense/util-web-infrastructure';
import { ExpenseService } from '@src/app/services/expense.service';
import {
  FavoriteJobCodeActions,
  FavoritePersonActions,
  FavoriteSalesforceCaseActions,
  getUser,
  LayoutActions,
  NotificationSettingActions,
  openModalExpenseWithActivityAndSalesforce,
  ProxyUserActions,
  selectAllProxiedUsersFlat,
  selectedProxyId,
  selectedProxyUser,
  selectHomeLoaded,
  selectOpenModalName,
  selectProxyUserIsAdmin,
  selectProxyUserIsProxyAll,
  selectUserHasProxyAllPermission,
  selectUserIsAdmin,
  selectUserHasReceiptManagerPermission,
  selectUserHasSpenseSettingsPermission,
  selectUserHasSpenseUsersPermission,
  selectUserHasSpenseLoginAsPermission,
  selectProxyUserHasReceiptManagerPermission,
  selectProxyUserHasSpenseSettingsPermission,
  selectProxyUserHasSpenseUsersPermission,
  State,
  UserActions,
  WebSubmitterFacade,
  selectCurrentUser,
  isLoggedInAsAnApprover,
  selectFromCustomCaseApi,
  SalesforceCaseActions
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ModalInput,
  SalesforceCase,
  User
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import {
  merge,
  Observable,
  ReplaySubject,
  Subject,
  withLatestFrom
} from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Submitter } from "@libs/shared/util-core/src/lib/models/expense.model";
import {
  FormManagerService
} from '@src/app/services/form-manager.service';
import { AlertService } from '@src/app/services/alert.service';

type SelectedExpense = Partial<Expense> & {
  salesforceId: Partial<SalesforceCase>
};

@Component({
  selector: 'ems-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [],
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  public navButtonIds = {
    dashboard: 'nav-dashboard-link',
    myExpenses: 'nav-my-expenses-link',
    unsubmitted: 'nav-unsubmitted-link',
    submitted: 'nav-submitted-link',
    trash: 'nav-trash-link',
    approvals: 'nav-approvals-link',
    awaitingReview: 'nav-awaiting-review-link',
    reviewed: 'nav-reviewed-link',
    settings: 'nav-settings-link',
    admin: 'nav-admin-link',
    users: 'nav-users-link',
    systemSettings: 'nav-system-settings-link',
    receiptManager: 'nav-receipt-manager-link',

  }

  unsubscribe$: Subject<void> = new Subject();
  user$: Observable<User> = this.store$.pipe(select(getUser));
  currentUser;
  private firstBreadcrumbSubject = new ReplaySubject();
  firstBreadcrumbs$ = this.firstBreadcrumbSubject.asObservable();
  allOtherBreadcrumbs$: Observable<string[]> = this.router.events.pipe(
    filter((event) => event instanceof RoutesRecognized),
    map((event: RoutesRecognized) => {
      return this.getBreadcrumbs(event.state.root);
    })
  );
  breadcrumbs$ = merge(this.firstBreadcrumbs$, this.allOtherBreadcrumbs$);
  openExpense$: Observable<Partial<Expense>> = this.store$.pipe(
    select(openModalExpenseWithActivityAndSalesforce)
  );
  modalConfig$: Observable<ModalInput> = this.openExpense$.pipe(
    map((expense) => {
      const subtitle = `ID ${expense.id}`;
      // TODO: Make this work with @Inject(LOCALE_ID)
      const submitter = this.buildSubmitter(expense.submitter);
      const submissionDate = expense && expense.submissionDate;
      const date = submissionDate
        ? formatDate(submissionDate, 'longDate', 'en-US', 'UTC')
        : '';

      let configInfo: any = {
        modalId: 'expense-modal',
        title: this.expenseService.getExpenseModalTitle(expense.paymentType),
        subtitle,
        submitter,
        date,
      };
      configInfo = addProxyInfo(expense, configInfo);
      return configInfo;
    })
  );
  isApprover$: Observable<boolean> = this.store$.select(isLoggedInAsAnApprover);

  proxyUser$: Observable<any> = this.store$.pipe(select(selectedProxyUser));
  getUsersForProxy$: Observable<any> = this.store$.pipe(
    select(selectAllProxiedUsersFlat)
  );
  // logged in user's details
  isAdmin$: Observable<boolean> = this.store$.pipe(select(selectUserIsAdmin));
  hasProxyAll$: Observable<any> = this.store$.pipe(
    select(selectUserHasProxyAllPermission)
  );
  hasReceiptMgr$: Observable<any> = this.store$.pipe(
    select(selectUserHasReceiptManagerPermission)
  );
  hasUsers$: Observable<any> = this.store$.pipe(
    select(selectUserHasSpenseUsersPermission)
  );
  hasSettings$: Observable<any> = this.store$.pipe(
    select(selectUserHasSpenseSettingsPermission)
  );
  hasLoginAs$: Observable<any> = this.store$.pipe(
    select(selectUserHasSpenseLoginAsPermission)
  );
  // Proxy user's details
  proxyUserIsAdmin$: Observable<any> = this.store$.pipe(
    select(selectProxyUserIsAdmin)
  );
  proxyUserIsProxyAll$: Observable<any> = this.store$.pipe(
    select(selectProxyUserIsProxyAll)
  );
  proxyUserHasUsers$: Observable<any> = this.store$.pipe(
    select(selectProxyUserHasSpenseUsersPermission)
  );
  proxyUserHasReceiptMgr$: Observable<any> = this.store$.pipe(
    select(selectProxyUserHasReceiptManagerPermission)
  );
  proxyUseHasSettings$: Observable<any> = this.store$.pipe(
    select(selectProxyUserHasSpenseSettingsPermission)
  );
  proxyId$: Observable<any> = this.store$.pipe(select(selectedProxyId));
  // end proxy user details
  openModalName$: Observable<string> = this.store$.pipe(
    select(selectOpenModalName)
  );
  openModalId$: Observable<any> = this.modalService.openModalId$;
  actionsDropIsActive = false;
  dropdownButtonVariant = '';
  currentNavDropdown = '';
  activeParentRoute = '';
  loadingModalConfig: ModalInput = {
    title: 'Scanning Data',
    modalId: 'loading-modal',
    closeable: false,
  };
  loaded$ = this.store$.pipe(select(selectHomeLoaded));
  tmpScrollY;
  modals = [];
  activityTabOpen = false;
  public isMyExpenses;
  public isApprovals;
  emptyList = false;
  displayNav = false;
  proxyMenuOpened = false;

  shouldShowRecallButton = this.openExpense$.pipe(
    map((expense) => {
      if (expense && expense.status) {

        const isDisplayedUserTheExpenseCreditCardHolder = expense.cardholderContactId === this.currentUser.contactId;

        const isStatusRecallable = expense.status === "pending" ||
          expense.status === "Submitted" ||
          expense.status === "In Progress (Backstage)";

        return isStatusRecallable && isDisplayedUserTheExpenseCreditCardHolder;
      }
      return false;
    })
  );

  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    public submitterState: WebSubmitterFacade,
    private ref: ChangeDetectorRef,
    public utilsService: UtilsService,
    private expenseService: ExpenseService,
    private readonly fm: FormManagerService,
    private readonly alerts: AlertService
  ) {
    const breadcrumbs = this.getBreadcrumbs(this.route.snapshot);
    this.firstBreadcrumbSubject.next(breadcrumbs);
    // TODO: Figure out how to get dropdown to set on page refresh
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((event: Event) => {
          this.displayNav = false;
          this.isMyExpenses =
            this.router.url === '/expenses/unsubmitted' ||
            this.router.url === '/expenses/submitted' ||
            this.router.url === '/expenses/trash';
          const isAdmin =
            this.router.url === '/admin/users' ||
            this.router.url === '/admin/system-settings' ||
            this.router.url === '/admin/transaction-history' ||
            this.router.url === '/admin/receipt-manager';
          this.isApprovals =
            this.router.url === '/approvals/awaiting' ||
            this.router.url === '/approvals/reviewed';
          if (event instanceof NavigationEnd) {
            if (this.isMyExpenses) {
              this.setNavDropdown('my-expenses');
              this.setActiveParentRoute('my-expenses');
            } else if (isAdmin) {
              this.setNavDropdown('admin');
              this.setActiveParentRoute('admin');
            } else if (this.isApprovals) {
              this.setNavDropdown('approvals');
              this.setActiveParentRoute('approvals');
            } else {
              this.setNavDropdown('');
              this.setActiveParentRoute('');
            }
          }
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.store$.dispatch(UserActions.getUser());
    this.store$.dispatch(FavoritePersonActions.getFavoritePerson());
    this.store$.dispatch(
      FavoriteSalesforceCaseActions.getFavoriteSalesforceCase()
    );
    this.store$.dispatch(NotificationSettingActions.getNotificationSetting());
    this.store$.dispatch(FavoriteJobCodeActions.getFavorite());
    this.store$.dispatch(LayoutActions.homeLoad());

    this.currentNavDropdown = '';

    this.utilsService.listState
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((state: boolean) => {
          this.emptyList = state;
          this.ref.detectChanges();
        })
      )
      .subscribe();
    this.store$.pipe(
      select(selectCurrentUser),
      takeUntil(this.unsubscribe$),
      tap((user) => {
        this.currentUser = user;
      })
    ).subscribe();
    this.openExpense$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.store$.select(selectFromCustomCaseApi)),
      map(([receivedExpense, salesforceId]) => {
        const expense = <SelectedExpense>receivedExpense;
        if(
          'id' in expense.salesforceId &&
          expense.salesforceId.id &&
          expense.salesforceId.case_number === undefined
        ) {
          if(!salesforceId) {
            const action = SalesforceCaseActions.getCustomCaseByExternalId({
              externalId: expense.salesforceId.id
            });
            this.store$.dispatch(action);
          } else {
            Object.assign(expense, { salesforceId });
          }
        }

        return expense;
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    merge(this.openModalId$, this.openModalName$)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((name) => {
          if (name) {
            this.modals.push(name);
          } else {
            this.modals = [];
          }

          if (this.modals.length === 1) {
            this.tmpScrollY = window.scrollY;
            document.querySelector('html').style.scrollBehavior = 'unset';
            document.body.scrollTo(0, window.scrollY);
            document.body.style.top = `-${this.tmpScrollY}px`;
            document.body.style.overflowY = 'hidden';
          } else {
            document.body.style.top = '';
            window.scrollTo(0, this.tmpScrollY);
            document.querySelector('html').style.scrollBehavior = 'smooth';
            document.body.style.overflowY = 'auto';
          }
        })
      )
      .subscribe();

  }

  toggleActionsDrop() {
    this.actionsDropIsActive = !this.actionsDropIsActive;
    if (this.actionsDropIsActive) {
      this.dropdownButtonVariant = 'brand';
    } else {
      this.dropdownButtonVariant = '';
    }
  }

  onRemoveProxy() {
    this.proxyMenuOpened = false;
    this.onToggleNav();
    this.store$.dispatch(ProxyUserActions.removeProxy());
  }

  onChooseProxy(id) {
    if (id) {
      this.store$.dispatch(ProxyUserActions.setProxy({ id }));
    }
  }

  onLogout() {
    this.store$.dispatch(UserActions.logout());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setNavDropdown(nav) {
    this.currentNavDropdown = nav;
  }

  setActiveParentRoute(route) {
    this.activeParentRoute = route;
  }

  toggleNavDropdown(nav) {
    if (this.currentNavDropdown === nav) {
      this.setNavDropdown('');
    } else {
      this.setNavDropdown(nav);
    }
  }

  protected buildSubmitter(submitter?: number | Submitter) {
    const isSubmitter = (sub?: number | Submitter): sub is Submitter => {
      return sub && typeof sub !== 'number';
    }
    if(!isSubmitter(submitter)) return submitter;
    return {
      firstName: submitter.firstName,
      lastName: submitter.lastName,
      id: submitter.primaryInfo.id,
      email: submitter.primaryInfo.workEmail,
      phone: submitter.primaryInfo.phoneNumber
    }
  }

  /**
   * getBreadcrumbs
   * Extracts the breadcrumbs from the data passed in from a route
   */
  getBreadcrumbs(route: ActivatedRouteSnapshot) {
    const topLevel = route.firstChild?.firstChild?.data.breadcrumbs;
    const secondLevel =
      route.firstChild?.firstChild?.firstChild?.data.breadcrumbs;
    if (topLevel) {
      return topLevel;
    }

    return secondLevel;
  }

  closeModal() {
    if (this.modals.length && this.modals[0] !== 'loading-modal') {
      if(this.fm.get(this.modals[0]).isDirty) {
        const fm = this.fm.get(this.modals[0]);
        this.alerts.getUnsavedChangesAlert(
          this.modals[0],
          fm['saveAndClose']
        )
      } else {
        this.modalService.close(this.modals[0]);
      }
    }
  }

  onTabSelected(tab: string) {
    if (tab === 'Activity Log') {
      this.activityTabOpen = true;
    } else {
      this.activityTabOpen = false;
    }
  }

  onToggleNav() {
    this.displayNav = !this.displayNav;
    this.proxyMenuOpened = false;
  }

  onOpenProxyMenu(status) {
    this.proxyMenuOpened = status;
  }

  onProxySet(id) {
    this.proxyMenuOpened = false;
    this.onToggleNav();
    this.store$.dispatch(ProxyUserActions.setProxy({ id }));
  }


  onRecall() {
    this.modalService.open('recall-expense-alert');
  }
}

const addProxyInfo = (expense, config) => {
  if (
    expense?.activity?.length !== 0 &&
    expense?.activity[0]?.action.search('Submitted to') !== -1 &&
    expense?.submitter?.lastName
  ) {
    const hasVia = expense?.activity[0].author?.author.search('via');
    if (hasVia !== -1) {
      const viaProxy = expense?.activity[0].author?.author.split('via');
      config.proxy = `via ${viaProxy[1]}`;
    }
  }
  return config;
};
