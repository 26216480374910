import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  OnChange,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  isFraudulentExpenseValidForSubmission,
} from '@ems-gui/shared/util-core';
import { BehaviorSubject, firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { Store } from '@ngrx/store';
import { AllModalActions, LayoutActions, selectUserHasCreditCard, State } from "@ems-gui/expense/util-web-ngrx";
@Component({
  selector: 'ems-expenses-list',
  templateUrl: './expenses-list.component.html',
  styleUrls: ['./expenses-list.component.scss'],
})
export class ExpensesListComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {}
  private unsubscribe$ = new Subject<void>();
  private allCheckedSubject: BehaviorSubject<any> = new BehaviorSubject(false);
  public allChecked$ = this.allCheckedSubject.asObservable();
  private allIndeterminateSubject: BehaviorSubject<any> = new BehaviorSubject(
    false
  );
  public allIndeteriminate$ = this.allIndeterminateSubject.asObservable();
  @OnChange(function (this: ExpensesListComponent, expenses) {
    this.dataSource = new MatTableDataSource<any>(expenses);
    /**
     * wrap the selection clear in setTimeout so that the resulting
     * rowSelection.emit happens in a new event loop
     */
    setTimeout(() => {
      this.selection.clear();
    });
  })
  @Input()
  expenses;
  @Input() totalCount;
  @Input() showInvalid = true;
  @Input() loading = false;
  @Input() emptyListText = '';
  @Output() rowSelected: EventEmitter<any> = new EventEmitter();
  @Output() openModal: EventEmitter<any> = new EventEmitter();
  @Output() viewDetails: EventEmitter<any> = new EventEmitter();
  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Output() paginationControls: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'checkbox',
    'transactionDate',
    'vendor',
    'amount',
    'image',
    'type',
    'description',
    'jobCode',
    'customCase',
    'invalid',
    'status',
  ];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  title = 'SIS EMS';
  rowHeight = 60;
  pageSize = PAGE_SIZE;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  defaultSortParams = { colId: 'transactionDate', sort: 'desc' };
  pageSubject$: BehaviorSubject<any> = new BehaviorSubject({});
  pageUpdate$: Observable<any> = this.pageSubject$.asObservable().pipe(share());
  closeFabMenu = false;
  ccUser$ = this.store$.select(selectUserHasCreditCard);

  statusColor = {
    draft: 'muted',
    pending: 'warning',
    rejected: 'negative',
    approved: 'positive',
    reimbursed: 'positive',
  };

  get isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numRows === numSelected && !!numSelected;
  }

  public async onOpenModal(modalId: string) {
    this.store$.dispatch(
      AllModalActions.setCurrentModal({ currentModal: modalId })
    );
    const data = {
      name: modalId,
      continueTo: undefined
    };

    const isNewExpenseModal = [
      'expense-new-modal',
      'file-upload'
    ].includes(data.name);
    if(isNewExpenseModal && await firstValueFrom(this.ccUser$)) {
      Object.assign(data, {
        name: 'credit-card-holder-alert',
        continueTo: data.name
      });
    }

    if (data.continueTo)
      this.store$.dispatch(AllModalActions.setContinueTo({ continueTo: data.continueTo }));

    this.store$.dispatch(LayoutActions.openExpenseModal({ name: data.name }));
  }

  onUpload(image) {
    if (image) {
      this.upload.emit(image);
    }
  }

  onViewDetails(event) {
    this.viewDetails.emit(event);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected
      ? this.selection.clear()
      : this.selection.select(...this.dataSource.data);
  }

  onSingleChange(row) {
    this.selection.toggle(row);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.expenses);

    this.selection.changed
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.rowSelected.emit(this.selection.selected);
          this.allCheckedSubject.next(this.isAllSelected);
          this.allIndeterminateSubject.next(
            this.selection.hasValue() && !this.isAllSelected
          );
        })
      )
      .subscribe();
  }

  /**
   * After initialization of component
   */
  ngAfterViewInit() {
    merge(this.pageUpdate$, this.sort.sortChange)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          if (Object.keys(res).length) {
            const options = {
              pageNo: res?.pageIndex + 1,
              pageSize: res?.pageSize,
              sortOrder: this.sort.active
                ? {
                    colId:
                      this.sort.active === 'type'
                        ? 'isBillable'
                        : this.sort.active,
                    sort: this.sort.direction ? this.sort.direction : 'asc',
                  }
                : this.defaultSortParams,
            };
            this.paginationControls.emit(options);
          }
        })
      )
      .subscribe();
  }

  /**
   * Paginator update
   **/
  onPageUpdate(data) {
    this.pageSubject$.next(data);
  }

  isRowSelected(row) {
    return this.selection.isSelected(row);
  }

  expenseReadyForSubmission(element) {
    // If expense has no invalid fields and transaction date is valid then its submittable
    if (element && element.paymentType === 'user') {
      return false;
    }
    if(
      element 
      && element.invalid && element.invalid.length === 0
      && element.transactionDate !== null
    ) {
      return true;
    } else if(
      element &&
      element.type &&
      element.type.name.toLowerCase().includes('fraud')
    ) {
      // If expense is fraudulent and receipt is not required
      // and fraudulent check box is checked then expense is submittable
      return isFraudulentExpenseValidForSubmission(element);

    } else if (
      element && 
      element.type &&  (
      element.type.name.toLowerCase().includes('foreign') ||
      element?.vendor?.toLowerCase() === 'foreign transaction fee')
    ){
      return true;
    } else {
      // Every other time its non submittable
      return false;
    }
  }

  ngOnDestroy() {
    this.closeFabMenu = true;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
