<div class="expense-form-layout"
  [class.detail-modal]="detailLayout"
  attr.data-includereceipt="{{ includeReceipt }}"
>
  <div class="expense-form-layout__content" [class.detail-modal]="detailLayout">
    <ng-content select="[content]"></ng-content>
  </div>
  <div
    class="expense-form-layout__receipt"
    [class.detail-modal]="detailLayout"
    *ngIf="includeReceipt"
  >
    <ng-content select="[receipt]"></ng-content>
  </div>
</div>
