import { createAction, props } from '@ngrx/store';

// This action is to be dispatched when an initial request for a selected cell in a modal is complete
export const initialModalRequestComplete = createAction(
  '[All-Modal] initialRequestComplete'
);
// This action is to be dispatched when the modal is closed
export const initialModalRequestFinished = createAction(
  '[All-Modal] initialRequestFinished'
);
// This action will set the continueTo property of the allModal state
export const setContinueTo = createAction(
  '[All-Modal] setContinueTo',
  props<{ continueTo: string }>()
);
// This action will reset the continueTo property of the allModal state
export const resetContinueTo = createAction('[All-Modal] resetContinueTo');

export const setAllowUploads = createAction(
  '[All-Modal] Setting upload permissions.',
  props<{ fileUploadAllowed: boolean }>()
);

export const setFabIsActive = createAction(
  '[All-Modal] Setting FAB is active.',
  props<{ fabIsActive: boolean }>()
);

export const setCurrentModal = createAction(
  '[All-Modal] Setting modal currently being used',
  props<{ currentModal: string }>()
);
