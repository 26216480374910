/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ApprovalActions,
  pendingApprovalWithActivityAndSalesforce,
  selectFromCustomCaseApi,
  SalesforceCaseActions,
  State,
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ModalInput,
  SalesforceCase
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ExpenseService } from '../../../../../app/services/expense.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

type SelectedExpense = Partial<Expense> & {
  salesforceId: Partial<SalesforceCase>
};
@Component({
  selector: 'ems-approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: ['./approval-modal.component.scss'],
})
export class ApprovalModalComponent implements OnInit {
  openApproval$: Observable<any>;
  modalConfig$: Observable<ModalInput>;
  hasFraudulentExpense = false;
  activityTabOpen = false;
  enableUploadReceipt = false;
  showEditSection = true;
  unsubscribe$: Subject<void> = new Subject();
  
  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>,
    private expenseService: ExpenseService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    this.openApproval$ = this.store$.pipe(
      select(pendingApprovalWithActivityAndSalesforce)
    );


    this.modalConfig$ = this.openApproval$.pipe(
      map((expense) => {
        const subtitle = `${expense.id}`;
        this.hasFraudulentExpense = expense?.fraudulent;
        this.enableUploadReceipt= expense?.enableUploadReceipt;
        // TODO: Make this work with @Inject(LOCALE_ID)
        const submitter = {
          firstName: expense?.submitter?.firstName,
          lastName: expense?.submitter?.lastName,
          id: expense?.submitter?.primaryInfo.id,
          email: expense?.submitter?.primaryInfo.work_email,
          phone: expense?.submitter?.primaryInfo.phone_number,
        };

        const date = expense?.submissionDate
          ? formatDate(expense.submissionDate, 'shortDate', 'en-US', 'UTC')
          : '';
        let configInfo: any = {
          submitter,
          date,
          subtitle,
          modalId: 'approval-modal',
          title: this.expenseService.getExpenseModalTitle(expense.paymentType),
        };
        configInfo = addProxyInfo(expense, configInfo);
        return configInfo;
      })
    );
    this.onResize();
  }

  smallMaxScreenWidth = 767;
  screenHeight: number;
  screenWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth;
  }

  isMobileView() {
    return this.screenWidth > this.smallMaxScreenWidth;
  }

  ngOnInit() {
    if (this.router.url === '/approvals/reviewed') {
      this.showEditSection = false;
    }
    this.openApproval$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.store$.select(selectFromCustomCaseApi)),
      map(([receivedExpense, salesforceId]) => {
        const expense = <SelectedExpense>receivedExpense;
        if(
          'id' in expense.salesforceId &&
          expense.salesforceId.id &&
          expense.salesforceId.case_number === undefined
        ) {
          if(!salesforceId) {
            const action = SalesforceCaseActions.getCustomCaseByExternalId({
              externalId: expense.salesforceId.id
            });
            this.store$.dispatch(action);
          } else {
            Object.assign(expense, { salesforceId });
          }
        }

        return expense;
      })
    ).subscribe();

  }

  onApprove() {
    if (this.hasFraudulentExpense) {
      return this.modalService.open('approver-fraud-warn-modal');
    }
    this.store$.dispatch(
      ApprovalActions.verifyExpenses({ status: 'approved' })
    );
  }

  onFileUploadSizeError() {
    this.toastrService.show('Receipt file size cannot exceed 10MB.', '', {
      timeOut: 10000,
    });
  }

  onRemoveReceipt(id) {
    this.store$.dispatch(ApprovalActions.removeReceipt({ id }));
  }

  onRotateReceipt({ id, angle }) {
    this.store$.dispatch(ApprovalActions.rotateReceipt({ id, angle }));
  }
  onReceiptUploaded(expense) {
    this.store$.dispatch(ApprovalActions.uploadReceiptComplete({ expense }));
  }

  onSendTo() {
    this.modalService.open('send-to-modal');
  }

  onTabSelected(tab: string) {
    if (tab === 'Activity Log') {
      this.activityTabOpen = true;
    } else {
      this.activityTabOpen = false;
    }
  }
}

const addProxyInfo = (expense, config) => {
  if (
    expense?.activity?.length !== 0 &&
    expense?.activity[0]?.action.search('Submitted to') !== -1 &&
    expense?.submitter?.lastName
  ) {
    const hasVia = expense?.activity[0].author?.author.search('via');
    if (hasVia !== -1) {
      const viaProxy = expense?.activity[0].author?.author.split('via');
      config.proxy = `via ${viaProxy[1]}`;
    }
  }
  return config;
};
