import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiService,
  BaseModalService,
  UtilsService,
} from '@ems-gui/expense/util-web-infrastructure';
import {
  Expense,
  EXPENSE_FILTERS,
  SORT_ORDER,
} from '@ems-gui/shared/util-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SnackbarService } from 'ngx-snackbar';
import { forkJoin, Observable } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  retryWhen,
  scan,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ActivityActions,
  ApprovalActions,
  DraftActions,
  LayoutActions,
  MileageDraftActions,
  NewActions,
  NewMileageActions,
  SubmittedActions,
  TrashActions,
} from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import {
  selectCurrentDraft,
  selectDraftActivityId,
  selectedDraftId,
  selectedExpense,
  selectFilters,
} from '../../selectors/expense.selectors';
import { selectJobCodeEntities } from '../../selectors/job-code.selectors';

@Injectable()
export class EditDraftEffects {
  submit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.submit),
      withLatestFrom(
        this.store$.pipe(select(selectedDraftId))
      ),
      switchMap(([{ saveExpensePayload }, id]) =>
        this.apiService
          .submitDraftExpense({...saveExpensePayload},id as number)
          .pipe(
            map((response: any) =>
              DraftActions.submitComplete({ expense: response.expense })
            ),
            catchError(({ error }) => {
              const message = `Could not submit: ${error.message}`;
              return [LayoutActions.globalToastErrorMessage({ message })];
            })
          )
      )
    )
  );

  multiEditSubmit$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(DraftActions.multiEditSubmit),
        switchMap((res: any) =>
          this.apiService.multiEditSaveExpense(res).pipe(
            map((response: any) =>
            DraftActions.multiEditExpensesComplete({ expenses: response })
            ),
            catchError(({ error }) => {
              const message = `Could not submit: ${error.message}`;
              return [LayoutActions.globalToastErrorMessage({ message })];
            })
          )
        )
      )
  );

  multipleExpenseEditComplete = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DraftActions.multiEditExpensesComplete
      ),
      withLatestFrom(this.store$.pipe(select(selectFilters))),
      switchMap(([, filters]) => [
        SubmittedActions.getUpdatedUnsubmitted({
          page: filters.unsubmitted.page,
          sort: filters.unsubmitted.sort,
          filters: filters.unsubmitted.filters,
        })
      ])
    )
  );

  trash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.trashDrafts),
      switchMap(({ expenses }) =>
        this.apiService.trashExpenses({ expenses }).pipe(
          map((response) =>
            DraftActions.trashDraftsComplete({ expenses: response })
          ),
          catchError(errorHandler(DraftActions.trashDraftsError))
        )
      )
    )
  );

  trashComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.trashDraftsComplete),
      tap(({ expenses }) => {
        this.modalService.close('expense-form');
        const singleExpense = expenses.length === 1;
        const caption = singleExpense
          ? 'Expense'
          : `${expenses.length} expenses`;
        this.utils.displaySnackbar(caption, 'trashed', '/expenses/trash');
      }),
      switchMap(() => [
        LayoutActions.dismissModal(),
        TrashActions.getUpdatedTrash({
          page: null,
          sort: SORT_ORDER,
          filters: EXPENSE_FILTERS,
        }),
      ])
    )
  );

  save$ = createEffect(() =>
      this.actions$.pipe(
        ofType(DraftActions.save),
        map((action: any) => {
          if (!action.expense.message) {
            const expense = JSON.parse(JSON.stringify(action.expense));
            delete expense.message;
            return DraftActions.saveWithoutNote({
              expense,
            });
          } else {
            return DraftActions.saveWithNote({
              expense: action.expense,
            });
          }
        })
      )
  );

  saveExpenseWithReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveExpenseWithReceipt),
      withLatestFrom(this.store$.pipe(select(selectDraftActivityId))),
      switchMap(([action, activityId]) => {
        if (action.saveExpensePayload.message) {
          action.saveExpensePayload.activityId = activityId ? activityId : undefined;
        }
        else {
          delete action.saveExpensePayload.message;
        }
        return this.apiService.saveExpenseWithReceipt(action.saveExpensePayload)
          .pipe(
            map((response: any) => {
              return DraftActions.saveComplete({ expense: response })
            }
            )
          )
      }
      )
    )
  );

  saveComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveComplete),
      map((expense ) => 
        DraftActions.getUpdatedDraftComplete(expense)
      )
    )
  );

  saveWithoutNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveWithoutNote),
      switchMap(({ expense }) =>
        this.apiService.saveDraft(expense).pipe(
          mergeMap((response) => [
            DraftActions.saveWithoutNoteComplete({ expense: response }),
            DraftActions.getUpdatedDraftComplete({ expense: response }),
          ]),
          catchError(errorHandler(DraftActions.saveWithoutNoteError))
        )
      )
    )
  );

  saveWithoutNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveWithoutNoteComplete),
      withLatestFrom(this.store$.pipe(select(selectDraftActivityId))),
      filter(([action, id]) => id !== null),
      switchMap(([note, activityId]) =>
        this.apiService.deleteNote(activityId).pipe(
          map(({ activity }) =>
            ActivityActions.deleteDraftNoteComplete({ activity: activity })
          ),
          catchError(errorHandler(DraftActions.saveWithoutNoteError))
        )
      )
    )
  );

  saveWithNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveWithNote),
      withLatestFrom(this.store$.pipe(select(selectDraftActivityId))),
      switchMap(([action, activityId]) =>
        this.apiService
          .saveDraft({
            ...action.expense,
            activityId: activityId ? activityId : action.expense.activityId,
          })
          .pipe(
            map((response) =>
              DraftActions.saveWithNoteComplete({ expense: response })
            ),
            catchError(errorHandler(DraftActions.saveWithNoteError))
          )
      )
    )
  );

  saveWithNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveWithNoteComplete),
      mergeMap(({ expense }) => [
        DraftActions.getUpdatedDraftComplete({ expense }),
        ActivityActions.getDraftActivityId({ activity: expense.activity.id })
      ])
    )
  );
  saveWithNoteCompleteAndGetUnsubmittedActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.saveWithNoteComplete),
      withLatestFrom(this.store$.select(selectJobCodeEntities)),
      switchMap(([{expense}, store]) =>
        this.apiService.getActivity(expense.expense.id).pipe(
          map((response: any) => {
            const activity = response.map((a) => {
              const modifiedActivity = { ...a };
              modifiedActivity.jobCode = a.jobCode ? store[a.jobCode].code : '';
              return modifiedActivity;
            });
            return ActivityActions.getUnsubmittedActivityComplete({ activity, openModal: false });
          }),
          catchError(errorHandler(ActivityActions.getUnsubmittedActivityError))
        )
    )
    )
  );


  getLatestExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.editDraft),
      withLatestFrom(this.store$.select(selectCurrentDraft)),
      switchMap(([{ id }]) => this.apiService.getOneExpense(id).pipe(
        map((expense) => DraftActions.getExpenseDataComplete({ expense }))
      ))
    )
  )

  editDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.editDraft),
      withLatestFrom(this.store$.select(selectJobCodeEntities)),
      switchMap(([{ id }, store]) =>
        this.apiService.getActivity(id).pipe(
          map((response: any) => {
            const activity = response.map((a) => {
              const modifiedActivity = { ...a };
              modifiedActivity.jobCode = a.jobCode ? store[a.jobCode].code : '';
              return modifiedActivity;
            });
            return ActivityActions.getUnsubmittedActivityComplete(
              { activity, openModal: true}
            );
          }),
          catchError(errorHandler(ActivityActions.getUnsubmittedActivityError))
        )
      )
    )
  );

  displayDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.getUnsubmittedActivityComplete),
      withLatestFrom(this.store$.pipe(select(selectedDraftId))),
      map(([{activity, openModal}, id]) => {
        if(openModal) {
          return LayoutActions.openExpenseModal({
            name: 'expense-form',
            id: typeof id === 'number' ? id : +id,
          });
        } else {
          return { type: '[No Operation]' };
        }
      })
    )
  );

  displayMultiDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.editMultiDraft),
      map((expenses) => {
        return LayoutActions.openExpenseModal({
          name: 'expense-multi-edit-form',
          expenses: expenses.expenses
        });
      })
    )
  );

  // added dismiss effect so dismissModal state property is updated and form change event from submitting
  // won't trigger another auto-save
  dismiss$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.submitComplete, DraftActions.resubmitComplete),
      map(() => LayoutActions.dismissModal())
    )
  );

  // submitDrafts$ is used for submitting multiple drafts at the same time using expense ids
  submitDrafts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.submitDrafts),
      switchMap((res: any) =>
        this.apiService.saveExpenses({ expenses: res.expenses }).pipe(
          map((response) =>
            DraftActions.submitDraftsComplete({ expenses: response })
          ),
          catchError(errorHandler(DraftActions.submitDraftsError))
        )
      )
    )
  );

  resubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.resubmit),
      switchMap((res: any) =>
        this.apiService.resubmitExpenses({ expenses: res.expenses }).pipe(
          map((response) =>
            DraftActions.resubmitComplete({ expenses: response })
          ),
          catchError(errorHandler(DraftActions.resubmitError))
        )
      )
    )
  );

  submitAllValidDrafts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.submitAllValidDrafts),
      switchMap((res: any) => {
        return forkJoin([
          this.apiService.saveExpenses({ expenses: res.drafts }),
          this.apiService.resubmitExpenses({ expenses: res.rejected }),
        ]).pipe(
          map(([res1, res2]: any) =>
            DraftActions.submitAllValidDraftsComplete({
              expenses: [...res1, ...res2],
            })
          ),
          catchError(errorHandler(DraftActions.submitAllValidDraftsError))
        );
      })
    )
  );

  getItemizedExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.getUnsubmittedActivityComplete),
      withLatestFrom(this.store$.pipe(select(selectCurrentDraft))),
      filter(([res, expense]) => expense.parent),
      switchMap(([action, expense]) =>
        this.apiService.getItemizedExpenses(+expense.parent).pipe(
          map((expenses: any) =>
            DraftActions.getItemizedExpensesComplete({ expenses })
          ),
          catchError(errorHandler(DraftActions.getItemizedExpensesError))
        )
      )
    )
  );

  updateItemizedExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.updateItemizedExpenses),
      withLatestFrom(this.store$.pipe(select(selectCurrentDraft))),
      switchMap(([res, expense]) =>
        this.apiService
          .updateItemizedExpenses({
            create: res.create,
            update: res.update,
            delete: res.delete,
            id: +expense.parent,
          })
          .pipe(
            map((expenses: any) => {
              if (expenses.delete && expenses.delete.length) {
                const deleted = expenses.delete.map((e) => e.id);
                const updatedExpenses = {
                  ...expenses,
                  delete: [...deleted],
                };
                return DraftActions.updateItemizedExpensesComplete({
                  expenses: updatedExpenses,
                });
              } else {
                return DraftActions.updateItemizedExpensesComplete({
                  expenses,
                });
              }
            }),
            catchError(errorHandler(DraftActions.updateItemizedExpensesError))
          )
      )
    )
  );

  updateItemizedExpensesComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DraftActions.updateItemizedExpensesComplete),
        tap(() => {
          this.modalService.close('expense-form');
          this.modalService.close('expense-itemize-edit-modal');
          this.snackbarService.add({
            msg: `Expenses re-itemized.`,
            timeout: 10000,
            action: {
              text: '',
            },
            background: '#1d3148',
          });
        })
      ),
    { dispatch: false }
  );

  saveItemizedExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.itemize),
      withLatestFrom(this.store$.pipe(select(selectedExpense))),
      switchMap(([amounts, id]) =>
        this.apiService
          .saveItemizedExpenses({
            amounts: amounts.amounts,
            id,
          })
          .pipe(
            map((response) =>
              DraftActions.itemizeComplete({ expenses: response })
            ),
            catchError(errorHandler(DraftActions.itemizeError))
          )
      )
    )
  );

  undoItemization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.undoItemization),
      withLatestFrom(this.store$.pipe(select(selectCurrentDraft))),
      filter(([res, expense]) => expense.parent),
      switchMap(([action, expense]) =>
        this.apiService.undoItemization(+expense.parent).pipe(
          map((expenses: any) => {
            const deleted = expenses.delete && expenses.delete.map((e) => e.id);
            const updatedExpenses = {
              ...expenses,
              delete: [...deleted],
            };
            return DraftActions.undoItemizationComplete({
              expenses: updatedExpenses,
            });
          }),
          catchError(errorHandler(DraftActions.undoItemizationError))
        )
      )
    )
  );

  saveItemizedExpensesComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DraftActions.itemizeComplete),
        tap((res: any) => {
          this.modalService.close('expense-modal-edit');
          this.modalService.close('expense-itemize-draft-modal');
          this.router.navigateByUrl('/expenses/unsubmitted');
          const caption = `${res.expenses.length - 1} expenses`;
          this.utils.displaySnackbar(caption, 'created');
        })
      ),
    { dispatch: false }
  );

  undoItemizationComplete$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(DraftActions.undoItemizationComplete),
        tap(() => {
          this.modalService.close('undo-itemization-alert-modal');
          this.snackbarService.add({
            msg: `Expenses de-itemized.`,
            timeout: 10000,
            action: {
              text: '',
            },
            background: '#1d3148',
          });
        }),
        map(() => LayoutActions.dismissModal())
      )
  );

  getItemizedParentAmount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.getItemizedParentData),
      switchMap(({ id }) =>
        this.apiService.getOneExpense(id).pipe(
          map((expense: Partial<Expense>) =>
            DraftActions.getItemizedParentDataComplete({
              amount: +expense?.amount,
              receipt: expense?.image,
            })
          ),
          catchError(errorHandler(DraftActions.getItemizedParentDataError))
        )
      )
    )
  );

  getItemizedParentAmountComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.getItemizedParentDataComplete),
      map(() =>
        LayoutActions.openExpenseModal({ name: 'expense-itemize-edit-modal' })
      )
    )
  );

  removeReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.removeReceipt, MileageDraftActions.removeReceipt),
      switchMap(({ id }) =>
        this.apiService.removeReceipt(id).pipe(
          map((response) =>
            DraftActions.removeReceiptComplete({ expense: response })
          ),
          catchError(errorHandler(DraftActions.removeReceiptError))
        )
      )
    )
  );

  rotateReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.rotateReceipt, MileageDraftActions.rotateReceipt),
      switchMap((res: any) =>
        this.apiService.rotateReceipt({ angle: res.angle, id: res.id }).pipe(
          map((expense: Expense) =>
            DraftActions.rotateReceiptComplete({ expense })
          ),
          catchError(errorHandler(DraftActions.rotateReceiptError))
        )
      )
    )
  );

  getExpenseData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.getExpenseData),
      delay(3000),
      switchMap((res: any) =>
        this.apiService.getOneExpenseReceipt(res.token).pipe(
          retryWhen((error$) => {
            return error$.pipe(
              delay(2000),
              scan((count, err) => {
                if (count > 2) {
                  throw err;
                } else {
                  return (count += 1);
                }
              }, 0)
            );
          }),
          map((response: Expense) =>
            DraftActions.getExpenseDataComplete({ expense: response })
          ),
          catchError(errorHandler(DraftActions.getExpenseDataError))
        )
      )
    )
  );

  uploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.uploadComplete),
      switchMap(({ draft }) =>
        this.apiService.getOneExpense(draft.id).pipe(
          map((expense: Partial<Expense>) =>
            DraftActions.getUpdatedDraftComplete({ expense })
          ),
          catchError(errorHandler(DraftActions.getUpdatedDraftComplete))
        )
      )
    )
  );

  openModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DraftActions.getExpenseDataComplete,
        DraftActions.getNoReceiptDataDraftComplete
      ),
      map(() => LayoutActions.openExpenseModal({ name: 'expense-form' }))
    )
  );

  getNoReceiptDataDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.getNoReceiptDataDraft),
      withLatestFrom(this.store$.pipe(select(selectedDraftId))),
      switchMap(([res, id]) =>
        this.apiService.getOneExpense(id).pipe(
          map((expense: Partial<Expense>) =>
            DraftActions.getNoReceiptDataDraftComplete({ expense })
          ),
          catchError(errorHandler(DraftActions.getNoReceiptDataDraftError))
        )
      )
    )
  );

  singleExpenseSubmittedComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DraftActions.submitComplete,
          MileageDraftActions.submitDraftComplete,
          NewMileageActions.submitComplete
        ),
        tap(() => {
          this.utils.displaySnackbar(
            'Expense',
            'submitted',
            '/expenses/submitted'
          );
        })
      ),
    { dispatch: false }
  );

  multipleExpensesSubmittedComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DraftActions.submitDraftsComplete,
        DraftActions.resubmitComplete,
        DraftActions.submitAllValidDraftsComplete
      ),
      withLatestFrom(this.store$.pipe(select(selectFilters))),
      tap(([{ expenses }, filters]) => {
        const singleExpense = expenses.length === 1;
        const caption = singleExpense
          ? 'Expense'
          : `${expenses.length} expenses`;
        this.utils.displaySnackbar(caption, 'submitted', '/expenses/submitted');
      }),
      switchMap(([, filters]) => [
        SubmittedActions.getUpdatedSubmitted({
          page: filters.unsubmitted.page,
          sort: filters.unsubmitted.sort,
          filters: filters.unsubmitted.filters,
        }),
      ])
    )
  );

  multipleExpenseUpdatesComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DraftActions.submitDraftsComplete,
        DraftActions.resubmitComplete,
        DraftActions.submitAllValidDraftsComplete,
        DraftActions.submitComplete,
        MileageDraftActions.submitDraftComplete,
        NewMileageActions.submitComplete,
        NewActions.submitComplete,
        DraftActions.itemizeComplete,
        DraftActions.undoItemizationComplete,
        DraftActions.updateItemizedExpensesComplete,
        DraftActions.trashDraftsComplete,
        MileageDraftActions.trashDraftsComplete,
        MileageDraftActions.applyExpenseToState,
        DraftActions.getNoReceiptDataDraftComplete,
        NewMileageActions.getExpenseComplete,
        NewActions.getExpenseDataComplete
      ),
      withLatestFrom(this.store$.pipe(select(selectFilters))),
      switchMap(([, filters]) => [
        ApprovalActions.getExpenseCounts(),
        SubmittedActions.getUpdatedUnsubmitted({
          page: filters.unsubmitted.page,
          sort: filters.unsubmitted.sort,
          filters: filters.unsubmitted.filters,
        }),
        SubmittedActions.getUpdatedSubmitted({
          page: filters.submitted.page,
          sort: filters.submitted.sort,
          filters: filters.submitted.filters,
        }),
      ])
    )
  );

  getReceiptByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DraftActions.getReceiptDataByToken),
      tap(() => {
        this.store$.dispatch(ApprovalActions.isLoading());
      }),
      switchMap(({ token }) =>
        this.apiService.getReceiptDataByToken(token).pipe(
          map((res: any) => {
            this.store$.dispatch(ApprovalActions.isLoadingComplete());
            return DraftActions.getReceiptDataByTokenComplete({ expense: res });
          }),
          catchError((error) => {
            this.store$.dispatch(ApprovalActions.isLoadingComplete());
            let message = "Error getting the receipt data. Try again...";
            if(error.error.code === 301) {
              message = `Could not scan the receipt for data: ${error.error.message}`;
            }
            return [LayoutActions.globalToastErrorMessage({ message })];
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private modalService: BaseModalService,
    private store$: Store<fromDashboard.State>,
    private router: Router,
    private utils: UtilsService,
    private snackbarService: SnackbarService
  ) {}
}
