import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-admin-user-summary',
  templateUrl: './admin-user-summary.component.html',
  styleUrls: ['./admin-user-summary.component.scss'],
})
export class AdminUserSummaryComponent implements OnInit {
  @Input() selectedUser;

  constructor() {}

  ngOnInit() {}
}
