import { Mileage } from '@ems-gui/shared/util-core';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MileageActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  currentMileage: Partial<Mileage>;
  futureMileage: Partial<Mileage>;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  rate: null,
  currentMileage: {
    rate: null,
    effectiveDate: null,
  },
  futureMileage: {
    rate: null,
    effectiveDate: null,
  },
});

export const reducer = createReducer(
  initialState,
  on(MileageActions.updateMileage, MileageActions.getMileage, (state) => ({
    ...state,
    loading: true,
  })),
  on(MileageActions.getMileageComplete, (state, { mileage }) => ({
    ...state,
    loading: false,
    currentMileage: {
      ...state.currentMileage,
      rate: mileage.current.rate,
      effectiveDate: mileage.current.effectiveDate,
    },
    futureMileage: {
      ...state.futureMileage,
      rate: mileage.future.rate,
      effectiveDate: mileage.future.effectiveDate,
    },
  })),
  on(MileageActions.updateMileageComplete, (state, { current, future }) => ({
    ...state,
    loading: false,
    currentMileage: {
      ...state.currentMileage,
      rate: current.rate,
      effectiveDate: current.effectiveDate,
    },
    futureMileage: {
      ...state.futureMileage,
      rate: future.rate,
      effectiveDate: future.effectiveDate,
    },
  })),
  on(
    MileageActions.updateMileageError,
    MileageActions.getMileageError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const currentMileage = (state: State) => state.currentMileage;
export const futureMileage = (state: State) => state.futureMileage;
