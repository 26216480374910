import { Expense } from '@ems-gui/shared/util-core';
import { createSelector } from '@ngrx/store';
import * as fromExpense from '../reducers/expense.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectExpenseState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.expense
);

export const {
  selectIds: selectExpenseIds,
  selectEntities: selectExpenseEntities,
  selectAll: selectAllExpense,
} = fromExpense.adapter.getSelectors(selectExpenseState);

export const selectFilterCount = createSelector(
  selectExpenseState,
  fromExpense.selectFilterCount
);

export const selectFilters = createSelector(
  selectExpenseState,
  fromExpense.selectFilters
);

export const selectSubmittedFilters = createSelector(
  selectExpenseState,
  fromExpense.getSubmittedFilters
);

export const selectFilterType = createSelector(
  selectExpenseState,
  fromExpense.selectFilterType
);

export const selectFilterExpenseTypeId = createSelector(
  selectExpenseState,
  fromExpense.selectFilterExpenseTypeId
);

export const selectFilterJobCodeId = createSelector(
  selectExpenseState,
  fromExpense.selectFilterJobCodeId
);

export const selectReceiptPhoto = createSelector(
  selectExpenseState,
  fromExpense.getReceiptPhoto
);
export const selectReceiptData = createSelector(
  selectExpenseState,
  fromExpense.getReceiptData
);
export const selectReceiptDataByToken = createSelector(
  selectExpenseState,
  fromExpense.getReceiptDateByToken
);
export const selectReceiptDataLoading = createSelector(
  selectExpenseState,
  fromExpense.newExpenseLoading
);
export const selectAutoSavedExpenseId = createSelector(
  selectExpenseState,
  fromExpense.newExpenseAutoSavedId
);
export const selectNewExpenseAutoSaving = createSelector(
  selectExpenseState,
  fromExpense.newExpenseAutoSaving
);
export const selectNewExpenseActivityId = createSelector(
  selectExpenseState,
  fromExpense.newExpenseActivityId
);
export const newMileageExpenseReimbursement = createSelector(
  selectExpenseState,
  fromExpense.newMileageExpenseReimbursement
);
export const selectNewItemizeConfirmedStatus = createSelector(
  selectExpenseState,
  fromExpense.getNewItemizeConfirmed
);
export const selectDraftItemizeConfirmedStatus = createSelector(
  selectExpenseState,
  fromExpense.getDraftItemizeConfirmed
);
export const selectExpenseItemizationStatus = createSelector(
  selectExpenseState,
  fromExpense.newExpenseItemizationStatus
);
export const selectDraftItemizationStatus = createSelector(
  selectExpenseState,
  fromExpense.draftExpenseItemizationStatus
);
export const selectDraftItems = createSelector(
  selectExpenseState,
  fromExpense.getSelectedDraftItems
);

export const selectedMultiEditExpenses = createSelector(
  selectExpenseState,
  fromExpense.getSelectedMultiEditExpenses
);

export const selectDraftAutoSavedAmount = createSelector(
  selectExpenseState,
  fromExpense.getAutoSavedDraftAmount
);

export const selectItemizedDraftParentData = createSelector(
  selectExpenseState,
  fromExpense.draftItemizedParentData
);

export const selectAutoSavedExpense = createSelector(
  selectExpenseEntities,
  selectAutoSavedExpenseId,
  (expenses, id) => expenses && expenses[+id]
);

export const selectAutoSavedExpenseItemizedAmount = createSelector(
  selectAutoSavedExpense,
  (expense) => {
    const amount = expense && +expense.amount;
    const rem = amount && amount % 2;
    if (amount && !rem) {
      const itemAmount = amount / 2;
      return [itemAmount, itemAmount];
    } else if (amount && rem) {
      const itemAmount = Math.floor(amount / 2);
      return [itemAmount, itemAmount + 1];
    } else {
      return [];
    }
  }
);

export const selectedDraftItemizedExpenses = createSelector<
  unknown,
  unknown[],
  unknown | Expense[]
>(selectDraftItems, (expenses: Expense[]) => {
  return expenses.map((expense) => {
    return { id: expense.id, amount: expense.amount };
  });
});

export const selectedDraftItemsWithInvalidStatus = createSelector<
  unknown,
  unknown[],
  number
>(
  selectDraftItems,
  (expenses: Expense[]) =>
    expenses.filter(
      (expense) => expense.status === 'pending' || expense.status === 'approved'
    ).length
);

export const submittedExpensesLoading = createSelector(
  selectExpenseState,
  fromExpense.submittedLoading
);

export const selectSelectedSubmittedExpenseId = createSelector(
  selectExpenseState,
  fromExpense.getSelectedSubmittedId
);

export const selectSubmittedCount = createSelector(
  selectExpenseState,
  fromExpense.submittedExpenseCount
);

export const selectUnsubmittedCount = createSelector(
  selectExpenseState,
  fromExpense.unsubmittedExpenseCount
);

export const selectUnsubmittedExpenseTotalCount = createSelector(
  selectAllExpense,
  selectUnsubmittedCount,
  (expenses, count) => {
    const parentTotal =
      expenses?.length && expenses.filter((e) => e.status === 'parent').length;
    return count - parentTotal;
  }
);

export const draftExpensesLoading = createSelector(
  selectExpenseState,
  fromExpense.draftLoading
);

export const selectDraftError = createSelector(
  selectExpenseState,
  (state) => state.draft.error
);

export const continueFraudulentSubmission = createSelector(
  selectExpenseState,
  fromExpense.continueFraudulentSubmission
);

export const selectedDraftId = createSelector(
  selectExpenseState,
  fromExpense.getSelectedDraftId
);


export const selectCurrentDraft = createSelector(
  selectExpenseEntities,
  selectedDraftId,
  (expenses, id) => expenses && expenses[id]
);

export const selectCurrentDraftReceipt = createSelector(
  selectCurrentDraft,
  (expense) => (expense && expense.hasReceipt)
);

export const selectedDraftExpenseItemizedAmount = createSelector(
  selectCurrentDraft,
  (expense) => {
    const amount = expense && +expense.amount;
    const rem = amount && amount % 2;
    if (amount && !rem) {
      const itemAmount = amount / 2;
      return [itemAmount, itemAmount];
    } else if (amount && rem) {
      const itemAmount = Math.floor(amount / 2);
      return [itemAmount, itemAmount + 1];
    } else {
      return [];
    }
  }
);

export const selectCurrentDraftWithUpdatedAmount = createSelector(
  selectCurrentDraft,
  selectDraftAutoSavedAmount,
  (expense, amount) => {
    return {
      ...expense,
      amount,
    };
  }
);

export const selectedItemizedExpenseParent = createSelector(
  selectExpenseEntities,
  selectCurrentDraft,
  (expenses, draft) => {
    return draft?.parent && expenses[draft.parent];
  }
);

export const selectedDraftItemizedAmount = createSelector(
  selectDraftAutoSavedAmount,
  (amount) => {
    const rem = amount && amount % 2;
    if (amount && !rem) {
      const itemAmount = amount / 2;
      return [itemAmount, itemAmount];
    } else if (amount && rem) {
      const itemAmount = Math.floor(amount / 2);
      return [itemAmount, itemAmount + 1];
    } else {
      return [];
    }
  }
);

export const selectDraftAutoSaving = createSelector(
  selectExpenseState,
  fromExpense.autoSaving
);

export const selectDraftIsSaveSuccessful = createSelector(
  selectExpenseState,
  fromExpense.isSaveSuccessful
)

export const selectAutoSavedDraftId = createSelector(
  selectExpenseState,
  fromExpense.draftAutoSavedId
);

export const selectedDraftExpenses = createSelector(
  selectExpenseState,
  fromExpense.getSelectedDrafts
);

export const selectedRejectedExpenses = createSelector(
  selectExpenseState,
  fromExpense.getSelectedRejected
);

export const selectUnsubmittedPageSize = createSelector(
  selectExpenseState,
  fromExpense.getUnsubmittedPageSize
);

export const selectSubmittedPageSize = createSelector(
  selectExpenseState,
  fromExpense.getSubmittedPageSize
);

export const selectTrashPageSize = createSelector(
  selectExpenseState,
  fromExpense.getTrashPageSize
);

// ---- Mileage Draft
export const selectDraftActivityId = createSelector(
  selectExpenseState,
  fromExpense.draftActivityId
);
export const selectMileageDraftReimbursement = createSelector(
  selectExpenseState,
  fromExpense.mileageDraftReimbursement
);
export const selectDraftReceiptPhoto = createSelector(
  selectExpenseState,
  fromExpense.getDraftReceiptPhoto
);

export const selectTrashLoading = createSelector(
  selectExpenseState,
  fromExpense.trashLoading
);

export const selectTrashedExpenseCount = createSelector(
  selectExpenseState,
  fromExpense.trashExpenseCount
);

export const selectTrashedExpenseId = createSelector(
  selectExpenseState,
  fromExpense.selectedTrashedExpenseId
);

// ---- Web - Trash
export const selectedTrashedExpenseCount = createSelector(
  selectExpenseState,
  fromExpense.selectedTrashedExpenseCount
);

export const selectedTrashedExpenses = createSelector(
  selectExpenseState,
  fromExpense.selectedTrashedExpenses
);

export const selectedExpense = createSelector(
  selectExpenseState,
  (state) => {
    if(state.new.autoSavedId) return Number(state.new.autoSavedId);
    if(state.draft.selectedId) return Number(state.draft.selectedId);
    if(state.submitted.selectedId) return Number(state.submitted.selectedId);
    if(state.trash.selectedId) return Number(state.trash.selectedId);
    if(state.draft.selectItemizeId) return Number(state.draft.selectItemizeId);

    return 0;
  }
);

export const selectedToItemizeExpense = createSelector(
  selectExpenseEntities,
  selectedExpense,
  (expenses, id) => expenses && expenses[id]
);

export const selectExpenseOcrMismatch = createSelector(
  selectExpenseEntities,
  selectedExpense,
  (entities, id) =>
    !!id &&
    entities &&
    entities[id] &&
    !!entities[id].ocrMismatch
);

export const selectCurrentExpenseId = createSelector(
  selectDashboardState,
  (state) => Number(state.expense.draft.selectedId) ??
    Number(state.expense.submitted.selectedId) ??
    Number(state.expense.trash.selectedId) ??
    Number(state.approval.selectedExpenseId) ??
    false
);

export const selectCurrentExpense = createSelector(
  selectCurrentExpenseId,
  selectExpenseEntities,
  (id, entities) => id && entities && <Expense>entities[id] || undefined
);


