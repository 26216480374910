import { Component, Input } from "@angular/core";
import { IconsService } from "@src/app/services/icons.service";

@Component({
  selector: 'sis-icon',
  templateUrl: 'icon.component.html',
  styleUrls: [ 'icon.component.scss' ]
})
export class IconComponent {
  @Input() iconName: string;
  @Input() width = '100%';
  @Input() height = '100%';

  public constructor(private iconService: IconsService) {}

  public get icon() {
    return this.iconService.getIcon(this.iconName);
  }
}
