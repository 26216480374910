import { Component, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  continueFraudulentSubmission,
  DraftActions,
  LayoutActions,
  selectedDraftExpenses,
  selectedRejectedExpenses,
  State,
} from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-expense-fraud-warn-modal',
  templateUrl: './expense-fraud-warn-modal.component.html',
  styleUrls: ['./expense-fraud-warn-modal.component.scss'],
})
export class ExpenseFraudWarnModalComponent implements OnInit {
  continueFraudulentSubmission$: Observable<any>;
  selectedDraftExpenses$: Observable<any>;
  selectedRejectedExpenses$: Observable<any>;
  form = {};
  modalConfig: ModalInput = {
    title: '',
    modalId: 'expense-fraud-warn-modal',
  };
  selectedDrafts = [];
  selectedRejected = [];
  constructor(
    private store$: Store<State>,
    private modalService: BaseModalService
  ) {
    this.continueFraudulentSubmission$ = this.store$.pipe(
      select(continueFraudulentSubmission)
    );
    this.selectedDraftExpenses$ = this.store$.pipe(
      select(selectedDraftExpenses)
    );
    this.selectedRejectedExpenses$ = this.store$.pipe(
      select(selectedRejectedExpenses)
    );
  }

  ngOnInit() {
    this.continueFraudulentSubmission$
      .pipe(
        take(1),
        tap((response) => {
          this.form = response;
        })
      )
      .subscribe();
    this.selectedDraftExpenses$
      .pipe(
        take(1),
        tap((res) => {
          if (res?.length) {
            this.selectedDrafts = res;
          }
        })
      )
      .subscribe();
    this.selectedRejectedExpenses$
      .pipe(
        take(1),
        tap((res) => {
          if (res?.length) {
            this.selectedRejected = res;
          }
        })
      )
      .subscribe();
  }

  onCancel() {
    this.modalService.close('expense-fraud-warn-modal');
    this.store$.dispatch(LayoutActions.dismissModal());
  }

  onContinue() {
    if (Object.keys(this.form).length) {
      const expense = { ...this.form, fraudulent: true };
      const payload = {
        expense: expense,
        file: null,
        rotationAngle: null
      }
      this.store$.dispatch(DraftActions.submit({saveExpensePayload: payload }));
      this.store$.dispatch(DraftActions.submitFraudDraftComplete());
    }

    if (this.selectedDrafts.length && this.selectedRejected.length) {
      this.store$.dispatch(
        DraftActions.submitAllValidDrafts({
          drafts: this.selectedDrafts,
          rejected: this.selectedRejected,
        })
      );
    } else if (this.selectedDrafts.length && !this.selectedRejected.length) {
      this.store$.dispatch(
        DraftActions.submitDrafts({ expenses: this.selectedDrafts })
      );
    } else if (!this.selectedDrafts.length && this.selectedRejected.length) {
      this.store$.dispatch(
        DraftActions.resubmit({ expenses: this.selectedRejected })
      );
    }
    this.modalService.close('expense-fraud-warn-modal');
    this.store$.dispatch(LayoutActions.dismissModal());
  }
}
