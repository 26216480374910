import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-user-chip',
  templateUrl: './user-chip.component.html',
  styleUrls: ['./user-chip.component.scss'],
})
export class UserChipComponent implements OnInit {
  /**
   * Users First Name
   */
  @Input() firstName = '';

  /**
   * Users Last Name
   */
  @Input() lastName = '';

  /**
   * An id assigned to the user
   */
  @Input() id = '';

  /**
   * Email assigned to the user
   */
  @Input() email = '';

  /**
   * Phone assigned to the user
   */
  @Input() phone = '';

  @Input() verticalPopup = false;

  /**
   * Event emitted when dismiss button is clicked
   */
  @Output() dismiss = new EventEmitter<boolean>();

  /**
   * Function that fires the dismiss event
   */
  onDismissClick() {
    this.dismiss.emit();
  }

  constructor() {}

  ngOnInit() {}
}
