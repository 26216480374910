<ems-base-modal
  id="receipt-data-warn-modal"
  [config]="modalConfig"
  (dismiss)="onContinue()"
  class="dialog-modal"
>
  <ems-receipt-data-warn-dialog
    (continue)="onContinue()"
  ></ems-receipt-data-warn-dialog>
</ems-base-modal>
