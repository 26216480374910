import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-receipt-manager-error-dialog',
  templateUrl: './receipt-manager-error-dialog.component.html',
  styleUrls: ['./receipt-manager-error-dialog.component.scss'],
})
export class ReceiptManagerErrorDialogComponent implements OnInit {
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadCsv: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    return
  }

  onContinue() {
    this.continue.emit();
  }

  onDownloadCsv() {
    this.downloadCsv.emit();
  }
}
