// TODO: We can probably remove this file, as we are no longer emptying trash

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-empty-trash-dialog',
  templateUrl: './empty-trash-dialog.component.html',
  styleUrls: ['./empty-trash-dialog.component.scss'],
})
export class EmptyTrashDialogComponent {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() emptyTrash: EventEmitter<any> = new EventEmitter<any>();

  onCancel() {
    this.cancel.emit();
  }

  onEmptyTrash() {
    this.emptyTrash.emit();
  }
}
