<ems-base-modal
  id="delete-modal"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onDeleteDialogCancel()"
>
  <ems-delete-dialog
    (cancel)="onDeleteDialogCancel()"
    (delete)="onDeleteExpenses()"
    [expenseCount]="selectedTrashExpenses$ | async"
  ></ems-delete-dialog>
</ems-base-modal>
