<div>
  <div
    class="zoom-container"
    *ngIf="imgUrl"
  >
  <div>
    <button class="receipt-download" (click)="downloadReceipt($event)"> Download Receipt</button>
  </div>
  <iframe
    #receiptPhoto 
    *ngIf="isPdf"
    [src]="expense.image"
    class="pdf-viewer"
  ></iframe>

  <img
    *ngIf="!isPdf && expense.imageMetadata.ContentType !== 'pdf'"
    [src]="expense.image"
    class="receipt-image"
    #receiptPhoto
  />
  </div>