import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ConvertToDollarsPipe, PaymentPipe } from '@ems-gui/shared/util-core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddFeedUserFormComponent } from './add-feed-user-form/add-feed-user-form.component';
import { AddFeedUserModalComponent } from './add-feed-user-modal/add-feed-user-modal.component';
import { AddProxyUserFormComponent } from './add-proxy-user-form/add-proxy-user-form.component';
import { AddProxyUserModalComponent } from './add-proxy-user-modal/add-proxy-user-modal.component';
import { AdminUserAltEmailsComponent } from './admin-user-alt-emails/admin-user-alt-emails.component';
import { AdminUserProxyComponent } from './admin-user-proxy/admin-user-proxy.component';
import { AdminUserSummaryComponent } from './admin-user-summary/admin-user-summary.component';
import { AltEmailFormComponent } from './alt-email-form/alt-email-form.component';
import { AltEmailModalComponent } from './alt-email-modal/alt-email-modal.component';
import { ApprovalsListComponent } from './approvals-list/approvals-list.component';
import { BaseAccordionComponent } from './base/base-accordion/base-accordion.component';
import { BaseAvatarComponent } from './base/base-avatar/base-avatar.component';
import { BaseBannerComponent } from './base/base-banner/base-banner.component';
import { BaseBoxComponent } from './base/base-box/base-box.component';
import { BaseButtonGroupComponent } from './base/base-button-group/base-button-group.component';
import { BaseButtonComponent } from './base/base-button/base-button.component';
import { BaseCaptionComponent } from './base/base-caption/base-caption.component';
import { BaseCardSectionComponent } from './base/base-card-section/base-card-section.component';
import { BaseCardComponent } from './base/base-card/base-card.component';
import { BaseChipComponent } from './base/base-chip/base-chip.component';
import { BaseDismissButtonComponent } from './base/base-dismiss-button/base-dismiss-button.component';
import { BaseDividerComponent } from './base/base-divider/base-divider.component';
import { BaseDropdownComponent } from './base/base-dropdown/base-dropdown.component';
import { BaseFiltersComponent } from './base/base-filters/base-filters.component';
import { BaseHelperTextComponent } from './base/base-helper-text/base-helper-text.component';
import { BaseIconComponent } from './base/base-icon/base-icon.component';
import { BaseInlineComponent } from './base/base-inline/base-inline.component';
import { BaseModalComponent } from './base/base-modal/base-modal.component';
import { BaseStackComponent } from './base/base-stack/base-stack.component';
import { BaseTabComponent } from './base/base-tab/base-tab.component';
import { BaseTabsComponent } from './base/base-tabs/base-tabs.component';
import { BaseTextStyleComponent } from './base/base-text-style/base-text-style.component';
import { BaseTextComponent } from './base/base-text/base-text.component';
import { BaseTitleComponent } from './base/base-title/base-title.component';
import { BaseToastComponent } from './base/base-toast/base-toast.component';
import { BaseTooltipComponent } from './base/base-tooltip/base-tooltip.component';
import { DatepickerHeaderComponent } from './datepicker/datepicker-header/datepicker-header.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ExpenseFabComponent } from './expense-fab/expense-fab.component';
import { ExpenseFiltersComponent } from './expense-filters/expense-filters.component';
import { ExpensePaginatorComponent } from './expense-paginator/expense-paginator.component';
import { ExpensesListComponent } from './expenses-list/expenses-list.component';
import { ExpenseTransactionDatePipe } from './expense-transaction-date-pipe/expense-transaction-date-pipe.pipe';
import { ExpenseDaysSincePipe } from './expense-days-since-pipe/expense-days-since-pipe.pipe';
import { IconInfoCircleComponent } from './icon-info-circle/icon-info-circle.component';
import { IconItemizeComponent } from './icon-itemize/icon-itemize.component';
import { IconMileageExpenseComponent } from './icon-mileage-expense/icon-mileage-expense.component';
import { MobileTabNavComponent } from './mobile-tab-nav/mobile-tab-nav.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ReceiptImageComponent } from './receipt-image/receipt-image.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { ServerTypeaheadComponent } from './server-typeahead/server-typeahead.component';
import { UserChipComponent } from './user-chip/user-chip.component';
import { UserPopupComponent } from './user-popup/user-popup.component';
import { RouterModule } from '@angular/router';
import { SisModule } from '@src/app/sis/sis.module';
import { CreditCardHolderAlertModalComponent } from '@src/app/sis/components/credit-card-holder-alert-modal/credit-card-holder-alert-modal.component';
import { AlertBoxComponent } from '@src/app/sis/components/alert-box/alert-box.component';
import { RecallExpenseAlertComponent } from '@src/app/sis/components/recall-expense-alert/recall-expense-alert.component';
import { UnsavedChangesAlertComponent } from '@src/app/sis/components/unsaved-changes-alert/unsaved-changes-alert.component';
import { DiscardChangesAlertComponent } from '@src/app/sis/components/discard-changes-alert/discard-changes-alert.component';
import { CreditCardHolderAlertComponent } from '@src/app/sis/components/credit-card-holder-alert/credit-card-holder-alert.component';
import { ExpenseMismatchAlertComponent } from '@src/app/sis/components/expense-mismatch-alert/expense-mismatch-alert.component';
import { StatusDisplayComponent } from './status-display/status-display.component';
import { SubmittingCompanyCCExpensesAlertComponent } from '@src/app/sis/components/submitting-company-cc-expenses-alert/submitting-company-cc-expenses-alert.component';
import { NoMatchAlertComponent } from '@src/app/sis/components/no-match-alert/no-match-alert.component';

@NgModule({
  declarations: [
    ExpenseMismatchAlertComponent,
    CreditCardHolderAlertComponent,
    NoMatchAlertComponent,
    SubmittingCompanyCCExpensesAlertComponent,
    CreditCardHolderAlertModalComponent,
    AlertBoxComponent,
    IconItemizeComponent,
    AlertBoxComponent,
    RecallExpenseAlertComponent,
    ReceiptImageComponent,
    ConvertToDollarsPipe,
    PaymentPipe,
    ExpensePaginatorComponent,
    ExpenseFabComponent,
    ExpensesListComponent,
    ExpenseTransactionDatePipe,
    ExpenseDaysSincePipe,
    BaseTextStyleComponent,
    BaseDividerComponent,
    BaseCaptionComponent,
    BaseDismissButtonComponent,
    BaseStackComponent,
    BaseAvatarComponent,
    BaseDropdownComponent,
    BaseBoxComponent,
    BaseCardComponent,
    BaseCardSectionComponent,
    BaseTooltipComponent,
    BaseHelperTextComponent,
    BaseBannerComponent,
    BaseModalComponent,
    BaseToastComponent,
    BaseTextComponent,
    BaseFiltersComponent,
    BaseTitleComponent,
    BaseInlineComponent,
    BaseAccordionComponent,
    BaseButtonComponent,
    BaseButtonGroupComponent,
    BaseChipComponent,
    BaseTabsComponent,
    BaseTabComponent,
    BaseIconComponent,
    UserPopupComponent,
    ExpenseFiltersComponent,
    DatepickerComponent,
    DatepickerHeaderComponent,
    PageHeaderComponent,
    ApprovalsListComponent,
    TypeaheadComponent,
    ServerTypeaheadComponent,
    AdminUserAltEmailsComponent,
    AdminUserSummaryComponent,
    UserChipComponent,
    AdminUserProxyComponent,
    AltEmailModalComponent,
    AddProxyUserModalComponent,
    AltEmailFormComponent,
    AddProxyUserFormComponent,
    AddFeedUserFormComponent,
    AddFeedUserModalComponent,
    IconMileageExpenseComponent,
    IconInfoCircleComponent,
    MobileTabNavComponent,
    UnsavedChangesAlertComponent,
    DiscardChangesAlertComponent,
    StatusDisplayComponent,
  ],
  exports: [
    ExpensePaginatorComponent,
    ExpenseFabComponent,
    ExpensesListComponent,
    ExpenseTransactionDatePipe,
    BaseTextStyleComponent,
    BaseDividerComponent,
    BaseCaptionComponent,
    BaseDismissButtonComponent,
    BaseModalComponent,
    BaseStackComponent,
    BaseAvatarComponent,
    BaseDropdownComponent,
    BaseBoxComponent,
    BaseCardComponent,
    BaseCardSectionComponent,
    BaseTooltipComponent,
    BaseHelperTextComponent,
    BaseBannerComponent,
    BaseToastComponent,
    BaseTextComponent,
    BaseFiltersComponent,
    BaseTitleComponent,
    BaseInlineComponent,
    BaseAccordionComponent,
    BaseButtonComponent,
    BaseButtonGroupComponent,
    BaseChipComponent,
    BaseTabsComponent,
    BaseTabComponent,
    BaseIconComponent,
    ReceiptImageComponent,
    ConvertToDollarsPipe,
    ReceiptImageComponent,
    IconItemizeComponent,
    PaymentPipe,
    UserPopupComponent,
    ExpenseFiltersComponent,
    DatepickerComponent,
    DatepickerHeaderComponent,
    PageHeaderComponent,
    ApprovalsListComponent,
    TypeaheadComponent,
    ServerTypeaheadComponent,
    AdminUserAltEmailsComponent,
    AdminUserSummaryComponent,
    UserChipComponent,
    AdminUserProxyComponent,
    AltEmailModalComponent,
    AddProxyUserModalComponent,
    AltEmailFormComponent,
    AddProxyUserFormComponent,
    AddFeedUserFormComponent,
    AddFeedUserModalComponent,
    IconInfoCircleComponent,
    IconMileageExpenseComponent,
    MobileTabNavComponent,
    ExpenseMismatchAlertComponent,
    CreditCardHolderAlertComponent,
    CreditCardHolderAlertModalComponent,
    SubmittingCompanyCCExpensesAlertComponent,
    NoMatchAlertComponent,
    AlertBoxComponent,
    RecallExpenseAlertComponent,
    UnsavedChangesAlertComponent,
    DiscardChangesAlertComponent,
    StatusDisplayComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    NgSelectModule,
    RouterModule,
    SisModule,
  ],
})
export class ExpenseUiWebComponentsModule {}
