<ems-base-modal
  id="recall-expense-alert"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="cancel()"
>
  <sis-alert-box [includeCloseButton]="true" (onClose)="cancel()">
    <ng-container alert-icon>
      <ems-base-icon iconName="warning-filled" class="alert-icon"></ems-base-icon>
    </ng-container>
    <ng-container alert-title>
      <span class="alert-title regular-title">Recall Expense</span>
    </ng-container>
    <ng-container alert-message>
      <p class="alert-message">
        Recalling the expense will remove it from the approval queue and allow you to make edits.
        <br /><br />
        The expense will have to be resubmitted.
        <br /><br />
        How do you wish to continue?
      </p>

      <div class="modal-buttons">
        <button class="cancel-button" (click)="cancel()">
          Cancel
        </button>
        <button class="recall-button" (click)="recallExpense()">
          Recall
        </button>
      </div>
    </ng-container>
  </sis-alert-box>
</ems-base-modal>
