<ems-base-modal
  id="alt-email-modal"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onAltEmailFormCancel()"
>
  <ems-alt-email-form
    [userName]="userName"
    (cancel)="onAltEmailFormCancel()"
    (addEmail)="onAltEmailConfirm($event)"
  ></ems-alt-email-form>
</ems-base-modal>
