import { Injectable } from '@angular/core';
import {
  ApiService,
  BaseModalService,
} from '@ems-gui/expense/util-web-infrastructure';
import {
  Expense,
  EXPENSE_FILTERS,
  SORT_ORDER
} from "@ems-gui/shared/util-core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SnackbarService } from 'ngx-snackbar';
import { forkJoin, Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ActivityActions,
  ApprovalActions,
  LayoutActions,
  ReviewedActions,
  UserActions
} from "../../actions";
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import {
  selectApprovalSelectedIds,
  selectApprovalsFiltersState,
  selectApprovalStatus,
} from '../../selectors/approval.selectors';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { isLoggedInAsAnApprover } from '@ems-gui/expense/util-web-ngrx';

@Injectable()
export class ApprovalEffects {
  // get$ is the effect used to return pages of pending approvals' data both initial when the pending approvals view
  // loads and also when users utilize the paginator to view different pages of approvals

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.get),
      switchMap((res: any) =>
        this.apiService
          .getPendingApprovals(res.page, res.sort, res.filters)
          .pipe(
            map((approvals) => ApprovalActions.getComplete({ approvals })),
            catchError(errorHandler(ApprovalActions.getError))
          )
      )
    )
  );

  /**
   * Initial Trigger Of the modal.
   */
  selectExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.selectExpense),
      switchMap((res: any) =>
        this.apiService.getOneExpense(res.id, res.getExpenseType || null).pipe(
          map((expense: Expense) =>
          ApprovalActions.selectExpenseComplete(
            { expense: expense, uploadReceipt: res.uploadReceipt }
          )),
          catchError(errorHandler(ApprovalActions.getError))
        )
      ))
  );

  /**
   * This gets the activity for the Approval Modal.
   */
  getApprovalActivity$ = createEffect(
    () => this.actions$.pipe(
      ofType(ApprovalActions.selectExpenseComplete),
      map(
        ({ expense: { id } }) =>
        ActivityActions.getApprovalActivity({ id })
      )
    )
  );

  /**
   * This effect opens the Approval Modal.
   */
  selectExpenseComplete$ = createEffect(
    () => this.actions$.pipe(
      ofType(ApprovalActions.selectExpenseComplete),
      map(({ expense: { id } }) =>
        LayoutActions.openExpenseModal({ id, name: 'approval-modal' })
      )
    )
  );

  // applyFilter$ is handles the applyFilters action which is dispatched when users apply filters on the pending approvals view
  // and returns filtered pending approvals based on the current page, sort order, and populated filters

  applyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.applyFilters),
      switchMap((res: any) =>
        this.apiService
          .getPendingApprovals(res.page, res.sort, res.filters)
          .pipe(
            map((approvals) =>
              ApprovalActions.applyFiltersComplete({ approvals })
            ),
            catchError(errorHandler(ApprovalActions.applyFiltersError))
          )
      )
    )
  );

  getReviewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewedActions.getReviewedApprovals),
      switchMap((res: any) =>
        this.apiService
          .getReviewedApprovals(res.page, res.sort, res.filters)
          .pipe(
            map((approvals) =>
              ReviewedActions.getReviewedApprovalsComplete({ approvals })
            ),
            catchError(errorHandler(ReviewedActions.getReviewedApprovalsError))
          )
      )
    )
  );

  getFilteredReviewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.applyReviewedFilters),
      switchMap((res: any) =>
        this.apiService
          .getReviewedApprovals(res.page, res.sort, res.filters)
          .pipe(
            map((approvals) =>
              ApprovalActions.applyReviewedFiltersComplete({ approvals })
            ),
            catchError(errorHandler(ApprovalActions.applyReviewedFiltersError))
          )
      )
    )
  );

  homeLoadComplete$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(LayoutActions.homeLoadComplete),
        switchMap(() => {
          return forkJoin([
            this.apiService.getPendingApprovals(1, SORT_ORDER, EXPENSE_FILTERS),
            this.apiService.getReviewedApprovals(
              1,
              SORT_ORDER,
              EXPENSE_FILTERS
            ),
          ]).pipe(
            map(
              ([res1, res2]: any) =>
                ApprovalActions.getAllApprovalsComplete({
                  approvals: [...res1.expenses, ...res2.expenses],
                  counts: {
                    pending: res1.totalCount,
                    reviewed: res2.totalCount,
                  },
                }),
              catchError(errorHandler(ApprovalActions.getAllApprovalsError))
            )
          );
        })
      )
  );

  getAllApprovals$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(
          ApprovalActions.getAllApprovals,
          ApprovalActions.getUpdatedApprovals
        ),
        withLatestFrom(this.store$.pipe(select(selectApprovalsFiltersState))),
        switchMap(([, filters]: any) => {
          return forkJoin([
            this.apiService.getPendingApprovals(
              filters.pending.page,
              filters.pending.sort,
              filters.pending.filters
            ),
            this.apiService.getReviewedApprovals(
              filters.reviewed.page,
              filters.reviewed.sort,
              filters.reviewed.filters
            ),
          ]).pipe(
            map(
              ([res1, res2]: any) =>
                ApprovalActions.getAllApprovalsComplete({
                  approvals: [...res1.expenses, ...res2.expenses],
                  counts: {
                    pending: res1.totalCount,
                    reviewed: res2.totalCount,
                  },
                }),
              catchError(errorHandler(ApprovalActions.getAllApprovalsError))
            )
          );
        })
      )
  );

  // getExpenseCounts$ will retrieve the expense counts for users that are approvers and display
  // them on the dashboard view
  getExpenseCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.getExpenseCounts),
      switchMap(() =>
        this.apiService.getExpenseCounts().pipe(
          map((res) =>
            ApprovalActions.getExpenseCountsComplete({ counts: res })
          ),
          catchError(errorHandler(ApprovalActions.getExpenseCountsError))
        )
      )
    )
  );

  getLoginComplete$ = createEffect(
    () => (): Observable<any> =>
      this.actions$.pipe(
        ofType(UserActions.getPermissionsComplete),
        withLatestFrom(
          this.store$.pipe(select(isLoggedInAsAnApprover))
        ),
        filter(([action, approver]) => !!approver),
        switchMap(() =>
          this.apiService
            .getPendingApprovals(1, SORT_ORDER, EXPENSE_FILTERS)
            .pipe(
              map((approvals) => ApprovalActions.getComplete({ approvals })),
              catchError(errorHandler(ApprovalActions.getError))
            )
        )
      )
  );

  verify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.verifyExpenses),
      withLatestFrom(this.store$.select(selectApprovalSelectedIds)),
      switchMap(([action, reportIds]) =>
        this.apiService
          .verifyExpenses({
            expenses: reportIds,
            status: action.status,
            message: action.message,
          })
          .pipe(
            map((res) =>
              ApprovalActions.verifyExpensesComplete({ expenses: res })
            ),
            catchError(errorHandler(ApprovalActions.verifyExpensesError))
          )
      )
    )
  );

  displaySnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApprovalActions.verifyExpensesComplete),
        withLatestFrom(this.store$.select(selectApprovalStatus)),
        tap(([action, status]) => {
          let caption = '';
          if (action.expenses?.length === 1) {
            caption = 'Expense ';
          } else {
            caption = `${action.expenses.length} expenses `;
          }
          this.snackbarService.add({
            msg: caption + status,
            timeout: 10000,
            action: {
              text: 'Undo',
              onClick: ({ id }) => {
                this.snackbarService.remove(id);
                this.store$.dispatch(
                  ApprovalActions.undoVerifyExpenses({ status: '' })
                );
              },
            },
          });
        })
      ),
    { dispatch: false }
  );

  undoVerifyExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.undoVerifyExpenses),
      withLatestFrom(this.store$.select(selectApprovalSelectedIds)),
      switchMap(([action, reportIds]) =>
        this.apiService
          .undoVerifyExpenses({
            reports: reportIds,
          })
          .pipe(
            mergeMap((res) => [
              ApprovalActions.undoVerifyExpensesComplete({ expenses: res }),
            ]),
            catchError(errorHandler(ApprovalActions.undoVerifyExpensesError))
          )
      )
    )
  );

  verifyExpenseComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApprovalActions.verifyExpensesComplete),
        tap(() => {
          this.modalService.close('approval-modal', 'complete');
          this.modalService.close('reject-modal', 'complete');
        })
      ),
    { dispatch: false }
  );

  approvalEdit$ = createEffect(() => {
    const {
      approvalEdit,
      approvalEditComplete
    } = ApprovalActions;

    return this.actions$
      .pipe(
        ofType(ApprovalActions.approvalEdit),
        switchMap(
          ({ updates }) => this.apiService
            .approvalEdit(updates)
            .pipe(
              mergeMap((response) => [

                updates.length > 1
                  ? ApprovalActions.approvalsMultiEditComplete({ expenses: response })
                  : ApprovalActions.approvalEditComplete({ expense: response[0].expense }),
                  ActivityActions.getExpenseActivityComplete({activity: response[0].activity})
              ]),
              catchError((error) => {
                let errorMessage = 'Please inform Software Solutions';
                if (error instanceof HttpErrorResponse) {
                  // Try to use the error message from the response body
                  errorMessage = error.error.message || errorMessage;
                }
                return of(
                  LayoutActions.globalToastErrorMessage({
                    message: errorMessage
                  })
                );
              })
            )
        )
      )
  });

  approvalEditComplete$ = createEffect(() => this.actions$
    .pipe(
      ofType(ApprovalActions.approvalEditComplete),
      tap(({ expense }) => {
        this.modalService.close('send-to-modal');
        expense.status === 'approved' ?
          this.modalService.close('approval-modal') :
          this.modalService.open('approval-modal');
      }),
      switchMap(() => [
        ApprovalActions.getUpdatedApprovals(),
        ApprovalActions.getExpenseCounts()
      ])
    )
  );

  approvalsMultiEditComplete$ = createEffect(() => this.actions$
    .pipe(
      ofType(ApprovalActions.approvalsMultiEditComplete),
      tap(({ expenses }) => {
        this.modalService.close('send-to-modal');
      }),
      switchMap(() => [
        ApprovalActions.getUpdatedApprovals(),
        ApprovalActions.getExpenseCounts()
      ])
    )
  );

  rotateReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.rotateReceipt),
      switchMap((res: any) =>
        this.apiService
          .rotateReceiptApprover({ angle: res.angle, id: res.id })
          .pipe(
            map((expense: any) => {
              return ApprovalActions.rotateReceiptComplete({ expense });
            }),
            catchError(errorHandler(ApprovalActions.rotateReceiptError))
          )
      )
    )
  );

  removeReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApprovalActions.removeReceipt),
      switchMap(({ id }) =>
        this.apiService.removeReceipt(id).pipe(
          map((response) =>
            ApprovalActions.removeReceiptComplete({ expense: response })
          ),
          catchError(errorHandler(ApprovalActions.removeReceiptError))
        )
      )
    )
  );

  multipleReviewedActionsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApprovalActions.verifyExpensesComplete,
        ApprovalActions.undoVerifyExpensesComplete
      ),
      switchMap(() => [
        ApprovalActions.getExpenseCounts(),
        ApprovalActions.getUpdatedApprovals(),
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<fromDashboard.State>,
    private modalService: BaseModalService,
    private snackbarService: SnackbarService
  ) {}
}
