import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ems-expense-fraud-submitter-warn-dialog',
  templateUrl: './expense-fraud-submitter-warn-dialog.component.html',
  styleUrls: ['.//expense-fraud-submitter-warn-dialog.component.scss'],
})
export class ExpenseFraudSubmitterWarnDialogComponent implements OnInit {
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    return;
  }

  onContinue() {
    this.continue.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
