<div class="receipt-data-warn-modal">
  <header class="base-modal__header">
    <div class="base-modal__pre-header">
      <p class="base-modal__state-title">Warning</p>
    </div>

    <div class="base-modal__header-content">
      <span class="base-modal__title-icon"
        ><ems-icon-scan-failed></ems-icon-scan-failed
      ></span>
      <h1 class="base-modal__title">Scan Failed to Find Any Data</h1>
    </div>
  </header>

  <div class="receipt-data-error-modal__body">
    <p class="base-modal__subtitle">
      We could not capture any relevant data from the receipt. You can try
      uploading again or continue to enter the data manually yourself.
    </p>
    <div class="button-align">
      <ems-base-button
        (click)="onContinue()"
        label="Continue"
        buttonType="primary"
        variant="success"
      ></ems-base-button>
    </div>
  </div>
</div>
