import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-admin-user-proxy',
  templateUrl: './admin-user-proxy.component.html',
  styleUrls: ['./admin-user-proxy.component.scss'],
})
export class AdminUserProxyComponent implements OnInit, OnDestroy {
  @Input() favoritePersons = [];
  @Input() proxies = [];
  @Input() subtitle = '';
  @Input() verticalLayout = false;
  @Output() favorite: EventEmitter<any> = new EventEmitter<any>();
  @Output() addProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicateProxy = new EventEmitter();
  @Output() modalOpened: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed = new EventEmitter();
  proxyFormControl = new FormControl(null);
  selectedUser;
  unsubscribe$: Subject<void>;
  formIsActive = false;
  @ViewChild('proxy', { static: false }) proxy;

  constructor() {
    this.unsubscribe$ = new Subject<void>();
  }

  ngOnInit() {
    this.proxyFormControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((id) => (this.selectedUser = id))
      )
      .subscribe();
  }

  toggleAddProxy() {
    this.formIsActive = !this.formIsActive;

    if (this.formIsActive) {
      this.modalOpened.emit('add-proxy-user-modal');
    }
  }

  onPersonFavorite(id) {
    this.favorite.emit(id);
  }

  onAddProxy(id) {
    const proxy = this.proxies.find((p) => p.proxy.id === +id);
    const isProxy = proxy ? true : false;
    if (!this.proxies.length || !isProxy) {
      this.addProxy.emit(id);
    } else {
      this.duplicateProxy.emit();
    }
    this.formIsActive = false;
    this.modalClosed.emit();
  }

  onRemoveProxy(id) {
    this.removeProxy.emit(id);
  }

  onCancel() {
    this.formIsActive = false;
    this.modalClosed.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
