import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  FavoriteJobCodeActions,
  FavoriteSalesforceCaseActions,
  LayoutActions,
  NewActions,
  selectAllExpenseTypesExceptMileage,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectJobCodesForDraftDropdown,
  selectAllSalesforceCasesWithGroupLabels,
  selectAutoSavedExpense,
  selectAutoSavedExpenseId,
  selectedProxyId,
  selectExpenseItemizationStatus,
  selectModalDismiss,
  selectNewExpenseAutoSaving,
  selectReceiptPhoto,
  State,
  selectCurrentExpenseId,
  selectCurrentlyLoading,
  selectCurrentModal,
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  JobCode,
  ModalInput,
  OnChange,
  SalesforceCase,
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  Subject,
  withLatestFrom,
} from 'rxjs';
import { map, share, take, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from '@ems-gui/expense/util-web-infrastructure';
import moment, { MomentInput } from 'moment';
import { CloseRequest } from '@ems-gui/expense/ui-web-components';
import { AlertService } from '@src/app/services/alert.service';
import { FormManagerService } from '@src/app/services/form-manager.service';
import exp from 'constants';

const MODAL_ID = 'expense-new-modal';

@Component({
  selector: 'ems-expense-new-modal',
  templateUrl: './expense-new-modal.component.html',
  styleUrls: ['./expense-new-modal.component.scss'],
})
export class ExpenseNewModalComponent implements OnInit, OnDestroy {
  public formId = MODAL_ID;
  public modalType = this.store$.select(selectCurrentModal);
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject(
    this.createNewModalInput()
  );
  isLoading$ = this.store$.select(selectCurrentlyLoading);
  modalInput$ = this.modalInputSubject.asObservable();
  form;
  amountStatus;
  autoSavedExpenseId$: Observable<number | string>;
  autoSavedExpense$: Observable<Expense>;
  newExpenseAutoSaving$: Observable<boolean>;
  dismiss$: Observable<boolean>;
  jobCodes$: Observable<JobCode[]>;
  expenseTypes: ExpenseType[];
  favoriteJobCodes$: Observable<any>;
  itemizationStatus$: Observable<any>;
  receipt$: Observable<any>;
  proxy$: Observable<any>;
  salesforceCases$: Observable<Partial<SalesforceCase>[]>;
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;
  statusNewSubject$: Subject<string> = new Subject();
  statusNew$: Observable<string> = this.statusNewSubject$.asObservable();
  unsubscribe$: Subject<void> = new Subject();
  receiptAttached = null;
  @ViewChild('modal', { static: false }) modal: ViewContainerRef;
  expenseStatus = null;
  isCompanyCc = false;
  isForeignTransactionFee = false;
  isFraud = false;
  submittedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  submitted$: Observable<boolean> = this.submittedSubject$.asObservable();
  formSubject$: BehaviorSubject<Partial<Expense>> = new BehaviorSubject({});
  form$: Observable<Partial<Expense>> = this.formSubject$
    .asObservable()
    .pipe(share());
  file: File;
  rotationAngle: number;
  type: string = 'personal';

  public currentExpenseId$ = this.store$.select(selectCurrentExpenseId);
  public saveAndCloseButtonDisabled: Set<string> = new Set();
  public isSubmitVisible = false;
  @OnChange(function (this: ExpenseNewModalComponent) {
    if (this.modalInputSubject) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const modalInput = this.createNewModalInput({
        title: this.formTitle,
      });
      this.modalInputSubject.next(modalInput);
    }
  })
  @Input()
  formTitle = 'Title Loading...';
  constructor(
    private alertService: AlertService,
    public modalService: BaseModalService,
    private store$: Store<State>,
    private toastrService: ToastrService,
    private utilsService: UtilsService,
    private formManager: FormManagerService
  ) {
    this.autoSavedExpenseId$ = this.store$.pipe(
      select(selectAutoSavedExpenseId)
    );
    this.autoSavedExpense$ = this.store$.pipe(select(selectAutoSavedExpense));
    this.newExpenseAutoSaving$ = this.store$.pipe(
      select(selectNewExpenseAutoSaving)
    );
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
    this.jobCodes$ = this.store$.pipe(select(selectJobCodesForDraftDropdown));
    this.store$
      .pipe(
        select(selectAllExpenseTypesExceptMileage),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((expenseTypes) => {
        this.expenseTypes = expenseTypes;
      });
    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );
    this.salesforceCases$ = this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels)
    );
    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.itemizationStatus$ = this.store$.pipe(
      select(selectExpenseItemizationStatus)
    );
    this.receipt$ = this.store$.pipe(select(selectReceiptPhoto));
    this.proxy$ = this.store$.pipe(select(selectedProxyId));
  }

  ngOnInit() {
    // This was added to ensure it only loads once after form registers.
    this.modalType
      .pipe(takeUntil(this.unsubscribe$), tap((type) => {
        if (type === 'expense-new-modal-company-cc') {
          this.type = 'user';
        }
      })).subscribe();
    const callbackUnsubscribe = new Subject<void>();
    this.formManager
      .getFormManager$(this.formId)
      .pipe(
        takeUntil(callbackUnsubscribe),
        tap((fm) => {
          if (!fm) return;
          fm.callback('save', () => this.saveForm(false)).callback(
            'saveAndClose',
            () => this.saveAndClose()
          );
          callbackUnsubscribe.next();
          callbackUnsubscribe.complete();
        })
      )
      .subscribe();

    combineLatest([this.autoSavedExpense$, this.statusNew$])
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(([expense, status]) => {
          this.isCompanyCc = expense && expense.paymentType === 'company';
          this.isForeignTransactionFee =
            this.utilsService.checkIfForeignTransactionType(
              expense,
              this.expenseTypes
            );
          const currentModal = this.modalInputSubject.getValue();
          this.modalInputSubject.next({
            ...currentModal,
            subtitle: expense?.id ? `${expense.id}` : 'New',
            status:
              status && (this.receiptAttached || this.isForeignTransactionFee)
                ? 'positive'
                : '',
            // statusText: setStatusText(this.isCompanyCc, status),
            fieldsComplete:
              status &&
              (this.isFraud ||
                this.receiptAttached ||
                this.isForeignTransactionFee)
                ? true
                : false,
          });
          this.expenseStatus = status;
        })
      )
      .subscribe();

    this.isLoading$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((isLoading) => {
          if (!isLoading) this.saveAndCloseButtonDisabled.add('isLoading');
          else if (this.saveAndCloseButtonDisabled.has('isLoading'))
            this.saveAndCloseButtonDisabled.delete('isLoading');
        })
      )
      .subscribe();

    this.newExpenseAutoSaving$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((bool) => {
          const currentModal = this.modalInputSubject.getValue();
          if (bool === true) {
            this.modalInputSubject.next({
              ...currentModal,
              caption: 'Saving...',
            });
          } else if (bool === false) {
            this.modalInputSubject.next({
              ...currentModal,
              caption: 'All Changes saved',
            });
          } else {
            this.modalInputSubject.next({
              ...currentModal,
              caption: '',
            });
          }
        })
      )
      .subscribe();
  }

  onDismiss(closeRequest: CloseRequest) {
    if (!this.form || !Object.keys(this.form).length) {
      this.store$.dispatch(LayoutActions.dismissNewExpenseModal());
      return;
    }

    closeRequest.isClosing = false;
    this.alertService.getUnsavedChangesAlert('expense-new-modal', () =>
      this.saveForm(true)
    );
  }

  // new expense form status updates
  onNewFormChange(value: Partial<Expense>) {
    this.form = { ...value };
    if (Object.keys(this.form).length > 0)
      this.saveAndCloseButtonDisabled.add('changes');
    else if (this.saveAndCloseButtonDisabled.has('changes'))
      this.saveAndCloseButtonDisabled.delete('changes');

    this.isSubmitVisible =
      +this.form.type === 18 && this.form.fraudulent === true;
  }

  /**
   * TODO:
   * 1. make submit pass a form, make it only submittable if its valid.
   * 2. remove this.form and this.isNewValid
   */
  onSubmit() {
    this.submittedSubject$.next(true);
    // expense-new-modal only takes a string not an id
    if (this.form && this.expenseStatus) {
      if (this.isCompanyCc) {
        this.toastrService.show(
          'Company Credit Card expenses cannot be submitted until verified.',
          '',
          { timeOut: 10000 }
        );
      } else if (
        !this.receiptAttached &&
        !this.isForeignTransactionFee &&
        !this.isFraud
      ) {
        this.toastrService.show('A receipt is required for this expense.', '', {
          timeOut: 10000,
        });
      } else if (!this.form.type || !this.form.jobCode) {
        this.toastrService.show('An expense type or job code is required for this expense', '', {
          timeOut: 10000,
        });
      } else {
        const payload = {
          expense: this.form,
          file: this.file,
          rotationAngle: this.rotationAngle
        }
        this.store$.dispatch(NewActions.submit(payload));
        this.modalService.close('expense-new-modal');
      }
    }
  }

  /**
   * Handles saving a company credit card expense
   */
  saveCompanyCreditCardExpense() {
    // In this case the expense is not going to be submitted, but the
    // since the submmited flag needs to be set to true in order to
    // show any error messages, we are setting it to true here.
    this.submittedSubject$.next(true);
    if (this.form && this.expenseStatus) {
      if (!this.receiptAttached &&
        !this.isForeignTransactionFee &&
        !this.isFraud
      ) {
        this.toastrService.show('A receipt is required for this expense.', '', {
          timeOut: 10000,
        });
        return;
      }
      this.saveAndClose();
    }
  }

  onRemoveReceipt() {
    this.receiptAttached = false;
  }

  onItemizeExpense() {
    if (this.form.paymentType !== 'company') {
      this.store$.dispatch(NewActions.unableToItemize());
      const currentModal = this.modalInputSubject.getValue();
      this.modalInputSubject.next({
        ...currentModal,
        scrollToBottom: true,
      });
    } else {
      this.toastrService.show(
        'Only verified Company Credit Card expenses can be itemized.',
        '',
        { timeOut: 10000 }
      );
    }
  }

  protected dateIsBeforeToday(date?: MomentInput) {
    const mDate = moment(date);
    if (!mDate.isValid()) return false;
    return !moment(moment(new Date()).format('MM/DD/YYYY')).isSameOrBefore(
      mDate
    );
  }

  public get newExpenseValues() {
    const changes: Partial<Expense> = {};
    const currentForm: Partial<Expense> = { ...this.form };
    for (const [key, value] of Object.entries(
      currentForm
    ) as Expense[keyof Expense][]) {
      switch (key) {
        case 'vendor':
        case 'address':
        case 'amount':
        case 'type':
        case 'jobCode':
        case 'description':
        case 'message':
        case 'fraudulent':
        case 'token':
          if (value) changes[key] = value;
          break;
        case 'isBillable':
          if (value !== null) changes[key] = value;
          break;
        case 'transactionDate':
          if (this.dateIsBeforeToday(value))
            changes[key] = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSZ');
          break;
        case 'salesforceId':
          if (value !== '0') changes[key] = value;
      }
    }
    return changes;
  }

  protected getCurrentForm() {
    const newExpense = this.newExpenseValues;
    if (Object.keys(newExpense).length <= 0) return;

    return <Partial<Expense>>{
      fraudulent: false,
      paymentType: this.type,
      salesforceId: 0,
      ...newExpense,
    };
  }


  protected saveExpense() {
    const formattedExpense = this.getCurrentForm();
    const payload = {
      expense: formattedExpense,
      file: this.file,
      rotationAngle: this.rotationAngle
    }
    this.store$.dispatch(NewActions.createNewExpense(payload));
  }

  public isClickable(el: HTMLButtonElement) {
    return el.classList.contains('is-disabled');
  }

  public onSave($event: MouseEvent) {
    if (this.isClickable(<HTMLButtonElement>$event.target)) return;

    this.saveForm(false);
  }

  public onSaveAndClose($event: MouseEvent) {
    if (this.isClickable(<HTMLButtonElement>$event.target)) return;

    this.saveAndClose();
  }

  public closeAfterSaving() {
    this.isLoading$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((isLoading) => {
          if (isLoading) return;
          this.modalService.close('expense-new-modal');
        })
      )
      .subscribe();
  }

  public saveForm(isClosingAfter = false) {
    this.formManager
      .updatedFields$(this.formId)
      .pipe(
        take(1),
        withLatestFrom(this.currentExpenseId$),
        tap(([form, id]) => {
          if (Object.keys(form).length === 0) return;
          this.saveExpense();
        }),
        tap(() => {
          if (isClosingAfter) this.closeAfterSaving();
        })
      )
      .subscribe();
  }

  public saveAndClose() {
    this.saveForm(true);
  }

  onTrashAutoSaved() {
    this.store$.dispatch(NewActions.trashAutoSaved());
  }

  onNewStatusChange(value) {
    this.statusNewSubject$.next(value);
  }

  onAmountStatusChange() {
    this.store$.dispatch(NewActions.unableToItemizeReset());
  }

  onFavoriteJobCode(id) {
    this.store$.dispatch(FavoriteJobCodeActions.saveOneFavorite({ id }));
  }

  onFavoriteSalesforceCase(id) {
    this.store$.dispatch(
      FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase({ id })
    );
  }

  onReceiptData(receiptDetails) {
    this.file = receiptDetails.file;
    this.rotationAngle = receiptDetails.rotationAngle;
    this.receiptAttached = true;
  }

  onFileUploadSizeError() {
    this.toastrService.show('Receipt file size cannot exceed 10MB.', '', {
      timeOut: 10000,
    });
  }

  onFraudulentExpense(isFraud) {
    this.isFraud = isFraud;
    this.formSubject$
      .pipe(
        take(1),
        tap((form) => {
          const expense = {
            ...form,
            fraudulent: isFraud,
          };
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.toastrService.clear();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.formManager.deregister(this.formId);
  }

  createNewModalInput(formModal?: Partial<ModalInput>) {
    return <ModalInput>{
      ...{
        title: 'Out of Pocket Expense: ',
        label: '',
        labelClass: '',
        caption: '',
        status: '',
        subtitle: 'New',
        fieldsComplete: false,
        modalId: MODAL_ID,
        scrollToBottom: false,
      },
      ...formModal,
    };
  }
}
