export const addVendor = (details) => {
  if (
    details['CardAcceptorName'] ||
    details['CardAcceptorStreetAddress'] ||
    details['CardAcceptorCity'] ||
    details['CardAcceptorCountryCode'] ||
    details['CardAcceptorStateProvince'] ||
    details['CardAcceptorLocationPostalCode'] ||
    details['CardAcceptorTelephoneNum']
  ) {
    return {
      Name: details['CardAcceptorName'],
      Address: details['CardAcceptorStreetAddress'],
      City: details['CardAcceptorCity'],
      State: details['CardAcceptorStateProvince'],
      Zip: details['CardAcceptorLocationPostalCode'],
      Country: details['CardAcceptorCountryCode'],
      Phone: details['CardAcceptorTelephoneNum'],
    };
  }
};

export const addFlight = (details) => {
  if (
    details['TravelDate'] ||
    details['CityOfOrigin'] ||
    details['CityOfDestination'] ||
    details['PassengerName'] ||
    details['TicketNum'] ||
    details['EgenciaItineraryNum'] || // property name TBD
    details['Type'] // property name TBD
  ) {
    return {
      'Travel Date': details['TravelDate'],
      Origin: details['CityOfOrigin'],
      Destination: details['CityOfDestination'],
      'Passenger Name': details['PassengerName'],
      'Ticket Number': details['TicketNum'],
      'Egencia Itinerary Number': details['EgenciaItineraryNum'],
      Type: details['Type'],
    };
  }
};

export const addShipping = (details) => {
  if (
    details['CardAcceptorReferenceNum'] ||
    details['DestinationPostalCode'] ||
    details['DestinationCountryCode'] ||
    details['ShipFromPostalCode']
  ) {
    return {
      'Tracking Number': details['CardAcceptorReferenceNum'],
      'Ship to Zip': details['DestinationPostalCode'],
      'Ship to Country': details['DestinationCountryCode'],
      'Ship from Zip': details['ShipFromPostalCode'],
    };
  }
};

export const addLodging = (details) => {
  if (
    details['ArrivalDate'] ||
    details['DepartureDate'] ||
    details['TotalRoomNights'] ||
    details['TravelerName']
  ) {
    return {
      'Arrival Date': details['ArrivalDate'],
      'Departure Date': details['DepartureDate'],
      'Total Nights': details['TotalRoomNights'],
      'Traveler Name': details['TravelerName'],
    };
  }
};

export const addVehicle = (details) => {
  if (
    details['RenterName'] ||
    details['RentalAgreementNum'] ||
    details['CheckOutDate'] ||
    details['RentalReturnDate'] ||
    details['RentalReturnCity'] ||
    details['RentalReturnStateProvince'] ||
    details['RentalReturnCountry'] ||
    details['ReturnLocation'] || // property name TBD
    details['RentalClass'] ||
    details['TollDate'] // property name TBD
  ) {
    return {
      'Renter Name': details['RenterName'],
      'Rental Agreement Number': details['RentalAgreementNum'],
      'Check Out Date': details['CheckOutDate'],
      'Return Date': details['RentalReturnDate'],
      'Return City': details['RentalReturnCity'],
      'Return State': details['RentalReturnStateProvince'],
      'Return Country': details['RentalReturnCountry'],
      'Return Location': details['ReturnLocation'],
      'Rental Class': details['RentalClass'],
      'Toll Date': details['TollDate'],
    };
  }
};

export const addRideShare = (details) => {
  if (
    details['TravelerName'] || // property name TBD
    details['TipAmount'] || // property name TBD
    details['TotalAmount'] // property name TBD
  ) {
    return {
      'Traveler Name': details['TravelerName'],
      'Tip Amount': details['TipAmount'],
      'Total Amount': details['TotalAmount'],
    };
  }
};
