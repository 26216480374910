import { Component } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { ReceiptManagerActions, State } from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ems-receipt-manager-error-modal',
  templateUrl: './receipt-manager-error-modal.component.html',
  styleUrls: ['./receipt-manager-error-modal.component.scss'],
})
export class ReceiptManagerErrorModalComponent {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'receipt-manager-error-modal',
  };

  constructor(
    private store$: Store<State>,
    private modalService: BaseModalService
  ) {}

  onContinue() {
    this.modalService.close('receipt-manager-error-modal');
  }

  onDownloadCsv() {
    this.store$.dispatch(ReceiptManagerActions.downloadErrorCsv());
    this.modalService.close('receipt-manager-error-modal');
  }
}
