import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  selectSearchedPersonsWithGroupLabels,
  PersonActions,
  State
} from '@ems-gui/expense/util-web-ngrx';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ems-add-proxy-user-form',
  templateUrl: './add-proxy-user-form.component.html',
  styleUrls: ['./add-proxy-user-form.component.scss'],
})
export class AddProxyUserFormComponent {
  @Input() subtitle;
  @Input() favoritePersons = [];
  @Output() addProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() favorite: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('proxy', { static: false }) proxy;
  persons$: Observable<any>;
  proxyFormControl = new FormControl(null, {
    validators: [Validators.required],
  });

  constructor(private store$: Store<State>) {
    this.persons$ = this.store$.pipe(
      select(selectSearchedPersonsWithGroupLabels)
    );
  }

  onConfirmClick() {
    if (this.proxyFormControl.valid) {
      const proxy = this.proxyFormControl.value;
      this.addProxy.emit(proxy);
    }
  }

  onCancelClick() {
    this.cancelProxy.emit();
  }

  onPersonFavorite(id) {
    this.favorite.emit(id);
  }
  onResetPersons(){
    this.store$.dispatch(PersonActions.resetSearchedPersons());
  }
  onSearchPerson(searchParams){
    this.store$.dispatch(PersonActions.getSearchedPersons(searchParams));
  }
}
