import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-receipt-manager-warn-dialog',
  templateUrl: './receipt-manager-warn-dialog.component.html',
  styleUrls: ['./receipt-manager-warn-dialog.component.scss'],
})
export class ReceiptManagerWarnDialogComponent implements OnInit {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadCsv: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadFile: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    return;
  }

  onCancel() {
    this.cancel.emit();
  }

  onDownloadCsv() {
    this.downloadCsv.emit();
  }

  onDownloadFile() {
    this.downloadFile.emit();
  }
}
