<sis-alert-box>
  <ng-container alert-icon>
    <ems-base-icon iconName="warning-filled" class="alert-icon"></ems-base-icon>
  </ng-container>
  <ng-container alert-title>
    <span class="alert-title regular-title" [attr.aria-hidden]="alertToggle">
      <span
        *ngIf="alertToggle"
        class="desktop-collapsed-title-prefix"
      >Remember: </span>
      You have a company Credit Card
    </span>
    <span class="alert-title collapsed-mobile-title" [attr.aria-hidden]="alertToggle">
      You have a company Credit Card
    </span>
    <button
      *ngIf="!isModal"
      class="tuggle-button see-more-btn"
      (click)="alertToggle = !alertToggle"
      [attr.aria-hidden]="!alertToggle"
      [hidden]="!alertToggle"
    >
      See more...
    </button>
  </ng-container>

  <ng-container alert-message>
    <div class="collapse-container" [attr.aria-hidden]="alertToggle">
      <div class="collapse-content">
        <p
          class="alert-message"
          [attr.aria-hidden]="alertToggle"
        >
          Charges made on this company card will be automatically added to Spense within 3-5 business days.
          <br /><br />
          Please wait for your company credit card expenses to appear in your
          <a
            class="redirect-link"
            (click)="navigateToUnsubmitted()"
          >unsubmitted expenses tab</a> in Spense.
          <br /><br />
          Only continue with creating an out-of-pocket expense if you paid using a personal credit card or cash.
        </p>
        <button
          *ngIf="!isModal"
          class="tuggle-button"
          (click)="alertToggle = !alertToggle"
          [attr.aria-hidden]="alertToggle"
        >
          Hide
        </button>
      </div>
    </div>
    <div
      *ngIf="isModal"
      class="modal-buttons"
    >
      <button
        class="continue-button"
        (click)="continue()"
        >
        Continue
      </button>
      <button
        class="cancel-button"
        (click)="cancel()"
      >
        Cancel, this was a company credit card expense
      </button>
    </div>
  </ng-container>
</sis-alert-box>
