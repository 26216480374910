import { Component, Input } from '@angular/core';

@Component({
  selector: 'ems-base-text-style',
  templateUrl: './base-text-style.component.html',
  styleUrls: ['./base-text-style.component.scss'],
})
export class BaseTextStyleComponent {
  /**
   * Visual style to apply to a string of text
   *
   * Accepts `positive`, `negative`, `warning`, `muted`, `disabled`, `strong`
   */
  @Input() variation = 'muted';

  /**
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-positive': this.variation === 'positive',
      'is-negative': this.variation === 'negative',
      'is-muted': this.variation === 'muted',
      'is-disabled': this.variation === 'disabled',
      'is-strong': this.variation === 'strong',
      'is-warning': this.variation === 'warning',
      'is-subtitle': this.variation === 'subtitle',
      'is-light': this.variation === 'light',
    };
  }
}
