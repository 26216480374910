import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ems-admin-user-alt-emails',
  templateUrl: './admin-user-alt-emails.component.html',
  styleUrls: ['./admin-user-alt-emails.component.scss'],
})
export class AdminUserAltEmailsComponent {
  @Input() alternateEmails = [];
  @Input() userName = 'this user';
  @Input() subtitle = '';
  @Output() duplicateEmail = new EventEmitter();
  email = new FormControl('', {
    validators: [Validators.required, Validators.email],
  });

  /**
   * Form State
   * Accepts 'true', false
   * @param {string} state - Sets the form drawer open/close state
   * @default false
   */
  @Input() isActive = false;

  @Input() enableNotifications = true;

  @Output() dismiss = new EventEmitter();
  @Output() emailNotificationChange = new EventEmitter();
  @Output() addEmail = new EventEmitter();
  @Output() modalOpened: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed = new EventEmitter();

  formIsActive = false;

  toggleAddEmail() {
    this.formIsActive = !this.formIsActive;
    if (this.formIsActive) {
      this.modalOpened.emit('add-alt-email');
    }
  }

  onDismiss(email) {
    this.dismiss.emit(email);
  }

  onEmailNotificationChange(email, event) {
    let notificationUpdate;
    if (event.target.checked) {
      notificationUpdate = {
        ...email,
        enabled: true,
      };
    } else {
      notificationUpdate = {
        ...email,
        enabled: false,
      };
    }
    if (notificationUpdate) {
      this.emailNotificationChange.emit(notificationUpdate);
    }
  }

  cancelEmail() {
    this.formIsActive = false;
    this.modalClosed.emit();
  }

  onAddEmail(email) {
    const altEmail = this.alternateEmails.find((a) => a.email === email);
    const isAltEmail = altEmail ? true : false;
    if (!this.alternateEmails.length || !isAltEmail) {
      this.addEmail.emit(email);
    } else {
      this.duplicateEmail.emit();
    }
    this.formIsActive = false;
    this.modalClosed.emit();
  }
}
