import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-banner',
  templateUrl: './base-banner.component.html',
  styleUrls: ['./base-banner.component.scss'],
})
export class BaseBannerComponent implements OnInit {
  /**
   * Banner Content
   * @param {string} content - Text to display in banner
   * @default ''
   */
  @Input() content = '';

  constructor() {}

  ngOnInit() {}
}
