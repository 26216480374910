import { createReducer, on } from '@ngrx/store';
import {
  DraftActions,
  LayoutActions,
  MileageDraftActions,
  NewActions,
  NewMileageActions,
} from '../actions';

export interface State {
  homeLoaded: boolean;
  openModalId: number;
  openModalName: string;
  dismissModal: boolean;
  displaySnackbar: boolean;
  draftExpenseScrollPosition: any;
  draftExpenseResetForm: any;
  newExpenseScrollPosition: any;
  newExpenseResetForm: boolean;
  newMileageExpenseResetForm: boolean;
  draftMileageExpenseResetForm: boolean;
  error: string;
}

export const initialState: State = {
  homeLoaded: false,
  openModalId: null,
  openModalName: '',
  dismissModal: false,
  displaySnackbar: false,
  draftExpenseScrollPosition: null,
  draftExpenseResetForm: null,
  newExpenseScrollPosition: null,
  newExpenseResetForm: null,
  newMileageExpenseResetForm: null,
  draftMileageExpenseResetForm: null,
  error: '',
};

export const reducer = createReducer(
  initialState,
  on(
    LayoutActions.openExpenseModal,
    LayoutActions.openFeedItemModal,
    (state, { id, name }) => ({
      ...state,
      openModalId: id,
      openModalName: name,
    })
  ),
  on(LayoutActions.modalOpened, (state, { name }) => ({
    ...state,
    openModalName: name,
  })),
  on(
    LayoutActions.dismissModal,
    LayoutActions.dismissNewExpenseModal,
    LayoutActions.dismissFeedItemModal,
    (state) => ({
      ...state,
      dismissModal: true,
      openModalName: '',
    })
  ),
  on(
    LayoutActions.resetDismissModal,
    LayoutActions.resetDismissNewExpenseModal,
    (state) => ({
      ...state,
      dismissModal: false,
    })
  ),
  on(LayoutActions.openSidenav, (state) => ({
    ...state,
  })),
  on(LayoutActions.closeSidenav, (state) => ({
    ...state,
  })),
  on(LayoutActions.displaySnackbar, (state, { display }) => ({
    ...state,
    displaySnackbar: display,
  })),
  on(DraftActions.saveScrollPosition, (state, { position }) => ({
    ...state,
    draftExpenseScrollPosition: position,
  })),
  on(DraftActions.resetForm, (state) => ({
    ...state,
    draftExpenseResetForm: true,
  })),

  on(DraftActions.resetFormComplete, (state) => ({
    ...state,
    draftExpenseResetForm: false,
  })),
  on(NewActions.saveScrollPosition, (state, { position }) => ({
    ...state,
    newExpenseScrollPosition: position,
  })),
  on(NewActions.resetForm, (state) => ({
    ...state,
    newExpenseResetForm: true,
  })),

  on(NewActions.resetFormComplete, (state) => ({
    ...state,
    newExpenseResetForm: false,
  })),
  on(NewMileageActions.resetForm, (state) => ({
    ...state,
    newMileageExpenseResetForm: true,
  })),
  on(NewMileageActions.resetFormComplete, (state) => ({
    ...state,
    newMileageExpenseResetForm: false,
  })),
  on(MileageDraftActions.resetForm, (state) => ({
    ...state,
    draftMileageExpenseResetForm: true,
  })),
  on(MileageDraftActions.resetFormComplete, (state) => ({
    ...state,
    draftMileageExpenseResetForm: false,
  })),
  on(LayoutActions.homeLoadComplete, (state) => ({
    ...state,
    homeLoaded: true,
  })),
  on(LayoutActions.homeLoadError, (state, { error }) => ({
    ...state,
    homeLoaded: true,
    error,
  }))
);

export const getOpenModalId = (state: State) => state.openModalId;
export const getOpenModalName = (state: State) => state.openModalName;
export const getDismissModal = (state: State) => state.dismissModal;
export const displaySnackbar = (state: State) => state.displaySnackbar;
export const newExpenseScrollPosition = (state: State) =>
  state.newExpenseScrollPosition;
export const draftExpenseScrollPosition = (state: State) =>
  state.draftExpenseScrollPosition;
export const draftExpenseResetForm = (state: State) =>
  state.draftExpenseResetForm;
export const newExpenseResetForm = (state: State) => state.newExpenseResetForm;
export const newMileageExpenseResetForm = (state: State) =>
  state.newMileageExpenseResetForm;
export const draftMileageExpenseResetForm = (state: State) =>
  state.draftMileageExpenseResetForm;
export const getHomeLoaded = (state: State) => state.homeLoaded;
