import { createSelector } from '@ngrx/store';
import * as fromProxiedUser from '../reducers/proxied-user.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardProxiedUserState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.proxiedUser
);

export const {
  selectIds: selectProxiedUserIds,
  selectEntities: selectProxiedUserEntities,
  selectAll: selectAllProxiedUsers,
} = fromProxiedUser.adapter.getSelectors(selectDashboardProxiedUserState);

export const selectAllProxiedUsersFlat = createSelector(
  selectAllProxiedUsers,
  (users) => users.map((u) => u.user)
);
