import {
  AdminFeedFilter,
  TransactionFeedItem,
} from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const get = createAction(
  '[Transaction Feed Item] get',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: AdminFeedFilter;
  }>()
);

export const getComplete = createAction(
  '[Transaction Feed Item] getComplete',
  props<{ results: TransactionFeedItem[]; totalCount: number }>()
);

export const getError = createAction(
  '[Transaction Feed Item] getError',
  props<{ error: any }>()
);

export const getFiltered = createAction(
  '[Transaction Feed Item] getFiltered',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: AdminFeedFilter;
  }>()
);

export const getFilteredComplete = createAction(
  '[Transaction Feed Item] getFilteredComplete',
  props<{
    results: TransactionFeedItem[];
    totalCount: number;
  }>()
);

export const getFilteredError = createAction(
  '[Transaction Feed Item] getFilteredError',
  props<{ error: string }>()
);

export const resetFilters = createAction(
  '[Transaction Feed Item] resetFilters'
);

export const getFeedItemDownload = createAction(
  '[Transaction Feed Item] getFeedItemDownload',
  props<{ id: number }>()
);

export const getFeedItemDownloadComplete = createAction(
  '[Transaction Feed Item] getFeedItemDownloadComplete',
  props<{ url: string }>()
);

export const getFeedItemDownloadError = createAction(
  '[Transaction Feed Item] getFeedItemDownloadError',
  props<{ error: string }>()
);

export const downloadFeedItemFile = createAction(
  '[Transaction Feed Item] downloadFeedItemFile',
  props<{ url: string }>()
);

export const downloadFeedItemFileComplete = createAction(
  '[Transaction Feed Item] downloadFeedItemFileComplete',
  props<{ file: any }>()
);

export const downloadFeedItemFileError = createAction(
  '[Transaction Feed Item] downloadFeedItemFileError',
  props<{ error: string }>()
);
