import { Activity } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getUnsubmittedActivity = createAction(
  '[Expense - Activity] getUnsubmittedActivity',
  props<{ id: number }>()
);

export const getUnsubmittedActivityComplete = createAction(
  '[Expense - Activity] getUnsubmittedActivityComplete',
  props<{ activity: Activity[], openModal?: boolean}>()
);

export const getUnsubmittedActivityError = createAction(
  '[Expense - Activity] getUnsubmittedActivityError',
  props<{ error: string }>()
);

export const getMileageActivityComplete = createAction(
  '[Expense - Activity] getMileageActivityComplete',
  props<{ activity: Activity[] }>()
);

export const getMileageActivityError = createAction(
  '[Expense - Activity] getMileageActivityError',
  props<{ error: string }>()
);

export const getSubmittedActivity = createAction(
  '[Expense - Activity] getSubmittedActivity',
  props<{ id: number }>()
);

export const getSubmittedActivityComplete = createAction(
  '[Expense - Activity] getSubmittedActivityComplete',
  props<{ activity: Activity[] }>()
);

export const getSubmittedActivityError = createAction(
  '[Expense - Activity] getSubmittedActivityError',
  props<{ error: string }>()
);

export const getApprovalActivity = createAction(
  '[Expense - Activity] getApprovalActivity',
  props<{ id: number }>()
);

export const getTrashedActivity = createAction(
  '[Expense - Activity] getTrashedActivity',
  props<{ id: number }>()
);

export const getExpenseActivityComplete = createAction(
  '[Expense - Activity] getExpenseActivityComplete',
  props<{ activity: Activity[] }>()
);

export const getExpenseActivityError = createAction(
  '[Expense - Activity] getExpenseActivityError',
  props<{ error: string }>()
);

export const getNewExpenseActivityId = createAction(
  '[Expense - Activity] getNewExpenseActivityId',
  props<{ id: number }>()
);

export const getDraftActivityId = createAction(
  '[Expense - Activity] getDraftActivityId',
  props<{ activity: any }>()
);

export const deleteDraftNote = createAction(
  '[Expense - Activity] deleteDraftNote',
  props<{ id: number }>()
);

export const deleteDraftNoteComplete = createAction(
  '[Expense - Activity] deleteDraftNoteComplete',
  props<{ activity: any }>()
);

export const deleteDraftNoteError = createAction(
  '[Expense - Activity] deleteDraftNoteError',
  props<{ error: string }>()
);

export const deleteNewExpenseNote = createAction(
  '[Expense - Activity] deleteNewExpenseNote',
  props<{ id: number }>()
);

export const deleteNewExpenseNoteComplete = createAction(
  '[Expense - Activity] deleteNewExpenseNoteComplete',
  props<{ activity: any }>()
);

export const deleteNewExpenseNoteError = createAction(
  '[Expense - Activity] deleteNewExpenseNoteError',
  props<{ error: string }>()
);

export const getTrashedActivityComplete = createAction(
  '[Expense - Activity] getTrashedActivityComplete',
  props<{ activity: Activity[] }>()
);

export const getTrashedActivityError = createAction(
  '[Expense - Activity] getTrashedActivityError',
  props<{ error: string }>()
);

export const getTrashedMileageActivityComplete = createAction(
  '[Expense - Activity] getTrashedMileageActivityComplete',
  props<{ activity: Activity[] }>()
);

export const getTrashedMileageActivityError = createAction(
  '[Expense - Activity] getTrashedMileageActivityError',
  props<{ error: string }>()
);
