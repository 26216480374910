import { Mileage } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getMileage = createAction('[Mileage] getMileage');

export const getMileageComplete = createAction(
  '[Mileage] getMileageComplete',
  props<{ mileage: { current: Mileage; future: Mileage } }>()
);

export const getMileageError = createAction(
  '[Mileage] getMileageError',
  props<{ error: any }>()
);

export const updateMileage = createAction(
  '[Mileage] updateMileage',
  props<{ rate: number; effectiveDate: number }>()
);

export const updateMileageComplete = createAction(
  '[Mileage] updateMileageComplete',
  props<{ current: Mileage; future: Mileage }>()
);

export const updateMileageError = createAction(
  '[Mileage] updateMileageError',
  props<{ error: any }>()
);
