<ems-base-modal
  [config]="modalInput$ | async"
  (dismiss)="onDismiss($event)"
  class="edit-mileage-modal"
>
  <ems-base-button-group modal-actions class="edit-mileage-actions">
    <ems-base-dropdown dropdownLabel="Actions" class="edit-mileage-dropdown">
      <a class="base-dropdown__item" (click)="onDeleteMileageDraft()">Trash</a>
    </ems-base-dropdown>
    <ems-base-button
      label="Save & Close"
      buttonType="primary"
      variant="brand"
      (click)="saveAndClose()"
    ></ems-base-button>
    <ems-base-button
      (click)="onDraftMileageSubmit()"
      label="Submit"
      buttonType="primary"
      variant="success"
      class="edit-mileage-submit"
    >
    </ems-base-button>
  </ems-base-button-group>
  <ems-mileage-expense-form-edit
    [formId]="formId"
    [expense]="mileageExpense$ | async"
    [dismiss]="dismiss$ | async"
    [jobCodes]="jobCodes$ | async"
    [favorites]="favoriteJobCodes$ | async"
    [reimbursement]="draftMileageReimbursement$ | async"
    [salesforceCases]="salesforceCases"
    [favoriteSalesforceCases]="favoriteSalesforceCases$ | async"
    [proxy]="proxy$ | async"
    [mileageExpenseType]="mileageExpenseType$ | async"
    [submitted]="submitted$ | async"
    (mileageFormChange)="onMileageFormChange($event)"
    (mileageStatusChange)="onMileageFormStatusChange($event)"
    (getReimbursement)="onGetMileageReimbursement($event)"
    (favoriteJobCode)="onFavoriteJobCode($event)"
    (favoriteSalesforce)="onFavoriteSalesforceCase($event)"
    (deleteNote)="onDeleteNote($event)"
  ></ems-mileage-expense-form-edit>
</ems-base-modal>
