import { Dashboard } from '@ems-gui/shared/util-core';
import { createReducer, on } from '@ngrx/store';
import { ApprovalActions } from '../actions';

export interface State extends Dashboard {
  loading: boolean;
  error: string;
}

export const initialState: State = {
  loading: false,
  error: '',
  approvals: {
    unapproved: {
      value: 0,
      totalCount: 0,
    },
    approved: {
      value: 0,
      totalCount: 0,
    },
    overdue: {
      value: 0,
      totalCount: 0,
    },
    rejected: {
      value: 0,
      totalCount: 0,
    },
  },
  submissions: {
    draft: {
      value: 0,
      totalCount: 0,
    },
    pending: {
      value: 0,
      totalCount: 0,
    },
    approved: {
      value: 0,
      totalCount: 0,
    },
    rejected: {
      value: 0,
      totalCount: 0,
    },
    trash: {
      value: 0,
      totalCount: 0,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(ApprovalActions.getExpenseCounts, (state) => ({
    ...state,
    loading: true,
  })),
  on(ApprovalActions.getExpenseCountsComplete, (state, { counts }) => ({
    ...state,
    loading: false,
    approvals: counts.approvals,
    submissions: counts.submissions,
  }))
);

export const selectApprovals = (state: State) => state.approvals;
export const selectSubmissions = (state: State) => state.submissions;
export const selectLoading = (state: State) => state.loading;
export const selectUnsubmittedCount = (state: State) =>
  state.submissions.draft.totalCount + state.submissions.rejected.totalCount;
export const selectSubmittedCount = (state: State) =>
  state.submissions.pending.totalCount + state.submissions.approved.totalCount;
export const selectSubmissionsPendingCount = (state: State) =>
  state.submissions.pending.totalCount;
export const selectSubmissionsApprovedCount = (state: State) =>
  state.submissions.approved.totalCount;
export const selectSubmissionsRejectedCount = (state: State) =>
  state.submissions.rejected.totalCount;
export const selectSubmissionsTrashCount = (state: State) =>
  state.submissions.trash.totalCount;
export const selectApprovalsReviewedTotalCount = (state: State) =>
  state.approvals.rejected.totalCount + state.approvals.approved.totalCount;
