import { Expense, ExpenseFilter } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getSubmitted = createAction(
  '[Expense - Submitted] getSubmitted',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getSubmittedComplete = createAction(
  '[Expense - Submitted] getSubmittedComplete',
  props<{ expenses: any }>()
);

export const getOneExpenseComplete = createAction(
  '[Expense - Submitted] getOneExpenseComplete',
  props<{ expense: Expense }>()
);

export const getSubmittedError = createAction(
  '[Expense - Submitted] getSubmittedError',
  props<{ error: string }>()
);

export const getUpdatedSubmitted = createAction(
  '[Expense - Submitted] getUpdatedSubmitted',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getFilteredSubmitted = createAction(
  '[Expense - FilteredSubmitted] getFilteredSubmitted',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getFilteredSubmittedComplete = createAction(
  '[Expense - FilteredSubmitted] getFilteredSubmittedComplete',
  props<{ expenses: any }>()
);

export const getFilteredSubmittedError = createAction(
  '[Expense - FilteredSubmitted] getFilteredSubmittedError',
  props<{ error: string }>()
);

export const getUnsubmitted = createAction(
  '[Expense - Submitted] getUnsubmitted',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getUnsubmittedComplete = createAction(
  '[Expense - Submitted] getUnsubmittedComplete',
  props<{ expenses: any }>()
);

export const getUnsubmittedError = createAction(
  '[Expense - Submitted] getUnsubmittedError',
  props<{ error: string }>()
);

export const getUpdatedUnsubmitted = createAction(
  '[Expense - Submitted] getUpdatedUnsubmitted',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getFilteredUnsubmitted = createAction(
  '[Expense - Submitted] getFilteredUnsubmitted',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getFilteredUnsubmittedComplete = createAction(
  '[Expense - Submitted] getFilteredUnsubmittedComplete',
  props<{ expenses: any }>()
);

export const getFilteredUnsubmittedError = createAction(
  '[Expense - Submitted] getFilteredUnsubmittedError',
  props<{ error: string }>()
);

export const getAllExpenses = createAction(
  '[Expense - Submitted] getAllExpenses'
);

export const getAllExpensesComplete = createAction(
  '[Expense - Submitted] getAllExpensesComplete',
  props<{
    expenses: Expense[];
    counts: { drafts: number; submitted: number; trash: number };
  }>()
);

export const getAllExpensesError = createAction(
  '[Expense - Submitted] getAllExpensesError',
  props<{ error: any }>()
);

export const selectExpense = createAction(
  '[Expense - Submitted] selectExpense',
  props<{ id: any }>()
);

export const getEmailedReceiptComplete = createAction(
  '[Expense - Submitted] getEmailedReceiptComplete',
  props<{ expenses: any }>()
);

export const getEmailedReceiptError = createAction(
  '[Expense - Submitted] getEmailedReceiptError',
  props<{ error: any }>()
);

export const recallExpense = createAction(
  '[Expense - Submitted] recallExpense',
  props<{ expenseId: number }>()
);

export const recallExpenseComplete = createAction(
  '[Expense - Submitted] recallExpenseComplete',
  props<{ expense: Expense }>()
);

export const recallExpenseError = createAction(
  '[Expense - Submitted] recallExpenseError',
  props<{ error: any }>()
);
