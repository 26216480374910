<div class="base-banner">
  <ems-base-caption size="1">
    <span class="base-banner__icon">
      <ng-content select="[icon]"></ng-content>
    </span>
    {{ content }}
  </ems-base-caption>
  <div class="base-banner__actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
