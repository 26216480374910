import { Injectable } from "@angular/core";
import { ApiService } from "@ems-gui/expense/util-web-infrastructure";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap, switchMap, of } from "rxjs";
import { select, Store } from '@ngrx/store';
import * as fromAllModal from '../reducers';
import {
    selectInitialCallMade,
    TravelTicketActions,
    LayoutActions,
    selectedDraftForWebOnly,
    AllModalActions,
  } from '@ems-gui/expense/util-web-ngrx';
import { Expense, TravelTicket } from "@ems-gui/shared/util-core";
@Injectable()
export class AllModalEffects {
    constructor(
        private store$: Store<fromAllModal.State>,
        private apiService: ApiService,
        private actions$: Actions
    ) {}

  // TO DO: Only initiate api call if selectInitialCallMade is false
  // TO DO: include actions that receipt widget and receipt view component need and make changes to those
  // openModal$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LayoutActions.openExpenseModal),
  //     switchMap(({id}) => {
  //       return this.apiService.getOneExpense(id).pipe(
  //           tap(console.log),
  //           switchMap((response: Expense) => of(
  //             TravelTicketActions.setTravelTicket({travelTicket: response.travelTicket &&  typeof response.travelTicket !== "string" ? response.travelTicket : null}),
  //             AllModalActions.initialModalRequestComplete()
  //           ))
  //       );
  //     })
  //   ));


}
