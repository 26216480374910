<div
  *ngIf="loaded$ | async; else loading"
  class="webapp"
  [ngClass]="{ 'nav-open': currentNavDropdown !== '' }"
>
  <div class="empty-div"></div>
  <div
    class="webapp__sidebar"
    (click)="closeModal()"
    [class.display-sidebar]="displayNav"
  >
    <header class="webapp__header">
      <a routerLink="/" alt="SPENSE" class="webapp__logo">
        <img
          class="webapp__logo__image"
          src="assets/images/logo.svg"
          alt="SPENSE"
        />
        <ems-icon-spense-logo class="small-logo"></ems-icon-spense-logo>
      </a>
      <ems-base-card-section
        [squish]="true"
        class="user-summary"
        *ngIf="(proxyUser$ | async) === null"
      >
        <ems-user-summary
          [firstName]="(user$ | async)?.firstName"
          [lastName]="(user$ | async)?.lastName"
          [employeeNumber]="(user$ | async)?.employeeNumber"
          size="s"
          [mobileUserSummary]="true"
          (openProxyMenu)="onOpenProxyMenu($event)"
        >
        </ems-user-summary>
      </ems-base-card-section>
      <ems-base-card-section
        [squish]="true"
        class="user-summary"
        *ngIf="proxyUser$ | async"
      >
        <ems-user-summary
          [firstName]="(proxyUser$ | async)?.name_first"
          [lastName]="(proxyUser$ | async)?.name_last"
          [employeeNumber]="(proxyUser$ | async)?.employee_number"
          size="s"
          [mobileUserSummary]="true"
          (openProxyMenu)="onOpenProxyMenu($event)"
        >
        </ems-user-summary>
      </ems-base-card-section>
    </header>
    <nav class="webapp-nav">
      <ul>
        <!-- Dashboard -->
        <li id="{{navButtonIds.dashboard}}">
          <a
            routerLink="/dashboard"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="webapp-nav__item"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="dashboard"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">Dashboard</span>
          </a>
        </li>

        <!-- My Expenses -->
        <li id="{{navButtonIds.myExpenses}}">
          <div
            class="webapp-nav-accordion"
            [ngClass]="{ 'is-open': currentNavDropdown === 'my-expenses' }"
          >
            <div
              class="webapp-nav-accordion__trigger webapp-nav__item"
              (click)="toggleNavDropdown('my-expenses')"
              [ngClass]="{ 'is-active': activeParentRoute === 'my-expenses' }"
            >
              <span class="webapp-nav__icon">
                <ems-base-icon iconName="expenses"></ems-base-icon>
              </span>
              <span class="webapp-nav__item-label">My Expenses</span>
              <div class="webapp-nav__dropdown-marker">
                <ems-base-icon iconName="chevron-right"></ems-base-icon>
              </div>
            </div>
            <div class="webapp-nav-accordion__menu">
              <ul class="webapp-nav__submenu">
                <li id="{{navButtonIds.unsubmitted}}">
                  <a
                    routerLink="/expenses/unsubmitted"
                    routerLinkActive="is-active"
                  >
                    Unsubmitted
                  </a>
                </li>
                <li id="{{navButtonIds.submitted}}">
                  <a
                    routerLink="/expenses/submitted"
                    routerLinkActive="is-active"
                  >
                    Submitted
                  </a>
                </li>
                <li id="{{navButtonIds.trash}}">
                  <a routerLink="/expenses/trash" routerLinkActive="is-active">
                    Trash
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <!-- Approvals -->
        <li id="{{navButtonIds.approvals}}" *ngIf="isApprover$ | async">
          <div
            class="webapp-nav-accordion"
            [ngClass]="{ 'is-open': currentNavDropdown === 'approvals' }"
          >
            <div
              class="webapp-nav-accordion__trigger webapp-nav__item"
              (click)="toggleNavDropdown('approvals')"
              [ngClass]="{ 'is-active': activeParentRoute === 'approvals' }"
            >
              <span class="webapp-nav__icon">
                <ems-base-icon iconName="approvals"></ems-base-icon>
              </span>
              <span class="webapp-nav__item-label">Approvals</span>
              <div class="webapp-nav__dropdown-marker">
                <ems-base-icon iconName="chevron-right"></ems-base-icon>
              </div>
            </div>
            <div class="webapp-nav-accordion__menu">
              <ul class="webapp-nav__submenu">
                <li id="{{navButtonIds.awaitingReview}}">
                  <a
                    routerLink="/approvals/awaiting"
                    routerLinkActive="is-active"
                    >Awaiting Review</a
                  >
                </li>
                <li id="{{navButtonIds.reviewed}}">
                  <a
                    routerLink="/approvals/reviewed"
                    routerLinkActive="is-active"
                    >Reviewed</a
                  >
                </li>
                <!-- <li><a class="is-disabled">Archive</a></li> -->
              </ul>
            </div>
          </div>
        </li>

        <!-- Settings -->
        <li id="{{navButtonIds.settings}}">
          <a
            class="webapp-nav__item"
            routerLink="/settings"
            routerLinkActive="is-active"
            *ngIf="!(proxyId$ | async) || (isAdmin$ | async)"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="settings"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">Settings</span>
          </a>
        </li>

        <!-- Admin -->
        <li id="{{navButtonIds.admin}}"
          *ngIf="
          (
              (proxyId$ | async) &&
              (
                (proxyUserIsAdmin$ | async) ||
                (proxyUserIsProxyAll$ | async) ||
                (proxyUserHasUsers$ | async) ||
                (proxyUserHasReceiptMgr$ | async) ||
                (proxyUseHasSettings$ | async)
              )
            ) ||
            (
              (!(proxyId$ | async)) &&
              (
                (isAdmin$ | async) ||
                (hasProxyAll$ | async) ||
                (hasUsers$ | async) ||
                (hasSettings$ | async) ||
                (hasReceiptMgr$ | async) ||
                (hasLoginAs$ | async)
              )
            )
          "
        >
          <div
            class="webapp-nav-accordion"
            [ngClass]="{ 'is-open': currentNavDropdown === 'admin' }"
          >
            <div
              class="webapp-nav-accordion__trigger webapp-nav__item"
              (click)="toggleNavDropdown('admin')"
              [ngClass]="{ 'is-active': activeParentRoute === 'admin' }"
            >
              <span class="webapp-nav__icon">
                <ems-base-icon iconName="admin"></ems-base-icon>
              </span>
              <span class="webapp-nav__item-label">Admin</span>
              <div class="webapp-nav__dropdown-marker">
                <ems-base-icon iconName="chevron-right"></ems-base-icon>
              </div>
            </div>
            <div class="webapp-nav-accordion__menu">
              <ul class="webapp-nav__submenu">
                <li id="{{navButtonIds.users}}"
                  *ngIf="
                    (
                      (!(proxyId$ | async)) &&
                      (
                        (hasProxyAll$ | async) ||
                        (hasUsers$ | async) ||
                        (hasLoginAs$ | async) ||
                        (isAdmin$ | async)
                      )
                    ) ||
                    (
                      (proxyId$ | async) &&
                      (
                        (proxyUserIsAdmin$ | async) ||
                        (proxyUserIsProxyAll$ | async)  ||
                        (proxyUserHasUsers$ | async)
                      )
                    )
                  "
                >
                  <a routerLink="/admin/users" routerLinkActive="is-active"
                    >Users</a
                  >
                </li>
                <li id="{{navButtonIds.systemSettings}}"
                  *ngIf="
                  (
                    (!(proxyId$ | async)) &&
                    (
                      (hasSettings$ | async) ||
                      (isAdmin$ | async)
                    )
                  ) ||
                  (
                    (proxyId$ | async) &&
                    (
                      (proxyUserIsAdmin$ | async) ||
                      (proxyUseHasSettings$ | async)
                    )
                  )
                  "
                >
                  <a
                    routerLink="/admin/system-settings"
                    routerLinkActive="is-active"
                    >System Settings</a
                  >
                </li>
                <li id="{{navButtonIds.receiptManager}}"
                  *ngIf="
                  (
                    (!(proxyId$ | async)) &&
                    (
                      (hasReceiptMgr$ | async) ||
                      (isAdmin$ | async)
                    )
                  ) ||
                  (
                    (proxyId$ | async) &&
                    (
                      (proxyUserIsAdmin$ | async) ||
                      (proxyUserHasReceiptMgr$ | async)
                    )
                  )
                  "
                >
                  <a
                    routerLink="/admin/receipt-manager"
                    routerLinkActive="is-active"
                    >Receipt Manager</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </nav>

    <nav class="webapp-nav__mobile" [class.hide-nav]="proxyMenuOpened">
      <ul>
        <!-- Dashboard -->
        <li id="{{navButtonIds.dashboard}}">
          <a
            routerLink="/dashboard"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="webapp-nav__item"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="dashboard"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">Dashboard</span>
          </a>
        </li>

        <!-- My Expenses -->
        <li id="{{navButtonIds.myExpenses}}">
          <a
            routerLink="/expenses/unsubmitted"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="webapp-nav__item"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="expenses"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">My Expenses</span>
          </a>
        </li>

        <!-- Approvals -->
        <li id="{{navButtonIds.approvals}}" *ngIf="isApprover$ | async">
          <a
            routerLink="/approvals/awaiting"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="webapp-nav__item"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="approvals"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">Approvals</span>
          </a>
        </li>

        <!-- Settings -->
        <li id="{{navButtonIds.settings}}">
          <a
            class="webapp-nav__item"
            routerLink="/settings"
            routerLinkActive="is-active"
            *ngIf="!(proxyId$ | async) || (isAdmin$ | async)"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="settings"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">Settings</span>
          </a>
        </li>
        <!-- Admin -->
        <li
          *ngIf="
            !(proxyId$ | async) &&
            ((isAdmin$ | async) ||
              (proxyUserIsAdmin$ | async) ||
              (hasProxyAll$ | async) ||
              (proxyUserIsProxyAll$ | async))
          "
        >
          <a
            routerLink="/admin/users"
            routerLinkActive="is-active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="webapp-nav__item"
          >
            <span class="webapp-nav__icon">
              <ems-base-icon iconName="admin"></ems-base-icon>
            </span>
            <span class="webapp-nav__item-label">Admin</span>
          </a>
        </li>
        <li class="logout-btn">
          <div class="webapp-nav__item" (click)="onLogout()">
            <span>Log Out</span>
          </div>
        </li>
      </ul>
    </nav>
    <ems-proxy-menu
      (chooseProxy)="onProxySet($event)"
      (removeProxy)="onRemoveProxy()"
      [proxies]="getUsersForProxy$ | async"
      [currentUser]="user$ | async"
      [proxyUser]="proxyUser$ | async"
      class="proxy-menu"
      *ngIf="proxyMenuOpened"
    ></ems-proxy-menu>
  </div>
  <div
    class="overlay"
    [class.display]="displayNav"
    (click)="onToggleNav()"
  ></div>

  <main class="webapp__main">
    <ems-top-bar
      [breadcrumbs]="breadcrumbs$ | async"
      [user]="user$ | async"
      [proxy]="proxyUser$ | async"
      [usersForProxy]="getUsersForProxy$ | async"
      [openModalName]="openModalId$ | async"
      (removeProxy)="onRemoveProxy()"
      (logout)="onLogout()"
      (chooseProxy)="onChooseProxy($event)"
      (toggleNav)="onToggleNav()"
    ></ems-top-bar>
    <div class="webapp__content" [class.empty-list-view]="emptyList">
      <router-outlet></router-outlet>
    </div>
  </main>

  <aside *ngIf="openModalId$ | async as id" [ngSwitch]="id">
    <!-- Expense Modal -->
    <ems-base-modal
      *ngSwitchCase="'expense-modal'"
      [config]="modalConfig$ | async"
      [activityTabOpen]="activityTabOpen"
      [ngClass]="(shouldShowRecallButton | async) ? 'expense-modal' : ''"
    >
      <ems-base-button-group
        *ngIf="shouldShowRecallButton | async"
        [ngClass]="(shouldShowRecallButton | async) ? 'expense-button-group' : ''"
        modal-actions
      >
        <ems-base-button
          (click)="onRecall()"
          label="Recall"
          buttonType="primary"
          variant="destructive"
          [ngClass]="(shouldShowRecallButton | async) ? 'recall-button' : ''"        >
        </ems-base-button>
      </ems-base-button-group>
      <ems-expense-modal
        [expense]="openExpense$ | async"
        [isDisabled]="true"
        [isApproval]="false"
        (tabSelected)="onTabSelected($event)"
      ></ems-expense-modal>
    </ems-base-modal>

    <!-- Approval Detail Modal -->
    <ems-approval-modal *ngSwitchCase="'approval-modal'"> </ems-approval-modal>

    <!-- New Expense Modal-->
    <ems-expense-new-modal 
      *ngSwitchCase="'expense-new-modal-company-cc'"
      formTitle="Company CC Expense: "
    >
    </ems-expense-new-modal>

    <ems-expense-new-modal 
      *ngSwitchCase="'expense-new-modal'"
      formTitle="Out of Pocket Expense: "
    >
    </ems-expense-new-modal>

    <!-- New Mileage Expense Modal -->
    <ems-mileage-expense-new-modal
      *ngSwitchCase="'mileage-expense-modal-new'"
    ></ems-mileage-expense-new-modal>

    <!-- Mileage Expense Modal Edit-->
    <ems-mileage-expense-edit-modal
      *ngSwitchCase="'mileage-expense-modal-edit'"
    ></ems-mileage-expense-edit-modal>

    <!-- Expense Edit Modal-->
    <!-- <ems-base-modal
      *ngSwitchCase="'expense-modal-edit'"
      modalTitle="Expense:"
      subtitle="ID 186"
      caption="Submitted by Tricia Hayes on 7/25/19"
      modalId="expense-edit-modal"
    >
      <ems-base-button-group modal-actions>
        <ems-base-button
          label="Cancel"
          buttonType="primary"
          variant="destructive"
        >
        </ems-base-button>
        <ems-base-button label="Save" buttonType="primary" variant="success">
        </ems-base-button>
      </ems-base-button-group>
      <ems-expense-modal-edit></ems-expense-modal-edit>
    </ems-base-modal> -->

    <!-- Expense Modal Multi-->
    <!-- <ems-base-modal
      *ngSwitchCase="'expense-modal-multi'"
      modalTitle="Multi-Edit:"
      subtitle="3 Expenses"
      caption="Changes will be applied to all expenses listed below"
      modalId="expense-multi-modal"
    >
      <ems-base-button-group modal-actions>
        <ems-base-dropdown dropdownLabel="Actions">
          <a>Clear Edits</a>
          <a>Select Expenses</a>
        </ems-base-dropdown>
        <ems-base-button
          (buttonClick)="modalService.close('expense-multi-modal')"
          label="Cancel"
          buttonType="primary"
          variant="destructive"
        >
        </ems-base-button>
        <ems-base-button label="Save" buttonType="primary" variant="success"
          >-
        </ems-base-button>
      </ems-base-button-group>

      <ems-expense-modal-multi></ems-expense-modal-multi>
    </ems-base-modal> -->

    <!-- Expense Modal Draft-->
    <ems-expense-edit-modal *ngSwitchCase="'expense-form'">
    </ems-expense-edit-modal>

    <!-- Expense Multi Edit Modal Draft-->
    <ems-expense-multi-edit-modal *ngSwitchCase="'expense-multi-edit-form'">
    </ems-expense-multi-edit-modal>

    <!-- Receipt Data Warning Dialog -->
    <ems-receipt-data-warn-modal
      *ngSwitchCase="'receipt-data-warn-modal'"
    ></ems-receipt-data-warn-modal>

    <!-- Expense Itemization Form -->
    <ems-expense-itemize-modal
      *ngSwitchCase="'expense-itemize-modal'"
    ></ems-expense-itemize-modal>

    <!-- Expense Itemization Edit Form -->
    <ems-expense-itemize-edit-modal
      *ngSwitchCase="'expense-itemize-edit-modal'"
    ></ems-expense-itemize-edit-modal>

    <!-- Undo Itemization Alert -->
    <ems-undo-itemization-alert-modal
      *ngSwitchCase="'undo-itemization-alert-modal'"
    ></ems-undo-itemization-alert-modal>

    <!-- Draft Itemization Form -->
    <ems-expense-itemize-draft-modal
      *ngSwitchCase="'expense-itemize-draft-modal'"
    ></ems-expense-itemize-draft-modal>

    <!-- Reject Modal-->
    <ems-reject-modal *ngSwitchCase="'reject-modal'"></ems-reject-modal>

    <!-- Loading Modal-->
    <ems-base-modal
      *ngSwitchCase="'loading-modal'"
      id="loading-modal"
      [config]="loadingModalConfig"
    >
      <div class="spinner-container">
        <ems-base-icon class="spinner" iconName="loading"></ems-base-icon>
      </div>
    </ems-base-modal>

    <!-- Delete Modal-->
    <ems-delete-modal *ngSwitchCase="'delete-modal'"></ems-delete-modal>

    <!-- Empty Trash Modal-->
    <!-- TODO: Remove this, we do not empty trash any more -->
    <ems-trash-modal *ngSwitchCase="'trash-modal'"></ems-trash-modal>

    <!-- Send To Another Job Code Modal -->
    <ems-send-to-modal *ngSwitchCase="'send-to-modal'"></ems-send-to-modal>

    <!-- Credit Card Holder Alert Modal -->
    <sis-credit-card-holder-alert-modal *ngSwitchCase="'credit-card-holder-alert'"></sis-credit-card-holder-alert-modal>

    <!-- Receipt Manager Modals -->
    <ems-receipt-manager-error-modal
      *ngSwitchCase="'receipt-manager-error-modal'"
    ></ems-receipt-manager-error-modal>

    <ems-receipt-manager-warn-modal
      *ngSwitchCase="'receipt-manager-warn-modal'"
    ></ems-receipt-manager-warn-modal>

    <!-- Fraudulent Expense Modals -->
    <ems-expense-fraud-warn-modal
      *ngSwitchCase="'expense-fraud-warn-modal'"
    ></ems-expense-fraud-warn-modal>

    <ems-approver-fraud-warn-modal
      *ngSwitchCase="'approver-fraud-warn-modal'"
    ></ems-approver-fraud-warn-modal>

    <!-- Trashed Details Modal-->
    <ems-trash-detail-modal *ngSwitchCase="'trash-detail'">
    </ems-trash-detail-modal>

    <ems-trash-mileage-detail-modal *ngSwitchCase="'trash-mileage-detail'">
    </ems-trash-mileage-detail-modal>

    <!-- Transaction Feed Item Modal -->
    <ems-transaction-feed-item-modal
      *ngSwitchCase="'transaction-feed-item-detail'"
    ></ems-transaction-feed-item-modal>

    <!-- Recall modal -->
    <sis-recall-expense-alert *ngSwitchCase="'recall-expense-alert'"></sis-recall-expense-alert>
  </aside>
</div>

<sis-is-loading></sis-is-loading>

<ng-template #loading>
  <div class="spinner-container"></div>
</ng-template>
