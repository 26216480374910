import { createSelector } from '@ngrx/store';
import * as fromAlternateEmail from '../reducers/alternate-email.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardAlternateEmailState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.alternateEmail
);

export const {
  selectIds: selectAlternateEmailIds,
  selectEntities: selectAlternateEmailEntities,
  selectAll: selectAllAlternateEmails,
} = fromAlternateEmail.adapter.getSelectors(selectDashboardAlternateEmailState);
