import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalInput } from '@ems-gui/shared/util-core';

@Component({
  selector: 'ems-alt-email-modal',
  templateUrl: './alt-email-modal.component.html',
  styleUrls: ['./alt-email-modal.component.scss'],
})
export class AltEmailModalComponent {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'alt-email-modal',
  };
  @Input() userName = '';
  @Output() addEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelEmail: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  onAltEmailConfirm(email) {
    this.addEmail.emit(email);
  }

  onAltEmailFormCancel() {
    this.cancelEmail.emit();
  }
}
