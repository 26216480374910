export const environment = {
  production: true,
  version: '0.0.1',
  apiURL: window['env']['apiURL'] || 'https://spense.prod.sis.us/api/v2/spense',
  sisApiURL: window['env']['sisApiURL'] || 'https://prod.sis.us/api/v1',
  baseHref: '/',
  sisURL:
    (window &&
      window['env'] &&
      window['env']['sisURL'] !== undefined &&
      window['env']['sisURL']) ||
    'https://spense.sis.us/',
};
