import { Proxy } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getProxies = createAction(
  '[Proxy - Admin] getProxies',
  props<{ user: number }>()
);

export const getProxiesComplete = createAction(
  '[Proxy - Admin] getProxiesComplete',
  props<{ proxies: Proxy[] }>()
);

export const getProxiesError = createAction(
  '[Proxy - Admin] getProxiesError',
  props<{ error: any }>()
);

export const addProxy = createAction(
  '[Proxy - Admin] addProxy',
  props<{ user: number; proxy: number }>()
);

export const addProxyComplete = createAction(
  '[Proxy - Admin] addProxyComplete',
  props<{ proxy: Proxy }>()
);

export const addProxyError = createAction(
  '[Proxy - Admin] addProxyError',
  props<{ error: any }>()
);

export const deleteProxy = createAction(
  '[Proxy - Admin] deleteProxy',
  props<{ id: number }>()
);

export const deleteProxyComplete = createAction(
  '[Proxy - Admin] deleteProxyComplete',
  props<{ proxy: Proxy }>()
);

export const deleteProxyError = createAction(
  '[Proxy - Admin] deleteProxyError',
  props<{ error: any }>()
);
