import { Proxy } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getProxies = createAction('[Proxy - User] getProxies');

export const getProxiesComplete = createAction(
  '[Proxy - User] getProxiesComplete',
  props<{ proxies: Proxy[] }>()
);

export const getProxiesError = createAction(
  '[Proxy - User] getProxiesError',
  props<{ error: any }>()
);

export const addProxy = createAction(
  '[Proxy - User] addProxy',
  props<{ proxy: number }>()
);

export const addProxyComplete = createAction(
  '[Proxy - User] addProxyComplete',
  props<{ proxy: Proxy }>()
);

export const addProxyError = createAction(
  '[Proxy - User] addProxyError',
  props<{ error: any }>()
);

export const setProxy = createAction(
  '[Proxy - User] setProxy',
  props<{ id: number }>()
);

export const setProxyError = createAction(
  '[Proxy - User] setProxyError',
  props<{ message: string }>()
);

export const removeProxy = createAction('[Proxy - User] removeProxy');

export const deleteProxy = createAction(
  '[Proxy - User] deleteProxy',
  props<{ id: number }>()
);

export const deleteProxyComplete = createAction(
  '[Proxy - User] deleteProxyComplete',
  props<{ proxy: Proxy }>()
);

export const deleteProxyError = createAction(
  '[Proxy - User] deleteProxyError',
  props<{ error: any }>()
);
