import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExpenseUtilWebInfrastructureModule } from '@ems-gui/expense/util-web-infrastructure';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ActivityEffects } from './+state/effects/activity/activity.effects';
import { AlternateEmailEffects } from './+state/effects/alternate-email.effects';
import { ApprovalEffects } from './+state/effects/expense/approval.effects';
import { DraftMileageExpenseEffects } from './+state/effects/expense/draft-mileage-expense.effects';
import { EditDraftEffects } from './+state/effects/expense/edit-draft.effects';
import { MyExpensesEffects } from './+state/effects/expense/my-expenses.effects';
import { NewExpenseEffects } from './+state/effects/expense/new-expense.effects';
import { NewMileageExpenseEffects } from './+state/effects/expense/new-mileage-expense.effects';
import { FavoriteSalesforceCaseEffects } from './+state/effects/favorite-salesforce-case.effects';
import { FeedErrorUserEffects } from './+state/effects/feed-error-user.effects';
import { FavoriteJobCodeEffects } from './+state/effects/job-code/favorite-job-code.effects';
import { LayoutEffects } from './+state/effects/layout/layout.effects';
import { MileageEffects } from './+state/effects/mileage/mileage.effects';
import { NotificationSettingEffects } from './+state/effects/notification/notification-setting.effects';
import { AdminEffects } from './+state/effects/person/admin.effects';
import { FavoritePersonEffects } from './+state/effects/person/favorite-person.effects';
import { PersonEffects } from './+state/effects/person/person.effects';
import { ProxiedUserEffects } from './+state/effects/proxied-user.effects';
import { ProxyEffects } from './+state/effects/proxy.effects';
import { ReceiptManagerEffects } from './+state/effects/receipt-manager.effects';
import { TransactionFeedItemEffects } from './+state/effects/transaction-feed-item.effects';
import { UserEffects } from './+state/effects/user.effects';
import { reducers } from './+state/reducers';
import { TravelTicketEffects } from "./+state/effects/travel-ticket.effects";
import { ExpenseMatchEffects } from './+state/effects/expense-match.effects';
import { ExpenseSubmittedEffects } from './+state/effects/expense/submitted.effects';
import { AllModalEffects } from './+state/effects/all-modal.effects';
import { SalesforceCaseEffects } from './+state/effects/salesforce-case.effects';

@NgModule({
  imports: [
    CommonModule,
    ExpenseUtilWebInfrastructureModule,
    EffectsModule.forFeature([
      NewExpenseEffects,
      ApprovalEffects,
      MyExpensesEffects,
      LayoutEffects,
      EditDraftEffects,
      ActivityEffects,
      PersonEffects,
      FavoritePersonEffects,
      FavoriteJobCodeEffects,
      UserEffects,
      AlternateEmailEffects,
      FavoriteSalesforceCaseEffects,
      ProxyEffects,
      ProxiedUserEffects,
      NotificationSettingEffects,
      MileageEffects,
      FeedErrorUserEffects,
      TransactionFeedItemEffects,
      NewMileageExpenseEffects,
      DraftMileageExpenseEffects,
      ReceiptManagerEffects,
      AdminEffects,
      TravelTicketEffects,
      ExpenseMatchEffects,
      ExpenseSubmittedEffects,
      AllModalEffects,
      SalesforceCaseEffects
    ]),
    StoreModule.forFeature('dashboard', reducers),
  ],
})
export class ExpenseUtilWebNgrxModule {}
