import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom
} from "rxjs/operators";
import {
  AdminActions,
  ApprovalActions, 
  LayoutActions,
  PersonActions,
  ProxyUserActions,
  UserActions
} from "../../actions";
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import { selectUserIsAdmin } from '../../selectors';
import {
  selectAllPersons,
  selectedProxyUser,
} from '../../selectors/person.selectors';
import {
  FavoritePersonActions,
  FavoriteJobCodeActions,
  FavoriteSalesforceCaseActions,
  NotificationSettingActions
} from '@ems-gui/expense/util-web-ngrx';
import {
  addPerson
} from "@libs/expense/util-web-ngrx/src/lib/+state/actions/person/person.actions";

@Injectable()
export class PersonEffects {
  getAllPersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonActions.getAllPerson),
      switchMap(() =>
        this.apiService.getAllPersons().pipe(
          map((person) => PersonActions.getAllPersonComplete({ person })),
          catchError(errorHandler(PersonActions.getAllPersonError))
        )
      )
    )
  );

  getSearchedEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonActions.getSearchedPersons),
      switchMap(({ filters }) =>
        this.apiService.getEmployeesBySearchString(filters).pipe(
          map(({ users }) =>
            PersonActions.getSearchedPersonsComplete({
              persons: users, searchString : filters.q
            })
          ),
          catchError(errorHandler(PersonActions.getAllPersonError))
        )
      )
    )
  );
  
  setProxy$ = createEffect(() => this.actions$.pipe(
    ofType(AdminActions.setProxy, ProxyUserActions.setProxy),
    withLatestFrom(this.store$.pipe(select(selectedProxyUser))),
    switchMap(([action, proxy]) => !proxy ?
      this.apiService.getContact(action.id).pipe(
        map((resp) => resp.users[0]),
        tap((person) => !person ?
          this.store$.dispatch(
            ProxyUserActions.setProxyError({ message: 'User Not Found' })
          ) :
          this.store$.dispatch(PersonActions.addPerson({ person }))
        ),
        tap((person) => !person ?
          undefined :
          this.store$.dispatch(
            ProxyUserActions.setProxy({ id: person.id })
          )
        )
      ) :
      this.apiService.getPermissions(proxy.user_id).pipe(
        map((permissions) =>
          AdminActions.getProxyPermissionsComplete({ permissions })
        ),
        catchError(errorHandler(AdminActions.getProxyPermissionsError))
      )
    ))
  );

  setProxyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProxyUserActions.setProxyError),
        tap(({ message }) =>
          this.store$.dispatch(
            LayoutActions.globalToastErrorMessage({ message })
          )
        ),
        tap(() => this.store$.dispatch(ProxyUserActions.removeProxy())
        )
      )
  );

  getProxyPermissionsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminActions.getProxyPermissionsComplete,
        UserActions.getPermissionsComplete
      ),
      tap(() => this.router.navigateByUrl('/')),
      map(() => ApprovalActions.getExpenseCounts()),
      tap(() => this.store$.dispatch(FavoritePersonActions.getFavoritePerson())),
      tap(() => this.store$.dispatch(FavoriteSalesforceCaseActions.getFavoriteSalesforceCase())),
      tap(() => this.store$.dispatch(NotificationSettingActions.getNotificationSetting())),
      tap(() => this.store$.dispatch(FavoriteJobCodeActions.getFavorite()))
    )
  );

  removeProxy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyUserActions.removeProxy),
      withLatestFrom(this.store$.pipe(select(selectUserIsAdmin))),
      tap(([, isAdmin]) => {
        if (isAdmin) {
          this.router.navigateByUrl('/admin/users');
        } else {
          this.router.navigateByUrl('/');
        }
      }),
      map(() => ApprovalActions.getExpenseCounts())
    )
  );

  getAllPersonComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getProxyPermissions),
      withLatestFrom(this.store$.pipe(select(selectAllPersons))),
      switchMap(([action, person]) => {
        return forkJoin([
          of(person),
          this.apiService.getUsersHasProxyStatus(),
          this.apiService.getUsersIsProxyStatus(),
        ]);
      }),
      map(([persons, hasProxy, isProxy]) => {
        const personsWithProxyStatus = persons.map((p) => {
          let updatedPerson = {
            ...p,
            hasProxy: false,
            isProxy: false,
          };

          if (hasProxy.includes(p.id)) {
            updatedPerson = {
              ...updatedPerson,
              hasProxy: true,
            };
          }

          if (isProxy.includes(p.id)) {
            updatedPerson = {
              ...updatedPerson,
              isProxy: true,
            };
          }
          return updatedPerson;
        });
        return AdminActions.getAllProxyStatusComplete({
          person: personsWithProxyStatus,
        });
      }),
      catchError(errorHandler(AdminActions.getProxyPermissionsError))
    )
  );

  getUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getAllUsersPermissions),
      switchMap(() =>
        this.apiService.getAllPermissions().pipe(
          map((permissions) =>
            AdminActions.getAllUsersPermissionsComplete({ permissions })
          ),
          catchError(errorHandler(AdminActions.getAllUsersPermissionsError))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private router: Router,
    private store$: Store<fromDashboard.State>
  ) {}
}
