import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';

@Component({
  selector: 'submitting-company-cc-expenses-alert',
  templateUrl: './submitting-company-cc-expenses-alert.component.html',
  styleUrls: ['./submitting-company-cc-expenses-alert.component.scss'],
})
export class SubmittingCompanyCCExpensesAlertComponent implements OnInit {
  alertToggle: boolean;

  public constructor(
    protected router: Router,
    protected modalService: BaseModalService
  ) {}

  ngOnInit() {
    this.alertToggle = false;
  }
}
