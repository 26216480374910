import { Component } from '@angular/core';

@Component({
  selector: 'ems-icon-menu',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Group 5367"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <path data-name="Rectangle 6202" style="fill:#fff" d="M0 0h20v1.5H0z" />
      <path
        data-name="Rectangle 6203"
        transform="translate(0 8.25)"
        style="fill:#fff"
        d="M0 0h20v1.5H0z"
      />
      <path
        data-name="Rectangle 6204"
        transform="translate(0 16.5)"
        style="fill:#fff"
        d="M0 0h20v1.5H0z"
      />
    </svg>
  `,
  styleUrls: ['./icon-menu.component.scss'],
})
export class IconMenuComponent {}
