import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  DraftActions,
  selectAllExpenseTypesExceptMileage,
  selectJobCodesForDraftDropdown,
  selectAllSalesforceCasesWithGroupLabels,
  selectedProxyId,
  selectModalDismiss,
  State,
  selectedMultiEditExpenses
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ExpenseType,
  JobCode,
  ModalInput,
  SalesforceCase,
  MAX_CHARACTER_LENGTH
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import {
  share,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

const multiEditProperties = ['salesforceId', 'type', 'description', 'jobCode'];

function getPropertiesWithSameValues<T>(arr: any[]) {
  const result = {};
  if (arr.length === 0) return result;

  const referenceObj = arr[0];

  for (const key in referenceObj) {
    if(multiEditProperties.includes(key) && referenceObj[key] !== null) {
      // Had to do this since we can not compare two nested objects
      // jobCode has couple of nested objects with in so compared id of the object instead
      if(key === 'jobCode' && arr.every(obj => obj[key].id === referenceObj[key].id)) {
        result[key] = referenceObj[key].id;
      } else if (arr.every(obj => obj[key] === referenceObj[key])) {
        result[key] = referenceObj[key];
      }
    }   
  }
  return result;
}

@Component({
  selector: 'ems-expense-multi-edit-modal',
  templateUrl: './expense-multi-edit-modal.component.html',
  styleUrls: ['./expense-multi-edit-modal.component.scss'],
})
export class ExpenseMultiEditModalComponent implements OnInit, OnDestroy {
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Expenses:',
    label: '',
    labelClass: '',
    caption: '',
    status: '',
    subtitle: '',
    statusText: 'Multi-Edit',
    fieldsComplete: false,
    modalId: 'expense-multi-edit-form',
    scrollToBottom: false,
  });
  modalInput$ = this.modalInputSubject.asObservable();
  showNote = false;
  showSalesforce = false;
  expense$: Observable<Partial<Expense>>;
  dismiss$: Observable<boolean>;
  jobCodes$: Observable<JobCode[]>;
  expenseTypes$: Observable<ExpenseType[]>;
  salesforceCases$: Observable<Partial<SalesforceCase>[]>;
  unsubscribe$: Subject<void> = new Subject();
  proxy$: Observable<any>;
  statusSubject$: BehaviorSubject<string> = new BehaviorSubject(null);
  status$: Observable<string> = this.statusSubject$.asObservable();
  selectedMultiEditExpenses$: Observable<any>;  
  formSubject$: BehaviorSubject<Partial<Expense>> = new BehaviorSubject({});
  form$: Observable<Partial<Expense>> = this.formSubject$
    .asObservable()
    .pipe(share());

  expenseStatus = null;
  submittedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  submitted$: Observable<boolean> = this.submittedSubject$.asObservable();
  readonly maxLength = MAX_CHARACTER_LENGTH;
  expensesItems: Expense[];
  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>,
    private fb: FormBuilder,
    
    private toastrService: ToastrService
  ) {
    this.selectedMultiEditExpenses$ = this.store$.pipe(select(selectedMultiEditExpenses));
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
    this.jobCodes$ = this.store$.pipe(select(selectJobCodesForDraftDropdown));
    this.expenseTypes$ = this.store$.pipe(
      select(selectAllExpenseTypesExceptMileage)
    );

    this.salesforceCases$ = this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels)
    );
    this.proxy$ = this.store$.pipe(select(selectedProxyId));
  }

  
  form: FormGroup;
  ngOnInit() {

    this.form = this.fb.group({
      jobCode: new FormControl(null),
      salesforceId: new FormControl(null),
      description: new FormControl(''),
      message: new FormControl(''),
      type: new FormControl(null)
    });

    this.selectedMultiEditExpenses$
    .pipe(
      takeUntil(this.unsubscribe$),
      tap((expenses) => {
        this.expensesItems = expenses;
        //Update the subtitle for the modal with expense ids to display max of 3 ids
        if(expenses) {
          const maxIds = 3;
          const ids = expenses.map((e) => e.id);
          const noOfSelectedExpenses = ids.length;
          const subTitle = noOfSelectedExpenses > maxIds ?
            `${ids.slice(0,maxIds)}...and ${noOfSelectedExpenses - maxIds} more` : ids;
          const currentModalInput = this.modalInputSubject.getValue();
          this.modalInputSubject.next({
            ...currentModalInput,
            subtitle: subTitle,
          });
        }
        const referenceObj = expenses[0];
        const propertiesWthSameValue = getPropertiesWithSameValues(expenses);
        for (const key in propertiesWthSameValue) {
          if (key === 'type') {
            this.form.controls.type.setValue(referenceObj.type.id);
          }
          if (key === 'description') {
            this.form.controls.description.setValue(referenceObj.description);
          }
          if (key === 'jobCode') {
            this.form.controls.jobCode.setValue(referenceObj.jobCode.id);
          }
          if (key === 'salesforceId') {
            this.form.controls.salesforceId.setValue(referenceObj.salesforceId);
          }
        }
      })
    )
    .subscribe();
    
  }

  onToggleNote() {
    this.showNote = !this.showNote;
    this.form.get('message').setValue('');
  }

  onToggleSalesforce() {
    this.showSalesforce = !this.showSalesforce;
    if (!this.showSalesforce) {
      this.form.get('salesforceId').setValue(null);
    }
  }

  onDismiss() {
    this.modalService.close('expense-multi-edit-form');
  }

  onSubmit(event) {
    // Send only the values that are not null or empty
    const expensePayload: Partial<Expense> = {};
    const formValues = event.value;
    Object.keys(formValues).forEach(key => {
      if (formValues[key] !== null && formValues[key] !== '') {
        expensePayload[key] = formValues[key];
      }
    });
    this.store$.dispatch(
      DraftActions.multiEditSubmit({
        expense: expensePayload,
        ids: this.expensesItems.map((e) => e.id)
      })
    );
    this.modalService.close('expense-multi-edit-form');
  }

  onCancel() {
    this.modalService.close('expense-multi-edit-form');
  }

  onFormChange(value) {
    this.formSubject$.next(value);
  }

  ngOnDestroy() {
    this.toastrService.clear();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

