import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-receipt-data-warn-dialog',
  templateUrl: './receipt-data-warn-dialog.component.html',
  styleUrls: ['./receipt-data-warn-dialog.component.scss'],
})
export class ReceiptDataWarnDialogComponent implements OnInit {
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    return;
  }

  onContinue() {
    this.continue.emit();
  }
}
