import { Component, Input } from "@angular/core";

@Component({
  selector: 'sis-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class FormControlComponent {
  @Input() public label: string;
  @Input() public inputId: string;
}
