import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FavoriteSalesforceCaseActions } from '../actions';
import { errorHandler } from '../error-handler';
import * as fromDashboard from '../reducers';

@Injectable()
export class FavoriteSalesforceCaseEffects {
  getFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteSalesforceCaseActions.getFavoriteSalesforceCase),
      switchMap(() =>
        this.apiService.getFavoriteSalesforceCases().pipe(
          map((salesforceCases) =>
            FavoriteSalesforceCaseActions.getFavoriteSalesforceCaseComplete({
              salesforceCases,
            })
          ),
          catchError(
            errorHandler(
              FavoriteSalesforceCaseActions.getFavoriteSalesforceCaseError
            )
          )
        )
      )
    )
  );

  favorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase),
      withLatestFrom(
        this.store$.pipe(
          select(fromDashboard.selectAllActiveFavoriteSalesforceCases)
        )
      ),
      switchMap(([{ id: salesforceId }, favorites]) => {
        const ids = favorites.map((f) => f.salesforceId);
        if (!ids.includes(+salesforceId)) {
          return this.apiService
            .saveFavoriteSalesforceCase({ salesforceId })
            .pipe(
              map((salesforceCase) =>
                FavoriteSalesforceCaseActions.saveFavoriteSalesforceCaseComplete(
                  { salesforceCase }
                )
              ),
              catchError(
                errorHandler(
                  FavoriteSalesforceCaseActions.saveFavoriteSalesforceCaseError
                )
              )
            );
        } else {
          return this.apiService.unfavoriteSalesforceCase(salesforceId).pipe(
            map((salesforceCase) =>
              FavoriteSalesforceCaseActions.deleteFavoriteSalesforceCaseComplete(
                { salesforceCase }
              )
            ),
            catchError(
              errorHandler(
                FavoriteSalesforceCaseActions.deleteFavoriteSalesforceCaseError
              )
            )
          );
        }
      })
    )
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store$: Store<fromDashboard.State>
  ) {}
}
