<ems-base-modal
  [config]="modalConfig$ | async"
  class="dialog-modal"
  (dismiss)="onDismiss()"
  [loading]="feedDataloading$ | async"
>
  <ems-transaction-feed-item
    [item]="feedItem$ | async"
    [downloadUrl]="feedItemDownloadUrl$ | async"
    (downloadFile)="onDownloadFeedFile($event)"
  ></ems-transaction-feed-item>
</ems-base-modal>
