import { Component } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { ApprovalActions, State } from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ems-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss'],
})
export class RejectModalComponent {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'reject-modal',
  };

  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {}

  onReject(message) {
    this.store$.dispatch(
      ApprovalActions.verifyExpenses({
        message: message,
        status: 'rejected',
      })
    );
  }

  onRejectFormCancel() {
    this.modalService.close('reject-modal');
  }
}
