import { createSelector } from '@ngrx/store';
import * as fromFavoritePerson from '../reducers/favorite-person.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardFavoritePersonState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.favoritePerson
);

export const {
  selectIds: selectFavoritePersonIds,
  selectEntities: selectFavoritePersonEntities,
  selectAll: selectAllFavoritePerson,
} = fromFavoritePerson.adapter.getSelectors(selectDashboardFavoritePersonState);

export const selectAllFavoritePersonFlat = createSelector(
  selectAllFavoritePerson,
  (favorites) => favorites.map((f) => f.person)
);
