import { Component, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  State,
  ApprovalActions,
  FavoriteJobCodeActions,
  FavoriteSalesforceCaseActions,
  selectApprovalSelectedIds
} from "@ems-gui/expense/util-web-ngrx";
import { ModalInput } from "@ems-gui/shared/util-core";
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";

@Component({
  selector: 'ems-send-to-modal',
  templateUrl: './send-to-modal.component.html',
  styleUrls: ['./send-to-modal.component.scss'],
})
export class SendToModalComponent implements OnInit {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'send-to-modal',
  };
  selectedExpenseIds = [];
  private unsubscribe$: Subject<void> = new Subject();
  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {
    this.store$.pipe(
      select(selectApprovalSelectedIds),
      takeUntil(this.unsubscribe$)
      ).subscribe(expenseIds => {
        this.selectedExpenseIds = expenseIds;
      });
  }

  ngOnInit(): void {
    return;
  }

  onSend({ updates }) {
    this.store$.dispatch(ApprovalActions.approvalEdit({ updates }));
  }

  onSendToFormCancel() {
    this.modalService.close('send-to-modal');
    // Open approval modal if edit accessed from approval modal
    if(this.selectedExpenseIds.length === 1) {
      this.modalService.open('approval-modal');
    }
  }

  onFavoriteJobCode(id) {
    this.store$.dispatch(FavoriteJobCodeActions.saveOneFavorite({ id }));
  }

  onFavoriteSalesforceCase(id) {
    this.store$.dispatch(
      FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase({ id })
    );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
