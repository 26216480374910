import { createSelector } from '@ngrx/store';
import * as fromJobCode from '../reducers/job-code.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';
import {
  filteredByRole
} from '@libs/expense/util-web-ngrx/src/lib/+state/selectors/person.selectors';
import { EmployeeRoles } from '@ems-gui/shared/util-core';

export const selectDashboardJobCodeState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.jobCode
);

export const {
  selectIds: selectJobCodeIds,
  selectEntities: selectJobCodeEntities,
  selectAll: selectAllJobCodes,
} = fromJobCode.adapter.getSelectors(selectDashboardJobCodeState);

export const selectCurrentJobCodeId = createSelector(
  selectDashboardJobCodeState,
  fromJobCode.getSelectedJobCodeId
);

export const selectCurrentJobCode = createSelector(
  selectJobCodeEntities,
  selectCurrentJobCodeId,
  (jobCodeEntities, jobCodeId) => jobCodeEntities[jobCodeId]
);

export const selectJobCodeSearchMatches = createSelector(
  selectAllJobCodes,
  (jobCodes, props) =>
    jobCodes.filter((jobCode) =>
      jobCode.code.toString().startsWith(props.search)
    )
);

export const selectJobCodesLoading = createSelector(
  selectDashboardJobCodeState,
  fromJobCode.getJobCodesLoading
);

export const selectAllMasterApproverIds = createSelector(
  filteredByRole(EmployeeRoles.MASTER_APPROVER),
  (entities) => entities.map(({ id }) => id)
);

export const selectAllApproverIds = createSelector(
  filteredByRole(EmployeeRoles.APPROVER),
  (entities) => entities.map(({ id }) => id)
);
