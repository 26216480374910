import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import {
  LayoutActions,
  selectedTransactionFeedDownloadUrl,
  selectedTransactionFeedItem,
  selectTransactionFeedLoading,
  State,
  TransactionFeedItemActions,
} from '@ems-gui/expense/util-web-ngrx';
import { ModalInput, TransactionFeedItem } from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ems-transaction-feed-item-modal',
  templateUrl: './transaction-feed-item-modal.component.html',
  styleUrls: ['./transaction-feed-item-modal.component.scss'],
})
export class TransactionFeedItemModalComponent {
  feedItem$: Observable<TransactionFeedItem>;
  feedItemDownloadUrl$: Observable<string>;
  modalConfig$: Observable<ModalInput>;
  feedDataloading$: Observable<boolean>;

  constructor(private store$: Store<State>) {
    this.feedItem$ = this.store$.pipe(select(selectedTransactionFeedItem));
    this.feedItemDownloadUrl$ = this.store$.pipe(
      select(selectedTransactionFeedDownloadUrl)
    );
    this.feedDataloading$ = this.store$.pipe(
      select(selectTransactionFeedLoading)
    );

    this.modalConfig$ = this.feedItem$.pipe(
      map((item) => {
        const processedDate = item?.createdAt;
        const date = processedDate
          ? formatDate(processedDate, 'MM/dd/yyyy hh:mm:ss', 'en-US').split(' ')
          : '';
        const dateFormatted = date.length ? `${date[0]} at ${date[1]}` : '';

        const configInfo: ModalInput = {
          modalId: 'transaction-feed-item-detail',
          title: 'MASTERCARD:',
          subtitle: 'TRANSACTION FILE',
          caption: `Processed on ${dateFormatted}`,
        };
        return configInfo;
      })
    );
  }

  onDownloadFeedFile(url) {
    this.store$.dispatch(
      TransactionFeedItemActions.downloadFeedItemFile({ url })
    );
  }

  onDismiss() {
    this.store$.dispatch(LayoutActions.dismissFeedItemModal());
  }
}
