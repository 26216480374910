import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  AdminActions,
  PersonActions,
  ProxyUserActions,
  UserActions,
} from '../actions';
import { Employee } from '@ems-gui/shared/util-core';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  filters: any;
  selectedId: string | number;
  proxyId: string | number;
  proxyPermissions: any[];
  userPermissions: any;
  mileageRate: string | number;
  searchedPersons: any[];
  searchedString: string;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<Employee>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  filters: {
    user: null,
    hasProxy: null,
    isProxy: null,
    sort: {
      colId: 'name_last',
      sort: 'asc',
    },
    role: null,
  },
  selectedId: null,
  proxyId: null,
  proxyPermissions: null,
  userPermissions: {},
  mileageRate: null,
  searchedPersons: [],
  searchedString: null
});

export const reducer = createReducer(
  initialState,
  on(
    PersonActions.getAllPersonComplete,
    AdminActions.getAllProxyStatusComplete,
    (state, { person }) =>
      adapter.setAll(person, {
        ...state,
        loading: false,
      })
  ),
  on(AdminActions.select, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(AdminActions.sort, (state, { colId, sort }) => ({
    ...state,
    filters: {
      ...state.filters,
      sort: {
        ...state.filters.sort,
        colId,
        sort,
      },
    },
  })),
  on(AdminActions.filter, (state, { id, hasProxy, isProxy, role }) => ({
    ...state,
    filters: {
      ...state.filters,
      user: id,
      hasProxy,
      isProxy,
      role,
    },
  })),
  on(AdminActions.resetFilters, (state) => ({
    ...state,
    filters: {
      ...state.filters,
      user: null,
      hasProxy: null,
      isProxy: null,
      role: null,
    },
    selectedId: null,
  })),
  on(AdminActions.setProxy, ProxyUserActions.setProxy, (state, { id }) => ({
    ...state,
    proxyId: id,
  })),
  on(
    AdminActions.removeProxy,
    ProxyUserActions.removeProxy,
    UserActions.logout,
    (state) => ({
      ...state,
      proxyId: null,
      selectedId: null,
      proxyPermissions: null,
    })
  ),
  on(AdminActions.getProxyPermissionsComplete, (state, { permissions }) => ({
    ...state,
    proxyPermissions: permissions,
  })),
  on(AdminActions.getAllUsersPermissionsComplete, (state, { permissions }) => ({
    ...state,
    userPermissions: {
      ...permissions,
    },
  })),

  on(
    PersonActions.addPerson,
    (state, { person }) =>
      adapter.addOne(person, {
        ...state,
        loading: false
      })
  ),

  on(
    AdminActions.getProxyPermissionsError,
    AdminActions.getAllUsersPermissionsError,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(PersonActions.getSearchedPersonsComplete, (state, { persons, searchString }) => ({
    ...state,
    searchedPersons: persons,
    searchedString: searchString
  })),
  on(PersonActions.resetSearchedPersons, (state) => ({
    ...state,
    searchedPersons: [],
    searchedString: null
  }))
);

export const selectedUserId = (state: State) => state.selectedId;
export const filters = (state: State) => state.filters;
export const proxyId = (state: State) => state.proxyId;
export const proxyUserPermissions = (state: State) => state.proxyPermissions;
export const mileageRate = (state: State) => state.mileageRate;
export const getAllUserPermissions = (state: State) => state.userPermissions;
export const getSearchedPersons = (state: State) => state.searchedPersons;
export const getSearchedString = (state: State) => state.searchedString;
