<div class="receipt-manager-modal">
  <header class="base-modal__header">
    <div class="base-modal__pre-header">
      <p class="base-modal__state-title">Warning</p>
    </div>

    <div class="base-modal__header-content">
      <span class="base-modal__title-icon"
        ><ems-base-icon
          class="base-modal__icon"
          icon-prepend
          iconName="remove"
        ></ems-base-icon
      ></span>
      <h1 class="base-modal__title">No Matching Receipts Found</h1>
    </div>
  </header>

  <div class="receipt-manager-modal__body">
    <p class="base-modal__subtitle">
      We did not find any receipts that match the provided IDs.
      <a (click)="onDownloadCsv()"> Download Error File.</a>
    </p>
    <div class="button-align">
      <ems-base-button
        (click)="onContinue()"
        label="Continue"
        buttonType="primary"
        variant="success"
      ></ems-base-button>
    </div>
  </div>
</div>
