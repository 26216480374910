<div class="base-tooltip">
  <ng-content></ng-content>
  <span
    [ngClass]="classNames()"
    class="base-tooltip__content"
    [class.full-address]="fullAddress"
  >
    <ems-base-caption>{{
      fullAddress ? formatAddress(content) : content
    }}</ems-base-caption>
  </span>
</div>
