<div class="datepicker-header">
  <button
    mat-icon-button
    (click)="previousClicked('month')"
    class="datepicker-header__button"
  >
    <mat-icon>
      <ems-base-icon
        icon-prepend
        iconName="page-prev"
        class="datepicker-header__icon"
      ></ems-base-icon>
    </mat-icon>
  </button>
  <span class="datepicker-header__label">{{ periodLabel }}</span>
  <button
    mat-icon-button
    (click)="nextClicked('month')"
    class="datepicker-header__button"
  >
    <mat-icon>
      <ems-base-icon
        icon-prepend
        iconName="page-next"
        class="datepicker-header__icon"
      ></ems-base-icon>
    </mat-icon>
  </button>
</div>
