import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IsLoadingComponent } from './components/is-loading/is-loading.component';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { CurrencyInputComponent } from "./components/money/currency-input.component";
import {
  FilterContainerComponent
} from "./components/filters/container/filter-container.component";
import { FormControlComponent } from "./components/form-control/form-control.component";
import { SelectComponent } from "./components/select/select.component";
import { MatIconModule } from "@angular/material/icon";
import { IconComponent } from "./icons/icon.component";
import { ReactiveFormsModule } from "@angular/forms";
import {
  DateRangeComponent
} from "./components/date-range/date-range.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  TwoCheckboxesComponent
} from "./components/two-checkboxes/two-checkboxes.component";
import {
  TwoRadioButtonsComponent
} from "@src/app/sis/components/two-radio-buttons/two-radio-buttons.component";

@NgModule({
  declarations: [
    IsLoadingComponent,
    FilterContainerComponent,
    CurrencyInputComponent,
    FormControlComponent,
    SelectComponent,
    IconComponent,
    DateRangeComponent,
    TwoCheckboxesComponent,
    TwoRadioButtonsComponent
  ],
  exports: [
    IsLoadingComponent,
    FilterContainerComponent,
    CurrencyInputComponent,
    FormControlComponent,
    SelectComponent,
    IconComponent,
    DateRangeComponent,
    TwoCheckboxesComponent,
    TwoRadioButtonsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule
  ]
})
export class SisModule { }
