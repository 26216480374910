<!-- Check for OCR Mismatch First -->
<div *ngIf="ocrMismatch; else hasReceiptTemplate">
  <ng-container *ngTemplateOutlet="ocrMismatchTemplate"></ng-container>
</div>

<!-- Template for OCR Mismatch -->
<ng-template #ocrMismatchTemplate>
  <ng-container>
    <div class="receipt-container">
      <div class="receipt-indicator mismatch-icon-color">
        <div class="receipt-indicator__icon mismatch-icon" >
          <ems-base-tooltip
            direction="right"
            content="Receipt Image Details Mismatch"
          >
            <ems-base-icon
              iconName="{{icon}}"
              class="no-image"
            ></ems-base-icon>
          </ems-base-tooltip>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Template for Has Receipt -->
<ng-template #hasReceiptTemplate>
  <div class="receipt-container" *ngIf="hasReceipt; else noReceiptTemplate">
    <ng-container>
      <div class="receipt-indicator">
        <div class="receipt-indicator__icon">
          <ems-base-icon iconName="{{icon}}" class="with-image"></ems-base-icon>
        </div>
      </div>
      <div *ngIf="parent !== null" class="itemize-icon">
        <ems-base-tooltip
          direction="right"
          content="This expense has been itemized."
        >
          <ems-icon-itemize></ems-icon-itemize>
        </ems-base-tooltip>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Template for No Receipt -->
<ng-template #noReceiptTemplate>
  <ng-container>
    <div class="receipt-container">
      <div class="receipt-indicator">
        <div class="receipt-indicator__icon">
          <ems-base-icon
            iconName="{{icon}}"
            class="no-image"
          ></ems-base-icon>
        </div>
      </div>
      <div *ngIf="parent !== null" class="itemize-icon">
        <ems-base-tooltip
          direction="right"
          content="This expense has been itemized."
        >
          <ems-icon-itemize></ems-icon-itemize>
        </ems-base-tooltip>
      </div>
    </div>
  </ng-container>
</ng-template>
