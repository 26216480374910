import { ExpenseFilter } from '@ems-gui/shared/util-core';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  ActivityActions,
  ApprovalActions,
  DraftActions,
  FilterActions,
  LayoutActions,
  MileageDraftActions,
  NewActions,
  NewMileageActions,
  SubmittedActions,
  TrashActions,
  UserActions,
} from "../actions";

export interface State extends EntityState<any> {
  draft: {
    loading: boolean;
    error: string;
    selectedId: string | number;
    totalCount: number;
    autoSaving: boolean;
    autoSavedId: string | number;
    selectItemizeId: string | number;
    isSaveSuccessful: boolean;
    activityId: number;
    reimbursement: number;
    itemizeConfirmed: boolean;
    canItemizeExpense: boolean;
    receiptPhoto: string;
    itemizedExpenses: any[];
    itemizedParentData: {
      id: number;
      amount: number;
      receipt: string;
    };
    amount: number;
    fraudulent: boolean;
    submitFraudForm: any;
    selectedDrafts: number[];
    selectedRejected: number[];
    selectedMultiEditExpenses: any[];
  };
  submitted: {
    loading: boolean;
    error: string;
    selectedId: string | number;
    totalCount: number;
  };
  new: {
    loading: boolean;
    error: string;
    receiptPhoto: string;
    receiptData: {};
    receiptDataByToken: {};
    editing: boolean;
    autoSavedId: string | number;
    autoSaving: boolean;
    activityId: number;
    reimbursement: number;
    itemizeConfirmed: boolean;
    canItemizeExpense: boolean;
  };
  trash: {
    loading: boolean;
    error: string;
    totalCount: number;
    selectedId: number;
    selectedExpenses: number[];
  };
  filterCount: {
    unsubmitted: number;
    submitted: number;
    trash: number;
  };
  filterType: string;
  filters: {
    unsubmitted: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
      selectedPaymentIndex: any;
      selectedStatusIndex: any;
      startDate: any;
      endDate: any;
    };
    submitted: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
      selectedPaymentIndex: any;
      selectedStatusIndex: any;
      startDate: any;
      endDate: any;
    };
    trash: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
      selectedPaymentIndex: any;
      selectedStatusIndex: any;
      startDate: any;
      endDate: any;
    };
  };
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

const baseState = {
  draft: {
    loading: false,
    error: '',
    selectedId: null,
    totalCount: 0,
    selectItemizeId: null,
    autoSavedId: null,
    autoSaving: null,
    isSaveSuccessful: null,
    activityId: null,
    reimbursement: null,
    itemizeConfirmed: false,
    receiptPhoto: '',
    itemizedExpenses: [],
    itemizedParentData: {
      id: null,
      amount: 0,
      receipt: '',
    },
    canItemizeExpense: true,
    amount: 0,
    fraudulent: false,
    submitFraudForm: {},
    selectedDrafts: [],
    selectedRejected: [],
    selectedMultiEditExpenses: []
  },
  submitted: {
    loading: false,
    error: '',
    selectedId: null,
    totalCount: 0,
  },
  new: {
    loading: false,
    error: '',
    receiptPhoto: '',
    receiptData: {
      paymentType: 3,
      isBillable: false,
    },
    receiptDataByToken: {},
    editing: false,
    autoSavedId: null,
    autoSaving: null,
    activityId: null,
    reimbursement: null,
    itemizeConfirmed: false,
    canItemizeExpense: true,
  },
  trash: {
    loading: false,
    error: '',
    totalCount: 0,
    selectedId: null,
    selectedExpenses: [],
  },
  filterCount: {
    unsubmitted: 0,
    submitted: 0,
    trash: 0,
  },
  filterType: '',
  filters: {
    unsubmitted: {
      page: 0,
      sort: {
        colId: 'transactionDate',
        sort: 'DESC',
      },
      filters: {
      },
      selectedPaymentIndex: null,
      selectedStatusIndex: null,
      startDate: null,
      endDate: null,
    },
    submitted: {
      page: 0,
      sort: {
        colId: 'transactionDate',
        sort: 'DESC',
      },
      filters: {
      },
      selectedPaymentIndex: null,
      selectedStatusIndex: null,
      startDate: null,
      endDate: null,
    },
    trash: {
      page: 0,
      sort: {
        colId: 'transactionDate',
        sort: 'DESC',
      },
      filters: {},
      selectedPaymentIndex: null,
      selectedStatusIndex: null,
      startDate: null,
      endDate: null,
    },
    reviewed: {
      page: 0,
      totalCount: 0,
      sort: {
        colId: 'transactionDate',
        sort: 'DESC',
      },
      filters: {},
      selectedPaymentIndex: null,
      selectedStatusIndex: null,
      startDate: null,
      endDate: null,
    },
  },
};

export const initialState: State = adapter.getInitialState(baseState);

export const reducer = createReducer(
  initialState,
  /**
   * Submitted Actions
   */
  on(
    SubmittedActions.getFilteredUnsubmitted,
    (state, { page, sort, filters }) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: true
      },
      filters: {
        ...state.filters,
        unsubmitted: {
          ...state.filters.unsubmitted,
          page,
          sort: {
            ...state.filters.unsubmitted.sort,
            ...sort,
          },
          filters: {
            ...state.filters.unsubmitted.filters,
            ...filters,
          },
        },
      },
    })
  ),
  on(SubmittedActions.getUnsubmitted, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      loading: true,
    },
  })),
  on(SubmittedActions.getUnsubmittedComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses.expenses, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        totalCount: expenses.totalCount,
        selectedDrafts: [],
      },
      trash: {
        ...state.trash,
        selectedExpenses: [],
      },
    })
  ),
  on(SubmittedActions.getFilteredUnsubmittedComplete, (state, { expenses }) =>
    adapter.setAll(expenses.expenses, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        totalCount: expenses.totalCount,
        selectedDrafts: []
      },
      trash: {
        ...state.trash,
        selectedExpenses: [],
      },
    })
  ),
  on(SubmittedActions.getSubmitted, (state) => ({
    ...state,
    submitted: {
      ...state.submitted,
      loading: true,
    },
  })),
  on(SubmittedActions.getSubmittedComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses.expenses, {
      ...state,
      submitted: {
        ...state.submitted,
        loading: false,
        totalCount: expenses.totalCount,
      },
      trash: {
        ...state.trash,
        selectedExpenses: [],
      },
      draft: {
        ...state.draft,
        selectedDrafts: [],
      },
    })
  ),
  on(
    SubmittedActions.getFilteredSubmitted,
    (state, { page, sort, filters }) => ({
      ...state,
      submitted: {
        ...state.submitted,
        loading: true
      },
      filters: {
        ...state.filters,
        submitted: {
          ...state.filters.submitted,
          page,
          sort: {
            ...state.filters.submitted.sort,
            ...sort
          },
          filters: {
            ...state.filters.submitted.filters,
            ...filters
          },
        },
      },
    })
  ),
  on(SubmittedActions.getFilteredSubmittedComplete, (state, { expenses }) =>
    adapter.setAll(expenses.expenses, {
      ...state,
      submitted: {
        ...state.submitted,
        loading: false,
        totalCount: expenses.totalCount,
      },
      trash: {
        ...state.trash,
        selectedExpenses: [],
      },
      draft: {
        ...state.draft,
        selectedDrafts: [],
      },
    })
  ),
  on(SubmittedActions.getAllExpenses, (state) => ({
    ...state,
    submitted: {
      ...state.submitted,
      loading: true,
    },
    draft: {
      ...state.draft,
      loading: true,
    },
    trash: {
      ...state.trash,
      loading: true,
    },
  })),
  on(SubmittedActions.getAllExpensesComplete, (state, { expenses, counts }) =>
    adapter.upsertMany(expenses, {
      ...state,
      submitted: {
        ...state.submitted,
        loading: false,
        totalCount: counts.submitted,
      },
      draft: {
        ...state.draft,
        loading: false,
        activityId: null,
        totalCount: counts.drafts,
      },
      trash: {
        ...state.trash,
        loading: false,
        totalCount: counts.trash,
      },
    })
  ),
  on(
    SubmittedActions.getSubmittedError,
    SubmittedActions.getUnsubmittedError,
    SubmittedActions.getAllExpensesError,
    SubmittedActions.getFilteredUnsubmittedError,
    SubmittedActions.getFilteredSubmittedError,
    (state, { error }) => ({
      ...state,
      submitted: {
        ...state.submitted,
        loading: false,
        error
      },
      draft: {
        ...state.draft,
        loading: false,
        error
      },
    })
  ),
  on(
    DraftActions.submitComplete,
    MileageDraftActions.submitDraftComplete,
    (state, { expense }) =>
      adapter.upsertOne(expense, {
        ...state,
        submitted: {
          ...state.submitted,
          loading: false,
        },
        draft: {
          ...state.draft,
          totalCount: state.draft.totalCount - 1,
        },
      })
  ),
  on(SubmittedActions.selectExpense, (state, { id }) => ({
    ...state,
    submitted: {
      ...state.submitted,
      selectedId: id,
    },
  })),
  on(ApprovalActions.selectExpense, (state, { id }) => ({
    ...state,
    submitted: {
      ...state.submitted,
      selectedId: null,
    }
  })),
  /**
   * Draft Actions
   */
  on(DraftActions.getDrafts, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      loading: true,
    },
  })),
  on(DraftActions.getDraftsComplete, (state, { expenses }) =>
    adapter.setAll(expenses, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        selectedId: null,
      },
    })
  ),
  on(
    DraftActions.getDraftsError,
    DraftActions.trashDraftsError,
    MileageDraftActions.trashDraftsError,
    DraftActions.saveWithoutNoteError,
    DraftActions.saveWithNoteError,
    MileageDraftActions.saveWithNoteError,
    MileageDraftActions.saveWithoutNoteError,
    NewMileageActions.saveWithNoteError,
    NewMileageActions.saveWithoutNoteError,
    MileageDraftActions.getExpenseError,
    DraftActions.getNoReceiptDataDraftError,
    DraftActions.getItemizedParentDataError,
    (state, { error }) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        autoSaving: null,
        isSaveSuccessful: false,
        error,
      },
    })
  ),
  on(
    DraftActions.deleteDraft,
    DraftActions.getItemizedExpenses,
    DraftActions.getNoReceiptDataDraft,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: true,
      },
    })
  ),
  on(DraftActions.getItemizedParentData, (state, { id }) => ({
    ...state,
    draft: {
      ...state.draft,
      loading: true,
      itemizedParentData: {
        ...state.draft.itemizedParentData,
        id,
      },
    },
  })),
  on(
    DraftActions.getItemizedParentDataComplete,
    (state, { amount, receipt }) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        itemizedParentData: {
          ...state.draft.itemizedParentData,
          amount,
          receipt,
        },
      },
    })
  ),
  on(
    DraftActions.removeReceiptComplete,
    MileageDraftActions.removeReceiptComplete,
    (state, { expense }) =>
      adapter.upsertOne(expense, {
        ...state,
        draft: {
          ...state.draft,
          loading: false,
        },
      })
  ),
  on(
    SubmittedActions.getOneExpenseComplete,
    (state, { expense }) => adapter.upsertOne(
      expense,
      { ...state }
      )
    ),
  on(
    MileageDraftActions.getExpenseComplete,
    DraftActions.getExpenseDataComplete,
    MileageDraftActions.savePhotoComplete,
    DraftActions.getUpdatedDraftComplete,
    (state, { expense }) =>
      adapter.updateOne(
        { id: expense.id, changes: expense },
        {
          ...state,
          draft: {
            ...state.draft,
            loading: false,
            receiptPhoto: expense.image,
          },
        }
      )
  ),
  on(DraftActions.addReceiptToDraftComplete, (state, { draft }) =>
    adapter.updateOne(
      { id: draft.id, changes: { ...draft, image: state.draft.receiptPhoto } },
      {
        ...state,
        draft: {
          ...state.draft,
          loading: false,
        },
      }
    )
  ),
  on(DraftActions.deleteDraftComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        selectedId: null,
      },
    })
  ),
  on(
    NewMileageActions.saveDraftComplete,
    NewActions.saveDraftComplete,
    (state, { expense }) =>
      adapter.upsertOne(expense, {
        ...state,
        new: {
          ...state.new,
          receiptData: {},
          receiptPhoto: '',
          loading: false,
        },
      })
  ),
  on(NewActions.cancel, (state, { expense }) =>
    adapter.removeOne(expense, {
      ...state,
      draft: {
        ...state.draft,
        loading: true,
      },
    })
  ),
  on(
    NewMileageActions.getEstimatedReimbursementComplete,
    (state, { reimbursement }) => ({
      ...state,
      new: {
        ...state.new,
        reimbursement: reimbursement,
      },
    })
  ),
  on(
    MileageDraftActions.getEstimatedReimbursementComplete,
    (state, { reimbursement }) => ({
      ...state,
      draft: {
        ...state.draft,
        reimbursement,
      },
    })
  ),
  on(
    DraftActions.editDraft,
    (state, { id }) => ({
      ...state,
      draft: {
        ...state.draft,
        selectedId: id,
        autoSaving: null,
        isSaveSuccessful: null,
        loading: true
      },
    })
  ),
  on(
    MileageDraftActions.editDraft,
    (state, { id }) => ({
      ...state,
      draft: {
        ...state.draft,
        selectedId: id,
        autoSaving: null,
        isSaveSuccessful: null
      },
    })
  ),
  on(
    DraftActions.editMultiDraft,
    (state, { expenses }) => ({
      ...state,
      draft: {
        ...state.draft,
        selectedMultiEditExpenses: expenses,
        autoSaving: null,
        isSaveSuccessful: null
      },
    })
  ),

  on(NewActions.getExpenseData, (state, { token }) => ({
    ...state,
    draft: {
      ...state.draft,
      selectedId: token,
      autoSaving: null,
      isSaveSuccessful: null
    },
  })),
  on(
    DraftActions.rotateReceipt,
    MileageDraftActions.rotateReceipt,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        autoSaving: true,
        isSaveSuccessful: null
      },
    })
  ),
  on(
    DraftActions.applyExpenseToState,
    MileageDraftActions.applyExpenseToState,
    (state, { expense }) => {
      return adapter.upsertOne(expense, state);
    }
  ),
  on(
    DraftActions.rotateReceiptComplete,
    MileageDraftActions.rotateReceiptComplete,
    (state, { expense }) =>
      adapter.updateOne(
        { id: expense.id, changes: expense },
        {
          ...state,
          draft: {
            ...state.draft,
            ...expense,
          },
          loading: false,
        }
      )
  ),
  on(DraftActions.deselectOneDraft, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      selectedId: null,
    },
  })),
  on(
    NewActions.getExpenseDataError,
    DraftActions.updateItemizedExpensesError,
    DraftActions.itemizeError,
    DraftActions.undoItemizationError,
    DraftActions.removeReceiptError,
    DraftActions.getExpenseDataError,
    MileageDraftActions.removeReceiptError,
    MileageDraftActions.rotateReceiptError,
    DraftActions.getUpdatedDraftError,
    (state, { error }) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        error,
      },
    })
  ),
  on(
    DraftActions.trashDraftsComplete,
    MileageDraftActions.trashDraftsComplete,
    (state, { expenses }) =>
      adapter.upsertMany(expenses, {
        ...state,
        draft: {
          ...state.draft,
          loading: false,
          totalCount: state.draft.totalCount - expenses.length,
        },
        trash: {
          ...state.trash,
          loading: false,
          totalCount: state.trash.totalCount + expenses.length,
        },
      })
  ),
  on(DraftActions.save, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      loading: true,
      autoSaving: true,
      isSaveSuccessful: null
    },
  })),
  on(DraftActions.saveExpenseWithReceipt, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      loading: true,
      autoSaving: true,
      isSaveSuccessful: null
    },
  })),
  
  on(
    MileageDraftActions.saveDraft,
    MileageDraftActions.submitDraft,
    DraftActions.updateItemizedExpenses,
    DraftActions.itemize,
    MileageDraftActions.uploadComplete,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: true,
      },
    })
  ),
  on(
    MileageDraftActions.saveDraftComplete,
    MileageDraftActions.submitDraftComplete,
    DraftActions.cancelAddReceipt,
    MileageDraftActions.cancelAddReceipt,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        receiptData: {},
        receiptPhoto: '',
        loading: false,
      },
    })
  ),
  on(
    MileageDraftActions.save,
    NewMileageActions.save,
    (state) => ({
    ...state,
    draft: {
      ...state.draft,
      autoSaving: true,
      isSaveSuccessful: null
    },
  })),
  on(
    DraftActions.resetFormComplete,
    MileageDraftActions.resetFormComplete,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        receiptData: {},
        receiptPhoto: '',
        reimbursement: null,
      },
    })
  ),
  on(
    DraftActions.saveWithoutNoteComplete,
    DraftActions.saveWithNoteComplete,
    DraftActions.saveComplete,
    MileageDraftActions.saveWithNoteComplete,
    MileageDraftActions.saveWithoutNoteComplete,
    NewMileageActions.saveWithNoteComplete,
    NewMileageActions.saveWithoutNoteComplete,
    (state, { expense }) =>
    adapter.upsertOne(
      expense.expense,
      {
        ...state,
        draft: {
          ...state.draft,
          autoSaving: false,
          autoSavedId: expense.expense.id,
          loading: false,
          isSaveSuccessful: true,
          selectItemizeId: expense.expense.id
        },
      })
  ),
  on(DraftActions.updateAmount, (state, { amount }) => ({
    ...state,
    draft: {
      ...state.draft,
      amount,
    },
  })),
  on(ActivityActions.getUnsubmittedActivityComplete, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      autoSaving: null,
      isSaveSuccessful: null
    },
  })),
  on(
    DraftActions.submitDrafts,
    DraftActions.resubmit,
    DraftActions.submitAllValidDrafts,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: true,
      },
    })
  ),
  on(
    DraftActions.submitDraftsComplete,
    DraftActions.resubmitComplete,
    DraftActions.submitAllValidDraftsComplete,
    (state, { expenses }) =>
      adapter.upsertMany([...expenses], {
        ...state,
        draft: {
          ...state.draft,
          loading: false,
          totalCount: state.draft.totalCount - expenses.length,
          selectedDrafts: [],
          selectedRejected: [],
        },
      })
  ),
  on(DraftActions.updateItemizedExpensesComplete, (state, { expenses }) =>
    adapter.upsertMany([...expenses.create, ...expenses.update], {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        totalCount: state.draft.totalCount + expenses.create.length,
      },
    })
  ),
  on(
    DraftActions.updateItemizedExpensesComplete,
    DraftActions.undoItemizationComplete,
    (state, { expenses }) =>
      adapter.removeMany([...expenses.delete], {
        ...state,
        draft: {
          ...state.draft,
          loading: false,
          totalCount: state.draft.totalCount - expenses.delete.length,
          itemizedExpenses: [],
        },
      })
  ),
  on(DraftActions.undoItemizationComplete, (state, { expenses }) =>
    adapter.upsertOne(expenses.update, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        totalCount: state.draft.totalCount - expenses.delete.length,
        itemizedExpenses: [],
        selectedDrafts: [],
        selectedId: null,
        autoSavedId: null,
      },
    })
  ),
  on(DraftActions.cancelSelectDrafts, (state) => ({
    ...state,
    trash: {
      ...state.trash,
      selectedExpenses: [],
    },
    draft: {
      ...state.draft,
    },
  })),
  on(
    DraftActions.submitDraftsError,
    DraftActions.resubmitError,
    DraftActions.submitAllValidDraftsError,
    (state, { error }) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        error,
      },
    })
  ),
  on(ActivityActions.getDraftActivityId, (state, { activity }) => ({
    ...state,
    draft: {
      ...state.draft,
      activityId: activity,
    },
  })),
  on(ActivityActions.deleteDraftNoteComplete, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      activityId: null,
    },
  })),
  on(ActivityActions.deleteNewExpenseNoteComplete, (state) => ({
    ...state,
    new: {
      ...state.new,
      activityId: null,
    },
  })),
  on(
    MileageDraftActions.selectPhotoLibrary,
    MileageDraftActions.takePhoto,
    DraftActions.selectPhotoLibrary,
    DraftActions.takePhoto,
    (state) => ({
      ...state,
      draft: {
        ...state.draft,
        loading: true,
      },
    })
  ),
  on(
    MileageDraftActions.selectPhotoLibraryComplete,
    MileageDraftActions.takePhotoComplete,
    DraftActions.selectPhotoLibraryComplete,
    DraftActions.takePhotoComplete,
    (state, { uri }) => ({
      ...state,
      draft: {
        ...state.draft,
        receiptPhoto: uri,
        loading: true,
      },
    })
  ),
  on(DraftActions.getItemizedExpensesComplete, (state, { expenses }) => ({
    ...state,
    draft: {
      ...state.draft,
      loading: false,
      itemizedExpenses: [...expenses],
    },
  })),
  on(DraftActions.itemizeComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
        itemizeConfirmed: false,
        totalCount: state.draft.totalCount + (expenses.length - 1),
        amount: 0,
      },
    })
  ),
  on(DraftActions.itemizeConfirm, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      itemizeConfirmed: true,
    },
  })),
  on(DraftActions.unableToItemizeReset, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      canItemizeExpense: true,
    },
  })),
  on(DraftActions.unableToItemize, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      canItemizeExpense: false,
    },
  })),
  on(DraftActions.cancelItemization, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      canItemizeExpense: true,
      itemizedExpenses: [],
      itemizeConfirmed: false,
      amount: 0,
      loading: false,
    },
  })),
  on(DraftActions.selectDrafts, (state, { ids }) => ({
    ...state,
    draft: {
      ...state.draft,
      selectedDrafts: ids,
    },
  })),
  on(DraftActions.selectRejectedExpenses, (state, { ids }) => ({
    ...state,
    draft: {
      ...state.draft,
      selectedRejected: ids,
    },
  })),
  /**
   * New Expense Actions
   */
  on(
    NewMileageActions.submitOne,
    NewMileageActions.submit,
    NewActions.submit,
    NewActions.trashAutoSaved,
    NewActions.itemize,
    NewMileageActions.trashAutoSaved,
    (state) => ({
      ...state,
      new: {
        ...state.new,
        loading: true,
      },
    })
  ),
  on(
    NewMileageActions.submitOneComplete,
    NewMileageActions.submitComplete,
    NewActions.submitComplete,
    (state, { expense }) =>
      adapter.upsertOne(expense, {
        ...state,
        new: {
          ...state.new,
          loading: false,
          receiptData: {},
          receiptPhoto: '',
        },
      })
  ),
  on(
    NewMileageActions.getExpenseComplete,
    DraftActions.getNoReceiptDataDraftComplete,
    (state, { expense }) => {
      return adapter.upsertOne(expense, {
        ...state,
        new: {
          ...state.new,
          loading: false,
        },
        draft: {
          ...state.draft,
          selectedId: expense.id,
          selectedDrafts: [],
          loading: false,
        },
      })
    }
  ),
  on(NewActions.itemizeComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      new: {
        ...state.new,
        loading: false,
        itemizeConfirmed: false,
        selectItemizeId: null
      },
      draft: {
        ...state.draft,
        selectedId: null,
      },
    })
  ),
  on(NewActions.itemizeConfirm, (state) => ({
    ...state,
    new: {
      ...state.new,
      itemizeConfirmed: true,
    },
  })),
  on(NewActions.unableToItemizeReset, (state) => ({
    ...state,
    new: {
      ...state.new,
      canItemizeExpense: true,
    },
  })),
  on(NewActions.unableToItemize, (state) => ({
    ...state,
    new: {
      ...state.new,
      canItemizeExpense: false,
    },
  })),
  on(NewActions.cancelItemization, (state) => ({
    ...state,
    new: {
      ...state.new,
      loading: false,
      itemizeConfirmed: false,
    },
  })),
  on(
    NewMileageActions.cancelComplete,
    NewActions.cancelComplete,
    NewMileageActions.cancelAddReceipt,
    (state) => ({
      ...state,
      new: {
        ...state.new,
        receiptPhoto: '',
        receiptData: {},
        loading: false,
      },
    })
  ),
  on(
    NewActions.submitError,
    NewMileageActions.submitError,
    NewActions.autoSaveWithNoteError,
    NewActions.autoSaveWithoutNoteError,
    NewMileageActions.saveWithNoteError,
    NewMileageActions.saveWithoutNoteError,
    NewActions.trashAutoSavedError,
    NewMileageActions.trashAutoSavedError,
    NewActions.itemizeError,
    NewActions.uploadError,
    NewActions.createNewExpenseError,
    (state, { error }) => ({
      ...state,
      new: {
        ...state.new,
        loading: false,
        autoSaving: null,
        error,
      },
    })
  ),
  on(LayoutActions.globalToastErrorMessage, (state) => ({
    ...state,
    new: {
      ...state.new,
      loading: false
    }
  })),
  on(NewActions.takePhoto, (state) => ({
    ...state,
    draft: {
      ...state.draft,
    },
  })),
  on(
    NewMileageActions.selectPhotoLibraryComplete,
    NewMileageActions.takePhotoComplete,
    NewActions.selectPhotoLibraryComplete,
    NewActions.takePhotoComplete,
    (state, { uri }) => ({
      ...state,
      new: {
        ...state.new,
        loading: true,
        receiptPhoto: uri,
      },
    })
  ),
  on(NewActions.savePhotoComplete, (state, { tokenData }) => ({
    ...state,
    new: {
      ...state.new,
      receiptData: {
        ...state.new.receiptData,
        id: tokenData,
      },
    },
  })),
  on(NewActions.ocrOnlyComplete, (state,{ expense }) => ({
    ...state,
    new: {
      ...state.new,
      receiptData: {
        ...expense
      },
    },
  })),
  on(DraftActions.getReceiptDataByTokenComplete, (state,{ expense }) => ({
    ...state,
    new: {
      ...state.new,
      receiptDataByToken: {
        ...expense
      },
    },
  })),
  on(DraftActions.clearReceiptDataByToken, (state) => ({
    ...state,
    new: {
      ...state.new,
      receiptDataByToken: {},
    },
  })),
  on(NewActions.clearReceiptData, (state) => ({
    ...state,
    new: {
      ...state.new,
      receiptData: {},
    },
  })),
  on(NewActions.getExpenseDataComplete, (state, { expenseData }) =>
    adapter.upsertOne(expenseData, {
      ...state,
      new: {
        ...state.new,
        loading: false,
        receiptData: {
          ...expenseData,
        },
        editing: false,
      },
      draft: {
        ...state.draft,
        selectedId: expenseData.id,
        receiptPhoto: expenseData.image,
      },
    })
  ),
  on(
    NewActions.getExpenseDataError,
    NewActions.savePhotoError,
    (state, { error }) => ({
      ...state,
      new: {
        ...state.new,
        loading: false,
        receiptPhoto: '',
        error,
      },
    })
  ),
  on(
    NewMileageActions.saveExpenseData,
    NewActions.saveExpenseData,
    (state, { expenseData }) => ({
      ...state,
      new: {
        ...state.new,
        loading: false,
        receiptData: expenseData,
      },
    })
  ),
  on(DraftActions.deselectOneDraft, (state) => ({
    ...state,
    new: {
      ...state.new,
      editing: false,
    },
  })),
  on(NewMileageActions.resetFormComplete, (state) => ({
    ...state,
    new: {
      ...state.new,
      receiptData: {},
      receiptPhoto: '',
      reimbursement: null,
    },
  })),
  on(NewActions.resetFormComplete, (state) => ({
    ...state,
    new: {
      ...state.new,
      receiptData: {
        paymentType: 3,
        isBillable: false,
      },
      receiptPhoto: '',
    },
  })),
  on(
    NewActions.createNewExpenseComplete,
    (state, { expense }) =>
      adapter.upsertOne(expense, {
        ...state,
        new: {
          ...state.new,
          autoSavedId: expense.id,
          autoSaving: false,
          loading: false,
          receiptData: {},
        },
        draft: {
          ...state.draft,
          selectedId: expense.id,
        },
      })
  ),
  on(
    NewActions.createNewExpense,
    (state) => ({
      ...state,
      new: {
        ...state.new,
        autoSaving: true,
        loading: true
      },
    })
  ),
  on(
    NewActions.autoSave,
    NewMileageActions.save,
    (state) => ({
    ...state,
    new: {
      ...state.new,
      autoSaving: true,
    },
  })),
  on(
    NewActions.autoSaveWithNoteComplete,
    NewActions.autoSaveWithoutNoteComplete,
    NewMileageActions.saveWithNoteComplete,
    NewMileageActions.saveWithoutNoteComplete,
    (state, { expense }) =>
      adapter.upsertOne(expense.expense, {
        ...state,
        new: {
          ...state.new,
          autoSavedId: expense.expense.id,
          autoSaving: false,
        },
        draft: {
          ...state.draft,
          selectedId: expense.expense.id,
        },
      })
  ),
  on(
    NewActions.trashAutoSavedComplete,
    NewMileageActions.trashAutoSavedComplete,
    (state) => ({
      ...state,
      new: {
        ...state.new,
        loading: false,
        autoSavedId: null,
      },
      trash: {
        ...state.trash,
        totalCount: state.trash.totalCount + 1,
      },
    })
  ),
  on(LayoutActions.dismissModal, (state) => ({
    ...state,
    new: {
      ...state.new,
      autoSavedId: null,
      autoSaving: null,
      activityId: null,
      reimbursement: null,
      itemizeConfirmed: false,
      canItemizeExpense: true,
      receiptPhoto: '',
    },
    draft: {
      ...state.draft,
      selectedId: null,
      autoSaving: null,
      isSaveSuccessful: null,
      autoSavedId: null,
      activityId: null,
      reimbursement: null,
      itemizeConfirmed: false,
      canItemizeExpense: true,
      receiptPhoto: '',
      amount: 0,
      selectedDrafts: [],
      selectedRejected: [],
      submitFraudForm: {},
    },
  })),
  on(
    LayoutActions.openNewExpenseModal,
    LayoutActions.openExpenseModal,
    (state) => ({
      ...state,
      new: {
        ...state.new,
        autoSavedId: null,
      },
    })
  ),
  on(ActivityActions.getNewExpenseActivityId, (state, { id }) => ({
    ...state,
    new: {
      ...state.new,
      activityId: id,
    },
  })),
  on(
    LayoutActions.openReceiptDataErrorModal,
    LayoutActions.openReceiptSizeErrorModal,
    (state) => ({
      ...state,
      new: {
        ...state.new,
        loading: false,
      },
      draft: {
        ...state.draft,
        loading: false,
      },
    })
  ),
  /**
   * Trash Actions
   */
  on(
    TrashActions.getTrash,
    TrashActions.restoreExpenses,
    TrashActions.deleteExpenses,
    (state) => ({
      ...state,
      trash: {
        ...state.trash,
        loading: true,
      },
    })
  ),
  on(TrashActions.getTrashComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses.expenses, {
      ...state,
      trash: {
        ...state.trash,
        loading: false,
        totalCount: expenses.totalCount,
      },
      draft: {
        ...state.draft,
        selectedDrafts: [],
      },
    })
  ),
  on(TrashActions.getFilteredTrash, (state, { page, sort, filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      trash: {
        ...state.filters.trash,
        page,
        sort: {
          ...state.filters.trash.sort,
          ...sort,
        },
        filters: {
          ...state.filters.trash.filters,
          ...filters,
        },
      },
    },
  })),
  on(TrashActions.getFilteredTrashComplete, (state, { expenses }) =>
    adapter.setAll(expenses.expenses, {
      ...state,
      trash: {
        ...state.trash,
        loading: false,
        totalCount: expenses.totalCount,
      },
      draft: {
        ...state.draft,
        selectedDrafts: [],
      },
    })
  ),
  on(TrashActions.deleteExpensesComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      trash: {
        ...state.trash,
        loading: false,
        selectedExpenses: [],
        totalCount: state.trash.totalCount - expenses.length,
      },
    })
  ),
  on(
    TrashActions.getTrashError,
    TrashActions.restoreExpensesError,
    TrashActions.deleteExpensesError,
    (state, { error }) => ({
      ...state,
      trash: {
        ...state.trash,
        loading: false,
        error,
      },
    })
  ),
  on(TrashActions.restoreExpensesComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      trash: {
        ...state.trash,
        loading: false,
        selectedExpenses: [],
        totalCount: state.trash.totalCount - expenses.length,
      },
      draft: {
        ...state.draft,
        loading: false,
        selectedExpenses: [],
        totalCount: state.draft.totalCount + expenses.length,
      },
    })
  ),
  on(
    TrashActions.selectExpense,
    TrashActions.selectMileageExpense,
    (state, { id }) => ({
      ...state,
      trash: {
        ...state.trash,
        selectedId: id,
      },
    })
  ),
  on(TrashActions.selectTrashedExpenses, (state, { expenses }) => ({
    ...state,
    draft: {
      ...state.draft,
    },
    trash: {
      ...state.trash,
      selectedExpenses: [...expenses],
    },
  })),
  on(TrashActions.emptyTrash, (state) => ({
    ...state,
    trash: {
      ...state.trash,
      loading: true,
    },
  })),
  on(TrashActions.emptyTrashComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      trash: {
        ...state.trash,
        loading: false,
        selectedExpenses: [],
        totalCount: 0,
      },
    })
  ),
  /**
   * Filter Actions
   */
  on(FilterActions.setFilterCount, (state, { name, count }) => ({
    ...state,
    filterCount: {
      ...state.filterCount,
      [name]: count,
    },
  })),
  on(FilterActions.setFilters, (state, { name, filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      [name]: filters,
    },
  })),
  on(FilterActions.clearFilters, (state, { name }) => ({
    ...state,
    filters: {
      ...state.filters,
      [name]: adapter.getInitialState(baseState).filters[name],
    },
    filterCount: {
      ...state.filterCount,
      [name]: 0,
    },
  })),
  on(FilterActions.setFilterType, (state, { filterType }) => ({
    ...state,
    filterType,
  })),
  on(FilterActions.setExpenseTypeId, (state, { id, filterType }) => ({
    ...state,
    filters: {
      ...state.filters,
      [filterType]: {
        ...state.filters[filterType],
        filters: {
          ...state.filters[filterType].filters,
          type: id,
        },
      },
    },
  })),
  on(FilterActions.setJobCodeId, (state, { id, filterType }) => ({
    ...state,
    filters: {
      ...state.filters,
      [filterType]: {
        ...state.filters[filterType],
        filters: {
          ...state.filters[filterType].filters,
          jobCode: id,
        },
      },
    },
  })),
  on(UserActions.logout, () => adapter.getInitialState(baseState)),
  on(DraftActions.submitFraudDraft, (state, { form }) => ({
    ...state,
    draft: {
      ...state.draft,
      submitFraudForm: form,
    },
  })),
  on(DraftActions.submitFraudDraftComplete, (state) => ({
    ...state,
    draft: {
      ...state.draft,
      submitFraudForm: {},
    },
  })),
  on(SubmittedActions.getEmailedReceiptComplete, (state, { expenses }) =>
    adapter.updateMany(expenses.expenses, {
      ...state,
      draft: {
        ...state.draft,
        loading: false,
      },
    })
  )
);

/**
 * Draft Selectors
 */
export const draftLoading = (state: State) => state.draft.loading;
export const continueFraudulentSubmission = (state: State) =>
  state.draft.submitFraudForm;
export const getSelectedDraftId = (state: State) => state.draft.selectedId;
export const getSelectedItemizedId = (state: State) => state.draft.selectItemizeId;
export const autoSaving = (state: State) => state.draft.autoSaving;
export const isSaveSuccessful = (state: State) => state.draft.isSaveSuccessful;
export const draftAutoSavedId = (state: State) => state.draft.autoSavedId;
export const draftActivityId = (state: State) => state.draft.activityId;
export const mileageDraftReimbursement = (state: State) =>
  state.draft.reimbursement;
export const getDraftReceiptPhoto = (state: State) => state.draft.receiptPhoto;
export const getDraftItemizeConfirmed = (state: State) =>
  state.draft.itemizeConfirmed;
export const getSelectedDraftItems = (state: State) =>
  state.draft.itemizedExpenses;
export const draftExpenseItemizationStatus = (state: State) =>
  state.draft.canItemizeExpense;
export const getAutoSavedDraftAmount = (state: State) => state.draft.amount;
export const draftItemizedParentData = (state: State) =>
  state.draft.itemizedParentData;
export const getSelectedDrafts = (state: State) => state.draft.selectedDrafts;
export const getSelectedRejected = (state: State) =>
  state.draft.selectedRejected;

export const getSelectedMultiEditExpenses = (state: State) =>
  state.draft.selectedMultiEditExpenses;

/**
 * Submitted Selectors
 */
export const submittedLoading = (state: State) => state.submitted.loading;
export const getSelectedSubmittedId = (state: State) =>
  state.submitted.selectedId;
export const submittedExpenseCount = (state: State) =>
  state.submitted.totalCount;

/**
 * Submitted Selectors
 */
export const unsubmittedExpenseCount = (state: State) => state.draft.totalCount;

/**
 * Trash Selectors
 */

export const trashLoading = (state: State) => state.trash.loading;
export const trashExpenseCount = (state: State) => state.trash.totalCount;
export const selectedTrashedExpenseId = (state: State) =>
  state.trash.selectedId;
export const selectedTrashedExpenses = (state: State) =>
  state.trash.selectedExpenses;
export const selectedTrashedExpenseCount = (state: State) =>
  state.trash.selectedExpenses.length;

/**
 * New Expense Selectors
 */
export const getReceiptPhoto = (state: State) => state.new.receiptPhoto;
export const getExpenseData = (state: State) => state.new.receiptData;
export const getReceiptData = (state: State) => state.new.receiptData;
export const getReceiptDateByToken = (state: State) => state.new.receiptDataByToken;
export const newExpenseLoading = (state: State) => state.new.loading;
export const newExpenseEditing = (state: State) => state.new.editing;
export const newExpenseAutoSavedId = (state: State) => state.new.autoSavedId;
export const newExpenseAutoSaving = (state: State) => state.new.autoSaving;
export const newExpenseActivityId = (state: State) => state.new.activityId;
export const newMileageExpenseReimbursement = (state: State) =>
  state.new.reimbursement;
export const getNewItemizeConfirmed = (state: State) =>
  state.new.itemizeConfirmed;
export const newExpenseItemizationStatus = (state: State) =>
  state.new.canItemizeExpense;

/**
 * Filters
 */
export const selectFilterCount = (state: State) => state.filterCount;
export const selectFilters = (state: State) => state.filters;
export const getSubmittedFilters = (state: State) => state.filters.submitted;
export const selectFilterType = (state: State) => state.filterType;
export const selectFilterExpenseTypeId = (state: State) =>
  state.filterType ? state.filters[state.filterType].filters.type : '';
export const selectFilterJobCodeId = (state: State) =>
  state.filterType ? state.filters[state.filterType].filters.jobCode : '';
export const getUnsubmittedPageSize = (state: State) =>
  state.filters.unsubmitted.filters.limit;
export const getSubmittedPageSize = (state: State) =>
  state.filters.submitted.filters.limit;
export const getTrashPageSize = (state: State) =>
  state.filters.trash.filters.limit;
