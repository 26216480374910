<ems-base-card-section
  sectionTitle="Proxy Users"
  [subtitle]="subtitle"
  [class.display-column]="verticalLayout"
>
  <ems-base-stack space="l">
    <ems-base-caption *ngIf="!proxies?.length">
      <ems-base-text-style>No proxy users added.</ems-base-text-style>
    </ems-base-caption>

    <ems-base-inline *ngIf="proxies?.length" space="l">
      <ems-user-chip
        *ngFor="let user of proxies"
        [firstName]="user.proxy?.name_first"
        [lastName]="user.proxy?.name_last"
        [id]="user.proxy?.employee_number"
        [email]="user.proxy?.work_email"
        [phone]="user.proxy?.phone_number"
        (dismiss)="onRemoveProxy(user.id)"
        [verticalPopup]="verticalLayout"
      ></ems-user-chip>
    </ems-base-inline>

    <ems-add-proxy-user-modal
      *ngIf="formIsActive"
      [subtitle]="subtitle"
      [favoritePersons]="favoritePersons"
      (addProxy)="onAddProxy($event)"
      (cancelProxy)="onCancel()"
      (favorite)="onPersonFavorite($event)"
    ></ems-add-proxy-user-modal>

    <ems-base-button
      label="Add Proxy"
      class="add-button"
      buttonType="primary"
      variant="brand"
      (click)="toggleAddProxy()"
    ></ems-base-button>
  </ems-base-stack>
</ems-base-card-section>
