import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-helper-text',
  templateUrl: './base-helper-text.component.html',
  styleUrls: ['./base-helper-text.component.scss'],
})
export class BaseHelperTextComponent implements OnInit {
  /**
   * Visual style to apply to a string of helper text
   *
   * Accepts `positive`, `negative`, `warning` `muted`, `strong`
   */
  @Input() variation = 'muted';
  @Input() removePadding = false;

  /**
   * Classnames
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-positive': this.variation === 'positive',
      'is-negative': this.variation === 'negative',
      'is-muted': this.variation === 'muted',
      'is-strong': this.variation === 'strong',
      'is-warning': this.variation === 'warning',
    };
  }

  constructor() {}

  ngOnInit() {}
}
