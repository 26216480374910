import { Injectable } from "@angular/core";
import { ApiService } from "@ems-gui/expense/util-web-infrastructure";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError, of } from "rxjs";
import { TravelTicketActions, DraftActions, LayoutActions } from "../actions";
import { errorHandler } from "../error-handler";
import { Expense, TravelTicket } from "@ems-gui/shared/util-core";
import { withLatestFrom } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { selectExpenseEntities, State } from "@ems-gui/expense/util-web-ngrx";

export function isTravelTicket(
  travelTicket: null | string | TravelTicket
): travelTicket is TravelTicket {
  return travelTicket !== null && typeof travelTicket !== 'string';
}

@Injectable()
export class TravelTicketEffects {

  /**
   * Searches for the TravelTicket in the expense object.
   */
  onExpenseModalOpens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.openExpenseModal),
      withLatestFrom(this.store$.pipe(select(selectExpenseEntities))),
      switchMap(
        ([{ id }, expenses]) => {
          const expense = id && expenses && <Expense>expenses[id];
          if(expense && expense.travelTicket) {
            const travelTicket = expense.travelTicket;
            if(!isTravelTicket(travelTicket)) return [];

            return [TravelTicketActions.setTravelTicket(
              { travelTicket: travelTicket, isAssigned: true }
            )];
          }

          return [];
        }
      )
    )
  );

  /**
   * After getting a response from the API and we add the expense to the store
   * this is triggered and adds the travel ticket to the store.
   */
  onOneExpenseDataComplete$ = createEffect(
    () => this.actions$.pipe(
      ofType(DraftActions.getUpdatedDraftComplete),
      switchMap(
        ({ expense }) => {
          if(expense && isTravelTicket(expense.travelTicket))
            return of(TravelTicketActions.setTravelTicket(
              { travelTicket: expense.travelTicket, isAssigned: true }
            ))

          return [];
        }
      )
    )
  );

  requestTravelTicketByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TravelTicketActions.requestTravelTicketByTicketName),
      switchMap(({ ticketName }) =>
        this.apiService.getTravelTicketByName(ticketName).pipe(
          map((travelTicket) => {
            return TravelTicketActions.requestTravelTicketSuccess({ travelTicket: travelTicket })
          }
          ),
          catchError(
            (err) => {
              if (err.status === 404) {
                return of(TravelTicketActions.requestTravelTicketError({ error: err.error?.message }));
              }
              errorHandler(TravelTicketActions.requestTravelTicketError);
              return of(null);
            }
          )
        )
      )
    )
  );

  requestTravelTicketByDetailName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TravelTicketActions.requestTravelTicketByDetailName),
      switchMap(({ detailName }) =>
        this.apiService.getTravelTicketByDetailName(detailName).pipe(
          map((travelTicket) => {
            return TravelTicketActions.requestTravelTicketSuccess({ travelTicket: travelTicket })
          }
          ),
          catchError(
            (err) => {
              if (err.status === 404) {
                return of(TravelTicketActions.requestTravelTicketError({ error: err.error?.message }));
              }
              errorHandler(TravelTicketActions.requestTravelTicketError);
              return of(null);
            }
          )
        )
      )
    )
  );

  requestTravelTicketDetailsBySaleforceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TravelTicketActions.requestTravelTicketDetailsBySaleforceId),
      switchMap(({ saleforceId }) =>
        this.apiService.getTravelTicketDetailsBySaleforceId(saleforceId).pipe(
          map((travelTicket) => {
            return TravelTicketActions.requestTravelTicketSuccess({ travelTicket: travelTicket })
          }
          ),
          catchError(
            (err) => {
              if (err.status === 404) {
                return of(TravelTicketActions.requestTravelTicketError({ error: err.error?.message }));
              }
              errorHandler(TravelTicketActions.requestTravelTicketError);
              return of(null);
            }
          )
        )
      )
    )
  );

  assignTravelTicketDetailToExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TravelTicketActions.assignTravelTicketDetailToExpense),
      map(({ expense } ) => {
        return TravelTicketActions.assignTravelTicketDetailToExpenseSuccess({ expense });
      }),
      catchError(errorHandler(TravelTicketActions.requestTravelTicketError))
    )
  );

  removeTravelTicketDetailFromExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TravelTicketActions.removeTravelTicketDetailFromExpense),
      map(({ expense }) => {
        return TravelTicketActions.removeTravelTicketDetailFromExpenseSuccess({ expense });
      }),
      catchError(errorHandler(TravelTicketActions.requestTravelTicketError))
    )
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store$: Store<State>
  ) {}
}
