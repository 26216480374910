import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  delay,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { FavoriteJobCodeActions } from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import { selectAllFavoriteJobCodes } from '../../selectors/favorite-job-code.selectors';

@Injectable()
export class FavoriteJobCodeEffects {
  getFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteJobCodeActions.getFavorite),
      withLatestFrom(this.store$.pipe(select(fromDashboard.selectCurrentUser))),
      switchMap(([action, user]) => {
        /**
         * when reloading the application there are cases when the getFavorite action is dispatched before the user resolves
         * by checking if there is a contactId we can retry getFavorite after a 1000ms delay if there is none
         */
        if (!user.contactId) {
          return of(FavoriteJobCodeActions.getFavorite()).pipe(delay(1000));
        }
        return this.apiService.getFavoriteJobCode().pipe(
          map((jobCode) =>
            FavoriteJobCodeActions.getFavoriteComplete({ jobCode })
          ),
          catchError(errorHandler(FavoriteJobCodeActions.getFavoriteError))
        );
      })
    )
  );

  favorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteJobCodeActions.saveOneFavorite),
      withLatestFrom(this.store$.pipe(select(selectAllFavoriteJobCodes))),
      switchMap(([{ id }, favorites]) => {
        const ids = favorites.map((f) => f.jobcodeId);
        if (!ids.includes(+id)) {
          return this.apiService.saveFavorite({ id }).pipe(
            map((jobCode) =>
              FavoriteJobCodeActions.saveOneFavoriteComplete({ jobCode })
            ),
            catchError(
              errorHandler(FavoriteJobCodeActions.saveOneFavoriteError)
            )
          );
        } else {
          return this.apiService.unfavorite(id).pipe(
            map((jobCode) =>
              FavoriteJobCodeActions.deleteOneFavoriteComplete({ jobCode })
            ),
            catchError(
              errorHandler(FavoriteJobCodeActions.deleteOneFavoriteError)
            )
          );
        }
      })
    )
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store$: Store<fromDashboard.State>
  ) {}
}
