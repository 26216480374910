//  TODO: REMOVE? this version of multi-edit does not match existing spec and is not currently in use
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-expense-modal-multi',
  templateUrl: './expense-modal-multi.component.html',
  styleUrls: ['./expense-modal-multi.component.scss'],
})
export class ExpenseModalMultiComponent implements OnInit {
  @Output() actionSelected: EventEmitter<any> = new EventEmitter();
  /**
   * REFACTOR NEEDED:
   * WHY: Separate dummy data from code logic?
   */
  expenses = [
    {
      createdAt: 1564175280008,
      updatedAt: 1564175280291,
      id: 125,
      status: 'pending',
      description: null,
      cardVerified: null,
      address: '201 Jefferson St, Syracuse, NY 13210',
      vendor: 'Lyft',
      token: '',
      image:
        'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/5QkKHTpQNS2fBp2m',
      thumbnail:
        'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/thumbnail/5QkKHTpQNS2fBp2m',
      amount: 22.06,
      comment: '',
      transactionDate: new Date().toISOString(),
      information: null,
      isBillable: false,
      approvalLevel: 0,
      paymentType: 'Personal CC',
      jobCode: 2019,
      report: 76,
      type: 'Travel',
      creditCardId: null,
      submitter: 4,
      approvedBy: null,
    },
    {
      createdAt: 1564175280008,
      updatedAt: 1564175280291,
      id: 126,
      status: 'pending',
      description: null,
      cardVerified: null,
      address: '201 Jefferson St, Syracuse, NY 13210',
      vendor: 'Eltana',
      token: '',
      image:
        'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/5QkKHTpQNS2fBp2m',
      thumbnail:
        'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/thumbnail/5QkKHTpQNS2fBp2m',
      amount: 18,
      comment: '',
      transactionDate: new Date().toISOString(),
      information: null,
      isBillable: false,
      approvalLevel: 0,
      paymentType: 'Cash',
      jobCode: 2019,
      report: 76,
      type: 'Meal',
      creditCardId: null,
      submitter: 4,
      approvedBy: null,
    },
    {
      createdAt: 1564175280008,
      updatedAt: 1564175280291,
      id: 127,
      status: 'pending',
      description: 'Rideshare from airport to hotel.',
      cardVerified: null,
      address: '201 Jefferson St, Syracuse, NY 13210',
      vendor: 'Uber',
      token: '',
      image:
        'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/5QkKHTpQNS2fBp2m',
      thumbnail:
        'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/thumbnail/5QkKHTpQNS2fBp2m',
      amount: 30,
      comment: '',
      transactionDate: new Date().toISOString(),
      information: null,
      isBillable: true,
      approvalLevel: 0,
      paymentType: 'Personal CC',
      jobCode: 2019,
      report: 76,
      type: 'Travel',
      creditCardId: null,
      submitter: 4,
      approvedBy: null,
    },
  ];

  onSelect() {
    this.actionSelected.emit('selectExpenses');
  }

  onClearEdits() {
    this.actionSelected.emit('clearEdits');
  }

  onCancel() {
    this.actionSelected.emit('cancelEdits');
  }
  onSave() {
    this.actionSelected.emit('saveEdits');
  }

  ngOnInit() {
    return;
  }
}
