import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-receipt-image',
  templateUrl: './receipt-image.component.html',
  styleUrls: ['./receipt-image.component.scss'],
})
export class ReceiptImageComponent implements OnInit {
  @Input() expense;
  params: any;
  hasReceipt = false;
  cardVerified = '';
  parent = '';
  icon = 'receipt-icon';
  ocrMismatch = false;

  ngOnInit() {
    if (this.expense.type?.name === 'Mileage' ||
      this.expense.paymentType === 'mileage'
    ) {
      this.icon = 'not_applicable';
    }

    if (
      this.expense.hasOwnProperty('hasReceipt')
      && this.expense.hasReceipt
    ) {
      this.hasReceipt = this.expense.hasReceipt;
      this.cardVerified = this.cardVerified;
      this.parent = this.expense.parent;
    } else {
      this.cardVerified = this.expense.cardVerified;
      this.parent = this.expense.parent;
    }

    if(
      this.expense.hasOwnProperty('ocrMismatch')
      && this.expense.ocrMismatch
    ){
      this.ocrMismatch = true;
      this.icon = 'error';
    }
  }
}
