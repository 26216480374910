import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  LayoutActions,
  MileageDraftActions,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectJobCodesForDraftDropdown,
  selectAllSalesforceCasesWithGroupLabels,
  selectMileageDraftReimbursement,
  selectMileageExpenseType,
  selectModalDismiss,
  selectTrashedExpenseWithSalesforceAndActivity,
  State,
  TrashActions,
  WebSubmitterFacade,
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  JobCode,
  ModalInput,
  SalesforceCase,
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-trash-mileage-detail-modal',
  templateUrl: './trash-mileage-detail-modal.component.html',
  styleUrls: ['./trash-mileage-detail-modal.component.scss'],
})
export class TrashMileageDetailModalComponent implements OnInit, OnDestroy {
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Mileage Expense:',
    label: '',
    labelClass: '',
    caption: '',
    status: '',
    subtitle: '',
    statusText: '',
    fieldsComplete: false,
    modalId: 'trash-mileage-detail',
  });
  modalInput$ = this.modalInputSubject.asObservable();
  form;
  private isExistingMileageValidSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  isExistingMileageValid$ = this.isExistingMileageValidSubject.asObservable();
  state$ = this.isExistingMileageValid$.pipe(
    map((bool) => (bool ? '' : 'disabled'))
  );
  currentDate = new Date();
  lastUpdatedDate;
  lastUpdatedTime;
  draftStatus;
  mileageExpense;
  mileageExpense$: Observable<Partial<Expense>>;
  dismiss$: Observable<boolean>;
  jobCodes$: Observable<JobCode[]>;
  favoriteJobCodes$: Observable<any>;
  salesforceCases$: Observable<Partial<SalesforceCase>[]>;
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;
  unsubscribe$: Subject<void> = new Subject();
  draftMileageReimbursement$: Observable<number>;
  mileageExpenseType$: Observable<ExpenseType>;
  isTrashed = true;

  constructor(
    public modalService: BaseModalService,
    public submitterState: WebSubmitterFacade,
    private store$: Store<State>
  ) {
    this.mileageExpense$ = this.store$.pipe(
      select(selectTrashedExpenseWithSalesforceAndActivity)
    );
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
    this.jobCodes$ = this.store$.pipe(select(selectJobCodesForDraftDropdown));
    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );
    this.salesforceCases$ = this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels)
    );
    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.draftMileageReimbursement$ = this.store$.pipe(
      select(selectMileageDraftReimbursement)
    );
    this.mileageExpenseType$ = this.store$.pipe(
      select(selectMileageExpenseType)
    );
  }

  ngOnInit() {
    this.mileageExpense$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((expense) => {
          this.mileageExpense = expense;
          if (expense?.updatedAt) {
            this.lastUpdatedDate = expense.updatedAt;
            this.lastUpdatedTime = formatDate(
              expense.updatedAt,
              'shortTime',
              'en-US'
            );
          }
          if (expense) {
            const currentModalInput = this.modalInputSubject.getValue();
            this.modalInputSubject.next({
              ...currentModalInput,
              subtitle: `ID ${expense.id}`,
              caption: this.getDraftStatus(),
              label: this.setModalInputLabel(expense.status),
              labelClass: this.setModalInputLabelClass(expense.status),
            });
          }
        })
      )
      .subscribe();
  }

  // getDraftStatus returns a string that will be used in the expense form's caption.
  getDraftStatus() {
    const expenseDate = new Date(this.lastUpdatedDate);
    const fullExpenseDate = formatDate(
      this.lastUpdatedDate,
      'shortDate',
      'en-US'
    );
    const today = `${this.currentDate.getMonth()}/${this.currentDate.getDate()}/${this.currentDate.getFullYear()}`;
    const shortExpenseDate = `${expenseDate.getMonth()}/${expenseDate.getDate()}/${expenseDate.getFullYear()}`;

    if (shortExpenseDate === today) {
      return `Last updataed today at ${this.lastUpdatedTime}`;
    } else {
      return `Last updated on ${fullExpenseDate}`;
    }
  }

  onDismiss() {
    this.store$.dispatch(LayoutActions.dismissModal());
  }

  onGetMileageReimbursement(mileage) {
    this.store$.dispatch(
      MileageDraftActions.getEstimatedReimbursement({ mileage })
    );
  }

  onOpenModal(modalId: string, expenseId: number) {
    this.submitterState.dispatch(
      TrashActions.selectTrashedExpenses({ expenses: [expenseId] })
    );
    this.modalService.open(modalId);
  }

  onRestore(expenseId: number) {
    const expenses = [expenseId];
    this.submitterState.dispatch(TrashActions.restoreExpenses({ expenses }));
    this.modalService.close('trash-mileage-detail');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setModalInputLabel(formStatus) {
    return `[${formStatus}]`;
  }

  setModalInputLabelClass(formStatus) {
    switch (formStatus) {
      case 'rejected':
        return 'danger';

      case 'awaiting verification':
        return 'warn';

      default:
        return '';
    }
  }
}
