import { Component } from '@angular/core';
import { AuthService } from '@sec-spec/lib-ng-oauth';

@Component({
  selector: 'ems-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  submitNewComplete$;

  constructor(private authService: AuthService) {
    this.authService.setLoginLogo(
      'https://global-sis-images.s3-us-west-2.amazonaws.com/spense-logo.svg'
    );
  }
}
