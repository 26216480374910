import { Component, OnDestroy } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  selectedTrashedExpenses,
  State,
  TrashActions,
} from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnDestroy {
  selectedTrashExpenses$: Observable<any[]> = this.store$.pipe(
    select(selectedTrashedExpenses)
  );
  unsubscribe$: Subject<void> = new Subject();
  modalConfig: ModalInput = {
    title: '',
    modalId: 'delete-modal',
  };

  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {}

  onDeleteExpenses() {
    this.selectedTrashExpenses$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((expenses) => expenses.length > 0),
        tap((expenses) => {
          this.store$.dispatch(TrashActions.deleteExpenses({ expenses }));
        })
      )
      .subscribe();
  }

  onDeleteDialogCancel() {
    this.modalService.close('delete-modal');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
