import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface ClosedData {
  modalId: string;
  action: string;
}

export interface BaseModal {
  config: {
    modalId: string;
  };
  isOpen: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BaseModalService {
  private modals: BaseModal[];
  private _openModalId$: Subject<string> = new Subject();
  public get openModalId$(): Observable<any> {
    return this._openModalId$.asObservable();
  }
  private closedSubject: BehaviorSubject<ClosedData> = new BehaviorSubject({
    modalId: '',
    action: '',
  });
  public closed$: Observable<ClosedData> = this.closedSubject.asObservable();

  constructor() {
    this.modals = [];
  }

  registerModal(newModal: BaseModal): void {
    const modal = this.modals.find(
      (m) => m.config.modalId === newModal.config.modalId
    );

    // Delete existing to replace the modal
    if (modal) {
      this.modals.splice(this.modals.indexOf(modal));
    }

    newModal.isOpen = true;
    this.modals.push(newModal);
  }

  open(modalId: string): void {
    this._openModalId$.next(modalId);
  }

  close(modalId: string, action?: string): void {
    const modal = this.modals.find((m) => m.config.modalId === modalId);

    if (modal) {
      this.closedSubject.next({
        modalId,
        action,
      });
      modal.isOpen = false;
      this._openModalId$.next('');
    }
  }
}
