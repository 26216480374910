import { createSelector } from "@ngrx/store";
import { selectDashboardState, DashboardState } from "../reducers/state";

export const selectDashboardExpenseMatchState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.expenseMatch
);

export const selectUserCreatedCreditCardExpenses = createSelector(
  selectDashboardExpenseMatchState,
  state => state.expenses
);

export const selectExpenseMatchLoading = createSelector(
  selectDashboardExpenseMatchState,
  state => state.loading
);

export const selectExpenseMatchError = createSelector(
  selectDashboardExpenseMatchState,
  state => state.error
);

export const selectMatchedExpenseId = createSelector(
  selectDashboardExpenseMatchState,
  state => state.matchedExpenseId
);

export const selectMatchedExpense = createSelector(
  selectDashboardExpenseMatchState,
  (state) => {
    return state.expenses.find(expense => expense.id === state.matchedExpenseId);
  }
);
