import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { OnChange } from '@ems-gui/shared/util-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { skipWhile, takeUntil, tap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'ems-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TypeaheadComponent,
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TypeaheadComponent
  implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit
{
  /**
   * @ignore
   */
  @OnChange(function (this: TypeaheadComponent, favorites, changes) {
    const favoriteChanges =
      favorites && changes.currentValue && changes.currentValue.length;
    if (favoriteChanges !== null && favoriteChanges !== false) {
      const currentSelection = this.control ? this.control.value : null;

      /**
       * update the favorited state with the current selection
       */
      if (currentSelection !== null) {
        this.updateFavorites(currentSelection);
      }
    }
  })

  /**
   * Favorites
   *
   * Accepts array of favorite Contacts or JobCodes
   * @param {any[]} favorites
   */
  @Input()
  favorites = [];
  @ViewChild('ngSelect', { read: ElementRef }) ngSelectElementRef: ElementRef;
  @Input() canClear = false;
  showClear = false;
  /**
   * The type of data being used for `favorites` and `all`. Accepts `employee`, `jobCode`, `salesforceCase`
   * @param {string} type
   */
  @Input() type;

  /**
   * Placeholder
   *
   * Accepts a string that renders the placeholder text
   * @param {string} placeholder
   */
  @Input() placeholder = 'Type to search...';

  /**
   * Toggle display of favorite action button. Accepts `true`, `false`
   * @param {boolean} favoritable
   * @default true
   */

  @Input() favoritable = true;

  /**
   * Direction of dropdown panel Accepts `right`, `left`
   * @param {string} direction
   * @default 'right'
   */
  @Input() direction = 'right';

  /**
   * Determines if input should be cleared on selection
   */
  @Input() clearOnSelection = false;

  @Input() items = [];

  /**
   * The id of the favorited item. Outputs `id`
   * @param {number} id
   */
  @Output() favorite = new EventEmitter();
  @Input() isDisabled = false;
  @Input() isInvalid = false;

  @Input() label = 'Placeholder';
  @Input() optional = false;
  @Input() inputId = '';

  /**
   * Select
   * The id of the selected item. Outputs `id`
   * @param {number} id
   * @ignore
   */
  control = new FormControl(null);

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  /**
   * @ignore
   */
  private unsubscribe$: Subject<void>;

  /**
   * @ignore
   */
  favoritedState$ = new BehaviorSubject(null);

  /**
   * @ignore
   */
  updateFavorites = (v) => {
    if(!this.favorites) return;
    const favorites = this.favorites;
    if (
      v === null ||
      (!favorites.find((f) => f.id === +v) &&
        this.favoritedState$.value === 'filled')
    ) {
      this.favoritedState$.next(null);
    } else if (favorites.find((f) => f.id === +v)) {
      this.favoritedState$.next('filled');
    } else {
      this.favoritedState$.next('outline');
    }
  };

  /**
   * @ignore
   */
  classNames() {
    return {
      'is-right': this.direction === 'right',
      'is-left': this.direction === 'left',
      'is-favoritable': this.favoritable === true,
      'is-invalid': this.isInvalid === true,
    };
  }

  constructor() {
    this.unsubscribe$ = new Subject<void>();
    this.control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        skipWhile((v) => v === null),
        tap((v) => this.writeValue(v))
      )
      .subscribe();
  }

  /**
   * Terminolology
   *
   * Returns a plural string of the current type
   */
  typeTerminology = () => {
    if (this.type === 'jobCode') {
      return 'Codes';
    } else if (this.type === 'person') {
      return 'Employees';
    } else {
      return 'Salesforce Cases';
    }
  };

  /**
   * Clear the value, this will trigger the onChange and clear the value
   * @ignore
   */
  clear() {
    this.writeValue(null);
    this.ngSelect.handleClearClick();
    this.ngSelect.blur();
    this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
    this.showClear = false;
    this.ngSelect.focused = false;
  }

  ngOnInit() {
    if (this.isDisabled) {
      this.control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  ngAfterViewInit(): void {
    if (
        this.ngSelect &&
        (!this.control.value ||
        this.control.value === '0' ||
        this.control.value === 0)) {
      this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
      this.showClear = false;
    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFavorite() {
    this.favorite.emit(this.control.value);
  }

  onClear() {
    this.writeValue(0);
    this.ngSelect.focus();
    this.ngSelect.blur();
    this.showClear = false;
    this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
    this.ngSelect.open();
  }

  /**
   * @ignore
   */
  onChange(id: number) {}

  /**
   * @ignore
   */
  writeValue(value) {
    this.control.setValue(+value, { emitEvent: false });
    this.updateFavorites(value);
    this.onChange(value);
    if (
          this.ngSelect &&
          (this.control.value !== '0' &&
          this.control.value !== 0)
      ) {
      this.ngSelect.blur();
      this.ngSelect.searchInput.nativeElement.placeholder = '';
      this.ngSelect.focused = true;
      this.showClear = true;
    }

    if (this.clearOnSelection && this.control.value && this.favoritable) {
      this.control.setValue('', { emitEvent: false });
      this.ngSelect.handleClearClick();
      this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
      this.showClear = false;
    } else if (
      this.clearOnSelection &&
      this.control.value &&
      !this.favoritable &&
      this.ngSelect
    ) {
      setTimeout(() => {
        this.ngSelect.handleClearClick();
        this.ngSelect.blur();
        this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
        this.showClear = false;
      }, 1000);
    }
  }

  /**
   * @ignore
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * @ignore
   */
  registerOnTouched(fn) {}

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  private getPosition(modal: HTMLElement, offset = 0) {
    const ngSelectElement = this.ngSelectElementRef.nativeElement;
    const rect = ngSelectElement.getBoundingClientRect();
    const modalRect = modal.getBoundingClientRect();
    const currentPosition = modal.scrollTop;
    const pxTop = rect.top - modalRect.top;
    const pxHeight = modalRect.height - rect.height - 20;

    return ((currentPosition + pxTop) - pxHeight) + offset;
  }

  private get isMobile() {
    return window.innerWidth < 768;
  }

  public scrollNgSelectIntoView() {
    if(!this.isMobile) return;
    const ngSelectElement = this
      .ngSelectElementRef
      .nativeElement as HTMLElement;
    const rect = ngSelectElement.getBoundingClientRect();
    const container = ngSelectElement.closest('.base-modal__content');
    if(!container) return;
    const modalBody = ngSelectElement.closest<HTMLElement>('.base-modal__body');
    if(!modalBody) return;
    const actionButtons = container.querySelector('.base-modal__actions');
    if(!actionButtons) return;
    const offset = actionButtons.getBoundingClientRect().height;
    const position = this.getPosition(modalBody, offset);

    console.log(position);

    modalBody.scrollTo({ top: position, behavior: 'smooth' })
  }

  public onOpen() {
    this.scrollNgSelectIntoView();
  }

  public onFocus() {
    this.scrollNgSelectIntoView();
  }
}
