<ems-base-modal
  id="add-proxy-user-modal"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onProxyCancel()"
>
  <ems-add-proxy-user-form
    [subtitle]="subtitle"
    [favoritePersons]="favoritePersons"
    (addProxy)="onProxyConfirm($event)"
    (cancelProxy)="onProxyCancel()"
    (favorite)="onPersonFavorite($event)"
  ></ems-add-proxy-user-form>
</ems-base-modal>
