import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FeedErrorUserActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
  selectedId: string | number;
  autoSaving: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedId: null,
  autoSaving: null,
});

export const reducer = createReducer(
  initialState,
  on(FeedErrorUserActions.getUsers, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    FeedErrorUserActions.addUser,
    FeedErrorUserActions.deleteUser,
    (state) => ({
      ...state,
      loading: true,
      autoSaving: true,
    })
  ),
  on(FeedErrorUserActions.getUsersComplete, (state, { users }) =>
    adapter.upsertMany(users, {
      ...state,
      loading: false,
    })
  ),
  on(FeedErrorUserActions.addUserComplete, (state, { user }) =>
    adapter.upsertOne(user[0], {
      ...state,
      loading: false,
      autoSaving: false,
    })
  ),
  on(FeedErrorUserActions.deleteUserComplete, (state, { user }) =>
    adapter.removeOne(user.id, {
      ...state,
      loading: false,
      autoSaving: false,
    })
  ),
  on(
    FeedErrorUserActions.getUsersError,
    FeedErrorUserActions.addUserError,
    FeedErrorUserActions.deleteUserError,
    (state, { error }) => ({
      ...state,
      loading: false,
      autoSaving: null,
      error,
    })
  ),
  on(FeedErrorUserActions.reset, (state) => ({
    ...state,
    autoSaving: null,
  }))
);

export const autoSavingStatus = (state: State) => state.autoSaving;
