import { JobCode } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const get = createAction('[Job Code] get');

export const getComplete = createAction(
  '[Job Code] getComplete',
  props<{ jobCode: JobCode[] }>()
);

export const getError = createAction(
  '[Job Code] getError',
  props<{ error: any }>()
);

export const select = createAction('[Job Code] select', props<{ id: any }>());

export const filter = createAction(
  '[Job Code] filter',
  props<{ filter: any }>()
);

export const resetJobCode = createAction('[Job Code] resetJobCode');
