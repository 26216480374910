<ems-base-card-section
  sectionTitle="Alternate Emails"
  [subtitle]="
    subtitle === ''
      ? 'Other emails ' + userName + ' can send receipts from.'
      : subtitle
  "
  class="alternate-emails"
>
  <!-- List of Alternate Emails-->
  <ems-base-stack space="l">
    <ol>
      <li class="alternate-email" *ngFor="let email of alternateEmails">
        <div class="alternate-email__address">
          <ems-base-stack space="s">
            <div class="ems-control">
              <input
                type="text"
                class="ems-input disabled"
                disabled
                [value]="email.email"
              />
              <div class="alternate-email__actions">
                <ems-base-dismiss-button
                  (dismiss)="onDismiss(email)"
                ></ems-base-dismiss-button>
              </div>
            </div>
            <div class="ems-control">
              <label class="ems-checkbox">
                <input
                  type="checkbox"
                  [checked]="email.enabled"
                  (change)="onEmailNotificationChange(email, $event)"
                />
                <div class="ems-checkbox__indicator"></div>
                Enable Notifications
              </label>
            </div>
          </ems-base-stack>
        </div>
      </li>
    </ol>

    <!-- Add Email Toggle -->
    <ems-base-button
      label="Add Email"
      class="add-button"
      buttonType="primary"
      variant="brand"
      (click)="toggleAddEmail()"
    ></ems-base-button>
  </ems-base-stack>

  <!-- Add Email Form -->
  <ems-alt-email-modal
    *ngIf="formIsActive"
    [userName]="userName"
    (addEmail)="onAddEmail($event)"
    (cancelEmail)="cancelEmail()"
  ></ems-alt-email-modal>
</ems-base-card-section>
