import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ReceiptManagerActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  selectedFormat: string;
  receiptFiles: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedFormat: '',
  receiptFiles: {
    csv: '',
    file: '',
  },
});

export const reducer = createReducer(
  initialState,
  on(
    ReceiptManagerActions.downloadErrorCsv,
    ReceiptManagerActions.downloadReceiptFile,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    ReceiptManagerActions.downloadReceiptFileComplete,
    ReceiptManagerActions.downloadErrorCsvComplete,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(
    ReceiptManagerActions.downloadReceiptUrls,
    (state, { expenses, action, id_type }) => ({
      ...state,
      loading: true,
      selectedFormat: action,
    })
  ),
  on(
    ReceiptManagerActions.downloadReceiptUrlsComplete,
    (state, { csv, file }) => ({
      ...state,
      receiptFiles: {
        ...state.receiptFiles,
        csv,
        file,
      },
      loading: false,
    })
  ),
  on(
    ReceiptManagerActions.downloadReceiptUrlsError,
    ReceiptManagerActions.downloadReceiptFileError,
    ReceiptManagerActions.downloadErrorCsvError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const receiptFiles = (state: State) => state.receiptFiles;
export const filesLoading = (state: State) => state.loading;
export const format = (state: State) => state.selectedFormat;
