import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-proxy-menu',
  templateUrl: './proxy-menu.component.html',
  styleUrls: ['./proxy-menu.component.scss'],
})
export class ProxyMenuComponent implements OnInit {
  @Input() proxies;
  @Input() currentUser;
  @Input() proxyUser;
  @Output() chooseProxy: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeProxy = new EventEmitter();
  
  ngOnInit(): void {
    return;
  }

  onChooseProxy(id) {
    this.chooseProxy.emit(id);
  }

  onRemoveProxy() {
    this.removeProxy.emit();
  }
}
