import { AlternateEmail } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getEmails = createAction(
  '[AlternateEmail - Admin] getEmails',
  props<{ id: number }>()
);

export const getEmailsComplete = createAction(
  '[AlternateEmail - Admin] getEmailsComplete',
  props<{ emails: AlternateEmail[] }>()
);

export const getEmailsError = createAction(
  '[AlternateEmail - Admin] getEmailsError',
  props<{ error: any }>()
);

export const addEmail = createAction(
  '[AlternateEmail - Admin] addEmail',
  props<{ id: number; email: string }>()
);

export const addEmailComplete = createAction(
  '[AlternateEmail - Admin] addEmailComplete',
  props<{ email: AlternateEmail }>()
);

export const addEmailError = createAction(
  '[AlternateEmail - Admin] addEmailError',
  props<{ error: any }>()
);

export const updateEmailNotifications = createAction(
  '[AlternateEmail - Admin] updateEmailNotifications',
  props<{ id: number; user: number; enabled: boolean }>()
);

export const updateEmailNotificationsComplete = createAction(
  '[AlternateEmail - Admin] updateEmailNotificationsComplete',
  props<{ email: AlternateEmail }>()
);

export const updateEmailNotificationsError = createAction(
  '[AlternateEmail - Admin] updateEmailNotificationsError',
  props<{ error: any }>()
);

export const deleteEmail = createAction(
  '[AlternateEmail - Admin] deleteEmail',
  props<{ id: number }>()
);

export const deleteEmailComplete = createAction(
  '[AlternateEmail - Admin] deleteEmailComplete',
  props<{ email: AlternateEmail }>()
);

export const deleteEmailError = createAction(
  '[AlternateEmail - Admin] deleteEmailError',
  props<{ error: any }>()
);
