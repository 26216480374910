import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ems-alt-email-form',
  templateUrl: './alt-email-form.component.html',
  styleUrls: ['./alt-email-form.component.scss'],
})
export class AltEmailFormComponent implements OnInit {
  @Input() userName = '';
  @Output() addEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  email = new FormControl(null, {
    validators: [Validators.required, Validators.email],
  });

  constructor() {}

  ngOnInit(): void {}

  onCancelClick() {
    this.cancel.emit();
    this.email.reset();
  }

  onConfirmClick() {
    if (this.email.valid) {
      const email = this.email.value;
      this.addEmail.emit(email);
    }
  }
}
