import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlOptions,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil, tap } from 'rxjs/operators';
import { DatepickerHeaderComponent } from './datepicker-header/datepicker-header.component';
import moment, { Moment } from 'moment';
import { CustomValidators } from '@ems-gui/shared/util-core';

interface SetValueOptions {
  onlySelf?: boolean;
  emitEvent?: boolean;
  emitModelToViewChange?: boolean;
  emitViewToModelChange?: boolean;
}

@Component({
  selector: 'ems-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements ControlValueAccessor, OnDestroy {
  @Input() placeholder = 'Select a date';
  @Input() minDate;
  @Input() maxDate;
  @Input() isDisabled = false;
  @Input() inputId: string = '';

  datepickerHeader = DatepickerHeaderComponent;
  @ViewChild(MatDatepicker) datepicker: MatDatepicker<any>;
  @Output() dateChanged = new EventEmitter<Date>();


  date: Date | string | number | Moment;
  control: FormControl;
  unsubscribe$: Subject<void> = new Subject();

  public initialSave = false;
  protected interval: NodeJS.Timeout;
  protected savedMoment: Moment;
  protected unsavedMoment: Moment;
  protected lastModified: Moment;
  protected waitToSave = 3000;

  constructor() {
    const formState = { value: this.date, disabled: this.isDisabled };
    const formControlOptions: FormControlOptions = { updateOn: 'blur' };

    this.control = new FormControl(formState, formControlOptions);
  }

  toMoment(value?: Date | string | number | Moment) {
    const date = moment.utc(value);
    if (!value || !date.isValid()) return undefined;
    return date;
  }

  clear() {
    this.control.reset();
  }

  protected isInterval() {
    return this.interval !== undefined;
  }

  protected clearInterval() {
    if (this.isInterval()) clearInterval(this.interval);
  }

  protected isUnsavedDifferent() {
    return !this.unsavedMoment.isSame(this.savedMoment);
  }

  protected toMS(date: Moment) {
    return date.toDate().getTime();
  }

  protected isIntervalComplete() {
    return (
      this.isUnsavedDifferent() &&
      this.lastModified.diff(moment()) > this.waitToSave
    );
  }

  protected triggerOnChange() {
    this.onChange(this.date);
  }

  protected setFormControlValue(options?: SetValueOptions) {
    this.control.setValue(this.date, options);
  }

  protected setDate(date: Moment) {
    if (date.isValid()) {
      this.date = date.format('YYYY-MM-DDT00:00:00.00');
    } else {
      this.date = null;
    }
  }

  protected saveChanges() {
    this.savedMoment = this.unsavedMoment.clone();
    this.setDate(this.savedMoment);
    this.setFormControlValue({
      emitViewToModelChange: true,
      emitEvent: true,
    });
    this.triggerOnChange();
    if (!this.initialSave) this.initialSave = true;
  }

  protected prepareOnChange(date: Moment) {
    this.clearInterval();
    this.lastModified = moment();
    this.unsavedMoment = date.clone();
    if (this.isUnsavedDifferent()) return this.saveChanges();
  }

  ngOnInit() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        skipWhile((value) => value === null),
        tap((value?: Date | string | number | Moment) => {
          if (!value) return;
          this.writeValue(value);
        })
      )
      .subscribe();
  }

  /**
   * @ignore
   */
  onChange(id: number | string | Date | Moment) {}

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.dateChanged.emit(event.value);
    }
  }

  /**
   * @ignore
   */
  writeValue(value?: string | number | Date | Moment | null) {
    const date = this.toMoment(value);
    if (!date) return;
    if (!this.initialSave) {
      this.setDate(this.toMoment(date));
    }
    this.prepareOnChange(date);
  }

  /**
   * @ignore
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * @ignore
   */
  registerOnTouched(fn) {}

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected readonly moment = moment;
}
