import { Component } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { State, TrashActions } from '@ems-gui/expense/util-web-ngrx';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ems-trash-modal',
  templateUrl: './trash-modal.component.html',
  styleUrls: ['./trash-modal.component.scss'],
})
export class TrashModalComponent {
  modalConfig = {
    title: '',
    modalId: 'trash-modal',
  };

  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {}

  onTrashDialogCancel() {
    this.modalService.close('trash-modal');
  }

  // TODO: We can probably delete this, we do not empty trash
  onEmptyTrash() {
    this.store$.dispatch(TrashActions.emptyTrash());
  }
}
