import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiService,
  BaseModalService,
} from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SnackbarService } from 'ngx-snackbar';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ActivityActions,
  LayoutActions,
  NewMileageActions,
} from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import {
  selectAutoSavedExpenseId,
  selectNewExpenseActivityId,
} from '../../selectors/expense.selectors';

@Injectable()
export class NewMileageExpenseEffects {
  getReimbursement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.getEstimatedReimbursement),
      switchMap((payload: any) =>
        this.apiService.getMileageReimbursement(payload.mileage).pipe(
          map((res) =>
            NewMileageActions.getEstimatedReimbursementComplete({
              reimbursement: res.reimbursement,
            })
          ),
          catchError(
            errorHandler(NewMileageActions.getEstimatedReimbursementError)
          )
        )
      )
    )
  );

  // submit$ handles submitting a new mileage expense which likely has been saved to a draft already so
  // both the activity id (for a note) and the expense id needs to be added before making the save
  // expense api call
  submitMileage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.submit),
      withLatestFrom(
        this.store$.pipe(select(selectAutoSavedExpenseId)),
        this.store$.pipe(select(selectNewExpenseActivityId))
      ),
      switchMap(([action, autoSaveId, activityId]) =>
        this.apiService
          .submitMileageDraft({
            ...action.expense,
            id: autoSaveId,
            activityId,
          })
          .pipe(
            map((res) =>
              NewMileageActions.submitComplete({ expense: res.expense })
            ),
            catchError(({ error }) => {
              const message = `Could not submit: ${error.message}`;
              return [LayoutActions.globalToastErrorMessage({ message })];
            })
          )
      )
    )
  );

  submitMileageComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.submitComplete),
      tap(() => {
        this.snackbarService.clear();
        this.snackbarService.add({
          msg: 'Expense submitted.',
          timeout: 10000,
          action: {
            text: 'View',
            onClick: () => {
              this.router.navigateByUrl('/expenses/submitted');
            },
          },
          background: '#1d3148',
        });
      }),
      map(() => LayoutActions.dismissModal())
    )
  );

  save$ = createEffect(() =>
  this.actions$.pipe(
    ofType(NewMileageActions.save),
    map((action: any) => {
      if (!action.expense.message) {
        const expense = JSON.parse(JSON.stringify(action.expense));
        delete expense.message;
        return NewMileageActions.saveWithoutNote({expense});
      } else {
        return NewMileageActions.saveWithNote({expense:action.expense});
      }
    })
  )
);

  autoSaveWithoutNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.saveWithoutNote),
      withLatestFrom(this.store$.pipe(select(selectAutoSavedExpenseId))),
      switchMap(([{ expense }, id]) =>
        this.apiService
          .saveMileageDraft({
            ...expense,
            id: +id,
          })
          .pipe(
            map((response) =>
              NewMileageActions.saveWithoutNoteComplete({
                expense: response,
              })
            ),
            catchError(errorHandler(NewMileageActions.saveWithoutNoteError))
          )
      )
    )
  );

  autoSaveWithoutNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.saveWithoutNoteComplete),
      withLatestFrom(this.store$.pipe(select(selectNewExpenseActivityId))),
      filter(([action, id]) => id !== null),
      switchMap(([note, activityId]) =>
        this.apiService.deleteNote(activityId).pipe(
          map(({ activity }) =>
            ActivityActions.deleteNewExpenseNoteComplete({ activity: activity })
          ),
          catchError(errorHandler(NewMileageActions.saveWithoutNoteError))
        )
      )
    )
  );

  autoSaveWithNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.saveWithNote),
      withLatestFrom(
        this.store$.pipe(select(selectAutoSavedExpenseId)),
        this.store$.pipe(select(selectNewExpenseActivityId))
      ),
      switchMap(([action, autoSavedId, activityId]) =>
        this.apiService
          .saveMileageDraft({
            ...action.expense,
            id: +autoSavedId,
            activityId,
          })
          .pipe(
            map((response) =>
              NewMileageActions.saveWithNoteComplete({ expense: response })
            ),
            catchError(errorHandler(NewMileageActions.saveWithNoteError))
          )
      )
    )
  );

  autoSaveWithNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.saveWithNoteComplete),
      map(({ expense }) =>
        ActivityActions.getNewExpenseActivityId({ id: expense.activity.id })
      )
    )
  );

  trashAutoSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.trashAutoSaved),
      withLatestFrom(this.store$.pipe(select(selectAutoSavedExpenseId))),
      switchMap(([, autoSaveId]) =>
        this.apiService.trashExpenses({ expenses: [autoSaveId] }).pipe(
          mergeMap((expenses) => [
            NewMileageActions.trashAutoSavedComplete({ expense: expenses[0] }),
            LayoutActions.dismissModal(),
          ]),
          tap(() => this.modalService.close('mileage-expense-modal-new')),
          catchError(errorHandler(NewMileageActions.trashAutoSavedError))
        )
      )
    )
  );

  openModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewMileageActions.getExpenseComplete),
      map(() =>
        LayoutActions.openExpenseModal({ name: 'mileage-expense-modal-edit' })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<fromDashboard.State>,
    private modalService: BaseModalService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {}
}
