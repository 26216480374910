import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  BaseToastComponent,
  ExpenseUiWebComponentsModule,
} from '@ems-gui/expense/ui-web-components';
import { ExpenseUtilWebInfrastructureModule } from '@ems-gui/expense/util-web-infrastructure';
import { ExpenseUtilWebNgrxModule } from '@ems-gui/expense/util-web-ngrx';
import { ApprovalsCountsComponent } from '../../app/core/components/approvals-counts/approvals-counts.component';
import { DeleteDialogComponent } from '../../app/core/components/delete-dialog/delete-dialog.component';
import { EmptyTrashDialogComponent } from '../../app/core/components/empty-trash-dialog/empty-trash-dialog.component';
import { ExpenseFormEditComponent } from '../../app/core/components/expense-form-edit/expense-form-edit.component';
import { ExpenseFormItemizeEditComponent } from '../../app/core/components/expense-form-itemize-edit/expense-form-itemize-edit.component';
import { ExpenseFormItemizeComponent } from '../../app/core/components/expense-form-itemize/expense-form-itemize.component';
import { ExpenseFormNewComponent } from '../../app/core/components/expense-form-new/expense-form-new.component';
import { ExpenseFraudApproverWarnDialogComponent } from '../../app/core/components/expense-fraud-dialog/expense-fraud-approver-warn-dialog.component';
import { ExpenseFraudSubmitterWarnDialogComponent } from '../../app/core/components/expense-fraud-dialog/expense-fraud-submitter-warn-dialog.component';
import { ExpenseCardComponent } from '../../app/core/components/expense/expense-card/expense-card.component';
import { DetailsCardComponent } from '../../app/core/components/expense/expense-details-card/expense-details-card.component';
import { ExpenseFormLayoutComponent } from '../../app/core/components/expense/expense-form-layout/expense-form-layout.component';
import { ExpenseItemComponent } from '../../app/core/components/expense/expense-item/expense-item.component';
import { ExpenseNoteComponent } from '../../app/core/components/expense/expense-note/expense-note.component';
import { IconScanFailedComponent } from '../../app/core/components/icon-scan-failed/icon-scan-failed.component';
import { MileageExpenseFormEditComponent } from '../../app/core/components/mileage-expense-form-edit/mileage-expense-form-edit.component';
import { MileageExpenseFormNewComponent } from '../../app/core/components/mileage-expense-form-new/mileage-expense-form-new.component';
import { ReceiptDataWarnDialogComponent } from '../../app/core/components/receipt-data-warn-dialog/receipt-data-warn-dialog.component';
import { ReceiptManagerErrorDialogComponent } from '../../app/core/components/receipt-manager-dialogs/receipt-manager-error-dialog.component';
import { ReceiptManagerWarnDialogComponent } from '../../app/core/components/receipt-manager-dialogs/receipt-manager-warn-dialog.component';
import { ReceiptWidgetComponent } from '../../app/core/components/receipt-widget/receipt-widget.component';
import { ReceiptContainerComponent } from './components/receipt-container/receipt-container.component';
import { RejectFormComponent } from '../../app/core/components/reject-form/reject-form.component';
import { SearchInputComponent } from '../../app/core/components/search-input/search-input.component';
import { SendToFormComponent } from '../../app/core/components/send-to-form/send-to-form.component';
import { TopBarComponent } from '../../app/core/components/top-bar/top-bar.component';
import { UndoItemizationDialogComponent } from '../../app/core/components/undo-itemization-dialog/undo-itemization-dialog.component';
import { UserSummaryComponent } from '../../app/core/components/users/user-summary/user-summary.component';
import { ExpenseModalEditComponent } from '../../app/core/containers/expense-modal-edit/expense-modal-edit.component';
import { ExpenseModalMultiComponent } from '../../app/core/containers/expense-modal-multi/expense-modal-multi.component';
import { ExpenseModalComponent } from '../../app/core/containers/expense-modal/expense-modal.component';
import { HomePageComponent } from '../../app/core/containers/home-page/home-page.component';
import { ViewReceiptComponent } from './containers/view-receipt/view-receipt.component';
import { ApprovalModalComponent } from '../../app/core/containers/modals/approval-modal/approval-modal.component';
import { DeleteModalComponent } from '../../app/core/containers/modals/delete-modal/delete-modal.component';
import { ExpenseEditModalComponent } from '../../app/core/containers/modals/expense-edit-modal/expense-edit-modal.component';
import { ApproverFraudWarnModalComponent } from '../../app/core/containers/modals/expense-fraud-approval-modals/approver-fraud-warn-modal.component';
import { ExpenseFraudWarnModalComponent } from '../../app/core/containers/modals/expense-fraud-modals/expense-fraud-warn-modal.component';
import { ExpenseItemizeDraftModalComponent } from '../../app/core/containers/modals/expense-itemize-draft-modal/expense-itemize-draft-modal.component';
import { ExpenseItemizeEditModalComponent } from '../../app/core/containers/modals/expense-itemize-edit-modal/expense-itemize-edit-modal.component';
import { ExpenseItemizeModalComponent } from '../../app/core/containers/modals/expense-itemize-modal/expense-itemize-modal.component';
import { ExpenseNewModalComponent } from '../../app/core/containers/modals/expense-new-modal/expense-new-modal.component';
import { MileageExpenseEditModalComponent } from '../../app/core/containers/modals/mileage-expense-edit-modal/mileage-expense-edit-modal.component';
import { MileageExpenseNewModalComponent } from '../../app/core/containers/modals/mileage-expense-new-modal/mileage-expense-new-modal.component';
import { ReceiptDataWarnModalComponent } from '../../app/core/containers/modals/receipt-data-warn-modal/receipt-data-warn-modal.component';
import { ReceiptManagerErrorModalComponent } from '../../app/core/containers/modals/receipt-manager-modals/receipt-manager-error-modal.component';
import { ReceiptManagerWarnModalComponent } from '../../app/core/containers/modals/receipt-manager-modals/receipt-manager-warn-modal.component';
import { RejectModalComponent } from '../../app/core/containers/modals/reject-modal/reject-modal.component';
import { SendToModalComponent } from '../../app/core/containers/modals/send-to-modal/send-to-modal.component';
import { TrashDetailModalComponent } from '../../app/core/containers/modals/trash-detail-modal/trash-detail-modal.component';
import { TrashModalComponent } from '../../app/core/containers/modals/trash-modal/trash-modal.component';
import { UndoItemizationAlertModalComponent } from '../../app/core/containers/modals/undo-itemization-alert-modal/undo-itemization-alert-modal.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { DropzoneModule } from 'nxt-dropzone-wrapper';
import { FileSaverModule } from 'ngx-filesaver';
import { SnackbarModule } from 'ngx-snackbar';
import { ToastrModule } from 'ngx-toastr';
import { IconMenuComponent } from './components/icon-menu/icon-menu.component';
import { IconSpenseLogoComponent } from './components/icon-spense-logo/icon-spense-logo.component';
import { ProxyMenuComponent } from './components/proxy-menu/proxy-menu.component';
import { TransactionFeedItemComponent } from './components/transaction-feed-item/transaction-feed-item.component';
import { TransactionFeedItemModalComponent } from './containers/modals/transaction-feed-item-modal/transaction-feed-item-modal.component';
import { TrashMileageDetailModalComponent } from './containers/modals/trash-mileage-detail-modal/trash-mileage-detail-modal.component';
import { TravelTicketViewComponent } from './components/travel-ticket-view/travel-ticket-view.component';
import { ExpenseMatchComponent } from './components/expense-match/expense-match.component';
import { MaterialModule } from './material.module';
import { ExpenseMultiEditModalComponent } from '../../app/core/containers/modals/expense-multi-edit-modal/expense-multi-edit-modal.component';
import { SisModule } from "../sis/sis.module";
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import OrderModule

@NgModule({
  declarations: [
    ExpenseItemComponent,
    HomePageComponent,
    ViewReceiptComponent,
    ApprovalsCountsComponent,
    TopBarComponent,
    ExpenseModalMultiComponent,
    ExpenseModalComponent,
    ExpenseFormNewComponent,
    ExpenseFormEditComponent,
    RejectFormComponent,
    DeleteDialogComponent,
    EmptyTrashDialogComponent,
    UserSummaryComponent,
    MileageExpenseFormNewComponent,
    MileageExpenseFormEditComponent,
    ExpenseFormLayoutComponent,
    ExpenseModalEditComponent,
    SearchInputComponent,
    ReceiptWidgetComponent,
    ReceiptContainerComponent,
    ExpenseNoteComponent,
    ExpenseCardComponent,
    ExpenseNewModalComponent,
    ExpenseEditModalComponent,
    ExpenseMultiEditModalComponent,
    MileageExpenseNewModalComponent,
    MileageExpenseEditModalComponent,
    ApprovalModalComponent,
    RejectModalComponent,
    DeleteModalComponent,
    TrashModalComponent,
    ExpenseFormItemizeComponent,
    ExpenseItemizeModalComponent,
    ExpenseItemizeEditModalComponent,
    ExpenseFormItemizeEditComponent,
    UndoItemizationDialogComponent,
    ExpenseItemizeDraftModalComponent,
    UndoItemizationAlertModalComponent,
    SendToModalComponent,
    SendToFormComponent,
    DetailsCardComponent,
    ReceiptManagerErrorModalComponent,
    ReceiptManagerErrorDialogComponent,
    ReceiptManagerWarnModalComponent,
    ReceiptManagerWarnDialogComponent,
    ExpenseFraudWarnModalComponent,
    ApproverFraudWarnModalComponent,
    ExpenseFraudSubmitterWarnDialogComponent,
    ExpenseFraudApproverWarnDialogComponent,
    ReceiptDataWarnDialogComponent,
    ReceiptDataWarnModalComponent,
    IconScanFailedComponent,
    TrashDetailModalComponent,
    TrashMileageDetailModalComponent,
    TransactionFeedItemComponent,
    TransactionFeedItemModalComponent,
    TravelTicketViewComponent,
    ExpenseMatchComponent,
    IconSpenseLogoComponent,
    IconMenuComponent,
    ProxyMenuComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      toastComponent: BaseToastComponent,
      disableTimeOut: false,
      closeButton: true,
      enableHtml: true
    }),
    SnackbarModule.forRoot(),
    BrowserAnimationsModule,
    TooltipModule,
    DropzoneModule,
    FileSaverModule,
    ExpenseUiWebComponentsModule,
    ExpenseUtilWebInfrastructureModule,
    ExpenseUtilWebNgrxModule,
    SisModule,
    PdfViewerModule
  ]
})
export class CoreModule {}
