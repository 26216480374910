import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { OnChange } from '@ems-gui/shared/util-core';

@Component({
  selector: 'ems-expense-paginator',
  templateUrl: './expense-paginator.component.html',
  styleUrls: ['./expense-paginator.component.scss'],
})
export class ExpensePaginatorComponent {
  @OnChange(function (count, changes: SimpleChange) {
    if (
      !changes.isFirstChange() &&
      !count &&
      this.paginator?.hasPreviousPage()
    ) {
      this.paginator.previousPage();
    }
  })
  @Input()
  currentPageCount: number;
  @Input()
  count: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() pageUpdate = new EventEmitter<PageEvent>();
}
