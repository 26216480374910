<input
  matInput
  [matDatepicker]="datepicker"
  [placeholder]="placeholder"
  [formControl]="control"
  [max]="maxDate ? maxDate : ''"
  [min]="minDate ? minDate : ''"
  required
  [disabled]="isDisabled"
  [id]="inputId"
  (click)="$event.target.select()"
  (dateChange)="onDateChange($event)"
/>
<mat-datepicker-toggle
  matSuffix
  [for]="datepicker"
  *ngIf="!isDisabled"
>
  <mat-icon matDatepickerToggleIcon>
    <ems-base-icon
      iconName="calendar"
      class="datepicker__toggle"
    ></ems-base-icon>
  </mat-icon>
</mat-datepicker-toggle>
<mat-datepicker
  #datepicker
  [calendarHeaderComponent]="datepickerHeader"
  [disabled]="isDisabled"
></mat-datepicker>
