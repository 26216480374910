import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProxyAdminActions, ProxyUserActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  selectedId: string | number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(
    ProxyAdminActions.getProxies,
    ProxyAdminActions.addProxy,
    ProxyAdminActions.deleteProxy,
    ProxyUserActions.getProxies,
    ProxyUserActions.addProxy,
    ProxyUserActions.deleteProxy,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    ProxyAdminActions.getProxiesComplete,
    ProxyUserActions.getProxiesComplete,
    (state, { proxies }) =>
      adapter.setAll(proxies, {
        ...state,
        loading: false,
      })
  ),
  on(
    ProxyAdminActions.getProxiesError,
    ProxyAdminActions.addProxyError,
    ProxyAdminActions.deleteProxyError,
    ProxyUserActions.getProxiesError,
    ProxyUserActions.addProxyError,
    ProxyUserActions.deleteProxyError,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    ProxyUserActions.addProxyComplete,
    ProxyAdminActions.addProxyComplete,
    (state, { proxy }) =>
      adapter.addOne(proxy, {
        ...state,
        loading: false,
      })
  ),
  on(
    ProxyUserActions.deleteProxyComplete,
    ProxyAdminActions.deleteProxyComplete,
    (state, { proxy }) =>
      adapter.removeOne(proxy.id, {
        ...state,
        loading: false,
      })
  )
);
