<div class="expense-item">
  <div class="expense-item__date">{{ createdAt | date: 'M/d/yyyy' }}</div>
  <div class="expense-item__group">
    <div class="expense-item__vendor">{{ vendor }}</div>
    <div class="expense-item__id">Expense ID {{ id }}</div>
  </div>

  <div class="expense-item__group">
    <div class="expense-item__amount">
      {{ amount | convertToDollars | currency }}
    </div>
    <div class="expense-item__payment-type">{{ paymentType }}</div>
  </div>

  <a class="expense-item__thumbnail">
    <img [src]="thumbnail" />
  </a>
  <div class="expense-item__group">
    <div class="expense-item__billable">
      <span *ngIf="!isBillable">Non-</span>Billable
    </div>
    <div class="expense-item__type">{{ expenseType }}</div>
  </div>
  <div class="expense-item__description">{{ description }}</div>
  <ems-base-button
    label="Dismiss"
    buttonType="tertiary"
    [showLabel]="false"
    class="base-modal__dismiss"
  >
    <ems-base-icon icon-prepend iconName="times"></ems-base-icon>
  </ems-base-button>
</div>
