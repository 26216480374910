import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ems-transaction-feed-item',
  templateUrl: './transaction-feed-item.component.html',
  styleUrls: ['./transaction-feed-item.component.scss'],
})
export class TransactionFeedItemComponent {
  @Input() item;
  @Input() downloadUrl = '';
  @Output() downloadFile: EventEmitter<string> = new EventEmitter<string>();

  onDownloadFeedFile() {
    if (this.downloadUrl) {
      this.downloadFile.emit(this.downloadUrl);
    }
  }
}
