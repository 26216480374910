import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { OnChange } from '@ems-gui/shared/util-core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  takeUntil,
  tap,
  debounceTime,
  distinctUntilChanged,
  filter
} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import {
  KEY_STROKE_SETTINGS
} from '@ems-gui/shared/util-core';

@Component({
  selector: 'ems-server-typeahead',
  templateUrl: './server-typeahead.component.html',
  styleUrls: ['./server-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ServerTypeaheadComponent,
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ServerTypeaheadComponent
  implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit
{

  /**
   * @ignore
   */
  @OnChange(function (this: ServerTypeaheadComponent, favorites, changes) {
    const favoriteChanges =
      favorites && changes.currentValue && changes.currentValue.length;
    if (favoriteChanges !== null && favoriteChanges !== false) {
      const currentSelection = this.control ? this.control.value : null;

      /**
       * update the favorited state with the current selection
       */
      if (currentSelection !== null) {
        this.updateFavorites(currentSelection);
      }
    }
  })

  /**
   * Favorites
   *
   * Accepts array of favorite Contacts or JobCodes
   * @param {any[]} favorites
   */
  @Input()
  favorites = [];

  @Input()persons = [];

  @Input() canClear = false;

  /**
   * The type of data being used for `favorites` and `all`. Accepts `person`, `jobCode`, `salesforceCase`
   * @param {string} type
   */
  @Input() type;

  /**
   * Placeholder
   *
   * Accepts a string that renders the placeholder text
   * @param {string} placeholder
   */
  @Input() placeholder = 'Type to search...';

  /**
   * Toggle display of favorite action button. Accepts `true`, `false`
   * @param {boolean} favoritable
   * @default true
   */

  @Input() favoritable = true;

  /**
   * Direction of dropdown panel Accepts `right`, `left`
   * @param {string} direction
   * @default 'right'
   */
  @Input() direction = 'right';

  /**
   * Determines if input should be cleared on selection
   */
  @Input() clearOnSelection = false;

  @Input() label = 'Placeholder';
  @Input() optional = false;

  /**
   * The id of the favorited item. Outputs `id`
   * @param {number} id
   */
  @Output() favorite = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() resetSearch = new EventEmitter();
  @Input() isDisabled = false;
  @Input() isInvalid = false;

  /**
   * Select
   * The id of the selected item. Outputs `id`
   * @param {number} id
   * @ignore
   */
  control = new FormControl(null);

  typeAhead = new Subject<string>();

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  /**
   * @ignore
   */
  private unsubscribe$: Subject<void>;

  /**
   * @ignore
   */
  favoritedState$ = new BehaviorSubject(null);

  /**
   * @ignore
   */
  updateFavorites = (v) => {
    if(!this.favorites) return;
    const favorites = this.favorites;
    if (
      v === null ||
      (!favorites.find((f) => f.id === +v) &&
        this.favoritedState$.value === 'filled')
    ) {
      this.favoritedState$.next(null);
    } else if (favorites.find((f) => f.id === +v)) {
      this.favoritedState$.next('filled');
    } else {
      this.favoritedState$.next('outline');
    }
  };

  /**
   * @ignore
   */
  classNames() {
    return {
      'is-right': this.direction === 'right',
      'is-left': this.direction === 'left',
      'is-favoritable': this.favoritable === true,
      'is-invalid': this.isInvalid === true,
    };
  }

  constructor() {
    this.unsubscribe$ = new Subject<void>();
    this.control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(v => v !== null),
        tap((v) => {
          this.writeValue(v);
        })
      )
      .subscribe();

      this.typeAhead.pipe(
        debounceTime(KEY_STROKE_SETTINGS.debounceTime),
        distinctUntilChanged(),
        filter(
          event => event &&
            event.length >= KEY_STROKE_SETTINGS.minSearchCharacter
        ),
        tap((event: string) => {
          const params= {
            filters: {
              q: event
            }
          };
          this.search.emit(params);

        }),
        takeUntil(this.unsubscribe$)
      ).subscribe();
  }

  onSearch(event: any) {
    this.typeAhead.next(event.term);
  }

  onDropdownClose() {
    // Reset the store
    this.resetSearch.emit();
    // this.store$.dispatch(PersonActions.resetSearchedPersons());
  }

  
  /**
   * Terminology
   *
   * Returns a plural string of the current type
   */
  typeTerminology = () => {
    if (this.type === 'jobCode') {
      return 'Codes';
    } else if (this.type === 'person') {
      return 'Employees';
    } else {
      return 'Salesforce Cases';
    }
  };

  /**
   * Clear the value, this will trigger the onChange and clear the value
   * @ignore
   */
  clear() {
    this.writeValue(null);
    this.ngSelect.handleClearClick();
    this.ngSelect.blur();
    this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
    this.ngSelect.focused = false;
  }

  ngOnInit() {
    if (this.isDisabled) {
      this.control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  ngAfterViewInit(): void {
    if (this.ngSelect && !this.control.value) {
      this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onFavorite() {
    this.favorite.emit(this.control.value);
  }

  onClear() {
    this.writeValue('')
    this.ngSelect.focus();
    this.ngSelect.blur();
  }

  /**
   * @ignore
   */
  onChange(id: number) {}

  /**
   * @ignore
   */
  writeValue(value) {
    this.control.setValue(+value, { emitEvent: false });
    this.updateFavorites(value);
    this.onChange(value);
    if (this.ngSelect) {
      this.ngSelect.blur();
      this.ngSelect.searchInput.nativeElement.placeholder = '';
      this.ngSelect.focused = true;
    }

    if (this.clearOnSelection && this.control.value && this.favoritable) {
      this.control.setValue('', { emitEvent: false });
      this.ngSelect.handleClearClick();
      this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
    } else if (
      this.clearOnSelection &&
      this.control.value &&
      !this.favoritable &&
      this.ngSelect
    ) {
      setTimeout(() => {
        this.ngSelect.handleClearClick();
        this.ngSelect.blur();
        this.ngSelect.searchInput.nativeElement.placeholder = this.placeholder;
      }, 1000);
    }
  }

  /** 
   * @ignore
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * @ignore
   */
  registerOnTouched(fn) {}

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }
}
