import { Component, Input, OnInit, } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { skipWhile, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OptionEntity } from './option.entity';

type TypeOfResults = 'undefined' | 'object' | 'boolean' | 'number' |
  'string' | 'function' | 'symbol' | 'bigint';
type UpdateType = 'change' | 'blur' | 'submit';
type AcceptedOptionObjectType = Record<string, unknown>;
type AcceptedOptionType = string | AcceptedOptionObjectType;
type AcceptedOptionTypeCollection = AcceptedOptionType[];
type IsOptionDisabledCallback = (option: AcceptedOptionType) => boolean;

@Component({
  selector: 'sis-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true
    }
  ],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder = '';
  @Input() public inputId = '';
  @Input() public options: AcceptedOptionTypeCollection;
  @Input() public textKey: string;
  @Input() public valueKey: string;
  @Input() public titleKey: string;
  @Input() public isOptionDisabled: IsOptionDisabledCallback;
  @Input() public isDisabled = false;
  @Input() public value: string;

  public touched: boolean;
  public disabled: boolean;
  public control: FormControl;
  public unsubscribe$: Subject<void> = new Subject();
  public optionCollection: OptionEntity[] = [];

  protected serializeOptions: string;

  /**
   * Sets the form control for this component.
   * @constructor
   */
  public constructor() {
    this.control = this.newFormControl();
    if(!this.onChange || !this.onTouched) this.setOnCallbacks();
  }

  /**
   * @ignore
   */
  public onChange: (value: string) => void;

  /**
   * @ignore
   */
  public onTouched: () => void;

  /**
   * This method is called when the component is initialized as well when the
   * value has successfully changed and is ready to be updated in the view and
   * model.
   * @param { string } value The new value.
   * @returns { void } Does not return anything.
   */
  public writeValue(value: string) {
    this.control.setValue(value, { emitEvent: false });
    this.onChange(value);
  }

  /**
   * This method is called internally so that Angular can register the event
   * listener used when a value is changed.
   * @param { () => void } onChange The event listener that will be set to
   * onChange.
   * @returns { void } Does not return anything.
   */
  public registerOnChange(onChange: () => void) {
    this.onChange = onChange;
  }

  /**
   * This method is called internally so that Angular can register the event
   * listener used the form element has been initially touched.
   * @param { () => void } onTouched The event listener that will be set to
   * onTouched.
   * @returns { void } Does not return anything.
   */
  public registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  /**
   * This method is called when the components form element is initially
   * touched.
   */
  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /**
   * This is called when the component is has become disabled or enabled.
   * @param { boolean } disabled What to set the status too.
   * @returns { void } Does not return anything.
   */
  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  protected setOnCallbacks() {
    this.onChange = (value) => value;
    this.onTouched = () => true;
  }

  /**
   * Creates the newFormControl that is used to translate changes to or from
   * this component.
   * @returns { FormControl } The new form control that has been instantiated.
   * @protected
   */
  protected newFormControl() {
    return new FormControl(
      { value: this.value, disabled: this.disabled },
      { updateOn: <UpdateType>'change' }
    );
  }

  /**
   * A getter that serializes the Options array that was provided.  It is
   * used to check if options has changed since our last lifecycle.
   * @returns { string } The serialized options string.
   * @protected
   */
  protected get jsonOptions() {
    return JSON.stringify(this.options);
  }

  /**
   * This method is checks if the Options has changed since our last lifecycle.
   * @returns { boolean } Whether or not the options have changed.
   * @protected
   */
  protected hasOptionsChanged() {
    const json = this.jsonOptions;
    if(!this.serializeOptions || this.serializeOptions !== json) {
      this.serializeOptions = json;
      return true;
    }

    return false;
  }

  /**
   * This method throws an error. Allows the error messages to be formatted
   * consistently.
   * @param { string } subject The subject of the error.
   * @param { string } message Why is there an error and how to correct it.
   * @returns { void } Does not return anything.
   * @throws { Error }
   * @example ```ts
   * this.newError('Options', 'Requires the options attribute.');
   * ```
   * @protected
   */
  protected newError(subject: string, message: string) {
    throw new Error(`Component sis-select: (${subject}) ${message}`);
  }

  /**
   * This method stops the code and throws an Error based on unexpected types.
   * @param { string } subject The subject that is requesting a attribute check.
   * @param { string } attrName The attribute name that was checked.
   * @param { TypeOfResults | string } expected The expected typeof.
   * @param { TypeOfResults | string } [received] What was received.  Optional,
   * used for custom expected and received types.
   * @returns { void } Does not return anything.
   * @throws { Error }
   * @example ```ts
   * this.attributeTypeError('Options', 'options', 'array');
   * ```
   * @protected
   */
  protected typeCheckError(
    subject: string,
    attrName: string,
    expected: TypeOfResults | string,
    received?: TypeOfResults | string
  ) {
    const expectedMessage = `Expected "${expected}"`;
    const receivedType = received ? received : typeof this[attrName];
    const receivedMessage = `Received "${receivedType}"`
    const message = `${attrName} - ${expectedMessage} | ${receivedMessage}`;
    return this.newError(subject, message);
  }

  /**
   * This method checks if the variableName is of the expected type or not.
   * @param { string } subject The subject that is checking.
   * @param { string } variableName The name of the variable to check.
   * @param { string } expected What type is expected.
   * @returns { void } Does not return anything.
   * @throws { Error }
   * @example ```ts
   * this.typeCheck('Options', 'options', 'array');
   * ```
   * @protected
   */
  protected typeCheck(subject: string, variableName: string, expected: string) {
    switch(expected) {
      case 'array':
        if(!Array.isArray(this[variableName]))
          return this.typeCheckError(subject, variableName, expected);
        break;
      case 'defined':
        if(this[variableName] === undefined)
          return this.typeCheckError(
            subject,
            variableName,
            expected,
            'undefined'
          );
        break;
    }

    return true;
  }

  /**
   * If no argument is provided it validates different parts of the component
   * when instantiated based on Options.  If an Option is passed it will check
   * if textKey and valueKey when the option is not a string.  These attributes
   * are required.
   * @param { AcceptedOptionObjectType } [option] The option from the the array.
   * @returns { void } Does not return anything.
   * @throws { Error }
   * @example ```ts
   * this.validateOptions();
   * ...
   * this.options.forEach(option => this.validateOptions(option));
   * ...
   * ```
   * @protected
   */
  protected validateOptions(option?: AcceptedOptionType) {
    const isString = typeof option !== 'string';
    [
      !isString ? ['Options Validation', 'options', 'array'] : undefined,
      isString ? ['Options Validation', 'textKey', 'defined'] : undefined,
      isString ? ['Options Validation', 'valueKey', 'defined'] : undefined
    ].forEach((args: [string, string, string]) => args !== undefined ?
      this.typeCheck(...args) :
      false
    );
  }

  /**
   * This is one of the last steps.  It iterates through all options that were
   * provided and based on the set up creates options.
   * @returns { void }
   * @protected
   */
  protected prepareOptions() {
    if(!this.hasOptionsChanged()) return;

    if(Array.isArray(this.options)) {
      this.optionCollection = this.options.map((option) => {
        this.validateOptions(option);
        return new OptionEntity(this, option);
      });
    }
  }

  /**
   * LifeCycle event called OnInit.  Everything that is Reactive will be set up
   * here.
   * @returns {void}
   */
  public ngOnInit() {
    if(this.isDisabled) this.disabled = this.isDisabled;
    this.validateOptions();
    this.prepareOptions();
    this.control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        skipWhile((value) => !value),
        tap((value?: string) => {
          this.writeValue(value);
        })
      )
      .subscribe();
  }
}
