import {
  Dashboard,
  Expense,
  approvals,
  Activity
} from "@ems-gui/shared/util-core";
import { createAction, props } from '@ngrx/store';
import {
  ApprovalEditColumns
} from "libs/shared/util-core/src/lib/models/approval-column-edit.model";

export const isLoading = createAction(
  '[Approval] isLoading'
);

export const isLoadingComplete = createAction(
  '[Approval] isLoadingComplete'
);

export const get = createAction(
  '[Approval] get',
  props<{ page: any; sort: any; filters: any }>()
);

export const getComplete = createAction(
  '[Approval] getComplete',
  props<{
    approvals: {
      expenses: Expense[];
      totalCount: number;
    };
  }>()
);

export const getError = createAction(
  '[Approval] getError',
  props<{ error: string }>()
);

export const getAllApprovals = createAction('[Approval] getAllApprovals');

export const getUpdatedApprovals = createAction(
  '[Approval] getUpdatedApprovals'
);

export const getAllApprovalsComplete = createAction(
  '[Approval] getAllApprovalsComplete',
  props<{
    approvals: Expense[];
    counts: { pending: number; reviewed: number };
  }>()
);

export const getAllApprovalsError = createAction(
  '[Approval] getAllApprovalsError',
  props<{ error: string }>()
);

export const applyFilters = createAction(
  '[Approval] applyFilters',
  props<{ page: any; sort: any; filters: any }>()
);

export const applyFiltersComplete = createAction(
  '[Approval] applyFiltersComplete',
  props<{
    approvals: {
      expenses: Expense[];
      totalCount: number;
    };
  }>()
);

export const applyFiltersError = createAction(
  '[Approval] applyFiltersError',
  props<{ error: string }>()
);

export const applyReviewedFilters = createAction(
  '[Approval] applyReviewedFilters',
  props<{ page: any; sort: any; filters: any }>()
);

export const applyReviewedFiltersComplete = createAction(
  '[Approval] applyReviewedFiltersComplete',
  props<{
    approvals: {
      expenses: Expense[];
      totalCount: number;
    };
  }>()
);

export const applyReviewedFiltersError = createAction(
  '[Approval] applyReviewedFiltersError',
  props<{ error: string }>()
);

export const verifyOneExpense = createAction(
  '[Approval] verifyOneExpense',
  props<{ reportId: number[]; status?: string; message?: string }>()
);

export const verifyOneExpenseComplete = createAction(
  '[Approval] verifyOneExpenseComplete',
  props<{ expense: any }>()
);

export const verifyOneExpenseError = createAction(
  '[Approval] verifyOneExpenseError',
  props<{ error: string }>()
);

export const updateVerify = createAction('[Approval] updateVerify');

export const verifyExpenses = createAction(
  '[Approval] verifyExpenses',
  props<{ reportIds?: number[]; status?: string; message?: string }>()
);

export const verifyExpensesComplete = createAction(
  '[Approval] verifyExpensesComplete',
  props<{ expenses: any }>()
);

export const verifyExpensesError = createAction(
  '[Approval] verifyExpensesError',
  props<{ error: string }>()
);

export const deleteExpenses = createAction(
  '[Approval] deleteExpenses',
  props<{ expenses: any }>()
);

export const deleteExpensesComplete = createAction(
  '[Approval] deleteExpensesComplete'
);

export const deleteExpensesError = createAction(
  '[Approval] deleteExpensesError',
  props<{ error: string }>()
);

export const getExpenseCounts = createAction('[Approval] getExpenseCounts');

export const getExpenseCountsComplete = createAction(
  '[Approval] getExpenseCountsComplete',
  props<{ counts: Dashboard }>()
);

export const getExpenseCountsError = createAction(
  '[Approval] getExpenseCountsError',
  props<{ error: string }>()
);

export const undoVerifyExpenses = createAction(
  '[Approval] undoverifyExpense',
  props<{ status: string }>()
);

export const undoVerifyExpensesComplete = createAction(
  '[Approval] undoVerifyExpensesComplete',
  props<{ expenses: any }>()
);

export const undoVerifyExpensesError = createAction(
  '[Approval] undoVerifyExpensesError',
  props<{ error: string }>()
);

export const undoVerifyOneExpense = createAction(
  '[Approval] undoVerifyOneExpense',
  props<{ status: string }>()
);

export const undoVerifyOneExpenseComplete = createAction(
  '[Approval] undoVerifyOneExpenseComplete',
  props<{ expense: any }>()
);

export const undoVerifyOneExpenseError = createAction(
  '[Approval] undoVerifyOneExpenseError',
  props<{ error: string }>()
);

export const undoUpdateVerify = createAction('[Approval] undoUpdateVerify');

export const uploadReceiptComplete = createAction(
  '[Approval] uploadReceiptComplete',
  props<{ expense: any;}>()
);

export const selectExpense = createAction(
  '[Approval] selectExpense',
  props<{ id: any; uploadReceipt?: boolean; getExpenseType?: string }>()
);

export const selectExpenseComplete = createAction(
  '[Approval] selectExpenseComplete',
  props<{ expense: Expense; uploadReceipt: boolean }>()
);

export const selectExpenses = createAction(
  '[Approval] selectExpenses',
  props<{ reportIds: any }>()
);

export const approvalEdit = createAction(
  '[Approval] approvalEdit',
  props<{ updates: ApprovalEditColumns }>()
);

export const approvalsMultiEditComplete = createAction(
  '[Approval] approvalsMultiEditComplete',
  props<{ expenses: any }>()
);

export const approvalEditComplete = createAction(
  '[Approval] approvalEditComplete',
  props<{ expense: Expense }>()
);

export const approvalEditError = createAction(
  '[Approval] approvalEditError',
  props<{ error: string }>()
);

export const setJobCodeId = createAction(
  '[Approval] setJobCodeId',
  props<{ id: number }>()
);

export const reset = createAction('[Approval] reset');

export const autoSaveNote = createAction(
  '[Approval] autoSaveNote',
  props<{ message: string }>()
);

export const rotateReceipt = createAction(
  '[Approval] rotateReceipt',
  props<{ angle: number; id: number }>()
);

export const rotateReceiptComplete = createAction(
  '[Approval] rotateReceiptComplete',
  props<{ expense: Expense }>()
);

export const rotateReceiptError = createAction(
  '[Approval] rotateReceiptError',
  props<{ error: any }>()
);

export const removeReceipt = createAction(
  '[Approval] removeReceipt',
  props<{ id: number }>()
);

export const removeReceiptComplete = createAction(
  '[Approval] removeReceiptComplete',
  props<{ expense: Partial<Expense> }>()
);

export const removeReceiptError = createAction(
  '[Approval] removeReceiptError',
  props<{ error: string }>()
);
