import { Injectable, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from '@ems-gui/shared/util-core';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  /**
   * form
   * @description configures a form that can be used on the new expense form and edit expense form
   */
  readonly form = () => ({
    vendor: new FormControl(null, {
      validators: [Validators.required],
      updateOn: 'change'
    }),
    address: new FormControl(null, {
      updateOn: 'change'
    }),
    amount: new FormControl(null, {
      validators: [
        Validators.required,
        CustomValidators.validAmount,
        CustomValidators.belowMaxAmount,
      ],
      updateOn: 'change'
    }),
    transactionDate: new FormControl(null, {
      validators: [Validators.required],
    }),
    type: new FormControl(null, {
      validators: [Validators.required],
    }),
    paymentType: new FormControl(null),
    jobCode: new FormControl(null, {
      validators: [Validators.required],
    }),
    salesforceId: new FormControl(null),
    description: new FormControl(null,{
      validators: [Validators.required],
      updateOn: 'change'
    }),
    message: new FormControl(null, {
      updateOn: 'change'
    }),
    isBillable: new FormControl(null),
    fraudulent: new FormControl(''),
    token: new FormControl(null),
    travelTicket: new FormControl(null),
    travelTicketDetail: new FormControl(null),
    rotationAngle: new FormControl(null),
    ocrMismatch: new FormControl(null)
  });

  /**
   * isBillable
   * @description configures isBillable options
   */
  readonly isBillable = () => [
    {
      display: 'Yes',
      value: true,
      checked: false,
    },
    {
      display: 'No',
      value: false,
      checked: true,
    },
  ];

  /**
   * Get Expense Modal Title based on payment type
   * @param paymentType from backend response
   * @returns Title to display for expense modals
   */
  getExpenseModalTitle(paymentType) {
    switch (paymentType) {
      case 'company':
        return 'Company CC Expense: ';
      case 'personal':
      case 'cash':
        return 'Out of Pocket Expense: ';
      case 'mileage':
        return 'Mileage Expense: ';
      default:
        return paymentType;
    }
  }

  /**
   * Get detailed payment types based on the keywords
   * @param paymentType from backend response
   * @returns Payment type description for list views
   */
  getPaymentType(paymentType) {
    switch (paymentType) {
      case 'company':
        return 'Company Credit Card';
      case 'personal':
        return 'Out of Pocket Expense';
      default:
        return paymentType;
    }
  }

  /**
   * checkForDismissChanges
   * @param that - `this` from the component
   * @param changes {SimpleChanges}
   * @param name {string} either 'new' or 'edit
   */
  checkForDismissChanges = (
    that: any,
    changes: SimpleChanges,
    name: string
  ): void => {
    if (changes.dismiss) {
      const dismiss = changes.dismiss.currentValue;
      if (dismiss) {
        that.dismissing = true;
        that.form.get('vendor').setValue(null);
        that.form.get('address').setValue(null);
        that.form.get('amount').setValue(null);
        that.form.get('type').setValue(null);
        that.form.get('jobCode').setValue(null);
        that.form.get('salesforceId').setValue(null);
        that.form.get('description').setValue(null);
        that.form.get('message').setValue(null);
        that.form.get('isBillable').setValue(false);
        that.form.get('travelTicket').setValue(null);
        that.form.get('travelTicketDetail').setValue(null);
        if (name === 'new') {
          that.jobCodeEl.clear();
          that.form.get('transactionDate').setValue([new Date()]);
          that.form.get('paymentType').setValue('cash');
        } else if (name === 'edit') {
          that.form.get('transactionDate').setValue(null);
          that.form.get('paymentType').setValue(null);
        }
        that.form.markAsPristine();
        that.form.markAsUntouched();
        that.form.updateValueAndValidity();
        that.showNote = false;
        that.showSalesforce = false;
        that.dismissing = false;
      }
    }
  };
}
