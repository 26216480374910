<ems-base-modal
  [config]="modalInput$ | async"
  (dismiss)="onDismiss()"
  class="expense-multi-edit-modal"
>

  <ems-base-button-group modal-actions>
    <ems-base-button
      buttonType="secondary"
      variant="brand"
      label="Cancel"
      (click)="onCancel()"
    ></ems-base-button>
    <ems-base-button
      buttonType="primary"
      variant="brand"
      label="Save"
      type="submit"
      (click)="onSubmit(form)"
    ></ems-base-button>
  </ems-base-button-group>

  <ems-expense-form-layout includeReceipt='false'>
    <div [formGroup]="form" content class="multi-edit-form">
      <!-- Expense Type -->
      <div>
        <label class="ems-label">Expense Type</label>
        <div
          class="ems-select"
        >
          <select
            formControlName="type"
          >
            <option [ngValue]="null" disabled>Mixed</option>
            <option
              *ngFor="let type of expenseTypes$ | async"
              [value]="type?.id"
              [title]="type?.description"
            >
              {{ type?.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Description -->
      <div>
        <label class="ems-label">
          Description
        </label>
        <div class="ems-control">
          <textarea
            formControlName="description"
            type="text"
            class="ems-textarea"
            placeholder="Changes will apply to all selected expenses"
            [maxlength]="maxLength"
          ></textarea>
        </div>
      </div>

      <!-- Job Code Autocomplete -->
      <div>
        <ems-typeahead
          label="Job Code"
          type="jobCode"
          [items]="jobCodes$ | async"
          formControlName="jobCode"
          placeholder="Mixed"
        ></ems-typeahead>
      </div>

      <div>
        <ems-base-inline *ngIf="!showNote">
          <ems-base-icon
            iconName="add-small"
            (click)="onToggleNote()"
            class="note-icon add"
          ></ems-base-icon>
          <span class="ems-label ems-label--expense-note">
            Add Note
          </span>
        </ems-base-inline>
        <ems-base-inline *ngIf="showNote">
          <ems-base-icon
            iconName="add-small"
            (click)="onToggleNote()"
            class="note-icon remove"
          ></ems-base-icon>
          <span class="ems-label ems-label--expense-note"> Note </span>
        </ems-base-inline>
        <div class="ems-control" *ngIf="showNote">
          <textarea
            formControlName="message"
            placeholder="Changes will apply to all selected expenses"
            class="ems-textarea"
            [value]="expense?.message ? expense?.message : ''"
          ></textarea>
        </div>
      </div>

      <div>
        <ems-base-inline *ngIf="!showSalesforce">
          <ems-base-icon
            iconName="remove"
            (click)="onToggleSalesforce()"
            class="note-icon add"
          ></ems-base-icon>
          <span class="ems-label ems-label--expense-note">
            Add Custom Case
          </span>
        </ems-base-inline>
        <ems-base-inline *ngIf="showSalesforce">
          <ems-base-icon
            iconName="remove"
            (click)="onToggleSalesforce()"
            class="note-icon remove"
          ></ems-base-icon>
        </ems-base-inline>
        <div class="ems-control" *ngIf="showSalesforce">
          <ems-typeahead
            #salesforceEl
            label="Custom Case"
            type="salesforceCase"
            [items]="salesforceCases$ | async"
            formControlName="salesforceId"
            (favorite)="onFavoriteSalesforceCase($event)"
            placeholder="Mixed"
          ></ems-typeahead>
        </div>
      </div>

    </div>
  </ems-expense-form-layout>

</ems-base-modal>
