import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ApiService,
  BaseModalService,
} from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import {
  catchError,
  delay,
  filter,
  map,
  retryWhen,
  scan,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ReceiptManagerActions } from '../actions';
import { errorHandler } from '../error-handler';
import * as fromDashboard from '../reducers';
import {
  selectCurrentFileFormat,
  selectReceiptFiles,
} from '../selectors/receipt-manager.selectors';

@Injectable()
export class ReceiptManagerEffects {
  downloadReceiptUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptManagerActions.downloadReceiptUrls),
      switchMap(({ expenses, action, id_type }) =>
        this.apiService.getReceiptFileUrls(expenses, action, id_type ).pipe(
          map((result: any) =>
            ReceiptManagerActions.downloadReceiptUrlsComplete({
              csv: result.csv,
              file: result.file,
            })
          ),
          catchError(
            errorHandler(ReceiptManagerActions.downloadReceiptUrlsError)
          )
        )
      )
    )
  );

  downloadReceiptsCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptManagerActions.downloadReceiptUrlsComplete),
      map((res) => {
        if (res.csv && !res.file) {
          return ReceiptManagerActions.displayNoMatchingReceiptsAlert();
        } else if (!res.csv && res.file) {
          return ReceiptManagerActions.downloadReceiptFile();
        } else {
          return ReceiptManagerActions.displayMissingReceiptsAlert();
        }
      })
    )
  );

  displayNoMatchingReceiptsAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReceiptManagerActions.displayNoMatchingReceiptsAlert),
        tap(() => this.modalService.open('receipt-manager-error-modal'))
      ),
    { dispatch: false }
  );

  displayMissingReceiptsAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReceiptManagerActions.displayMissingReceiptsAlert),
        tap(() => this.modalService.open('receipt-manager-warn-modal'))
      ),
    { dispatch: false }
  );

  downloadReceiptFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptManagerActions.downloadReceiptFile),
      withLatestFrom(this.store$.pipe(select(selectReceiptFiles))),
      filter(([action, files]) => files.file),
      switchMap(([action, files]) =>
        this.apiService.getReceiptFile(files.file).pipe(
          retryWhen((error$) => {
            return error$.pipe(
              delay(3000),
              scan((count, err) => {
                if (count > 9) {
                  throw err;
                } else {
                  return (count += 1);
                }
              }, 0)
            );
          }),
          map((file: any) =>
            ReceiptManagerActions.downloadReceiptFileComplete({ file })
          ),
          catchError(
            errorHandler(ReceiptManagerActions.downloadReceiptFileError)
          )
        )
      )
    )
  );

  downloadErrorCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptManagerActions.downloadErrorCsv),
      withLatestFrom(this.store$.pipe(select(selectReceiptFiles))),
      filter(([action, files]) => files.csv),
      switchMap(([action, files]) =>
        this.apiService.getReceiptFile(files.csv).pipe(
          retryWhen((error$) => {
            return error$.pipe(
              delay(1000),
              scan((count, err) => {
                if (count > 9) {
                  throw err;
                } else {
                  return (count += 1);
                }
              }, 0)
            );
          }),
          map((file: any) =>
            ReceiptManagerActions.downloadErrorCsvComplete({ file })
          ),
          catchError(errorHandler(ReceiptManagerActions.downloadErrorCsvError))
        )
      )
    )
  );

  downloadFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReceiptManagerActions.downloadReceiptFileComplete),
        withLatestFrom(this.store$.pipe(select(selectCurrentFileFormat))),
        map(([res, format]) => {
          const timestamp = res.file.url
            .split(/\//)[5]
            .split(/\?/)[0]
            .split(/\-/)[1]
            .split(/\./)[0];
          const employeeId = res.file.url
            .split(/\//)[5]
            .split(/\?/)[0]
            .split(/\-/)[0];
          const dateTime = formatDate(
            new Date(+timestamp),
            'yyyy-MM-ddThhmmss',
            'en-US'
          );
          const filename = `${employeeId}-${dateTime}.${format}`;
          if (filename) {
            return this.fileSaverService.save(<Blob>res.file.body, filename);
          }
        })
      ),
    { dispatch: false }
  );

  downloadErrorCsvComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReceiptManagerActions.downloadErrorCsvComplete),
        map((res) => {
          const timestamp = res.file.url
            .split(/\//)[5]
            .split(/\?/)[0]
            .split(/\-/)[1]
            .split(/\./)[0];
          const employeeId = res.file.url
            .split(/\//)[5]
            .split(/\?/)[0]
            .split(/\-/)[0];
          const dateTime = formatDate(
            new Date(+timestamp),
            'yyyy-MM-ddThhmmss',
            'en-US'
          );
          const filename = `${employeeId}-${dateTime}.csv`;
          if (filename) {
            return this.fileSaverService.save(<Blob>res.file.body, filename);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private modalService: BaseModalService,
    private fileSaverService: FileSaverService,
    private store$: Store<fromDashboard.State>
  ) {}
}
