import { AuthService } from '@sec-spec/lib-ng-oauth';
import { JWT } from '../guards/jwt.interface';
import { JsonWebTokenEntity } from './oauth-token/json-web-token.entity';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import type { Observable } from 'rxjs';

/**
 * This class is intended to provide the ability to work with and access the
 * Json Web Token(JWT) from anywhere at any point.  It will make it easy to
 * keep one source of truth for this code at any point.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class OauthTokenService {
  private loginRoutePath: string[] = ['oauth', 'login'];

  /**
   * This allows you to easily create JsonWebTokenEntity objects.
   * @param [token] { string | JWT } The token that is being read.
   * @returns { JsonWebTokenEntity } The JWT class that allows you easily read
   * different parts of the token I.E. Payloads.
   */
  public jwt(token?: string | JWT) {
    if(!token || token === 'No Access Token') return undefined;
    return new JsonWebTokenEntity(<JWT>token)
  }

  /**
   * Gets and prepares the Access Token.
   * @returns { JsonWebTokenEntity }
   */
  public get accessToken() {
    const token = this.authService.getToken();
    return this.jwt(token);
  }

  /**
   * Gets and prepares the Refresh Token.
   * @returns { JsonWebTokenEntity }
   */
  public get refreshToken() {
    const token = this.authService.getTokenForRefresh();
    return this.jwt(token);
  }

  /**
   * Returns if the Access Token is expired or not.
   * @returns { boolean }
   */
  public get isAccessTokenExpired() {
    const token = this.accessToken;
    return !token ? true : this.accessToken.isExpired;
  }

  /**
   * Returns if the Refresh Token is expired or not.
   * @returns { boolean }
   */
  public get isRefreshTokenExpired() {
    const token = this.refreshToken;
    return !token ? true : this.refreshToken.isExpired;
  }

  /**
   * Checks if both Access Token and Refresh Token are expired or not.
   * @returns { boolean }
   */
  public get isCompletelyExpired() {
    return this.isAccessTokenExpired && this.isRefreshTokenExpired;
  }

  public constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  /**
   * Attempts to refresh the Access Token.  It assumes that the refresh token
   * has been checked already at this point.
   * @returns { Observable<string> }
   */
  public refreshAccessToken(): Observable<string> {
    return this.authService.refreshToken();
  }

  /**
   * Redirects the user back to the Login page.
   * @returns { Promise<boolean> }
   */
  public async goToLogin() {
    return this.router.navigate(this.loginRoutePath)
      .catch((error: Error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * Transforms the goToLogin return from a Promise<boolean> to a
   * Observable<boolean>.
   * @returns { Observable<boolean> }
   */
  public ofGoToLogin() {
    return of(this.goToLogin());
  }

  /**
   * Gets the current Access Token if it is not expired.  If it is then checks
   * the Refresh Token.  If the Refresh Token is expired it returns undefined.
   * If it is not it reaches out to attempt and get a new Access Token.
   * @returns { Observable<string | undefined> }
   */
  public getAccessToken() {
    return of(undefined)
      .pipe(
        take(1),
        map(() => {
          if(this.isCompletelyExpired)
            return of(undefined)

          if(this.isAccessTokenExpired)
            return this.refreshAccessToken()

          return of(`${this.accessToken}`);
        }),
        switchMap((accessToken) => accessToken)
      );
  }
}
