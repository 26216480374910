import { createSelector } from '@ngrx/store';
import * as fromProxy from '../reducers/proxy.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardProxyState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.proxy
);

export const {
  selectIds: selectProxyIds,
  selectEntities: selectProxyEntities,
  selectAll: selectAllProxy,
} = fromProxy.adapter.getSelectors(selectDashboardProxyState);
