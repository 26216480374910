import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FavoritePersonActions, UserActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  selectedId: string | number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  filter: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(
    FavoritePersonActions.saveFavoritePerson,
    FavoritePersonActions.getFavoritePerson,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    FavoritePersonActions.deleteFavoritePersonError,
    FavoritePersonActions.getFavoritePersonError,
    FavoritePersonActions.saveFavoritePersonError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(FavoritePersonActions.getFavoritePersonComplete, (state, { person }) =>
    adapter.setAll(person, {
      ...state,
      loading: false,
    })
  ),
  on(FavoritePersonActions.saveFavoritePersonComplete, (state, { person }) =>
    adapter.addOne(person, {
      ...state,
      loading: false,
    })
  ),
  on(FavoritePersonActions.deleteFavoritePersonComplete, (state, { person }) =>
    adapter.removeOne(person.id, {
      ...state,
      loading: false,
    })
  ),
  on(UserActions.logout, () => adapter.getInitialState(initialState))
);
