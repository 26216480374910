import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FavoriteSalesforceCaseActions, UserActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  selectedId: string | number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  filter: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(
    FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase,
    FavoriteSalesforceCaseActions.getFavoriteSalesforceCase,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    FavoriteSalesforceCaseActions.deleteFavoriteSalesforceCaseError,
    FavoriteSalesforceCaseActions.getFavoriteSalesforceCaseError,
    FavoriteSalesforceCaseActions.saveFavoriteSalesforceCaseError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    FavoriteSalesforceCaseActions.getFavoriteSalesforceCaseComplete,
    (state, { salesforceCases }) =>
      adapter.setAll(salesforceCases, {
        ...state,
        loading: false,
      })
  ),
  on(
    FavoriteSalesforceCaseActions.saveFavoriteSalesforceCaseComplete,
    (state, { salesforceCase }) =>
      adapter.addOne(salesforceCase, {
        ...state,
        loading: false,
      })
  ),
  on(
    FavoriteSalesforceCaseActions.deleteFavoriteSalesforceCaseComplete,
    (state, { salesforceCase }) =>
      adapter.removeOne(salesforceCase.id, {
        ...state,
        loading: false,
      })
  ),
  on(UserActions.logout, () => adapter.getInitialState(initialState))
);
