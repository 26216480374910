<ems-base-modal
  id="credit-card-holder-alert"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onCancel()"
>
  <sis-credit-card-holder-alert
    (onContinue)="onContinue()"
    (onCancel)="onCancel()"
    isModal="true"
  ></sis-credit-card-holder-alert>
</ems-base-modal>
