import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DiscardArguments {
  save(): void;
  discard(): void;
}

@Component({
  selector: 'sis-discard-changes-alert',
  templateUrl: './discard-changes-alert.component.html',
  styleUrls: ['./discard-changes-alert.component.scss']
})
export class DiscardChangesAlertComponent {

  public constructor(
    public dialogRef: MatDialogRef<DiscardChangesAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DiscardArguments
  ) {}

  cancel() {
    this.dialogRef.close(this);
  }

  saveChanges() {
    this.data.save();
    this.dialogRef.close(this);
  }

  discard() {
    this.data.discard();
    this.dialogRef.close(this);
  }
}
