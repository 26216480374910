<div class="base-filters">
  <div class="base-filters__controls">
    <ems-base-button
      [label]="filtersDropLabel"
      buttonType="primary"
      variant="inverted"
      [state]="filterButtonState"
      (click)="toggleFiltersDrop()"
    >
      <ems-base-icon
        icon-prepend
        iconName="filters"
        class="filters-btn"
      ></ems-base-icon>
    </ems-base-button>
  </div>

  <div class="base-filters__content" [class.is-active]="filtersDropIsActive">
    <ng-content></ng-content>
  </div>
</div>
