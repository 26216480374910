<ems-base-modal
  [config]="modalInput$ | async"
  (dismiss)="onDismiss()"
  class="trash-detail-modal"
>
  <!-- Trashed Actions -->
  <ems-base-button-group modal-actions class="trash-actions">
    <ems-base-dropdown dropdownLabel="Actions" class="trash-dropdown">
      <a class="base-dropdown__item" (click)="onRestore(expense.id)">
        Restore
      </a>
    </ems-base-dropdown>
  </ems-base-button-group>

  <ems-expense-form-edit
    [favoriteJobCodes]="favoriteJobCodes$ | async"
    [dismiss]="dismiss$ | async"
    [expense]="expense$ | async"
    [jobCodes]="jobCodes$ | async"
    [salesforceCases]="salesforceCases$ | async"
    [favoriteSalesforceCases]="favoriteSalesforceCases$ | async"
    [expenseTypes]="expenseTypes$ | async"
    [isTrashed]="isTrashed"
    [itemizationStatus]="itemizationStatus$ | async"
  ></ems-expense-form-edit>
</ems-base-modal>
