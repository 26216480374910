// TODO: REMOVE: only used in expense-modal-multi that is also unused
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-expense-item',
  templateUrl: './expense-item.component.html',
  styleUrls: ['./expense-item.component.scss'],
})
export class ExpenseItemComponent implements OnInit {
  @Input() createdAt: number = null;
  @Input() vendor = 'Vendor';
  @Input() id: number = null;
  @Input() amount = '0';
  @Input() paymentType = 'Payment Type';
  @Input() thumbnail = '';
  @Input() isBillable: boolean = null;
  @Input() expenseType = 'Expense Type';
  @Input() description = 'No Description';

  ngOnInit() {
    return;
  }
}
