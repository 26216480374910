import { Injectable } from '@angular/core';
import { Expense, ExpenseType } from '@ems-gui/shared/util-core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectCurrentSubmittedPageSorted,
  selectCurrentTrashPageSorted,
  selectCurrentUnsubmittedPageSorted,
  State,
} from '../+state/reducers';
import {
  selectAllExpenseTypes,
  selectDashboardSubmissionsApprovedCount,
  selectDashboardSubmissionsPendingCount,
  selectDashboardSubmissionsRejectedCount,
  selectDashboardSubmittedCount,
  selectDashboardTrashCount,
  selectDashboardUnsubmittedCount,
  selectSubmittedCount,
  selectTrashedExpenseCount,
  selectUnsubmittedCount,
  submittedExpensesLoading,
} from '../+state/selectors';

@Injectable({ providedIn: 'root' })
export class WebSubmitterFacade {
  expenseTypes$: Observable<ExpenseType[]> = this.store$.pipe(
    select(selectAllExpenseTypes)
  );
  submitted$: Observable<Expense[]> = this.store$.pipe(
    select(selectCurrentSubmittedPageSorted)
  );
  trashed$: Observable<Expense[]> = this.store$.pipe(
    select(selectCurrentTrashPageSorted)
  );
  drafts$: Observable<Expense[]> = this.store$.pipe(
    select(selectCurrentUnsubmittedPageSorted)
  );
  pendingCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardSubmissionsPendingCount)
  );
  approvedCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardSubmissionsApprovedCount)
  );
  rejectedExpenseCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardSubmissionsRejectedCount)
  );
  unsubmittedDashboardTotalCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardUnsubmittedCount)
  );
  totalUnsubmittedCount$: Observable<any> = this.store$.pipe(
    select(selectUnsubmittedCount)
  );
  submittedDashboardTotalCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardSubmittedCount)
  );
  totalSubmittedCount$: Observable<any> = this.store$.pipe(
    select(selectSubmittedCount)
  );
  totalTrashCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardTrashCount)
  );
  filteredTrashCount$: Observable<any> = this.store$.pipe(
    select(selectTrashedExpenseCount)
  );
  submittedExpensesLoading$: Observable<any> = this.store$.pipe(
    select(submittedExpensesLoading)
  );

  constructor(private store$: Store<State>) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }
}
