<ems-base-modal [config]="modalInput$ | async" (dismiss)="onDismiss()">
  <ems-base-button-group modal-actions>
    <ems-base-button
      label="Confirm"
      buttonType="primary"
      variant="success"
      (click)="onSubmitItemized()"
    >
    </ems-base-button>
  </ems-base-button-group>
  <ems-expense-form-itemize-edit
    [items]="itemizedExpenses$ | async"
    [parentData]="parentData$ | async"
    [confirmStatus]="confirmStatus$ | async"
    [dismissing]="dismiss$ | async"
    (formChange)="onFormUpdate($event)"
    (statusChange)="onFormStatusChange($event)"
    (rotateReceipt)="onRotateReceipt($event)"
  ></ems-expense-form-itemize-edit>
</ems-base-modal>
