<div class="expense-modal-edit">
  <header class="base-modal__header">
    <div class="base-modal__header-content">
      <h1 class="base-modal__title">Edit</h1>
      <p class="base-modal__subtitle">
        Change expense details
      </p>
    </div>
    <div class="base-modal__actions">
      <ng-content select="[modal-actions]"></ng-content>
    </div>
  </header>

  <div class="expense-modal-edit__body" [formGroup]="form">
    <section class="expense-modal-edit__content">
      <div class="expense-edit-form">

        <!-- Custom Case -->
        <div class="ems-field add-salesforce">
          <div class="ems-field">
            <div class="ems-control">
              <ems-typeahead
                label="Custom Case"
                type="salesforceCase"
                [items]="salesforceCases$ | async"
                [favoritable]="true"
                [favorites]="favoriteSalesforceCases$ | async"
                formControlName="salesforceId"
                (favorite)="onFavoriteSalesforceCase($event)"
                [canClear]="true"
                [placeholder]="customCaseDefault"
              ></ems-typeahead>
            </div>
          </div>
        </div>

        <!-- Expense Type Autocomplete -->
        <div class="ems-field">
          <label class="ems-label">Expense Type</label>
          <div class="ems-select">
            <select formControlName="type">
              <option [ngValue]="null" disabled>Mixed</option>
              <option
                *ngFor="let type of expenseTypes$ | async"
                [value]="type?.id"
                [title]="type?.description"
              >
                {{ type?.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Billable -->
        <div class="ems-field billable">
          <label class="ems-label">Billable</label>
          <div class="ems-control billable">
            <label class="ems-radio">
              <input
                formControlName="isBillable"
                (change)="isMarkAsApprovedVisible()"
                type="radio"
                [value]="true"
              />
              <div class="ems-radio__indicator"></div>
              <span class="ems-radio__option">Yes</span>
            </label>
            <label class="ems-radio">
              <input
                formControlName="isBillable"
                (change)="isMarkAsApprovedVisible()"
                type="radio"
                [value]="false"
              />
              <div class="ems-radio__indicator"></div>
              <span class="ems-radio__option">No</span>
            </label>
          </div>
        </div>

        <!-- Job Code Autocomplete -->
        <div class="ems-field">
          <ems-typeahead
            label="Job Code"
            [items]="jobCodes$ | async"
            [favoritable]="true"
            [favorites]="favoriteJobCodes$ | async"
            (favorite)="onFavorite($event)"
            formControlName="jobCode"
            [isInvalid]="jobCode.invalid && submitted"
            placeholder="Mixed"
          ></ems-typeahead>
        </div>

        <!-- Message -->
        <div class="ems-field">
          <div class="ems-control">
            <textarea
              formControlName="message"
              class="ems-textarea"
              placeholder="Type here to add a note (optional)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="ems-control"
       [style.visibility]="displayApproveCheckbox ? 'visible' : 'hidden'"
      >
        <label class="ems-checkbox">
          <input
            type="checkbox"
            formControlName="approveExpense"
          />
          <div class="ems-checkbox__indicator"></div>
          Mark as Approved
        </label>
      </div>
      <!-- Cancel or Send -->
      <ems-base-button-group>
        <ems-base-button
          (click)="onCancelClick()"
          label="Cancel"
          buttonType="tertiary"
          variant="cancel"
        >
        </ems-base-button>
        <ems-base-button
          (click)="onSendClick()"
          label="Save"
          buttonType="primary"
          variant="success"
          [state]="isFormPristine() ? 'disabled' : ''"
        >
        </ems-base-button>
      </ems-base-button-group>
    </section>
  </div>
</div>
