import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ems-reject-form',
  templateUrl: './reject-form.component.html',
  styleUrls: ['./reject-form.component.scss'],
})
export class RejectFormComponent implements OnInit {
  @Output() reject: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  displayValidation = false;

  form: FormGroup = this.fb.group({
    message: new FormControl(null, {
      validators: [Validators.required],
    }),
  });

  constructor(private fb: FormBuilder) {
    this.message.valueChanges
      .pipe(tap(() => (this.displayValidation = false)))
      .subscribe();
  }

  get message() {
    return this.form.get('message');
  }

  ngOnInit() {
    return;
  }

  onCancelClick() {
    this.cancel.emit();
  }

  onRejectClick() {
    const message = this.form.get('message').value;
    if (message) {
      this.displayValidation = false;
      this.reject.emit(message);
    } else {
      this.displayValidation = true;
    }
  }
}
