import { Expense } from "@ems-gui/shared/util-core";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ExpenseMatchActions } from "../actions";

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
  expenses: Expense[];
  matchedExpenseId: number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  expenses: [],
  matchedExpenseId: null
});

export const reducer = createReducer(
  initialState,
  on(
    ExpenseMatchActions.requestUserCreatedCreditCardExpenses,
    state => ({
      ...state,
      loading: true
    })
  ),
  on(
    ExpenseMatchActions.requestUserCreatedCreditCardExpensesSuccess,
    (state, { expenses }) => ({
      ...state,
      expenses: expenses,
      loading: false
    })
  ),
  on(
    ExpenseMatchActions.requestUserCreatedCreditCardExpensesError,
    (state, { error }) => ({
      ...state,
      error: error,
      loading: false
    })
  ),
  on(
    ExpenseMatchActions.clearUserCreatedCreditCardExpenses,
    state => ({
      ...state,
      expenses: [],
      matchedExpenseId: null
    })
  ),
  on(
    ExpenseMatchActions.matchExpense,
    (state, { expenseId }) => ({
      ...state,
      matchedExpenseId: expenseId
    })
  )
);
