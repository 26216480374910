import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CloseRequest } from '@ems-gui/expense/ui-web-components';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ActivityActions,
  FavoriteJobCodeActions,
  FavoriteSalesforceCaseActions,
  LayoutActions,
  MileageDraftActions,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectJobCodesForDraftDropdown,
  selectAllSalesforceCasesWithGroupLabels,
  selectDraftAutoSaving,
  selectedMileageDraftForWebOnly,
  selectedProxyId,
  selectMileageDraftReimbursement,
  selectMileageExpenseType,
  selectModalDismiss,
  State,
  selectDraftIsSaveSuccessful,
  selectFromCustomCaseApi,
  SalesforceCaseActions
} from '@ems-gui/expense/util-web-ngrx';
import {
  ConvertToDollarsPipe,
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  JobCode,
  ModalInput,
  SalesforceCase,
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil, tap, share } from 'rxjs/operators';
import { AlertService } from '@src/app/services/alert.service';
import { FormManagerService } from '@src/app/services/form-manager.service';

 const MODAL_ID = 'mileage-expense-modal-edit';

@Component({
  selector: 'ems-mileage-expense-edit-modal',
  templateUrl: './mileage-expense-edit-modal.component.html',
  styleUrls: ['./mileage-expense-edit-modal.component.scss'],
  providers: [ConvertToDollarsPipe, CurrencyPipe],
})
export class MileageExpenseEditModalComponent implements OnInit, OnDestroy {
  public formId = MODAL_ID;
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Mileage Expense:',
    label: '',
    labelClass: '',
    caption: '',
    status: '',
    subtitle: '',
    receiptNotRequiredText: 'Receipt not required for mileage expense.',
    fieldsComplete: false,
    modalId: MODAL_ID,
  });
  modalInput$ = this.modalInputSubject.asObservable();
  form;
  private isExistingMileageValidSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  isExistingMileageValid$ = this.isExistingMileageValidSubject.asObservable();
  currentDate = new Date();
  lastUpdatedDate;
  lastUpdatedTime;
  mileageExpense$: Observable<Partial<Expense>>;
  mileageExpenseType$: Observable<ExpenseType>;
  dismiss$: Observable<boolean>;
  jobCodes$: Observable<JobCode[]>;
  favoriteJobCodes$: Observable<any>;
  salesforceCases: SalesforceCase[];
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;
  proxy$: Observable<any>;
  unsubscribe$: Subject<void> = new Subject();
  draftMileageReimbursement$: Observable<number>;
  draftMileageExpenseAutoSaving$: Observable<boolean>;
  formSubject$: BehaviorSubject<Partial<Expense>> = new BehaviorSubject({});
  form$: Observable<Partial<Expense>> = this.formSubject$
    .asObservable()
    .pipe(share());
  submittedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  submitted$: Observable<boolean> = this.submittedSubject$.asObservable();
  status = '';
  currentExpense: Partial<Expense>;
  isSaveSuccessful$;
  changeCount = 0;
  private isFormDirty = false;
  private originalForm: string;

  constructor(
    private convertToDollarsPipe: ConvertToDollarsPipe,
    private currencyPipe: CurrencyPipe,
    public modalService: BaseModalService,
    private store$: Store<State>,
    private toastrService: ToastrService,
    private alertService: AlertService,
    private readonly fm: FormManagerService
  ) {
    this.mileageExpense$ = this.store$.pipe(
      select(selectedMileageDraftForWebOnly)
    );
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
    this.jobCodes$ = this.store$.pipe(select(selectJobCodesForDraftDropdown));
    this.isSaveSuccessful$ = this.store$.pipe(select(selectDraftIsSaveSuccessful));
    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );
    this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels),
      takeUntil(this.unsubscribe$)
      ).subscribe(salesforceCases => {
        this.salesforceCases = salesforceCases;
      });
    
  
    // IF the expense has an outdated customCase get the value and add to the list
    this.store$.select(selectFromCustomCaseApi).pipe(
      takeUntil(this.unsubscribe$),
      tap((outDatedSalesforceCase) => {
        if(outDatedSalesforceCase && this.salesforceCases) {
          const updateSalesforceCase = {
            ...outDatedSalesforceCase,
            label: outDatedSalesforceCase.case_number + ' ' + outDatedSalesforceCase.name,
            case_number:  outDatedSalesforceCase?.case_number?.substr(6),
            groupName: 'All Cases'
          }
          this.salesforceCases = [...this.salesforceCases, updateSalesforceCase];
        }
      })
    ).subscribe();
    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.draftMileageReimbursement$ = this.store$.pipe(
      select(selectMileageDraftReimbursement)
    );
    this.draftMileageExpenseAutoSaving$ = this.store$.pipe(
      select(selectDraftAutoSaving)
    );
    this.proxy$ = this.store$.pipe(select(selectedProxyId));
    this.mileageExpenseType$ = this.store$.pipe(
      select(selectMileageExpenseType)
    );
  }

  ngOnInit() {
    this.mileageExpense$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((expense) => {
          this.status = expense.status;
          if (expense?.updatedAt) {
            this.lastUpdatedDate = expense.updatedAt;
            this.lastUpdatedTime = formatDate(
              expense.updatedAt,
              'shortTime',
              'en-US'
            );
          }
          if (expense) {
            const currentModalInput = this.modalInputSubject.getValue();
            const salesforceCase =
              expense &&
              expense.salesforceId &&
              this.salesforceCases.some(caseObj => caseObj.id === expense.salesforceId);

            if(salesforceCase === undefined && expense.salesforceId) {
              const action = SalesforceCaseActions.getCustomCaseByExternalId({
                externalId: expense.salesforceId
              });
              this.store$.dispatch(action);
            }
            this.modalInputSubject.next({
              ...currentModalInput,
              subtitle: `ID ${expense.id}`,
              label: this.setModalInputLabel(expense.status),
              labelClass: this.setModalInputLabelClass(expense.status),
            });
          }
        })
      )
      .subscribe();

    this.draftMileageExpenseAutoSaving$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((status) => {
          const currentModalInput = this.modalInputSubject.getValue();
          this.modalInputSubject.next({
            ...currentModalInput,
            caption: this.getDraftStatus(status),
          });
        })
      )
      .subscribe();

    const unsubscribeAfterRegisteringForm = new Subject<void>();
    this.fm.getFormManager$(this.formId).pipe(
      takeUntil(unsubscribeAfterRegisteringForm),
      tap((fm) => {
        if(!fm) {
          return;
        }
        unsubscribeAfterRegisteringForm.next();
        unsubscribeAfterRegisteringForm.complete();
        fm.callback('saveAndClose', () => this.saveAndClose());
      })
    ).subscribe()
  }

  onMileageFormChange(value) {
    this.form = {
      ...value,
    };
    this.formSubject$.next(value);
    this.currentExpense = this.form;
    this.changeCount++;
    this.manageChanges(this.currentExpense);
  }

  private manageChanges(manageChanges: Partial<Expense>) {
    const changes = { ...manageChanges };
    delete changes.updatedAt;
    const serializedForm = JSON.stringify(changes);
    if(!this.originalForm) {
      this.originalForm = serializedForm;
      return;
    }
    this.isFormDirty = serializedForm !== this.originalForm;
  }

  onMileageFormStatusChange(value) {
    this.isExistingMileageValidSubject.next(value);
    const currentModalInput = this.modalInputSubject.getValue();
    this.modalInputSubject.next({
      ...currentModalInput,
      fieldsComplete: value,
      // statusText: this.setStatusText(value),
    });
  }

  // getDraftStatus returns a string that will be used in the expense form's caption. When the draft/rejected
  // expense is first opened, either a string with the last time the expense was updated will be displayed if
  // the expense's last updated date is the same as the current date or a string with the full date of the last
  // expense update will be displayed
  getDraftStatus(draftStatus) {
    const expenseDate = new Date(this.lastUpdatedDate);
    const fullExpenseDate = formatDate(
      this.lastUpdatedDate,
      'shortDate',
      'en-US'
    );
    const today = `${this.currentDate.getMonth()}/${this.currentDate.getDate()}/${this.currentDate.getFullYear()}`;
    const shortExpenseDate = `${expenseDate.getMonth()}/${expenseDate.getDate()}/${expenseDate.getFullYear()}`;

    /**
     * REFACTOR NEEDED:
     * WHY: extract!
     */
    if (draftStatus === true) {
      return 'Saving...';
    } else if (draftStatus === false) {
      return 'All changes saved';
    } else if (shortExpenseDate === today) {
      return `Last updated today at ${this.lastUpdatedTime}`;
    } else {
      return `Last updated on ${fullExpenseDate}`;
    }
  }

  onDismiss(closeRequest: CloseRequest) {
      closeRequest.isClosing = !this.isFormDirty;
      if(closeRequest.isClosing) {
        this.store$.dispatch(LayoutActions.dismissModal());
        this.formSubject$
          .pipe(
            take(1),
            tap((form: any) => {
              const updatedForm = {
                ...form,
                jobCode: form.jobCode ? +form.jobCode : null,
              };
              this.store$.dispatch(
                MileageDraftActions.applyExpenseToState({ expense: updatedForm })
              );
            })
          )
          .subscribe();
      } else {
        this.alertService.getUnsavedChangesAlert(
          'mileage-expense-modal-edit',
          () => this.saveAndClose()
        );
      }
  }

  onGetMileageReimbursement(mileage) {
    this.store$.dispatch(
      MileageDraftActions.getEstimatedReimbursement({ mileage })
    );
  }

  onFavoriteJobCode(id) {
    this.store$.dispatch(FavoriteJobCodeActions.saveOneFavorite({ id }));
  }

  onFavoriteSalesforceCase(id) {
    this.store$.dispatch(
      FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase({ id })
    );
  }

  onDeleteNote(id) {
    this.store$.dispatch(ActivityActions.deleteDraftNote({ id }));
  }

  onDraftMileageSubmit() {
    this.submittedSubject$.next(true);
    if (this.form && this.isExistingMileageValidSubject.getValue()) {
      const updatedForm = {
        ...this.form,
        status: this.status,
      };
      this.store$.dispatch(
        MileageDraftActions.submit({ expense: updatedForm })
      );
      this.modalService.close('mileage-expense-modal-edit');
    }
  }

  onDeleteMileageDraft() {
    const drafts = [];
    if (this.form && this.form.id) {
      drafts.push(this.form.id);
    }
    if (drafts.length) {
      this.store$.dispatch(
        MileageDraftActions.trashDrafts({ expenses: drafts })
      );
    }
  }

  onRotateReceipt({ id, angle }) {
    this.store$.dispatch(MileageDraftActions.rotateReceipt({ id, angle }));
  }

  onRemoveReceipt(id) {
    this.store$.dispatch(MileageDraftActions.removeReceipt({ id }));
  }

  onReceiptData(id) {
    this.store$.dispatch(MileageDraftActions.uploadComplete({ id }));
  }

  onFileUploadSizeError() {
    this.toastrService.show('Receipt file size cannot exceed 10MB.', '', {
      timeOut: 10000,
    });
  }

  ngOnDestroy() {
    this.toastrService.clear();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.fm.deregister(this.formId);
  }

  setModalInputLabel(formStatus) {
    if (formStatus === 'awaiting verification') {
      return '[verifying]';
    }
    return `[${formStatus}]`;
  }

  setModalInputLabelClass(formStatus) {
    switch (formStatus) {
      case 'rejected':
        return 'danger';

      case 'awaiting verification':
        return 'warn';

      default:
        return '';
    }
  }

  saveAndClose() {
    // Save
    if (this.changeCount > 0) {
      this.store$.dispatch(MileageDraftActions.save({expense: this.currentExpense}));

      // Close
      this.isSaveSuccessful$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((isSuccessful) => {
          if (isSuccessful) {
            this.changeCount = 0;
            this.modalService.close('mileage-expense-modal-edit');
          }
        } )
      ).subscribe();
    }
  }

      /**
   * REFACTOR NEEDED:
   * WHY: formatDate can be called once or not withinn string interpolation
   */
    getTimestamp(date: number) {
      const beginningTimeToday = new Date(
        formatDate(new Date(), 'mediumDate', 'en-US')
      ).getTime();
      const endingTimeToday = beginningTimeToday + 86399999;
      const beginningTimeYesterday = beginningTimeToday - 86400000;
      const endingTimeYesterday = beginningTimeToday - 1;

      if (date >= beginningTimeToday && date <= endingTimeToday) {
        return `Today at ${formatDate(date, 'shortTime', 'en-US')}`;
      } else if (date >= beginningTimeYesterday && date <= endingTimeYesterday) {
        return `Yesterday at ${formatDate(date, 'shortTime', 'en-US')}`;
      } else {
        return `${formatDate(date, 'MM/dd/yyyy', 'en-US')} ${formatDate(
          date,
          'shortTime',
          'en-US'
        )}`;
      }
    }
    getFormattedNote(item) {

      return this.mileageExpense$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((expense) => {
          if (item.action === 'Reimbursed Expense') {
            return `${this.currencyPipe.transform(
              this.convertToDollarsPipe.transform(expense.amount)
            )} has been reimbursed to ${item.author.author}.`;
          } else if (item.action !== 'Reimbursed Expense' && item.message) {
            return item.message;
          } else {
            return '';
          }
        })
      )
      .subscribe();


    }
}
