import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  ActivityActions,
  LayoutActions,
  SubmittedActions
} from "../../actions";
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import { selectJobCodeEntities } from '../../selectors/job-code.selectors';
import {
  getSubmittedActivity
} from "@libs/expense/util-web-ngrx/src/lib/+state/actions/expense/activity.actions";

@Injectable()
export class ActivityEffects {
  updateExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActivityActions.getSubmittedActivity,
        ActivityActions.getTrashedActivity
      ),
      switchMap(
        ({ id }) => this.apiService.getOneExpense(id).pipe(
          map(
            (expense) => SubmittedActions.getOneExpenseComplete(
              { expense }
            )
          )
        )
      ),
      catchError(errorHandler(ActivityActions.getExpenseActivityError))
    )
  );

  selectExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActivityActions.getSubmittedActivity,
        ActivityActions.getApprovalActivity,
        ActivityActions.getTrashedActivity
      ),
      withLatestFrom(this.store$.select(selectJobCodeEntities)),
      switchMap(([{ id }, store]) =>
        this.apiService.getActivity(id).pipe(
          map((response: any) => {
            const activity = response.map((a) => {
              const modifiedActivity = { ...a };
              modifiedActivity.jobCode = a.jobCode
                ? store[a.jobCode].code
                : null;
              return modifiedActivity;
            });
            return ActivityActions.getExpenseActivityComplete({ activity });
          }),
          catchError(errorHandler(ActivityActions.getExpenseActivityError))
        )
      )
    )
  );

  deleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.deleteDraftNote),
      withLatestFrom(this.store$.select(selectJobCodeEntities)),
      switchMap(([{ id }, store]) =>
        this.apiService.deleteNote(id).pipe(
          map((response) => {
            const { activity } = response;
            activity.jobCode = response.jobCode
              ? store[response.jobCode].code
              : null;
            return ActivityActions.deleteDraftNoteComplete({ activity });
          }),
          catchError(errorHandler(ActivityActions.deleteDraftNoteError))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<fromDashboard.State>
  ) {}
}
