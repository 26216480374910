<ems-base-modal
  id="trash-modal"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onTrashDialogCancel()"
>
  <!-- TODO: Remove this, we do not empty trash any more -->
  <ems-empty-trash-dialog
    (cancel)="onTrashDialogCancel()"
    (emptyTrash)="onEmptyTrash()"
  ></ems-empty-trash-dialog>
</ems-base-modal>
