import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  JobCode,
  SalesforceCase
} from "@ems-gui/shared/util-core";
import {
  selectAllExpenseTypesExceptFraudulent,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectAllSalesforceCasesWithGroupLabels,
  selectJobCodesForDraftDropdown,
  WebApproverFacade,
  State
} from "@ems-gui/expense/util-web-ngrx";
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from "rxjs";
import { ApprovalColumnEdit, ApprovalEditColumns } from '@libs/shared/util-core/src/lib/models/approval-column-edit.model';
import { takeUntil } from 'rxjs/operators';

const multiEditProperties = ['salesforceId', 'type', 'isBillable', 'jobCode'];

function getPropertiesWithSameValues<T>(arr: any[]) {
  const result = {};
  if (arr.length === 0) return result;

  const referenceObj = arr[0];

  for (const key in referenceObj) {
    if(multiEditProperties.includes(key)) {
      // Had to do this since we can not compare two nested objects
      // jobCode has couple of nested objects with in so compared id of the object instead
      if(
        (key === 'jobCode' || key === 'salesforceId') &&
        arr.every(obj => obj[key]?.id === referenceObj[key]?.id)
      ) {
        result[key] = referenceObj[key]?.id;
      } else if (arr.every(obj => obj[key] === referenceObj[key])) {
        result[key] = referenceObj[key];
      }
    }   
  }
  return result;
}

@Component({
  selector: 'ems-send-to-form',
  templateUrl: './send-to-form.component.html',
  styleUrls: ['./send-to-form.component.scss'],
})
export class SendToFormComponent implements OnInit {
  jobCodeErrorMessage = 'Job Code is required.';
  
  @Input() selectedExpenseIds;
  @Output() send: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() favorite: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteSalesforceCase: EventEmitter<any> = new EventEmitter<any>();
  submitted = false;
  displayApproveCheckbox = false;
  customCaseDefault = "";
  form: FormGroup = this.fb.group({
    approveExpense: [null],
    message: [null],
    jobCode: [null],
    type: [null],
    salesforceId: [null],
    isBillable: [null]
  });

  get approveExpense() {
    return this.form.get('approveExpense');
  }

  get isBillable() {
    return this.form.get('isBillable');
  }

  get salesforceId() {
    return this.form.get('salesforceId');
  }

  get type() {
    return this.form.get('type');
  }

  get message() {
    return this.form.get('message');
  }

  get jobCode() {
    return this.form.get('jobCode');
  }

  expense$: Expense;
  jobCodes$: Observable<JobCode[]>;
  favoriteJobCodes$: Observable<JobCode[]>;
  expenseTypes$: Observable<ExpenseType[]>;
  salesforceCases$: Observable<SalesforceCase[]>;
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;

  selectedExpenses = [];
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public approverState: WebApproverFacade,
    private fb: FormBuilder,
    private store$: Store<State>
  ) {}

  async ngOnInit() {
    this.jobCodes$ = this.store$.pipe(select(selectJobCodesForDraftDropdown));
    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );
    this.expenseTypes$ = this.store$.pipe(select(selectAllExpenseTypesExceptFraudulent));
    this.salesforceCases$ = this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels)
    );
    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.approverState.approvals$.pipe(
      takeUntil(this.unsubscribe$)
      ).subscribe(expenses => {
        
        this.selectedExpenses = expenses.filter(expense =>
          this.selectedExpenseIds.includes(expense.id)
        );
        const referenceObj: any = this.selectedExpenses[0];
        // Since custom case is not a required field so it can be undefined
        // when its undefined it should be empty
        // for multi edit even if one is defined and others are undefined then its mixed
        const isSalesforceMixed = 
          this.selectedExpenses.some(
            obj => obj.salesforceId?.id !== referenceObj.salesforceId?.id
          ); 
        this.customCaseDefault = isSalesforceMixed ? "Mixed" : "";
        const propertiesWthSameValue = getPropertiesWithSameValues(this.selectedExpenses);
        for (const key in propertiesWthSameValue) {
          if (key === 'type') {
            this.type.setValue(referenceObj.type);
          }
          if (key === 'isBillable') {
            this.isBillable.setValue(referenceObj.isBillable);
          }
          if (key === 'jobCode') {
            this.jobCode.setValue(referenceObj.jobCode.id);
          }
          if (key === 'salesforceId') {
            this.salesforceId.setValue(referenceObj.salesforceId?.id);
          }
        }
        this.isMarkAsApprovedVisible();
      });
  }

  onCancelClick() {
    this.cancel.emit();
  }

  async onSendClick() {
    this.submitted = true;
    const updates = this.formatDataToSend();
    if (this.form.valid) {
      this.send.emit({ updates});
    }
  }

  onFavorite(id: number) {
    this.favorite.emit(id);
  }

  onFavoriteSalesforceCase(id: number) {
    this.favoriteSalesforceCase.emit(id);
  }

  isMarkAsApprovedVisible() {
    this.displayApproveCheckbox = this.isBillable.value;
  }

  createUpdateColumn(
    column: 'jobCode' | 'isBillable' | 'salesforceId' | 'type',
    oldValue: string | number,
    newValue: string | number | null,
    note?: string
  ) {
    return <ApprovalColumnEdit>{
      column,
      oldValue,
      newValue,
      note
    }
  }

  mapIsBillable(value: boolean) {
    return value ? 'Yes' : 'No';
  }

  getIsBillable(expense: any) {
    if(this.isBillable.value === expense.isBillable) {
      return undefined;
    }

    return this.createUpdateColumn(
      'isBillable',
      this.mapIsBillable(expense.isBillable),
      this.mapIsBillable(this.isBillable.value)
    );
  }

  getType(expense: any) {
    if(
      this.type.value === null ||
      +this.type.value === +expense.type
    ) {
      return undefined;
    }

    return this.createUpdateColumn(
      'type',
      +expense.type,
      +this.type.value
    );
  }

  getJobCode(expense: any) {
    if(this.jobCode.value === null
      || this.jobCode.value === expense.jobCode.id
    ) {
      return undefined;
    }

    return this.createUpdateColumn(
      'jobCode',
      expense.jobCode.id,
      this.jobCode.value,
      !this.message.value ? undefined : this.message.value
    );
  }

  getSalesforceId(expense: any) {

    if (
      this.salesforceId.value === undefined ||
      this.salesforceId.value === null ||
      this.salesforceId.value === expense.salesforceId?.id
    ) {
      return undefined;
    }

    return this.createUpdateColumn(
      'salesforceId',
      expense.salesforceId?.id || "null",
      this.salesforceId.value
    );
  }

  getMarkedAsApproved() {
    if(!this.displayApproveCheckbox || !this.approveExpense.value) {
      return undefined;
    }

    return {
      markedAsApproved: true
    }
  }

  isFormPristine() {
    return !this.formatDataToSend().length;
  }

  formatDataToSend(): ApprovalEditColumns {
    const payload = [];
    this.selectedExpenses.forEach((expense) => {
      const changes = [
        this.getIsBillable(expense),
        this.getType(expense),
        this.getJobCode(expense),
        this.getSalesforceId(expense),
        this.getMarkedAsApproved()
      ].filter((columnUpdate) => {
        return columnUpdate !== undefined
      });

      if(changes.length > 0 ){
        payload.push({expenseId: expense.id, changes: changes});
      }
    })
    return <ApprovalEditColumns>payload;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

