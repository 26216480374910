<ems-expense-fab
  (openCCExpense)="onOpenModal('expense-new-modal-company-cc')"
  (openExpense)="onOpenModal('expense-new-modal')"
  (openMileageExpense)="onOpenModal('mileage-expense-modal-new')"
  (upload)="onUpload($event)"
  [closeMenu]="closeFabMenu"
>
</ems-expense-fab>

<div [hidden]="loading">
  <div class="expense-table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      [matSortActive]="defaultSortParams.colId"
      [matSortDirection]="defaultSortParams.sort"
      [matSortDisabled]="loading"
      [matSortDisableClear]="!loading"
      matSort
    >
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <div class="parent-check-box">
            <mat-checkbox
              (change)="masterToggle()"
              [checked]="allChecked$ | async"
              [indeterminate]="allIndeteriminate$ | async"
            >
            </mat-checkbox>
          </div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="onSingleChange(row)"
              [checked]="isRowSelected(row)"
            >
            </mat-checkbox>
          </div>
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="has-card-label"
          data-label="Date"
        >
          <div class="first-line expense-date">
            {{
              element.transactionDate
                ? (element.transactionDate | expenseDate)
                : '-'
            }}
          </div>
        </td>
      </ng-container>

      <!-- Vendor Column -->
      <ng-container matColumnDef="vendor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</th>
        <td mat-cell *matCellDef="let element">
          <div class="cell-content">
            <div class="first-line show-ellipsis">
              {{
                element.vendor === 'Mileage'
                  ? '(Mileage)'
                  : element.vendor
                  ? element.vendor
                  : '-'
              }}
            </div>
            <div class="second-line">{{ element.id }}</div>
          </div>
        </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let element">
          <div class="cell-content">
            <div
              class="first-line show-ellipsis has-card-label"
              data-label="Amount"
            >
              {{ element.amount | convertToDollars | currency }}
            </div>
            <div
              class="second-line payment-type has-card-label"
              data-label="Payment"
            >
              {{ element.paymentType | paymentPipe }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Receipt Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef>Receipt</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <ems-receipt-image [expense]="element"> </ems-receipt-image>
          </div>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Billable?</th>
        <td mat-cell *matCellDef="let element">
          <div class="cell-content">
            <div class="first-line has-card-label" data-label="Billable">
              {{ element.isBillable ? 'Yes' : 'No' }}
            </div>
            <div class="second-line has-card-label" data-label="Expense Type">
              <div *ngIf="!element.type">-</div>
              <div *ngIf="element.type" class="show-ellipsis">
                {{ element.type.name }}
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <div class="description">
            <div class="description-container">
              <div
                *ngIf="
                  element.description === null ||
                  element.description === '' ||
                  element.description === ' ' ||
                  element.description === '/t' ||
                  element.description === '/n'
                "
                class="description-context has-card-label"
                data-label="Description"
              >
                <span> - </span>
              </div>
              <div
                *ngIf="element.description !== ''"
                class="description-context has-card-label"
                data-label="Description"
              >
                <span> {{ element.description }} </span>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Job Code -->
      <ng-container matColumnDef="jobCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Code</th>
        <td mat-cell *matCellDef="let element">
          <div class="cell-content">
            <div class="first-line">
              {{ element?.jobCode?.code ? element?.jobCode?.code : '-' }}
            </div>
            <div class="second-line show-ellipsis">
              {{ element?.jobCode?.name ? element?.jobCode?.name : '-' }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Invalid -->
      <ng-container matColumnDef="invalid" *ngIf="showInvalid">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div>
            <ems-base-tooltip
              *ngIf="expenseReadyForSubmission(element)"
              direction="left"
              content="All required fields for this expense are valid and complete."
            >
              <ems-base-button
                label="Right"
                buttonType="tertiary"
                variant="brand"
                [showLabel]="false"
                size="m"
              >
                <ems-base-icon
                  icon-prepend
                  iconName="check-circle"
                ></ems-base-icon>
              </ems-base-button>
            </ems-base-tooltip>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="invalid" *ngIf="!showInvalid">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <!-- Custom Case -->
      <ng-container matColumnDef="customCase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Custom Case</th>
        <td mat-cell *matCellDef="let element">
          <div 
            *ngIf="element.salesforceId && element.salesforceId.id && element.salesforceId?.case_number === undefined"
          >
            *
          </div>
          <div class="cell-content"
            *ngIf="!(element.salesforceId && element.salesforceId.id && element.salesforceId?.case_number === undefined)"
          >
            <div class="first-line">
              {{
                element.salesforceId?.case_number
                  ? element.salesforceId?.case_number
                  : '-'
              }}
            </div>
            <div class="second-line show-ellipsis">
              {{
                element.salesforceId?.name ? element.salesforceId?.name : '-'
              }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div class="status-flex">
              <div>
                <div *ngIf="element.fraudulent">
                  <ems-base-caption size="1">
                    <ems-base-text-style variation="negative">
                      FRAUDULENT
                    </ems-base-text-style>
                  </ems-base-caption>
                </div>
                <div *ngIf="!element.fraudulent">
                  <ems-base-caption *ngIf="element.status" class="status-text">
                    <status-display [expense]="element"></status-display>
                  </ems-base-caption>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onViewDetails(row)"
        [class.is-row-selected]="isRowSelected(row)"
      ></tr>
    </table>
  </div>

  <div class="list-view-cards">
    <div
      class="list-view-card"
      *ngFor="let expense of expenses"
      (click)="onViewDetails(expense)"
    >
      <div class="list-view-card__header">
        <mat-checkbox
          class="list-view-card__checkbox"
          (click)="$event.stopPropagation()"
          (change)="onSingleChange(expense)"
          [checked]="isRowSelected(expense)"
        ></mat-checkbox>
        <span class="list-view-card__title">{{
          expense.vendor || 'Unspecified Vendor'
        }}</span>
        <span class="list-view-card__subtitle"
          >Expense ID {{ expense.id || '-' }}</span
        >
        <ems-receipt-image
          class="list-view-card__receipt-image"
          [expense]="expense"
        >
        </ems-receipt-image>
      </div>

      <div class="list-view-card__amount-date">
        <span class="list-view-card__amount">
          <span class="text">Amount:</span>
          {{ expense.amount | convertToDollars | currency }}
        </span>
        <span class="list-view-card__date">
          <span class="text"> Date:</span>
          {{ expense.transactionDate | expenseDate }}
        </span>
      </div>

      <div class="list-view-card__details">
        <div class="list-view-card__detail">
          <span class="title">Employee:</span>
          <span
            class="detail"
            *ngIf="expense.submitter?.firstName && expense.submitter?.lastName"
            >{{ expense.submitter?.firstName }}
            {{ expense.submitter.lastName }}</span
          >
          <span
            class="detail"
            *ngIf="
              !expense.submitter?.firstName && !expense.submitter?.lastName
            "
            >-</span
          >
        </div>
        <div class="list-view-card__detail">
          <span class="title">Payment:</span>
          <span class="detail">{{
            (expense.paymentType | paymentPipe) || '-'
          }}</span>
        </div>
        <div class="list-view-card__detail">
          <span class="title">Billable?:</span>
          <span class="detail">{{
            expense.isBillable
              ? 'Yes'
              : expense.isBillable === null
              ? '-'
              : 'No'
          }}</span>
        </div>
        <div class="list-view-card__detail">
          <span class="title">Expense Type:</span>
          <span class="detail expense-type">{{ expense.type?.name || '-' }}</span>
        </div>
        <div class="list-view-card__divider"></div>
        <div class="list-view-card__detail">
          <span class="title">Description:</span>
          <span class="detail lighter description">{{
            expense.description
          }}</span>
        </div>
      </div>

      <div class="list-view-card__code-case">
        <div class="list-view-card__detail">
          <span class="title">Job Code:</span>
          <span class="detail">{{ expense.jobCode?.code || '-' }}</span>
          <span class="extra">{{ expense.jobCode?.name || '-' }}</span>
        </div>
        <div class="list-view-card__detail">
          <span class="title">Custom Case:</span>
          <span class="detail">{{
            expense.salesforceId?.case_number || '-'
          }}</span>
          <span class="extra">{{ expense.salesforceId?.name || '-' }}</span>
        </div>
      </div>

      <div class="list-view-card__divider"></div>

      <div class="list-view-card__footer">
        <div class="list-view-card__detail footer">
          <span class="title">Status:</span>
          <span class="detail" [attr.data-status]="statusColor[expense.status]">
            {{ expense.status | uppercase }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="no-expenses" *ngIf="expenses.length === 0">
    <p class="no-expenses-text">{{ emptyListText }}</p>
  </div>
  <ems-expense-paginator
    [count]="totalCount"
    [pageSize]="pageSize"
    [currentPageCount]="dataSource.data.length"
    [pageSizeOptions]="pageSizeOptions"
    (pageUpdate)="onPageUpdate($event)"
  ></ems-expense-paginator>
</div>

<div [hidden]="!loading">
  <div class="spinner-container">
    <ems-base-icon class="spinner" iconName="loading"></ems-base-icon>
  </div>
</div>
