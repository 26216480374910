<ng-template #content><ng-content></ng-content></ng-template>

<ng-container [ngSwitch]="tag">
  <ng-container *ngSwitchCase="'h2'"
    ><h2 [ngClass]="classNames()" class="base-title">
      <ng-container *ngTemplateOutlet="content"></ng-container></h2
  ></ng-container>
  <ng-container *ngSwitchCase="'h3'"
    ><h3 [ngClass]="classNames()" class="base-title">
      <ng-container *ngTemplateOutlet="content"></ng-container></h3
  ></ng-container>
  <ng-container *ngSwitchCase="'h4'"
    ><h4 [ngClass]="classNames()" class="base-title">
      <ng-container *ngTemplateOutlet="content"></ng-container></h4
  ></ng-container>
  <ng-container *ngSwitchCase="'h5'"
    ><h5 [ngClass]="classNames()" class="base-title">
      <ng-container *ngTemplateOutlet="content"></ng-container></h5
  ></ng-container>
  <ng-container *ngSwitchCase="'h6'"
    ><h6 [ngClass]="classNames()" class="base-title">
      <ng-container *ngTemplateOutlet="content"></ng-container></h6
  ></ng-container>
  <ng-container *ngSwitchDefault
    ><p [ngClass]="classNames()" class="base-title">
      <ng-container *ngTemplateOutlet="content"></ng-container></p
  ></ng-container>
</ng-container>
