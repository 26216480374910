import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseModalService } from "@ems-gui/expense/util-web-infrastructure";
import { Subject, takeUntil } from "rxjs";
import { ModalInput } from "@ems-gui/shared/util-core";
import { State, selectOpenModalId, SubmittedActions, LayoutActions } from "@ems-gui/expense/util-web-ngrx";
import { select, Store } from '@ngrx/store';

const THIS_MODAL_ID = 'recall-expense-alert';

@Component({
  selector: 'sis-recall-expense-alert',
  templateUrl: './recall-expense-alert.component.html',
  styleUrls: ['./recall-expense-alert.component.scss']
})
export class RecallExpenseAlertComponent
  implements OnInit, OnDestroy
{
  private unsubscribe$: Subject<void> = new Subject();
  public constructor(
    protected modalService: BaseModalService,
    private store: Store<State>
  ) {}
  modalConfig: ModalInput = {
    title: '',
    modalId: 'recall-expense-alert',
  };
  expenseId: number;

  ngOnInit() {
    this.store.pipe(
      select(selectOpenModalId),
      takeUntil(this.unsubscribe$)
    ).subscribe((modalId: number) => {
      this.expenseId = modalId;
    });
  }

  cancel() {
    this.store.dispatch(LayoutActions.openExpenseModal({
      id: this.expenseId,
      name: 'expense-modal'
     }));
  }

  recallExpense() {
    this.store.dispatch(
      SubmittedActions.recallExpense({ expenseId: this.expenseId })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
