import { Component, OnInit } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { ActivatedRoute } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { errorHandler } from '@libs/expense/util-web-ngrx/src/lib/+state/error-handler';
import { DraftActions } from '@ems-gui/expense/util-web-ngrx';
import {
  Expense
} from '@ems-gui/shared/util-core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'ems-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.scss'],
  providers: [],
})
export class ViewReceiptComponent implements OnInit {
  id: number;
  imgUrl: string;
  isPdf = false;
  expense;
  
  constructor(
    private route: ActivatedRoute,
    public apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {}

  /** Get Expense id from the route params.
   * @returns Expense id used to query data for the Receipt view.
   */
  getApplicationId(): number {
    return this.route.snapshot.params.id;
  }
  ngOnInit() {
    this.id = this.getApplicationId();
    this.apiService.getOneExpense(this.id)
    .subscribe(
      {
        next: (expenseResponse: Partial<Expense>) => {
          this.expense = expenseResponse;
          this.updatePdfImageUrl(this.expense);
        },
        error: () => {
          catchError(errorHandler(DraftActions.getExpenseDataError));
        }
      }
    );
  }
  downloadReceipt() {
    const downloadLink = document.createElement('a');
    downloadLink.href = this.imgUrl;
    downloadLink.download = `${this.id}.pdf`;
    downloadLink.click();
  }
  // Iframe is used to display pdf aws signed url,
  // when doing so Iframe requires it to have these extra properties to display image 
  updatePdfImageUrl(expense){
    this.imgUrl = expense.image;
    if (
      expense.imageMetadata &&
      expense.imageMetadata.ContentType === 'application/pdf'
    ) {
      this.isPdf = true;
      this.expense.image = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.expense.image + '#toolbar=0&navpanes=0&view=fit'
      );
    }
  }
}
