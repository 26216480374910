import { ExpenseFilter } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getTrash = createAction(
  '[Expense - Trash] getTrash',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getTrashComplete = createAction(
  '[Expense - Trash] getTrashComplete',
  props<{ expenses: any }>()
);

export const getTrashError = createAction(
  '[Expense - Trash] getTrashError',
  props<{ error: any }>()
);

export const getUpdatedTrash = createAction(
  '[Expense - Trash] getUpdatedTrash',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getFilteredTrash = createAction(
  '[Expense - Trash] getFilteredTrash',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getFilteredTrashComplete = createAction(
  '[Expense - Trash] getFilteredTrashComplete',
  props<{ expenses: any }>()
);

export const getFilteredTrashError = createAction(
  '[Expense - Trash] getFilteredTrashError',
  props<{ error: any }>()
);

export const restoreExpenses = createAction(
  '[Expense - Trash] restoreExpenses',
  props<{ expenses: number[] }>()
);

export const restoreExpensesComplete = createAction(
  '[Expense - Trash] restoreExpensesComplete',
  props<{ expenses: any }>()
);

export const restoreExpensesError = createAction(
  '[Expense - Trash] restoreExpensesError',
  props<{ error: any }>()
);

export const deleteExpenses = createAction(
  '[Expense - Trash] deleteExpenses',
  props<{ expenses: number[] }>()
);

export const deleteExpensesComplete = createAction(
  '[Expense - Trash] deleteExpensesComplete',
  props<{ expenses: any }>()
);

export const deleteExpensesError = createAction(
  '[Expense - Trash] deleteExpensesError',
  props<{ error: any }>()
);

export const selectTrashedExpenses = createAction(
  '[Expense - Trash] selectTrashedExpenses',
  props<{ expenses: number[] }>()
);

export const emptyTrash = createAction('[Expense - Trash] emptyTrash');

export const emptyTrashComplete = createAction(
  '[Expense - Trash] emptyTrashComplete',
  props<{ expenses: any }>()
);

export const emptyTrashError = createAction(
  '[Expense - Trash] emptyTrashError',
  props<{ error: any }>()
);

export const selectExpense = createAction(
  '[Expense - Trash] selectExpense',
  props<{ id: number }>()
);

export const selectMileageExpense = createAction(
  '[Expense - Trash] selectMileageExpense',
  props<{ id: number }>()
);
