import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ems-undo-itemization-dialog',
  templateUrl: './undo-itemization-dialog.component.html',
  styleUrls: ['./undo-itemization-dialog.component.scss'],
})
export class UndoItemizationDialogComponent implements OnInit {
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    return;
  }

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }
}
