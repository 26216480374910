import { ExpenseFilter } from '../models';

export const UNSUBMITTED_STATUSES = [
  { display: 'Draft', value: 'draft' },
  { display: 'Rejected', value: 'rejected' }
];

export const SUBMITTED_STATUSES = [
  { display: 'Pending', value: 'pending' },
  { display: 'Approved', value: 'approved' },
];

export const AWAITING_STATUSES = [
  { display: 'New', value: 'pending' },
  { display: 'Overdue', value: 'overdue' },
  { display: 'Fraudulent', value: 'fraudulent' },
];

export const REVIEWED_STATUSES = [
  { display: 'Approved', value: 'approved' },
  { display: 'Rejected', value: 'rejected' },
  { display: 'Pending', value: 'pending' }
];

export const SORT_ORDER = { colId: 'transactionDate', sort: 'DESC' };
export const ADMIN_FEED_SORT_ORDER = { colId: 'createdAt', sort: 'DESC' };

export const PAGE_SIZE_OPTIONS = [10, 25, 100];
export const PAGE_SIZE = 10;

export const ADMIN_FEED_FILTERS = {
  fileName: '',
  startDate: '',
  endDate: '',
  limit: PAGE_SIZE,
};

export const EXPENSE_FILTERS: ExpenseFilter = {
  startDate: '',
  endDate: '',
  minAmount: null,
  maxAmount: null,
  isBillable: '',
  vendor: '',
  type: '',
  paymentType: '',
  status: '',
  /**
   * isSingle is never applied, is a legacy of when there was a concept
   * of Reports in the application.
   */
  isSingle: '',
  jobCode: '',
  limit: PAGE_SIZE,
  isOverdue: '',
  employee: '',
};

export const PAYMENT_OPTIONS = [
  { id: '0', display: 'Company CC', value: 'company' },
  { id: '1', display: 'Out of Pocket', value: 'personal' },
  { id: '2', display: 'Mileage', value: 'mileage' },
  { id: '3', display: 'Match Credit Card', value: 'user' }
];

export const ExpenseSortOptions = [
  {
    name: 'Date: New to Old',
    sortParam: 'transactionDate',
    sortOrder: 'DESC',
  },
  {
    name: 'Date: Old to New',
    sortParam: 'transactionDate',
    sortOrder: 'ASC',
  },
  {
    name: 'Vendor: A to Z',
    sortParam: 'vendor',
    sortOrder: 'ASC',
  },
  {
    name: 'Vendor: Z to A',
    sortParam: 'vendor',
    sortOrder: 'DESC',
  },
  {
    name: 'Amount: Low to High',
    sortParam: 'amount',
    sortOrder: 'ASC',
  },
  {
    name: 'Amount: High to Low',
    sortParam: 'amount',
    sortOrder: 'DESC',
  },
  {
    name: 'Billable: Yes to No',
    sortParam: 'isBillable',
    sortOrder: 'DESC',
  },
  {
    name: 'Billable: No to Yes',
    sortParam: 'isBillable',
    sortOrder: 'ASC',
  },
  {
    name: 'Job Code: Low to High',
    sortParam: 'jobCode',
    sortOrder: 'ASC',
  },
  {
    name: 'Job Code: High to Low',
    sortParam: 'jobCode',
    sortOrder: 'DESC',
  },
  {
    name: 'Status: A to Z',
    sortParam: 'status',
    sortOrder: 'ASC',
  },
  {
    name: 'Status: Z to A',
    sortParam: 'status',
    sortOrder: 'DESC',
  },
];

export const ApprovalsSortOptions = [
  {
    name: 'Date: New to Old',
    sortParam: 'transactionDate',
    sortOrder: 'DESC',
  },
  {
    name: 'Date: Old to New',
    sortParam: 'transactionDate',
    sortOrder: 'ASC',
  },
  {
    name: 'Employee: A to Z',
    sortParam: 'lastName',
    sortOrder: 'ASC',
  },
  {
    name: 'Employee: Z to A',
    sortParam: 'lastName',
    sortOrder: 'DESC',
  },
  {
    name: 'Vendor: A to Z',
    sortParam: 'vendor',
    sortOrder: 'ASC',
  },
  {
    name: 'Vendor: Z to A',
    sortParam: 'vendor',
    sortOrder: 'DESC',
  },
  {
    name: 'Amount: Low to High',
    sortParam: 'amount',
    sortOrder: 'ASC',
  },
  {
    name: 'Amount: High to Low',
    sortParam: 'amount',
    sortOrder: 'DESC',
  },
  {
    name: 'Billable: Yes to No',
    sortParam: 'isBillable',
    sortOrder: 'DESC',
  },
  {
    name: 'Billable: No to Yes',
    sortParam: 'isBillable',
    sortOrder: 'ASC',
  },
  {
    name: 'Job Code: Low to High',
    sortParam: 'jobCode',
    sortOrder: 'ASC',
  },
  {
    name: 'Job Code: High to Low',
    sortParam: 'jobCode',
    sortOrder: 'DESC',
  },
  {
    name: 'Status: New to Old',
    sortParam: 'status',
    sortOrder: 'ASC',
  },
  {
    name: 'Status: Old to New',
    sortParam: 'status',
    sortOrder: 'DESC',
  },
];

export const EXPENSES_SORT_ORDER = {
  name: 'Date: New to Old',
  sortParam: 'transactionDate',
  sortOrder: 'DESC',
};

export const MAX_CHARACTER_LENGTH = 255;

export const ADMIN_USERS_FILTERS = {
  role: '',
  hasProxy: null,
  isProxy: null,
  contacts: null,
};

export const KEY_STROKE_SETTINGS = {
  debounceTime: 300,
  minSearchCharacter: 3
}

export const ADMIN_USERS_SORT = {
  colId: 'lastName',
  sort: 'ASC',
};

/**
 * Constant values for spense roles
 */
export enum PERMISSIONS {
  proxyAll = 'proxyAll',
  receiptMgr = 'accessSpenseReceiptMgr',
  admin = 'spenseAdmin',
  loginAs = 'accessSpenseLoginAs',
  users = 'accessSpenseUsers',
  proxySelf = 'proxySelf',
  readClients = 'readClients',
  settings = 'accessSpenseSettings',
  tdr = 'assignTDR'
}

export enum EmployeeRoles {
  SPENSE_ADMIN = 'spenseAdmin',
  MASTER_APPROVER = 'master_approver',
  APPROVER = 'approver',
  SUBMITTER = 'submitter'
}
