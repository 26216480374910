<div class="mileage-expense-form-new" [formGroup]="form" content>
  <!-- Transaction Date -->
  <sis-form-control
    label="Transaction Date"
    inputId="new-expense-transaction-date"
  >
    <ems-datepicker
      inputId="new-expense-transaction-date"
      formControlName="transactionDate"
      [maxDate]="maxDate"
    ></ems-datepicker>
  </sis-form-control>

  <!-- Odometer at Start of Trip -->
  <div class="field-container odometer-start">
    <sis-form-control
      label="Odometer at Start of Trip"
      inputId="filter-start-mileage"
      [class.input-error]="startMileageIsInvalid || startMileageIsRequired"
    >
      <input
        id="filter-start-mileage"
        formControlName="startMileage"
        type="text"
        placeholder="Example: 50000"
        [class.input-error]="startMileageIsInvalid || startMileageIsRequired"
      />
    </sis-form-control>
    <ems-base-helper-text
      variation="negative"
      [style.content-visibility]="
        startMileageIsRequired || startMileageIsInvalid
          ? 'visible'
          : 'hidden'
      "
    >
      {{
        startMileageIsRequired
          ? 'Odometer Reading is required.'
          : startMileageIsInvalid
          ? 'Please enter a valid odometer reading.'
          : 'No Error'
      }}
    </ems-base-helper-text>
  </div>

  <!-- Odometer at End of Trip -->
  <div class="field-container odometer-end">
    <sis-form-control
      label="Odometer at End of Trip"
      inputId="filter-end-mileage"
      [class.input-error]="endMileageIsRequired || endMileageIsInvalid || distanceIsInvalid"
    >
      <input
        id="filter-end-mileage"
        formControlName="endMileage"
        type="text"
        placeholder="Example: 50100"
        [class.input-error]="
          endMileageIsRequired || endMileageIsInvalid || distanceIsInvalid
        "
      />
    </sis-form-control>
    <ems-base-helper-text
      variation="negative"
      [style.content-visibility]="
        endMileageIsRequired || endMileageIsInvalid || distanceIsInvalid
          ? 'visible'
          : 'hidden'
      "
    >
      {{
        endMileageIsRequired
          ? 'Odometer Reading is required.'
          : endMileageIsInvalid
          ? 'Please enter a valid odometer reading.'
          : distanceIsInvalid
          ? 'End mileage cannot be less than start mileage.'
          : 'No Error'
      }}
    </ems-base-helper-text>
  </div>

  <!-- Distance (Disabled) -->
  <sis-form-control
    label="Distance"
    inputId="filter-distance"
    class="distance"
  >
    <input
      id="filter-distance"
      type="text"
      placeholder="Enter odometer readings above"
      disabled
      #distance
    />
  </sis-form-control>

  <!-- Estimated Reimbursement (Disabled) -->
  <sis-form-control
    label="Estimated Reimbursement"
    inputId="filter-estimated-reimbursement"
    class="reimbursement"
  >
    <input
      id="filter-estimated-reimbursement"
      type="text"
      placeholder="Calculated based on distance"
      value="{{ reimbursement | convertToDollars | currency }}"
      disabled
    />
  </sis-form-control>

  <!-- Description -->
  <div class="field-container description">
    <sis-form-control
      label="Description"
      inputId="filter-description"
      [class.input-error]="descriptionIsRequired"
    >
      <input
        id="filter-description"
        formControlName="description"
        type="text"
        placeholder="Example: Transporting secure shipment."
        value=""
        [maxlength]="maxLength"
      />
    </sis-form-control>
    <ems-base-helper-text
      variation="negative"
      [style.content-visibility]="descriptionIsRequired ? 'visible' : 'hidden'"
      >Description is required.
    </ems-base-helper-text>
  </div>

  <!-- Job Code -->
  <div class="field-container job-code">
    <ems-typeahead
      #jobCodeEl
      label="Job Code"
      type="jobCode"
      formControlName="jobCode"
      [items]="jobCodes"
      [favorites]="favorites"
      (click)="onJobCodeClick()"
      (favorite)="onFavoriteJobCode($event)"
      [isInvalid]="jobCodeIsRequired"
      [canClear]="true"
    ></ems-typeahead>
    <ems-base-helper-text
      variation="negative"
      [style.content-visibility]="jobCodeIsRequired ? 'visible' : 'hidden'"
      >Job Code is required.
    </ems-base-helper-text>
  </div>

  <!-- Custom Case -->
  <ems-typeahead
    #salesforceEl
    label="Custom Case"
    [optional]="true"
    type="salesforceCase"
    [items]="salesforceCases"
    [favorites]="favoriteSalesforceCases"
    formControlName="salesforceId"
    (favorite)="onFavoriteSalesforceCase($event)"
    [canClear]="true"
    class="custom-case"
  ></ems-typeahead>

  <!-- Billable -->
  <sis-form-control
    label="Billable"
    class="billable"
    *ngIf="showBillable || innerWidth > 959"
    [style.visibility]="showBillable ? 'visible' : 'hidden'"
  >
    <sis-two-radio-buttons
      [displayTexts]="['Yes', 'No']"
      [values]="[true, false]"
      formControlName="isBillable"
    ></sis-two-radio-buttons>
  </sis-form-control>

  <!-- Add Note -->
  <div class="note">
    <ems-base-inline *ngIf="!showNote">
      <ems-base-icon
        iconName="add-small"
        (click)="onToggleNote()"
        class="note-icon add"
      ></ems-base-icon>
      <span class="ems-label ems-label--expense-note">Add Note</span>
    </ems-base-inline>
    <ems-base-inline *ngIf="showNote">
      <ems-base-icon
        iconName="add-small"
        (click)="onToggleNote()"
        class="note-icon remove"
      ></ems-base-icon>
      <span class="ems-label ems-label--expense-note">Note</span>
    </ems-base-inline>
    <div class="ems-field" *ngIf="showNote">
      <div class="ems-control">
        <textarea
          formControlName="message"
          placeholder=""
          class="ems-textarea"
        >
        </textarea>
      </div>
    </div>
  </div>
</div>
