<div
  class="base-tabs"
  [class.detail-modal]="detailLayout"
  [class.hide-tabs]="hideTabs"
>
  <ul class="base-tabs__list">
    <li
      class="base-tab-item"
      *ngFor="let tab of tabs"
      (click)="selectTab(tab)"
      [class.is-active]="tab.active"
    >
      <span class="base-tab-item__link">{{ tab.title }}</span>
    </li>
  </ul>
</div>
<ng-content></ng-content>
