import { createSelector } from '@ngrx/store';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectAllModal = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.allModal
);

export const selectInitialCallMade = createSelector(
  selectAllModal,
  (state) => state.initialCallMade
);

export const selectContinueTo = createSelector(
  selectAllModal,
  (state) => state.continueTo
);

export const selectAllowedToUpload = createSelector(
  selectAllModal,
  (state) => state.fileUploadAllowed
);

export const selectAllModalActiveFab = createSelector(
  selectAllModal,
  (state) => state.fabIsActive
);

export const selectCurrentModal = createSelector(
  selectAllModal,
  (state) => state.currentModal
);
