import { Component, Input } from '@angular/core';

@Component({
  selector: 'ems-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss'],
})
export class UserPopupComponent {
  /**
   * Default popup type is name
   * Accepts 'avatar' to create a popup off of the user's avatar
   */
  @Input() type = 'name';

  /**
   * Users First Name
   */
  @Input() firstName = '';

  /**
   * Users Last Name
   */
  @Input() lastName = '';

  /**
   * Id assigned to the user
   */
  @Input() id = '';

  /**
   * Email assigned to the user
   */
  @Input() email = '';
  mailto = 'mailto:' + this.email;
  /**
   * Phone assigned to the user
   */
  @Input() phone = '';

  @Input() proxy;

  @Input() verticalLayout = false;

  isActive = false;

  /**
   * Function that shows the popup
   */
  onShowclick() {
    this.isActive = true;
  }

  /**
   * Function dismisses the popup
   */
  onDismissClick() {
    this.isActive = false;
  }
}
