import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalInput } from '@ems-gui/shared/util-core';

@Component({
  selector: 'ems-add-proxy-user-modal',
  templateUrl: './add-proxy-user-modal.component.html',
  styleUrls: ['./add-proxy-user-modal.component.scss'],
})
export class AddProxyUserModalComponent {
  @Input() subtitle;
  @Input() favoritePersons = [];
  modalConfig: ModalInput = {
    title: '',
    modalId: 'add-proxy-user-modal',
  };
  @Output() addProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() favorite: EventEmitter<any> = new EventEmitter<any>();

  onProxyConfirm(proxyUser) {
    this.addProxy.emit(proxyUser);
  }

  onProxyCancel() {
    this.cancelProxy.emit();
  }

  onPersonFavorite(id) {
    this.favorite.emit(id);
  }
}
