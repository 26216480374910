<ems-base-modal
  id="reject-modal"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onRejectFormCancel()"
>
  <ems-reject-form
    (cancel)="onRejectFormCancel()"
    (reject)="onReject($event)"
  ></ems-reject-form>
</ems-base-modal>
