import { Injectable } from '@angular/core';
import {
  ApiService,
  BaseModalService,
} from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { SnackbarService } from 'ngx-snackbar';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ApprovalActions,
  ExpenseTypeActions,
  JobCodeActions,
  LayoutActions,
  PersonActions,
  SalesforceCaseActions,
  SubmittedActions,
} from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import { selectFilters } from '../../selectors';

@Injectable()
export class LayoutEffects {
  openModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.openExpenseModal, LayoutActions.openFeedItemModal),
        tap(({ name }) => {
          this.modalService.open(name);
        })
      ),
    { dispatch: false }
  );

  dismissal$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(LayoutActions.dismissModal),
        delay(20),
        map(() => LayoutActions.resetDismissModal())
      )
  );

  newExpenseDismissal$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(LayoutActions.dismissNewExpenseModal),
        delay(500),
        withLatestFrom(this.store$.pipe(select(selectFilters))),
        switchMap(([, filters]) => [
          LayoutActions.resetDismissNewExpenseModal(),
          SubmittedActions.getUpdatedUnsubmitted({
            page: filters.unsubmitted.page,
            sort: filters.unsubmitted.sort,
            filters: filters.unsubmitted.filters,
          }),
        ])
      )
  );

  resetNewExpenseDismissal$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(LayoutActions.resetDismissNewExpenseModal),
        map(() => ApprovalActions.getExpenseCounts())
      )
  );

  openReceiptDataErrorModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.openReceiptDataErrorModal),
        tap(() => this.modalService.open('receipt-data-warn-modal'))
      ),
    { dispatch: false }
  );

  openReceiptSizeErrorModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.openReceiptSizeErrorModal),
        tap(() => {
          this.modalService.close('loading-modal');
          this.toastrService.show('Receipt file size cannot exceed 6MB.', '', {
            timeOut: 10000,
          });
        })
      ),
    { dispatch: false }
  );

  homeLoad$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(LayoutActions.homeLoad),
      withLatestFrom(this.storage.get('home')),
      switchMap(([, home]) => {
        const server = forkJoin([
          this.apiService.getAllPersons().pipe(map((users) => users?.users)),
          this.apiService.getJobCode(),
          this.apiService.getSalesforceCase(),
          this.apiService.getExpenseType(),
        ]);
        // const dataSource = home && Object.keys(home).length ? of(home) : server;
        const dataSource = server;

        return dataSource.pipe(
          mergeMap(([users, jobCode, salesforceCase, expenseType]: any) => [
            LayoutActions.saveHomeLoadData({
              homeData: [users, jobCode, salesforceCase, expenseType],
            }),
            PersonActions.getAllPersonComplete({ person: users }),
            JobCodeActions.getComplete({ jobCode }),
            SalesforceCaseActions.getComplete({ salesforceCase }),
            ExpenseTypeActions.getComplete({ expenseType }),
            LayoutActions.homeLoadComplete(),
            SubmittedActions.getAllExpenses(),
          ]),
          catchError(errorHandler(LayoutActions.homeLoadError))
        );
      })
    )
  });

  saveHomeLoadData$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(LayoutActions.saveHomeLoadData),
        switchMap(({ homeData }) => this.storage.set('home', homeData)),
        map(() => LayoutActions.saveHomeLoadDataComplete())
      )
  );

  /**
   * REFACTOR NEEDED:
   * WHY: Snackbar timeout is alway 10000, do we need it everywhere in the codebase?
   */

  toast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.globalToast),
        tap(({ triggerAction }) => {
          this.snackbarService.add({
            msg: triggerAction.type,
            timeout: 10000,
            action: {
              text: 'X',
              color: '#ffffff',
              onClick: ({ id }) => {
                this.snackbarService.remove(id);
              },
            },
            background: '#cc4f51',
            color: '#ffffff',
          });
        })
      ),
    { dispatch: false }
  );

  errorMessageToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.globalToastErrorMessage),
        tap(({ message }) => {
          this.snackbarService.add({
            msg: message,
            timeout: 10000,
            action: {
              text: 'X',
              color: '#ffffff',
              onClick: ({ id }) => {
                this.snackbarService.remove(id);
              },
            },
            background: '#cc4f51',
            color: '#ffffff',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private modalService: BaseModalService,
    private snackbarService: SnackbarService,
    private apiService: ApiService,
    private store$: Store<fromDashboard.State>,
    private toastrService: ToastrService,
    private storage: StorageMap
  ) {}
}
