import { Component } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { DraftActions, State } from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ems-undo-itemization-alert-modal',
  templateUrl: './undo-itemization-alert-modal.component.html',
  styleUrls: ['./undo-itemization-alert-modal.component.scss'],
})
export class UndoItemizationAlertModalComponent {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'undo-itemization-alert-modal',
  };

  constructor(
    private modalService: BaseModalService,
    private store$: Store<State>
  ) {}

  onUndoItemizationDialogCancel() {
    this.modalService.close('undo-itemization-alert-modal');
    this.modalService.open('expense-form');
  }

  onConfirm() {
    this.store$.dispatch(DraftActions.undoItemization());
  }
}
