<div class="receipt-manager-modal">

  <header class="base-modal__header" >

    <div class="base-modal__pre-header">
      <p class="base-modal__state-title">Warning</p>
    </div>

    <div class="base-modal__header-content">
      <span class="base-modal__title-icon"><ems-base-icon class="base-modal__icon" icon-prepend iconName="warning"></ems-base-icon></span>
      <h1 class="base-modal__title">Please Be Sure to Contact the Bank</h1>
    </div>

  </header>
  
  <div class="receipt-manager-modal__body">
    <p class="base-modal__subtitle">When you submit a fraudulent expense, your approver will be notified. It is still your responsibility to call the credit card's bank to report this fraud and reverse the charge. </p>
    <ems-base-button-group >
      <ems-base-button (click)="onCancel()" label="Cancel" buttonType="tertiary" variant="inverted">
      </ems-base-button>
      <ems-base-button (click)="onContinue()" label="Submit" buttonType="primary" variant="success">
      </ems-base-button>
    </ems-base-button-group>
  </div>

</div>
