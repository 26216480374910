import { Component, Input, OnInit } from '@angular/core';
import { ExpenseDetails } from '@ems-gui/shared/util-core';
import {
  addFlight,
  addLodging,
  addRideShare,
  addShipping,
  addVehicle,
  addVendor,
} from './details-card-helper-functions';

@Component({
  selector: 'ems-details-card',
  templateUrl: './expense-details-card.component.html',
  styleUrls: ['./expense-details-card.component.scss'],
})
export class DetailsCardComponent implements OnInit {
  @Input() detailsData: {}; //eslint-disable-line

  details: ExpenseDetails = {};

  // Preserve original property order
  originalOrder = () => {
    return 0;
  };

  ngOnInit() {
    // VENDOR
    const vendorDetails = addVendor(this.detailsData);
    if (vendorDetails) {
      this.details.Vendor = vendorDetails;
    }

    // FLIGHT
    const flightDetails = addFlight(this.detailsData);
    if (flightDetails) {
      this.details.Flight = flightDetails;
    }

    // SHIPPING
    const shippingDetails = addShipping(this.detailsData);
    if (shippingDetails) {
      this.details.Shipping = shippingDetails;
    }

    // LODGING
    const lodgingDetails = addLodging(this.detailsData);
    if (lodgingDetails) {
      this.details.Lodging = lodgingDetails;
    }

    // VEHICLE
    const vehicleDetails = addVehicle(this.detailsData);
    if (vehicleDetails) {
      this.details.Vehicle = vehicleDetails;
    }

    // RIDESHARE
    const rideshareDetails = addRideShare(this.detailsData);
    if (rideshareDetails) {
      this.details.Rideshare = rideshareDetails;
    }
  }
}
