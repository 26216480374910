import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  ActivityActions,
  DraftActions,
  NewActions,
  SubmittedActions
} from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
});

export const reducer = createReducer(
  initialState,
  /**
   * Activity Actions
   */
  on(
    ActivityActions.getSubmittedActivity,
    ActivityActions.getApprovalActivity,
    ActivityActions.getTrashedActivity,
    ActivityActions.getUnsubmittedActivity,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    NewActions.createNewExpenseComplete,
    (state, { activity }) => !activity ?
      ({ ...state, loading: false }) :
      adapter.upsertOne(activity,{
        ...state,
        loading: false
      })
  ),
  on(
    ActivityActions.getUnsubmittedActivityComplete,
    ActivityActions.getMileageActivityComplete,
    ActivityActions.getTrashedActivityComplete,
    ActivityActions.getTrashedMileageActivityComplete,
    ActivityActions.getSubmittedActivityComplete,
    ActivityActions.getExpenseActivityComplete,
    (state, { activity }) =>
      adapter.upsertMany([...activity], {
        ...state,
        loading: false,
      })
  ),
  on(
    SubmittedActions.getFilteredUnsubmittedComplete,
    SubmittedActions.getUnsubmittedComplete,
    SubmittedActions.getAllExpensesComplete,
    DraftActions.resetFormComplete,
    (state) =>
      adapter.removeAll({
        ...state,
        loading: false,
      })
  ),
  on(
    ActivityActions.deleteDraftNote,
    ActivityActions.deleteNewExpenseNote,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    ActivityActions.deleteDraftNoteComplete,
    ActivityActions.deleteNewExpenseNoteComplete,
    (state, { activity }) =>
      adapter.removeOne(activity.id, {
        ...state,
        loading: false,
      })
  ),
  on(
    ActivityActions.getUnsubmittedActivityError,
    ActivityActions.getMileageActivityError,
    ActivityActions.getSubmittedActivityError,
    ActivityActions.getExpenseActivityError,
    ActivityActions.deleteDraftNoteError,
    ActivityActions.deleteNewExpenseNoteError,
    ActivityActions.getTrashedActivityError,
    ActivityActions.getTrashedMileageActivityError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);

/**
 * Trash Selectors
 */

export const activityLoading = (state: State) => state.loading;
