<ems-base-modal
  [config]="modalInput$ | async"
  [completeOverlay]="true"
  (dismiss)="onDismiss($event)"
  class="edit-expense-modal"
>
  <ems-base-button-group modal-actions class="edit-expense-actions" [gap]="8">
    <div
      *ngIf="(expense$ | async)?.paymentType !== 'user'"
      style="display: contents;"
    >
      <ems-base-dropdown dropdownLabel="Actions" class="edit-expense-dropdown">
        <a
          class="base-dropdown__item"
          (click)="onRedoItemization()"
          *ngIf="(expense$ | async)?.parent"
        >
          Re-itemize
        </a>
        <a
          class="base-dropdown__item"
          (click)="onUndoItemization()"
          *ngIf="(expense$ | async)?.parent"
        >
          De-Itemize
        </a>
        <a
          class="base-dropdown__item"
          (click)="onItemizeDraft()"
          *ngIf="!(expense$ | async)?.parent && (expense$ | async)?.paymentType !== 'user'"
        >
          Itemize
        </a>
        <a class="base-dropdown__item" (click)="onDeleteDraft()">Trash</a>
      </ems-base-dropdown>
      <ems-base-button
        label="Save & Close"
        buttonType="primary"
        variant="brand"
        (click)="saveAndClose()"
      ></ems-base-button>
      <!--
        Submission Button Validation State - 1.
        - Button renders as enable
      -->
      <ems-base-button
        label="Submit"
        buttonType="primary"
        variant="success"
        (click)="onOpenModal()"
      ></ems-base-button>
      <!--
        Submission Button Validation State -  2.
        - form is invalid or payment method is Company CC
        - Button renders disable
      -->
    </div>
    <div
      *ngIf="(expense$ | async)?.paymentType === 'user'"
      style="display: contents;"
    >
      <ems-base-button
        label="Trash"
        buttonType="secondary"
        variant="brand"
        (click)="onDeleteDraft()"
        class="new-expense-submit"
        iconName="trash"
      ></ems-base-button>
      <ems-base-button
        label="Ready to Submit"
        buttonType="primary"
        variant="success"
        (click)="saveCompanyCreditCardExpense()"
      ></ems-base-button>
    </div>
  </ems-base-button-group>

  <ems-base-tabs
    [detailLayout]="true"
    [hideTabs]="(expense$ | async)?.activity.length === 0"
    (tabSelected)="onTabSelected($event)"
  >
    <ems-base-tab [tabTitle]="'Form'" [detailLayout]="true">
      <ems-expense-form-edit
        [formId]="formId"
        [favoriteJobCodes]="favoriteJobCodes$ | async"
        [dismiss]="dismiss$ | async"
        [expense]="expense$ | async"
        [jobCodes]="jobCodes$ | async"
        [salesforceCases]="salesforceCases"
        [favoriteSalesforceCases]="favoriteSalesforceCases$ | async"
        [itemizationStatus]="itemizationStatus$ | async"
        [proxy]="proxy$ | async"
        [submitted]="submitted$ | async"
        (expenseFormChange)="onFormChange($event)"
        (statusChange)="onExistingStatusChange($event)"
        (amountStatusChange)="onAmountStatusChange($event)"
        (amountChange)="onAmountChange($event)"
        (favoriteJobCode)="onFavoriteJobCode($event)"
        (favoriteSalesforce)="onFavoriteSalesforceCase($event)"
        (deleteNote)="onDeleteNote($event)"
        (removeReceipt)="onRemoveReceipt($event)"
        (rotateReceipt)="onRotateReceipt($event)"
        (receiptData)="onReceiptData($event)"
        (receiptUploaded)="onReceiptUploaded($event)"
        (fileSizeError)="onFileUploadSizeError()"
      ></ems-expense-form-edit>

    </ems-base-tab>
    <ems-base-tab
      [tabTitle]="isMobileView ? 'Activity' : 'Activity Log'"
      [detailLayout]="true"
      [activityLogLayout]="true"
      *ngIf="(expense$ | async)?.activity.length > 0"
    >
      <ems-base-stack space="l">
        <ems-base-box *ngFor="let item of (expense$ | async)?.activity">
          <ems-expense-note
            [noteTitle]="item.action"
            [date]="getTimestamp(item.date)"
            [content]="getFormattedNote(item)"
            [user]="item.action !== 'Reimbursed Expense' ? item.author : 'Spense'"
            [email]="item.author?.work_email"
            [id]="item.author?.employee_number"
          ></ems-expense-note>
        </ems-base-box>
      </ems-base-stack>
    </ems-base-tab>
  </ems-base-tabs>

</ems-base-modal>







