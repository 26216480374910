import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-tab',
  templateUrl: './base-tab.component.html',
  styleUrls: ['./base-tab.component.scss'],
})
export class BaseTabComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('tabTitle') title: string;
  @Input() active = false;
  @Input() detailLayout = false;
  @Input() activityLogLayout = false;

  constructor() {}

  ngOnInit() {}
}
