import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-button-group',
  templateUrl: './base-button-group.component.html',
  styleUrls: ['./base-button-group.component.scss'],
})
export class BaseButtonGroupComponent implements OnInit {
  /**
   * Direction to align buttons. Accepts `Left`, `Right`
   */
  @Input() alignButtons = '';
  @Input() gap: number;

  /**
   * Classnames
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-align-right': this.alignButtons === 'right',
    };
  }

  styles() {
    if(!this.gap) return {};
    return {
      columnGap: `${this.gap}px`
    };
  }

  constructor() {}

  ngOnInit() {}
}
