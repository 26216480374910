import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-base-dismiss-button',
  templateUrl: './base-dismiss-button.component.html',
  styleUrls: ['./base-dismiss-button.component.scss'],
})
export class BaseDismissButtonComponent implements OnInit {
  /**
   * Dismiss Event
   * Emits `dismiss` event when button is clicked
   */
  @Output() dismiss = new EventEmitter<boolean>();

  /**
   * Dismiss Click
   * Fires `dismiss` event emitter
   */
  onDismissClick() {
    this.dismiss.emit();
  }

  constructor() {}

  ngOnInit() {}
}
