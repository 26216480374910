import { Component } from '@angular/core';

@Component({
  selector: 'ems-icon-spense-logo',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.071"
    height="21.428"
    viewBox="0 0 16.071 21.428"
  >
    <defs>
      <style>
        .cls-1 {
          fill: #009efb;
        }
      </style>
    </defs>
    <g
      id="Group_5837"
      data-name="Group 5837"
      transform="translate(-536.964 -428.286)"
    >
      <path
        id="Path_1946"
        data-name="Path 1946"
        class="cls-1"
        d="M1121.4 693.273a5.82 5.82 0 0 1 3.286 1.1 3.3 3.3 0 0 1 1.318 2.613h3.2a5.775 5.775 0 0 0-.333-1.96 6.664 6.664 0 0 0-4.261-3.95 11.6 11.6 0 0 0-6.033-.315 7.454 7.454 0 0 0-3.987 2.128 5.69 5.69 0 0 0-1.245 5.433 4.536 4.536 0 0 0 2.709 3.3l1.973-2.585a2.949 2.949 0 0 1-1.671-2.592c-.025-1.883 1.791-3.324 5.044-3.172z"
        transform="translate(-576.168 -262.213)"
      />
      <path
        id="Path_1947"
        data-name="Path 1947"
        class="cls-1"
        d="M1120.907 720.51a5.819 5.819 0 0 1-3.286-1.1 3.3 3.3 0 0 1-1.319-2.613h-3.195a5.772 5.772 0 0 0 .334 1.96 6.663 6.663 0 0 0 4.261 3.95 11.6 11.6 0 0 0 6.033.315 7.453 7.453 0 0 0 3.986-2.128 5.689 5.689 0 0 0 1.245-5.432 4.535 4.535 0 0 0-2.708-3.3l-1.974 2.585a2.949 2.949 0 0 1 1.672 2.592c.024 1.882-1.791 3.323-5.049 3.171z"
        transform="translate(-576.144 -273.57)"
      />
      <ellipse
        id="Ellipse_742"
        data-name="Ellipse 742"
        class="cls-1"
        cx="2.139"
        cy="2.141"
        rx="2.139"
        ry="2.141"
        transform="translate(542.861 436.859)"
      />
    </g>
  </svg>`,
  styleUrls: ['./icon-spense-logo.component.scss'],
})
export class IconSpenseLogoComponent {}
