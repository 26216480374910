import { Component, ElementRef, forwardRef, Input, OnChanges, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";

@Component({
  selector: 'sis-two-radio-buttons',
  templateUrl: './two-radio-buttons.component.html',
  styleUrls: ['./two-radio-buttons.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TwoRadioButtonsComponent),
    multi: true
  }]
})

export class TwoRadioButtonsComponent implements ControlValueAccessor, OnChanges {
  @Input() public formControl: FormControl = new FormControl();
  @Input() public displayTexts: [ string, string ];
  @Input() public values: [ string | boolean, string | boolean ];
  @Input() public disabled = false;
  @ViewChild('input') inputRef: ElementRef;
  private innerValue: boolean = false;

  constructor() {}

  ngOnChanges() {}

  onChange(_event: Event, value: string) {
    this.innerValue = value === "true" ? true : false;
    this.propagateChange(this.innerValue);
  }

  get value(): any {
    return this.innerValue;
  };

  set value(value: any) {
      if (value !== this.innerValue) {
          this.innerValue = value;
      }
  }

  // These methods are from ControlValueAccessor interface

  propagateChange = (_: any) => { }

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
      this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {}
}
