import { Component, Input } from '@angular/core';

@Component({
  selector: 'ems-base-text',
  templateUrl: './base-text.component.html',
  styleUrls: ['./base-text.component.scss'],
})
export class BaseTextComponent {
  /**
   * Size of the text
   *
   * Accepts `1`, `2`
   */
  @Input() size = '2';

  /**
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-size-1': this.size === '1',
      'is-size-2': this.size === '2',
    };
  }
}
