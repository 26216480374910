import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-avatar',
  templateUrl: './base-avatar.component.html',
  styleUrls: ['./base-avatar.component.scss'],
})
export class BaseAvatarComponent implements OnInit {
  /**
   * The users first name
   */
  @Input() firstName = '';

  /**
   * The users first name
   */
  @Input() lastName = '';

  /**
   * Sets the size of the avatar
   * Accepts `m` or `l`
   */
  @Input() size = 'm';

  avatarClasses() {
    return {
      'is-medium': this.size === 'm',
      'is-large': this.size === 'l',
    };
  }

  constructor() {}

  ngOnInit() {}
}
