import {
  JsonWebToken,
  JsonWebTokenHeader, JsonWebTokenPayload,
  JWT
} from '../../guards/jwt.interface';
import { Buffer } from 'buffer';

/**
 * This is used to determine which index for the Header, Payload, and
 * Signature.
 */
enum JsonWebTokenIndexEnum {
  HeaderIndex,
  PayloadIndex,
  SignatureIndex
}

/**
 * This class is used to actually handle the Json Web Token(JWT).  Takes in a
 * JWT "string.string.string".  It performs the converting, decoding, and
 * retrieving for you.  It will currently also perform expiration checks.
 */
export class JsonWebTokenEntity {
  public jsonWebToken: JsonWebToken;

  /**
   * Retrieves the Headers decoded and parsed as an Object Literal.
   * @returns { JsonWebTokenHeader }
   */
  public get header(): JsonWebTokenHeader {
    return this.jsonWebToken[JsonWebTokenIndexEnum.HeaderIndex];
  }

  /**
   * Retrieves the Payload decoded and parsed as an Object Literal.
   * @returns { JsonWebTokenPayload }
   */
  public get payload(): JsonWebTokenPayload {
    return this.jsonWebToken[JsonWebTokenIndexEnum.PayloadIndex];
  }

  /**
   * Retrieves the Signature, it is left as a Base64 encode because it is
   * typically encrypted and used only for validating a Token itself.
   * @returns { string }
   */
  public get signature(): string {
    return this.jsonWebToken[JsonWebTokenIndexEnum.SignatureIndex];
  }

  /**
   * Returns if the token is expired.
   * @returns { boolean }
   */
  public get isExpired() {
    return Date.now() > this.payload.exp;
  }

  public constructor(public token: JWT) {
    if(!token) throw new Error(`Falsy token provided: ${token}`);
    this.jsonWebToken = this.convertFromToken(token);
  }

  /**
   * Takes in a string that should be set as a Base64 and returns a Buffer.
   * @param base64 { string }
   * @private
   * @returns { Buffer }
   */
  private fromBase64(base64: string) {
    return Buffer.from(base64, 'base64').toString('utf8');
  }

  /**
   * Takes in a object that is stringify and attempts to parse it using
   * JSON.parse.
   * @param stringify
   * @private
   * @returns { Record<string, unknown> }
   */
  private parseString(stringify: string): Record<string, unknown> {
    return JSON.parse(stringify);
  }

  /**
   * Takes a JWT "string.string.string" and converts it into a JsonWebToken.
   * @param token { string | JWT }
   * @private
   * @returns { JsonWebToken }
   */
  private convertFromToken(token: string | JWT) {
    const parseTheseIndexes = [
      JsonWebTokenIndexEnum.HeaderIndex,
      JsonWebTokenIndexEnum.PayloadIndex
    ];
    return <JsonWebToken>(token)
      .split('.')
      .map((val: string, index: number) => {
        const includes = parseTheseIndexes.find(
          (jsonIndex: JsonWebTokenIndexEnum) => jsonIndex === index
        );
        if(includes !== undefined)
          return this.parseString(this.fromBase64(val));
        return val;
      });
  }

  /**
   * Implemented so that this class can be passed into a string directly.
   * @returns { JWT }
   */
  public toString(): JWT {
    return this.token;
  }
}
