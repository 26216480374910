import { Component } from '@angular/core';

@Component({
  selector: 'ems-icon-mileage-expense',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19.6"
    viewBox="0 0 26 19.6"
  >
    <defs>
      <style>
        .cls-2,
        .cls-4,
        .cls-5 {
          fill: none;
          stroke: #fff;
          stroke-width: 1.5px;
        }
        .cls-2,
        .cls-4 {
          stroke-linejoin: round;
        }
        .cls-4 {
          stroke-linecap: round;
        }
        .cls-5 {
          stroke-miterlimit: 10;
        }
      </style>
    </defs>
    <g
      id="Group_2768"
      transform="translate(-1809.25 -597.25)"
      style="isolation:isolate"
    >
      <g id="Group_2769" transform="translate(1810 598)">
        <g id="Group_2768-2">
          <circle
            id="Ellipse_746"
            cx="1.725"
            cy="1.725"
            r="1.725"
            class="cls-2"
            transform="translate(2.27 8.702)"
          />
          <circle
            id="Ellipse_747"
            cx="1.725"
            cy="1.725"
            r="1.725"
            class="cls-2"
            transform="translate(16.823 8.702)"
          />
          <path
            id="Path_2504"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="1.5px"
            d="M0 0h4.324"
            transform="translate(9.102 9.513)"
          />
          <path
            id="Line_285"
            d="M0 0L4.324 0"
            class="cls-4"
            transform="translate(9.102 11.892)"
          />
          <path
            id="Path_2497"
            d="M104.957 660.966l-1.974-6.789H88.451l-1.974 6.789"
            class="cls-4"
            transform="translate(-84.454 -654.177)"
          />
          <rect
            id="Rectangle_4203"
            width="22.543"
            height="7.335"
            class="cls-2"
            rx="2.033"
            transform="translate(0 6.789)"
          />
          <path
            id="Path_2499"
            d="M102.017 659.863h1.1a1.073 1.073 0 0 0 1.07-1.07 1.073 1.073 0 0 0-1.07-1.07h-1.809"
            class="cls-2"
            transform="translate(-81.648 -653.507)"
          />
          <path
            id="Path_2500"
            d="M86.949 659.863h-1.1a1.073 1.073 0 0 1-1.07-1.07 1.073 1.073 0 0 1 1.07-1.07h1.809"
            class="cls-2"
            transform="translate(-84.776 -653.507)"
          />
          <path
            id="Path_2501"
            d="M85.49 665.582a.976.976 0 0 1 .086-.113"
            class="cls-5"
            transform="translate(-84.64 -652.042)"
          />
          <path
            id="Path_2502"
            d="M88.8 666.147v3.032a.919.919 0 0 1-.916.917h-1.6a.919.919 0 0 1-.916-.917v-3.119a.909.909 0 0 1 .146-.5"
            class="cls-5"
            transform="translate(-84.664 -652.023)"
          />
          <path
            id="Path_2503"
            d="M100.256 666.147v3.032a.92.92 0 0 0 .917.917h1.6a.92.92 0 0 0 .917-.917v-3.119a.919.919 0 0 0-.146-.5"
            class="cls-5"
            transform="translate(-81.848 -652.023)"
          />
        </g>
      </g>
    </g>
  </svg>`,
})
export class IconMileageExpenseComponent {}
