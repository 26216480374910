import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from "@angular/core";
import {
  NewActions,
  WebSubmitterFacade
} from '@ems-gui/expense/util-web-ngrx';
import { OnChange } from "@ems-gui/shared/util-core";
import { ToastrService } from "ngx-toastr";
export interface ReceiptData {
  file?: File, rotationAngle?: number, newReceipt: boolean
}

const MAX_FILE_SIZE = 10000000;
@Component({
  selector: "receipt-container",
  templateUrl: "./receipt-container.component.html",
  styleUrls: ["./receipt-container.component.scss"],
})
export class ReceiptContainerComponent implements OnInit {
  stagedImage: Blob;
  receiptForm: HTMLFormElement;
  inputDiv: HTMLDivElement;
  input: HTMLInputElement;
  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;
  image = new Image();
  pdfSrcPresent = false;
  degrees = 0;
  pdfSrc;
  angle = 0
  pdfStyles = '';
  deleteReceipt:boolean = false;
  newReceiptAdded: boolean = false;
  imageLoaded = false;

  constructor(
    private elementRef: ElementRef,
    public submitterState: WebSubmitterFacade,
    private toastrService: ToastrService,
    private renderer: Renderer2
  ) {}

  @Output() fileSelected: EventEmitter<ReceiptData> =
    new EventEmitter<ReceiptData>();
  @Output() removeReceipt: EventEmitter<void> = new EventEmitter<void>();

  @OnChange(function(this: ReceiptContainerComponent, imageUrl) {
    if (imageUrl) {
      const request = new XMLHttpRequest();
      request.open("GET", imageUrl, true);
      request.responseType = "blob";
      request.onload = () => {
        if (request.status === 200) {
          const blob = request.response;
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            const base64data = fileReader.result as string;
            const fileType = base64data.split(";")[0].split(":")[1];
            const file = new File([blob], `receipt.${fileType.split("/")[1]}`, { type: fileType });

            // Ensure the file is correctly staged
            this.stagedImage = new Blob([file], { type: file.type });

            this.fileSelected.emit({file, rotationAngle: this.angle, newReceipt: false});

            if (file.type.match("pdf.*")) {
              this.setPdfStyles();
              this.pdfSrcPresent = true;
              this.pdfSrc = base64data;
              this.imageLoaded = true;
              return;
            }

            // Load image for preview
            this.image.src = URL.createObjectURL(blob);
            this.imageLoaded = true;
          };
          fileReader.readAsDataURL(blob);

        } else {
          console.error(`Failed to fetch image. Status: ${request.status}`);
        }
      };
      request.onerror = () => {
        console.error("Network error while fetching image.");
      };
      request.send();
    }
  })
  @Input() imageUrl: string;
  @Input() hasReceipt: boolean = false;


  ngOnInit() {
    this.receiptForm = this.elementRef.nativeElement.querySelector("#receipt-form");
    this.inputDiv = this.elementRef.nativeElement.querySelector(".input-div");
    this.input = this.elementRef.nativeElement.querySelector(".input-div input");
    this.canvas = this.elementRef.nativeElement.querySelector("#canvas");
    this.canvasContext = this.canvas.getContext("2d");
    this.image.crossOrigin = "anonymous";
    this.image.onload = () => {
      const width = this.canvas.width;
      const naturalWidth = this.image.naturalWidth;
      const naturalHeight = this.image.naturalHeight;
      const aspectRatio = naturalWidth / naturalHeight;
      const height = width / aspectRatio;
      this.canvas.height = height;
      this.canvasContext.drawImage(this.image, 0, 0, width, height);
      this.imageLoaded = true;
    }

    this.input.addEventListener("change", () => {
      if (this.input.files[0].size > MAX_FILE_SIZE) {
        this.toastrService.show('Receipt file size cannot exceed 10MB.', '', {
          timeOut: 10000,
        });
        return
      }
      this.newReceiptAdded = true;
      this.receiptForm.reset();
      this.displayImage(this.input.files[0]);
      this.dispatchAndEmitChange(this.input.files[0], this.angle);
    });

    this.inputDiv.addEventListener("drop", (event) => {
      this.newReceiptAdded = true;
      event.preventDefault();
      event.stopPropagation(); // Ensure the event does not propagate further
      const file = event.dataTransfer.files[0];
      if (file.size > MAX_FILE_SIZE) {
        this.toastrService.show('Receipt file size cannot exceed 10MB.', '', {
          timeOut: 10000,
        });
        return
      }
      if (file && (file.type.match("image.*") || file.type.match("pdf.*"))) {
        this.displayImage(file);
        this.dispatchAndEmitChange(file, this.angle);
      }
    });
  }

  dispatchAndEmitChange(file: File, rotationAngle: number) {
    const newReceipt = this.deleteReceipt && this.newReceiptAdded;
    this.fileSelected.emit({file, rotationAngle, newReceipt: newReceipt});
    this.submitterState.dispatch(NewActions.ocrOnly({ image: file }));
  }

  displayImage(file: File) {
    this.hasReceipt = true;
    this.imageLoaded = false;
    this.stagedImage = new Blob([file], { type: file.type });

    if (file.type.match("pdf.*")) {
      this.setPdfStyles();
      this.pdfSrcPresent = true;
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        this.pdfSrc = event.target.result;
        this.imageLoaded = true;
      };
      fileReader.readAsArrayBuffer(file);
      return;
    }

    this.image.src = URL.createObjectURL(this.stagedImage);
  }

  setPdfStyles() {
    const width = this.elementRef.nativeElement.offsetWidth;
    this.pdfStyles = `display: block; width: ${width}px; height: 36rem; max-width: 100%;`;
  }

  deleteQueuedImage() {
    this.stagedImage = undefined;
    this.input.value = "";
    this.degrees = 0;
    this.pdfSrcPresent = false;
    this.pdfSrc = undefined;
    this.deleteReceipt = true;
    this.hasReceipt = false;
    this.removeReceipt.emit();
  }

  onImageClick() {
    if(this.imageUrl){
      window.open(this.imageUrl, '_blank');
    }
  }

  zoomIn() {
    this.renderer.addClass(
      this.elementRef.nativeElement.querySelector('.pdf-container'),
      'zoomed-in'
    );
  }

  zoomOut() {
    this.renderer.removeClass(
      this.elementRef.nativeElement.querySelector('.pdf-container'),
      'zoomed-in'
    );
  }

  onMouseMove(event: MouseEvent) {
    const container = this.elementRef.nativeElement.querySelector('.pdf-container');
    const rect = container.getBoundingClientRect();
    const x = event.clientX - rect.left; // x position within the element.
    const y = event.clientY - rect.top;  // y position within the element.

    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;

    this.renderer.setStyle(container, 'transform-origin', `${xPercent}% ${yPercent}%`);
  }

  rotateImage() {
    const fileType = this.stagedImage.type;
    const file = new File([this.stagedImage], `receipt.${fileType.split("/")[1]}`, { type: fileType });

    this.degrees = (this.degrees + 90) % 360;

    if (file.type.match("image.*")) {
      if (this.degrees == 90 || this.degrees == 270) {
        this.canvas.width = this.image.height;
        this.canvas.height = this.image.width;
      } else {
        this.canvas.width = this.image.width;
        this.canvas.height = this.image.height;
      }

      this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (this.degrees == 90 || this.degrees == 270) {
        this.canvasContext.translate(this.image.height / 2, this.image.width / 2);
      } else {
        this.canvasContext.translate(this.image.width / 2, this.image.height / 2);
      }

      this.canvasContext.rotate(this.degrees * Math.PI / 180);
      this.canvasContext.drawImage(this.image, -this.image.width / 2, -this.image.height / 2);
    }
    const receiptData: ReceiptData = {
      newReceipt: this.deleteReceipt && this.newReceiptAdded
    };
    receiptData[`file`] = file;
    if(this.degrees && this.degrees !== 0) {
      receiptData[`rotationAngle`] = this.degrees;
    }
    this.fileSelected.emit(receiptData);
  }
}
