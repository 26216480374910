import { Expense } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const editDraft = createAction(
  '[Expense - Mileage Draft] editDraft',
  props<{ id: number }>()
);

export const submitDraft = createAction(
  '[Expense - Mileage Draft] submitDraft'
);

export const submitDraftComplete = createAction(
  '[Expense - Mileage Draft] submitDraftComplete',
  props<{ expense: any }>()
);

export const submitDraftError = createAction(
  '[Expense - Mileage Draft] submitDraftError',
  props<{ error: string }>()
);

export const saveDraft = createAction('[Expense - Mileage Draft] saveDraft');

export const saveDraftComplete = createAction(
  '[Expense - Mileage Draft] saveDraftComplete',
  props<{ expense: any }>()
);

export const saveDraftError = createAction(
  '[Expense - Mileage Draft] saveDraftError',
  props<{ error: string }>()
);

export const applyExpenseToState = createAction(
  '[Expense - Mileage Draft] applyExpenseToState',
  props<{ expense: Partial<Expense> }>()
);

export const save = createAction(
  '[Expense - Mileage Draft] save',
  props<{ expense: Partial<Expense> }>()
);

export const selectExpenseType = createAction(
  '[Expense - Mileage Draft] selectExpenseType',
  props<{ id: any }>()
);

export const selectJobCode = createAction(
  '[Expense - Mileage Draft] selectJobCode',
  props<{ id: any }>()
);

export const deleteDraft = createAction(
  '[Expense - Mileage Draft] deleteDraft'
);

export const deleteDraftComplete = createAction(
  '[Expense - Mileage Draft] deleteDraftComplete',
  props<{ expense: any }>()
);

export const deleteDraftError = createAction(
  '[Expense - Mileage Draft] deleteDraftError',
  props<{ error: string }>()
);

export const deselectOneDraft = createAction(
  '[Expense - Mileage Draft] deselectOneDraft'
);

export const selectPhotoLibrary = createAction(
  '[Expense - Mileage Draft] selectPhotoLibrary'
);

export const selectPhotoLibraryComplete = createAction(
  '[Expense - Mileage Draft] selectPhotoLibraryComplete',
  props<{ uri: string }>()
);

export const selectPhotoLibraryError = createAction(
  '[Expense - Mileage Draft] selectPhotoLibraryError',
  props<{ error: string }>()
);

export const takePhoto = createAction('[Expense - Mileage Draft] takePhoto');

export const takePhotoComplete = createAction(
  '[Expense - Mileage Draft] takePhotoComplete',
  props<{ uri: string }>()
);

export const takePhotoError = createAction(
  '[Expense - Mileage Draft] takePhotoError',
  props<{ error: string }>()
);

export const savePhotoComplete = createAction(
  '[Expense - Mileage Draft] savePhotoComplete',
  props<{ expense: any }>()
);

export const savePhotoError = createAction(
  '[Expense - Mileage Draft] savePhotoError',
  props<{ error: string }>()
);

export const getEstimatedReimbursement = createAction(
  '[Expense - Mileage Draft] getEstimatedReimbursement',
  props<{ mileage: any }>()
);

export const getEstimatedReimbursementComplete = createAction(
  '[Expense - Mileage Draft] getEstimatedReimbursementComplete',
  props<{ reimbursement: any }>()
);

export const getEstimatedReimbursementError = createAction(
  '[Expense - Mileage Draft] getEstimatedReimbursementError',
  props<{ error: string }>()
);

export const resetForm = createAction('[Expense - Mileage Draft] resetForm');

export const resetFormComplete = createAction(
  '[Expense - Mileage Draft] resetFormComplete'
);

export const saveWithNote = createAction(
  '[Expense - Mileage Draft] saveWithNote',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithNoteComplete = createAction(
  '[Expense - Mileage Draft] saveWithNoteComplete',
  props<{ expense: any }>()
);

export const saveWithNoteError = createAction(
  '[Expense - Mileage Draft] saveWithNoteError',
  props<{ error: string }>()
);

export const saveWithoutNote = createAction(
  '[Expense - Mileage Draft] saveWithoutNote',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithoutNoteComplete = createAction(
  '[Expense - Mileage Draft] saveWithoutNoteComplete',
  props<{ expense: any }>()
);

export const saveWithoutNoteError = createAction(
  '[Expense - Mileage Draft] saveWithoutNoteError',
  props<{ error: string }>()
);

export const submit = createAction(
  '[Expense - Mileage Draft] submit',
  props<{ expense: any }>()
);

export const trashDrafts = createAction(
  '[Expense - Mileage Draft] trashDrafts',
  props<{ expenses: number[] }>()
);

export const trashDraftsComplete = createAction(
  '[Expense - Mileage Draft] trashDraftsComplete',
  props<{ expenses: any }>()
);

export const trashDraftsError = createAction(
  '[Expense - Mileage Draft] trashDraftsError',
  props<{ error: string }>()
);

export const rotateReceipt = createAction(
  '[Expense - Mileage Draft] rotateReceipt',
  props<{ angle: number; id: number }>()
);

export const rotateReceiptComplete = createAction(
  '[Expense - Mileage Draft] rotateReceiptComplete',
  props<{ expense: Expense }>()
);

export const rotateReceiptError = createAction(
  '[Expense - Mileage Draft] rotateReceiptError',
  props<{ error: any }>()
);

export const removeReceipt = createAction(
  '[Expense - Mileage Draft] removeReceipt',
  props<{ id: number }>()
);

export const removeReceiptComplete = createAction(
  '[Expense - Mileage Draft] removeReceiptComplete',
  props<{ expense: Partial<Expense> }>()
);

export const removeReceiptError = createAction(
  '[Expense - Mileage Draft] removeReceiptError',
  props<{ error: string }>()
);

export const uploadComplete = createAction(
  '[Expense - Mileage Draft] uploadComplete',
  props<{ id: number }>()
);

export const getExpenseComplete = createAction(
  '[Expense - Mileage Draft] getExpenseComplete',
  props<{ expense: Partial<Expense> }>()
);

export const getExpenseError = createAction(
  '[Expense - Mileage Draft] getExpenseError',
  props<{ error: string }>()
);

export const cancelAddReceipt = createAction(
  '[Expense - Mileage Draft] cancelAddReceipt'
);
