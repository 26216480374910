import { Component, Input } from '@angular/core';

@Component({
  selector: 'ems-base-tooltip',
  templateUrl: './base-tooltip.component.html',
  styleUrls: ['./base-tooltip.component.scss'],
})
export class BaseTooltipComponent {
  /**
   * String of content for the tooltip
   * @param {string} content
   */
  @Input() content = '';

  /**
   * Amount of space between each element. Accepts `top`, `right`, `bottom`, `left`
   * @param {string} direction
   * @default 'right'
   */
  @Input() direction = 'right';

  @Input() fullAddress = false;

  /**
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-top': this.direction === 'top',
      'is-right': this.direction === 'right',
      'is-bottom': this.direction === 'bottom',
      'is-left': this.direction === 'left',
    };
  }

  formatAddress(address: string) {
    return address.replace(/#/, '\n');
  }
}
