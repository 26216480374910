import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ems-icon-scan-failed',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_4872"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <defs>
        <clipPath id="clip-path">
          <path id="Rectangle_5633" d="M0 0H100V100H0z" class="cls-1" />
        </clipPath>
        <style>
          .cls-1 {
            fill: #f5a623;
          }
        </style>
      </defs>
      <g id="Group_4871" clip-path="url(#clip-path)">
        <path
          id="Path_2739"
          d="M5.66 23.585H0v-7.023A16.582 16.582 0 0 1 16.562 0h7.023v5.66h-7.023a10.914 10.914 0 0 0-10.9 10.9z"
          class="cls-1"
        />
        <path
          id="Path_2740"
          d="M43.835 23.585h-5.66v-7.023A10.915 10.915 0 0 0 27.269 5.66H20.25V0h7.019a16.582 16.582 0 0 1 16.566 16.562z"
          class="cls-1"
          transform="translate(56.165)"
        />
        <path
          id="Path_2741"
          d="M27.269 43.835H20.25v-5.66h7.019a10.915 10.915 0 0 0 10.906-10.9V20.25h5.66v7.023a16.582 16.582 0 0 1-16.566 16.562"
          class="cls-1"
          transform="translate(56.165 56.165)"
        />
        <path
          id="Path_2742"
          d="M23.585 43.835h-7.023A16.582 16.582 0 0 1 0 27.273V20.25h5.66v7.023a10.914 10.914 0 0 0 10.9 10.9h7.023z"
          class="cls-1"
          transform="translate(0 56.165)"
        />
        <path
          id="Path_2743"
          d="M50.524 67.526a2.354 2.354 0 0 1-1.6-.623l-5.234-4.815-5.226 4.812a2.354 2.354 0 0 1-3.2 0l-5.226-4.815-5.231 4.815a2.345 2.345 0 0 1-3.189 0l-5.234-4.815-5.233 4.815A2.359 2.359 0 0 1 7.2 65.168V7.318A2.362 2.362 0 0 1 9.554 4.96h40.97a2.359 2.359 0 0 1 2.358 2.358v57.85a2.356 2.356 0 0 1-1.411 2.158 2.385 2.385 0 0 1-.947.2m-34.14-11a2.353 2.353 0 0 1 1.6.623l5.234 4.815 5.23-4.815a2.354 2.354 0 0 1 3.2 0l5.226 4.815 5.23-4.815a2.35 2.35 0 0 1 3.192 0l2.875 2.645V9.677H11.913V59.79l2.875-2.645a2.353 2.353 0 0 1 1.6-.623"
          class="cls-1"
          transform="translate(19.959 13.757)"
        />
        <path
          id="Path_2744"
          d="M32.851 13.189H12.258a2.358 2.358 0 1 1 0-4.717h20.593a2.358 2.358 0 1 1 0 4.717"
          class="cls-1"
          transform="translate(27.458 23.498)"
        />
        <path
          id="Path_2745"
          d="M32.851 15.627H12.258a2.358 2.358 0 1 1 0-4.717h20.593a2.358 2.358 0 0 1 0 4.717"
          class="cls-1"
          transform="translate(27.458 30.26)"
        />
        <path
          id="Path_2746"
          d="M32.851 17.935H12.258a2.358 2.358 0 1 1 0-4.717h20.593a2.358 2.358 0 1 1 0 4.717"
          class="cls-1"
          transform="translate(27.458 36.661)"
        />
      </g>
    </svg>
  `,
  styleUrls: ['./icon-scan-failed.component.scss'],
})
export class IconScanFailedComponent implements OnInit {
  ngOnInit(): void {
    return;
  }
}
