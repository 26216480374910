import { User } from '@ems-gui/shared/util-core';
import { createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions';

export interface State {
  user: User;
  error: string;
}

export const initialState: State = {
  user: {
    id: 1,
    created: '',
    sysPeriod: '',
    login: null,
    pass: null,
    email: '',
    registered: '',
    activationKey: null,
    status: 0,
    displayName: '',
    employeeNumber: '',
    employeeStatus: '',
    employeeRole: '',
    clientId: 0,
    firstName: '',
    lastName: '',
    jobTitle: null,
    bcrypt: null,
    contactId: 0,
  },
  error: '',
};

export const reducer = createReducer(
  initialState,
  on(UserActions.getUserComplete, (state, { user }) => ({
    ...state,
    user,
  })),
  on(UserActions.logout, (state) => ({
    ...state,
    user: {
      ...initialState.user,
    },
  })),
  on(UserActions.getPermissionsComplete, (state, { permissions }) => ({
    ...state,
    user: {
      ...state.user,
      permissions,
    },
  })),
  on(UserActions.getPermissionsError, (state, { error }) => ({
    ...state,
    error,
  }))
);

export const getUser = (state: State) => {
  return state.user;
};
