<div
  class="receipt-widget"
  [class.disabled]="isDisabled"
  [class.detail-modal]="detailLayout"
>
  <div class="itemize-icon" *ngIf="parent !== null">
    <ems-base-tooltip
      direction="right"
      content="This expense has been itemized. If it is no longer needed, use Re-Itemize or De-Itemize to delete the expense."
    >
      <ems-icon-itemize></ems-icon-itemize>
    </ems-base-tooltip>
  </div>

  <div
    *ngIf="expense && expense.hasReceipt; else uploadImage"
    class="receipt-widget__photo">
    <!-- Display Image if exists -->
    <div
      class="zoom-container"
      *ngIf="expense.image && expense.image !== 'Invalid Image'"
    >
      <div
        #zoomContainer
        class="zoom-container"
        (mouseover)="zoomIn()"
        (mouseleave)="zoomReset()"
        (mousemove)="mouseFollow($event)"
        (click)="onImageClick()"
        [ngClass]="{'image-clickable' : expense.imageMetadata.ContentType !== 'pdf'}"
      >
        <iframe
          #receiptPhoto
          *ngIf="isPdf"
          [src]="expense.image"
          class="photo"
        ></iframe>

        <img
          *ngIf="!isPdf && expense.imageMetadata.ContentType !== 'pdf'"
          [src]="expense.image"
          class="photo"
          #receiptPhoto
        />
      </div>

      <div
        [ngClass]="showRemoveButton || canUploadNew ? 'rotate rotate__with-grid' : 'rotate'"
      >
        <button
          class="receipt-button"
          title="Rotate"
          (click)="rotate()"
          [class.single-receipt-button]="expenseStatus === 'pending' || parent"
        >
          <ems-base-icon iconName="replay" class="rotate__icon"></ems-base-icon>
          <span class="rotate__label">rotate</span>
        </button>
        <button
          class="receipt-button remove"
          title="Remove"
          (click)="onRemoveReceipt()"
          *ngIf="showRemoveButton"
        >
          <ems-base-icon iconName="remove" class="rotate__icon"></ems-base-icon>
          <span class="rotate__label">remove</span>
        </button>
        <button
          #dropzone
          class="receipt-button upload"
          title="Upload new"
          [dropzone]="config"
          (processing)="onUploadProgress()"
          (error)="onUploadError($event)"
          (success)="onUploadSuccess($event)"
          (removedFile)="onUploadInit()"
          *ngIf="canUploadNew"
          (click)="clickToOpenFileSelect($event)"
        >
          <ems-base-icon iconName="file_upload" class="rotate__icon upload-new"></ems-base-icon>
          <span class="rotate__label">upload new</span>
        </button>
      </div>
    </div>
    <!-- Display invalid icon if image does not exist-->
    <div
      *ngIf="expense.image && expense.image === 'Invalid Image'"
      class="dropzone upload"
    >
      <div class="dz-message dropzone__label upload">
        <ems-base-icon
          iconName="invalid-image-icon"
          class="dropzone__invalid_icon"
        ></ems-base-icon>
        <span class="dropzone__label text">
          Receipt Not Available
        </span>
        <span class="dropzone__sub-label dropzone__padding">
          Please contact Software Solutions to assist with this issue
        </span>
        <div class="rotate">
          <button
            class="receipt-button remove"
            title="Remove"
            (click)="onRemoveReceipt()"
          >
            <ems-base-icon iconName="remove" class="rotate__icon"></ems-base-icon>
            <span class="rotate__label">remove</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #uploadImage>
    <div
      #dropzone
      class="dropzone {{ status }}"
      (click)="clickToOpenFileSelect($event)"
      [class.disabled]="isDisabled"
      [dropzone]="config"
      [disabled]="isDisabled"
      (processing)="onUploadProgress()"
      (error)="onUploadError($event)"
      (success)="onUploadSuccess($event)"
      (removedFile)="onUploadInit()"
      [class.input-error]="
        itemizationStatus === false ||
        (
          !expense?.hasReceipt &&
          submitted &&
          receiptRequired
        )
      "
    >
      <!-- initial label -->
      <div class="dz-message dropzone__label upload">
        <ems-base-icon
          [iconName]="!isDisabled ? 'upload-receipt' : 'no-receipt'"
          [ngClass]="!isDisabled ? 'dropzone__icon' : 'dropzone__disabled'"
        ></ems-base-icon>
        <span
          [ngClass]="
            !isDisabled
              ? 'dropzone__label text'
              : 'dropzone__disabled-label text'
          "
        >
          {{
            !isDisabled ? 'Click to Upload Receipt' : 'No Receipt Attached'
          }}</span
        >
        <span class="dropzone__sub-label" *ngIf="!isDisabled"
          >(or drag and drop here)</span
        >
      </div>
    </div>
  </ng-template>
  <ems-base-helper-text
    variation="negative"
    *ngIf="itemizationStatus === false"
  >
    Receipt is required to itemize expense.
  </ems-base-helper-text>
  <ems-base-helper-text
    [style.visibility]="
      !expense?.hasReceipt &&
      submitted &&
      receiptRequired
        ? 'visible'
        : 'hidden'
    "
    variation="negative"
  >
    Receipt is required
  </ems-base-helper-text>
</div>

<div id="receipt-preview" #receiptPreview>
  <div
    class="dropzone-previews dz-preview dz-file-preview"
    [ngClass] = "canUploadNew? 'content-hidden' : ''"
  >
    <!-- shows w .dz-processing -->
    <div class="dropzone__label progress">
      <ems-base-icon
        iconName="upload-receipt"
        class="dropzone__icon"
      ></ems-base-icon>
      <div class="dz-progress">
        <span class="dz-upload" data-dz-uploadprogress></span>
      </div>
    </div>
    <!-- shows w .dz-success -->
    <div class="dz-details success">
      <img data-dz-thumbnail />
      <div class="remove" data-dz-remove>
        <ems-base-icon iconName="remove" class="remove__icon"></ems-base-icon>
      </div>
    </div>
    <!-- shows w .dz-error -->
    <div class="dropzone__label error" data-dz-remove>
      <ems-base-icon
        iconName="receipt-unverified"
        class="dropzone__icon"
      ></ems-base-icon>
      Upload Failed
      <span class="dropzone__sub-label">(click here to retry)</span>
    </div>
  </div>
</div>
