import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from '../app/app.routes';
import { AuthExpiredGuard } from "./guards/auth-expired.guard";
import {
  RedirectToDashboardGuard
} from './guards/redirect-to-dashboard.guard';

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthExpiredGuard, RedirectToDashboardGuard]
})
export class AppRoutingModule {}
