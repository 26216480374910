import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExpenseFilter, Expense, ExpenseType } from '@ems-gui/shared/util-core';
import moment from 'moment';
import { SnackbarService } from 'ngx-snackbar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private _currentListEmpty = new BehaviorSubject<boolean>(false);
  constructor(
    private snackbar: SnackbarService,
    private router: Router
  ) {}

  get listState() {
    return this._currentListEmpty.asObservable();
  }

  emptyList() {
    this._currentListEmpty.next(true);
  }

  populatedList() {
    this._currentListEmpty.next(false);
  }

  resetListState() {
    this._currentListEmpty.next(false);
  }

  /**
   * Converts dollar values into cents values
   * @param dollars A string that represents and amount in dollars
   */
  dollarsToCents(dollars: string | number): number {
    if(typeof dollars === 'string') {
      const dollarsAndCommas = /[$,]/g;
      const cleanDollars: number = +dollars.replace(dollarsAndCommas, '');
      return Math.round(cleanDollars * 100);
    }

    return +dollars
  }

  /**
   * displays snackbar for completed expense related actions
   * @param caption either 'Expense' or '# expenses'
   * @param actionText the text of the action
   * @param url for navigation
   */
  displaySnackbar(caption: string, actionText: string, url?: string) {
    const navigateAction = {
      text: 'View',
      onClick: () => {
        this.router.navigateByUrl(url);
      },
    };
    const noAction = {
      text: '',
    };
    const action = url ? navigateAction : noAction;
    this.snackbar.add({
      msg: `${caption} ${actionText}.`,
      timeout: 10000,
      action,
      background: '#1d3148',
    });
  }

  updateFilters({
    existingFilters,
    newFilters,
  }: {
    existingFilters: ExpenseFilter;
    newFilters: ExpenseFilter;
  }): ExpenseFilter {
    const consolidatedFilters: ExpenseFilter = {
      ...existingFilters,
      ...newFilters,
    };

    consolidatedFilters.startDate = !consolidatedFilters.startDate
      ? ''
      : new Date(consolidatedFilters.startDate).toISOString();

    consolidatedFilters.endDate = !consolidatedFilters.endDate
      ? ''
      : new Date(consolidatedFilters.endDate).toISOString();

    consolidatedFilters.minAmount = consolidatedFilters.minAmount ?? null;

    consolidatedFilters.maxAmount =
      consolidatedFilters.maxAmount ?? null;

    consolidatedFilters.employee = consolidatedFilters.employee ?? '';

    consolidatedFilters.jobCode = consolidatedFilters.jobCode ?? '';

    consolidatedFilters.salesforceId = consolidatedFilters.salesforceId ?? '';

    if (consolidatedFilters.status === 'overdue') {
      consolidatedFilters.isOverdue = 'true';
    } else if (consolidatedFilters.status === 'pending') {
      consolidatedFilters.isOverdue = 'false';
    } else {
      consolidatedFilters.isOverdue = '';
    }

    if (consolidatedFilters.status === 'fraudulent') {
      consolidatedFilters.fraudulent = 'true';
    } else if (
      consolidatedFilters.status === 'pending' ||
      consolidatedFilters.status === 'overdue'
    ) {
      consolidatedFilters.fraudulent = 'false';
    } else {
      consolidatedFilters.fraudulent = '';
    }

    return consolidatedFilters;
  }

  daysSinceSubmission(date) {
    const submissionDate = new Date(date).getTime();
    const currentDate = new Date().getTime();

    return Math.floor((currentDate - submissionDate) / 86400000);
  }

  formatDate(dateInput) {
    const useFormat = 'MM/DD/YY';
    const useUtc = true;

    if(typeof useFormat !== 'string') {
      throw new Error('useFormat must be a string')
    }

    if(typeof useUtc !== 'boolean') {
      throw new Error('useUtc must be a boolean')
    }

    const today = moment().startOf('day');
    const date = moment(dateInput).startOf('day');
    const diff = today.diff(date, 'days');

    if(diff === 0) {
      return 'Today';
    }

    if(diff === 1) {
      return 'Yesterday';
    }

    if(diff >= 2 && diff <= 5) {
      return `${diff} days ago`;
    }

    return moment(dateInput)
      .utc(!useUtc)
      .format(useFormat);
  }

  checkIfForeignTransactionType (expense, expenseTypes) {
    let result = false;
    let isExpenseForeignTransactionType = false;
    let isVendorForeignTransactionFee = false;
    if(expense && expense.type) {
      const expenseTypeId = expense.type?.id ? expense.type.id : expense.type;
      const expenseTypeValue = expenseTypes.find(p => p.id == +expenseTypeId);
      if (expenseTypeValue && expenseTypeValue.name) {
        isExpenseForeignTransactionType =
        expenseTypeValue.name.toLowerCase().includes('foreign')? true : false;
      }
    }
    if(expense && expense.vendor){
      isVendorForeignTransactionFee =
        expense?.vendor?.toLowerCase() === 'foreign transaction fee';
    }
    result = isExpenseForeignTransactionType || isVendorForeignTransactionFee;

    return result;
 }
}
