import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AllModalActions, DraftActions, MileageDraftActions } from '../actions';
import { setAllowUploads } from '@libs/expense/util-web-ngrx/src/lib/+state/actions/all-modal.actions';
import { selectAllModal } from '@ems-gui/expense/util-web-ngrx';

export interface State extends EntityState<any> {
  initialCallMade: boolean;
  loading: boolean;
  continueTo: string;
  fileUploadAllowed: boolean;
  error: string;
  fabIsActive: boolean;
  currentModal: string;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: State = adapter.getInitialState({
  initialCallMade: false,
  loading: false,
  continueTo: '',
  fileUploadAllowed: true,
  error: '',
  fabIsActive: false,
  currentModal: '',
});

export const reducer = createReducer(
  initialState,
  /**
   * Modal-Agnostic Actions
   */
  on(AllModalActions.initialModalRequestComplete, (state) => ({
    ...state,
    initialCallMade: true,
  })),
  on(AllModalActions.initialModalRequestFinished, (state) => ({
    ...state,
    initialCallMade: false,
    continueTo: '',
  })),
  on(AllModalActions.setContinueTo, (state, { continueTo }) => ({
    ...state,
    continueTo,
  })),
  on(AllModalActions.resetContinueTo, (state) => ({
    ...state,
    continueTo: '',
  })),
  on(AllModalActions.setAllowUploads, (state, { fileUploadAllowed }) => ({
    ...state,
    fileUploadAllowed,
  })),
  on(AllModalActions.setFabIsActive, (state, { fabIsActive }) => ({
    ...state,
    fabIsActive,
  })),
  on(
    MileageDraftActions.saveDraft,
    MileageDraftActions.submitDraft,
    DraftActions.updateItemizedExpenses,
    DraftActions.itemize,
    MileageDraftActions.uploadComplete,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(AllModalActions.setCurrentModal, (state, { currentModal }) => ({
    ...state,
    currentModal,
  }))
);

// /**
//  * Trash Selectors
//  */

// export const activityLoading = (state: State) => state.loading;
