<div class="expense-modal-edit">
  <header class="base-modal__header">
    <div class="base-modal__header-content">
      <h1 class="base-modal__title">Alternate Emails</h1>
      <p class="base-modal__subtitle">
        Add another email address for {{ userName }}.
      </p>
    </div>
    <div class="base-modal__actions">
      <ng-content select="[modal-actions]"></ng-content>
    </div>
  </header>

  <div class="expense-modal-edit__body">
    <section class="expense-modal-edit__content">
      <div class="expense-edit-form">
        <!-- Email -->
        <div class="ems-field">
          <div class="ems-control">
            <input
              [formControl]="email"
              class="ems-input"
              type="email"
              placeholder="johndoe57@gmail.com"
              [class.input-error]="
                email.invalid && (email.touched || email.dirty)
              "
            />
          </div>
        </div>
      </div>

      <!-- Cancel or Confirm -->
      <ems-base-button-group>
        <ems-base-button
          (click)="onCancelClick()"
          label="Cancel"
          buttonType="tertiary"
          variant="cancel"
        ></ems-base-button>
        <ems-base-button
          (click)="onConfirmClick()"
          label="Confirm"
          buttonType="primary"
          variant="success"
        ></ems-base-button>
      </ems-base-button-group>
    </section>
  </div>
</div>
