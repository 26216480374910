<div class="expense-modal-edit">
  <header class="base-modal__header">
    <div class="base-modal__header-content">
      <h1 class="base-modal__title">Reject</h1>
      <p class="base-modal__subtitle">
        Please explain why you are rejecting this expense.
      </p>
    </div>
    <div class="base-modal__actions">
      <ng-content select="[modal-actions]"></ng-content>
    </div>
  </header>

  <div class="expense-modal-edit__body" [formGroup]="form">
    <section class="expense-modal-edit__content">
      <div class="expense-edit-form">
        <!-- Message -->
        <div class="ems-field">
          <div class="ems-control">
            <textarea
              formControlName="message"
              class="ems-textarea"
              placeholder="Reason for Rejection"
              [class.input-error]="message.invalid && displayValidation"
            ></textarea>
          </div>
          <ems-base-helper-text
            [style.visibility]="
              message.invalid && displayValidation ? 'visible' : 'hidden'
            "
            variation="negative"
          >
            A reason is required.
          </ems-base-helper-text>
        </div>
      </div>

      <!-- Cancel or Reject -->
      <ems-base-button-group>
        <ems-base-button
          (click)="onCancelClick()"
          label="Cancel"
          buttonType="tertiary"
          variant="cancel"
        >
        </ems-base-button>
        <ems-base-button
          (click)="onRejectClick()"
          label="Reject"
          buttonType="primary"
          variant="destructive"
        >
        </ems-base-button>
      </ems-base-button-group>
    </section>
  </div>
</div>
