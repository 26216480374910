<div class="expense-modal-edit">
  <header class="base-modal__header">
    <div class="base-modal__header-content">
      <h1 class="base-modal__title">Delete</h1>
      <p class="base-modal__subtitle">
        You are about to permanently delete
        {{ expenseCount > 1 ? 'this expense' : 'these expenses' }}. This cannot
        be undone. Do you wish to continue?
      </p>
    </div>
    <div class="base-modal__actions">
      <ng-content select="[modal-actions]"></ng-content>
    </div>
  </header>

  <div class="expense-modal-edit__body">
    <section class="expense-modal-edit__content">
      <!-- Cancel or Delete -->
      <ems-base-button-group>
        <ems-base-button
          (click)="onCancel()"
          label="Cancel"
          buttonType="tertiary"
          variant="inverted"
        >
        </ems-base-button>
        <ems-base-button
          (click)="onDelete()"
          label="Delete"
          buttonType="primary"
          variant="destructive"
        >
        </ems-base-button>
      </ems-base-button-group>
    </section>
  </div>
</div>
