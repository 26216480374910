<ems-base-modal [config]="modalInput$ | async" (dismiss)="onDismiss()">
  <!-- Trashed Actions -->
  <ems-base-button-group modal-actions>
    <ems-base-dropdown dropdownLabel="Actions">
      <a class="base-dropdown__item" (click)="onRestore(mileageExpense.id)">
        Restore
      </a>
    </ems-base-dropdown>
  </ems-base-button-group>

  <ems-mileage-expense-form-edit
    [expense]="mileageExpense$ | async"
    [dismiss]="dismiss$ | async"
    [jobCodes]="jobCodes$ | async"
    [favorites]="favoriteJobCodes$ | async"
    [reimbursement]="draftMileageReimbursement$ | async"
    [salesforceCases]="salesforceCases$ | async"
    [favoriteSalesforceCases]="favoriteSalesforceCases$ | async"
    [mileageExpenseType]="mileageExpenseType$ | async"
    [isTrashed]="isTrashed"
    (getReimbursement)="onGetMileageReimbursement($event)"
  ></ems-mileage-expense-form-edit>
</ems-base-modal>
