import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-box',
  templateUrl: './base-box.component.html',
  styleUrls: ['./base-box.component.scss'],
})
export class BaseBoxComponent implements OnInit {
  /**
   * Box Space
   *
   * Accepts `xxs`, `xs`, `s`, `m`, `l`, `xl`, `xxl`
   */
  @Input() space = 'm';

  /**
   * ClassNames
   *
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'has-space-xxs': this.space === 'xxs',
      'has-space-xs': this.space === 'xs',
      'has-space-s': this.space === 's',
      'has-space-m': this.space === 'm',
      'has-space-l': this.space === 'l',
      'has-space-xl': this.space === 'xl',
      'has-space-xxl': this.space === 'xxl',
    };
  }

  constructor() {}

  ngOnInit() {}
}
