import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DraftActions, ExpenseTypeActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
  selectedId: string | number;
}

export const sortAlphabetically = (a, b) => {
  return a.name - b.name;
};

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  sortComparer: sortAlphabetically,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(ExpenseTypeActions.get, (state) => ({
    ...state,
    loading: true,
  })),
  on(ExpenseTypeActions.getComplete, (state, { expenseType }) =>
    adapter.setAll(expenseType, {
      ...state,
      loading: false,
    })
  ),
  on(ExpenseTypeActions.getError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(ExpenseTypeActions.select, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(ExpenseTypeActions.resetExpenseType, (state) => ({
    ...state,
    selectedId: '',
  })),
  on(DraftActions.deselectOneDraft, (state) => ({
    ...state,
    selectedId: '',
  }))
);

export const getSelectedExpenseTypeId = (state: State) => {
  return state.selectedId;
};
