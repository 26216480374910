import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProxiedUserActions, UserActions } from '../actions';
import { errorHandler } from '../error-handler';
import * as fromDashboard from '../reducers';

@Injectable()
export class ProxiedUserEffects {
  getUsersForProxy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserComplete),
      withLatestFrom(this.store$.select(fromDashboard.selectCurrentUser)),
      switchMap(([, user]) =>
        this.apiService.getUsersForProxy(user.contactId).pipe(
          map((proxies) =>
            ProxiedUserActions.getUsersForProxyComplete({ proxies })
          ),
          catchError(errorHandler(ProxiedUserActions.getUsersForProxyError))
        )
      )
    )
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store$: Store<fromDashboard.State>
  ) {}
}
