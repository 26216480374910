import { Component, OnInit } from '@angular/core';
import { DraftActions, State } from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ems-receipt-data-warn-modal',
  templateUrl: './receipt-data-warn-modal.component.html',
  styleUrls: ['./receipt-data-warn-modal.component.scss'],
})
export class ReceiptDataWarnModalComponent implements OnInit {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'receipt-data-warn-modal',
  };
  constructor(private store$: Store<State>) {}

  ngOnInit(): void {
    return;
  }

  onContinue() {
    this.store$.dispatch(DraftActions.getNoReceiptDataDraft());
  }
}
