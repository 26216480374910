<div class="two-radio-buttons">
  <label
    *ngFor="let val of values; let i = index"
    class="two-radio-buttons-label"
  >
    <div class="two-radio-buttons-button"></div>
    <input
      #input
      type="radio"
      name="radioGroup"
      [value]="val"
      [checked]="val === value"
      (change)="onChange($event, input.value)"
      [disabled]="disabled"
    />{{displayTexts[i]}}
  </label>
</div>
