import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'ems-base-dropdown',
  templateUrl: './base-dropdown.component.html',
  styleUrls: ['./base-dropdown.component.scss'],
  animations: [
    trigger('dropdown', [
      state('open', style({ height: 'auto' })),
      state('closed', style({ height: 0 })),
      transition('open => closed', [animate('.5s ease')]),
      transition('closed => open', [animate('0.25s ease')]),
    ]),
  ],
})
export class BaseDropdownComponent implements OnInit {
  /**
   * Text to render on the dropdown button
   */
  @Input() dropdownLabel = 'Dropdown';

  /**
   * Text to render on the dropdown button
   */
  @Input() dropdownWidth = 'Dropdown';

  /**
   * State of the dropdown. Set to active to show dropdown content.
   */
  dropdownIsActive = false;

  /**
   * dropdownButtonVariant that toggles between brand and default states when active
   */
  dropdownButtonVariant = '';

  triggerType = 'secondary';

  triggerClasses() {
    return {
      'is-primary': this.triggerType === 'primary',
      'is-secondary': this.triggerType === 'secondary',
      'is-tertiary': this.triggerType === 'tertiary',
      'is-tab': this.triggerType === 'tab',
      'is-brand': this.dropdownButtonVariant === 'brand',
      'is-inverted': this.dropdownButtonVariant === 'inverted',
      'is-success': this.dropdownButtonVariant === 'success',
      'is-destructive': this.dropdownButtonVariant === 'destructive',
    };
  }

  // For Static width option
  setWidth(w) {
    return { width: w + 'px' };
  }

  constructor() {}

  ngOnInit() {}

  // Dropdown State
  toggleDropdown() {
    this.dropdownIsActive = !this.dropdownIsActive;
    if (this.dropdownIsActive) {
      this.dropdownButtonVariant = 'brand';
    } else {
      this.dropdownButtonVariant = '';
    }
  }
}
