<div class="proxy-chooser" *ngIf="!proxyUser">
  <button
    class="proxy-chooser__item"
    *ngFor="let user of proxies"
    (click)="onChooseProxy(user.id)"
  >
    <ems-user-summary
      [id]="user.id"
      [firstName]="user.name_first"
      [lastName]="user.name_last"
      [employeeNumber]="user.employee_number"
    >
    </ems-user-summary>
  </button>
  <div class="proxy-chooser__item empty" *ngIf="proxies?.length === 0">
    <div class="proxy-chooser__none">You are not set up as a proxy.</div>
  </div>
</div>

<div class="proxy-chooser" *ngIf="currentUser && proxyUser">
  <button class="proxy-chooser__item" (click)="onRemoveProxy()">
    <ems-user-summary
      [id]="currentUser?.id"
      [firstName]="currentUser?.firstName"
      [lastName]="currentUser?.lastName"
      [employeeNumber]="currentUser?.employeeNumber"
      [proxy]="true"
    >
    </ems-user-summary>
  </button>
</div>
