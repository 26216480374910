<ems-base-modal [config]="modalInput$ | async" (dismiss)="onDismiss($event)">
  <ems-base-button-group modal-actions class="new-mileage-actions">
    <ems-base-button
      label="Save & Close"
      buttonType="primary"
      variant="brand"
      (click)="saveAndClose()"
    ></ems-base-button>
    <ems-base-button
      (click)="onNewMileageSubmit()"
      label="Submit"
      buttonType="primary"
      variant="success"
      class="new-mileage-submit"
    >
    </ems-base-button>
  </ems-base-button-group>
  <ems-mileage-expense-form-new
    [dismiss]="dismiss$ | async"
    [jobCodes]="jobCodes$ | async"
    [favorites]="favoriteJobCodes$ | async"
    [reimbursement]="newMileageReimbursement$ | async"
    [salesforceCases]="salesforceCases$ | async"
    [favoriteSalesforceCases]="favoriteSalesforceCases$ | async"
    [autoSavedId]="autoSavedExpenseId$ | async"
    [proxy]="proxy$ | async"
    [submitted]="submitted$ | async"
    [mileageExpenseType]="mileageExpenseType$ | async"
    (newMileageFormChange)="onNewMileageFormChange($event)"
    (newMileageStatusChange)="onNewMileageFormStatusChange($event)"
    (getNewReimbursement)="onGetNewMileageReimbursement($event)"
    (favoriteJobCode)="onFavoriteJobCode($event)"
    (favoriteSalesforce)="onFavoriteSalesforceCase($event)"
  ></ems-mileage-expense-form-new>
</ems-base-modal>
