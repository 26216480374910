import { Activity, Expense } from './models';
import {
  Submitter
} from "@libs/shared/util-core/src/lib/models/expense.model";
const date = new Date();

export const expenses: Expense[] = [
  {
    id: 1,
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    status: 'draft',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    transactionDate: date.getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: (<Submitter>{
      primaryInfo: {
        id: 4,
      },
    }),
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
  {
    id: 2,
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    status: 'parent',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    transactionDate: date.getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 4,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
  {
    id: 3,
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    transactionDate: date.getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 6,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
];

export const selectedExpense = {
  id: 1,
  createdAt: date.getTime(),
  updatedAt: date.getTime(),
  status: 'draft',
  description: 'string',
  cardVerified: false,
  address: '123 imagine drive',
  vendor: 'rainbow pats',
  token: '',
  amount: 2000,
  transactionDate: date.getTime(),
  information: 'none',
  isBillable: true,
  approvalLevel: 1,
  paymentType: 'cash',
  jobCode: 1,
  report: 1,
  type: {
    id: 5,
    name: 'lunch',
    description: '',
    expectedFields: '',
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
  },
  creditCardId: null,
  submitter: 4,
  approvedBy: 2,
  voidAt: 'string',
  fraudulent: false,
  notifications: [],
  image: 'receipt.jpg',
};

export const nonTypedExpenses = [
  {
    createdAt: 1603743744830,
    updatedAt: 1603743757654,
    id: 3,
    status: 'pending',
    description: '',
    cardVerified: false,
    address: '3500 h 6th st ste 325',
    vendor: 'etude lounge',
    token: 'PvfPNHmdNocJIkp3',
    amount: '70000',
    pendingOCR: false,
    transactionDate: '2017-04-21T00:00:00.000Z',
    submissionDate: '2020-10-26T00:00:00.000Z',
    isBillable: false,
    paymentType: 'cash',
    jobCode: 1,
    salesforceId: 0,
    submitter: {
      firstName: 'Aladdin',
      lastName: 'Ababwa',
      primaryInfo: {
        extension: '4444',
        id: 4,
        personal_email: 'princeali@sis.test',
        phone_number: '111-111-1111',
        work_email: 'aladdin@sis.test',
      },
    },
    approvedBy: 0,
    approvalDate: null,
    trashedAt: null,
    voidAt: null,
    fraudulent: false,
    extraInfo: null,
    report: {
      createdAt: 1603743757216,
      updatedAt: 1603743757216,
      id: 2,
      name: 'Expense #3',
      description: '',
      isResubmitted: false,
    },
    type: 6,
    creditCardId: null,
    parent: null,
    mediumImage:
      'https://sis-upstate.s3.us-west-2.amazonaws.com/22231/receipt/',
    thumbnail:
      'https://sis-upstate.s3.us-west-2.amazonaws.com/22231/receipt/',
    image:
      'https://sis-upstate.s3.us-west-2.amazonaws.com/22231/receipt/',
  },
  {
    createdAt: 1604687604011,
    updatedAt: 1604687604011,
    id: 24,
    status: 'draft',
    description: '',
    cardVerified: true,
    address: '',
    vendor: 'axtel antes alestra',
    token: '',
    amount: '2667500',
    pendingOCR: false,
    transactionDate: '2019-01-01T00:00:00.000Z',
    submissionDate: '2020-11-06T00:00:00.000Z',
    isBillable: true,
    paymentType: 'company',
    jobCode: 0,
    salesforceId: 0,
    submitter: {
      firstName: 'Jasmine',
      lastName: 'Ababwa',
      primaryInfo: {
        extension: '2222',
        id: 2,
        personal_email: 'liljasmine@sis.test',
        phone_number: '111-111-1111',
        work_email: 'jasmine@sis.test',
      },
    },
    approvedBy: 0,
    approvalDate: null,
    trashedAt: null,
    voidAt: null,
    fraudulent: false,
    extraInfo: {},
    report: null,
    type: null,
    creditCardId: null,
    parent: null,
  },
];

export const expenseEntities = {};
expenses.forEach((e) => {
  expenseEntities[e.id] = {
    ...e,
  };
});

export const expenseTypes = [
  {
    createdAt: 1604680858599,
    updatedAt: 1604680858599,
    id: 1,
    name: 'Advertising',
    description: 'Getting Security Industry Specialists name out there',
    expectedFields: [],
  },
  {
    createdAt: 1604680858599,
    updatedAt: 1604680858599,
    id: 2,
    name: 'Airfare',
    description: 'Spend to fly via airplane.',
    expectedFields: {
      travelDate: 'TravelDate',
      origin: 'CityOfOrigin',
      destination: 'CityOfDestination',
      passengerName: 'PassengerName',
      ticketNumber: 'TicketNum',
      egenciaItineraryNumber: 'TravelAgencyCode',
      vendorName: 'CardAcceptorName',
      venderAddress: 'CardAcceptorStreetAddress',
      vendorCity: 'CardAcceptorCity',
      vendorCountry: 'CardAcceptorCountryCode',
      vendorPhone: 'CardAcceptorTelephoneNum',
      vendorState: 'CardAcceptorStateProvince',
      vendorZip: 'CardAcceptorLocationPostalCode',
    },
  },
  {
    createdAt: 1604680858599,
    updatedAt: 1604680858599,
    id: 3,
    name: 'Company Events',
    description: 'Spend for events for employees',
    expectedFields: '[]',
  },
  {
    id: 5,
    name: 'lunch',
    description: '',
    expectedFields: '',
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
  },
  {
    id: 10,
    name: 'mileage',
    description: '',
    expectedFields: '',
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
  },
];

export const expenseTypeEntities = {};
expenseTypes.forEach((e) => {
  expenseTypeEntities[e.id] = {
    ...e,
  };
});

export const expenseStatuses = [
  {
    display: 'Draft',
    value: 'draft',
  },
  {
    display: 'Rejected',
    value: 'rejected',
  },
  {
    display: 'Verifying',
    value: 'awaiting verification',
  },
  {
    display: 'Failed to Verify',
    value: 'failed to verify',
  },
];

export const activities: Activity[] = [
  {
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    id: 1,
    message: 'submitted',
    author: {
      id: 4,
      firstName: 'Aladdin',
      lastName: 'Ababwa',
    },
    action: 'submitted',
    expense: 1,
    jobCode: 1,
    expenseFor: {
      id: 4,
      firstName: 'Aladdin',
      lastName: 'Ababwa',
    },
  },
  {
    createdAt: date.getTime() - 10000000,
    updatedAt: date.getTime() - 10000000,
    id: 2,
    message: 'resubmitted',
    author: {
      id: 4,
      firstName: 'Aladdin',
      lastName: 'Ababwa',
    },
    action: 'submitted',
    expense: 1,
    jobCode: 1,
    expenseFor: {
      id: 4,
      firstName: 'Aladdin',
      lastName: 'Ababwa',
    },
  },
];

export const salesforceCases = [
  {
    billing_status: 'ready for invoicing',
    case_number: 'CC-000111111',
    id: 1,
    name: 'Sultans Palace, Guard, East Gate, Agrabah 3/20 - 4/20',
    status: 'new',
  },
  {
    billing_status: 'ready for invoicing',
    case_number: 'CC-000111111',
    id: 2,
    name: 'Sultans Palace, Guard, East Gate, Agrabah 3/20 - 4/20',
    status: 'new',
  },
  {
    billing_status: 'partially billed',
    case_number: 'CC-000111112',
    id: 3,
    name: 'Sultans Palace, Chef, Kitchen, Agrabah 3/20 - 4/20',
    status: 'new',
  },
];

export const salesforceEntities = {};
salesforceCases.forEach((s) => {
  salesforceEntities[s.id] = {
    ...s,
  };
});

export const jobCodes = [
  {
    billable_percentage: null,
    city: 'Agrabah',
    client_id: 1,
    country: 'Agrabah',
    lunch_hours_after_start: 5,
    notification_email: 'abu@sis.us',
    state: 'WA',
    time_zone: 'America/Los_Angeles',
    zip_code: '98109',
    parent_id: null,
    status: 'active',
    billable: 'true',
    division_id: 3,
    code: '10000',
    department_id: 1,
    description: 'Agrabah Castle',
    id: 1,
    lunch: 45,
    name: 'active',
    expense_approver: 4,
    master_expense_approver: 2,
  },
  {
    billable_percentage: null,
    city: 'Atlantica',
    client_id: 2,
    country: 'Under the sea',
    lunch_hours_after_start: 5,
    notification_email: 'flounder@sis.us',
    state: 'NY',
    time_zone: 'America/New_York',
    zip_code: '10001',
    parent_id: null,
    status: 'active',
    billable: 'true',
    division_id: 5,
    code: '20000',
    department_id: 4,
    description: 'Kingdom of Atlantica',
    id: 2,
    lunch: 30,
    name: 'active',
    expense_approver: 4,
    master_expense_approver: 2,
  },
];

export const jobCodeEntities = {};
jobCodes.forEach((j) => {
  jobCodeEntities[j.id] = {
    ...j,
  };
});

export const personEntities = {
  4: {
    id: 4,
    name_first: 'Aladdin',
    name_last: 'Ababwa',
    personal_email: 'princeali@sis.test',
    work_email: 'aladdin@sis.test',
    employee_number: '22231',
    user_id: 141,
  },
};

export const mileageDraft = {
  id: 1,
  createdAt: date.getTime(),
  updatedAt: date.getTime(),
  status: 'draft',
  description: 'string',
  cardVerified: false,
  address: '123 imagine drive',
  vendor: 'rainbow pats',
  token: '',
  amount: 2000,
  transactionDate: date.getTime(),
  information: 'none',
  isBillable: true,
  approvalLevel: 1,
  paymentType: 'cash',
  jobCode: 1,
  report: 1,
  type: 10,
  creditCardId: null,
  submitter: 4,
  approvedBy: 2,
  voidAt: 'string',
  fraudulent: false,
  notifications: [],
  extraInfo: {
    start: 400,
    end: 415,
  },
};

export const mileageEntities = {
  1: {
    ...mileageDraft,
  },
};

export const mileageExpenseTypeEntities = {
  10: {
    id: 10,
    name: 'mileage',
    description: '',
    expectedFields: '',
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
  },
};

export const approvals: Expense[] = [
  {
    id: 1,
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    transactionDate: date.getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 4,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
  {
    id: 2,
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    transactionDate: date.getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 4,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
  {
    id: 3,
    createdAt: date.getTime(),
    updatedAt: date.getTime(),
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    transactionDate: date.getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 6,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
];

export const overDueApprovals: Expense[] = [
  {
    id: 1,
    createdAt: date.getTime() - 35 * 24 * 60 * 60 * 1000,
    updatedAt: date.getTime() - 35 * 24 * 60 * 60 * 1000,
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    submissionDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime(),
    transactionDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 4,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
  {
    id: 2,
    createdAt: date.getTime() - 120 * 24 * 60 * 60 * 1000,
    updatedAt: date.getTime() - 120 * 24 * 60 * 60 * 1000,
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    submissionDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime(),
    transactionDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 4,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
  {
    id: 3,
    createdAt: date.getTime() - 35 * 24 * 60 * 60 * 1000,
    updatedAt: date.getTime() - 35 * 24 * 60 * 60 * 1000,
    status: 'pending',
    description: 'string',
    cardVerified: false,
    address: '123 imagine drive',
    vendor: 'rainbow pats',
    token: '',
    amount: 2000,
    submissionDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime(),
    transactionDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime(),
    information: 'none',
    isBillable: true,
    approvalLevel: 1,
    paymentType: 'cash',
    jobCode: 1,
    report: 1,
    type: 5,
    creditCardId: null,
    submitter: 6,
    approvedBy: 2,
    voidAt: 'string',
    fraudulent: false,
    notifications: [],
  },
];

export const favoriteSalesforceCases = [
  {
    createdAt: 1605552878620,
    updatedAt: 1605552878620,
    id: 2,
    salesforceId: 3,
    contactId: 2,
  },
];

export const favoriteJobCodes = [
  {
    billable_percentage: null,
    city: 'Agrabah',
    client_id: 1,
    country: 'Agrabah',
    lunch_hours_after_start: 5,
    notification_email: 'abu@sis.us',
    state: 'WA',
    time_zone: 'America/Los_Angeles',
    zip_code: '98109',
    parent_id: null,
    status: 'active',
    billable: 'true',
    division_id: 3,
    code: '10000',
    department_id: 1,
    description: 'Agrabah Castle',
    id: 1,
    lunch: 45,
    name: 'active',
    expense_approver: 4,
    master_expense_approver: 2,
  },
];

interface MockFile {
  name: string;
  mimeType: string;
  body: string;
}
const createFileFromMockFile = (file: MockFile): File => {
  const blob = new Blob([file.body], {
    type: file.mimeType,
  }) as any;
  blob['lastModifiedDate'] = new Date();
  blob['name'] = file.name;
  return blob as File;
};
export const image = createFileFromMockFile({
  body: 'test',
  mimeType: 'image/jpeg',
  name: 'test.jpg',
});

export const usersFeed = [
  {
    active: true,
    createdAt: 1607432966460,
    email: 'jafar@sis.test',
    id: 1,
    name_first: 'Jafar',
    name_last: 'Jafa',
    updatedAt: 1607432966460,
    user: 3,
  },
  {
    active: true,
    createdAt: 1607432998327,
    email: 'aladdin@sis.test',
    id: 2,
    name_first: 'Aladdin',
    name_last: 'Ababwa',
    updatedAt: 1607432998327,
    user: 4,
  },
];

export const allUsers = [
  {
    employee_number: '22231',
    id: 4,
    name_first: 'Aladdin',
    name_last: 'Ababwa',
    personal_email: 'princeali@sis.test',
    user_id: 141,
    work_email: 'aladdin@sis.test',
  },
  {
    employee_number: '22222',
    id: 2,
    name_first: 'Jasmine',
    name_last: 'Ababwa',
    personal_email: 'liljasmine@sis.test',
    user_id: 143,
    work_email: 'jasmine@sis.test',
  },
  {
    employee_number: '22225',
    id: 5,
    name_first: 'Simba',
    name_last: 'Lion',
    personal_email: 'lilsimba@sis.test',
    user_id: 135,
    work_email: 'simba@sis.test',
  },
];

export const favoriteUsers = [
  {
    employee_number: '22222',
    id: 2,
    name_first: 'Jasmine',
    name_last: 'Ababwa',
    personal_email: 'liljasmine@sis.test',
    user_id: 143,
    work_email: 'jasmine@sis.test',
  },
];
