import { createSelector } from '@ngrx/store';
import * as fromActivity from '../reducers/activity.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectActivityState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.activity
);

export const {
  selectIds: selectActivityIds,
  selectEntities: selectActivityEntities,
  selectAll: selectAllActivity,
} = fromActivity.adapter.getSelectors(selectActivityState);
