<ems-expense-form-layout>
  <div [formGroup]="form" content class="content">
    <ng-container *ngIf="(modalType | async) === 'expense-new-modal'">
      <sis-credit-card-holder-alert
        *ngIf="creditCardUser$ | async"
      ></sis-credit-card-holder-alert>
    </ng-container>
    <submitting-company-cc-expenses-alert
      *ngIf="(modalType | async) === 'expense-new-modal-company-cc'"
    ></submitting-company-cc-expenses-alert>
    <sis-expense-mismatch-alert
      *ngIf="isOcrMismatch$ | async"
    ></sis-expense-mismatch-alert>
    <ems-base-stack>
      <div class="expense-form-new">
        <!-- left column -->
        <div>
          <!-- Vendor -->
          <sis-form-control
            label="Vendor"
            inputId="filter-vendor"
            [class.input-error]="vendorIsRequired"
          >
            <input
              id="filter-vendor"
              formControlName="vendor"
              type="text"
              placeholder="Ex. Trader Joe's"
              [value]=""
              [class.input-error]="vendorIsRequired"
              [maxlength]="maxLength"
            />
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="vendorIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Vendor is required.
          </ems-base-helper-text>

          <!-- Address -->
          <sis-form-control
            class="bottom-margin"
            label="Address (optional)"
            inputId="filter-address"
          >
            <textarea
              id="filter-address"
              formControlName="address"
              type="text"
              class="form-control-textarea"
              placeholder="Ex. 1916 Anne Ave N. Seattle, WA 98109"
              [maxlength]="maxLength"
            ></textarea>
          </sis-form-control>

          <!-- Transaction Date -->
          <sis-form-control
            label="Transaction Date"
            inputId="new-expense-transaction-date"
          >
            <ems-datepicker
              inputId="new-expense-transaction-date"
              formControlName="transactionDate"
              [maxDate]="maxDate"
            ></ems-datepicker>
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="dateIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Date is required.
          </ems-base-helper-text>

          <!-- Amount -->
          <sis-form-control
            label="Amount"
            inputId="edit-expense-amount"
            [class.input-error]="amountInputError"
          >
            <input
              id="edit-expense-amount"
              formControlName="amount"
              placeholder="Ex. 11.97"
              type="text"
              (click)="$event.target.select()"
            />
          </sis-form-control>
          <ems-base-helper-text
            *ngIf="
              amountIsInvalid ||
              amountIsRequired ||
              amountRequiredForItemization ||
              maxAmountExceeded
            "
            [style.display]="
              amountIsInvalid ||
              amountIsRequired ||
              amountRequiredForItemization ||
              maxAmountExceeded
                ? 'block'
                : 'none'
            "
            variation="negative"
          >
            {{
              amountIsInvalid
                ? 'Please enter a valid amount.'
                : amountIsRequired
                ? 'Amount is required.'
                : amountRequiredForItemization
                ? ' Amount is required to itemize expense.'
                : maxAmountExceeded
                ? 'Amount must be less than 10 million dollars.'
                : 'No Error'
            }}
          </ems-base-helper-text>

          <!-- Billable -->
          <sis-form-control label="Billable" *ngIf="showBillable">
            <sis-two-radio-buttons
              [displayTexts]="['Yes', 'No']"
              [values]="[true, false]"
              formControlName="isBillable"
            ></sis-two-radio-buttons>
          </sis-form-control>

          <!-- Notes -->
          <div class="ems-field add-note">
            <ems-base-inline
              *ngIf="!showNote"
              class="note-toggle"
              (click)="onToggleNote()"
            >
              <ems-base-icon
                iconName="add-small"
                class="note-icon add"
              ></ems-base-icon>
              <span class="ems-label ems-label--expense-note">Add Note</span>
            </ems-base-inline>
            <ems-base-inline
              *ngIf="showNote"
              class="note-toggle"
              (click)="onToggleNote()"
            >
              <ems-base-icon
                iconName="add-small"
                class="note-icon remove"
              ></ems-base-icon>
              <span class="ems-label ems-label--expense-note">Note</span>
            </ems-base-inline>
            <div class="ems-field" *ngIf="showNote">
              <div class="ems-control">
                <textarea
                  formControlName="message"
                  placeholder=""
                  class="ems-textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- right column -->
        <div>
          <!-- Expense Type -->
          <sis-form-control
            label="Expense Type"
            inputId="filter-expense-types"
            [class.input-error]="expenseTypeIsRequired"
          >
            <select
              id="filter-expense-types"
              class="form-control-select"
              formControlName="type"
            >
              <option
                *ngFor="let type of expenseTypes"
                [value]="type?.id"
                [title]="type?.description"
              >
                {{ type?.name }}
              </option>
            </select>
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="expenseTypeIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Expense Type is required
          </ems-base-helper-text>

          <!-- Description -->
          <sis-form-control
            label="Description"
            inputId="filter-description"
            [class.input-error]="descriptionIsRequired"
          >
            <textarea
              id="filter-description"
              formControlName="description"
              type="text"
              class="form-control-textarea"
              placeholder="Example: Taxi from airport to condo."
              [maxlength]="maxLength"
            ></textarea>
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="descriptionIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Description is required
          </ems-base-helper-text>

          <!-- Job Code Autocomplete -->
          <div class="job-code">
            <ems-typeahead
              #jobCodeEl
              label="Job Code"
              type="jobCode"
              [items]="jobCodes"
              [favorites]="favoriteJobCodes"
              formControlName="jobCode"
              (favorite)="onFavoriteJobCode($event)"
              [isInvalid]="jobCodeIsRequired"
              [canClear]="true"
            ></ems-typeahead>
            <ems-base-helper-text
              [style.visibility]="jobCodeIsRequired ? 'visible' : 'hidden'"
              variation="negative"
            >
              Job Code is required
            </ems-base-helper-text>
          </div>

          <!-- Fraud -->
          <div
            *ngIf="showFraud"
            [class.error]="fraudulentInvalid"
            class="fraudulent-container"
          >
            <label class="ems-label fraudulent-label">Fraud</label>
            <label style="display: contents">
              <div class="fraudulent-checkbox ems-checkbox">
                <input
                  type="checkbox"
                  (change)="checkFraud($event)"
                  formControlName="fraudulent"
                />
                <div class="ems-checkbox__indicator"></div>
              </div>
              <span class="fraudulent-text">
                Notified Credit Card Company and/or Vendor?
                <br />
                Credit card customer service number is on the back of your
                credit card.
              </span>
            </label>
          </div>

          <!-- Custom Case -->
          <div class="ems-field custom-case">
            <ems-typeahead
              #salesforceEl
              label="Custom Case"
              optional="true"
              type="salesforceCase"
              [items]="salesforceCases"
              [favorites]="favoriteSalesforceCases"
              formControlName="salesforceId"
              (favorite)="onFavoriteSalesforceCase($event)"
              [canClear]="true"
              (uploaded)="onReceiptUploaded($event)"
            ></ems-typeahead>
          </div>
        </div>
      </div>
    </ems-base-stack>
  </div>
  <receipt-container
    receipt 
    (fileSelected)="onFileSelected($event)"
  ></receipt-container>
</ems-expense-form-layout>
