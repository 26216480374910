import { Activity, Expense } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const submit = createAction(
  '[Expense - New] submit',
  props<{ expense: Partial<Expense>, file: File, rotationAngle: number }>()
);

export const submitOne = createAction('[Expense - New] submitOne');

export const submitComplete = createAction(
  '[Expense - New] submitComplete',
  props<{ expense: any }>()
);

export const submitError = createAction(
  '[Expense - New] submitError',
  props<{ error: string }>()
);

export const cancel = createAction(
  '[Expense - New] cancel',
  props<{ expense: any }>()
);

export const cancelComplete = createAction('[Expense - New] cancelComplete');

export const cancelError = createAction(
  '[Expense - New] cancelError',
  props<{ error: string }>()
);

export const takePhoto = createAction('[Expense - New] takePhoto');

export const takePhotoComplete = createAction(
  '[Expense - New] takePhotoComplete',
  props<{ uri: string }>()
);

export const takePhotoError = createAction(
  '[Expense - New] takePhotoError',
  props<{ error: string }>()
);

export const savePhoto = createAction(
  '[Expense - New] savePhoto',
  props<{ uri: string }>()
);

export const savePhotoComplete = createAction(
  '[Expense - New] savePhotoComplete',
  props<{ tokenData: any }>()
);

export const savePhotoError = createAction(
  '[Expense - New] savePhotoError',
  props<{ error: string }>()
);

export const getExpenseData = createAction(
  '[Expense - New] getExpenseData',
  props<{ token: any }>()
);

export const getExpenseDataComplete = createAction(
  '[Expense - New] getExpenseDataComplete',
  props<{ expenseData: Expense }>()
);

export const getExpenseDataError = createAction(
  '[Expense - New] getExpenseDataError',
  props<{ error: string }>()
);

export const saveExpenseData = createAction(
  '[Expense - New] saveExpenseData',
  props<{ expenseData: any }>()
);

export const selectPhotoLibrary = createAction(
  '[Expense - New] selectPhotoLibrary'
);

export const selectPhotoLibraryComplete = createAction(
  '[Expense - New] selectPhotoLibraryComplete',
  props<{ uri: string }>()
);

export const selectPhotoLibraryError = createAction(
  '[Expense - New] selectPhotoLibraryError',
  props<{ error: string }>()
);

export const selectExpenseType = createAction(
  '[Expense - New] selectExpenseType'
);

export const selectJobCode = createAction('[Expense - New] selectJobCode');

export const saveScrollPosition = createAction(
  '[Expense - New] saveScrollPosition',
  props<{ position: number }>()
);

export const resetForm = createAction('[Expense - New] resetForm');

export const resetFormComplete = createAction(
  '[Expense - New] resetFormComplete'
);

export const ocrOnly = createAction(
  '[Expense - New] ocrOnly',
  props<{ image: File }>()
);

export const ocrOnlyComplete = createAction(
  '[Expense - New] ocrOnlyComplete',
  props<{ expense: Expense }>()
);

export const clearReceiptData = createAction('[Expense - New] clearReceiptData');

export const upload = createAction(
  '[Expense - New] upload',
  props<{ image: File }>()
);

export const uploadComplete = createAction(
  '[Expense - New] uploadComplete',
  props<{ expense: any }>()
);

export const uploadError = createAction(
  '[Expense - New] uploadError',
  props<{ error: string }>()
);

export const favorite = createAction(
  '[Expense - New] favorite',
  props<{ jobCode: any }>()
);

export const favoriteComplete = createAction(
  '[Expense - New] favoriteComplete',
  props<{ expense: any }>()
);

export const favoriteError = createAction(
  '[Expense - New] favoriteError',
  props<{ error: string }>()
);

export const autoSave = createAction(
  '[Expense - New] autoSave',
  props<{ expense: Partial<Expense> }>()
);

export const createNewExpense = createAction(
  '[Expense - New] Create New Expense',
  props<{ expense: Partial<Expense>, file: File, rotationAngle: number }>()
);

export const createNewExpenseRequest = createAction(
  '[Expense - New] Create New Expense Request.',
  props<{ expense: Partial<Expense>, file: File, rotationAngle: number }>()
);

export const createNewExpenseComplete = createAction(
  '[Expense - New] Create New Expense Finished.',
  props<{ expense: Expense, activity?: Activity }>()
);

export const createNewExpenseError = createAction(
  '[Expense - New] Error when Creating an Expense.',
  props<{ error: string }>()
);

export const trashAutoSaved = createAction('[Expense - New] trashAutoSaved');

export const trashAutoSavedComplete = createAction(
  '[Expense - New] trashAutoSavedComplete',
  props<{ expense: Expense }>()
);

export const trashAutoSavedError = createAction(
  '[Expense - New] trashAutoSavedError',
  props<{ error: string }>()
);

export const saveDraft = createAction('[Expense - New] saveDraft');

export const saveDraftComplete = createAction(
  '[Expense - New] saveDraftComplete',
  props<{ expense: any }>()
);

export const saveDraftError = createAction(
  '[Expense - New] saveDraftError',
  props<{ error: string }>()
);

export const autoSaveWithNote = createAction(
  '[Expense - New] autoSaveWithNote',
  props<{ expense: Partial<Expense> }>()
);

export const autoSaveWithNoteComplete = createAction(
  '[Expense - New] autoSaveWithNoteComplete',
  props<{ expense: any }>()
);

export const autoSaveWithNoteError = createAction(
  '[Expense - New] autoSaveWithNoteError',
  props<{ error: string }>()
);

export const autoSaveWithoutNote = createAction(
  '[Expense - New] autoSaveWithoutNote',
  props<{ expense: Partial<Expense> }>()
);

export const autoSaveWithoutNoteComplete = createAction(
  '[Expense - New] autoSaveWithoutNoteComplete',
  props<{ expense: any }>()
);

export const autoSaveWithoutNoteError = createAction(
  '[Expense - New] autoSaveWithoutNoteError',
  props<{ error: string }>()
);

export const itemize = createAction(
  '[Expense - New] itemize',
  props<{ amounts: any }>()
);

export const itemizeComplete = createAction(
  '[Expense - New] itemizeComplete',
  props<{ expenses: Expense[] }>()
);

export const itemizeError = createAction(
  '[Expense - New] itemizeError',
  props<{ error: string }>()
);

export const itemizeConfirm = createAction('[Expense - New] itemizeConfirm');

export const unableToItemize = createAction('[Expense - New] unableToItemize');

export const unableToItemizeReset = createAction(
  '[Expense - New] unableToItemizeReset'
);

export const cancelItemization = createAction(
  '[Expense - New] cancelItemization'
);
