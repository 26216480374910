<div
  class="ems-typeahead"
  [ngClass]="classNames()"
  [class.disabled]="isDisabled"
>
  <label class="ems-label" (click)="ngSelect.open()">
    {{ label }}&nbsp;<em *ngIf="optional">(optional)</em>
  </label>

  <div class="input-container">
    <ems-base-icon
      iconName="magnifying_glass"
      class="ems-control__icon is-left search-icon"
      *ngIf="!ngSelect?.focused && !control.value"
    ></ems-base-icon>

    <ng-select
      #ngSelect
      class="ems-typeahead__select"
      [items]="items"
      bindValue="id"
      dropdownPosition="bottom"
      [virtualScroll]="true"
      groupBy="groupName"
      [formControl]="control"
      [clearable]="false"
      [class.disabled]="isDisabled"
      [hideSelected]="true"
      (open)="onOpen()"
      (focus)="onFocus()"
    >
      <ng-template ng-optgroup-tmp let-item="item">
        {{ item.groupName }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div class="ng-value-label">
          <span>{{
            type === 'salesforceCase'
              ? item?.case_number
              : type === 'person'
              ? item?.employee_number
              : item?.code
          }}</span>
          <span>{{ item?.name }}</span>
        </div>
      </ng-template>

      <ng-template ng-label-tmp let-item="item">
        <div class="ng-value-label">
          <span>{{
            type === 'salesforceCase'
              ? item?.case_number
              : type === 'person'
              ? item?.employee_number
              : item?.code
          }}</span>
          <span>{{ item?.name }}</span>
        </div>
      </ng-template>
    </ng-select>

      <button
        *ngIf="canClear && showClear"
        class="ems-typeahead__clear"
        (click)="onClear()"
        [class.disabled]="isDisabled"
      >
        <ems-base-icon iconName="close"></ems-base-icon>
      </button>
  </div>

  <button
    *ngIf="favoritable"
    [ngSwitch]="favoritedState$ | async"
    class="ems-typeahead__favorite"
    [class.disabled]="isDisabled"
    (click)="onFavorite()"
  >
    <ems-base-icon
      *ngSwitchCase="'filled'"
      iconName="star-filled"
    ></ems-base-icon>
    <ems-base-icon
      *ngSwitchCase="'outline'"
      iconName="star-outline"
    ></ems-base-icon>
    <ems-base-icon *ngSwitchDefault iconName="star-empty"></ems-base-icon>
  </button>
</div>
