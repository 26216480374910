import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppConfigService } from '@sec-spec/lib-ng-app-config';
import { ModalModule } from '@sec-spec/lib-ng-modal';
import packageJson from '../../../../package.json';

const environment: Environment = structuredClone(window.env);
if(!environment.version) {
  environment.version = packageJson.version;
}

import {
  AuthGuardService,
  AuthHttpService,
  AuthService,
  HttpConfigInterceptor,
  OauthModule,
  UserResolveService,
} from '@sec-spec/lib-ng-oauth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ProxyInterceptor } from './services/proxy/proxy.interceptor';
import { SnackbarModule } from 'ngx-snackbar';
import { AlertService } from "@src/app/services/alert.service";
import { Environment } from '@libs/interfaces';

/** Get callback function to load runtime application config data.
 * @param appConfig Service that loads the config data.
 * @returns Callback function.
 */
export function appInitializerCallback(appConfig: AppConfigService) {
  return async () => {
    return appConfig.loadAppConfig(environment.baseHref, {
      baseUrl: environment.sisURL
    });
  };
}

const imports = [
  BrowserModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  AppRoutingModule,
  StoreModule.forRoot(
    {},
    {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
        strictActionSerializability: false,
        strictActionTypeUniqueness: true,
      },
    }
  ),
  EffectsModule.forRoot([]),
  OauthModule,
  CoreModule,
  ModalModule,
  SnackbarModule.forRoot(),
];

if(!environment.production && environment.storeDevTools) {
  imports.push(
    StoreDevtoolsModule.instrument({
      maxAge:  environment.storeDevTools.maxAge,
      logOnly: environment.storeDevTools.logOnly,
    })
  );
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports,
  providers: [
    AppConfigService,
    AuthGuardService,
    AuthService,
    AuthHttpService,
    UserResolveService,
    AlertService,
    {
      provide: APP_BASE_HREF,
      useValue: environment.baseHref,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: ProxyInterceptor,
    },
    {
      deps: [AppConfigService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: appInitializerCallback,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

