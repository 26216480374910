import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ems-expense-fraud-approver-warn-dialog',
  templateUrl: './expense-fraud-approver-warn-dialog.component.html',
  styleUrls: ['./expense-fraud-approver-warn-dialog.component.scss'],
})
export class ExpenseFraudApproverWarnDialogComponent implements OnInit {
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    return;
  }

  onContinue() {
    this.continue.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
