<details
  class="base-accordion"
  [class.feed-accordion]="feedLayout"
  [class.last-row]="lastRow"
>
  <summary [class.has-content]="description">
    <div class="base-accordion__dropdown">
      <div class="base-accordion__title">{{ title }}</div>
      <div class="feed-accordion description">{{ description }}</div>
      <div class="base-accordion__dropdown-marker" *ngIf="description">
        <ems-base-icon iconName="chevron-right"></ems-base-icon>
      </div>
    </div>
  </summary>
  <div class="base-accordion__body" *ngIf="description">
    <ng-content select="[accordion-body]"></ng-content>
  </div>
</details>
