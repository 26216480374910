import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Expense, OnChange } from '@ems-gui/shared/util-core';
import { AuthService } from '@sec-spec/lib-ng-oauth';
import { DropzoneConfigInterface } from 'nxt-dropzone-wrapper';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ApprovalActions,
  draftExpensesLoading,
  State
} from '@ems-gui/expense/util-web-ngrx';

import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AlertService } from '@src/app/services/alert.service';
import { skip, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { FormManagerService } from '@src/app/services/form-manager.service';
import { Subject } from 'rxjs';

interface DropzoneDirective {
  addFile(file: File): void;
}

interface ReceiptResponse {
  code: number;
  duplicate: boolean;
  duplicateToken: string
  expenseId: number
  message: string;
  status: string;
  status_code: number;
  success: boolean;
  token: string;
}

@Component({
  selector: 'ems-receipt-widget',
  templateUrl: './receipt-widget.component.html',
  styleUrls: ['./receipt-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReceiptWidgetComponent implements OnInit, AfterViewInit, OnChanges {
  @OnChange<boolean>(function(this: ReceiptWidgetComponent) {
    this.config.clickable = this.getDropzoneClickable();
  })
  @Input() v: boolean;
  @Input() public formId: string;

  @OnChange<string>(function (this: ReceiptWidgetComponent, uploadUrl) {
    if (uploadUrl && this.uploadUrl) {
      this.config = this.getDropzoneConfig({ url: this.uploadUrl });
    }
  })
  @Input() uploadUrl = 'none';

  @OnChange<string>(function (this: ReceiptWidgetComponent, uploadMethod) {
    if (uploadMethod && this.uploadMethod)
      this.config = this.getDropzoneConfig({
        method: this.uploadMethod,
      });
  })
  @Input() uploadMethod: string;
  public config: DropzoneConfigInterface;

  // Dropzone Status
  public status = 'upload';

  // // URL For the Receipt Image
  @Input() expense;
  @Input() params = {};
  @Input() expenseStatus;
  @Input() itemizationStatus = true;
  @Input() parent = null;
  // // Renders a subtitle, if appropriate
  @Input() subtitle = '';

  // // Flags the component as a Multi-Edit widget
  @Input() isMultiEdit = false;

  @Input() isDisabled = false;
  @Input() canUploadNew = false;
  @Input() submitted;
  @Input() receiptRequired;
  showRemoveButton = false;
  private isFormDirty = false;

  @OnChange<string>(function (this: ReceiptWidgetComponent, proxy) {
    if (proxy) {
      this.config = this.getDropzoneConfig({
        headers: {
          ...this.config.headers,
          'x-proxy-for': proxy.toString(),
        },
      });
    }
  })
  @Input() proxy = '';
  @Input() detailLayout = false;

  @Output() uploaded = new EventEmitter<any>();
  @Output() remove = new EventEmitter();
  @Output() updated = new EventEmitter<any>();
  @Output() rotateReceipt = new EventEmitter();
  @Output() fileSizeError = new EventEmitter();
  @ViewChild('receiptPreview', { static: false }) receiptPreview: ElementRef;
  @ViewChild('receiptPhoto', { static: false }) receiptPhoto: ElementRef;
  @ViewChild('zoomContainer', { static: false }) zoomContainer: ElementRef;
  @ViewChild('dropzone') private dropzone: ElementRef;

  public isPdf = false;
  public imageUrl = '';
  public uploadComplete = false;

  private getDropzoneConfig(config: Partial<DropzoneConfigInterface> = {}) {
    const clickable = this.getDropzoneClickable();
    return { ...this.config, clickable, ...config };
  }

  private getDropzoneClickable() {
    return this.imageUrl ? true : !this.isFormDirty;
  }

  constructor(
    private auth: AuthService,
    public apiService: ApiService,
    private sanitizer: DomSanitizer,
    private store$: Store<State>,
    private alertService: AlertService,
    private formManager: FormManagerService
  ) {}

  // Set Default Rotation

  public onUploadInit(): void {
    this.status = 'upload';
    this.uploadComplete = false;
    // this.initialUploadInProgress = false;
    this.store$.dispatch(ApprovalActions.isLoadingComplete());
  }

  /**
   * REFACTOR NEEDED:
   * WHY: args[1]
   */

  public onUploadError(args: any): void {
    if (args[1].expenseId && !args[1].expense) {
      this.uploaded.emit(args[1].expenseId);
    } else if (
      !args[1].expenseId &&
      !args[1].expense &&
      !args[1].id &&
      !(typeof args[1] === 'string')
    ) {
      this.uploaded.emit();
    } else if (!args[1].expenseId && args[1].expense && args[1].expense.id) {
      this.uploaded.emit(args[1].expense.id);
    } else if (!args[1].expenseId && !args[1].expense && args[1].id) {
      this.uploaded.emit(args[1].id);
    } else if (
      typeof args[1] === 'string' &&
      args[1].match(/File is too big/)
    ) {
      this.status = 'error';
      this.fileSizeError.emit();
    } else {
      this.status = 'error';
    }
  }



  public onUploadSuccess(args: [
    File,
    Expense | ReceiptResponse | { expense: Expense },
    ProgressEvent
  ]): void {
    const resp = args[1];
    if('id' in resp) this.uploaded.emit(resp);
    else if('expenseId' in resp) this.uploaded.emit(resp.expenseId);
    else if('expense' in resp) this.uploaded.emit(resp.expense);
    else this.uploaded.emit();

    this.uploadComplete = true;
    this.store$.dispatch(ApprovalActions.isLoadingComplete());
  }

  public onUploadProgress(): void {
    this.status = 'progress';
    this.store$.dispatch(ApprovalActions.isLoading())
  }

  // // Zoom Functionality
  public zoomIn(): void {
    this.receiptPhoto.nativeElement.style.transform = 'scale(2.5)';
  }

  public zoomReset(): void {
    this.receiptPhoto.nativeElement.style.transform = 'scale(1)';
    this.receiptPhoto.nativeElement.style['transform-origin'] = '50% 50%';
  }

  public mouseFollow(args: any) {
    const receipt = this.receiptPhoto.nativeElement;
    let originX = (args.offsetX / receipt.offsetWidth) * 100;
    let originY = (args.offsetY / receipt.offsetHeight) * 100;

    originX = originX < 100 ? originX : 100;
    originY = originY < 100 ? originY : 100;
    receipt.style['transform-origin'] = originX + '%' + ' ' + originY + '%';
  }

  // // Rotate Functionality
  public rotate() {
    if (!this.isDisabled) {
      this.rotateReceipt.emit({ angle: 90 });
    }
  }



  get authorization() {
    const token = this.auth.getToken();
    const auth = token ? `Bearer ${token}` : null;
    return auth;
  }

  ngOnInit() {
    this.showRemoveButton = ((
        this.expenseStatus === 'draft' ||
        this.expenseStatus === 'pending' ||
        this.expenseStatus === 'rejected') &&
        !this.parent && !this.canUploadNew
      );

    this.config = {
      maxFilesize: 10,
      url: this.uploadUrl, // Change to POST URL
      method: this.uploadMethod,
      clickable: !!this.imageUrl,
      maxFiles: 1,
      headers: {
        Authorization: this.authorization,
        'x-proxy-for': this.proxy ? this.proxy : '',
      },
      paramName: 'receipt',
      params: this.params,
      autoReset: null,
      errorReset: null,
      cancelReset: null,
      thumbnailWidth: 320,
      thumbnailHeight: 560,
      acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf'
    };

    this.updatePdfImageUrl(this.expense);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('expense' in changes) {
        const previousExpense = changes.expense.previousValue;
        const currentExpense = changes.expense.currentValue;

        if (previousExpense && currentExpense) {
            this.expense = currentExpense;
            // Check if the object properties have changed or not.
            if (JSON.stringify(previousExpense) !== JSON.stringify(currentExpense)) {
              this.updatePdfImageUrl(currentExpense);
            }
        }
    }
  }

  ngAfterViewInit() {
    this.config.previewTemplate = this.receiptPreview.nativeElement.innerHTML;
  }

  onRemoveReceipt() {
    if (!this.isDisabled) {
      this.expense.image = '';
      this.formManager.isFormDirty$(this.formId).pipe(
        take(1),
        tap((isDirty) => {
          if(!isDirty) {
            this.saveChanges(false)
            return;
          }
          return this.alertService.getDiscardChangesAlert(
            () => this.saveChanges(false),
            () => this.formManager.get(this.formId).restore()
          )
        })
      ).subscribe();
    }
  }
  onImageClick() {
    // expense.image is sanitized above for image to display pdf so
    // the sanitized url can not be used to display, hence the condition
    if(this.expense.imageMetadata.ContentType !== 'pdf') {
      const imgUrl = this.isPdf ? this.imageUrl : this.expense.image;
      window.open(imgUrl, '_blank');
    }
  }
  // Iframe is used to display pdf aws signed url,
  // when doing so Iframe requires it to have these extra properties to display image
  updatePdfImageUrl(expense){
    if (
      expense &&
      expense.imageMetadata &&
      expense.imageMetadata.ContentType === 'application/pdf'
    ) {
      this.isPdf = true;
      this.imageUrl = expense.image;
      this.expense.image = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.expense.image + '#toolbar=0&navpanes=0&view=fit'
      );
    }
  }

  /**
   * This is needed to manually open a file selector when bypassing Dropzone.
   * @returns { void }  Nothing is returned.
   */
  public openFileSelector() {
    const fileHolder = document.createElement('input');
    fileHolder.type = 'file';
    fileHolder.accept = this.config.acceptedFiles;
    fileHolder.onchange = ($event: InputEvent) => this.uploadFile($event);
    fileHolder.click();
  }

  /**
   * This is executed after a file has been selected.  This will only be called
   * when the input is manually called, and we bypassed the way Dropzone is
   * allowing the user to select a file.  For instance when a alert is
   * generated before the file selector.
   * @param { InputEvent } $event The input event generated by the browser.
   * @returns { void } Nothing is returned.
   */
  public uploadFile($event: InputEvent) {
    const element = $event.target || $event.currentTarget;
    if(element instanceof HTMLInputElement) {
      if(element.files && element.files.length) {
        const file = element.files[0];
        const dz = <DropzoneDirective>this.dropzone.nativeElement.dropzone;
        dz.addFile(file);
      }
    }
  }

  /**
   * This will restore the form back to its original state, so it is pristine,
   * then opens the file selector.
   */
  public discardUnsavedChanges() {
    this.formManager.get(this.formId).restore();
    this.openFileSelector();
  }

  /**
   * This gets the save function from the form manager and executes it.
   * After the save function is executed, we then listen to the isLoading from
   * the store.  This is how we know when the save is finished.  Afterwards we
   * will open the file selector.
   */
  public saveChanges(isUploadFile: boolean) {
    const unsubscribe$ = new Subject<void>();

    this.formManager.callback(this.formId, 'save')();
    this.store$.select(draftExpensesLoading).pipe(
      takeUntil(unsubscribe$),
      tap((isLoading) => {
        if(isLoading) {
          return;
        }
        unsubscribe$.next();
        unsubscribe$.complete();
        if(isUploadFile){
          return this.openFileSelector()
        }
        else {
          return this.remove.emit();
        }
      })
    ).subscribe()
  }

  /**
   * This is implemented so that we can provide a check before actually
   * populating the alert instead of the file selector.
   * @param { MouseEvent } $event A mouse event generated from the browser.
   * @returns { void } Nothing is returned.
   */
  public clickToOpenFileSelect($event: MouseEvent) {
    $event.preventDefault();
    this.formManager.isFormDirty$(this.formId).pipe(
      take(1),
      tap((isDirty) => {
        if(!isDirty) {
          return this.openFileSelector();
        }

        return this.alertService.getDiscardChangesAlert(
          () => this.saveChanges(true),
          () => this.discardUnsavedChanges()
        )
      })
    ).subscribe();
  }
}
