<div class="admin-user-summary">
  <ems-base-stack space="l">
    <div class="admin-user-summary__content">
      <ems-base-stack space="m">
        <ems-base-title>
          {{ selectedUser?.name_first }}
          {{ selectedUser?.name_last }}
        </ems-base-title>
        <ems-base-caption>
          <ems-base-inline space="m">
            <ems-base-text-style variation="muted">
              <span
                class="admin-user-summary__icon admin-user-summary__icon--user"
              >
                <ems-base-icon iconName="user-id"></ems-base-icon>
              </span>
              <span class="admin-user-summary__number">
                {{
                  selectedUser?.employeeNumber
                    ? selectedUser?.employeeNumber
                    : selectedUser?.employee_number
                }}
              </span>
            </ems-base-text-style>
            <ems-base-text-style variation="muted">
              <span
                class="admin-user-summary__icon admin-user-summary__icon--mail"
              >
                <ems-base-icon iconName="mail"></ems-base-icon>
              </span>
              <span class="admin-user-summary__email">
                {{ selectedUser?.work_email }}
              </span>
            </ems-base-text-style>
          </ems-base-inline>
        </ems-base-caption>
      </ems-base-stack>
    </div>
  </ems-base-stack>
</div>
