import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ems-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
})
export class UserSummaryComponent {
  @Input() employeeNumber = '';
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() role = '';
  @Input() workEmail = '';
  @Input() isCurrentProxy = false;
  @Input() size = 'm';
  @Input() mobileUserSummary = false;
  @Input() proxy = false;
  @Output() openProxyMenu: EventEmitter<boolean> = new EventEmitter();
  proxyMenuOpened = false;

  toggleProxyMenu() {
    this.proxyMenuOpened = !this.proxyMenuOpened;
    this.openProxyMenu.emit(this.proxyMenuOpened);
  }
}
