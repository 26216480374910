<div
  class="is-loading-backdrop"
  [@fadeInOut]="isLoading$ | async"
  *ngIf="isLoading$ | async"
  [style]="{ background: backDrop }"
>
  <div>
    <div [style]="{ background: ballColor }"></div>
    <div [style]="{ background: ballColor }"></div>
    <div [style]="{ background: ballColor }"></div>
    <div [style]="{ background: ballColor }"></div>
  </div>
</div>
