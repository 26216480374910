<div class="search-container">
  <form class="input-button-container" [formGroup]="form">
    <sis-form-control label="Travel Ticket #" inputId="travel-ticket">
      <div class="travel-ticket-input-container">
        <div style="display: contents" *ngIf="(isAssigned$ | async) === false">
          <span class="permanent-text">TT-</span>
          <input
            (focus)="setIsFocusedTrue()"
            (blur)="setIsFocusedFalse()"
            id="travel-ticket"
            type="text"
            placeholder="12345"
            formControlName="travelTicketNumber"
            class="travel-ticket-input"
          />
        </div>
        <input
          type="text"
          [value]="travelTicketTextValue"
          *ngIf="(isAssigned$ | async) === true"
        />
        <ems-base-icon
          icon-prepend
          iconName="times"
          class="travel-ticket-clear"
          (click)="clearTravelTicketInput()"
          [attr.hidden]="
            showClearIcon || (isAssigned$ | async) === true ? null : 'true'
          "
          [class.ticket-assigned]="(isAssigned$ | async) === true"
        ></ems-base-icon>
        <ems-base-button
          *ngIf="(isAssigned$ | async) === false"
          buttonType="primary"
          variant="brand"
          label="Search"
          type="submit"
          (click)="onSubmit()"
        ></ems-base-button>
      </div>
    </sis-form-control>
  </form>
  <div
    *ngIf="form.controls.travelTicketNumber.errors?.required && submitted"
    class="error-message"
  >
    <ems-base-icon icon-prepend iconName="warning"></ems-base-icon>
    <span class="error-text">This field is required.</span>
  </div>
  <div
    *ngIf="(travelTicketError$ | async) === 'Ticket not found'"
    class="error-message"
  >
    <ems-base-icon icon-prepend iconName="warning"></ems-base-icon>
    <span class="error-text">Travel ticket not found.</span>
  </div>
  <div
    *ngIf="
      (travelTicket$ | async)?.details?.length === 0 &&
      (travelTicketError$ | async) === undefined
    "
    class="error-message"
  >
    <ems-base-icon icon-prepend iconName="warning"></ems-base-icon>
    <span class="error-text">No Travel Detail records found.</span>
  </div>
</div>

<table
  *ngIf="
    travelTicketTableData &&
    travelTicketTableData.length &&
    (isAssigned$ | async) === false
  "
  mat-table
  [dataSource]="travelTicketTableData"
  class="tdr-table"
>
  <!-- Date Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.date | date: 'MM/dd/yy' }}
    </td>
  </ng-container>

  <!-- Detail Type Column -->
  <ng-container matColumnDef="detailType">
    <th mat-header-cell *matHeaderCellDef>Detail Type</th>
    <td mat-cell *matCellDef="let element">{{ element.detailType }}</td>
  </ng-container>

  <!-- Vendor Column -->
  <ng-container matColumnDef="vendor">
    <th mat-header-cell *matHeaderCellDef>Vendor</th>
    <td mat-cell *matCellDef="let element">{{ element.vendor }}</td>
  </ng-container>

  <!-- Traveler Column -->
  <ng-container matColumnDef="traveler">
    <th mat-header-cell *matHeaderCellDef>Traveler</th>
    <td mat-cell *matCellDef="let element">
      {{ (travelTicket$ | async).traveler }}
    </td>
  </ng-container>

  <!-- TTNumber Column -->
  <ng-container matColumnDef="TTNumber">
    <th mat-header-cell *matHeaderCellDef>TT#</th>
    <td mat-cell *matCellDef="let element">
      {{ (travelTicket$ | async).name }}
    </td>
  </ng-container>

  <!-- Purpose Column -->
  <ng-container matColumnDef="purpose">
    <th mat-header-cell *matHeaderCellDef>Purpose</th>
    <td mat-cell *matCellDef="let element">
      {{ (travelTicket$ | async).purpose }}
    </td>
  </ng-container>

  <!-- Assign Column -->
  <ng-container matColumnDef="assign">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button (click)="assignTDR(element)" class="assign-btn">
        <ems-base-icon
          icon-prepend
          iconName="arrow_circle_right"
        ></ems-base-icon>
      </button>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="tdr-table-header"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="tdr-table-row"
  ></tr>
</table>
