<ems-base-modal
  id="receipt-manager-error-modal"
  [config]="modalConfig"
  (dismiss)="onContinue()"
  class="dialog-modal"
>
  <ems-receipt-manager-error-dialog
    (continue)="onContinue()"
    (downloadCsv)="onDownloadCsv()"
  ></ems-receipt-manager-error-dialog>
</ems-base-modal>
