import { AlternateEmail } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getAllAlternateEmails = createAction(
  '[AlternateEmails] getAllAlternateEmails'
);

export const getAllAlternateEmailsComplete = createAction(
  '[AlternateEmails] getAllAlternateEmailsComplete',
  props<{ emails: any[] }>()
);

export const getAllAlternateEmailsError = createAction(
  '[AlternateEmails] getAllAlternateEmailsError',
  props<{ error: any }>()
);

export const saveAlternateEmail = createAction(
  '[AlternateEmails] saveAlternateEmail',
  props<{ email: string }>()
);

export const saveAlternateEmailComplete = createAction(
  '[AlternateEmails] saveAlternateEmailComplete',
  props<{ email: any }>()
);

export const saveAlternateEmailError = createAction(
  '[AlternateEmails] saveAlternateEmailError',
  props<{ error: any }>()
);

export const deleteAlternateEmail = createAction(
  '[AlternateEmails] deleteAlternateEmail',
  props<{ id: any }>()
);

export const deleteAlternateEmailComplete = createAction(
  '[AlternateEmails] deleteAlternateEmailComplete',
  props<{ email: any }>()
);

export const deleteAlternateEmailError = createAction(
  '[AlternateEmails] deleteAlternateEmailError',
  props<{ error: any }>()
);

export const updateEmailNotifications = createAction(
  '[AlternateEmail] updateEmailNotifications',
  props<{ id: number; enabled: boolean }>()
);

export const updateEmailNotificationsComplete = createAction(
  '[AlternateEmail] updateEmailNotificationsComplete',
  props<{ email: AlternateEmail }>()
);

export const updateEmailNotificationsError = createAction(
  '[AlternateEmail] updateEmailNotificationsError',
  props<{ error: any }>()
);
