<sis-alert-box>
  <ng-container alert-icon>
    <ems-base-icon iconName="warning-filled" class="alert-icon"></ems-base-icon>
  </ng-container>
  <ng-container alert-title>
    <span class="alert-title regular-title" [attr.aria-hidden]="alertToggle">
      Receipt Image Details Mismatch
    </span>
    <span class="alert-title collapsed-mobile-title" [attr.aria-hidden]="alertToggle">
      Receipt Mismatch
    </span>
    <button
      class="tuggle-button see-more-btn"
      (click)="alertToggle = !alertToggle"
      [attr.aria-hidden]="!alertToggle"
      [hidden]="!alertToggle"
    >
      See more...
    </button>
  </ng-container>

  <ng-container alert-message>
    <div class="collapse-container" [attr.aria-hidden]="alertToggle">
      <div class="collapse-content">
        <p
          class="alert-message"
          [attr.aria-hidden]="alertToggle"
        >
          The <b>transaction date</b> and/or <b>amount</b> from the uploaded receipt do not seem to match the details from the credit card. 
          <br /><br />
          While you can still submit the expense, please take a moment to review the receipt for accuracy.
          <br /><br />
          If everything appears correct, go ahead and submit. Note that the approver will be notified of the mismatch.
          <br /><br />
          Thank you for your attention to detail.
        </p>
        <button
          class="tuggle-button"
          (click)="alertToggle = !alertToggle"
          [attr.aria-hidden]="alertToggle"
        >
          Hide
        </button>
      </div>
    </div>
  </ng-container>
</sis-alert-box>
