import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FavoritePersonActions } from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import { selectAllFavoritePerson } from '../../selectors/favorite-person.selectors';

@Injectable()
export class FavoritePersonEffects {
  getFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritePersonActions.getFavoritePerson),
      switchMap(() =>
        this.apiService.getFavoritePersons().pipe(
          map((person) =>
            FavoritePersonActions.getFavoritePersonComplete({ person })
          ),
          catchError(errorHandler(FavoritePersonActions.getFavoritePersonError))
        )
      )
    )
  );

  favorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritePersonActions.saveFavoritePerson),
      withLatestFrom(this.store$.pipe(select(selectAllFavoritePerson))),
      switchMap(([{ id: personId }, favorites]) => {
        const ids = favorites.map((f) => f.person.id);
        if (!ids.includes(+personId)) {
          return this.apiService.saveFavoritePerson({ person: personId }).pipe(
            map((person) =>
              FavoritePersonActions.saveFavoritePersonComplete({ person })
            ),
            catchError(
              errorHandler(FavoritePersonActions.saveFavoritePersonError)
            )
          );
        } else {
          return this.apiService.unfavoritePerson(personId).pipe(
            map((person) =>
              FavoritePersonActions.deleteFavoritePersonComplete({ person })
            ),
            catchError(
              errorHandler(FavoritePersonActions.deleteFavoritePersonError)
            )
          );
        }
      })
    )
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store$: Store<fromDashboard.State>
  ) {}
}
