import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SalesforceCaseActions } from '../actions';
import { SalesforceCase } from '@ems-gui/shared/util-core';

export interface State extends EntityState<SalesforceCase> {
  loading: boolean;
  error: string;
  selectedId: string | number;
  fromCustomCaseApi?: SalesforceCase;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(SalesforceCaseActions.get, (state) => ({
    ...state,
    loading: true,
  })),
  on(SalesforceCaseActions.getComplete, (state, { salesforceCase }) =>
    adapter.upsertMany(salesforceCase, {
      ...state,
      loading: false,
    })
  ),
  on(SalesforceCaseActions.getError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(SalesforceCaseActions.select, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(SalesforceCaseActions.resetSelectedSalesforceCase, (state) => ({
    ...state,
    selectedId: null,
  })),
  on(SalesforceCaseActions.getCustomCaseByExternalId, (state) => ({
    ...state,
    loading: true
  })),
  on(
    SalesforceCaseActions.getCustomCaseByExternalIdComplete,
    (state, { fromCustomCaseApi }) =>
      adapter.upsertOne(fromCustomCaseApi, {
        ...state,
        loading: false
      })
  ),
  on(
    SalesforceCaseActions.getCustomCaseByExternalIdError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error
    })
  )
);

export const getSelectedSalesforceCaseId = (state: State) => state.selectedId;
export const salesforceCasesLoading = (state: State) => state.loading;
