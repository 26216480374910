<button
  (click)="buttonClicked()"
  [ngClass]="buttonClasses()"
  [attr.aria-label]="label"
  [disabled]="state === 'disabled'"
  class="base-button"
>
  <span class="base-button__icon is-prepend">
    <ng-content select="[icon-prepend]"></ng-content>
  </span>
  <span *ngIf="!!iconName && !iconRight" class="base-button__icon">
    <ems-base-icon [iconName]="iconName" height> </ems-base-icon>
  </span>
  <span *ngIf="showLabel" class="base-button__label">
    {{ label }}
  </span>
  <span class="base-button__icon is-append">
    <ng-content select="[icon-append]"></ng-content>
  </span>
</button>
