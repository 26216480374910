<div class="expense-modal-edit">
  <div class="expense-modal-edit__body">
    <section class="expense-modal-edit__content">
      <div class="expense-edit-form">
        <!-- Vendor -->
        <div class="ems-field">
          <label class="ems-label">Vendor</label>
          <div class="ems-control">
            <input
              class="ems-input"
              type="text"
              placeholder="Vendor"
              [value]="expense.vendor"
            />
          </div>
        </div>

        <!-- Expense Type -->
        <div class="ems-field">
          <label class="ems-label">Expense Type</label>
          <div class="ems-select">
            <select>
              <option value="meal" selected>Meal</option>
              <option value="travel">Travel</option>
            </select>
          </div>
        </div>

        <!-- Address -->
        <div class="ems-field">
          <label class="ems-label">Address</label>
          <div class="ems-control">
            <textarea class="ems-textarea">{{ expense.address }}</textarea>
          </div>
        </div>

        <!-- Description -->
        <div class="ems-field">
          <label class="ems-label">Description</label>
          <div class="ems-control">
            <textarea class="ems-textarea">{{ expense.description }}</textarea>
          </div>
        </div>

        <!-- Transaction Date -->
        <div class="ems-field">
          <label class="ems-label">Transaction Date</label>
          <div class="ems-control has-icon-right">
            <input
              class="ems-input"
              type="date"
              placeholder="MM/DD/YYYY"
              [value]="expense.transactionDate | date: 'shortDate'"
            />
            <span class="ems-control__icon is-right">
              <ems-base-icon iconName="calendar"></ems-base-icon>
            </span>
          </div>
        </div>

        <!-- Billable -->
        <div class="ems-field">
          <label class="ems-label">Billable</label>
          <div class="ems-control">
            <label class="ems-radio">
              <input type="radio" name="billable" value="true" />
              <div class="ems-radio__indicator"></div>
              Yes
            </label>
            <label class="ems-radio">
              <input type="radio" name="billable" value="false" checked />
              <div class="ems-radio__indicator"></div>
              No
            </label>
          </div>
        </div>

        <!-- Amount -->
        <div class="ems-field">
          <label class="ems-label">Amount</label>
          <div class="ems-control">
            <input
              class="ems-input"
              type="text"
              [value]="expense.amount | convertToDollars | currency"
            />
          </div>
        </div>

        <!-- Payment Method -->
        <div class="ems-field">
          <label class="ems-label">Payment Method</label>
          <div class="ems-select">
            <select name="paymentMethod">
              <option value="companyCreditCard" selected>
                Company Credit Card
              </option>
              <option value="personalCreditCard">Personal Credit Card</option>
            </select>
          </div>
        </div>
      </div>
    </section>

    <aside class="expense-modal-edit__receipt">
      <ems-receipt-widget [expense]="expense">
        <ems-base-button
          actions
          label="Upload"
          buttonType="tab"
          state="disabled"
        >
          <ems-base-icon icon-prepend iconName="upload"></ems-base-icon>
        </ems-base-button>
        <ems-base-button
          actions
          label="Remove"
          buttonType="tab"
          state="disabled"
        >
          <ems-base-icon icon-prepend iconName="remove"></ems-base-icon>
        </ems-base-button>
      </ems-receipt-widget>
    </aside>
  </div>
</div>
