<select [id]="inputId" [formControl]="control" [disabled]="disabled">
  <option *ngIf="placeholder" value="">{{placeholder}}</option>
  <option
    *ngFor="let option of optionCollection"
    [value]="option.value"
    [selected]="option.selected ? 'selected' : ''"
    [disabled]="option.disabled ? 'disabled' : ''"
    [title]="option.title ? option.title : null"
  >{{option.text}}</option>
</select>
<sis-icon iconName="chevron-down"></sis-icon>
