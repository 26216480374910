import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MileageActions } from '../../actions';
import { errorHandler } from '../../error-handler';

@Injectable()
export class MileageEffects {
  getMileage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageActions.getMileage),
      switchMap(() =>
        this.apiService.getMileage().pipe(
          map((mileage: any) => MileageActions.getMileageComplete({ mileage })),
          catchError(errorHandler(MileageActions.getMileageError))
        )
      )
    )
  );

  updatedMileage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageActions.updateMileage),
      switchMap((update) =>
        this.apiService.updateMileage(update).pipe(
          map(({ current, future }) =>
            MileageActions.updateMileageComplete({ current, future })
          ),
          catchError(errorHandler(MileageActions.updateMileageError))
        )
      )
    )
  );

  // updateMileageComplete$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MileageActions.updateMileageComplete),
  //     map(() => MileageActions.getMileage())
  //   )
  // );

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
