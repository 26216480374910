import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { AlternateEmail } from '@ems-gui/shared/util-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { State } from '../../+state/reducers/state';
import { AlternateEmailActions, AlternateEmailAdminActions } from '../actions';
import { errorHandler } from '../error-handler';
import { selectUserIsAdmin } from '../selectors';
@Injectable()
export class AlternateEmailEffects {
  getAllAlternateEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlternateEmailActions.getAllAlternateEmails),
      switchMap(() =>
        this.apiService.getAllAlternateEmails().pipe(
          map((emails) =>
            AlternateEmailActions.getAllAlternateEmailsComplete({ emails })
          ),
          catchError(
            errorHandler(AlternateEmailActions.getAllAlternateEmailsError)
          )
        )
      )
    )
  );

  saveAlternateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlternateEmailActions.saveAlternateEmail),
      switchMap(({ email }) =>
        this.apiService.saveAlternateEmail(email).pipe(
          map((res) =>
            AlternateEmailActions.saveAlternateEmailComplete({ email: res })
          ),
          catchError(
            errorHandler(AlternateEmailActions.saveAlternateEmailError)
          )
        )
      )
    )
  );

  deleteAlternateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AlternateEmailActions.deleteAlternateEmail,
        AlternateEmailAdminActions.deleteEmail
      ),
      switchMap(({ id }) =>
        this.apiService.deleteAlternateEmail(id).pipe(
          map((email) =>
            AlternateEmailActions.deleteAlternateEmailComplete({ email })
          ),
          catchError(
            errorHandler(AlternateEmailActions.deleteAlternateEmailError)
          )
        )
      )
    )
  );

  adminGetEmailsAlternateEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlternateEmailAdminActions.getEmails),
      withLatestFrom(this.store$.pipe(select(selectUserIsAdmin))),
      filter(([{ id }, userIsAdmin]) => userIsAdmin),
      switchMap(([{ id }, userIsAdmin]) =>
        this.apiService.adminGetAlternateEmails(id).pipe(
          map((emails) =>
            AlternateEmailAdminActions.getEmailsComplete({ emails })
          ),
          catchError(errorHandler(AlternateEmailAdminActions.getEmailsError))
        )
      )
    )
  );

  adminAddAlternateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlternateEmailAdminActions.addEmail),
      switchMap((email) =>
        this.apiService.adminAddAlternateEmail(email).pipe(
          map((res) =>
            AlternateEmailAdminActions.addEmailComplete({ email: res })
          ),
          catchError(errorHandler(AlternateEmailAdminActions.addEmailError))
        )
      )
    )
  );

  adminUpateEmailNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlternateEmailAdminActions.updateEmailNotifications),
      switchMap((res) =>
        this.apiService.adminUpdateAlternateEmailNotifications(res).pipe(
          map((response: AlternateEmail) =>
            AlternateEmailAdminActions.updateEmailNotificationsComplete({
              email: response,
            })
          ),
          catchError(
            errorHandler(
              AlternateEmailAdminActions.updateEmailNotificationsError
            )
          )
        )
      )
    )
  );

  updateAlternateEmailNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlternateEmailActions.updateEmailNotifications),
      switchMap((res) =>
        this.apiService.updateAlternateEmailNotifications(res).pipe(
          map((response: AlternateEmail) =>
            AlternateEmailActions.updateEmailNotificationsComplete({
              email: response,
            })
          ),
          catchError(
            errorHandler(AlternateEmailActions.updateEmailNotificationsError)
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<State>
  ) {}
}
