<div
  class="expense-fab"
  [class.is-active]="(fabIsActive$ | async) || closeMenu"
>
  <!-- FAB Trigger Button -->
  <button
    class="expense-fab__button"
    aria-label="Create a New Expense"
    (click)="toggleFab()"
  >
    <ems-base-icon iconName="add" class="expense-fab__icon"> </ems-base-icon>
  </button>

  <!-- FAB Menu -->
  <nav class="expense-fab__menu">
    <button
      *ngIf="(creditCardUser$ | async)"
      class="expense-fab__menu-item open-regular"
      (click)="onOpenExpense(true)"
    >
      <span class="expense-fab__menu-label">New Company Credit Card Expense</span>
      <div class="expense-fab__menu-icon expense-icon">
        <ems-base-icon iconName="credit-card"> </ems-base-icon>
      </div>
    </button>
    <button
      class="expense-fab__menu-item open-regular"
      (click)="onOpenExpense()"
    >
      <span class="expense-fab__menu-label">New Out of Pocket Expense</span>
      <div class="expense-fab__menu-icon expense-icon">
        <ems-base-icon iconName="expense-circle-heavy"> </ems-base-icon>
      </div>
    </button>
    <button
      class="expense-fab__menu-item open-mileage"
      (click)="onOpenMileageExpense()"
    >
      <span class="expense-fab__menu-label">Mileage Expense</span>
      <div class="expense-fab__menu-icon">
        <ems-icon-mileage-expense></ems-icon-mileage-expense>
      </div>
    </button>
  </nav>
</div>
<div
  class="expense-fab-overlay" [class.is-active]="fabIsActive$ | async"></div>
