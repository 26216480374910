<ems-expense-form-layout>
  <!-- Itemize Form-->
  <div class="expense-form-itemize" content>
    <div class="expense-form-itemize__items" [formGroup]="form">
      <!-- Existing Items -->
      <div
        class="expense-form-itemize__item"
        *ngFor="let item of existingAmounts?.controls; let i = index"
        formArrayName="existingAmounts"
      >
        <div class="ems-field">
          <label class="ems-label">Item {{ i + 1 }}</label>
          <div class="ems-control">
            <input
              class="ems-input item-input"
              type="text"
              placeholder="$0.00"
              [value]="item"
              [class.input-error]="
                getItemControl('existingAmounts', i)?.errors?.amountNotValid &&
                confirmStatus
              "
              [formControlName]="i"
            />
            <ems-base-dismiss-button
              (click)="deleteItem('existingAmounts', i)"
              *ngIf="
                newAmounts?.controls?.length +
                  existingAmounts?.controls?.length >
                2
              "
              class="delete-existing"
            ></ems-base-dismiss-button>
          </div>
          <ems-base-helper-text
            variation="negative"
            [style.visibility]="
              getItemControl('existingAmounts', i)?.errors?.amountNotValid &&
              confirmStatus
                ? 'visible'
                : 'hidden'
            "
            >Amount must be greater than $0.00</ems-base-helper-text
          >
        </div>
      </div>

      <!-- New Items -->
      <div
        class="expense-form-itemize__item"
        *ngFor="let item of newAmounts?.controls; let i = index"
        formArrayName="newAmounts"
      >
        <div class="ems-field">
          <label class="ems-label"
            >Item {{ existingAmounts?.controls?.length + (i + 1) }}</label
          >
          <div class="ems-control">
            <input
              class="ems-input new-item"
              type="text"
              placeholder="$0.00"
              [value]="item"
              [class.input-error]="
                getItemControl('newAmounts', i)?.errors?.amountNotValid &&
                confirmStatus
              "
              [formControlName]="i"
            />
            <ems-base-dismiss-button
              (click)="deleteItem('newAmounts', i)"
              *ngIf="
                newAmounts?.controls?.length +
                  existingAmounts?.controls?.length >
                2
              "
              class="delete-new"
            ></ems-base-dismiss-button>
          </div>
          <ems-base-helper-text
            variation="negative"
            [style.visibility]="
              getItemControl('newAmounts', i)?.errors?.amountNotValid &&
              confirmStatus
                ? 'visible'
                : 'hidden'
            "
            >Amount must be greater than $0.00</ems-base-helper-text
          >
        </div>
      </div>
    </div>

    <!-- Totals -->
    <div class="expense-form-itemize__total">
      <div class="ems-field">
        <label class="ems-label">Sum</label>
        <div class="ems-control">
          <input class="ems-input" type="text" disabled #sumEl />
          <!-- Sum: Doesn't equal total Helper Text-->
          <ems-base-helper-text
            variation="negative"
            *ngIf="diff !== 0 && confirmStatus"
            >Sum must equal total</ems-base-helper-text
          >
        </div>
      </div>
      <div class="ems-field">
        <label class="ems-label">Total</label>
        <div class="ems-control">
          <input
            class="ems-input"
            type="text"
            disabled
            [value]="parentData.amount | convertToDollars | currency"
          />
          <!-- Total Amount: Over Limit Helper Text-->
          <ems-base-helper-text
            variation="negative"
            *ngIf="overAmount; else notOver"
          >
            {{ actualDiff | convertToDollars | currency }} over total
            limit</ems-base-helper-text
          >
          <ng-template #notOver>
            <!-- Total Amount: Remaining Amount Helper Text-->
            <ems-base-helper-text
              >{{
                actualDiff | convertToDollars | currency
              }}
              Remaining</ems-base-helper-text
            >
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div class="expense-form-itemize__actions">
      <ems-base-button
        label="Add Item"
        buttonType="primary"
        variant="brand"
        (click)="addNewItem()"
      ></ems-base-button>
    </div>
  </div>

  <!-- Itemize Receipt -->
  <ems-receipt-widget
    [expense]="parentData?.receipt"
    expenseStatus="draft"
    [parent]="true"
    (rotateReceipt)="onRotateReceipt($event)"
    receipt
  ></ems-receipt-widget>
</ems-expense-form-layout>
