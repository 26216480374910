import { Component } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  DraftActions,
  selectedToItemizeExpense,
  selectDraftItemizeConfirmedStatus,
  selectedDraftItemizedAmount,
  selectModalDismiss,
  State,
} from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'ems-expense-itemize-draft-modal',
  templateUrl: './expense-itemize-draft-modal.component.html',
  styleUrls: ['./expense-itemize-draft-modal.component.scss'],
})
export class ExpenseItemizeDraftModalComponent {
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Expense:',
    label: '',
    labelClass: '',
    caption: 'Split an expense into parts.',
    status: '',
    subtitle: 'Itemize',
    statusText: '',
    fieldsComplete: false,
    modalId: 'expense-itemize-draft-modal',
  });
  modalInput$ = this.modalInputSubject.asObservable();
  form;
  expense$: Observable<any>;
  expenseItemized$: Observable<any>;
  confirmStatus$: Observable<any>;
  dismiss$: Observable<any>;
  isFormValid;
  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {
    this.expenseItemized$ = this.store$.pipe(
      select(selectedDraftItemizedAmount)
    );
    this.expense$ = this.store$.pipe(
      select(selectedToItemizeExpense)
    );
    this.confirmStatus$ = this.store$.pipe(
      select(selectDraftItemizeConfirmedStatus)
    );
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
  }

  onFormUpdate(value) {
    this.form = { ...value };
  }

  onFormStatusChange(value) {
    this.isFormValid = value;
  }

  onDismiss() {
    this.store$.dispatch(DraftActions.cancelItemization());
    this.modalService.open('expense-form');
  }

  onSubmitItemized() {
    this.store$.dispatch(DraftActions.itemizeConfirm());
    if (this.isFormValid) {
      this.store$.dispatch(
        DraftActions.itemize({ amounts: this.form.amounts })
      );
    }
  }

  onRotateReceipt({ id, angle }) {
    this.store$.dispatch(DraftActions.rotateReceipt({ id, angle }));
  }
}
