import { ExpenseFilter } from '@ems-gui/shared/util-core';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  ApprovalActions,
  PendingActions,
  ReviewedActions,
  UserActions,
} from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  pendingLoading: boolean;
  reviewedLoading: boolean;
  error: string;
  selectedExpenseId: string | number;
  uploadReceipt: boolean;
  selectedJobCodeId: number;
  sendToNote: string;
  selectedReportIds: [];
  totalCount: {
    pending: number;
    reviewed: number;
  };
  status: string;
  filters: {
    pending: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
    };
    reviewed: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
    };
  };
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  pendingLoading: false,
  reviewedLoading: false,
  error: '',
  selectedExpenseId: null,
  uploadReceipt: false,
  selectedJobCodeId: null,
  sendToNote: '',
  selectedReportIds: null,
  totalCount: {
    pending: 0,
    reviewed: 0,
  },
  status: '',
  filters: {
    pending: {
      page: 0,
      sort: {
        colId: 'transactionDate',
        sort: 'DESC',
      },
      filters: {
      },
    },
    reviewed: {
      page: 0,
      sort: {
        colId: 'transactionDate',
        sort: 'DESC',
      },
      filters: {
      },
    },
  },
});

export const reducer = createReducer(
  initialState,
  on(ApprovalActions.get, (state, { page, sort, filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      pending: {
        ...state.filters.pending,
        page,
        sort: {
          ...state.filters.pending.sort,
          ...sort,
        },
        filters: {
          ...state.filters.pending.filters,
          ...filters,
        },
      },
    },
    pendingLoading: true,
  })),
  on(ApprovalActions.isLoading, (state) => ({
    ...state,
    loading: true
  })),
  on(ApprovalActions.isLoadingComplete, (state) => ({
    ...state,
    loading: false
  })),

  on(ApprovalActions.getComplete, (state, { approvals }) =>
    adapter.setAll(approvals.expenses,
    {
      ...state,
      pendingLoading: false,
      totalCount: {
        ...state.totalCount,
        pending: approvals.totalCount,
      },
    })
  ),
  on(
    ApprovalActions.getError,
    ApprovalActions.applyFiltersError,
    (state, { error }) => ({
      ...state,
      pendingLoading: false,
      error,
    })
  ),

  on(ApprovalActions.applyFilters, (state, { page, sort, filters }) => ({
    ...state,
    pendingLoading: true,
    filters: {
      ...state.filters,
      pending: {
        ...state.filters.pending,
        page,
        sort: {
          ...state.filters.pending.sort,
          ...sort,
        },
        filters: {
          ...state.filters.pending.filters,
          ...filters,
        },
      },
    },
  })),
  on(ApprovalActions.applyFiltersComplete, (state, { approvals }) =>
    adapter.setAll(approvals.expenses, {
      ...state,
      pendingLoading: false,
      totalCount: {
        ...state.totalCount,
        pending: approvals.totalCount,
      },
    })
  ),
  on(ApprovalActions.getAllApprovals, (state) => ({
    ...state,
    pendingLoading: true,
    reviewedLoading: true,
  })),
  on(ApprovalActions.getExpenseCountsComplete, (state) => ({
    ...state,
    pendingLoading: false,
    reviewedLoading: false,
  })),
  on(ApprovalActions.getExpenseCountsError, (state, { error }) => ({
    ...state,
    pendingLoading: false,
    reviewedLoading: false,
    error,
  })),
  on(
    ApprovalActions.undoVerifyExpenses,
    ApprovalActions.verifyExpenses,
    (state, { status }) => ({
      ...state,
      status,
      pendingLoading: true
    })
  ),
  on(
    ApprovalActions.verifyExpensesError,
    ApprovalActions.undoVerifyExpensesError,
    ApprovalActions.approvalEditError,
    ApprovalActions.getAllApprovalsError,
    ApprovalActions.undoVerifyOneExpenseError,
    (state, { error }) => ({
      ...state,
      pendingLoading: false,
      reviewedLoading: false,
      error,
    })
  ),
  on(ApprovalActions.selectExpenses, (state, { reportIds }) => ({
    ...state,
    selectedReportIds: reportIds,
  })),
  on(ApprovalActions.selectExpenseComplete, (state, res ) =>
    adapter.upsertOne(res.expense, {
      ...state,
      selectedExpenseId: res.expense.id,
      uploadReceipt: res.uploadReceipt,
      error: null
    })
  ),
  on(ApprovalActions.uploadReceiptComplete, (state, { expense }) =>
    adapter.updateOne(
      { id: expense.id, changes: expense },
      {
        ...state,
        pendingLoading: false,
      })
  ),
  on(
    ApprovalActions.removeReceiptComplete,
    (state, { expense }) =>
      adapter.setOne(expense, {
        ...state,
        loading: false,
        selectedExpenseId: expense.id,
      })
  ),
  
  

  on(ApprovalActions.setJobCodeId, (state, { id }) => ({
    ...state,
    selectedJobCodeId: id,
  })),
  on(ApprovalActions.autoSaveNote, (state, { message }) => ({
    ...state,
    sendToNote: message,
  })),
  on(ApprovalActions.verifyExpensesComplete, (state, { expenses }) =>
    adapter.upsertMany(expenses, {
      ...state,
      pendingLoading: false,
    })
  ),
  on(ApprovalActions.undoVerifyExpensesComplete, (state) => ({
    ...state,
    selectedReportIds: [],
    pendingLoading: false,
  })),
  on(ApprovalActions.approvalEditComplete, (state, { expense }) => adapter.updateOne(
    { id: expense.id, changes: expense },
    {
      ...state,
      pendingLoading: false,
    }
  )),
  on(ApprovalActions.approvalsMultiEditComplete, (state, { expenses }) => ( {
    ...state,
    loading: false,
    })
  ),
  /**
   * Pending Actions
   */
  on(PendingActions.getAllPendingApprovalsComplete, (state, { approvals }) =>
    adapter.upsertMany(approvals.expenses, {
      ...state,
      totalCount: {
        ...state.totalCount,
        pending: approvals.totalCount,
      },
      pendingLoading: false,
    })
  ),
  on(PendingActions.getAllPendingApprovalsError, (state, { error }) => ({
    ...state,
    pendingLoading: false,
    error,
  })),
  // on verify
  on(PendingActions.removePendingExpense, (state, { expense }) =>
    adapter.removeOne(expense.id, {
      ...state,
    })
  ),
  // on undo verify
  on(PendingActions.addPendingExpense, (state, { expense }) =>
    adapter.addOne(expense, {
      ...state,
    })
  ),
  /**
   * Reviewed Actions
   */
  on(
    ApprovalActions.approvalEdit,
    ((state) => ({ ...state, loading: true }))
  ),
  on(ApprovalActions.approvalEditComplete, (state, { expense }) =>
    adapter.upsertOne(expense, {
      ...state,
      loading: false,
      selectedExpenseId: expense.id,
    })
  ),
  on(
    ApprovalActions.approvalEditError,
    (state,  { error }) =>
      ({ ...state, loading: false, error })
  ),
  on(ApprovalActions.verifyOneExpenseComplete, (state, { expense }) =>
    adapter.upsertOne(expense, {
      ...state,
      selectedExpenseId: expense.id,
    })
  ),
  on(ApprovalActions.undoVerifyOneExpenseComplete, (state, { expense }) =>
    adapter.upsertOne(expense, {
      ...state,
      selectedExpenseId: expense.id,
    })
  ),
  on(
    ApprovalActions.applyReviewedFilters,
    (state, { page, sort, filters }) => ({
      ...state,
      reviewedLoading: true,
      filters: {
        ...state.filters,
        reviewed: {
          ...state.filters.reviewed,
          page,
          sort: {
            ...state.filters.reviewed.sort,
            ...sort,
          },
          filters: {
            ...state.filters.reviewed.filters,
            ...filters,
          },
        },
      },
    })
  ),
  on(
    ReviewedActions.getReviewedApprovals,
    (state, { page, sort, filters }) => ({
      ...state,
      filters: {
        ...state.filters,
        reviewed: {
          ...state.filters.reviewed,
          page,
          sort: {
            ...state.filters.reviewed.sort,
            ...sort,
          },
          filters: {
            ...state.filters.reviewed.filters,
            ...filters,
          },
        },
      },
      reviewedLoading: true
    })
  ),
  on(ReviewedActions.getReviewedApprovalsComplete, (state, { approvals }) =>
    adapter.setAll(approvals.expenses, {
      ...state,
      reviewedLoading: false,
      totalCount: {
        ...state.totalCount,
        reviewed: approvals.totalCount,
      },
    })
  ),
  on(ApprovalActions.applyReviewedFiltersComplete, (state, { approvals }) =>
    adapter.setAll(approvals.expenses, {
      ...state,
      reviewedLoading: false,
      totalCount: {
        ...state.totalCount,
        reviewed: approvals.totalCount,
      },
    })
  ),
  on(ReviewedActions.getReviewedApprovalsError, (state, { error }) => ({
    ...state,
    reviewedLoading: false,
    error,
  })),
  on(ApprovalActions.getAllApprovalsComplete, (state, { approvals, counts }) =>
    adapter.setAll(approvals, {
      ...state,
      pendingLoading: false,
      reviewedLoading: false,
      totalCount: {
        ...state.totalCount,
        pending: counts.pending,
        reviewed: counts.reviewed,
      },
    })
  ),
  // on verify
  on(ReviewedActions.addReviewedExpense, (state, { expense }) =>
    adapter.addOne(expense, {
      ...state,
    })
  ),
  // on undo
  on(ReviewedActions.removeReviewedExpense, (state, { expense }) =>
    adapter.removeOne(expense.id, {
      ...state,
    })
  ),
  on(UserActions.logout, () => adapter.getInitialState(initialState)),

  on(ApprovalActions.rotateReceipt, (state) => ({
    ...state,
    pendingLoading: true,
  })),
  on(ApprovalActions.rotateReceiptComplete, (state, { expense }) =>
    adapter.updateOne(
      { id: expense.id, changes: expense },
      {
        ...state,
        pendingLoading: false,
      }
    )
  ),
  on(ApprovalActions.rotateReceiptError, (state, { error }) => ({
    ...state,
    pendingLoading: false,
    error,
  }))
);

export const getSelectedApprovalId = (state: State) => {
  return state.selectedExpenseId;
};

export const getSelectedCanUploadReceipt = (state: State) => {
  return state.uploadReceipt;
};

export const getSelectedApprovalIds = (state: State) => {
  return state.selectedReportIds;
};

export const getApprovalStatus = (state: State) => {
  return state.status;
};

export const getverifyExpenseError = (state: State) => {
  return state.error;
};

export const getUndoverifyExpenseError = (state: State) => {
  return state.error;
};

export const totalApprovalCount = (state: State) => {
  return state.totalCount;
};

export const getPendingApprovalsLoading = (state: State) => {
  return state.pendingLoading;
};

export const getReviewedApprovalsLoading = (state: State) => {
  return state.reviewedLoading;
};

export const getSelectedJobCodeId = (state: State) => {
  return state.selectedJobCodeId;
};

export const getSendToNote = (state: State) => {
  return state.sendToNote;
};

export const getPendingApprovalsFilters = (state: State) => {
  return state.filters.pending;
};

export const getReviewedApprovalsFilters = (state: State) => {
  return state.filters.reviewed;
};

export const getApprovalsFiltersState = (state: State) => {
  return state.filters;
};

export const getPendingPageSize = (state: State) => {
  return state.filters.pending.filters.limit;
};

export const getReviewedPageSize = (state: State) => {
  return state.filters.reviewed.filters.limit;
};

export const totalPendingApprovalsCount = (state: State) => {
  return state.totalCount.pending;
};

export const totalReviewedApprovalsCount = (state: State) => {
  return state.totalCount.reviewed;
};
