import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProxiedUserActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
});

export const reducer = createReducer(
  initialState,
  on(ProxiedUserActions.getUsersForProxy, (state) => ({
    ...state,
    loading: true,
  })),
  on(ProxiedUserActions.getUsersForProxyComplete, (state, { proxies }) =>
    adapter.setAll(proxies, {
      ...state,
      loading: false,
    })
  ),
  on(ProxiedUserActions.getUsersForProxyError, (state, { error }) => ({
    ...state,
    error,
  }))
);
