import { createAction } from '@ngrx/store';

export const takePhoto = createAction('[Expense - Edit Receipt] takePhoto');

export const selectPhotoLibrary = createAction(
  '[Expense - Edit Receipt] selectPhotoLibrary'
);

export const removeReceipt = createAction(
  '[Expense - Edit Receipt] removeReceipt'
);
