import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AlternateEmailActions, AlternateEmailAdminActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  selectedId: string | number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(
    AlternateEmailActions.getAllAlternateEmails,
    AlternateEmailActions.saveAlternateEmail,
    AlternateEmailActions.deleteAlternateEmail,
    AlternateEmailActions.updateEmailNotifications,
    AlternateEmailAdminActions.getEmails,
    AlternateEmailAdminActions.addEmail,
    AlternateEmailAdminActions.updateEmailNotifications,
    AlternateEmailAdminActions.deleteEmail,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    AlternateEmailActions.getAllAlternateEmailsComplete,
    AlternateEmailAdminActions.getEmailsComplete,
    (state, { emails }) =>
      adapter.setAll(emails, {
        ...state,
        loading: false,
      })
  ),
  on(
    AlternateEmailActions.saveAlternateEmailComplete,
    AlternateEmailAdminActions.addEmailComplete,
    (state, { email }) =>
      adapter.addOne(email, {
        ...state,
        loading: false,
      })
  ),
  on(
    AlternateEmailActions.updateEmailNotificationsComplete,
    AlternateEmailAdminActions.updateEmailNotificationsComplete,
    (state, { email }) =>
      adapter.upsertOne(email, {
        ...state,
        loading: false,
      })
  ),
  on(
    AlternateEmailActions.deleteAlternateEmailComplete,
    AlternateEmailAdminActions.deleteEmailComplete,
    (state, { email }) =>
      adapter.removeOne(email.id, {
        ...state,
        loading: false,
      })
  ),
  on(
    AlternateEmailActions.getAllAlternateEmailsError,
    AlternateEmailActions.saveAlternateEmailError,
    AlternateEmailActions.deleteAlternateEmailError,
    AlternateEmailActions.updateEmailNotificationsError,
    AlternateEmailAdminActions.getEmailsError,
    AlternateEmailAdminActions.addEmailError,
    AlternateEmailAdminActions.updateEmailNotificationsError,
    AlternateEmailAdminActions.deleteEmailError,
    (state, { error }) => ({
      ...state,
      loading: true,
      error,
    })
  )
);
