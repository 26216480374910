import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TravelTicketActions } from '../actions';
import { Expense, TravelTicket } from '@ems-gui/shared/util-core';

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
  travelTicketDetailName: string;
  travelTicketName: string;
  travelTicket: Partial<TravelTicket>;
  isAssigned: boolean;
  expense: Partial<Expense>;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  travelTicketDetailName: '',
  travelTicketName: '',
  travelTicket: {},
  isAssigned: false,
  expense: {}
});

export const reducer = createReducer(
  initialState,
  on(
    TravelTicketActions.requestTravelTicketByTicketName,
    (state, { ticketName }) => ({
      ...state,
      loading: true,
      travelTicketDetailName: ticketName
    })
  ),
  on(
    TravelTicketActions.requestTravelTicketByDetailName,
    (state, { detailName }) => ({
      ...state,
      loading: true,
      travelTicketDetailName: detailName
    })
  ),
  on(
    TravelTicketActions.requestTravelTicketSuccess,
    (state, { travelTicket }) => ({
      ...state,
      travelTicket: travelTicket,
      loading: false
    })
  ),
  on(TravelTicketActions.setTravelTicket,
    (state, { travelTicket, isAssigned }) => ({
      ...state,
      travelTicket: travelTicket,
      isAssigned: isAssigned ?? false,
      loading: false
    })),
  on(
    TravelTicketActions.requestTravelTicketError,
    (state, { error }) => ({
      ...state,
      error,
      loading: false
    })
  ),
  on(
    TravelTicketActions.removeTravelTicketDetailFromExpense,
    (state, { expense }) => ({
      ...state,
      loading: true,
      expense: expense
    })
  ),
  on(
    TravelTicketActions.clearTravelTicket,
    TravelTicketActions.removeTravelTicketDetailFromExpenseSuccess,
    (state) => ({
    ...state,
    travelTicket: {},
    travelTicketDetailName: '',
    isAssigned: false,
    loading: false
  })),
  on(TravelTicketActions.clearTravelTicketError, (state) => ({
    ...state,
    loading: false,
    error: undefined
  })),
  on(
    TravelTicketActions.assignTravelTicketDetailToExpense,
    (state, { expense }) => ({
      ...state,
      loading: true,
      expense: expense
    })
  ),
  on(
    TravelTicketActions.assignTravelTicketDetailToExpenseSuccess,
    (state, { expense }) => ({
      ...state,
      loading: false,
      expense: expense
    })
  ),
);

export const travelTicket = (state: State) => state.travelTicket;
export const travelTicketLoading = (state: State) => state.loading;
export const travelTicketError = (state: State) => state.error;
