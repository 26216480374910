import { Injectable } from "@angular/core";
import { AuthGuardService } from "@sec-spec/lib-ng-oauth";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { OauthTokenService } from '../services/oauth-token.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthExpiredGuard {
  constructor(
    private tokenService: OauthTokenService,
    private authGuardService: AuthGuardService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.tokenService.getAccessToken()
      .pipe(
        take(1),
        switchMap((accessToken?: string) => {
          if(!accessToken)
            return this.tokenService.ofGoToLogin()
              .pipe(map(() => false));

          return of(this.authGuardService.canActivate(route, state))
            .pipe(map(() => true));
        }),
        catchError((error: Error) => {
          console.error(error);
          return this.tokenService.goToLogin();
        })
      );
  }
}

