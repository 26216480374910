import { AdminUsersFilter, Employee } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getPersons = createAction(
  '[Persons - Admin] getPersons',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: AdminUsersFilter;
  }>()
);

export const getPersonsComplete = createAction(
  '[Person - Admin] getPersonsComplete',
  props<{ employees: Employee[]; totalCount: number }>()
);

export const getPersonsError = createAction(
  '[Person - Admin] getPersonsError',
  props<{ error: string }>()
);

export const getFilteredPersons = createAction(
  '[Person - Admin] getFilteredPersons',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: AdminUsersFilter;
  }>()
);

export const getFilteredPersonsComplete = createAction(
  '[Person - Admin] getFilteredPersonsComplete',
  props<{ employees: Employee[]; totalCount: number }>()
);

export const getFilteredPersonsError = createAction(
  '[Person - Admin] getFilteredPersonsError',
  props<{ error: string }>()
);

export const select = createAction(
  '[Persons - Admin] select',
  props<{ id: number }>()
);

export const filter = createAction(
  '[Persons - Admin] filter',
  props<{
    id: number;
    hasProxy: boolean | null;
    isProxy: boolean | null;
    role: string;
  }>()
);

export const sort = createAction(
  '[Persons - Admin] sort',
  props<{ colId: string; sort: string }>()
);

export const resetFilters = createAction('[Persons - Admin] resetFilters');

export const setProxy = createAction(
  '[Persons - Admin] setProxy',
  props<{ id: number }>()
);

export const removeProxy = createAction('[Persons - Admin] removeProxy');

export const getProxyPermissions = createAction(
  '[Persons - Admin] getProxyPermissions'
);

export const getProxyPermissionsComplete = createAction(
  '[Persons - Admin] getProxyPermissionsComplete',
  props<{ permissions: any }>()
);

export const getProxyPermissionsError = createAction(
  '[Persons - Admin] getProxyPermissionsError',
  props<{ error: string }>()
);

export const getAllProxyStatusComplete = createAction(
  '[Persons - Admin] getAllProxyStatusComplete',
  props<{ person: any[] }>()
);

export const updateMileage = createAction(
  '[Contacts - Admin] updateMileage',
  props<{ rate: number; effectiveDate: number }>()
);

export const updateMileageComplete = createAction(
  '[Contacts - Admin] updateMileageComplete',
  props<{ rate: number }>()
);

export const updateMileageError = createAction(
  '[Contacts - Admin] updateMileageError',
  props<{ error: any }>()
);

export const getAllUsersPermissions = createAction(
  '[Persons - Admin] getAllUsersPermissions'
);

export const getAllUsersPermissionsComplete = createAction(
  '[Persons - Admin] getAllUsersPermissionsComplete',
  props<{ permissions: any }>()
);

export const getAllUsersPermissionsError = createAction(
  '[Persons - Admin] getAllUsersPermissionsError',
  props<{ error: string }>()
);
