import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ems-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent {
  @Input() user;
  @Input() breadcrumbs;
  @Input() proxy;
  @Input() usersForProxy;
  @Input() openModalName = '';
  @Output() chooseProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeProxy: EventEmitter<any> = new EventEmitter<any>();
  @Output() logout: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleNav = new EventEmitter();

  // Dropdown UI State
  userMenuIsActive = false;
  proxyChooserIsActive = false;
  helpMenuIsActive = false;

  helpGuide = [
    {
      name: 'Spense user guide (pdf)',
      link: 'https://drive.google.com/file/d/18R-8K29NRIQ-raJgRjFL_5Qy2HulPNfX/view?usp=sharing',
      iconName: 'library_books'
    },
    {
      name: 'How to submit an expense',
      link: 'https://drive.google.com/file/d/1RShMVhVkbZAYmumTQa-eFH8Pbo0XA-oB/view',
      iconName: 'play_circle'
    },
    {
      name: 'How to submit a mileage expense',
      link: 'https://drive.google.com/file/d/1RXoeM7CFYWguDGeO3SsMWL8bNOFeY9Ca/view',
      iconName: 'play_circle'
    },
    {
      name: 'How to complete a company credit card expense',
      link: 'https://drive.google.com/file/d/1RZCxYiZIWQFKXb1IrHuB7GWiU5NtI7Sv/view',
      iconName: 'play_circle'
    },
    {
      name: 'How to approve expenses',
      link: 'https://drive.google.com/file/d/1RfZ6yNzkjmEScTTV9L8cDuZ00MkM6EiQ/view',
      iconName: 'play_circle'
    },
    {
      name: 'Contact support',
      link: 'https://sis.us/hris/#/forms/techsupport',
      iconName: 'contact_support'
    }

]

  // Logs out the current user
  onLogout() {
    this.logout.emit();
  }

  // Toggles visibility of user dropdown menu
  toggleUserMenu() {
    this.userMenuIsActive = !this.userMenuIsActive;
  }


  // Toggles visibility of Help dropdown menu
  toggleHelpMenu() {
    this.helpMenuIsActive = !this.helpMenuIsActive;
  }

  // Toggles the Proxy Chooser Feature
  toggleProxyChooser() {
    this.proxyChooserIsActive = !this.proxyChooserIsActive;
  }

  // Choose Proxy Users
  onChooseProxy(id) {
    this.chooseProxy.emit(id);
    this.toggleProxyChooser();
  }

  // choose help guide
  onChooseHelpGuide(guidDetails){
    window.open(guidDetails.link, '_blank');
  }

  onRemoveProxy() {
    this.removeProxy.emit();
  }

  getBreadCrumb(modal) {
    switch (modal) {
      case 'expense-form':
      case 'expense-modal':
      case 'trash-detail':
      case 'approval-modal':
        return 'Expense';
      case 'expense-new-modal-company-cc':
      case 'expense-new-modal':
      case 'mileage-expense-modal-new':
        return 'New Expense';
      case 'transaction-feed-item-detail':
        return 'Transaction File';
      default:
        return '';
    }
  }

  toggleNavMenu() {
    this.toggleNav.emit();
  }
}
