import { Injectable } from '@angular/core';
import { State } from '@ems-gui/expense/util-web-ngrx';
import { Mileage } from '@ems-gui/shared/util-core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserEffects } from '../+state/effects/user.effects';
import {
  selectAllActiveFavoritePersons,
  selectAllFavoritePersonExceptSelectedUser,
  selectAllPersonsExceptCurrentAndSelectedUserWithLabels,
  selectAllPersonsExceptCurrentWithGroupLabels,
  selectAllPersonsWithGroupLabels,
  selectFilteredPersons,
  selectUpdatedFeedErrorUsers,
} from '../+state/reducers';
import {
  selectAllAlternateEmails,
  selectAllEmployees,
  selectAllProxy,
  selectAllTransactionFeedItem,
  selectCurrentMileage,
  selectedUser,
  selectFeedErrorAutoSavingStatus,
  selectReceiptFilesLoading,
  selectTransactionFeedTotalCount,
  selectUpdatedFutureMileage,
  selectUserIsAdmin,
} from '../+state/selectors';

@Injectable({ providedIn: 'root' })
export class WebAdminFacade {
  persons$: Observable<any> = this.store$.pipe(select(selectFilteredPersons));
  employees$: Observable<any> = this.store$.pipe(select(selectAllEmployees));
  selectedUser$: Observable<any> = this.store$.pipe(select(selectedUser));
  favoritesPerson$: Observable<UserEffects[]> = this.store$.pipe(
    select(selectAllActiveFavoritePersons)
  );
  favoritesPersonExceptSelectedUser$: Observable<UserEffects[]> =
    this.store$.pipe(select(selectAllFavoritePersonExceptSelectedUser));
  proxies$: Observable<any> = this.store$.pipe(select(selectAllProxy));
  emails$: Observable<any> = this.store$.pipe(select(selectAllAlternateEmails));
  transactions$: Observable<any> = this.store$.pipe(
    select(selectAllTransactionFeedItem)
  );
  currentMileage$: Observable<Partial<Mileage>> = this.store$.pipe(
    select(selectCurrentMileage)
  );
  futureMileage$: Observable<Partial<Mileage>> = this.store$.pipe(
    select(selectUpdatedFutureMileage)
  );
  feedErrorAutoSaving$: Observable<boolean> = this.store$.pipe(
    select(selectFeedErrorAutoSavingStatus)
  );
  feedErrorUsers$: Observable<any> = this.store$.pipe(
    select(selectUpdatedFeedErrorUsers)
  );
  personsExceptCurrentAndSelectedUser$: Observable<any> = this.store$.pipe(
    select(selectAllPersonsExceptCurrentAndSelectedUserWithLabels)
  );
  allPersonsExceptCurrent$: Observable<unknown> = this.store$.pipe(
    select(selectAllPersonsExceptCurrentWithGroupLabels)
  );
  receiptsLoading$: Observable<any> = this.store$.pipe(
    select(selectReceiptFilesLoading)
  );
  userIsAdmin$: Observable<any> = this.store$.pipe(select(selectUserIsAdmin));
  totalFeedCount$: Observable<number> = this.store$.pipe(
    select(selectTransactionFeedTotalCount)
  );

  constructor(private store$: Store<State>) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }
}
