import { NotificationSetting } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getNotificationSetting = createAction(
  '[Notification Settings] getNotificationSettings'
);

export const getNotificationSettingComplete = createAction(
  '[Notification Settings] getNotificationSettingsComplete',
  props<{ notificationSetting: NotificationSetting }>()
);

export const getNotificationSettingError = createAction(
  '[Notification Settings] getNotificationSettingError',
  props<{ error: string }>()
);

export const saveOneNotificationSetting = createAction(
  '[Notification Settings] saveOneNotificationSetting',
  props<{ body: any }>()
);

export const saveOneNotificationSettingComplete = createAction(
  '[Notification Settings] saveOneNotificationSettingComplete',
  props<{ notificationSetting: NotificationSetting }>()
);

export const saveOneNotificationSettingError = createAction(
  '[Notification Settings] saveOneNotificationSettingError',
  props<{ error: string }>()
);
