import { createSelector } from '@ngrx/store';
import * as fromMileage from '../reducers/mileage.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardMileageState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.mileage
);

export const selectCurrentMileage = createSelector(
  selectDashboardMileageState,
  fromMileage.currentMileage
);

export const selectFutureMileage = createSelector(
  selectDashboardMileageState,
  fromMileage.futureMileage
);

export const selectUpdatedFutureMileage = createSelector(
  selectFutureMileage,
  (mileage: any) => {
    const currentDate = new Date(mileage?.effectiveDate?.split('.')[0]);
    return {
      ...mileage,
      effectiveDate: currentDate?.getTime(),
    };
  }
);
