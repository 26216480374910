import { createSelector } from '@ngrx/store';
import * as fromDashboard from '../reducers/dashboard.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardDashboardState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.dashboard
);

export const selectApprovals = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectApprovals
);

export const selectSubmissions = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectSubmissions
);

export const selectDashboardLoading = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectLoading
);

export const selectDashboardOverdueApprovalCount = createSelector(
  selectApprovals,
  (approvals) => approvals.overdue.totalCount
);

export const selectDashboardPendingApprovalCount = createSelector(
  selectApprovals,
  (approvals) => approvals.unapproved.totalCount
);

export const selectDashboardReviewedApprovalCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectApprovalsReviewedTotalCount
);

export const selectDashboardSubmissionsPendingCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectSubmissionsPendingCount
);

export const selectDashboardSubmissionsApprovedCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectSubmissionsApprovedCount
);

export const selectDashboardSubmissionsRejectedCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectSubmissionsRejectedCount
);

export const selectDashboardUnsubmittedCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectUnsubmittedCount
);

export const selectDashboardSubmittedCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectSubmittedCount
);

export const selectDashboardTrashCount = createSelector(
  selectDashboardDashboardState,
  fromDashboard.selectSubmissionsTrashCount
);
