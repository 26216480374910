import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'convertToDollars',
  pure: false
})
export class ConvertToDollarsPipe implements PipeTransform {
  transform(value: any) {
    value = parseFloat(value);
    if (value > 0 || value < 0) {
      return value / 100;
    } else if (value === 0) {
      return 0;
    } else {
      return null;
    }
  }
}
