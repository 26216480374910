import { createAction, props } from '@ngrx/store';
import { AdminUsersFilter, Employee } from '@ems-gui/shared/util-core';

export const getAllPerson = createAction('[Person] getAllPerson');

export const getAllPersonComplete = createAction(
  '[Person] getAllPersonComplete',
  props<{ person: any[] }>()
);

export const getSearchedPersons = createAction(
  '[Person] getSearchedPersons',
  props<{
    filters: AdminUsersFilter;
  }>()
);

export const getSearchedPersonsComplete = createAction(
  '[Person] getSearchedPersonsComplete',
  props<{ persons: any[]; searchString: string }>()
);

export const getAllPersonError = createAction(
  '[Person] getAllPersonError',
  props<{ error: any }>()
);

export const getProxyPermissionsComplete = createAction(
  '[Person] getProxyPermissionsComplete',
  props<{ permissions: any }>()
);

export const getProxyPermissionsError = createAction(
  '[Person] getProxyPermissionsError',
  props<{ error: string }>()
);

export const resetSearchedPersons = createAction(
  '[Person Component] resetSearchedPersons'
);
export const addPerson = createAction(
  '[Person] addPerson',
  props<{ person: Employee }>()
);
