import { Component, Inject, OnDestroy } from "@angular/core";
import { BaseModalService } from "@ems-gui/expense/util-web-infrastructure";
import { Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface UnsavedArguments {
  parentModalId: string;
  saveAndCloseModal: () => void;
}

@Component({
  selector: 'sis-unsaved-changes-alert',
  templateUrl: './unsaved-changes-alert.component.html',
  styleUrls: ['./unsaved-changes-alert.component.scss']
})
export class UnsavedChangesAlertComponent implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  public constructor(
    public dialogRef: MatDialogRef<UnsavedChangesAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UnsavedArguments,
    private modalService: BaseModalService
  ) {}

  cancel() {
    this.dialogRef.close(this);
  }

  saveChanges() {
    this.data.saveAndCloseModal();
    this.dialogRef.close(this);
  }

  discardAndCloseChanges() {
    this.modalService.close(this.data.parentModalId);
    this.dialogRef.close(this);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
