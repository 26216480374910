import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';

@Component({
  selector: 'no-match-alert',
  templateUrl: './no-match-alert.component.html',
  styleUrls: ['./no-match-alert.component.scss'],
})
export class NoMatchAlertComponent implements OnInit {
  alertToggle: boolean;

  public constructor(
    protected router: Router,
    protected modalService: BaseModalService
  ) {}

  ngOnInit() {
    this.alertToggle = false;
  }
}
