import { createAction, props } from "@ngrx/store";
import { Expense, TravelTicket } from '@ems-gui/shared/util-core';

export const requestTravelTicketByTicketName = createAction(
  '[Travel Ticket] Request Travel Ticket by Ticket Name',
  props<{ ticketName: string }>()
);

export const requestTravelTicketByDetailName = createAction(
  '[Travel Ticket] Request Travel Ticket by Detail Name',
  props<{ detailName: string }>()
);

export const requestTravelTicketDetailsBySaleforceId = createAction(
  '[Travel Ticket] Request Travel Ticket Details by Saleforce Id',
  props<{ saleforceId: string }>()
);

export const requestTravelTicketSuccess = createAction(
  '[Travel Ticket] Request Travel Ticket Success',
  props<{ travelTicket: TravelTicket }>()
);

export const requestTravelTicketError = createAction(
  '[Travel Ticket] Request Travel Ticket Error',
  props<{ error: string }>()
);

export const clearTravelTicket = createAction(
  '[Travel Ticket] Clear Travel Ticket'
);

export const clearTravelTicketError = createAction(
  '[Travel Ticket] Clear Travel Ticket Error'
);

export const assignTravelTicketDetailToExpense = createAction(
  '[Travel Ticket] Assign Travel Ticket Detail To Expense',
  props<{ expense: Expense }>()
);

export const assignTravelTicketDetailToExpenseSuccess = createAction(
  '[Travel Ticket] Assign Travel Ticket Detail To Expense Success',
  props<{ expense: Expense }>()
);

export const removeTravelTicketDetailFromExpense = createAction(
  '[Travel Ticket] Remove Travel Ticket Detail From Expense',
  props<{ expense: Expense  }>()
);

export const removeTravelTicketDetailFromExpenseSuccess = createAction(
  '[Travel Ticket] Remove Travel Ticket Detail From Expense Success',
  props<{ expense: Expense }>()
);

export const setTravelTicket = createAction(
  '[Travel Ticket] set travel ticket value',
  props<{ travelTicket: TravelTicket, isAssigned?: boolean }>()
);
