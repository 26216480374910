<ems-base-modal
  id="send-to-modal"
  [config]="modalConfig"
  class="dialog-modal"
  (dismiss)="onSendToFormCancel()"
>
  <ems-send-to-form
    [selectedExpenseIds]="selectedExpenseIds"
    (cancel)="onSendToFormCancel()"
    (send)="onSend($event)"
    (favorite)="onFavoriteJobCode($event)"
    (favoriteSalesforceCase)="onFavoriteSalesforceCase($event)"
  ></ems-send-to-form>
</ems-base-modal>
