<div class="user-summary" [ngClass]="{ 'is-size-l': size === 'l' }">
  <div class="user-summary__avatar">
    <ems-base-avatar
      [firstName]="firstName"
      [lastName]="lastName"
      [size]="size"
    ></ems-base-avatar>
  </div>
  <div class="user-summary__content">
    <div class="user-summary__proxy-label">
      <ems-base-caption size="1" *ngIf="isCurrentProxy"
        >Proxying As</ems-base-caption
      >
    </div>
    <ems-base-text *ngIf="size === 'l'"
      >{{ firstName }} {{ lastName }}</ems-base-text
    >
    <ems-base-text size="1" *ngIf="size !== 'l'"
      >{{ firstName }} {{ lastName }}</ems-base-text
    >
    <ems-base-caption size="1" *ngIf="workEmail">
      <ems-base-text-style variation="muted">{{
        workEmail
      }}</ems-base-text-style>
    </ems-base-caption>
    <ems-base-caption size="1">
      <ems-base-inline space="s">
        <ems-base-text-style variation="muted" *ngIf="employeeNumber"
          >#{{ employeeNumber }}</ems-base-text-style
        >
        <ems-base-text-style variation="muted" *ngIf="role">{{
          role
        }}</ems-base-text-style>
      </ems-base-inline>
    </ems-base-caption>
  </div>
  <div
    class="dropdown-icon"
    (click)="toggleProxyMenu()"
    [class.open-menu]="proxyMenuOpened"
    *ngIf="mobileUserSummary"
  >
    <ems-base-icon iconName="dropdown-arrow"></ems-base-icon>
  </div>
  <div class="proxy-set" *ngIf="proxy"><span>(Back to Main Account)</span></div>
</div>
