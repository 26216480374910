import { of } from 'rxjs';
import { LayoutActions, NewActions } from './actions';

export const errorHandler = (action) => (err) => {
  let message = err.message;
  if (!message) {
    message = 'Something went wrong, try again.';
  }

  const rx = /\/receipt: 0 Unknown Error/g;
  if (
    (err.error?.message === 'The uploaded receipt was not valid' ||
      rx.test(err.message)) &&
    !err.error.expenseId
  ) {
    return of(
      action({ error: message }),
      LayoutActions.openReceiptDataErrorModal()
    );
  } else if (err.error && err.error?.message?.match(/Upload limit/)) {
    return of(
      action({ error: err.error.message }),
      LayoutActions.openReceiptSizeErrorModal()
    );
  } else if (
    (err?.error?.message || rx.test(err?.message)) &&
    err?.error?.expenseId
  ) {
    return of(NewActions.getExpenseData({ token: err.error.expenseId }));
  } else {
    return of(
      LayoutActions.globalToast({
        triggerAction: action({ error: message }),
      }),
      action({ error: err.error.message ?? message })
    );
  }
};
