import { Component, Input } from "@angular/core";
import {
  animate,
  style,
  transition,
  trigger
} from "@angular/animations";
import { Store } from '@ngrx/store';
import { State } from '@libs/expense/util-web-ngrx/src/lib/+state/reducers/state';
import { selectCurrentlyLoading } from '@ems-gui/expense/util-web-ngrx';

const FADE_IN_OUT_TIMING = 250;

@Component({
  selector: 'sis-is-loading',
  templateUrl: './is-loading.component.html',
  styleUrls: ['./is-loading.component.scss'],
  animations: [ trigger('fadeInOut',[
    transition(':enter', [
      style({ opacity: 0 }),
      animate(FADE_IN_OUT_TIMING, style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(FADE_IN_OUT_TIMING, style({ opacity: 0 }))
    ])
  ]) ]
})
export class IsLoadingComponent {
  @Input() public backDrop = '#00000088';
  @Input() public ballColor = '#fff';
  public isLoading$ = this.store$.select(selectCurrentlyLoading);

  public constructor(private store$: Store<State>) {}
}
