import { createSelector } from '@ngrx/store';
import * as fromFavoriteSalesforceCase from '../reducers/favorite-salesforce-case.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardFavoriteSalesforceCaseState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.favoriteSalesforceCase
);

export const {
  selectIds: selectFavoriteSalesforceCaseIds,
  selectEntities: selectFavoriteSalesforceCaseEntities,
  selectAll: selectAllFavoriteSalesforceCase,
} = fromFavoriteSalesforceCase.adapter.getSelectors(
  selectDashboardFavoriteSalesforceCaseState
);
