import { createSelector } from '@ngrx/store';
import * as fromLayout from '../reducers/layout.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectLayoutState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.layout
);

export const selectDraftScrollPosition = createSelector(
  selectLayoutState,
  fromLayout.draftExpenseScrollPosition
);

export const selectScrollPosition = createSelector(
  selectLayoutState,
  fromLayout.newExpenseScrollPosition
);

export const selectOpenModalId = createSelector<unknown, unknown[], number>(
  selectLayoutState,
  fromLayout.getOpenModalId
);

export const selectModalDismiss = createSelector(
  selectLayoutState,
  fromLayout.getDismissModal
);

export const selectResetForm = createSelector(
  selectLayoutState,
  fromLayout.newExpenseResetForm
);

export const selectResetDraftForm = createSelector(
  selectLayoutState,
  fromLayout.draftExpenseResetForm
);

export const selectResetMileageForm = createSelector(
  selectLayoutState,
  fromLayout.newMileageExpenseResetForm
);

export const selectMileageDraftResetForm = createSelector(
  selectLayoutState,
  fromLayout.draftMileageExpenseResetForm
);

export const selectDisplaySnackbar = createSelector(
  selectLayoutState,
  fromLayout.displaySnackbar
);

export const selectHomeLoaded = createSelector(
  selectLayoutState,
  fromLayout.getHomeLoaded
);

export const selectOpenModalName = createSelector(
  selectLayoutState,
  fromLayout.getOpenModalName
);

export const selectCurrentlyLoading = createSelector(
  selectDashboardState,
  (state) =>
    state.expense.new.loading ||
    state.approval.loading ||
    state.travelTicket.loading ||
    state.expense.draft.loading ||
    state.salesforceCase.loading ||
    false
);
