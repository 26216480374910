import { SalesforceCase } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const get = createAction('[Salesforce Case] get');

export const getComplete = createAction(
  '[Salesforce Case] getComplete',
  props<{ salesforceCase: SalesforceCase[] }>()
);

export const getError = createAction(
  '[Salesforce Case] getError',
  props<{ error: any }>()
);

export const select = createAction(
  '[Salesforce Case] select',
  props<{ id: any }>()
);

export const resetSelectedSalesforceCase = createAction(
  '[Salesforce Case] resetSelectedSalesforceCase'
);

export const getCustomCaseByExternalId = createAction(
  '[Salesforce Case] getCustomCaseByExternalId',
  props<{ externalId: number }>()
);

export const getCustomCaseByExternalIdComplete = createAction(
  '[Salesforce Case] getCustomCaseByExternalIdComplete',
  props<{ fromCustomCaseApi: SalesforceCase }>()
);

export const getCustomCaseByExternalIdError = createAction(
  '[Salesforce Case] getCustomCaseByExternalIdError',
  props<{ error: string }>()
);
