import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { FeedErrorUserActions, UserActions } from '../actions';
import { errorHandler } from '../error-handler';

@Injectable()
export class FeedErrorUserEffects {
  getFeedErrorUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getPermissionsComplete),
      filter(
        (res: any) =>
          res.permissions.length &&
          res.permissions.find((p) => p === 'spenseAdmin')
      ),
      switchMap(() =>
        this.apiService.getFeedErrorUsers().pipe(
          map((users) => FeedErrorUserActions.getUsersComplete({ users })),
          catchError(errorHandler(FeedErrorUserActions.getUsersError))
        )
      )
    )
  );

  addFeedErrorUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedErrorUserActions.addUser),
      switchMap((ids) =>
        this.apiService.addFeedErrorUsers(ids).pipe(
          map((user) => FeedErrorUserActions.addUserComplete({ user })),
          catchError(errorHandler(FeedErrorUserActions.addUserError))
        )
      )
    )
  );

  deleteFeedErrorUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedErrorUserActions.deleteUser),
      switchMap(({ id }) =>
        this.apiService.deleteFeedErrorUser(id).pipe(
          map((user) => FeedErrorUserActions.deleteUserComplete({ user })),
          catchError(errorHandler(FeedErrorUserActions.deleteUserError))
        )
      )
    )
  );

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
