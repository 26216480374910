import {
  Component,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from "@angular/core";
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { firstValueFrom, Subscription } from "rxjs";
import {
  AllModalActions,
  LayoutActions,
  selectAllModalActiveFab,
  selectAllowedToUpload, selectContinueTo,
  selectUserHasCreditCard,
  State
} from "@ems-gui/expense/util-web-ngrx";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'ems-expense-fab',
  templateUrl: './expense-fab.component.html',
  styleUrls: ['./expense-fab.component.scss'],
})
export class ExpenseFabComponent implements OnInit, OnDestroy {
  @Output() openExpense: EventEmitter<any> = new EventEmitter();
  @Output() openCCExpense: EventEmitter<any> = new EventEmitter();
  @Output() openMileageExpense: EventEmitter<any> = new EventEmitter();
  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Input() closeMenu = false;
  @ViewChild('#fab-ocr-to-expense') fileInputRef: HTMLInputElement;

  creditCardUser$ = this.store$.select(selectUserHasCreditCard);
  protected allowedToUpload$ = this.store$.select(selectAllowedToUpload);
  protected continueTo$ = this.store$.select(selectContinueTo);
  public fabIsActive$ = this.store$.select(selectAllModalActiveFab);
  protected ccUserInit = false;


  constructor(
    public modalService: BaseModalService,
    protected store$: Store<State>,
    @Inject(DOCUMENT) private document: Document
  ) {}

  protected subscriptions: Subscription[] = [];

  protected listenForConfirm() {
    this.subscriptions.push(
      this.continueTo$.subscribe(async (continueTo) => {
        if(continueTo !== '') return;
        this.unsubscribe();
        const creditCardUser = await firstValueFrom(this.creditCardUser$);
        const allowedToUpload = await firstValueFrom(this.allowedToUpload$);

        if(!creditCardUser || allowedToUpload) {
          this.document
            .querySelector<HTMLInputElement>('#fab-ocr-to-expense')
            .click();
        }
      })
    );
  }

  protected unsubscribe() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {}

  onOpenExpense(openCCExpense?: boolean) {
    if (openCCExpense === true) {
      this.openCCExpense.emit(null);
    }
    else {
      this.openExpense.emit(null);
    }
    this.toggleFab();
  }

  onOpenMileageExpense() {
    this.openMileageExpense.emit(null);
    this.toggleFab();
  }

  // FAB Toggle State
  public async toggleFab() {
    const fabIsActive = await firstValueFrom(this.fabIsActive$);
    this.store$.dispatch(
      AllModalActions.setFabIsActive(
        { fabIsActive: !fabIsActive }
      )
    );
  }

  protected async canWeProceed() {
    return [
      await firstValueFrom(this.creditCardUser$),
      await firstValueFrom(this.allowedToUpload$)
    ]
  }

  public async uploadFileClick(clickEvent: MouseEvent) {
    const [ ccUser, allowedToUpload ] = await this.canWeProceed();
    if(!ccUser) return;
    const setToFalse = () => {
      this.store$.dispatch(
        AllModalActions.setAllowUploads({ fileUploadAllowed: false })
      );

      this.ccUserInit = true;
    }


    if(!this.ccUserInit) {
      setToFalse();
    } else if(allowedToUpload) {
      setToFalse();

      return;
    }
    clickEvent.preventDefault();
    this.store$.dispatch(
      LayoutActions.openExpenseModal(
        { name: 'credit-card-holder-alert' }
      )
    );
    this.store$.dispatch(
      AllModalActions.setContinueTo({ continueTo: 'file-upload' })
    );
    this.listenForConfirm();
  }

  public onFileChange(event: Event) {
    if(event.currentTarget instanceof HTMLInputElement) {
      const element = event.currentTarget;
      const files = element.files;
        return this.onFileUpload(element.files);
    }
  }

  onFileUpload(files: FileList) {
    if(files.length === 0) return;
    const image = files.item(0);
    this.upload.emit(image);
    this.toggleFab();
    this.modalService.open('loading-modal');
  }

  ngOnDestroy(): void {
    this.closeMenu = false;
    this.unsubscribe();
  }

  protected readonly HTMLInputElement = HTMLInputElement;
}
