import { ProxiedUser } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getUsersForProxy = createAction('[Proxy - User] getUsersForProxy');

export const getUsersForProxyComplete = createAction(
  '[Proxy - User] getUsersForProxyComplete',
  props<{ proxies: ProxiedUser[] }>()
);

export const getUsersForProxyError = createAction(
  '[Proxy - User] getUsersForProxyError',
  props<{ error: any }>()
);
