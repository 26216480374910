import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-filters',
  templateUrl: './base-filters.component.html',
  styleUrls: ['./base-filters.component.scss'],
})
export class BaseFiltersComponent implements OnInit {
  /**
   * Sets the filter drawer open/close state
   *
   * Accepts `true`, `false`
   */
  @Input() isActive = false;

  /**
   * Sets the filter drawer internal state
   */
  filtersDropIsActive = false;

  /**
   * Inital Label to render on filter button
   */
  filtersDropLabel = 'Filters';

  /**
   * Interal State for Button
   */
  filterButtonState = '';

  /**
   * Set intital state on load
   */
  setInitialState() {
    this.filtersDropIsActive = this.isActive;
    if (this.filtersDropIsActive) {
      this.filtersDropLabel = 'Hide Filters';
      this.filterButtonState = 'active';
    } else {
      this.filtersDropLabel = 'Filters';
      this.filterButtonState = '';
    }
  }

  /**
   * Toggles the dropdown state
   */
  toggleFiltersDrop() {
    this.filtersDropIsActive = !this.filtersDropIsActive;
    if (this.filtersDropIsActive) {
      this.filtersDropLabel = 'Hide Filters';
      this.filterButtonState = 'active';
    } else {
      this.filtersDropLabel = 'Filters';
      this.filterButtonState = '';
    }
  }

  constructor() {}

  ngOnInit() {
    this.setInitialState();
  }
}
