<svg
  class="base-icon"
  [attr.width]="width"
  [attr.height]="height"
  [attr.viewBox]="icon?.viewBox"
  [attr.fill]="icon?.fill ? icon.fill : 'currentColor'"
>
  <defs>
    <linearGradient
      id="loadingGradient"
      gradientUnits="userSpaceOnUse"
      x1="40.0001"
      y1="40.0001"
      x2="20"
      y2="-7.582189e-05"
    >
      <stop offset="0" stop-color="#00bdda" />
      <stop offset="1" stop-color="#007fbe" />
    </linearGradient>
  </defs>
  <path
    *ngIf="!icon.transform; else transform"
    [attr.d]="icon.path"
  />
  <ng-template #transform>
    <g [attr.transform]="icon.transform">
      <path
        [attr.fill]="icon.fill ? '' : 'currentColor'"
        [attr.d]="icon.path"
      />
    </g>
  </ng-template>
</svg>
