import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-caption',
  templateUrl: './base-caption.component.html',
  styleUrls: ['./base-caption.component.scss'],
})
export class BaseCaptionComponent implements OnInit {
  /**
   * Caption Size
   *
   * Accepts 1, 2, 3
   * @param {string} size - Size of the caption text
   * @default '2'
   */
  @Input() size = '2';

  /**
   * Classnames
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-size-1': this.size === '1',
      'is-size-2': this.size === '2',
      'is-size-3': this.size === '3',
    };
  }

  constructor() {}

  ngOnInit() {}
}
