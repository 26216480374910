/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AllModalActions, State } from "@ems-gui/expense/util-web-ngrx";
import { Store } from "@ngrx/store";
import { BaseModalService } from "@ems-gui/expense/util-web-infrastructure";
import { selectOpenModalName } from "@ems-gui/expense/util-web-ngrx";

@Component({
  selector: 'sis-credit-card-holder-alert',
  templateUrl: './credit-card-holder-alert.component.html',
  styleUrls: ['./credit-card-holder-alert.component.scss']
})
export class CreditCardHolderAlertComponent implements OnInit {
  @Input() isModal = false;
  @Output() onContinue = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  alertToggle: boolean;
  modalName: string;

  public constructor(
    protected router: Router,
    protected modalService: BaseModalService,
    protected store$: Store<State>
  ) {}

  ngOnInit() {
    this.alertToggle = false;
    this.store$.select(selectOpenModalName).subscribe((modalName) => {
      this.modalName = modalName;
    });
  }

  continue() {
    this.onContinue.emit();
  }

  cancel() {
    this.onCancel.emit();
  }

  navigateToUnsubmitted() {
    this.router.navigateByUrl('/expenses/unsubmitted')
      .then(() => {
        this.cancel();
        this.store$.dispatch(
          AllModalActions.setFabIsActive(
            { fabIsActive: false }
          )
        )

        if (!this.isModal) {
          this.modalService.close(this.modalName);
        }
      });
  }
}
