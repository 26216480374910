import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_OPTIONS } from './constants';

@Pipe({
  name: 'paymentPipe',
})
export class PaymentPipe implements PipeTransform {
  paymentOptions = PAYMENT_OPTIONS;
  currentPaymentOption = [];
  transform(payment: string): string {
    this.currentPaymentOption = this.paymentOptions.filter(
      (p) => p.value === payment
    );
    return this.currentPaymentOption[0].display;
  }
}
