<ems-expense-form-layout>
  <!-- Itemize Form-->
  <div class="expense-form-itemize" content>
    <div class="expense-form-itemize__items" [formGroup]="form">
      <!-- Existing Items -->
      <div
        class="expense-form-itemize__item"
        *ngFor="let item of amounts?.controls; let i = index"
        formArrayName="amounts"
      >
        <div>
          <label class="ems-label">Item {{ i + 1 }}</label>
          <div class="ems-control">
            <input
              class="ems-input item-input"
              type="text"
              placeholder="$0.00"
              [value]="item"
              [formControlName]="i"
            />
            <ems-base-dismiss-button
              (click)="deleteItem(i)"
              *ngIf="amounts?.controls?.length > 2"
            ></ems-base-dismiss-button>
          </div>
          <ems-base-helper-text
            variation="negative"
            [style.visibility]="
              getItemControl(i)?.errors?.amountNotValid && confirmStatus
                ? 'visible'
                : 'hidden'
            "
            >Amount must be greater than $0.00</ems-base-helper-text
          >
        </div>
      </div>
    </div>

    <!-- Totals -->
    <div class="expense-form-itemize__total">
      <div class="ems-field">
        <label class="ems-label">Sum</label>
        <div class="ems-control">
          <input class="ems-input" type="text" disabled #sumEl />
          <!-- Sum: Doesn't equal total Helper Text-->
          <ems-base-helper-text
            variation="negative"
            *ngIf="diff !== 0 && confirmStatus"
            >Sum must equal total</ems-base-helper-text
          >
        </div>
      </div>
      <div class="ems-field">
        <label class="ems-label">Total</label>
        <div class="ems-control">
          <input
            class="ems-input"
            type="text"
            disabled
            [value]="expense?.amount | convertToDollars | currency"
          />
          <!-- Total Amount: Over Limit Helper Text-->
          <ems-base-helper-text
            variation="negative"
            *ngIf="overAmount; else notOver"
            >{{ actualDiff | convertToDollars | currency }} over total
            limit</ems-base-helper-text
          >
          <!-- Total Amount: Remaining Amount Helper Text-->
          <ng-template #notOver>
            <ems-base-helper-text
              >{{
                actualDiff | convertToDollars | currency
              }}
              Remaining</ems-base-helper-text
            >
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div class="expense-form-itemize__actions">
      <ems-base-button
        label="Add Item"
        buttonType="primary"
        variant="brand"
        (click)="addNewItem()"
      ></ems-base-button>
    </div>
  </div>

  <!-- Itemize Receipt -->
  <ems-receipt-widget
    [expense]="expense"
    [expenseStatus]="expense?.status"
    [parent]="true"
    (rotateReceipt)="onRotateReceipt($event)"
    receipt
  ></ems-receipt-widget>
</ems-expense-form-layout>
