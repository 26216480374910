import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { ModalInput, OnChange } from '@ems-gui/shared/util-core';
import { BehaviorSubject } from 'rxjs';

export interface CloseRequest {
  modalId: string;
  isClosing: boolean;
}

@Component({
  selector: 'ems-base-modal',
  styleUrls: ['./base-modal.component.scss'],
  templateUrl: './base-modal.component.html',
})
export class BaseModalComponent implements OnInit {
  private configSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    modalId: '',
    title: '',
    closeable: true,
  });
  config$ = this.configSubject.asObservable();
  @OnChange(function (this: BaseModalComponent, config) {
    const currentConfig = this.configSubject.getValue();
    this.configSubject.next({
      ...currentConfig,
      ...config,
    });
    if (config?.scrollToBottom) {
      this.scrollToBottom();
    }
  })
  @Input()
  config: ModalInput = null;
  @Input() loading = false;
  @Input() activityTabOpen = false;
  @Input() completeOverlay = false;
  @Output() dismiss = new EventEmitter<CloseRequest>();
  @ViewChild('modal', { static: false }) private modalEl: ElementRef;

  isOpen = false;

  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    this.keyup(event);
  }

  constructor(
    private modalService: BaseModalService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.modalService.registerModal(this);
  }

  closeModal() {
    const modalId = this.config.modalId;
    const closeRequest: CloseRequest = {
      modalId,
      isClosing: true
    }
    this.dismiss.emit(closeRequest);
    if(closeRequest.isClosing) this.modalService.close(modalId);
  }

  close() {
    if (this.config.modalId !== 'loading-modal') this.closeModal();
  }

  private keyup(event: KeyboardEvent): void {
    if (event.key === 'Escape') this.closeModal();
  }

  scrollToBottom() {
    this.renderer.setProperty(
      this.modalEl.nativeElement,
      'scrollTop',
      this.modalEl.nativeElement.scrollHeight
    );
  }
}
