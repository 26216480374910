import { Expense } from '@ems-gui/shared/util-core';
import { Action, createAction, props } from '@ngrx/store';

export const openSidenav = createAction('[Layout] open side nav');

export const closeSidenav = createAction('[Layout] close side nav');

export const dismissModal = createAction('[Layout] dismiss modal');

export const resetDismissModal = createAction('[Layout] reset dismiss modal');

export const selectedSegmentBarIndex = createAction(
  '[Layout] selectedSegmentBarIndex',
  props<{ index: number }>()
);

export const openExpenseModal = createAction(
  '[Layout] openExpenseModal',
  props<{ name: string; id?: number; expenses?: any }>()
);

export const globalToast = createAction(
  '[Layout] toast',
  props<{
    triggerAction: Action;
  }>()
);

export const globalToastErrorMessage = createAction(
  '[Layout] Error Message toast',
  props<{
    message: string;
  }>()
);

export const displaySnackbar = createAction(
  '[Layout] displaySnackbar',
  props<{ display: boolean }>()
);

export const openReceiptDataErrorModal = createAction(
  '[Layout] openReceiptDataErrorModal'
);

export const openReceiptSizeErrorModal = createAction(
  '[Layout] openReceiptSizeErrorModal'
);

export const homeLoad = createAction('[Layout] homeLoad');

export const homeLoadComplete = createAction('[Layout] homeLoadComplete');

export const homeLoadError = createAction(
  '[Layout] homeLoadError',
  props<{ error: string }>()
);

export const saveHomeLoadData = createAction(
  '[Layout] saveHomeLoadData',
  props<{ homeData: any[] }>()
);
export const saveHomeLoadDataComplete = createAction(
  '[Layout] saveHomeLoadDataComplete'
);

export const dismissNewExpenseModal = createAction(
  '[Layout] dismissNewExpenseModal'
);

export const resetDismissNewExpenseModal = createAction(
  '[Layout] resetDismissNewExpenseModal'
);

export const openNewExpenseModal = createAction('[Layout] openNewExpenseModal');

export const modalOpened = createAction(
  '[Layout] modalOpened',
  props<{ name: string }>()
);

export const openFeedItemModal = createAction(
  '[Layout] openFeedItemModal',
  props<{ name: string; id?: number }>()
);

export const dismissFeedItemModal = createAction(
  '[Layout] dismissFeedItemModal'
);
