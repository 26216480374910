import { createAction, props } from '@ngrx/store';
import { Expense } from '@ems-gui/shared/util-core';

export const requestUserCreatedCreditCardExpenses = createAction(
  '[Expense Match] Request User Created Credit Card Expenses'
);

export const requestUserCreatedCreditCardExpensesSuccess = createAction(
  '[Expense Match] Request User Created Credit Card Expenses Success',
  props<{ expenses: Expense[] }>()
);

export const requestUserCreatedCreditCardExpensesError = createAction(
  '[Expense Match] Request User Created Credit Card Expenses Error',
  props<{ error: string }>()
);

export const clearUserCreatedCreditCardExpenses = createAction(
  '[Expense Match] Clear User Created Credit Card Expenses'
);

export const matchExpense = createAction(
  '[Expense Match] Match Expense',
  props<{ expenseId: number }>()
);
