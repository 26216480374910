<div class="receipt-manager-modal">
  <header class="base-modal__header">
    <div class="base-modal__pre-header">
      <p class="base-modal__state-title">Warning</p>
    </div>

    <div class="base-modal__header-content">
      <span class="base-modal__title-icon"
        ><ems-base-icon
          class="base-modal__icon"
          icon-prepend
          iconName="warning"
        ></ems-base-icon
      ></span>
      <h1 class="base-modal__title">This Expense Is Marked as Fraudulent</h1>
    </div>
  </header>

  <div class="receipt-manager-modal__body">
    <p class="base-modal__subtitle">
      Please contact the submitter and make sure they call their credit card
      company to reverse this charge. We do not recommended approving this
      expense until you see the corresponding credit in your approval queue.
    </p>
    <ems-base-button-group>
      <ems-base-button
        (click)="onCancel()"
        label="Cancel"
        buttonType="tertiary"
        variant="inverted"
      >
      </ems-base-button>
      <ems-base-button
        (click)="onContinue()"
        label="Approve"
        buttonType="primary"
        variant="success"
      >
      </ems-base-button>
    </ems-base-button-group>
  </div>
</div>
