import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { OauthTokenService } from '../services/oauth-token.service';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class RedirectToDashboardGuard {
  constructor(
    private tokenService: OauthTokenService,
    private router: Router
  ) {}

  public canActivate() {
    return this.tokenService.getAccessToken()
      .pipe(
        take(1),
        map((accessToken?: string) => {
          if(accessToken) return this.router.navigate(['dashboard']);

          return true;
        }),
        catchError((error: Error) => {
          console.error(error);
          return this.tokenService.goToLogin();
        })
      );
  }
}

