import { createSelector } from "@ngrx/store";
import { selectDashboardState, DashboardState } from "../reducers/state";
import { Expense, TravelTicket } from "@ems-gui/shared/util-core";

export const selectDashboardTravelTicketState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.travelTicket
);

export const selectTravelTicket = createSelector(
  selectDashboardTravelTicketState,
  state => state.travelTicket
);

export const selectTravelTicketLoading = createSelector(
  selectDashboardTravelTicketState,
  state => state.loading
);

export const selectTravelTicketError = createSelector(
  selectDashboardTravelTicketState,
  state => state.error
);

export const selectTravelTicketName = createSelector(
  selectDashboardTravelTicketState,
  state => state.travelTicketName
);

export const selectTravelTicketDetailName = createSelector(
  selectDashboardTravelTicketState,
  state => state.travelTicketDetailName
);

export const selectTravelTicketIsAssigned = createSelector(
  selectDashboardTravelTicketState,
  state => state.isAssigned
);

export const selectTravelTicketAssigned = createSelector(
  selectDashboardState,
  (state) => {
    const expenseId = state.expense.draft.selectedId ||
      state.expense.submitted.selectedId ||
      state.expense.trash.selectedId ||
      state.approval.selectedExpenseId ||
      undefined;
    if(!expenseId) return undefined;
    const expense = <Expense>state.expense.entities[expenseId];
    if(typeof expense.travelTicket === 'string') return undefined;
    return expense.travelTicket;
  }
);

export const selectExpenseWithAssignedTravelTicket = createSelector(
  selectDashboardTravelTicketState,
  state => state.expense
);
