import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectAllActiveFavoritePersons,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectAllJobCodesWithGroupLabels,
  selectAllPersonsExceptCurrentWithGroupLabels,
  selectAllSalesforceCasesWithGroupLabels,
  selectCurrentUser,
  State,
} from '../+state/reducers';
import {
  isLoggedInAsAnApprover,
  selectAllAlternateEmails,
  selectAllProxy,
  selectNotificationSetting,
  selectUserHasProxySelfPermission
} from '../+state/selectors';

@Injectable({ providedIn: 'root' })
export class WebSettingsFacade {
  salesforceCases$: Observable<any> = this.store$.pipe(
    select(selectAllSalesforceCasesWithGroupLabels)
  );
  favoriteSalesforceCases$: Observable<any> = this.store$.pipe(
    select(selectAllFavoriteSalesforceCasesPopulated)
  );
  jobCodes$: Observable<any> = this.store$.select(
    selectAllJobCodesWithGroupLabels
  );
  favoriteJobCodes$: Observable<any> = this.store$.select(
    selectAllFavoriteJobCodesPopulated
  );
  favoritePersons$: Observable<any> = this.store$.pipe(
    select(selectAllActiveFavoritePersons)
  );
  user$: Observable<any> = this.store$.pipe(select(selectCurrentUser));
  persons$: Observable<any> = this.store$.pipe(
    select(selectAllPersonsExceptCurrentWithGroupLabels)
  );
  alternateEmails$: Observable<any> = this.store$.pipe(
    select(selectAllAlternateEmails)
  );
  notificationSetting$: Observable<any> = this.store$.pipe(
    select(selectNotificationSetting)
  );
  proxies$: Observable<any> = this.store$.pipe(select(selectAllProxy));
  updateProxyPermissions$: Observable<any> = this.store$.pipe(
    select(selectUserHasProxySelfPermission)
  );
  isApprover$: Observable<boolean> = this.store$.pipe(
    select(isLoggedInAsAnApprover)
  );

  constructor(private store$: Store<State>) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }
}
