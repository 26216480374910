import { createSelector } from '@ngrx/store';
import * as fromFeedErrorUser from '../reducers/feed-error-user.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardFeedErrorUserState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.feedErrorUser
);

export const {
  selectIds: selectFeedErrorUserIds,
  selectEntities: selectFeedErrorUserEntities,
  selectAll: selectAllFeedErrorUser,
} = fromFeedErrorUser.adapter.getSelectors(selectDashboardFeedErrorUserState);

export const selectFeedErrorAutoSavingStatus = createSelector(
  selectDashboardFeedErrorUserState,
  fromFeedErrorUser.autoSavingStatus
);
