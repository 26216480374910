import { createSelector } from '@ngrx/store';
import * as fromSalesforceCase from '../reducers/salesforce-case.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';
import { SalesforceCase } from '@ems-gui/shared/util-core';

export const selectDashboardSalesforceCaseState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.salesforceCase
);

export const {
  selectIds: selectSalesforceCaseIds,
  selectEntities: selectSalesforceCaseEntities,
  selectAll: selectAllSalesforceCases,
} = fromSalesforceCase.adapter.getSelectors(selectDashboardSalesforceCaseState);

export const selectedSalesforceCaseId = createSelector(
  selectDashboardSalesforceCaseState,
  fromSalesforceCase.getSelectedSalesforceCaseId
);

export const selectedSalesforceCase = createSelector(
  selectSalesforceCaseEntities,
  selectedSalesforceCaseId,
  (salesforceCases, salesforceCaseId) => {
    const currentSalesforceCase =
      salesforceCaseId &&
      Object.keys(salesforceCases).length &&
      salesforceCases[salesforceCaseId];
    if (currentSalesforceCase) {
      return currentSalesforceCase;
    }
  }
);

export const selectSalesforceCasesLoading = createSelector(
  selectDashboardSalesforceCaseState,
  fromSalesforceCase.salesforceCasesLoading
);

export const selectSalesforceSearchMatches = createSelector(
  selectAllSalesforceCases,
  (salesforceCases, props) => {
    const parsed = parseInt(props.search, 10);
    if (props.search && parsed) {
      return salesforceCases.filter((c) =>
        c.case_number.toString().startsWith(props.search)
      );
    } else if (props.search && isNaN(parsed)) {
      return salesforceCases.filter((c) => c.name.startsWith(props.search));
    } else {
      return salesforceCases;
    }
  }
);

export const selectCustomCaseApiError = createSelector(
  selectDashboardSalesforceCaseState,
  (state) => state.error ?? null
)

export const selectFromCustomCaseApi = createSelector(
  selectDashboardSalesforceCaseState,
  (state) => <SalesforceCase | null>state.fromCustomCaseApi ?? null
);
