import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Expense,
  ExpenseType,
  JobCode,
  SalesforceCase, TravelTicket
} from '@ems-gui/shared/util-core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import moment, { MomentInput } from 'moment';

type EK = keyof Expense;
type EV = Expense[keyof Expense];

type ForEachCallback = (k: EK, original: EV, form: EV) => void;
type MapCallback = (k: EK, original: EV, form: EV) => Partial<Expense>;
type FilterCallback = (k: EK, original: EV, form: EV) => boolean;

export class FormManager {
  public original: Partial<Expense>;
  public constructor(
    public formId: string,
    public form: FormGroup,
    private next$: () => void,
    private unsubscribe$: Subject<void>
  ) {
    this.original = this.formValues;
    form.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      tap(() => this.next$())
    ).subscribe();
  }

  public deregister() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private shouldBeNumber(
    value: ExpenseType |
      SalesforceCase |
      JobCode |
      string |
      number |
      null |
      undefined
  ) {
    if(value === undefined || value === null) return 0;
    if(typeof value === 'string') return +value;
    if(typeof value === 'number') return value;
    return value.id;
  }

  private shouldBeCurrency(amount?: string | number | null) {
     if(amount === undefined || amount === null) return undefined;
    if(typeof amount === 'number')
      return `$${(amount / 100).toFixed(2)}`;
    return this.shouldBeCurrency(+amount.replace(/[$.,]/g, ''));
  }

  private shouldBeDate(date: MomentInput) {
    return moment.utc(date).format('YYYY-MM-DDT00:00:00.00Z');
  }

  private getTravelTicketId(travelTicket?: TravelTicket | string | null) {
    if(!travelTicket || typeof travelTicket === 'string') return travelTicket;

    return travelTicket.sfId;
  }

  private convertToValues(values: Partial<Expense>): Expense {
    const expenseForm = structuredClone(values);
    if('ocrMismatch' in expenseForm) delete expenseForm.ocrMismatch;
    return <
      Expense &
      {
        amount: string,
        transactionDate: string,
        travelTicket: TravelTicket | string | undefined | null
      }>{
      ...expenseForm,
      type: this.shouldBeNumber(values.type),
      jobCode: this.shouldBeNumber(values.jobCode),
      salesforceId: this.shouldBeNumber(values.salesforceId),
      amount: this.shouldBeCurrency(values.amount),
      transactionDate: this.shouldBeDate(values.transactionDate),
      travelTicket: this.getTravelTicketId(values.travelTicket)
    };
  }

  public get formValues() {
    return this.convertToValues(this.form.value);
  }

  public get originalValues() {
    return this.convertToValues(this.original);
  }

  public get isDirty() {
    return Object.keys(this.updatedFields()).length > 0;
  }

  public get fieldNames() {
    return <(keyof Expense)[]>Object.keys(this.form.value);
  }

  public onChanges$() {
    return this.form.valueChanges.pipe(
      map(() => this)
    )
  }

  public isFormDirty$() {
    return this.form.valueChanges.pipe(
      map(() => this.isDirty)
    );
  }

  public restore() {
    this.forEach((key, original, form) => {
      if(original !== form) {
        this.form.get(key).setValue(original, { emitEvent: false });
      }
    });
  }

  public update(newFormValue: Partial<Expense>) {
    this.original = this.convertToValues(newFormValue);
    this.restore();
  }

  public forEach(callback: ForEachCallback) {
    this.fieldNames.forEach((key) => {
      callback(<EK>key, <EV>this.original[key], <EV>this.form.value[key]);
    });
  }

  public map(callback: MapCallback) {
    const mappedObject = {};
    Object.keys(this.original).forEach((key) => {
      mappedObject[key] = callback(
        <EK>key,
        <EV>this.original[key],
        <EV>this.form[key]
      );
    });

    return mappedObject;
  }

  public filter(callback: FilterCallback) {
    const filteredObject = {};

    Object.keys(this.formValues).forEach((key) => {
      const formObj = this.formValues;
      const originalObj = { ...this.original };

      const formValue = formObj[key];
      const original = originalObj[key];
      const results = callback(<EK>key, <EV>original, <EV>formValue);
      if(results === true) filteredObject[key] = formValue;
    });
    return filteredObject;
  }

  public updatedFields() {
    const values: Partial<Expense> = this.filter((_key, form, original) => {
      return form !== original
    });
     if('amount' in values && typeof values.amount === 'string')
       values.amount = +(<string>values.amount).replace(/[$.,]/g, '')

    return values;
  }

  public callback(name: string): () => void;
  public callback(name: string, callback: () => void): this;
  public callback(name: string, callback?: () => void) {
     if(!callback) return this[name];
     this[name] = callback;

     return this;
  }

  public processExpense(expense: Expense) {
    const keys = this.fieldNames;
    const entries = Object.entries(expense)
      .filter(([key, value]) => {
        return keys.includes(<keyof Expense>key);
      });

    return this.convertToValues(Object.fromEntries(entries));
  }
}

@Injectable({ providedIn: 'root' })
export class FormManagerService {
  private forms: Map<string, FormManager> = new Map();
  private formSubject$ = new BehaviorSubject<string | null>(null);

  public register(id: string, form: FormGroup) {
    const unsubscribe$ = new Subject<void>();
    const fm = new FormManager(
      id,
      form,
      () => this.formSubject$.next(id),
      unsubscribe$
    );
    this.forms.set(id, fm);
    this.formSubject$.next(id);

    form.valueChanges.pipe(
      takeUntil(unsubscribe$),
      tap(() => this.formSubject$.next(id))
    ).subscribe();

    return fm;
  }

  public has(id: string) {
    return this.forms.has(id);
  }

  public get(id: string) {
    return this.has(id) ? this.forms.get(id) : undefined;
  }

  public getFormManager$(id: string) {
    return this.formSubject$.pipe(
      switchMap((currentId) => {
        if(currentId === id && this.has(id))
          return of(this.get(id));
        return of(undefined);
      })
    );
  }

  public getForm$(id: string) {
    return this.formSubject$.pipe(
      switchMap((currentId) => {
        if(currentId === id && this.has(id))
          return of(this.get(id).form);
        return of(undefined);
      })
    );
  }

  public getOriginal$(id: string) {
    return this.formSubject$.pipe(
      switchMap((currentId) => {
        if(currentId === id && this.has(id))
          return of(this.get(id).original);
        return of(undefined);
      })
    );
  }

  public isFormDirty$(id: string) {
    return this.formSubject$.pipe(
      switchMap((currentId) => {
        if(currentId === id && this.has(id))
          return of(this.get(id).isDirty);
        return of(false);
      })
    );
  }

  public updatedFields$(id: string) {
    return this.formSubject$.pipe(
      switchMap((currentId) => of(
        currentId === id && this.has(id) ?
          <Partial<Expense>>this.get(id).updatedFields() :
          {}
        )
      )
    );
  }

  public callback(formId: string, name: string): () => void;
  public callback(formId: string, name: string, callback: () => void): this;
  public callback(formId: string, name: string, callback?: () => void) {
    const fm = this.get(formId);
    if(!fm)
      throw new Error(`Could not locate Form ID: ${formId}`);

    if(!callback) return fm.callback(name);
    fm.callback(name, callback);

    return this;
  }

  public deregister(id: string) {
    if(this.has(id)) {
      this.get(id).deregister();
      this.forms.delete(id);
    }
  }
}
