<div class="top-bar-banner" *ngIf="proxy">
  <ems-base-banner
    [content]="
      'You are currently using Spense as ' +
      proxy?.name_first +
      ' ' +
      proxy?.name_last
    "
  >
    <ems-base-icon iconName="user" icon></ems-base-icon>
    <ems-base-button
      label="LOG OUT"
      buttonType="tertiary"
      variant="brand"
      size="s"
      actions
      (click)="onRemoveProxy()"
    ></ems-base-button>
  </ems-base-banner>
</div>

<header class="top-bar">
  <div class="menu-icon" (click)="toggleNavMenu()">
    <ems-icon-menu></ems-icon-menu>
  </div>
  <div class="top-bar__location">
    <nav class="base-breadcrumb">
      <ul>
        <li *ngFor="let crumb of breadcrumbs">
          <span class="base-breadcrumb__item" [title]="crumb">
            {{ crumb }}
          </span>
        </li>
        <li *ngIf="openModalName">
          <span
            class="base-breadcrumb__item"
            [title]="getBreadCrumb(openModalName)"
          >
            {{ getBreadCrumb(openModalName) }}
          </span>
        </li>
      </ul>
    </nav>
    <nav class="mobile-nav">
      <p *ngIf="breadcrumbs?.length">{{ breadcrumbs[0] }}</p>
    </nav>
  </div>
  <div class="top-bar__nav">
    <!-- User Utility Nav -->
    <div class="user-nav user-dropdown" [ngClass]="!helpMenuIsActive ? '' : 'is-active'">
      <button
        class="user-nav__button"
        title="Help"
        (click)="toggleHelpMenu()"
      >
        <span class="icon">
          <ems-base-icon iconName="help"></ems-base-icon>
        </span>
        <div class="label">Help</div>
      </button>
      <div class="user-dropdown__menu">
        <div class="user-dropdown__menu-content help-guide-container">
          <span class="help-guide-title">Help</span>
          <ul class="help-guide-list">
            <li *ngFor="let guide of helpGuide" class="help-guide__list-item">
              <a
                [href]=guide.link
                target="_blank"
                class="help-guide__item"
              >
                <span class="help-guide__icon">
                  <ems-base-icon [iconName]="guide.iconName"></ems-base-icon>
                </span>
                <span class="help-guide__item-label">{{guide.name}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- User Dropdown -->
    <div class="user-dropdown user-icon" [ngClass]="!userMenuIsActive ? '' : 'is-active'">
      <button
        class="user-dropdown__button"
        (click)="toggleUserMenu()"
        *ngIf="!proxy"
      >
        <div class="user-dropdown__name">
          {{ user.firstName }} {{ user.lastName }}
        </div>
        <ems-base-avatar
          [firstName]="user.firstName"
          [lastName]="user.lastName"
        >
        </ems-base-avatar>
      </button>
      <button
        class="user-dropdown__button"
        (click)="toggleUserMenu()"
        *ngIf="proxy"
      >
        <div class="user-dropdown__name">
          {{ proxy?.name_first }} {{ proxy?.name_last }}
        </div>
        <ems-base-avatar
          [firstName]="proxy?.name_first"
          [lastName]="proxy?.name_last"
        >
        </ems-base-avatar>
      </button>
      <div class="user-dropdown__menu">
        <div class="user-dropdown__menu-content">
          <!-- Normal User State (Not Proxying) -->
          <ems-base-card-section
            *ngIf="!proxy && !proxyChooserIsActive"
            [squish]="true"
          >
            <ems-user-summary
              [firstName]="user.firstName"
              [lastName]="user.lastName"
              [employeeNumber]="user.employeeNumber"
              [workEmail]="user.email"
              size="l"
            >
            </ems-user-summary>
          </ems-base-card-section>

          <!-- Proxied User (Proxying) -->
          <ems-base-card-section *ngIf="proxy" [squish]="true">
            <ems-user-summary
              [firstName]="proxy?.name_first"
              [lastName]="proxy?.name_last"
              [employeeNumber]="proxy?.employeeNumber"
              [workEmail]="proxy?.work_email"
              [isCurrentProxy]="true"
              size="l"
            >
            </ems-user-summary>
          </ems-base-card-section>

          <!-- Proxy Chooser View -->
          <div class="proxy-chooser" *ngIf="proxyChooserIsActive && !proxy">
            <button
              class="proxy-chooser__item"
              *ngFor="let user of usersForProxy"
              (click)="onChooseProxy(user.id)"
            >
              <ems-user-summary
                [id]="user.id"
                [firstName]="user.name_first"
                [lastName]="user.name_last"
                [employeeNumber]="user.employee_number"
              >
              </ems-user-summary>
            </button>
            <div class="proxy-chooser__item" *ngIf="usersForProxy.length === 0">
              <div class="proxy-chooser__none">
                You are not set up as a proxy.
              </div>
            </div>
          </div>

          <!-- User Dropdown Actions -->
          <ems-base-card-section [squish]="true" *ngIf="!proxyChooserIsActive">
            <ems-base-button-group alignButtons="right">
              <ems-base-button
                label="Log In To Proxy"
                buttonType="tertiary"
                size="s"
                *ngIf="!proxy"
                (click)="toggleProxyChooser()"
              ></ems-base-button>
              <ems-base-button
                label="Back to Main Account"
                buttonType="tertiary"
                size="s"
                *ngIf="proxy"
                (click)="onRemoveProxy()"
              ></ems-base-button>
              <ems-base-button
                label="Log Out"
                buttonType="primary"
                variant="brand"
                (click)="onLogout()"
                size="m"
              ></ems-base-button>
            </ems-base-button-group>
          </ems-base-card-section>

          <!-- Proxy Chooser Actions -->
          <ems-base-card-section
            [squish]="true"
            *ngIf="proxyChooserIsActive && !proxy"
          >
            <ems-base-button-group>
              <ems-base-button
                label="Back"
                buttonType="tertiary"
                size="s"
                (click)="toggleProxyChooser()"
              >
                <ems-base-icon
                  iconName="page-prev"
                  icon-prepend
                ></ems-base-icon>
              </ems-base-button>
            </ems-base-button-group>
          </ems-base-card-section>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="userMenuIsActive"
    class="user-dropdown__overlay"
    (click)="toggleUserMenu()"
  ></div>
  <div
    *ngIf="helpMenuIsActive"
    class="user-dropdown__overlay"
    (click)="toggleHelpMenu()"
  ></div>
</header>
<div class="mobile-proxy-banner" *ngIf="proxy">
  <ems-base-banner
    [content]="
      'You are currently using Spense as ' +
      proxy?.name_first +
      ' ' +
      proxy?.name_last
    "
  >
    <ems-base-icon iconName="user" icon></ems-base-icon>
    <ems-base-button
      label="LOG OUT"
      buttonType="tertiary"
      variant="brand"
      size="s"
      actions
      (click)="onRemoveProxy()"
    ></ems-base-button>
  </ems-base-banner>
</div>
