<div class="base-dropdown" [class.is-active]="dropdownIsActive">
  <!-- Option 1 -->
  <button
    (click)="toggleDropdown()"
    [ngClass]="triggerClasses()"
    [attr.aria-label]="dropdownLabel"
    class="base-button"
  >
    <span class="base-button__label">
      {{ dropdownLabel }}
    </span>
    <span class="base-button__icon is-append">
      <ems-base-icon icon-append iconName="chevron-right"></ems-base-icon>
    </span>
  </button>
  <div
    class="base-dropdown__menu"
    [@dropdown]="dropdownIsActive ? 'open' : 'closed'"
  >
    <div class="base-dropdown__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
