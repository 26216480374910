import { Expense } from '@ems-gui/shared/util-core';
import * as Sentry from '@sentry/angular-ivy'

export function setExpenseOnSentry(expense: Partial<Expense>) {
  Sentry.setTag('expense_id', expense.id ?? 'New Expense');
  Sentry.setTag('expense_vendor', expense.vendor);
  Sentry.setTag('expense_amount', expense.amount);
  Sentry.setTag('expense_description', expense.description);
  Sentry.setTag('expense_address', expense.address);
  Sentry.setTag('expense_custom_case', expense.salesforceId);
  Sentry.setTag('expense_job_code', expense.jobCode);

  Sentry.captureMessage('Adding Expense Tags');
}
