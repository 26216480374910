import { createSelector } from '@ngrx/store';
import * as fromAdmin from '../reducers/admin.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardAdminState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.admin
);

export const {
  selectIds: selectEmployeeIds,
  selectEntities: selectEmployeeEntities,
  selectAll: selectAllEmployees,
} = fromAdmin.adapter.getSelectors(selectDashboardAdminState);

export const selectEmployeesTotalCount = createSelector(
  selectDashboardAdminState,
  fromAdmin.getTotalCount
);

export const selectAdminUsersFilters = createSelector(
  selectDashboardAdminState,
  fromAdmin.filters
);

export const selectAdminLoading = createSelector(
  selectDashboardAdminState,
  fromAdmin.adminLoading
);
