<div class="activity">
  <div class="initials">
    <ems-user-popup
      class="expense-note__user"
      type="avatar"
      [firstName]="authorFirstName"
      [lastName]="authorLastName"
      [proxy]="viaMessage"
      [id]="id"
      [email]="email"
      [phone]=""
      *ngIf="noteTitle !== 'Reimbursed Expense'"
    ></ems-user-popup>
    <div class="spense-avatar" *ngIf="noteTitle === 'Reimbursed Expense'">
      <ems-icon-spense-logo></ems-icon-spense-logo>
    </div>
  </div>
  <div class="expense-note">
    <div class="expense-note__info">
      <ems-user-popup
        class="expense-note__user"
        [firstName]="authorFirstName"
        [lastName]="authorLastName"
        [proxy]="viaMessage"
        [id]="id"
        [email]="email"
        [phone]=""
      ></ems-user-popup>
      <span class="expense-note__date">{{ date }}</span>
    </div>
    <div class="expense-note__details">
      <p class="expense-note__title">{{ noteTitle }}</p>
      <blockquote class="expense-note__content" 
        *ngIf="content !== null && content !== 'null' "
      >
        {{ content }}
      </blockquote>
    </div>
  </div>
</div>
