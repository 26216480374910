import { Component, Input } from '@angular/core';

@Component({
  selector: 'ems-expense-form-layout',
  templateUrl: './expense-form-layout.component.html',
  styleUrls: ['./expense-form-layout.component.scss'],
})
export class ExpenseFormLayoutComponent {
  @Input() detailLayout = false;
  @Input() includeReceipt = true;
}
