import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from '@sec-spec/lib-ng-oauth';
import { SnackbarService } from 'ngx-snackbar';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProxyUserActions, UserActions } from '../actions';
import { errorHandler } from '../error-handler';
@Injectable()
export class UserEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUser, ProxyUserActions.removeProxy),
      switchMap(() => this.apiService.getCurrentUser()),
      map((user) => UserActions.getUserComplete({ user }))
    )
  );

  loginComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserComplete),
      switchMap(({ user }) =>
        this.apiService.getPermissions(user.id).pipe(
          map((res) =>
            UserActions.getPermissionsComplete({ permissions: res })
          ),
          catchError(errorHandler(UserActions.getPermissionsError))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      tap(() => {
        this.auth.logout();
        this.toastrService.clear();
        this.snackbarService.clear();
      }),
      switchMap(() => this.storage.clear()),
      map(() => UserActions.logoutComplete())
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private auth: AuthService,
    private storage: StorageMap,
    private toastrService: ToastrService,
    private snackbarService: SnackbarService
  ) {}
}
