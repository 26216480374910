<div class="details-card" *ngFor="let card of details | keyvalue">
  <ems-base-stack space="l">
    <table>
      <thead>
        <tr>
          <th colspan="2" class="{{ card.key }}">{{ card.key }}</th>
        </tr>
      </thead>
      <tbody class="card-data {{ card.key }}">
        <tr *ngFor="let item of card.value | keyvalue: originalOrder">
          <td class="card-data__label" *ngIf="item.value">{{ item.key }}</td>
          <td class="card-data__data" *ngIf="item.value">{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </ems-base-stack>
</div>
