<div class="receipt-manager-modal">
  <header class="base-modal__header">
    <div class="base-modal__pre-header">
      <p class="base-modal__state-title">Warning</p>
    </div>

    <div class="base-modal__header-content">
      <span class="base-modal__title-icon"
        ><ems-base-icon
          class="base-modal__icon"
          icon-prepend
          iconName="warning"
        ></ems-base-icon
      ></span>
      <h1 class="base-modal__title">Unable to Find Some Receipts</h1>
    </div>
  </header>

  <div class="receipt-manager-modal__body">
    <p class="base-modal__subtitle">
      We did not find receipts for some of the provided IDs.
      <a (click)="onDownloadCsv()">These IDs</a> will be omitted from the file
      if you choose to proceed with the download.
    </p>
    <ems-base-button-group>
      <ems-base-button
        (click)="onCancel()"
        label="Cancel"
        buttonType="tertiary"
        variant="inverted"
      >
      </ems-base-button>
      <ems-base-button
        (click)="onDownloadFile()"
        label="Download"
        buttonType="primary"
        variant="success"
      >
      </ems-base-button>
    </ems-base-button-group>
  </div>
</div>
