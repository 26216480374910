import { Injectable } from '@angular/core';
import { User } from '@ems-gui/shared/util-core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  selectCurrentUser,
  selectedSubmittedWithTypeAndJobCode,
} from '../+state/reducers';
import { State } from '../+state/reducers/state';
import {
  getUser,
  isLoggedInAsAnApprover,
  selectApprovals,
  selectDashboardLoading,
  selectDashboardOverdueApprovalCount,
  selectDashboardUnsubmittedCount,
  selectSubmissions,
  selectUserHasProxyAllPermission
} from '../+state/selectors';

@Injectable({ providedIn: 'root' })
export class WebDashboardFacade {
  user$: Observable<User> = this.store$.pipe(select(getUser));
  submissions$ = this.store$.pipe(select(selectSubmissions));
  approvals$ = this.store$.pipe(select(selectApprovals));
  welcomeMessage$ = this.store$.pipe(
    select(selectCurrentUser),
    map((user: any) => `Hi ${user.name_first},`)
  );
  recentSubmissions$ = this.store$.pipe(
    select(selectedSubmittedWithTypeAndJobCode)
  );
  summaryLoading$ = this.store$.pipe(select(selectDashboardLoading));
  isApprover$: Observable<boolean> = this.store$.pipe(
    select(isLoggedInAsAnApprover)
  );
  hasProxyAll$: Observable<any> = this.store$.pipe(
    select(selectUserHasProxyAllPermission)
  );
  overdueApprovalsCount$ = this.store$.pipe(
    select(selectDashboardOverdueApprovalCount)
  );
  unsubmittedTotalCount$ = this.store$.pipe(
    select(selectDashboardUnsubmittedCount)
  );

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }

  constructor(private store$: Store<State>) {}
}
