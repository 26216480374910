import { Component, Input } from '@angular/core';
import { OnChange } from '@ems-gui/shared/util-core';

interface UserInfo {
  author?: string;
  employee_number?: number;
  work_email?: string;
}

@Component({
  selector: 'ems-expense-note',
  templateUrl: './expense-note.component.html',
  styleUrls: ['./expense-note.component.scss'],
})
export class ExpenseNoteComponent {
  @Input() noteTitle = 'Note Title';
  @Input() date = 'Date';
  @Input() content = 'Content';
  @Input() id;
  @Input() email;
  @Input() action;
  authorName = [];
  viaPosition = null;

  @OnChange(function (this: ExpenseNoteComponent, user: UserInfo | string) {
    this.onUserInput(user);
    this.viaPosition = this.authorName.length
      ? this.authorName.findIndex((word) => word === 'via')
      : null;
  })
  @Input()
  user: UserInfo;

  onUserInput(author) {
    if (typeof author === 'string') {
      this.authorName = author.split(' ');
    } else if (author.author && typeof author.author === 'string') {
      this.authorName = author.author.split(' ');
    }
    return;
  }

  get authorFirstName() {
    if (this.authorName.length === 0) {
      return;
    }
    return this.authorName[0];
  }

  get authorLastName() {
    if (this.authorName.length < 1) return;
    return this.viaPosition > 0
      ? this.authorName.slice(1, this.viaPosition).join(' ')
      : this.authorName.slice(1).join(' ');
  }

  get viaMessage() {
    return this.viaPosition > 0
      ? `${this.authorName.slice(this.viaPosition).join(' ')}`
      : null;
  }
}
