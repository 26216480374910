import { Injectable } from '@angular/core';
import { ApiService } from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProxyAdminActions, ProxyUserActions } from '../actions';
import { errorHandler } from '../error-handler';
import * as fromDashboard from '../reducers';

@Injectable()
export class ProxyEffects {
  getProxyAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyAdminActions.getProxies),
      switchMap(({ user }) =>
        this.apiService.getProxies(user).pipe(
          map((proxies) => ProxyAdminActions.getProxiesComplete({ proxies })),
          catchError(errorHandler(ProxyAdminActions.getProxiesError))
        )
      )
    )
  );

  addProxy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyAdminActions.addProxy),
      switchMap(({ proxy, user }) =>
        this.apiService.addProxy({ proxy: +proxy, user }).pipe(
          map((proxy) => ProxyAdminActions.addProxyComplete({ proxy })),
          catchError(errorHandler(ProxyAdminActions.addProxyError))
        )
      )
    )
  );

  deleteProxy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyAdminActions.deleteProxy),
      switchMap(({ id }) =>
        this.apiService.removeProxy(id).pipe(
          map((proxy) => ProxyAdminActions.deleteProxyComplete({ proxy })),
          catchError(errorHandler(ProxyAdminActions.deleteProxyError))
        )
      )
    )
  );

  getProxyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyUserActions.getProxies),
      withLatestFrom(this.store$.select(fromDashboard.selectCurrentUser)),
      switchMap(([, user]) =>
        this.apiService.getProxies(user.contactId).pipe(
          map((proxies) => ProxyUserActions.getProxiesComplete({ proxies })),
          catchError(errorHandler(ProxyUserActions.getProxiesError))
        )
      )
    )
  );

  addProxyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyUserActions.addProxy),
      withLatestFrom(this.store$.select(fromDashboard.selectCurrentUser)),
      switchMap(([{ proxy }, { user }]) =>
        this.apiService
          .addProxy({
            proxy,
            user: user?.contactId,
          })
          .pipe(
            map((response) =>
              ProxyUserActions.addProxyComplete({ proxy: response })
            ),
            catchError(errorHandler(ProxyUserActions.addProxyError))
          )
      )
    )
  );

  deleteProxyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProxyUserActions.deleteProxy),
      switchMap(({ id }) =>
        this.apiService.removeProxy(id).pipe(
          map((proxy) => ProxyUserActions.deleteProxyComplete({ proxy })),
          catchError(errorHandler(ProxyUserActions.deleteProxyError))
        )
      )
    )
  );

  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store$: Store<fromDashboard.State>
  ) {}
}
