import { Component, Input } from '@angular/core';

@Component({
  selector: 'ems-base-accordion',
  templateUrl: './base-accordion.component.html',
  styleUrls: ['./base-accordion.component.scss'],
})
export class BaseAccordionComponent {
  /**
   * Title of the accordion
   */
  @Input() title = 'Title';
  @Input() description = '';
  @Input() feedLayout = false;
  @Input() lastRow = false;
}
