<div class="section">
  <p class="section-header">Filename</p>
  <p class="filename" (click)="onDownloadFeedFile()">{{ item?.fileName }}</p>
</div>

<div class="section detail">
  <p class="section-header">Details</p>
  <div class="section-details">
    <span class="detail-label">Records Retrieved</span>
    <span class="detail-value">{{ item?.matchingRecords }}</span>
    <span class="detail-label">Expense types Determined</span>
    <span class="detail-value">{{ item?.matchingExpenseTypes }}</span>
  </div>
</div>
<ems-base-accordion
  title="Records Failed to Match:"
  [feedLayout]="true"
  [lastRow]="false"
  [description]="item?.failedToMatch"
>
  <div accordion-body *ngIf="item?.failedToMatchInfos?.length">
    <div *ngFor="let item of item.failedToMatchInfos">
      <div class="additional-details-item">
        <span class="detail-label">Record ID</span>
        <span class="detail-value">{{ item?.recordId }}</span>
        <span class="detail-label">Card Number</span>
        <span class="detail-value">{{ item?.cardNumber }}</span>
        <span class="detail-label">Employee Number</span>
        <span class="detail-value">{{ item?.employeeNum }}</span>
      </div>
    </div>
  </div>
</ems-base-accordion>

<ems-base-accordion
  title="Errors Encountered:"
  [feedLayout]="true"
  [lastRow]="true"
  [description]="item?.errors"
>
  <div accordion-body *ngIf="item?.errorTraces?.length">
    <div *ngFor="let error of item?.errorTraces">
      <div class="error-details">
        <span class="detail-label">Record ID</span>
        <span class="detail-value">{{ error?.recordId }}</span>
        <span class="detail-label">Stack Trace</span>
        <span class="detail-value">
          <ng-container *ngFor="let row of error?.error; let i = index">
            <div class="stack-trace">
              <span class="error-line-number">{{ i + 1 }}</span>
              <span
                class="error-line-value"
                *ngIf="i === 0; else notFirstRow"
                >{{ row }}</span
              >
              <ng-template #notFirstRow>
                <span class="error-line-value"
                  >&nbsp;&nbsp;&nbsp;{{ row }}</span
                >
              </ng-template>
            </div>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</ems-base-accordion>
