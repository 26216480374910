import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-inline',
  templateUrl: './base-inline.component.html',
  styleUrls: ['./base-inline.component.scss'],
})
export class BaseInlineComponent implements OnInit {
  /**
   * Amount of horizontal space between each element
   *
   * Accepts `xxs`, `xs`, `s`, `m`, `l`, `xl`, `xxl`
   */
  @Input() space = 's';

  /**
   * Classnames
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'has-space-xxs': this.space === 'xxs',
      'has-space-xs': this.space === 'xs',
      'has-space-s': this.space === 's',
      'has-space-m': this.space === 'm',
      'has-space-l': this.space === 'l',
      'has-space-xl': this.space === 'xl',
      'has-space-xxl': this.space === 'xxl',
    };
  }
  constructor() {}

  ngOnInit() {}
}
