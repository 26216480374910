<ems-base-tabs
  class="mileage-edit-tabs"
  [hideTabs]="expense?.activity.length === 0"
>
  <ems-base-tab [tabTitle]="'Form'">
    <div [formGroup]="form" content class="mileage-expense-form-edit">
      <!-- Transaction Date -->
      <sis-form-control
        label="Transaction Date"
        inputId="new-expense-transaction-date"
      >
        <ems-datepicker
          inputId="new-expense-transaction-date"
          formControlName="transactionDate"
          [maxDate]="maxDate"
          [attr.isDisabled]="isTrashed"
        ></ems-datepicker>
      </sis-form-control>

      <!-- Odometer at Start of Trip -->
      <div class="field-container odometer-start">
        <sis-form-control
          label="Odometer at Start of Trip"
          inputId="start-mileage"
          [class.input-error]="!isTrashed && (startOdometerReadingIsRequired || startOdometerReadingIsInvalid)"
        >
          <input
            formControlName="startMileage"
            type="text"
            placeholder="Example: 50000"
            [value]="expense?.extraInfo?.start"
            [attr.disabled]="isTrashed"
            [class.input-error]="!isTrashed && (startOdometerReadingIsRequired || startOdometerReadingIsInvalid)"
          />
        </sis-form-control>
        <ems-base-helper-text
          variation="negative"
          [style.visibility]="
            startOdometerReadingIsInvalid || startOdometerReadingIsRequired ? 'visible' : 'hidden'"
        >
          {{
            startOdometerReadingIsRequired
              ? 'Odometer Reading is required.' : startOdometerReadingIsInvalid
              ? 'Please enter a valid odometer reading.' : 'No Error'
          }}
        </ems-base-helper-text>
      </div>

      <!-- Odometer at End of Trip -->
      <div class="field-container odometer-end">
        <sis-form-control
          label="Odometer at End of Trip"
          inputId="end-mileage"
          [class.input-error]="
            !isTrashed && (endOdometerReadingIsRequired || endOdometerReadingIsInvalid || distanceInvalid)"
        >
          <input
            formControlName="endMileage"
            type="text"
            placeholder="Example: 50100"
            [value]="expense?.extraInfo?.end"
            [attr.disabled]="isTrashed"
            [class.input-error]="
              !isTrashed && (endOdometerReadingIsRequired || endOdometerReadingIsInvalid || distanceInvalid)"
          />
        </sis-form-control>
        <ems-base-helper-text
          variation="negative"
          [style.visibility]="
            endOdometerReadingIsRequired || endOdometerReadingIsInvalid || distanceInvalid ? 'visible' : 'hidden'"
        >
          {{
            endOdometerReadingIsRequired
              ? 'Odometer Reading is required.' : endOdometerReadingIsInvalid
              ? 'Please enter a valid odometer reading.' : distanceInvalid
              ? 'End mileage cannot be less than start mileage.' : 'No Error'
          }}
        </ems-base-helper-text>
      </div>

      <!-- Distance (Disabled) -->
      <sis-form-control
        label="Distance"
        inputId="filter-distance"
        class="distance"
      >
        <input
          id="filter-distance"
          type="text"
          placeholder="Enter odometer readings above"
          disabled
          #distance
        />
      </sis-form-control>

      <!-- Estimated Reimbursement (Disabled) -->
      <sis-form-control
        label="Estimated Reimbursement"
        inputId="filter-estimated-reimbursement"
        class="reimbursement"
      >
        <input
          id="filter-estimated-reimbursement"
          type="text"
          placeholder="Calculated based on distance"
          value="{{ reimbursement | convertToDollars | currency }}"
          disabled
        />
      </sis-form-control>

      <!-- Description -->
      <div class="field-container description">
        <sis-form-control
          label="Description"
          inputId="filter-description"
          [class.input-error]="descriptionIsRequired"
        >
          <input
            formControlName="description"
            type="text"
            placeholder="Example: Transporting secure shipment."
            [value]="expense?.description"
            [maxlength]="maxLength"
            [attr.disabled]="isTrashed"
          />
        </sis-form-control>
        <ems-base-helper-text
          variation="negative"
          [style.visibility]="descriptionIsRequired ? 'visible' : 'hidden'"
          >Description is required.
        </ems-base-helper-text>
      </div>

      <!-- Job Code -->
      <div class="field-container job-code">
        <ems-typeahead
          #jobCodeEl
          label="Job Code"
          type="jobCode"
          formControlName="jobCode"
          [items]="jobCodes"
          [favorites]="favorites"
          (click)="onJobCodeClick()"
          (favorite)="onFavoriteJobCode($event)"
          [isDisabled]="isTrashed"
          [isInvalid]="jobCodeIsRequired"
          [canClear]="true"
        ></ems-typeahead>
        <ems-base-helper-text
          variation="negative"
          [style.visibility]="jobCodeIsRequired ? 'visible' : 'hidden'"
          >Job Code is required.
        </ems-base-helper-text>
      </div>

      <!-- Custom Case -->
      <ems-typeahead
        #salesforceEl
        label="Custom Case"
        [optional]="true"
        type="salesforceCase"
        [items]="salesforceCases"
        [favorites]="favoriteSalesforceCases"
        formControlName="salesforceId"
        (favorite)="onFavoriteSalesforceCase($event)"
        [isDisabled]="isTrashed"
        [canClear]="true"
        class="custom-case"
      ></ems-typeahead>

      <!-- Billable -->
      <sis-form-control label="Billable" *ngIf="showBillable" class="billable">
        <sis-two-radio-buttons
          [displayTexts]="['Yes', 'No']"
          [values]="[true, false]"
          [disabled]="isTrashed"
          formControlName="isBillable"
        ></sis-two-radio-buttons>
      </sis-form-control>

      <!-- Add Note -->
      <div class="note">
        <ems-base-inline *ngIf="!showNote">
          <ems-base-icon
            iconName="add-small"
            (click)="onToggleNote()"
            class="note-icon add"
            [class.disabled]="isTrashed"
          ></ems-base-icon>
          <span class="ems-label note-label">Add Note</span>
        </ems-base-inline>
        <ems-base-inline *ngIf="showNote">
          <ems-base-icon
            iconName="add-small"
            (click)="onToggleNote()"
            class="note-icon remove"
            [class.disabled]="isTrashed"
          ></ems-base-icon>
          <span class="ems-label note-label">Note</span>
        </ems-base-inline>
        <div class="ems-field" *ngIf="showNote">
          <div class="ems-control">
            <textarea
              formControlName="message"
              placeholder=""
              class="ems-textarea"
              [value]="expense?.message ? expense?.message : ''"
              [attr.disabled]="isTrashed"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </ems-base-tab>

  <!-- Activity Log -->
  <ems-base-tab
    [tabTitle]="isMobileView ? 'Activity': 'Activity Log'"
    *ngIf="expense?.activity.length > 0"
  >
    <ems-base-stack space="l">
      <ems-base-box *ngFor="let item of expense?.activity">
        <ems-expense-note
          [noteTitle]="item.action"
          [date]="getTimestamp(item.date)"
          [content]="item.message ? item.message : ''"
          [user]="item.author"
          [email]="item.author?.work_email"
          [id]="item.author?.employee_number"
        ></ems-expense-note>
      </ems-base-box>
    </ems-base-stack>
  </ems-base-tab>
</ems-base-tabs>
