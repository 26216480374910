<sis-alert-box class="secondary">
  <ng-container alert-icon>
    <ems-base-icon iconName="warning-filled" class="alert-icon"></ems-base-icon>
  </ng-container>

  <ng-container alert-title>
    <span class="alert-title"> Submitting Credit Card Expenses </span>
    <button
      class="tuggle-button see-more-btn"
      [attr.aria-hidden]="!alertToggle"
      [hidden]="!alertToggle"
      (click)="alertToggle = !alertToggle"
    >
      See more...
    </button>
  </ng-container>

  <ng-container alert-message>
    <div class="collapse-container" [attr.aria-hidden]="alertToggle">
      <div class="collapse-content">
        <p class="alert-message" [attr.aria-hidden]="alertToggle">
          You can now create and submit your company credit card expenses immediately.
          <br /><br />
          In cases where a match isn't found, you'll be notified to complete the process manually.
        </p>
        <button
          class="tuggle-button"
          [attr.aria-hidden]="alertToggle"
          (click)="alertToggle = !alertToggle"
        >
          Hide
        </button>
      </div>
    </div>
  </ng-container>
</sis-alert-box>
