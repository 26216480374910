import {
  SelectComponent
} from "@src/app/sis/components/select/select.component";

export class OptionEntity {
  public title: string;
  public text: string;
  public value: string;
  public selected = false;
  public disabled = false;

  public constructor(
    component: SelectComponent,
    option: string | Partial<OptionEntity>
  ) {
    if(typeof option === 'string') {
      return Object.assign(this, { text: option, value: option });
    }

    const { titleKey, textKey, valueKey } = component;
    if(titleKey) this.title = option[titleKey];
    if(textKey) this.text = option[textKey];
    if(valueKey) this.value = option[valueKey];
    if(this.value === component.value) this.selected = true;
    if(component.isOptionDisabled)
      this.disabled = component.isOptionDisabled(option);
  }
}
