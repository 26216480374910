import { Expense } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getReviewedApprovals = createAction(
  '[Expense - Reviewed] getReviewedApprovals',
  props<{ page: any; sort: any; filters: any }>()
);

export const getReviewedApprovalsComplete = createAction(
  '[Expense - Reviewed] getReviewedApprovalsComplete',
  props<{
    approvals: {
      expenses: Expense[];
      totalCount: number;
    };
  }>()
);

export const getReviewedApprovalsError = createAction(
  '[Expense - Reviewed] getReviewedApprovalsError',
  props<{ error: string }>()
);

export const addReviewedExpense = createAction(
  '[Approval] addReviewedExpense',
  props<{ expense: any }>()
);

export const removeReviewedExpense = createAction(
  '[Expense - Reviewed] removeReviewedExpense',
  props<{ expense: any }>()
);

export const updateReviewed = createAction(
  '[Expense - Reviewed] updateReviewed'
);
