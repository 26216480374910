import { Directive, ElementRef, Pipe, PipeTransform } from "@angular/core";
import moment from 'moment';
/**
 * Directive declaration
 */
@Pipe({
  name: 'expenseDate'
})
/**
 * directive for expense transaction date formatting
 */
export class ExpenseTransactionDatePipe implements PipeTransform {

  protected DATE_FORMAT = 'MM/DD/YY';
  protected USE_UTC = true;

  /**
   *
   * @param { string | Date | number } value - Date to be formatted
   * @param { [ string, boolean ] } args - Allows for two arguments.  The first
   * argument is the format to be used.  The second argument is whether or not
   * to use UTC.
   * The default format is MM/DD/YYYY.
   * The default for UTC is true.
   * @returns { string } - Formatted date.
   */
  transform(
    value: string | Date | number,
    ...args: Array<boolean | string>
  ): string {
    const useFormat = args[0] ?? this.DATE_FORMAT;
    const useUtc = args[1] ?? this.USE_UTC;

    if(typeof useFormat !== 'string') {
      throw new Error('useFormat must be a string')
    }

    if(typeof useUtc !== 'boolean') {
      throw new Error('useUtc must be a boolean')
    }

    return moment(value)
      .utc(!useUtc)
      .format(useFormat);
  }
}
