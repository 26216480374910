import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ems-icon-itemize',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <defs>
        <filter
          id="Rectangle_5472"
          width="24"
          height="25"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" />
          <feGaussianBlur result="blur" stdDeviation="1.5" />
          <feFlood flood-opacity=".2" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
        <style>
          .cls-4,
          .cls-5 {
            stroke: #1d3148;
          }

          .cls-5 {
            stroke-linecap: round;
          }

          .cls-4,
          .cls-5 {
            fill: none;
          }
        </style>
      </defs>
      <g
        id="Group_4639"
        data-name="Group 4639"
        transform="translate(-907.5 -398.5)"
      >
        <g filter="url(#Rectangle_5472)" transform="translate(907.5 398.5)">
          <g
            id="Rectangle_5472-2"
            fill="#fff"
            stroke="#1d3148"
            stroke-linecap="round"
            stroke-linejoin="round"
            data-name="Rectangle 5472"
            transform="translate(4.5 3.5)"
          >
            <rect width="15" height="16" stroke="none" rx="2" />
            <rect width="14" height="15" x=".5" y=".5" fill="none" rx="1.5" />
          </g>
        </g>
        <path
          id="Line_316"
          d="M0 0L6.542 0"
          class="cls-4"
          data-name="Line 316"
          transform="translate(917.36 406.09)"
        />
        <path
          id="Line_317"
          d="M0 0L6.542 0"
          class="cls-4"
          data-name="Line 317"
          transform="translate(917.36 409)"
        />
        <path
          id="Line_318"
          d="M0 0L6.542 0"
          class="cls-4"
          data-name="Line 318"
          transform="translate(917.36 412)"
        />
        <path
          id="Line_319"
          d="M0 0L0 0"
          class="cls-5"
          data-name="Line 319"
          transform="translate(915 406)"
        />
        <path
          id="Line_320"
          d="M0 0L0 0"
          class="cls-5"
          data-name="Line 320"
          transform="translate(915 409)"
        />
        <path
          id="Line_321"
          d="M0 0L0 0"
          class="cls-5"
          data-name="Line 321"
          transform="translate(915 412)"
        />
      </g>
    </svg>
  `,
  styleUrls: ['./icon-itemize.component.scss'],
})
export class IconItemizeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
