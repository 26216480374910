import { Expense } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const selectPhotoLibrary = createAction(
  '[Expense - New Mileage] selectPhotoLibrary'
);

export const selectPhotoLibraryComplete = createAction(
  '[Expense - New Mileage] selectPhotoLibraryComplete',
  props<{ uri: string }>()
);

export const selectPhotoLibraryError = createAction(
  '[Expense - New Mileage] selectPhotoLibraryError',
  props<{ error: string }>()
);

export const takePhoto = createAction('[Expense - New Mileage] takePhoto');

export const takePhotoComplete = createAction(
  '[Expense - New Mileage] takePhotoComplete',
  props<{ uri: string }>()
);

export const takePhotoError = createAction(
  '[Expense - New Mileage] takePhotoError',
  props<{ error: string }>()
);

export const cancel = createAction('[Expense - New Mileage] cancel');

export const cancelComplete = createAction(
  '[Expense - New Mileage] cancelComplete'
);

export const cancelError = createAction(
  '[Expense - New Mileage] cancelError',
  props<{ error: string }>()
);

export const resetForm = createAction('[Expense - NewMileage] resetForm');

export const resetFormComplete = createAction(
  '[Expense - New Mileage] resetFormComplete'
);

export const saveExpenseData = createAction(
  '[Expense - New Mileage] saveExpenseData',
  props<{ expenseData: any }>()
);

export const getEstimatedReimbursement = createAction(
  '[Expense - New Mileage] getEstimatedReimbursement',
  props<{ mileage: any }>()
);

export const getEstimatedReimbursementComplete = createAction(
  '[Expense - New Mileage] getEstimatedReimbursementComplete',
  props<{ reimbursement: any }>()
);

export const getEstimatedReimbursementError = createAction(
  '[Expense - New Mileage] getEstimatedReimbursementError',
  props<{ error: string }>()
);

export const submitOne = createAction('[Expense - New Mileage] submitOne');

export const submitOneComplete = createAction(
  '[Expense - New Mileage] submitOneComplete',
  props<{ expense: any }>()
);

export const submitOneError = createAction(
  '[Expense - New Mileage] submitOneError',
  props<{ error: string }>()
);

export const saveDraft = createAction('[Expense - New Mileage] saveDraft');

export const saveDraftComplete = createAction(
  '[Expense - New Mileage] saveDraftComplete',
  props<{ expense: any }>()
);

export const saveDraftError = createAction(
  '[Expense - New Mileage] saveDraftError',
  props<{ error: string }>()
);

export const submit = createAction(
  '[Expense - New Mileage] submit',
  props<{ expense: any }>()
);

export const submitComplete = createAction(
  '[Expense - New Mileage] submitComplete',
  props<{ expense: any }>()
);

export const submitError = createAction(
  '[Expense - New Mileage] submitError',
  props<{ error: string }>()
);

export const save = createAction(
  '[Expense - New Mileage] save',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithNote = createAction(
  '[Expense - New Mileage] saveWithNote',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithNoteComplete = createAction(
  '[Expense - New Mileage] saveWithNoteComplete',
  props<{ expense: any }>()
);

export const saveWithNoteError = createAction(
  '[Expense - New Mileage] saveWithNoteError',
  props<{ error: string }>()
);

export const saveWithoutNote = createAction(
  '[Expense - New Mileage] saveWithoutNote',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithoutNoteComplete = createAction(
  '[Expense - New Mileage] saveWithoutNoteComplete',
  props<{ expense: any }>()
);

export const saveWithoutNoteError = createAction(
  '[Expense - New Mileage] saveWithoutNoteError',
  props<{ error: string }>()
);

export const trashAutoSaved = createAction(
  '[Expense - New Mileage] trashAutoSaved'
);

export const trashAutoSavedComplete = createAction(
  '[Expense - New Mileage] trashAutoSavedComplete',
  props<{ expense: Expense }>()
);

export const trashAutoSavedError = createAction(
  '[Expense - New Mileage] autoSaveError',
  props<{ error: string }>()
);

export const uploadComplete = createAction(
  '[Expense - New Mileage] uploadComplete',
  props<{ id: number }>()
);

export const getExpenseComplete = createAction(
  '[Expense - New Mileage] getExpenseComplete',
  props<{ expense: Partial<Expense> }>()
);

export const getExpenseError = createAction(
  '[Expense - New Mileage] getExpenseError',
  props<{ error: string }>()
);

export const cancelAddReceipt = createAction(
  '[Expense - New Mileage] cancelAddReceipt'
);
