import { Expense } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getAllPendingApprovals = createAction(
  '[Expense - Pending] getAllPendingApprovals'
);

export const getAllPendingApprovalsComplete = createAction(
  '[Expense - Pending] getAllPendingApprovalsComplete',
  props<{
    approvals: {
      expenses: Expense[];
      totalCount: number;
    };
  }>()
);

export const getAllPendingApprovalsError = createAction(
  '[Expense - Pending] getAllPendingApprovalsError',
  props<{ error: string }>()
);

export const removePendingExpense = createAction(
  '[Expense - Pending] removePendingExpense',
  props<{ expense: any }>()
);

export const addPendingExpense = createAction(
  '[Expense - Pending] addPendingExpense',
  props<{ expense: any }>()
);
