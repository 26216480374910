<sis-alert-box [includeCloseButton]="true" (onClose)="cancel()">
  <ng-container alert-icon>
    <ems-base-icon iconName="warning-filled" class="alert-icon"></ems-base-icon>
  </ng-container>
  <ng-container alert-title>
    <span class="alert-title regular-title">You have unsaved changes</span>
  </ng-container>
  <ng-container alert-message>
    <p class="alert-message">
      Continuing will discard any unsaved changes on this expense.
      <br /><br />
      How do you wish to continue?
    </p>

    <div class="modal-buttons">
      <button class="cancel-button" (click)="cancel()">
        Cancel
      </button>
      <button class="save-button" (click)="saveChanges()">
        Save Changes
      </button>
      <button class="discard-button" (click)="discard()">
        Discard
      </button>
    </div>
  </ng-container>
</sis-alert-box>
