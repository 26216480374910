import { createAction, props } from '@ngrx/store';

export const downloadReceiptUrls = createAction(
  '[Receipt Manager] downloadReceiptUrls',
  props<{ expenses: number[]; action: string; id_type: string; }>()
);

export const downloadReceiptUrlsComplete = createAction(
  '[Receipt Manager] downloadReceiptUrlsComplete',
  props<{ csv: any; file: any }>()
);

export const downloadReceiptUrlsError = createAction(
  '[Receipt Manager] downloadReceiptUrlsError',
  props<{ error: any }>()
);

export const downloadErrorCsv = createAction(
  '[Receipt Manager] downloadErrorCsv'
);

export const downloadErrorCsvComplete = createAction(
  '[Receipt Manager] downloadErrorCsvComplete',
  props<{ file: any }>()
);

export const downloadErrorCsvError = createAction(
  '[Receipt Manager] downloadErrorCsvError',
  props<{ error: any }>()
);

export const downloadReceiptFile = createAction(
  '[Receipt Manager] downloadReceiptFile'
);

export const downloadReceiptFileComplete = createAction(
  '[Receipt Manager] downloadReceiptFileComplete',
  props<{ file: any }>()
);

export const downloadReceiptFileError = createAction(
  '[Receipt Manager] downloadReceiptFileError',
  props<{ error: any }>()
);

export const displayMissingReceiptsAlert = createAction(
  '[Receipt Manager] displayMissingReceiptsAlert'
);

export const displayNoMatchingReceiptsAlert = createAction(
  '[Receipt Manager] displayNoMatchingReceiptsAlert'
);
