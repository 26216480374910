import { Injectable } from '@angular/core';
import { State } from '@ems-gui/expense/util-web-ngrx';
import { Expense } from '@ems-gui/shared/util-core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectAllActiveFavoritePersons,
  selectAllPersonsExceptCurrentWithGroupLabels,
  selectCurrentPendingApprovalsPage,
  selectCurrentReviewedApprovalsPage,
} from '../+state/reducers';
import {
  selectAllExpenseTypes,
  selectDashboardPendingApprovalCount,
  selectDashboardReviewedApprovalCount,
  selectPendingApprovalsTotalCount,
  selectReviewedApprovalsTotalCount,
} from '../+state/selectors';

@Injectable({ providedIn: 'root' })
export class WebApproverFacade {
  reviewedExpenses$: Observable<Expense[]> = this.store$.pipe(
    select(selectCurrentReviewedApprovalsPage)
  );
  expenseTypes$: Observable<any> = this.store$.pipe(
    select(selectAllExpenseTypes)
  );
  totalPendingCount$: Observable<any> = this.store$.pipe(
    select(selectPendingApprovalsTotalCount)
  );
  totalReviewedCount$: Observable<any> = this.store$.pipe(
    select(selectReviewedApprovalsTotalCount)
  );
  approvals$: Observable<Expense[]> = this.store$.pipe(
    select(selectCurrentPendingApprovalsPage)
  );
  totalDashboardReviewedCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardReviewedApprovalCount)
  );
  totalDashboardPendingCount$: Observable<any> = this.store$.pipe(
    select(selectDashboardPendingApprovalCount)
  );
  favoritePersons$: Observable<any> = this.store$.pipe(
    select(selectAllActiveFavoritePersons)
  );
  persons$: Observable<any> = this.store$.pipe(
    select(selectAllPersonsExceptCurrentWithGroupLabels)
  );

  constructor(private store$: Store<State>) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }
}
