import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {
  DiscardChangesAlertComponent
} from '../sis/components/discard-changes-alert/discard-changes-alert.component';
import {
  UnsavedChangesAlertComponent
} from "@src/app/sis/components/unsaved-changes-alert/unsaved-changes-alert.component";

@Injectable()
export class AlertService {
  public constructor(private dialog: MatDialog) {}

  public baseConfig(config: Partial<MatDialogConfig>) {
    return <MatDialogConfig>{
      maxWidth: null,
      ...config
    };
  }

  public getDiscardChangesAlert(save: () => void, discard: () => void) {
    const id = 'discard-changes-alert';
    const data = { save, discard }
    const config = this.baseConfig({ id, data })

    return this.dialog.open(DiscardChangesAlertComponent, config);
  }

  public getUnsavedChangesAlert(
    parentModalId: string,
    saveAndCloseModal: () => void
  ) {
    const id = 'unsaved-changes-modal';
    const data = { parentModalId, saveAndCloseModal };
    const config = this.baseConfig({ id, data });

    return this.dialog.open(UnsavedChangesAlertComponent, config);
  }
}
