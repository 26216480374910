import { Injectable } from "@angular/core";
import { BaseIcons } from "@ems-gui/shared/util-core";
import {
  icons
} from "@libs/expense/ui-web-components/src/lib/base/base-icon/icons";

@Injectable({ providedIn: 'root' })
export class IconsService {
  private iconCollection: Map<string, BaseIcons> = new Map();

  public constructor() {
    icons.forEach((icon) => this.setIcon(icon));
  }

  public getIcon(iconName: string) {
    if(this.hasIcon(iconName))
      return <BaseIcons>this.iconCollection.get(iconName);
  }

  public hasIcon(iconName: string) {
    return this.iconCollection.has(iconName);
  }

  public setIcon(icon: BaseIcons) {
    this.iconCollection.set(icon.name, icon);
  }

  public drop(iconName: string) {
    if(this.hasIcon(iconName))
      this.iconCollection.delete(iconName);
  }

  public length() {
    return this.iconCollection.size;
  }
}
