import { DatePipe, formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DatepickerComponent } from '@ems-gui/expense/ui-web-components';
import { environment } from '@ems-gui/expense/util-web-infrastructure';
import {
  CustomValidators,
  MAX_CHARACTER_LENGTH,
  OnChange,
} from '@ems-gui/shared/util-core';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { State } from '@ems-gui/expense/util-web-ngrx';
import { Store } from '@ngrx/store';
import { FormManagerService } from '@src/app/services/form-manager.service';

@Component({
  selector: 'ems-mileage-expense-form-edit',
  templateUrl: './mileage-expense-form-edit.component.html',
  styleUrls: ['./mileage-expense-form-edit.component.scss'],
  providers: [DatePipe],
})
export class MileageExpenseFormEditComponent
  implements OnInit, OnDestroy, AfterViewInit {
  expenseString: string;
  @Input() formId = 'mileage-expense-modal-edit'
  @OnChange(function (this: MileageExpenseFormEditComponent, expense) {
    delete expense?.updatedAt;

    if (this.expenseString === JSON.stringify(expense)) {
      return;
    }

    this.expenseString = JSON.stringify(expense);

    if (this.expense.message) {
      this.showNote = true;
    }

    if (this.expense.salesforceId) {
      this.showSalesforce = true;
    }

    const jobCode = expense['jobCode'];
    const salesforceId = expense['salesforceId'];

    Object.keys(this.expense).forEach((property) => {
      const propertyIsOther =
        property &&
        property !== 'extraInfo' &&
        property !== 'jobCode' &&
        property !== 'salesforceId' &&
        this.form &&
        this.form.get(property);
      const propertyIsExtraInfo =
        property && property === 'extraInfo' && this.form;
      const propertyIsJobCode =
        property && property === 'jobCode' && jobCode && this.form;
      const propertyIsSalesforceId =
        property && property === 'salesforceId' && salesforceId && this.form;

      if (propertyIsOther) {
        this.form.get(property).setValue(expense[property]);
      } else if (propertyIsExtraInfo) {
        this.form.get('startMileage').setValue(expense.extraInfo.start);
        this.form.get('endMileage').setValue(expense.extraInfo.end);
        this.lastValidStart = +expense.extraInfo.start;
        this.lastValidEnd = +expense.extraInfo.end;
      } else if (propertyIsJobCode) {
        this.form.get('jobCode').setValue(jobCode);
      } else if (propertyIsSalesforceId) {
        this.form.get('salesforceId').setValue(salesforceId.toString());
      }
    });

    /**
     * allow initial submission of status change.
     * wrap the emit in setTimeout so that it occurs
     * in a new event loop
     */
    setTimeout(() => {
      const valid = this.form.status === 'VALID';
      this.mileageStatusChange.emit(valid);
    });
  })
  @Input() expense;
  @Input() isTrashed = null;
  @OnChange<number>(function (
    this: MileageExpenseFormEditComponent,
    dismiss,
    changes
  ) {
    if (dismiss) {
      const dismissCurrentValue = changes.currentValue;
      if (dismissCurrentValue) {
        this.dismissing = true;
        this.form.get('startMileage').setValue(null);
        this.form.get('endMileage').setValue(null);
        this.distance.nativeElement.value = null;
        this.form.get('jobCode').setValue(null);
        this.form.get('description').setValue(null);
        this.form.get('message').setValue(null);
        this.form.get('salesforceId').setValue(null);
        this.form.get('isBillable').setValue(false);
        this.jobCodeEl.clear();
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
        this.showNote = false;
        this.showSalesforce = false;
        this.dismissing = false;
        this.jobCodeClicked = false;
      }
    }
  })
  @Input()
  dismiss;
  @Input() favorites;
  @Input() reimbursement;
  @Input() jobCodes;
  @Input() salesforceCases;
  @Input() favoriteSalesforceCases;
  @Input() proxy;
  @Input() submitted;
  @Input() mileageExpenseType;
  @Output() mileageFormChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() mileageStatusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() getReimbursement: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteJobCode: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteNote: EventEmitter<any> = new EventEmitter<string>();
  @Output() favoriteSalesforce: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup = this.fb.group(
    {
      transactionDate: new FormControl(new Date(), Validators.required),
      description: new FormControl(null,{
        validators: [Validators.required],
        updateOn: 'change'
      }),
      startMileage: new FormControl(null, {
        validators: [ Validators.required, CustomValidators.validOdometerAmount ],
        updateOn: 'change'
      }),
      endMileage: new FormControl(null, {
        validators: [ Validators.required, CustomValidators.validOdometerAmount ],
        updateOn: 'change'
      }),
      isBillable: new FormControl(null, Validators.required),
      jobCode: new FormControl(null, {
        validators: [Validators.required],
      }),
      message: new FormControl(null, {
        updateOn: 'change'
      }),
      salesforceId: new FormControl(null),
    },
    {
      validators: [
        CustomValidators.validDistance('startMileage', 'endMileage'),
      ],
    }
  );
  dismissing = false;
  showNote = false;
  showSalesforce = false;
  unsubscribe$: Subject<void>;
  jobCodeClicked = false;
  maxDate = new Date();
  totalMileage;
  selectedDate;
  readonly maxLength = MAX_CHARACTER_LENGTH;
  @ViewChild('distance', { static: false }) distance: ElementRef;
  @ViewChild('jobCodeEl', { static: false }) jobCodeEl;
  @ViewChild(DatepickerComponent) datepicker: DatepickerComponent;
  lastValidStart = 0;
  lastValidEnd = 0;
  showBillable = false;

  url = `${environment.apiURL}/expenses/`;
  method = 'put';


  smallMaxScreenWidth = 767;
  screenHeight: number;
  screenWidth: number;
  isMobileView:boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth;
     this.isMobileView = this.screenWidth <= this.smallMaxScreenWidth;

  }



  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private store$: Store<State>,
    private readonly fm: FormManagerService
  ) {
    this.unsubscribe$ = new Subject<void>();
    // this.onResize();
  }

  get transactionDate() {
    return this.form.get('transactionDate');
  }

  get jobCode() {
    return this.form.get('jobCode');
  }

  get description() {
    return this.form.get('description');
  }

  get message() {
    return this.form.get('message');
  }

  get startMileage() {
    return this.form.get('startMileage');
  }

  get endMileage() {
    return this.form.get('endMileage');
  }

  get salesforceId() {
    return this.form.get('salesforceId');
  }

  get isBillable() {
    return this.form.get('isBillable');
  }

  get startOdometerReadingIsRequired() {
    const startMileage = this.startMileage;
    return this.submitted && startMileage.errors?.required;
  }

  get startOdometerReadingIsInvalid() {
    const startMileage = this.startMileage;
    return (
      (startMileage.dirty || this.submitted) &&
      startMileage.errors?.amountNotValid
    );
  }

  get endOdometerReadingIsRequired() {
    const endMileage = this.endMileage;
    return this.submitted && endMileage.errors?.required;
  }

  get endOdometerReadingIsInvalid() {
    const endMileage = this.endMileage;
    return (
      (endMileage.dirty || this.submitted) &&
      endMileage.errors?.amountNotValid
    );
  }

  get distanceInvalid() {
    const form = this.form;
    return (
      form.errors?.distanceInvalid && (this.endMileage.dirty || this.submitted)
    );
  }

  get jobCodeIsRequired() {
    const jobCode = this.jobCode;

    return jobCode.invalid && this.submitted;
  }

  get descriptionIsRequired() {
    const description = this.description;

    return description.invalid && this.submitted;
  }

  ngOnInit() {
    this.onResize();
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(
          () => !this.form.pristine && !this.dismissing && !this.isTrashed
        ),
        debounceTime(750),
        distinctUntilChanged(),
        tap((value: any) => {
          const mileageForm = this._handleMileageForm(value);
          this.mileageFormChange.emit(mileageForm);
        })
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.mileageStatusChange.emit(valid);
        })
      )
      .subscribe();

    this.form
      .get('jobCode')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          this.handleJobCodeChange(value);
        })
      )
      .subscribe();

    this.form
      .get('salesforceId')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: any) => {
          const filteredCases = this.salesforceCases.filter((c) => c.id === value);
          // Find the matching jobCode record if custom case ahs approval jobCode
          if(filteredCases.length > 0 && filteredCases[0].expense_approval_job_code !== null) {
            const approval_jobCode = this.jobCodes.filter
              ((j) => j.id === filteredCases[0].expense_approval_job_code
            );
            // if the approval jobCode is part of the dropdown assign jobCode to expense
            if(approval_jobCode.length > 0) {
              this.jobCode.setValue(approval_jobCode[0].id);
            }
          }
        })
      )
      .subscribe();
    const currentForm = this._handleMileageForm(this.form.value);
    this.mileageFormChange.emit(currentForm);
    this.handleJobCodeChange(this.jobCode.value);
    if (this.showBillable) this.isBillable.setValue(this.expense.isBillable);

    this.fm.register(this.formId, this.form);
  }

  ngAfterViewInit() {
    this.selectedDate = new Date(this.expense.transactionDate).toISOString();
    this.checkMileage();
  }

  onToggleNote() {
    if (!this.isTrashed) {
      this.showNote = !this.showNote;
      this.form.get('message').setValue('');
      if (this.expense && this.expense.activityId) {
        this.deleteNote.emit(this.expense.activityId);
      }
    }
  }

  onToggleSalesforce() {
    if (!this.isTrashed) {
      this.showSalesforce = !this.showSalesforce;
      if (!this.showSalesforce) {
        this.form.get('salesforceId').setValue('0');
      }
    }
  }

  onJobCodeClick() {
    if (!this.isTrashed) {
      this.jobCodeClicked = true;
    }
  }

  checkMileage() {
    const start =
      typeof this.startMileage.value == 'string'
        ? +this.startMileage.value?.replace(/,/g, '')
        : this.startMileage.value;
    const end =
      typeof this.endMileage.value == 'string'
        ? +this.endMileage.value?.replace(/,/g, '')
        : this.endMileage.value;

    const mileage = end - start;
    if (
      mileage > 0 &&
      this.distance?.nativeElement
    ) {
      this.distance.nativeElement.value = mileage;
      this.getReimbursement.emit({
        start,
        end,
        transactionDate: this.selectedDate,
      });
      this.startMileage.setErrors(null);
      this.endMileage.setErrors(null);
    }
  }

  onFavoriteJobCode(id) {
    this.favoriteJobCode.emit(id);
  }

  onFavoriteSalesforceCase(id) {
    this.favoriteSalesforce.emit(id);
  }

  getTimestamp(date: number) {
    const beginningTimeToday = new Date(
      formatDate(new Date(), 'mediumDate', 'en-US')
    ).getTime();
    const endingTimeToday = beginningTimeToday + 86399999;
    const beginningTimeYesterday = beginningTimeToday - 86400000;
    const endingTimeYesterday = beginningTimeToday - 1;

    if (date >= beginningTimeToday && date <= endingTimeToday) {
      return `Today at ${formatDate(date, 'shortTime', 'en-US')}`;
    } else if (date >= beginningTimeYesterday && date <= endingTimeYesterday) {
      return `Yesterday at ${formatDate(date, 'shortTime', 'en-US')}`;
    } else {
      return formatDate(date, 'shortDate', 'en-US');
    }
  }

  private _handleMileageForm(value) {
    const form = { ...value, extraInfo: {} };
    // TODO, clean away the creation of new Date. at this point form.transactionDate is an array, with the date in value 0
    form.transactionDate = form.transactionDate
      ? new Date(form.transactionDate).toISOString()
      : this.expense.transactionDate;
    this.selectedDate = new Date(value.transactionDate).toISOString();
    const start =
      typeof value.startMileage === 'string'
        ? value.startMileage?.replace(/,/g, '')
        : value.startMileage;
    const end =
      typeof value.endMileage === 'string'
        ? value.endMileage?.replace(/,/g, '')
        : value.endMileage;
    this.lastValidStart = this.startMileage.valid
      ? +start
      : null;
    this.lastValidEnd =
      this.endMileage.valid && !this.form.errors?.distanceInvalid
        ? +end
        : this.lastValidEnd;
    form.extraInfo.start = this.startMileage.valid ? +start : null;
    form.extraInfo.end = this.endMileage.valid ? +end : null;
    form.paymentType = 'mileage';
    form.type = this.mileageExpenseType.id;
    if (this.expense?.id) {
      form.id = this.expense.id;
    }
    if (this.expense?.activityId) {
      form.activityId = this.expense.activityId;
    }

    if (form.message && this.expense?.status === 'rejected') {
      form.action = 'resubmitted';
    }

    if (this.expense && form.salesforceId !== '0') {
      form.salesforceId = +form.salesforceId;
    }

    if (this.expense?.jobCode?.id) {
      const formJobCode = this.form.get('jobCode').value;
      form.jobCode = formJobCode
        ? formJobCode
        : this.expense.jobCode.id.toString();
    }

    if (this.isTrashed) {
      this.transactionDate.disable({ onlySelf: true, emitEvent: true });
    }

    if (this.expense?.status) {
      form.status = this.expense.status;
    }

    this.checkMileage();

    delete form.startMileage;
    delete form.endMileage;
    return form;
  }

  handleJobCodeChange(value: any) {
    if(value) {
      this.showBillable = true;
      const jobCodeValue = this.jobCodes.find(p => p.id == value);
      this.isBillable.setValue(jobCodeValue.company.billable);
      const form = this._handleMileageForm(this.form.value);
    }
    // Set the jobCode vaue to default when the job code is removed
    else if(value === 0)  {
      this.jobCode.setValue(null);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
