import { Routes } from '@angular/router';
import {
  AccessDeniedComponent,
  DestroyUserService,
  ForgotPasswordComponent,
  LockoutComponent,
  LoginComponent,
  LogoutComponent,
  OauthComponent,
  PasswordChangedComponent,
  ResetPasswordComponent,
  SessionTimeoutComponent,
  UserResolveService
} from '@sec-spec/lib-ng-oauth';
import {
  ExpenseModalEditComponent
} from './core/containers/expense-modal-edit/expense-modal-edit.component';
import {
  ExpenseModalMultiComponent
} from './core/containers/expense-modal-multi/expense-modal-multi.component';
import {
  ExpenseModalComponent
} from './core/containers/expense-modal/expense-modal.component';
import {
  HomePageComponent
} from './core/containers/home-page/home-page.component';
import {
  ViewReceiptComponent
} from './core/containers/view-receipt/view-receipt.component';
import { ActiveUserGuardService } from './guards/active-user-guard.service';
import { AuthExpiredGuard } from "./guards/auth-expired.guard";
import {
  RedirectToDashboardGuard
} from './guards/redirect-to-dashboard.guard';
export const routes: Routes = [
  {
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        component: ForgotPasswordComponent,
        path: 'forgot-password',
      },
      {
        component: LockoutComponent,
        path: 'lockout',
      },
      {
        canActivate: [RedirectToDashboardGuard],
        component: LoginComponent,
        path: 'login',
      },
      {
        canActivate: [DestroyUserService],
        component: LogoutComponent,
        path: 'logout',
      },
      {
        component: PasswordChangedComponent,
        path: 'password-changed',
      },
      {
        canActivate: [AuthExpiredGuard],
        component: ResetPasswordComponent,
        path: 'reset-password',
        resolve: {
          user: UserResolveService,
        },
      },
      {
        component: SessionTimeoutComponent,
        path: 'session-timeout',
      },
    ],
    component: OauthComponent,
    path: 'oauth',
  },
  {
    component: HomePageComponent,
    path: '',
    canActivate: [AuthExpiredGuard, ActiveUserGuardService],
    resolve: {
      user: UserResolveService,
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        component: ExpenseModalEditComponent,
        path: 'expense-modal-edit',
      },
      {
        component: ExpenseModalComponent,
        path: 'expense-modal',
      },
      {
        component: ExpenseModalMultiComponent,
        path: 'expense-modal-multi',
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('@ems-gui/expense/feature-web-admin').then(
            (m) => m.ExpenseFeatureWebAdminModule
          ),
      },
      {
        path: 'expenses',
        loadChildren: () =>
          import('@ems-gui/expense/feature-web-submitter').then(
            (m) => m.ExpenseFeatureWebSubmitterModule
          ),
      },
      {
        path: 'approvals',
        loadChildren: () =>
          import('@ems-gui/expense/feature-web-approver').then(
            (m) => m.ExpenseFeatureWebApproverModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@ems-gui/expense/feature-web-dashboard').then(
            (m) => m.ExpenseFeatureWebDashboardModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@ems-gui/expense/feature-web-settings').then(
            (m) => m.ExpenseFeatureWebSettingsModule
          ),
      },
    ],
  },
  {
    path: 'homepage',
    redirectTo: '',
  },
  {
    component: ViewReceiptComponent,
    path: 'view-receipt/:id',
    canActivate: [AuthExpiredGuard],
    resolve: {
      user: UserResolveService,
    },
  },
  {
    component: AccessDeniedComponent,
    path: 'access-denied',
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];
