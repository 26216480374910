import { Expense, ExpenseFilter } from '@ems-gui/shared/util-core';
import { SaveExpensePayload } from '@libs/shared/util-core/src/lib/models/expense.model';
import { createAction, props } from '@ngrx/store';

export const takePhoto = createAction('[Expense - Draft] takePhoto');

export const takePhotoComplete = createAction(
  '[Expense - Draft] takePhotoComplete',
  props<{ uri: string }>()
);

export const takePhotoError = createAction(
  '[Expense - Draft] takePhotoError',
  props<{ error: string }>()
);

export const savePhoto = createAction(
  '[Expense - Draft] savePhoto',
  props<{ uri: string }>()
);

export const savePhotoComplete = createAction(
  '[Expense - Draft] savePhotoComplete',
  props<{ tokenData?: any }>()
);

export const savePhotoError = createAction(
  '[Expense - Draft] savePhotoError',
  props<{ error: string }>()
);

export const getExpenseData = createAction(
  '[Expense - Draft] getExpenseData',
  props<{ token?: any }>()
);

export const getExpenseDataComplete = createAction(
  '[Expense - Draft] getExpenseDataComplete',
  props<{ expense: Expense }>()
);

export const getExpenseDataError = createAction(
  '[Expense - Draft] getExpenseDataError',
  props<{ error: string }>()
);

export const getReceiptDataByToken = createAction(
  '[Expense - Draft] getReceiptData',
  props<{ token?: any }>()
);

export const getReceiptDataByTokenComplete = createAction(
  '[Expense - Draft] getReceiptDataComplete',
  props<{ expense: Expense }>()
);
export const clearReceiptDataByToken = createAction(
  '[Expense - Draft] clearReceiptDataByToken'
);


export const saveExpenseData = createAction(
  '[Expense - Draft] saveExpenseData',
  props<{ expenseData: any }>()
);

export const selectPhotoLibrary = createAction(
  '[Expense - Draft] selectPhotoLibrary'
);

export const selectPhotoLibraryComplete = createAction(
  '[Expense - Draft] selectPhotoLibraryComplete',
  props<{ uri: string }>()
);

export const selectPhotoLibraryError = createAction(
  '[Expense - Draft] selectPhotoLibraryError',
  props<{ error: string }>()
);

export const submit = createAction(
  '[Expense - Draft] submit',
  props<{ saveExpensePayload: Partial<SaveExpensePayload>  }>()
);

export const multiEditSubmit = createAction(
  '[Expense - Draft] multiEditSubmit',
  props<{ expense: Partial<Expense>, ids: number[] }>()
);
export const multiEditExpensesComplete = createAction(
  '[Expense - Draft] multiEditExpensesComplete',
  props<{ expenses: any }>()
);

export const submitFraudDraft = createAction(
  '[Expense - Draft] submitFraudDraft',
  props<{ form: any }>()
);

export const submitFraudDraftComplete = createAction(
  '[Expense - Draft] submitFraudDraftComplete'
);

export const submitComplete = createAction(
  '[Expense - Draft] submitComplete',
  props<{ expense: any }>()
);

export const submitError = createAction(
  '[Expense - Draft] submitError',
  props<{ error: string }>()
);

export const selectExpenseType = createAction(
  '[Expense - Draft] selectExpenseType',
  props<{ id: any }>()
);

export const selectJobCode = createAction(
  '[Expense - Draft] selectJobCode',
  props<{ id: any }>()
);

export const deleteDraft = createAction(
  '[Expense - Draft] deleteDraft',
  props<{ id: any }>()
);

export const deleteDraftComplete = createAction(
  '[Expense - Draft] deleteDraftComplete',
  props<{ expenses: any }>()
);

export const deleteDraftError = createAction(
  '[Expense - Draft] deleteDraftError',
  props<{ error: string }>()
);

export const getDrafts = createAction(
  '[Expense - Draft] getDrafts',
  props<{
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: ExpenseFilter;
  }>()
);

export const getDraftsComplete = createAction(
  '[Expense - Draft] getDraftsComplete',
  props<{ expenses: Expense[] }>()
);

export const getDraftsError = createAction(
  '[Expense - Draft] getDraftsError',
  props<{ error: string }>()
);

export const saveScrollPosition = createAction(
  '[Expense - Draft] saveScrollPosition',
  props<{ position: number }>()
);

export const editDraft = createAction(
  '[Expense - Draft] editDraft',
  props<{ id: any }>()
);

export const editMultiDraft = createAction(
  '[Expense - Draft] editMultiDraft',
  props<{ expenses: any }>()
);

export const multiEditDraftSubmitComplete = createAction(
  '[Expense - Draft] multiEditDraftSubmitComplete',
  props<{ expenses: any }>()
);

export const editDraftComplete = createAction(
  '[Expense - Draft] editDraftComplete'
);

export const deselectOneDraft = createAction(
  '[Expense - Draft] deselectOneDraft'
);

export const trashDrafts = createAction(
  '[Expense - Draft] trashDrafts',
  props<{ expenses: number[] }>()
);

export const trashDraftsComplete = createAction(
  '[Expense - Draft] trashDraftsComplete',
  props<{ expenses: any }>()
);

export const trashDraftsError = createAction(
  '[Expense - Draft] trashDraftsError',
  props<{ error: string }>()
);

export const saveDraft = createAction('[Expense - Draft] saveDraft');

export const save = createAction(
  '[Expense - Draft] save',
  props<{ expense: Partial<Expense> }>()
);

export const saveExpenseWithReceipt = createAction(
  '[Expense - Draft] saveExpenseWithReceipt',
  props<{ saveExpensePayload: Partial<SaveExpensePayload> }>()
);

export const saveComplete = createAction(
  '[Expense - Draft] saveComplete',
  props<{ expense: any }>()
);

export const submitDrafts = createAction(
  '[Expense - Draft] submitDrafts',
  props<{ expenses: number[] }>()
);

export const submitDraftsComplete = createAction(
  '[Expense - Draft] submitDraftsComplete',
  props<{ expenses: any }>()
);

export const submitDraftsError = createAction(
  '[Expense - Draft] submitDraftsError',
  props<{ error: string }>()
);

export const cancelSelectDrafts = createAction(
  '[Expense - Draft] cancelSelectDraft'
);

export const resubmit = createAction(
  '[Expense - Draft] resubmit',
  props<{ expenses: number[] }>()
);

export const resubmitComplete = createAction(
  '[Expense - Draft] resubmitComplete',
  props<{ expenses: any }>()
);

export const resubmitError = createAction(
  '[Expense - Draft] resubmitError',
  props<{ error: string }>()
);

export const submitAllValidDrafts = createAction(
  '[Expense - Draft] submitAllValidDrafts',
  props<{ drafts: number[]; rejected: number[] }>()
);

export const submitAllValidDraftsComplete = createAction(
  '[Expense - Draft] submitAllValidDraftsComplete',
  props<{ expenses: any }>()
);

export const submitAllValidDraftsError = createAction(
  '[Expense - Draft] submitAllValidDraftsError',
  props<{ error: string }>()
);

export const saveWithNote = createAction(
  '[Expense - Draft] saveWithNote',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithNoteComplete = createAction(
  '[Expense - Draft] saveWithNoteComplete',
  props<{ expense: any }>()
);

export const saveWithNoteError = createAction(
  '[Expense - Draft] saveWithNoteError',
  props<{ error: string }>()
);

export const saveWithoutNote = createAction(
  '[Expense - Draft] saveWithoutNote',
  props<{ expense: Partial<Expense> }>()
);

export const saveWithoutNoteComplete = createAction(
  '[Expense - Draft] saveWithoutNoteComplete',
  props<{ expense: any }>()
);

export const saveWithoutNoteError = createAction(
  '[Expense - Draft] saveWithoutNoteError',
  props<{ error: string }>()
);

export const resetForm = createAction('[Expense - Draft] resetForm');

export const resetFormComplete = createAction(
  '[Expense - Draft] resetFormComplete'
);

export const getItemizedExpenses = createAction(
  '[Expense - Draft] getItemizedExpenses'
);

export const getItemizedExpensesComplete = createAction(
  '[Expense - Draft] getItemizedExpensesComplete',
  props<{ expenses: any }>()
);

export const getItemizedExpensesError = createAction(
  '[Expense - Draft] getItemizedExpensesError',
  props<{ error: string }>()
);

export const updateItemizedExpenses = createAction(
  '[Expense - Draft] updateItemizedExpenses',
  props<{ update: any; create: any; delete: any }>()
);

export const updateItemizedExpensesComplete = createAction(
  '[Expense - Draft] updateItemizedExpensesComplete',
  props<{ expenses: { create: any; update: any; delete: any } }>()
);

export const updateItemizedExpensesError = createAction(
  '[Expense - Draft] updateItemizedExpensesError',
  props<{ error: string }>()
);

export const itemize = createAction(
  '[Expense - Draft] itemize',
  props<{ amounts: any }>()
);

export const itemizeComplete = createAction(
  '[Expense - Draft] itemizeComplete',
  props<{ expenses: Expense[] }>()
);

export const itemizeError = createAction(
  '[Expense - Draft] itemizeError',
  props<{ error: string }>()
);

export const itemizeConfirm = createAction('[Expense - Draft] itemizeConfirm');

export const unableToItemize = createAction(
  '[Expense - Draft] unableToItemize'
);

export const unableToItemizeReset = createAction(
  '[Expense - Draft] unableToItemizeReset'
);

export const getItemizedParentData = createAction(
  '[Expense - Draft] getItemizedParentData',
  props<{ id: number }>()
);

export const getItemizedParentDataComplete = createAction(
  '[Expense - Draft] getItemizedParentDataComplete',
  props<{ amount: number; receipt: string }>()
);

export const getItemizedParentDataError = createAction(
  '[Expense - Draft] getItemizedParentDataError',
  props<{ error: any }>()
);

export const updateAmount = createAction(
  '[Expense - Draft] updateAmount',
  props<{ amount: number }>()
);
export const undoItemization = createAction(
  '[Expense - Draft] undoItemization'
);

export const undoItemizationComplete = createAction(
  '[Expense - Draft] undoItemizationComplete',
  props<{ expenses: any }>()
);

export const undoItemizationError = createAction(
  '[Expense - Draft] undoItemizationError',
  props<{ error: string }>()
);

export const cancelItemization = createAction(
  '[Expense - Draft] cancelItemization'
);

export const removeReceipt = createAction(
  '[Expense - Draft] removeReceipt',
  props<{ id: number }>()
);

export const removeReceiptComplete = createAction(
  '[Expense - Draft] removeReceiptComplete',
  props<{ expense: Partial<Expense> }>()
);

export const removeReceiptError = createAction(
  '[Expense - Draft] removeReceiptError',
  props<{ error: string }>()
);

export const rotateReceipt = createAction(
  '[Expense - Draft] rotateReceipt',
  props<{ angle: number; id: number }>()
);

export const rotateReceiptComplete = createAction(
  '[Expense - Draft] rotateReceiptComplete',
  props<{ expense: Expense }>()
);

export const rotateReceiptError = createAction(
  '[Expense - Draft] rotateReceiptError',
  props<{ error: any }>()
);

export const cancelAddReceipt = createAction(
  '[Expense - Draft] cancelAddReceipt'
);

export const addReceiptToDraftComplete = createAction(
  '[Expense - Draft] addReceiptToDraftComplete',
  props<{ draft: Partial<Expense> }>()
);

export const uploadComplete = createAction(
  '[Expense - Draft] uploadComplete',
  props<{ draft: Partial<Expense> }>()
);

export const getUpdatedDraftComplete = createAction(
  '[Expense - Draft] getUpdatedDraftComplete',
  props<{ expense: Partial<Expense> }>()
);

export const getUpdatedDraftError = createAction(
  '[Expense - Draft] getUpdatedDraftError',
  props<{ error: any }>()
);

export const applyExpenseToState = createAction(
  '[Expense - Draft] applyExpenseToState',
  props<{ expense: Partial<Expense> }>()
);

export const getNoReceiptDataDraft = createAction(
  '[Expense - Draft] getNoReceiptDataDraft'
);

export const getNoReceiptDataDraftComplete = createAction(
  '[Expense - Draft] getNoReceiptDataDraftComplete',
  props<{ expense: Partial<Expense> }>()
);

export const getNoReceiptDataDraftError = createAction(
  '[Expense - Draft] getNoReceiptDataDraftError',
  props<{ error: string }>()
);

export const selectDrafts = createAction(
  '[Expense - Draft] selectDrafts',
  props<{ ids: number[] }>()
);

export const selectRejectedExpenses = createAction(
  '[Expense - Draft] selectRejectedExpenses',
  props<{ ids: number[] }>()
);

