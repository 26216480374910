<div class="user-name" [ngClass]="type">
  <span
    *ngIf="type !== 'avatar'"
    (click)="onShowclick()"
    class="user-name__name"
  >
    {{ firstName }} {{ lastName }}
    <span *ngIf="proxy" class="user-name__proxy">({{ proxy }})</span>
  </span>
  <ems-base-avatar
    *ngIf="type === 'avatar'"
    firstName="{{ firstName }}"
    lastName="{{ lastName }}"
    (click)="onShowclick()"
  ></ems-base-avatar>
  <div
    *ngIf="isActive"
    class="user-popup"
    [class.vertical-layout]="verticalLayout"
  >
    <div class="user-popup__content" [class.vertical-layout]="verticalLayout">
      <div class="user-popup__avatar" [class.vertical-layout]="verticalLayout">
        <ems-base-avatar
          firstName="{{ firstName }}"
          lastName="{{ lastName }}"
        ></ems-base-avatar>
      </div>
      <p class="user-popup__name" [class.vertical-layout]="verticalLayout">
        {{ firstName }} {{ lastName }}
        <span *ngIf="proxy" class="user-popup__proxy"> ({{ proxy }}) </span>
      </p>
      <div class="user-popup__details" [class.vertical-layout]="verticalLayout">
        <p class="user-popup__meta" [class.vertical-layout]="verticalLayout">
          <ems-base-icon
            class="user-popup__icon"
            iconName="user-id"
          ></ems-base-icon>
          <span class="user-popup__meta-text">{{ id }}</span>
        </p>
        <p class="user-popup__meta" [class.vertical-layout]="verticalLayout">
          <ems-base-icon
            class="user-popup__icon"
            iconName="mail"
          ></ems-base-icon>
          <a [href]="mailto" class="user-popup__meta-text">{{ email }}</a>
        </p>
        <p class="user-popup__meta" [class.vertical-layout]="verticalLayout">
          <ems-base-icon
            class="user-popup__icon"
            iconName="phone"
          ></ems-base-icon>
          <span class="user-popup__meta-text">315-200-1000</span>
        </p>
      </div>
    </div>

    <button
      class="user-popup__dismiss"
      title="Dismiss"
      (click)="onDismissClick()"
    >
      <ems-base-icon iconName="times"></ems-base-icon>
    </button>
  </div>
  <div
    *ngIf="isActive"
    class="user-popup__overlay"
    (click)="onDismissClick()"
  ></div>
</div>
