<ems-base-modal
  id="receipt-manager-warn-modal"
  [config]="modalConfig"
  (dismiss)="onCancel()"
  class="dialog-modal"
>
  <ems-receipt-manager-warn-dialog
    (cancel)="onCancel()"
    (downloadCsv)="onDownloadCsv()"
    (downloadFile)="onDownloadFile()"
  ></ems-receipt-manager-warn-dialog>
</ems-base-modal>
