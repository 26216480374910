/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Activity,
  Employee,
  Expense,
  ExpenseFilter,
  ExpenseFilterState,
  ExpenseType,
  JobCode,
  PAGE_SIZE,
  SalesforceCase,
  User,
} from '@ems-gui/shared/util-core';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import * as addressit from 'addressit';
import { selectTransactionFeedItemEntities } from '../selectors';
import { selectAllActivity } from '../selectors/activity.selectors';
import {
  selectAllApprovals,
  selectApprovalEntities,
  selectApprovalSelected,
  selectApprovalSelectedId,
  selectApprovalUploadReceipt,
  selectPendingApprovalsFilters,
  selectPendingPageSize,
  selectReviewedApprovalsFilters,
  selectReviewedPageSize,
} from '../selectors/approval.selectors';
import {
  selectExpenseTypeEntities,
  selectMileageExpenseType
} from '../selectors/expense-type.selectors';
import {
  selectAllExpense,
  selectedDraftId,
  selectExpenseEntities,
  selectFilters,
  selectSelectedSubmittedExpenseId,
  selectSubmittedFilters,
  selectSubmittedPageSize,
  selectTrashPageSize,
  selectUnsubmittedPageSize,
} from '../selectors/expense.selectors';
import { selectAllFavoriteJobCodes } from '../selectors/favorite-job-code.selectors';
import { selectAllFavoritePersonFlat } from '../selectors/favorite-person.selectors';
import { selectAllFavoriteSalesforceCase } from '../selectors/favorite-salesforce-case.selectors';
import { selectAllFeedErrorUser } from '../selectors/feed-error-users.selectors';
import {
  selectAllApproverIds,
  selectAllJobCodes,
  selectAllMasterApproverIds,
  selectJobCodeEntities,
} from '../selectors/job-code.selectors';
import { selectOpenModalId } from '../selectors/layout.selectors';
import {
  selectAllPersons,
  selectAllUserPermissions,
  selectedProxyUser,
  selectedUser,
  selectPersonFilters,
  selectAllSearchedPersons,
  selectSearchedString
} from '../selectors/person.selectors';
import {
  selectAllSalesforceCases,
  selectSalesforceCaseEntities,
} from '../selectors/salesforce-case.selectors';
import { getUser } from '../selectors/user.selectors';
import { Submitter } from "@libs/shared/util-core/src/lib/models/expense.model";

export { reducers, State } from './state';

export const selectCurrentUser = createSelector(
  getUser,
  selectedProxyUser,
  (user: User, proxyUser: any) => {
    let currentUser;
    if (proxyUser) {
      currentUser = {
        ...proxyUser,
        contactId: proxyUser.id,
      };
    } else {
      currentUser = {
        ...user,
        // temporarily added name_first and work_email so both token user and proxy objects work in Settings - User Summary
        name_first: user?.firstName,
        name_last: user?.lastName,
        work_email: user?.email,
      };
    }

    return currentUser;
  }
);

// ---- Favorite Persons

export const selectAllActiveFavoritePersons = createSelector(
  selectAllPersons,
  selectAllFavoritePersonFlat,
  (persons, favorites) => {
    const userIds = persons.map((p) => p.user_id);
    return persons.length && favorites.length
      ? favorites
          .filter((f) => userIds.includes(f.user_id))
          .filter((f) => f.user_id !== null)
      : [];
  }
);

// ---- Persons

export const selectAllPersonsExceptUser = createSelector(
  selectAllPersons,
  getUser,
  (persons, user) => {
    if (!Array.isArray(persons)) return;
    return persons.filter((p) => p['employee_number'] !== user.employeeNumber);
  }
);

export const selectAllPersonsExceptFavoritesAndCurrentUser = createSelector(
  selectAllPersonsExceptUser,
  selectAllActiveFavoritePersons,
  (persons, favorites) => {
    if (!Array.isArray(persons)) return;
    if (favorites.length && persons.length) {
      const ids = favorites.map((f) => f.id);
      return persons.filter((p) => !ids.includes(p.id));
    }
    return persons;
  }
);

export const selectAllPersonsExceptFavorites = createSelector(
  selectAllPersons,
  selectAllActiveFavoritePersons,
  (persons, favorites) => {
    if (!Array.isArray(persons)) return;
    if (favorites.length && persons.length) {
      const ids = favorites.map((f) => f.id);
      return persons.filter((p) => !ids.includes(p.id));
    }
    return persons;
  }
);

export const selectAllPersonsWithGroupLabels = createSelector(
  selectAllPersons,
  selectAllActiveFavoritePersons,
  (persons, favorites) => {

    return getDropDownListWithFavorites(persons, favorites)
  }
);

export const selectAllPersonsExceptCurrentWithGroupLabels = createSelector(
  selectAllPersonsExceptUser,
  selectAllActiveFavoritePersons,
  (persons, favorites) => {
    return getDropDownListWithFavorites(persons, favorites)
  }
);

export const selectSearchedPersonsWithGroupLabels = createSelector(
  selectAllSearchedPersons,
  selectAllActiveFavoritePersons,
  selectSearchedString,
  getUser,
  (persons, favorites, searchString, user) => {
    return getDropDownListWithFavorites(persons, favorites, searchString, user)
  }
);

export const selectAllPersonsExceptFavoritesAndSelectedUser = createSelector(
  selectAllPersonsExceptFavoritesAndCurrentUser,
  selectedUser,
  (persons, selectedUser) => {
    if (selectedUser) {
      return persons.filter((p) => p.id !== selectedUser.id);
    } else {
      return persons;
    }
  }
);

export const selectAllFavoritePersonExceptSelectedUser = createSelector(
  selectAllActiveFavoritePersons,
  selectedUser,
  (persons, selectedUser) => persons.filter((p) => p?.id !== selectedUser?.id)
);

export const selectAllPersonsExceptCurrentAndSelectedUserWithLabels =
  createSelector(
    selectAllPersonsExceptCurrentWithGroupLabels,
    selectedUser,
    (persons, selectedUser) => persons.filter((p) => p?.id !== selectedUser?.id)
  );

export const selectPersonsWithRoles = createSelector(
  selectAllPersonsExceptUser,
  selectAllApproverIds,
  selectAllMasterApproverIds,
  selectAllUserPermissions,
  (persons, approverIds, masterApproverIds, allUserPermissions) => {
    const adminIds =
      allUserPermissions &&
      Object.keys(allUserPermissions)
        .filter((key) => {
          if (allUserPermissions[key]?.includes('spenseAdmin')) {
            return key;
          }
        })
        .map((id) => +id);
    return persons.map((p) => {
      if (adminIds.length && adminIds.includes(p.user_id)) {
        return {
          ...p,
          user_role: 'Admin',
        };
      } else if (masterApproverIds.includes(p.id)) {
        return {
          ...p,
          user_role: 'Master Approver',
        };
      } else if (approverIds.includes(p.id)) {
        return {
          ...p,
          user_role: 'Approver',
        };
      } else {
        return {
          ...p,
          user_role: 'Submitter',
        };
      }
    });
  }
);

export const selectFilteredPersons = createSelector(
  selectPersonsWithRoles,
  selectPersonFilters,
  (persons, filters) => {
    let filteredPersons = [...persons];
    const updatedFilters = {
      ...filters,
    };
    delete updatedFilters.sort;
    if (filters?.user !== null && filters?.user !== undefined) {
      filteredPersons = filteredPersons.filter(
        (person) => person.id === +updatedFilters.user
      );
    }
    if (filters?.role) {
      filteredPersons = filteredPersons.filter(
        (person) => person.user_role.toLowerCase() === updatedFilters.role
      );
    }

    if (filters?.isProxy !== null && filters?.hasProxy === null) {
      filteredPersons = selectFilteredPeople({
        peopleArr: [...filteredPersons],
        filterFor: ['isProxy'],
        filters: {
          ...updatedFilters,
          isProxy: updatedFilters.isProxy,
        },
      });
    } else if (filters?.hasProxy !== null && filters?.isProxy === null) {
      filteredPersons = selectFilteredPeople({
        peopleArr: [...filteredPersons],
        filterFor: ['hasProxy'],
        filters: {
          ...updatedFilters,
          hasProxy: updatedFilters.hasProxy,
        },
      });
    } else if (filters?.isProxy !== null && filters?.hasProxy !== null) {
      filteredPersons = selectFilteredPeople({
        peopleArr: [...filteredPersons],
        filterFor: ['hasProxy', 'isProxy'],
        filters: {
          ...updatedFilters,
          hasProxy: updatedFilters.hasProxy,
          isProxy: updatedFilters.isProxy,
        },
      });
    }

    if (filters.sort.sort === 'desc') {
      return filteredPersons
        .map((person) => {
          return {
            ...person,
            name_last: person.name_last.toLowerCase(),
            name_first: person.name_first.toLowerCase(),
          };
        })
        .sort((a, b): any => {
          if (a[filters?.sort?.colId] < b[filters?.sort?.colId]) {
            return 1;
          }
          if (a[filters?.sort?.colId] > b[filters?.sort?.colId]) {
            return -1;
          }
          if (a[filters?.sort?.colId] === b[filters?.sort?.colId]) {
            return 0;
          }
        })
        .map((person) => {
          return {
            ...person,
            name_last:
              person.name_last.charAt(0).toUpperCase() +
              person.name_last.slice(1),
            name_first:
              person.name_first.charAt(0).toUpperCase() +
              person.name_first.slice(1),
          };
        });
    } else if (filters.sort.sort === 'asc' || !filters.sort.sort) {
      return (
        filteredPersons.length &&
        filteredPersons
          .map((person) => {
            return {
              ...person,
              name_last: person.name_last.toLowerCase(),
              name_first: person.name_first.toLowerCase(),
            };
          })
          .sort((a, b): any => {
            if (a[filters?.sort?.colId] < b[filters?.sort?.colId]) {
              return 1;
            }
            if (a[filters?.sort?.colId] > b[filters?.sort?.colId]) {
              return -1;
            }
            if (a[filters?.sort?.colId] === b[filters?.sort?.colId]) {
              return 0;
            }
          })
          .reverse()
          .map((person) => {
            return {
              ...person,
              name_last:
                person.name_last.charAt(0).toUpperCase() +
                person.name_last.slice(1),
              name_first:
                person.name_first.charAt(0).toUpperCase() +
                person.name_first.slice(1),
            };
          })
      );
    }

    return filteredPersons;
  }
);

// ---- Feed Error Users
export const selectUpdatedFeedErrorUsers = createSelector(
  selectAllFeedErrorUser,
  selectAllPersons,
  (feedErrorUsers, persons) =>
    feedErrorUsers.map((u) => {
      const person = persons.find((p) => p.id === u.user);
      return {
        ...u,
        name_first: person?.name_first,
        name_last: person?.name_last,
        employee_number: person?.employee_number,
      };
    })
);

// ---- Transaction History Feed Item
export const selectedTransactionFeedItem = createSelector(
  selectOpenModalId,
  selectTransactionFeedItemEntities,
  (id, feedItems) => {
    const selectedItem = feedItems[id];
    const createdAt = selectedItem?.createdAt;
    const errorTraces =
      selectedItem &&
      selectedItem.errorTraces.map((t) => {
        return {
          ...t,
          error: t?.error?.split('\n'),
        };
      });

    return {
      ...selectedItem,
      createdAt,
      errorTraces,
    };
  }
);

export const selectAllSubmitted = createSelector<
  unknown,
  (Expense[] | User)[],
  Expense[]
>(selectAllExpense, selectCurrentUser, (expenses: Expense[], user: User) => {
  return expenses.filter((e) => {
    const isCorrectStatus =
      e.status === 'approved' ||
      e.status === 'pending';
    const isSubmitter =
    (
      (user && user.contactId) === getSubmitter(e.submitter) ||
      (user && user.contactId === e.cardholderContactId)
    );
    return isCorrectStatus && isSubmitter;
  });
});

export const selectAllDrafts = createSelector<
  unknown,
  (Expense[] | Partial<User>)[],
  Expense[]
>(
  [selectAllExpense, selectCurrentUser],
  (expenses: Expense[], user: Partial<User>) =>
    expenses.filter((e) => {
      const isCorrectStatus =
        e.status === 'rejected' ||
        e.status === 'parent' ||
        e.status === 'draft';
      const isSubmitter =
        (
          (user && user.contactId) === getSubmitter(e.submitter) ||
          (user && user.contactId === e.cardholderContactId)
        );
      return isCorrectStatus && isSubmitter;
    })
);

export const selectSelectedSubmittedExpense = createSelector(
  selectExpenseEntities,
  selectSelectedSubmittedExpenseId,
  selectExpenseTypeEntities,
  (
    submittedExpenseEntities: Dictionary<Expense>,
    submittedExpenseId: number,
    expenseTypeEntities: Dictionary<ExpenseType>
  ) => {
    const expense = submittedExpenseEntities[submittedExpenseId];
    return {
      ...expense,
      type: expense && expense.type && expenseTypeEntities[expense.type],
    };
  }
);

export const selectedSubmittedWithTypeAndJobCode = createSelector<
  unknown,
  (Expense[] | Dictionary<ExpenseType> | Dictionary<JobCode>)[],
  unknown | Expense[]
>(
  [selectAllSubmitted, selectExpenseTypeEntities, selectJobCodeEntities],
  (
    submitted: Expense[],
    expenseTypeEntities: Dictionary<ExpenseType>,
    jobCodeEntities: Dictionary<JobCode>
  ) => {
    return submitted.map((s) => ({
      ...s,
      transactionDate: s?.transactionDate,
      type: s.type && expenseTypeEntities[s.type],
      jobCode: {
        ...jobCodeEntities[s?.jobCode],
        name: jobCodeEntities[s?.jobCode]?.name?.includes('-')
          ? jobCodeEntities[s?.jobCode]?.name?.substring(
              jobCodeEntities[s?.jobCode]?.name?.indexOf('-') + 2
            )
          : jobCodeEntities[s?.jobCode]?.name,
      },
    }));
  }
);

export const selectCurrentSubmittedPageSorted = createSelector<
  unknown,
  (Expense[] | number | unknown)[],
  Expense[]
>(
  selectedSubmittedWithTypeAndJobCode,
  selectSubmittedPageSize,
  selectSubmittedFilters,
  selectSalesforceCaseEntities,
  (submitted: Expense[], pageSize: number, filters: ExpenseFilterState, salesforceCaseEntities) => {
    const sortDirection = filters.sort.sort;
    const sortKey =
      filters.sort.colId === 'type' ? 'isBillable' : filters.sort.colId;
    const updatedPageSize = pageSize ? pageSize : PAGE_SIZE;
    addSalesforceEntitiesToExpenses(submitted, salesforceCaseEntities);


    if (submitted.length > updatedPageSize) {
      return determineSortType(
        submitted,
        sortKey,
        sortDirection.toUpperCase()
      ).slice(0, updatedPageSize);
    } else {
      return determineSortType(submitted, sortKey, sortDirection.toUpperCase());
    }
  }
);

export const openModalExpense = createSelector<
  unknown,
  (Dictionary<Expense> | number)[],
  Partial<Expense>
>(
  [selectExpenseEntities, selectOpenModalId],
  (entities: Dictionary<Expense>, id: number) => {
    return entities[id] ? entities[id] : {};
  }
);

export const selectAllActivityWithAdditionalDetails = createSelector(
  selectAllActivity,
  selectAllPersons,
  (activities, persons) => {
    return activities.map((a) => {
      const author = persons.find((person) => person?.id === a?.author?.id);
      return {
        ...a,
        author: {
          ...a.author,
          work_email: author?.work_email,
          employee_number: +author?.employee_number,
        },
      };
    });
  }
);

export const openModalExpenseWithActivity = createSelector<
  unknown,
  (
    | Partial<Expense>
    | number
    | unknown[]
    | Dictionary<JobCode>
    | Dictionary<ExpenseType>
  )[],
  unknown | Partial<Expense>
>(
  [
    openModalExpense,
    selectOpenModalId,
    selectAllActivityWithAdditionalDetails,
    selectJobCodeEntities,
    selectExpenseTypeEntities,
  ],
  (
    expense: Partial<Expense>,
    id: number,
    activities: Activity[],
    jobCodeEntities,
    expenseTypeEntities: Dictionary<ExpenseType>
  ) => {
    const selectedExpenseActivity = sortAndFilterActivity(activities, id).map(
      (activity) => {
        return {
          ...activity,
          action: getAction(activity),
        };
      }
    );
    const name = jobCodeEntities[expense?.jobCode]?.name?.includes('-')
      ? jobCodeEntities[expense?.jobCode]?.name?.substr(
          jobCodeEntities[expense?.jobCode]?.name?.indexOf('-') + 2
        )
      : jobCodeEntities[expense?.jobCode]?.name;
    const jobCodeName = `${jobCodeEntities[expense?.jobCode]?.code} - ${name}`;
    const jobCode = {
      ...(jobCodeEntities && jobCodeEntities[expense?.jobCode]),
      name: jobCodeName,
    };
    return {
      ...expense,
      jobCode: expense?.jobCode ? jobCode : '',
      type: expense.type && expenseTypeEntities[expense.type],
      activity: selectedExpenseActivity ? selectedExpenseActivity : [],
    };
  }
);

export const openModalExpenseWithActivityAndSalesforce = createSelector<
  unknown,
  (Partial<Expense> | Dictionary<SalesforceCase> | Array<unknown>)[],
  unknown | Partial<Expense>
>(
  [
    openModalExpenseWithActivity,
    selectSalesforceCaseEntities,
    selectAllPersons,
  ],
  (
    expense: Partial<Expense>,
    salesforceCaseEntities: Dictionary<SalesforceCase>,
    persons: Array<unknown>
  ) => {
    const salesforceId = expense.salesforceId;
    const hasEntities =
      salesforceCaseEntities && Object.keys(salesforceCaseEntities).length;
    const salesforceCase =
      expense &&
      expense.salesforceId &&
      hasEntities &&
      salesforceCaseEntities[expense?.salesforceId];
    const extraInfo =
      typeof expense?.extraInfo === 'string' && expense?.extraInfo.length > 2
        ? JSON.parse(expense?.extraInfo)
        : expense?.extraInfo;
    const address = expense?.address ? parseAddress(expense?.address) : '';
    return {
      ...expense,
      address,
      submitter: expense.submitter,
      extraInfo,
      salesforceId: {
        ...{ id: salesforceId },
        ...salesforceCase,
        case_number: salesforceCase?.case_number?.substring(6),
      },
    };
  }
);

export const selectSubmittedPendingCount = createSelector(
  selectAllSubmitted,
  (submitted) => submitted.filter((s) => s.status === 'pending').length
);

export const selectSubmittedApprovedCount = createSelector(
  selectAllSubmitted,
  (submitted) => submitted.filter((s) => s.status === 'approved').length
);

// --- Draft

export const selectedDraft = createSelector<
  unknown,
  (Dictionary<Expense> | unknown | Dictionary<ExpenseType> | JobCode[])[],
  unknown | Partial<Expense>
>(
  [
    selectExpenseEntities,
    selectedDraftId,
    selectExpenseTypeEntities,
    selectAllJobCodes,
    selectMileageExpenseType
  ],
  (
    draftEntities: Dictionary<Expense>,
    draftId: unknown,
    types: Dictionary<ExpenseType>,
    jobCodes: JobCode[],
    mileageExpenseType: ExpenseType
  ) => {
    const draft = draftEntities[+draftId];
    const jobCode: JobCode = jobCodes.find(
      (c) => c.id === (draft && draft.jobCode)
    );
    if (draft && draft.type !== mileageExpenseType.id) {
      return {
        ...draft,
        jobCode: jobCode,
        type: draft && draft.type && types[draft.type],
      };
    }
  }
);

// NOTE: selector for converting expense's job code to the job code's id and then making that a string so it works
// with the web expense form's nb-choices plugin on the job code field

export const selectedDraftForWebOnly = createSelector<
  unknown,
  (unknown | number | unknown[])[],
  unknown | Partial<Expense>
>(
  [selectedDraft, selectedDraftId, selectAllActivityWithAdditionalDetails],
  (draft: Partial<Expense>, draftId: number, activities: Activity[]) => {
    const draftNotes = filterActivityForNotes(activities, draftId);
    const activity = sortAndFilterActivity(activities, draftId).map((a) => ({
      ...a,
      action: getAction(a),
    }));
    const jobCode =
      draft?.jobCode &&
      draft?.jobCode['id'] &&
      draft?.jobCode['id']?.toString();
    return {
      ...draft,
      transactionDate: draft?.transactionDate
        ? draft?.transactionDate
        : null,
      jobCode,
      message:
        activity && activity.length && activity[0].action !== 'Expense Rejected'
          ? draftNotes && draftNotes.note
          : '',
      activityId:
        activity && activity.length && activity[0].action !== 'Expense Rejected'
          ? draftNotes && draftNotes.id
          : '',
      activity,
    };
  }
);

export const selectedMileageDraft = createSelector<
  unknown,
  (Dictionary<Expense> | unknown | Dictionary<ExpenseType> | JobCode[])[],
  unknown | Partial<Expense>
>(
  [
    selectExpenseEntities,
    selectedDraftId,
    selectExpenseTypeEntities,
    selectAllJobCodes,
    selectMileageExpenseType
  ],
  (
    draftEntities: Dictionary<Expense>,
    draftId: number,
    types,
    jobCodes: JobCode[],
    mileageExpenseType: ExpenseType
  ) => {
    const draft = draftEntities[draftId];
    const extraInfo =
      typeof draft?.extraInfo === 'string'
        ? JSON.parse(draft?.extraInfo)
        : draft?.extraInfo;
    const jobCode = jobCodes.find((c) => c.id === (draft && draft.jobCode));
    if (draft && draft.type === mileageExpenseType.id) {
      return {
        ...draft,
        jobCode: jobCode,
        type: draft?.type && types[draft?.type],
        extraInfo,
      };
    }
  }
);

export const selectedMileageDraftForWebOnly = createSelector<
  unknown,
  (Partial<Expense> | unknown | Activity[])[],
  unknown | Partial<Expense>
>(
  [
    selectedMileageDraft,
    selectedDraftId,
    selectAllActivityWithAdditionalDetails,
  ],
  (draft: Partial<Expense>, id: number, activities: Activity[]) => {
    const draftNotes = filterActivityForNotes(activities, id);
    const activity = sortAndFilterActivity(activities, id).map((a) => ({
      ...a,
      action: getAction(a),
    }));
    const jobCode = <Partial<JobCode>>draft?.jobCode
      ? (<Partial<JobCode>>draft?.jobCode).id
      : null;
    return {
      ...draft,
      transactionDate: draft?.transactionDate,
      jobCode,
      message:
        activity && activity.length && activity[0].action !== 'Expense Rejected'
          ? draftNotes && draftNotes.note
          : '',
      activityId:
        activity && activity.length && activity[0].action !== 'Expense Rejected'
          ? draftNotes && draftNotes.id
          : '',
      activity,
    };
  }
);

/**
 * REFACTOR NEEDED:
 * WHY: Too long, giant functions inside if else
 */
export const selectAllCheckedDrafts = createSelector(
  selectAllDrafts,
  selectExpenseTypeEntities,
  selectMileageExpenseType,
  (drafts, types, mileageExpenseType) => {
    return drafts.map((draft) => {
      let updatedDraft = {};

      const invalid = [];
      if (draft.type === mileageExpenseType.id) {
        Object.keys(draft).forEach((key) => {
          const extraInfo = draft['extraInfo'];
          switch (key) {
            case 'extraInfo':
              if (!extraInfo.start || !extraInfo.end) {
                invalid.push(key);
              }
              break;
            case 'paymentType':
            case 'isBillable':
            case 'jobCode':
            case 'description':
              if (draft[key] === null
                  || draft[key] === 0
                  || draft[key] === ''
                ) {
                invalid.push(key);
              }
              break;
          }
          updatedDraft = {
            ...draft,
            invalid,
          };
        });
      } else {
        Object.keys(draft).forEach((key) => {
          switch (key) {
            case 'amount':
              if (draft['amount'] === 0) {
                invalid.push('amount');
              }
              break;
            case 'vendor':
            case 'type':
            case 'paymentType':
            case 'isBillable':
            case 'jobCode':
            case 'description':
              if (draft[key] === null
                || draft[key] === 0
                || draft[key] === ''
              ) {
                invalid.push(key);
              }
              break;
            case 'hasReceipt':
              if (
                !draft[key] &&
                draft.vendor?.toLowerCase() !== 'foreign transaction fee'
              ) {
                invalid.push(key);
              }
              break;
          }
          updatedDraft = {
            ...draft,
            invalid,
          };
        });
      }

      return {
        ...updatedDraft,
        type: draft.type && types[draft.type],
      };
    });
  }
);

export const selectAllActiveDraftsSorted = createSelector(
  selectAllCheckedDrafts,
  selectJobCodeEntities,
  (drafts: any[], jobCodeEntities) =>
    drafts
      .filter((d) => d.trashedAt === null && d.status !== 'parent')
      .map((expense) => {
        return {
          ...expense,
          transactionDate: expense?.transactionDate
            ? expense?.transactionDate
            : null,
          jobCode: {
            ...jobCodeEntities[expense?.jobCode],
            name: jobCodeEntities[expense?.jobCode]?.name?.includes('-')
              ? jobCodeEntities[expense?.jobCode]?.name?.substr(
                  jobCodeEntities[expense?.jobCode]?.name?.indexOf('-') + 2
                )
              : jobCodeEntities[expense?.jobCode]?.name,
          },
        };
      })
);

export const selectCurrentUnsubmittedPageSorted = createSelector(
  selectAllActiveDraftsSorted,
  selectUnsubmittedPageSize,
  selectFilters,
  selectSalesforceCaseEntities,
  (drafts, pageSize, filters, salesforceCaseEntities) => {
    const sortDirection = filters.unsubmitted.sort.sort;
    const sortKey =
      filters.unsubmitted.sort.colId === 'type'
        ? 'isBillable'
        : filters.unsubmitted.sort.colId;
    const updatedPageSize = pageSize ? pageSize : PAGE_SIZE;
    addSalesforceEntitiesToExpenses(drafts, salesforceCaseEntities);

    if (drafts.length > updatedPageSize) {
      return determineSortType(
        drafts,
        sortKey,
        sortDirection.toUpperCase()
      ).slice(0, updatedPageSize);
    } else {
      return determineSortType(drafts, sortKey, sortDirection.toUpperCase());
    }
  }
);

export const selectRejectedCount = createSelector(
  selectAllDrafts,
  (drafts) =>
    drafts.filter((d) => d.status === 'rejected' && !d.trashedAt).length
);

// ---- Approvals

export const flatApprovals = createSelector<
  unknown,
  (Expense[] | Dictionary<ExpenseType>)[],
  unknown | Expense[]
>(
  [selectAllApprovals, selectExpenseTypeEntities],
  (expenses: Expense[], types: Dictionary<ExpenseType>) => {
    // create a map where the key is the report id and the value is
    // the count of expenses on the report
    const reportMap = {};
    expenses.forEach((expense) => {
      const id = expense.report && expense.report.id;
      // if there is no current value for the report id, then this is the first expense (1)
      // otherwise we are seeing another expense, so this is the current number of expenses + 1
      reportMap[id] = !reportMap[id] ? 1 : reportMap[id] + 1;
    });

    return expenses.map((expense) => {
      // use the reportMap to determine if this is a single expense
      const singleExpense =
        expense.report &&
        expense.report.id &&
        reportMap[expense.report.id] === 1;

      if (expense.report) {
        // flatten the report onto the expense
        return {
          ...expense,
          reportId: expense.report.id,
          singleExpense,
          reportNote: expense.report.note,
          reportName: expense.report.name,
          reportCreatedAt: expense.report.createdAt,
          reportUpdatedAt: expense.report.updatedAt,
          type: types[expense.type],
        };
      } else {
        return {
          ...expense,
        };
      }
    });
  }
);

export const flatPendingApprovals = createSelector<
  unknown,
  (Expense[] | Dictionary<JobCode>)[],
  unknown | Expense[]
>(
  [flatApprovals, selectJobCodeEntities],
  (approvals: Expense[], jobCodeEntities: Dictionary<JobCode>) =>
    approvals
      .filter((a) => a.status === 'pending')
      .map((approval) => {
        const currentDate = new Date().getTime();
        const approvalDate = new Date(approval.submissionDate).getTime();
        const diff = currentDate - approvalDate;
        if (diff > 777600000) {
          approval['isOverdue'] = true;
        } else {
          approval['isOverdue'] = false;
        }
        return {
          ...approval,
          jobCode: {
            ...jobCodeEntities[approval?.jobCode],
            name: jobCodeEntities[approval?.jobCode]?.name?.includes('-')
              ? jobCodeEntities[approval?.jobCode]?.name?.substr(
                  jobCodeEntities[approval?.jobCode]?.name?.indexOf('-') + 2
                )
              : jobCodeEntities[approval?.jobCode]?.name,
          },
        };
      })
);

// FIX: This will get all expenses with this status, not only those from the user as a approver but also from the user as submitter
export const selectAllReviewedApprovals = createSelector<
  unknown,
  (Expense[] | Dictionary<ExpenseType> | Dictionary<JobCode>)[],
  unknown
>(
  selectAllApprovals,
  selectExpenseTypeEntities,
  selectJobCodeEntities,
  (
    expenses: Expense[],
    expenseTypes: Dictionary<ExpenseType>,
    jobCodeEntities: Dictionary<JobCode>
  ) =>
    expenses
      .map((approval) => {
        return {
          ...approval,
          type: expenseTypes && expenseTypes[+approval?.type],
          jobCode: {
            ...jobCodeEntities[approval?.jobCode],
            name: jobCodeEntities[approval?.jobCode]?.name?.includes('-')
              ? jobCodeEntities[approval?.jobCode]?.name?.substr(
                  jobCodeEntities[approval?.jobCode]?.name?.indexOf('-') + 2
                )
              : jobCodeEntities[approval?.jobCode]?.name,
          },
        };
      })
);

export const selectCurrentReviewedApprovalsPage = createSelector<
  unknown,
  (Expense[] | number | unknown)[],
  Expense[]
>(
  [
    selectAllReviewedApprovals,
    selectReviewedPageSize,
    selectReviewedApprovalsFilters,
    selectSalesforceCaseEntities
  ],
  (
    approvals: Expense[],
    pageSize,
    filters: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
    },
    salesforceCaseEntities
  ) => {
    const sortDirection = filters.sort.sort;
    const sortKey =
      filters.sort.colId === 'type' ? 'isBillable' : filters.sort.colId;
    const updatedPageSize = pageSize ? pageSize : PAGE_SIZE;
    const hasEntities =
    salesforceCaseEntities && Object.keys(salesforceCaseEntities).length;
    const approvalsCopy = [...approvals];

    if (hasEntities) {
      approvalsCopy.forEach((approval) => {
        if (approval.salesforceId) {
          const salesforceCase =
            salesforceCaseEntities[approval.salesforceId];
          approval.salesforceId = {
            ...salesforceCase,
            case_number: salesforceCase?.case_number?.substring(6),
          };
        }
      });
    }

    if (approvalsCopy.length > updatedPageSize) {
      return determineSortType(
        approvalsCopy,
        sortKey,
        sortDirection.toUpperCase()
      ).slice(0, updatedPageSize);
    } else {
      return determineSortType(approvalsCopy, sortKey, sortDirection.toUpperCase());
    }
  }
);

export const selectCurrentPendingApprovalsPage = createSelector<
  unknown,
  (Expense[] | number | unknown)[],
  Expense[]
>(
  [
    flatPendingApprovals,
    selectPendingPageSize,
    selectPendingApprovalsFilters,
    selectSalesforceCaseEntities
  ],
  (
    approvals: Expense[],
    pageSize: number,
    filters: {
      page: number;
      sort: {
        colId: string;
        sort: string;
      };
      filters: ExpenseFilter;
    },
    salesforceCaseEntities
  ) => {
    const sortDirection = filters.sort.sort;
    const sortKey =
      filters.sort.colId === 'type' ? 'isBillable' : filters.sort.colId;
    const updatedPageSize = pageSize ? pageSize : PAGE_SIZE;
    const hasEntities =
      salesforceCaseEntities && Object.keys(salesforceCaseEntities).length;
    const approvalsCopy = [...approvals];

    if (hasEntities) {
      approvalsCopy.forEach((approval) => {
        if (approval.salesforceId) {
          const salesforceCase =
            salesforceCaseEntities[approval?.salesforceId];
          approval.salesforceId = {
            ...{ id: approval.salesforceId },
            ...salesforceCase,
            case_number: salesforceCase?.case_number?.substring(6),
          };
        }
      });
    }

    if (approvalsCopy.length > updatedPageSize) {
      return determineSortType(
        approvalsCopy,
        sortKey,
        sortDirection.toUpperCase()
      ).slice(0, updatedPageSize);
    } else {
      return determineSortType(approvalsCopy, sortKey, sortDirection.toUpperCase());
    }
  }
);

export const pendingApprovalWithActivityAndSalesforce = createSelector<
  unknown,
  (
    | Partial<Expense>
    | number
    | unknown[]
    | boolean
    | Dictionary<JobCode>
    | Dictionary<Expense>
    | unknown[]
  )[],
  unknown | Expense
>(
  [
    selectApprovalSelected,
    selectOpenModalId,
    selectAllActivityWithAdditionalDetails,
    selectApprovalUploadReceipt,
    selectJobCodeEntities,
    selectExpenseTypeEntities,
    selectSalesforceCaseEntities,
    selectAllPersons,
  ],
  (
    expense: Partial<Expense>,
    id: number,
    activities: Activity[],
    uploadReceipt,
    jobCodeEntities,
    expenseTypeEntities: Dictionary<ExpenseType>,
    salesforceCaseEntities,
    persons: unknown[]
  ) => {
    const selectedExpenseActivity = sortAndFilterActivity(activities, id).map(
      (activity) => {
        return {
          ...activity,
          action: getAction(activity),
        };
      }
    );
    const salesforceId = expense.salesforceId;
    const hasEntities =
      salesforceCaseEntities && Object.keys(salesforceCaseEntities).length;
    const salesforceCase =
      expense &&
      expense.salesforceId &&
      hasEntities &&
      salesforceCaseEntities[expense.salesforceId];
    const type = expense && expense.type && expenseTypeEntities[expense.type];
    const code = jobCodeEntities[expense?.jobCode]?.code;
    const jobCodeName = jobCodeEntities[expense?.jobCode]?.name?.includes('-')
      ? jobCodeEntities[expense?.jobCode]?.name?.substr(
          jobCodeEntities[expense?.jobCode]?.name?.indexOf('-') + 2
        )
      : jobCodeEntities[expense?.jobCode]?.name;
    const jobCode = {
      ...jobCodeEntities[expense?.jobCode],
      name: `${code} - ${jobCodeName}`,
    };

    const address = expense?.address ? parseAddress(expense?.address) : '';
    return {
      ...expense,
      address,
      transactionDate: expense.transactionDate
        ? expense?.transactionDate
        : null,
      submitter: expense.submitter,
      jobCode,
      type,
      salesforceId: {
        ...{ id: salesforceId },
        ...salesforceCase,
        case_number: salesforceCase?.case_number?.substr(6),
      },
      activity: selectedExpenseActivity ? selectedExpenseActivity : [],
      enableUploadReceipt: uploadReceipt
    };
  }
);

// ---- Web - Reviewed Approvals
export const selectReviewedApproval = createSelector(
  selectApprovalEntities,
  selectApprovalSelectedId,
  (reviewedEntities: any, reviewedId: number) => reviewedEntities[reviewedId]
);

// ---- Web - Trash
export const selectTrashExpensesWithExpenseTypeSorted = createSelector<
  unknown,
  (Expense[] | Dictionary<JobCode>)[],
  unknown[] | Expense[]
>(
  [selectAllCheckedDrafts, selectJobCodeEntities],
  (expenses: Expense[], jobCodeEntities: Dictionary<JobCode>) => {
    return expenses
      .filter((e) => e.trashedAt && !e.voidAt)
      .sort(sortByMostRecentTransactionDate)
      .map((expense) => {
        return {
          ...expense,
          jobCode: {
            ...jobCodeEntities[expense?.jobCode],
            name: jobCodeEntities[expense?.jobCode]?.name?.includes('-')
              ? jobCodeEntities[expense?.jobCode]?.name?.substr(
                  jobCodeEntities[expense?.jobCode]?.name?.indexOf('-') + 2
                )
              : jobCodeEntities[expense?.jobCode]?.name,
          },
        };
      });
  }
);

export const selectCurrentTrashPageSorted = createSelector(
  selectTrashExpensesWithExpenseTypeSorted,
  selectTrashPageSize,
  selectFilters,
  selectSalesforceCaseEntities,
  (expenses, pageSize, filters, salesforceCaseEntities) => {
    const sortDirection = filters.trash.sort.sort;
    const sortKey =
      filters.trash.sort.colId === 'type'
        ? 'isBillable'
        : filters.trash.sort.colId;
    const updatedPageSize = pageSize ? pageSize : PAGE_SIZE;
    addSalesforceEntitiesToExpenses(expenses, salesforceCaseEntities);

    if (expenses.length > updatedPageSize) {
      return determineSortType(
        expenses,
        sortKey,
        sortDirection.toUpperCase()
      ).slice(0, updatedPageSize);
    } else {
      return determineSortType(expenses, sortKey, sortDirection.toUpperCase());
    }
  }
);

export const selectTrashedExpense = createSelector(
  selectExpenseEntities,
  selectOpenModalId,
  (trashedExpenses, id) => trashedExpenses && trashedExpenses[id]
);

export const selectTrashedExpenseWithSalesforceAndActivity = createSelector<
  unknown,
  (Partial<Expense> | Activity[] | Dictionary<JobCode> | User)[],
  unknown | Partial<Expense>
>(
  [
    selectTrashedExpense,
    selectAllActivity,
    selectJobCodeEntities,
    selectCurrentUser,
  ],
  (
    expense: Partial<Expense>,
    activities: Activity[],
    jobCodeEntities: Dictionary<JobCode>,
    currentUser: User
  ) => {
    const activity = sortAndFilterActivity(activities, expense?.id)
      .map((a) => {
        return {
          ...a,
          action: a.jobCode ? getAction(a) : '',
        };
      })
      .filter((a) => a.action);
    const jobCode =
      expense?.jobCode && jobCodeEntities
        ? jobCodeEntities[expense?.jobCode].id.toString()
        : '';
    const salesforceCase = expense?.salesforceId;
    let message = '';
    if (
      (expense?.status === 'draft' && activities.length) ||
      (expense?.status === 'rejected' &&
        activities[activities.length - 1]?.action === 'resubmitted')
    ) {
      message = activities[activities.length - 1].message;
    }
    const regex = /Resubmitted/g;
    if (
      expense?.status === 'rejected' &&
      activity.length &&
      regex.test(activity[0].action)
    ) {
      activity.shift();
    }

    const sub = isSubmitter(expense.submitter) ?
      expense.submitter :
      undefined;
    const submitter = !sub ? '' : `${sub.firstName} ${sub.lastName}`
    const extraInfo =
      typeof expense?.extraInfo === 'string'
        ? JSON.parse(expense?.extraInfo)
        : expense?.extraInfo;

    return {
      ...expense,
      submitter,
      message,
      jobCode,
      extraInfo,
      salesforceId: salesforceCase,
      activity: activity ? activity : [],
    };
  }
);

// ---- Combined
export const selectAllFavoriteJobCodesPopulated = createSelector(
  selectAllFavoriteJobCodes,
  selectJobCodeEntities,
  (favorites, entities) => {
    const hasEntities = Object.keys(entities).length;
    const hasFavorites = favorites.length;
    return hasEntities && hasFavorites
      ? favorites
          .map((f) => entities[f.jobcodeId])
          .map((j: JobCode) => {
            return {
              ...j,
              name: j?.name?.includes('-')
                ? j?.name?.substr(j?.name?.indexOf('-') + 2)
                : j?.name,
            };
          })
      : [];
  }
);

export const selectAllJobCodesWithGroupLabels = createSelector(
  selectAllJobCodes,
  selectAllFavoriteJobCodesPopulated,
  (jobCodes, favorites: any[]) => {
    const ids = favorites.map((f) => f.id);
    const updatedCodes = jobCodes.length
      ? jobCodes
          .filter((jobCode) => {
            return !ids.includes(jobCode.id);
          }).map((jobCode) => {
            return {
              ...jobCode,
              groupName: 'All Codes',
            };
          })
      : [];

    const updatedFavorites = favorites.map((j) => {
        return {
          ...j,
          groupName: 'Favorites',
        };
      });

    const allCodes = [
      ...updatedFavorites,
      ...updatedCodes.map((j: JobCode) => {
        j.name = j.name.includes('-') ?
          j.name.split('-')
            .filter((_, i) => i > 0)
            .join('-')
            .trim() :
          j.name;
        return j;
      })
    ];

    return allCodes.map((j: JobCode) => {
      return {
        ...j,
        name: j.name,
        label: j.code + '  ' + j.name,
      };
    });
  }
);

export const selectJobCodesForDraftDropdown = createSelector(
  selectAllJobCodesWithGroupLabels,
  (jobCodes: any[]) => {
    const filteredJobCodes = jobCodes.filter((jobCode) => {
      return jobCode.include_expense_management === true
        && jobCode.inactive_code === false;
    });
    return filteredJobCodes;
  }
)

export const selectAllJobCodesExceptFavorites = createSelector(
  selectAllJobCodes,
  selectAllFavoriteJobCodes,
  (jobCodes, favorites: any[]) => {
    if (jobCodes.length && favorites.length) {
      const ids = favorites.map((f) => f.jobcodeId);
      return jobCodes
        .filter((jc) => !ids.includes(jc.id))
        .map((j: JobCode) => {
          return {
            ...j,
            name: j.name.includes('-')
              ? j.name?.substr(j.name.indexOf('-') + 2)
              : j.name,
          };
        });
    } else {
      return jobCodes.map((j: JobCode) => {
        return {
          ...j,
          name: j.name.includes('-')
            ? j.name?.substr(j.name.indexOf('-') + 2)
            : j.name,
        };
      });
    }
  }
);

export const selectAllActiveFavoriteSalesforceCases = createSelector(
  selectAllFavoriteSalesforceCase,
  selectAllSalesforceCases,
  (favorites, cases) => {
    const caseIds = cases.map((c) => c.id);
    return favorites.length && caseIds.length
      ? favorites.filter((f) => caseIds.includes(f.salesforceId))
      : [];
  }
);

export const selectAllFavoriteSalesforceCasesPopulated = createSelector(
  selectAllActiveFavoriteSalesforceCases,
  selectSalesforceCaseEntities,
  (favorites, entities) => {
    const hasEntities = Object.keys(entities).length;
    const hasFavorites = favorites.length;
    return hasEntities && hasFavorites
      ? favorites.map((f) => {
          return {
            ...entities[f?.salesforceId],
            case_number: entities[f?.salesforceId]?.case_number?.substr(6),
          };
        })
      : [];
  }
);

export const selectAllSalesforceCasesExceptFavorites = createSelector(
  selectAllSalesforceCases,
  selectAllActiveFavoriteSalesforceCases,
  (salesforceCases, favorites: any[]) => {
    const updatedSalesforceCases = salesforceCases.map((c) => {
      return {
        ...c,
        case_number: c?.case_number?.substr(6),
      };
    });
    if (updatedSalesforceCases.length && favorites.length) {
      const ids = favorites.map((f) => f.salesforceId);
      return updatedSalesforceCases.filter((sc) => !ids.includes(sc.id));
    } else {
      return updatedSalesforceCases;
    }
  }
);

export const selectAllSalesforceCasesWithGroupLabels = createSelector(
  selectAllSalesforceCases,
  selectAllFavoriteSalesforceCasesPopulated,
  (salesforceCases, favorites: any[]) => {
    const favoriteIds = favorites.map((favorite) => favorite.id);
    const updatedSalesforceCases = salesforceCases
      .map((salesforceCase) => {
        return {
          ...salesforceCase,
          case_number:  salesforceCase?.case_number?.substr(6),
          groupName: 'All Cases',
        };
      })
      .filter((salesforceCase) => !favoriteIds.includes(salesforceCase.id));
    const updatedFavorites = favorites.map((favoriteSalesforceCase) => {
      return {
        ...favoriteSalesforceCase,
        groupName: 'Favorites',
      };
    });

    const allCases = [...updatedFavorites, ...updatedSalesforceCases];

    return allCases.map((salesforceCase) => {
      return {
        ...salesforceCase,
        label: salesforceCase.case_number + ' ' + salesforceCase.name,
      };
    });
  }
);

function isSubmitter(sub?: number | Submitter) : sub is Submitter {
  return sub && typeof sub !== 'number';
}

function getSubmitter(submitter?: number | Submitter) {
  return !isSubmitter(submitter) ?
    submitter :
    (<Submitter>submitter).primaryInfo.id;
}

export function sortAndFilterActivity(
  activities: Activity[],
  expenseId: number
) {
  return activities
    .filter((a) => a.expense === expenseId)
    .map((activity: any) => {
      return {
        date: activity.createdAt,
        message: activity.message,
        author: {
          author: setAuthor(activity),
          work_email: activity.author.work_email,
          employee_number: activity.author.employee_number,
        },
        action: activity.action,
        jobCode: activity.jobCode,
        id: activity.id,
      };
    })
    .sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      if (a.date === b.date) {
        return 0;
      }
    });
}

function filterActivityForNotes(activities: Activity[], expenseId: number) {
  try {
    const expenseNotes = activities
    .filter((a) => a.expense === expenseId)
    .map((activity: Activity) => {
      return {
        date: activity.createdAt,
        message: activity.message,
        author: `${activity.author.firstName} ${activity.author.lastName}`,
        expenseFor: activity.expenseFor
          ? `${activity.expenseFor.firstName || ''} ${activity.expenseFor.lastName || ''}`
          : '',
        action: activity.action,
        jobCode: activity.jobCode,
        id: activity.id,
      };
    })
    .filter((a) => a.message !== '' && a.action !== 'rejected')
    .sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      if (a.date === b.date) {
        return 0;
      }
    });

    return expenseNotes && expenseNotes.length
      ? { note: expenseNotes[0].message, id: expenseNotes[0].id }
      : null;
  }
  catch (error) {
    console.error("Error in filterActivityForNotes:", error);
  }
}

export function getAction(activity) {
  const action = activity.action;
  const jobCode = activity.jobCode;

  switch (action) {
    case 'approvalEdit':
      return activity.message;
    case 'resubmitted':
      return `Resubmitted Expense to ${jobCode}`;
    case 'approved':
      return 'Approved Expense';
    case 'rejected':
      return 'Rejected Expense';
    case 'reassigned':
      return `Sent Expense to ${jobCode}`;
    case 'Recall':
      return 'Recalled Expense';
  }

  if(action) {
    if(action.toLowerCase().startsWith('send from')) {
      const jobCodes = action.split(':')[1];
      return `Sent Expense from ${jobCodes}`;
    }
    else if(action.toLowerCase().startsWith('submitted')) {
      const jobCode = action.split(':')[1];
      return `Submitted Expense to ${jobCode}`;
    }
    else if(action.toLowerCase().startsWith('receipt uploaded')) {
      return `Uploaded new receipt`;
    }
    else if(action.toLowerCase().startsWith('changed custom case')) {
      return action;
    }
  }

  return '';
}

function setAuthor(activity) {

  const hasData = Boolean(activity);
  const hasAuthor = hasData && activity.author;
  const hasExpenseFor = hasData && activity.expenseFor;
  const notSameAuthor = hasAuthor && hasExpenseFor && activity.expenseFor.id !== activity.author.id;

  if (notSameAuthor) {
    return `${activity.expenseFor.firstName} ${activity.expenseFor.lastName} via ${activity.author.firstName} ${activity.author.lastName}`;
  } else if (hasAuthor) {
    return `${activity.author.firstName} ${activity.author.lastName}`;
  } else {
    return '';
  }
}

function determineSortType(expenses, key, direction) {
  const updatedDirection = direction.toUpperCase();
  switch (key) {
    case 'jobCode':
      return sortByJobCode(expenses, updatedDirection);
    case 'vendor':
      return sortByVendor(expenses, updatedDirection);
    case 'lastName':
      return sortEmployees(expenses, updatedDirection);
    case 'amount':
      return sortByAmount(expenses, updatedDirection);
    default:
      return sortExpenses(expenses, key, updatedDirection);
  }
}

function sortByMostRecentTransactionDate(a, b) {
  if (a.transactionDate < b.transactionDate) {
    return 1;
  }
  if (a.transactionDate > b.transactionDate) {
    return -1;
  }
  if (a.transactionDate === b.transactionDate) {
    return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
  }
}

function sortExpenses(expenses, key, direction) {
  const sortedExpenses = expenses.sort((a, b) => {
    if (a[key] < b[key]) {
      return 1;
    }
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] === b[key] && key === 'transactionDate') {
      return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
    } else if (a[key] === b[key] && key !== 'transactionDate') {
      return 0;
    }
  });
  if (direction === 'DESC') {
    return sortedExpenses;
  } else {
    return sortedExpenses.reverse();
  }
}

function sortByJobCode(expenses, direction) {
  const sortedExpenses = expenses.sort((a, b) => {
    if (!a.jobCode.code) {
      return 1;
    }
    if (!b.jobCode.code) {
      return -1;
    }
    if (+a.jobCode.code < +b.jobCode.code) {
      return 1;
    }
    if (+a.jobCode.code > +b.jobCode.code) {
      return -1;
    }
    if (+a.jobCode.code === +b.jobCode.code) {
      return 0;
    }
  });
  if (direction === 'DESC') {
    return sortedExpenses;
  } else {
    return sortedExpenses.reverse();
  }
}

function sortByAmount(expenses, direction) {
  const sortedExpenses = expenses.sort((a, b) => {
    if (+a.amount < +b.amount) {
      return 1;
    }
    if (+a.amount > +b.amount) {
      return -1;
    }
    if (+a.amount === +b.amount) {
      return 0;
    }
  });
  if (direction === 'DESC') {
    return sortedExpenses;
  } else {
    return sortedExpenses.reverse();
  }
}

function sortEmployees(approvals, direction) {
  const sortedApprovals = approvals.sort((a, b) => {
    if (
      a.submitter.lastName.toLowerCase() < b.submitter.lastName.toLowerCase()
    ) {
      return -1;
    }
    if (
      a.submitter.lastName.toLowerCase() > b.submitter.lastName.toLowerCase()
    ) {
      return 1;
    }
    if (
      a.submitter.lastName.toLowerCase() === b.submitter.lastName.toLowerCase()
    ) {
      return 0;
    }
  });

  if (direction === 'ASC') {
    return sortedApprovals;
  } else {
    return sortedApprovals.reverse();
  }
}

function sortByVendor(expenses, direction) {
  const sortedExpenses = expenses.sort((a, b) => {
    if ( (a.vendor === null || a.vendor === undefined)
      && (b.vendor === null || b.vendor === undefined) ) {
      return 0;
    }
    if (a.vendor === null || a.vendor === undefined) {
      return 1;
    }
    if (b.vendor === null || b.vendor === undefined) {
      return -1;
    }
    if (a.vendor.toLowerCase() < b.vendor.toLowerCase()) {
      return -1;
    }
    if (a.vendor.toLowerCase() > b.vendor.toLowerCase()) {
      return 1;
    }
    if (a.vendor.toLowerCase() === b.vendor.toLowerCase()) {
      return 0;
    }
  });

  if (direction === 'ASC') {
    return sortedExpenses;
  } else {
    return sortedExpenses.reverse();
  }
}

function selectFilteredPeople({ peopleArr, filterFor, filters }) {
  if (!Array.isArray(peopleArr)) return;
  let filterOptions = [...filterFor];
  if (filterOptions.length === 1) {
    return peopleArr.filter(
      (person) => person[filterOptions[0]] === filters[filterOptions[0]]
    );
  } else {
    return peopleArr.filter((person) => {
      return (
        person['hasProxy'] === filters['hasProxy'] &&
        person['isProxy'] === filters['isProxy']
      );
    });
  }
}

function parseAddress(address: string): string {
  const addressObj = addressit(address);
  const streetNum = addressObj.number ? addressObj.number : '';
  const street = addressObj.street
    ? ` ${addressObj.street
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}`
    : '';
  const city = addressObj.regions.length
    ? addressObj.regions[0]
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    : '';
  const state = addressObj.state ? `, ${addressObj.state}` : '';
  const zip = addressObj.postalcode ? ` ${addressObj.postalcode}` : '';
  let parsedAddress = '';
  if (street && city) {
    parsedAddress = `${streetNum}${street}#${city}${state}${zip}`;
  } else {
    parsedAddress = `${streetNum}${street}${city}${state}${zip}`;
  }

  return parsedAddress;
}

function addSalesforceEntitiesToExpenses(expenses, salesforceCaseEntities) {
  const hasEntities = salesforceCaseEntities && Object.keys(salesforceCaseEntities).length;
  if (hasEntities) {
    expenses.forEach((expense) => {
      if (expense.salesforceId) {
        const salesforceCase = salesforceCaseEntities[expense?.salesforceId];
        expense.salesforceId = {
          ...{ id: expense.salesforceId},
          ...salesforceCase,
          case_number: salesforceCase?.case_number?.substring(6)
        }
      }
    })
  }
}

function getDropDownListWithFavorites(persons, favorites, searchString?: string, currentUser?) {
  if (!Array.isArray(persons)) return;
  const ids = favorites.map((f) => f.id);

  let updatedPersons = persons.length
    ? persons
        .filter((p) => !ids.includes(p.id))
        .map((p) => {
          const employeeFullName = `${p.name_first} ${p.name_last}`;
          return {
            ...p,
            groupName: 'All Employees',
            name: employeeFullName,
            label: `${p.employee_number} ${employeeFullName}`,
          };
        })
    : [];
  if (currentUser && currentUser.employeeNumber) {
    updatedPersons = updatedPersons.filter(
      p => p.employee_number !== currentUser.employeeNumber
    );
  }
  let updatedFavorites = favorites
    .map((f) => {
      const employeeFullName = `${f.name_first} ${f.name_last}`;
      return {
        ...f,
        groupName: 'Favorites',
        name: employeeFullName,
        label: `${f.employee_number} ${employeeFullName}`,
      };
    });

  if (searchString && searchString.trim().length > 0) {
    const searchStringLower = searchString.toLowerCase();
    updatedFavorites = updatedFavorites.filter(f => {
      const employeeFullName = `${f.name_first} ${f.name_last}`;
      return employeeFullName.toLowerCase().includes(searchStringLower) ||
             f.employee_number.toLowerCase().includes(searchStringLower);
    });
  }

  return [...updatedFavorites, ...updatedPersons];
}
