import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status-display',
  templateUrl: './status-display.component.html',
})
export class StatusDisplayComponent implements OnInit {
  @Input() expense;

  status = {
    displayedValue: '',
    textStyleVariation: '',
  };

  ngOnInit(): void {
    this.getStatusElement(this.expense);
  }

  getStatusElement(expense) {
    if (!expense.fraudulent) {
      if (
        expense.status === 'draft' &&
        expense.paymentType === 'company' &&
        expense.cardVerified
      ) {
        this.status.displayedValue = 'CC Feed Auto Draft';
        this.status.textStyleVariation = 'muted';
      } else if (expense.status === 'draft' && expense.paymentType === 'user') {
        this.status.displayedValue = 'Pending CC Match';
        this.status.textStyleVariation = 'positive';
      } else if (expense.status === 'draft') {
        this.status.displayedValue = 'Draft';
        this.status.textStyleVariation = 'muted';
      } else if (expense.status === 'pending') {
        this.status.displayedValue = 'Pending';
        this.status.textStyleVariation = 'warning';
      } else if (expense.status === 'rejected') {
        this.status.displayedValue = 'Rejected';
        this.status.textStyleVariation = 'negative';
      } else if (expense.status === 'approved') {
        this.status.displayedValue = 'Approved';
        this.status.textStyleVariation = 'positive';
      } else if (expense.status === 'reimbursed') {
        this.status.displayedValue = 'Reimbursed';
        this.status.textStyleVariation = 'positive';
      }
    }
  }
}
