import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ems-base-title',
  templateUrl: './base-title.component.html',
  styleUrls: ['./base-title.component.scss'],
})
export class BaseTitleComponent implements OnInit {
  /**
   * The semantic HTML tag to render. Does not change the visual size
   *
   * Accepts `h2`, `h3`, `h4`, `h5`, `h6`, `p`
   */
  @Input() tag = 'p';

  /**
   * Size of the title
   *
   * Accepts `1`, `2`
   */
  @Input() size = '1';

  @Input() weight = 'normal';

  /**
   * Generates a list of classes based on inputs
   */
  classNames() {
    return {
      'is-size-1': this.size === '1',
      'is-size-2': this.size === '2',
      'is-light': this.weight === 'light',
    };
  }

  constructor() {}

  ngOnInit() {}
}
