import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { selectedProxyId, State } from '@ems-gui/expense/util-web-ngrx';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as Sentry from "@sentry/browser";

@Injectable({
  providedIn: 'root',
})
export class ProxyInterceptor implements HttpInterceptor {
  constructor(private store: Store<State>) {}

  handleError(error: HttpErrorResponse) {
    const errorMsg =
      `ERROR URL = ${error.url}, Status Code = ${error.status}`;
    Sentry.configureScope((scope) => {
      scope.setTransactionName(errorMsg);
    });
    Sentry.captureMessage(error.statusText);
    Sentry.addBreadcrumb({
      message: error.url
    });
    Sentry.captureException(error.error);
    return throwError(error);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.addProxyHeader(req).pipe(
      take(1),
      switchMap((updatedRequest: HttpRequest<any>) => {
        return next.handle(updatedRequest)
          .pipe(catchError(this.handleError));
      })
    );
  }

  addProxyHeader(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.store.pipe(
      select(selectedProxyId),
      take(1),
      switchMap((id: any) => {
        const proxyFor: HttpRequest<any> = id
          ? request.clone({ setHeaders: { 'x-proxy-for': id.toString() } })
          : request;
        return of(proxyFor);
      })
    );
  }
}
