import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'sis-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent {
  @Input() includeCloseButton = false;
  @Output() onClose = new EventEmitter();

  close() {
    this.onClose.emit();
  }
}