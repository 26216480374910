<div [ngClass]="classNames()" class="base-card-section">
  <header *ngIf="sectionTitle" class="base-card-section__header">
    <div class="base-card-section__header-content">
      <p class="base-card-section__title">{{ sectionTitle }}</p>
      <ems-base-caption *ngIf="subtitle">
        <ems-base-text-style>{{ subtitle }}</ems-base-text-style>
      </ems-base-caption>
    </div>
    <div class="base-card-section__actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </header>
  <div class="base-card-section__content">
    <ng-content></ng-content>
  </div>
</div>
