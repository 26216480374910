import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FavoriteJobCodeActions, UserActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: any;
  filter: string;
  selectedId: string | number;
}

export const sortByJobCode = (a, b) => {
  return a.jobCode - b.jobCode;
};

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  sortComparer: sortByJobCode,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  filter: '',
  selectedId: null,
});

export const reducer = createReducer(
  initialState,
  on(
    FavoriteJobCodeActions.saveOneFavorite,
    FavoriteJobCodeActions.getFavorite,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    FavoriteJobCodeActions.getFavoriteError,
    FavoriteJobCodeActions.saveOneFavoriteError,
    FavoriteJobCodeActions.deleteOneFavoriteError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(FavoriteJobCodeActions.getFavoriteComplete, (state, { jobCode }) =>
    adapter.setAll(jobCode, {
      ...state,
      loading: false,
    })
  ),
  on(FavoriteJobCodeActions.saveOneFavoriteComplete, (state, { jobCode }) =>
    adapter.addOne(jobCode, {
      ...state,
      loading: false,
    })
  ),
  on(FavoriteJobCodeActions.deleteOneFavoriteComplete, (state, { jobCode }) =>
    adapter.removeOne(jobCode.id, {
      ...state,
      loading: false,
    })
  ),
  on(UserActions.logout, () => adapter.getInitialState(initialState))
);
