import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiService,
  BaseModalService,
  UtilsService,
} from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SnackbarService } from 'ngx-snackbar';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  retry,
  retryWhen,
  scan,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ActivityActions,
  LayoutActions,
  NewActions,
  NewMileageActions,
  TrashActions,
  ApprovalActions
} from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import {
  selectAutoSavedExpenseId,
  selectFilters,
  selectNewExpenseActivityId,
} from '../../selectors/expense.selectors';

@Injectable()
export class NewExpenseEffects {
  upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.upload),
      tap(() => {
         this.modalService.open('loading-modal');
         this.store$.dispatch(ApprovalActions.isLoading());
      }),
      switchMap(({ image }) =>
        this.apiService.saveOneExpenseReceipt(image).pipe(
          map((res: any) =>
            NewActions.getExpenseData({ token: res.expenseId })
          ),
          catchError(errorHandler(NewActions.uploadError))
        )
      )
    )
  );

  ocrOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.ocrOnly),
      tap(() => {
        this.store$.dispatch(ApprovalActions.isLoading());
      }),
      switchMap(({ image }) =>
        this.apiService.getReceiptDataOcrOnly(image).pipe(
          map((res: any) => {
            this.store$.dispatch(ApprovalActions.isLoadingComplete());
            return NewActions.ocrOnlyComplete({ expense: res });
          }),
          catchError((error) => {
            this.store$.dispatch(ApprovalActions.isLoadingComplete());
            let message = "Error uploading the receipt. Try again...";
            if(error.error.code === 301) {
              message = `Could not scan the receipt for data: ${error.error.message}`;
            }
            return [LayoutActions.globalToastErrorMessage({ message })];
          })
        )
      )
    )
  );

  getExpenseData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.getExpenseData),
      mergeMap((res: any) => of(res).pipe(delay(3000))), // Delay before the first call
      switchMap((res: any) =>
        this.apiService.getOneExpenseReceipt(res.token).pipe(
          retryWhen((error$) => {
            return error$.pipe(
              scan((count, err) => {
                if (count > 2) {
                  throw err;
                } else {
                  return (count += 1);
                }
              }, 0),
              delay(2000)
            );
          }),
          map((response) =>
            NewActions.getExpenseDataComplete({ expenseData: response })
          ),
          catchError(errorHandler(NewActions.getExpenseDataError))
        )
      )
    )
  );

  openModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.getExpenseDataComplete),
      tap(() => {
        this.modalService.close('loading-modal');
        this.store$.dispatch(ApprovalActions.isLoadingComplete());
      }),
      map(() => LayoutActions.openExpenseModal({ name: 'expense-form' }))
    )
  );

  getReceiptDataError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewActions.getExpenseDataError, NewActions.uploadError),
        tap(() => {
          this.modalService.close('loading-modal');
          this.modalService.close('new-expense-modal');
        })
      ),
    { dispatch: false }
  );

  dismiss$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.submitComplete),
      tap(() => {
        this.snackbarService.clear();
        this.snackbarService.add({
          msg: 'Expense submitted.',
          timeout: 10000,
          action: {
            text: 'View',
            onClick: () => {
              this.router.navigateByUrl('/expenses/submitted');
            },
          },
          background: '#1d3148',
        });
      }),
      map(() => LayoutActions.dismissModal())
    )
  );
  // submit$ handles submitting a new expense which likely has been saved to a draft already so
  // both the activity id (for a note) and the expense id needs to be added before making the save
  // expense api call
  submit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.submit),
      switchMap(({ expense, file, rotationAngle }) =>
        this.apiService
          .submitNewExpense(expense, file, rotationAngle )
          .pipe(
            map((res: any) =>
              NewActions.submitComplete({ expense: res.expense })
            ),
            catchError(errorHandler(NewActions.submitError))
          )
      )
    )
  );

  cancel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewActions.cancel),
        tap(() => {
          // TODO: navigate backwards
        })
      ),
    { dispatch: false }
  );

  takePhoto$ = createEffect(
    () => this.actions$.pipe(ofType(NewActions.takePhoto)),
    { dispatch: false }
  );

  savePhoto$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NewActions.takePhotoComplete,
          NewActions.selectPhotoLibraryComplete
        )
      ),
    { dispatch: false }
  );

  selectPhotoLibrary$ = createEffect(
    () => this.actions$.pipe(ofType(NewActions.selectPhotoLibrary)),
    { dispatch: false }
  );

  getReceiptData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.savePhotoComplete),
      switchMap((res: any) =>
        this.apiService.getOneExpenseReceipt(res.tokenData).pipe(retry())
      ),
      map((res) => NewActions.getExpenseDataComplete({ expenseData: res }))
    )
  );

  selectExpenseType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewActions.selectExpenseType),
        tap(() => {
          // TODO: navigate to expense type list
        })
      ),
    { dispatch: false }
  );

  selectJobCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewActions.selectJobCode),
        tap(() => {
          // TODO: navigate to job code list
        })
      ),
    { dispatch: false }
  );

  createNewExpenseRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.createNewExpenseRequest),
      switchMap(({ expense, file, rotationAngle }) =>
        this.apiService.saveDraftWithReceipt(expense, file, rotationAngle).pipe(
          tap(async () => {
            await this.router.navigateByUrl('/expenses/unsubmitted');
          }),
          map(NewActions.createNewExpenseComplete)
        )
      )
    )
  );

  createNewExpense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.createNewExpense),
      map(({ expense, file, rotationAngle }) =>
        NewActions.createNewExpenseRequest({ expense, file, rotationAngle })
      )
    )
  );

  autoSaveWithoutNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.autoSaveWithoutNote),
      withLatestFrom(this.store$.pipe(select(selectAutoSavedExpenseId))),
      switchMap(([{ expense }, id]) =>
        this.apiService
          .saveDraft({
            ...expense,
            id: +id,
          })
          .pipe(
            map((response) =>
              NewActions.autoSaveWithoutNoteComplete({ expense: response })
            ),
            catchError(errorHandler(NewActions.autoSaveWithoutNoteError))
          )
      )
    )
  );

  autoSaveWithoutNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.autoSaveWithoutNoteComplete),
      withLatestFrom(this.store$.pipe(select(selectNewExpenseActivityId))),
      filter(([action, id]) => id !== null),
      switchMap(([note, activityId]) =>
        this.apiService.deleteNote(activityId).pipe(
          map(({ activity }) =>
            ActivityActions.deleteNewExpenseNoteComplete({ activity: activity })
          ),
          catchError(errorHandler(NewActions.autoSaveWithoutNoteError))
        )
      )
    )
  );

  autoSaveWithNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.autoSaveWithNote),
      withLatestFrom(
        this.store$.pipe(select(selectAutoSavedExpenseId)),
        this.store$.pipe(select(selectNewExpenseActivityId))
      ),
      switchMap(([action, autoSavedId, activityId]) =>
        this.apiService
          .saveDraft({
            ...action.expense,
            id: +autoSavedId,
            activityId,
          })
          .pipe(
            map((response) =>
              NewActions.autoSaveWithNoteComplete({ expense: response })
            ),
            catchError(errorHandler(NewActions.autoSaveWithNoteError))
          )
      )
    )
  );

  autoSaveWithNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.autoSaveWithNoteComplete),
      map(({ expense }) =>
        ActivityActions.getNewExpenseActivityId({ id: expense.activity.id })
      )
    )
  );

  trashAutoSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.trashAutoSaved),
      withLatestFrom(this.store$.pipe(select(selectAutoSavedExpenseId))),
      switchMap(([action, autoSaveId]) =>
        this.apiService.trashExpenses({ expenses: [autoSaveId] }).pipe(
          mergeMap(([expenses, autoSavedId]) => [
            NewActions.trashAutoSavedComplete({ expense: expenses[0] }),
            LayoutActions.dismissModal(),
          ]),
          tap(() => this.modalService.close('expense-new-modal')),
          catchError(errorHandler(NewActions.trashAutoSavedError))
        )
      )
    )
  );

  trashAutoSavedComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NewActions.trashAutoSavedComplete,
        NewMileageActions.trashAutoSavedComplete
      ),
      tap(() => {
        this.utils.displaySnackbar('Expense', 'trashed', '/expenses/trash');
      }),
      withLatestFrom(this.store$.pipe(select(selectFilters))),
      map(([, filters]) =>
        TrashActions.getUpdatedTrash({
          page: filters.trash.page,
          sort: filters.trash.sort,
          filters: filters.trash.filters,
        })
      )
    )
  );

  saveItemizedExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.itemize),
      withLatestFrom(this.store$.pipe(select(selectAutoSavedExpenseId))),
      switchMap(([amounts, id]) =>
        this.apiService
          .saveItemizedExpenses({
            amounts: amounts.amounts,
            id,
          })
          .pipe(
            map((response) =>
              NewActions.itemizeComplete({ expenses: response })
            ),
            catchError(errorHandler(NewActions.itemizeError))
          )
      )
    )
  );

  saveItemizedExpensesComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewActions.itemizeComplete),
      tap((res: any) => {
        this.modalService.close('expense-new-modal');
        this.modalService.close('expense-itemize-modal');
        this.router.navigateByUrl('/expenses/unsubmitted');
        const caption = `${res.expenses.length - 1} expenses`;
        this.snackbarService.add({
          msg: `${caption} created.`,
          timeout: 10000,
          action: {
            text: '',
          },
          background: '#1d3148',
        });
      }),
      map(() => LayoutActions.dismissModal())
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromDashboard.State>,
    private apiService: ApiService,
    private modalService: BaseModalService,
    private router: Router,
    private snackbarService: SnackbarService,
    private utils: UtilsService
  ) {}
}
