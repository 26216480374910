import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromActivity from './activity.reducer';
import * as fromAdmin from './admin.reducer';
import * as fromAlternateEmail from './alternate-email.reducer';
import * as fromApproval from './approval.reducer';
import * as fromDashboard from './dashboard.reducer';
import * as fromExpenseType from './expense-type.reducer';
import * as fromExpense from './expense.reducer';
import * as fromFavoriteJobCode from './favorite-job-code.reducer';
import * as fromFavoritePerson from './favorite-person.reducer';
import * as fromFavoriteSalesforceCase from './favorite-salesforce-case.reducer';
import * as fromFeedErrorUser from './feed-error-user.reducer';
import * as fromJobCode from './job-code.reducer';
import * as fromLayout from './layout.reducer';
import * as fromMileage from './mileage.reducer';
import * as fromNotificationSetting from './notification-setting.reducer';
import * as fromPerson from './person.reducer';
import * as fromProxiedUser from './proxied-user.reducer';
import * as fromProxy from './proxy.reducer';
import * as fromReceiptManager from './receipt-manager.reducer';
import * as fromSalesforceCase from './salesforce-case.reducer';
import * as fromTransactionFeedItem from './transaction-feed-item.reducer';
import * as fromUser from './user.reducer';
import * as fromTravelTicket from './travel-ticket.reducer';
import * as fromExpenseMatch from './expense-match.reducer';
import * as fromAllModal from './all-modal.reducer';

export interface DashboardState {
  user: fromUser.State;
  approval: fromApproval.State;
  expenseType: fromExpenseType.State;
  favoriteJobCode: fromFavoriteJobCode.State;
  jobCode: fromJobCode.State;
  dashboard: fromDashboard.State;
  expense: fromExpense.State;
  layout: fromLayout.State;
  activity: fromActivity.State;
  person: fromPerson.State;
  favoritePerson: fromFavoritePerson.State;
  favoriteSalesforceCase: fromFavoriteSalesforceCase.State;
  alternateEmail: fromAlternateEmail.State;
  salesforceCase: fromSalesforceCase.State;
  proxy: fromProxy.State;
  proxiedUser: fromProxiedUser.State;
  notificationSetting: fromNotificationSetting.State;
  mileage: fromMileage.State;
  feedErrorUser: fromFeedErrorUser.State;
  transactionFeedItem: fromTransactionFeedItem.State;
  receiptManager: fromReceiptManager.State;
  admin: fromAdmin.State;
  travelTicket: fromTravelTicket.State;
  expenseMatch: fromExpenseMatch.State
  allModal: fromAllModal.State;
}

export interface State {
  dashboard: DashboardState;
}

export const selectDashboardState = createFeatureSelector<
  DashboardState
>('dashboard');

export function reducers(state: DashboardState | undefined, action: Action) {
  return combineReducers({
    user: fromUser.reducer,
    approval: fromApproval.reducer,
    expenseType: fromExpenseType.reducer,
    favoriteJobCode: fromFavoriteJobCode.reducer,
    jobCode: fromJobCode.reducer,
    dashboard: fromDashboard.reducer,
    expense: fromExpense.reducer,
    layout: fromLayout.reducer,
    activity: fromActivity.reducer,
    person: fromPerson.reducer,
    favoritePerson: fromFavoritePerson.reducer,
    favoriteSalesforceCase: fromFavoriteSalesforceCase.reducer,
    alternateEmail: fromAlternateEmail.reducer,
    salesforceCase: fromSalesforceCase.reducer,
    proxy: fromProxy.reducer,
    proxiedUser: fromProxiedUser.reducer,
    notificationSetting: fromNotificationSetting.reducer,
    mileage: fromMileage.reducer,
    feedErrorUser: fromFeedErrorUser.reducer,
    transactionFeedItem: fromTransactionFeedItem.reducer,
    receiptManager: fromReceiptManager.reducer,
    admin: fromAdmin.reducer,
    travelTicket: fromTravelTicket.reducer,
    expenseMatch: fromExpenseMatch.reducer,
    allModal: fromAllModal.reducer,
  })(state, action);
}
