import { Injectable } from "@angular/core";
import { ApiService } from "@ems-gui/expense/util-web-infrastructure";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError, of } from "rxjs";
import { ExpenseMatchActions } from "../actions";
import { Store } from "@ngrx/store";
import { State } from "@ems-gui/expense/util-web-ngrx";
import { SORT_ORDER } from "@ems-gui/shared/util-core";

const PAGE= 1;
const EXPENSE_FILTERS = {
  limit: 100,
  paymentType: 'user'
};

@Injectable()
export class ExpenseMatchEffects {
  onRequestUserCreatedCreditCardExpenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpenseMatchActions.requestUserCreatedCreditCardExpenses),
      switchMap(() =>
        this.apiService.getUnsubmittedExpenses(PAGE, SORT_ORDER, EXPENSE_FILTERS).pipe(
          map(({ expenses }) =>
            ExpenseMatchActions.requestUserCreatedCreditCardExpensesSuccess({ expenses })
          ),
          catchError((error) =>
            of(ExpenseMatchActions.requestUserCreatedCreditCardExpensesError({ error }))
          )
        )
      )
    )
  );      

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<State>
  ) {}
}
