<ems-base-tabs [detailLayout]="true" (tabSelected)="onTabSelected($event)">
  <ems-base-tab [tabTitle]="'Form'" [detailLayout]="true">
    <ems-expense-form-layout 
      [detailLayout]="true"
      [includeReceipt] = "expense?.type?.name !== 'Mileage'"
    >
      <div content>
        <sis-expense-mismatch-alert
          *ngIf="expense?.ocrMismatch"
        ></sis-expense-mismatch-alert>
        <ems-base-stack space="l">
          <div class="approval-card">
            <div class="approval-card__header">
              <span class="approval-card__title">{{ expense.vendor }}</span>
              <span class="approval-card__subtitle">Expense ID {{ expense.id }}</span>
            </div>
            <div class="approval-card__amount-date">
              <span class="approval-card__amount">
                <span class="text">Amount:</span>
                {{ expense.amount | convertToDollars | currency }}
              </span>
              <span class="approval-card__date">
                <span class="text"> Date:</span>
                {{ expense.transactionDate | expenseDate }}
              </span>
            </div>
            <div class="approval-card__details">
              <div class="approval-card__detail">
                <span class="title">Employee:</span>
                <span class="detail">{{ expense.submitter.firstName }} {{ expense.submitter.lastName }}</span>
              </div>
              <div class="approval-card__detail payment-detail">
                <span class="title">Payment:</span>
                <span class="detail">{{ expense.paymentType | paymentPipe }}</span>
              </div>
              <div class="approval-card__detail">
                <span class="title">Expense Type:</span>
                <span class="detail expense-type">{{ expense.type ? expense.type.name : '-' }}</span>
              </div>
              <div class="approval-card__detail">
                <span class="title">Billable?:</span>
                <span class="detail">{{ expense.isBillable ? 'Yes' : 'No' }}</span>
              </div>
              <div class="approval-card__detail">
                <span class="title">Travel Ticket:</span>
                <span class="detail">{{ expense?.travelTicket?.name || '-' }}</span>
              </div>
              <div class="approval-card__divider"></div>
              <div class="approval-card__detail">
                <span class="title">Description:</span>
                <span class="detail lighter description">{{ expense.description }}</span>
              </div>
            </div>
            <div class="approval-card__code-case">
              <div class="approval-card__detail">
                <span class="title">Job Code:</span>
                <span class="detail">{{ expense.jobCode?.code }}</span>
                <span class="extra">{{ expense.jobCode?.name }}</span>
              </div>
              <div class="approval-card__detail">
                <span class="title">Custom Case:</span>
                <span class="detail">{{ expense.salesforceId?.case_number || '-' }}</span>
                <span class="extra">{{ expense.salesforceId?.name || '-' }}</span>
              </div>
            </div>
            <div class="approval-card__divider"></div>
            <div class="approval-card__footer">
              <div class="approval-card__detail footer">
                <span class="title">Submitted:</span>
                <span class="detail">{{ getFormattedDate(expense.submissionDate) }}</span>
              </div>
            </div>
          </div>
        </ems-base-stack>
      </div>
      <ems-receipt-widget
        [uploadUrl]="url"
        [uploadMethod]="method"
        [expense]="expense"
        [parent]="expense?.parent"
        [isDisabled]="isDisabled"
        [canUploadNew]="canUploadNew"
        [detailLayout]="true"
        [expenseStatus]="expense?.status"
        (rotateReceipt)="onRotateReceipt($event)"
        (uploaded)="onReceiptUploaded($event)"
        (fileSizeError)="fileSizeError.emit()"
        (remove)="removeReceipt.emit(expense?.id)"
        receipt
      >
      </ems-receipt-widget>
    </ems-expense-form-layout>
  </ems-base-tab>
  <ems-base-tab
    [tabTitle]="isMobileView() ? 'Activity' : 'Activity Log'"
    [detailLayout]="true"
    [activityLogLayout]="true"
    *ngIf="expense?.activity"
  >
    <ems-base-stack space="l">
      <ems-base-box *ngFor="let item of expense?.activity">
        <ems-expense-note
          [noteTitle]="item.action"
          [date]="getTimestamp(item.date)"
          [content]="getFormattedNote(item)"
          [user]="item.action !== 'Reimbursed Expense' ? item.author : 'Spense'"
          [email]="item.author?.work_email"
          [id]="item.author?.employee_number"
        ></ems-expense-note>
      </ems-base-box>
    </ems-base-stack>
  </ems-base-tab>
</ems-base-tabs>
