import { AdminFeedFilter } from '@ems-gui/shared/util-core';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  LayoutActions,
  TransactionFeedItemActions,
  UserActions,
} from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  error: string;
  selectedId: string | number;
  filters: {
    page: number;
    sort: {
      colId: string;
      sort: string;
    };
    filters: AdminFeedFilter;
  };
  selectedItemDownloadUrl: string;
  totalCount: number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: '',
  selectedId: null,
  filters: {
    page: 1,
    sort: {
      colId: 'createdAt',
      sort: 'desc',
    },
    filters: {
      startDate: '',
      endDate: '',
      fileName: '',
    },
  },
  totalCount: 0,
  selectedItemDownloadUrl: '',
});

export const reducer = createReducer(
  initialState,
  on(
    TransactionFeedItemActions.get,
    TransactionFeedItemActions.downloadFeedItemFile,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(TransactionFeedItemActions.getComplete, (state, { results, totalCount }) =>
    adapter.upsertMany(results, {
      ...state,
      loading: false,
      totalCount,
    })
  ),
  on(
    TransactionFeedItemActions.getError,
    TransactionFeedItemActions.getFilteredError,
    TransactionFeedItemActions.getFeedItemDownloadError,
    TransactionFeedItemActions.downloadFeedItemFileError,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    TransactionFeedItemActions.getFiltered,
    (state, { page, sort, filters }) => ({
      ...state,
      filters: {
        ...state.filters,
        page,
        sort,
        filters,
      },
    })
  ),
  on(
    TransactionFeedItemActions.getFilteredComplete,
    (state, { results, totalCount }) =>
      adapter.setAll(results, {
        ...state,
        loading: false,
        totalCount,
      })
  ),
  on(TransactionFeedItemActions.resetFilters, (state) => ({
    ...state,
    filters: {
      ...initialState.filters,
    },
  })),
  on(
    TransactionFeedItemActions.getFeedItemDownloadComplete,
    (state, { url }) => ({
      ...state,
      selectedItemDownloadUrl: url,
    })
  ),
  on(LayoutActions.dismissFeedItemModal, (state) => ({
    ...state,
    selectedItemDownloadUrl: '',
  })),
  on(TransactionFeedItemActions.downloadFeedItemFileComplete, (state) => ({
    ...state,
    loading: false,
  })),
  on(UserActions.logout, () => adapter.getInitialState(initialState))
);

export const filters = (state: State) => state.filters;
export const totalCount = (state: State) => state.totalCount;
export const feedItemDownloadUrl = (state: State) =>
  state.selectedItemDownloadUrl;
export const transactionFeedLoading = (state: State) => state.loading;
