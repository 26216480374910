import { AdminUsersFilter } from '@ems-gui/shared/util-core';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminActions } from '../actions';

export interface State extends EntityState<any> {
  loading: boolean;
  filters: {
    page: number;
    sort: { colId: string; sort: string };
    filters: AdminUsersFilter;
  };
  totalCount: number;
  selectedId: string | number;
  error: string;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: State = adapter.getInitialState({
  loading: false,
  filters: {
    page: 1,
    sort: { colId: 'lastName', sort: 'asc' },
    filters: {
      user: null,
      hasProxy: null,
      isProxy: null,
      role: null,
    },
  },
  totalCount: 0,
  selectedId: null,
  error: '',
});

export const reducer = createReducer(
  initialState,
  on(
    AdminActions.getPersons,
    AdminActions.getFilteredPersons,
    (state, { page, sort, filters }) => ({
      ...state,
      filters: {
        page,
        sort: { ...state.filters.sort, ...sort },
        filters: {
          ...state.filters.filters,
          ...filters,
        },
      },
      loading: true,
    })
  ),
  on(AdminActions.getPersonsComplete, (state, { employees, totalCount }) =>
    adapter.upsertMany(employees, {
      ...state,
      totalCount,
      loading: false,
    })
  ),
  on(
    AdminActions.getFilteredPersonsComplete,
    (state, { employees, totalCount }) =>
      adapter.setAll(employees, {
        ...state,
        totalCount,
        loading: false,
      })
  ),
  on(AdminActions.select, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(AdminActions.resetFilters, (state) => ({
    ...state,
    filters: {
      ...initialState.filters,
      filters: {
        ...initialState.filters.filters,
      },
    },
    selectedId: null,
  })),
  on(
    AdminActions.getPersonsError,
    AdminActions.getFilteredPersonsError,
    (state, { error }) => ({
      ...state,
      error,
    })
  )
);

export const selectedUserId = (state: State) => state.selectedId;
export const filters = (state: State) => state.filters;
export const getTotalCount = (state: State) => state.totalCount;
export const adminLoading = (state: State) => state.loading;
