import { Injectable } from '@angular/core';
import {
  ApiService,
  BaseModalService,
  UtilsService,
} from '@ems-gui/expense/util-web-infrastructure';
import { EXPENSE_FILTERS, SORT_ORDER } from '@ems-gui/shared/util-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  ApprovalActions,
  ProxyUserActions,
  SubmittedActions,
  TrashActions,
} from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';

@Injectable()
export class MyExpensesEffects {
  /* ---- My Expenses ---- */
  // The following three effects are used on the web to GET the submitted, unsubmitted, and trashed expenses
  // for the My Expenses' Unsubmitted, Submitted, and Trash views. each takes a payload of the requested
  // page of data, sort order, and filters

  getSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SubmittedActions.getSubmitted,
        SubmittedActions.getUpdatedSubmitted
      ),
      switchMap((res: any) =>
        this.apiService
          .getSubmittedExpenses(res.page, res.sort, res.filters)
          .pipe(
            map((response: any) =>
              SubmittedActions.getSubmittedComplete({ expenses: response })
            ),
            catchError(errorHandler(SubmittedActions.getSubmittedError))
          )
      )
    )
  );

  getFilteredSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmittedActions.getFilteredSubmitted),
      switchMap((res: any) =>
        this.apiService
          .getSubmittedExpenses(res.page, res.sort, res.filters)
          .pipe(
            map((response: any) =>
              SubmittedActions.getFilteredSubmittedComplete({
                expenses: response,
              })
            ),
            catchError(errorHandler(SubmittedActions.getFilteredSubmittedError))
          )
      )
    )
  );

  getUnsubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SubmittedActions.getUnsubmitted,
        SubmittedActions.getUpdatedUnsubmitted
      ),
      switchMap((res: any) =>
        this.apiService
          .getUnsubmittedExpenses(res.page, res.sort, res.filters)
          .pipe(
            map((response: any) =>
              SubmittedActions.getUnsubmittedComplete({ expenses: response })
            ),
            catchError(errorHandler(SubmittedActions.getUnsubmittedError))
          )
      )
    )
  );

  getFilteredUnsubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmittedActions.getFilteredUnsubmitted),
      switchMap((res: any) =>
        this.apiService
          .getUnsubmittedExpenses(res.page, res.sort, res.filters)
          .pipe(
            map((response: any) =>
              SubmittedActions.getFilteredUnsubmittedComplete({
                expenses: response,
              })
            ),
            catchError(
              errorHandler(SubmittedActions.getFilteredUnsubmittedError)
            )
          )
      )
    )
  );

  getTrash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.getTrash, TrashActions.getUpdatedTrash),
      switchMap((res: any) =>
        this.apiService
          .getTrashedExpenses(res.page, res.sort, res.filters)
          .pipe(
            map((response: any) =>
              TrashActions.getTrashComplete({ expenses: response })
            ),
            catchError(errorHandler(TrashActions.getTrashError))
          )
      )
    )
  );

  getFilteredTrash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.getFilteredTrash),
      switchMap((res: any) =>
        this.apiService
          .getTrashedExpenses(res.page, res.sort, res.filters)
          .pipe(
            map((response: any) =>
              TrashActions.getFilteredTrashComplete({
                expenses: response,
              })
            ),
            catchError(errorHandler(TrashActions.getFilteredTrashError))
          )
      )
    )
  );

  restore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.restoreExpenses),
      switchMap((res: any) =>
        this.apiService.restoreExpenses({ expenses: res.expenses }).pipe(
          map((response) =>
            TrashActions.restoreExpensesComplete({ expenses: response })
          ),
          catchError(errorHandler(TrashActions.restoreExpensesError))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.deleteExpenses),
      switchMap((res: any) =>
        this.apiService.deleteExpenses({ expenses: res.expenses }).pipe(
          map((response: any) =>
            TrashActions.deleteExpensesComplete({ expenses: response.expenses })
          ),
          catchError(errorHandler(TrashActions.deleteExpensesError))
        )
      )
    )
  );

  empty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.emptyTrash),
      switchMap(() =>
        this.apiService.emptyTrash().pipe(
          map((response) =>
            TrashActions.emptyTrashComplete({ expenses: response })
          ),
          catchError(errorHandler(TrashActions.emptyTrashError))
        )
      )
    )
  );

  deleteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.deleteExpensesComplete),
      tap((res: any) => {
        let caption = '';
        if (res.expense || res.expenses?.length === 1) {
          caption = 'Expense';
        } else {
          caption = `${res.expenses.length} expenses`;
        }
        this.utils.displaySnackbar(caption, 'deleted');
        this.modalService.close('delete-modal', 'delete');
      }),
      map(() => ApprovalActions.getExpenseCounts())
    )
  );

  emptyComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.emptyTrashComplete),
      tap(() => {
        this.utils.displaySnackbar('Trash', 'emptied', '/expenses/unsubmitted');
        this.modalService.close('trash-modal', 'delete');
      }),
      map(() => ApprovalActions.getExpenseCounts())
    )
  );

  restoreComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrashActions.restoreExpensesComplete),
      tap((res: any) => {
        let caption = '';
        if (res.expense || res.expenses?.length === 1) {
          caption = 'Expense';
        } else {
          caption = `${res.expenses.length} expenses`;
        }
        this.utils.displaySnackbar(
          caption,
          'restored',
          '/expenses/unsubmitted'
        );
      }),
      map(() => ApprovalActions.getExpenseCounts())
    )
  );

  getEmailReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmittedActions.getUnsubmittedComplete),
      switchMap(() =>
        this.apiService.getEmailReceipt().pipe(
          map((response: any) =>
            SubmittedActions.getEmailedReceiptComplete({ expenses: response })
          ),
          catchError(errorHandler(SubmittedActions.getEmailedReceiptError))
        )
      )
    )
  );

  getAllExpenses$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(SubmittedActions.getAllExpenses, ProxyUserActions.removeProxy),
        switchMap(() => {
          return forkJoin([
            this.apiService.getUnsubmittedExpenses(
              1,
              SORT_ORDER,
              EXPENSE_FILTERS
            ),
            this.apiService.getSubmittedExpenses(
              1,
              SORT_ORDER,
              EXPENSE_FILTERS
            ),
            this.apiService.getTrashedExpenses(1, SORT_ORDER, EXPENSE_FILTERS),
          ]).pipe(
            map(
              ([res1, res2, res3]: any) =>
                SubmittedActions.getAllExpensesComplete({
                  expenses: [
                    ...res1.expenses,
                    ...res2.expenses,
                    ...res3.expenses,
                  ],
                  counts: {
                    drafts: res1.totalCount,
                    submitted: res2.totalCount,
                    trash: res3.totalCount,
                  },
                }),
              catchError(errorHandler(SubmittedActions.getSubmittedError))
            )
          );
        })
      )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private modalService: BaseModalService,
    private store$: Store<fromDashboard.State>,
    private utils: UtilsService
  ) {}
}
