import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ems-expense-modal-edit',
  templateUrl: './expense-modal-edit.component.html',
  styleUrls: ['./expense-modal-edit.component.scss'],
})

/**
 * REFACTOR NEEDED:
 * WHY: Should we separate dummy data from actual code logic?
 */
export class ExpenseModalEditComponent implements OnInit {
  expense = {
    createdAt: 1564072744226,
    updatedAt: 1564072745275,
    id: 186,
    status: 'pending',
    description: 'Supervisor said he would cover my lunch.',
    cardVerified: null,
    address: '1916 Queen Anne Ave, Seattle, WA 98109',
    vendor: 'Trader Joes',
    token: '',
    image:
      'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/5QkKHTpQNS2fBp2m',
    thumbnail:
      'https://sis-ems.s3-us-west-1.amazonaws.com/receipt/thumbnail/5QkKHTpQNS2fBp2m',
    amount: 996,
    comment: '',
    transactionDate: new Date().toISOString(),
    information: null,
    isBillable: false,
    approvalLevel: 0,
    paymentType: 'check',
    jobCode: 2019,
    report: {
      createdAt: 1564072744383,
      updatedAt: 1564072744383,
      id: 186,
      name: 'Sy Philus',
      note: 'Stupid Note',
      description: 'Boring Description',
      isResubmitted: false,
    },
    type: null,
    creditCardId: null,
    submitter: {
      createdAt: 1564072744212,
      updatedAt: 1564072744212,
      id: 4,
      username: 'Tricia Hayes',
      email: 'thayes@example.com',
    },
    approvedBy: null,
  };

  ngOnInit() {
    return;
  }
}
