import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilsService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ConvertToDollarsPipe,
  CustomValidators,
} from '@ems-gui/shared/util-core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-expense-form-itemize-edit',
  templateUrl: './expense-form-itemize-edit.component.html',
  styleUrls: ['./expense-form-itemize-edit.component.scss'],
  providers: [CurrencyPipe, ConvertToDollarsPipe],
})
export class ExpenseFormItemizeEditComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sumEl', { static: false }) sumEl: ElementRef;
  @Input() parentData;
  @Input() confirmStatus;
  @Input() dismissing;
  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() rotateReceipt: EventEmitter<any> = new EventEmitter<any>();
  unsubscribe$: Subject<void>;

  private _items;
  currentItems = [];
  deletedItems = [];
  diff = 0;
  actualDiff = 0;
  overAmount;
  form: FormGroup = new FormGroup({
    existingAmounts: this.fb.array([]),
    newAmounts: this.fb.array([]),
    sum: new FormControl(null),
  });

  get items(): any[] {
    return this._items;
  }

  @Input('items')
  set items(data: any[]) {
    if (data && data.length) {
      const itemsControl = <FormArray>this.form.get('existingAmounts');
      while (itemsControl && itemsControl.length !== 0) {
        itemsControl.removeAt(0);
      }

      data.forEach((datum) => {
        this.addItem(datum);
      });
      this._items = data;
    }
  }

  constructor(
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private convertToDollarsPipe: ConvertToDollarsPipe,
    private utilsService: UtilsService,
    private ref: ChangeDetectorRef
  ) {
    this.unsubscribe$ = new Subject<any>();
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => !this.form.pristine && !this.dismissing),
        tap((value) => {
          const updatedForm = value && { ...value };
          if (updatedForm?.existingAmounts?.length) {
            updatedForm.existingAmounts = this.itemsToCents(
              updatedForm.existingAmounts
            );
          }
          if (updatedForm?.newAmounts?.length) {
            updatedForm.newAmounts = this.itemsToCents(updatedForm.newAmounts);
          }
          this.calculateItemsSum([
            ...updatedForm.existingAmounts,
            ...updatedForm.newAmounts,
          ]);
          const form = updatedForm && this.updateForm(updatedForm);
          if (form) {
            this.formChange.emit(form);
          }
        })
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          const valid = value === 'VALID';
          this.statusChange.emit(valid);
        })
      )
      .subscribe();

    this.formChange.emit(this.updateForm(this.form.value));
    this.statusChange.emit(this.form.status === 'VALID');
    this.currentItems = [...this.items];
  }

  get existingAmounts() {
    return <FormArray>this.form.get('existingAmounts');
  }
  get newAmounts() {
    return <FormArray>this.form.get('newAmounts');
  }
  get sum() {
    return this.form.get('sum');
  }

  ngAfterViewInit() {
    if (this.existingAmounts?.length && this.sumEl?.nativeElement) {
      const amounts = this.itemsToCents(
        this.existingAmounts.controls.map((amount) => amount.value)
      );
      this.calculateItemsSum(amounts);
      this.ref.detectChanges();
    }
  }

  calculateItemsSum(items) {
    if (items && items.length) {
      const updatedItems = items.map((item) => +item);
      const itemsSum = updatedItems.reduce((a, b) => a + b);
      const sumInDollars = this.convertToDollarsPipe.transform(itemsSum);
      this.sumEl.nativeElement.value =
        this.currencyPipe.transform(sumInDollars);
      const total = this.parentData && +this.parentData.amount;
      this.diff = total - itemsSum;
      this.checkDiff();
    } else {
      return null;
    }
  }

  itemsToCents(items) {
    const updatedItems = [];
    items.forEach((item) => {
      item = this.utilsService.dollarsToCents(item);
      updatedItems.push(`${item}`);
    });
    return updatedItems;
  }

  checkDiff() {
    if (this.diff > 0) {
      this.overAmount = false;
      this.actualDiff = this.diff;
      this.sum.setErrors({
        sumNotValid: true,
      });
    } else if (this.diff < 0) {
      this.overAmount = true;
      this.actualDiff = Math.abs(this.diff);
      this.sum.setErrors({
        sumNotValid: true,
      });
    } else if (this.diff === 0) {
      this.overAmount = false;
      this.actualDiff = 0;
      this.sum.setErrors(null);
    }
  }

  addItem(item) {
    const itemsControl = <FormArray>this.form.get('existingAmounts');
    const updatedItem = this.currencyPipe.transform(
      this.convertToDollarsPipe.transform(item.amount)
    );
    itemsControl.push(
      this.fb.control(`${updatedItem}`, {
        validators: CustomValidators.validAmount,
      })
    );
  }

  addNewItem() {
    const itemsControl = <FormArray>this.form.get('newAmounts');
    itemsControl.push(
      this.fb.control('$0.00', { validators: CustomValidators.validAmount })
    );
  }
  /**
   * REFACTOR NEEDED:
   * WHY: the things  inside the if statements can be turned into functions that return these variable items.
   * the ternary operators can live inside these functions
   */
  deleteItem(list, index) {
    if (list === 'existingAmounts') {
      this.deletedItems.push({ id: this.items[index].id });
      this.existingAmounts.removeAt(index);
      this.currentItems = this.currentItems.filter(
        (item) => !this.deletedItems.map((d) => d.id).includes(item.id)
      );
    } else {
      this.newAmounts.removeAt(index);
    }
    const existingItemAmounts = this.existingAmounts.controls.length
      ? this.itemsToCents(
          this.existingAmounts.controls.map((item) => item.value)
        )
      : [];
    const newItemAmounts = this.newAmounts.controls.length
      ? this.itemsToCents(this.newAmounts.controls.map((item) => item.value))
      : [];
    const allItems = [...existingItemAmounts, ...newItemAmounts];
    this.calculateItemsSum(allItems);
  }

  getItemControl(list, index) {
    if (list === 'existingAmounts') {
      return (<FormArray>this.existingAmounts).at(index);
    } else {
      return (<FormArray>this.newAmounts).at(index);
    }
  }

  updateForm(form: any) {
    const updatedForm = { ...form };
    updatedForm.existingAmounts =
      updatedForm.existingAmounts?.length && this.currentItems?.length
        ? this.currentItems.map((item, index) => {
            return { id: item.id, amount: +updatedForm.existingAmounts[index] };
          })
        : [];
    updatedForm.newAmounts = updatedForm.newAmounts?.length
      ? updatedForm.newAmounts.map((item) => {
          return { amount: +item };
        })
      : [];
    updatedForm.deletedItems = this.deletedItems;
    return updatedForm;
  }

  onRotateReceipt({ angle }) {
    this.rotateReceipt.emit({ id: this.parentData.id, angle });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
