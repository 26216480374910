<div class="expense-modal-multi">
  <!-- Multiple Expenses -->
  <div class="expense-modal-multi__expense-items">
    <ems-base-accordion title="Expenses">
      <div accordion-body class="expense-modal-multi__expenses">
        <ems-expense-item
          *ngFor="let expense of expenses"
          [createdAt]="expense.createdAt"
          [vendor]="expense.vendor"
          [id]="expense.id"
          [amount]="expense.amount"
          [paymentType]="expense.paymentType"
          [thumbnail]="expense.thumbnail"
          [isBillable]="expense.isBillable"
          [expenseType]="expense.type"
          [description]="expense.description"
        >
        </ems-expense-item>
      </div>
    </ems-base-accordion>
  </div>

  <div class="expense-modal-multi__body">
    <!-- Bulk Edit Form -->
    <section class="expense-modal-multi__content">
      <div class="expense-edit-form">
        <!-- Vendor -->
        <div class="ems-field">
          <label class="ems-label">Vendor</label>
          <div class="ems-control">
            <input class="ems-input" type="text" placeholder="Vendor" />
          </div>
        </div>

        <!-- Expense Type -->
        <div class="ems-field">
          <label class="ems-label">Expense Type</label>
          <div class="ems-select">
            <select>
              <option value="meal" selected>Meal</option>
              <option value="travel">Travel</option>
            </select>
          </div>
        </div>

        <!-- Address -->
        <div class="ems-field">
          <label class="ems-label">Address</label>
          <div class="ems-control">
            <textarea class="ems-textarea"></textarea>
          </div>
        </div>

        <!-- Description -->
        <div class="ems-field">
          <label class="ems-label">Description</label>
          <div class="ems-control">
            <textarea class="ems-textarea"></textarea>
          </div>
        </div>

        <!-- Transaction Date -->
        <div class="ems-field">
          <label class="ems-label">Transaction Date</label>
          <div class="ems-control has-icon-right">
            <input class="ems-input" type="date" placeholder="MM/DD/YYYY" />
            <span class="ems-control__icon is-right">
              <ems-base-icon iconName="calendar"></ems-base-icon>
            </span>
          </div>
        </div>

        <!-- Billable -->
        <div class="ems-field">
          <label class="ems-label">Billable</label>
          <div class="ems-control">
            <label class="ems-radio">
              <input type="radio" name="billable" />
              <div class="ems-radio__indicator"></div>
              Yes
            </label>
            <label class="ems-radio">
              <input type="radio" name="billable" checked />
              <div class="ems-radio__indicator"></div>
              No
            </label>
          </div>
        </div>

        <!-- Amount -->
        <div class="ems-field">
          <label class="ems-label">Amount</label>
          <div class="ems-control">
            <input class="ems-input" type="text" />
          </div>
        </div>

        <!-- Payment Method -->
        <div class="ems-field">
          <label class="ems-label">Payment Method</label>
          <div class="ems-select">
            <select name="paymentMethod">
              <option value="companyCreditCard" selected>Company CC</option>
              <option value="personalCreditCard">Personal CC</option>
              <option value="cash">Cash</option>
              <option value="check">Check</option>
            </select>
          </div>
        </div>
      </div>
    </section>

    <!-- Receipt Widget-->
    <aside class="expense-modal-multi__receipt">
      <ems-receipt-widget isMultiEdit>
        <ems-base-button
          actions
          label="Upload"
          buttonType="tab"
          state="disabled"
        >
          <ems-base-icon icon-prepend iconName="upload"></ems-base-icon>
        </ems-base-button>
        <ems-base-button
          actions
          label="Remove"
          buttonType="tab"
          state="disabled"
        >
          <ems-base-icon icon-prepend iconName="remove"></ems-base-icon>
        </ems-base-button>
      </ems-receipt-widget>
    </aside>
  </div>
</div>
