<sis-alert-box class="secondary">
    <ng-container alert-icon>
      <ems-base-icon iconName="warning-filled" class="alert-icon"></ems-base-icon>
    </ng-container>
  
    <ng-container alert-title>
      <span class="alert-title"> Not Finding a Match? </span>
      <button
        class="tuggle-button see-more-btn"
        [attr.aria-hidden]="!alertToggle"
        [hidden]="!alertToggle"
        (click)="alertToggle = !alertToggle"
      >
        See more...
      </button>
    </ng-container>
  
    <ng-container alert-message>
      <div class="collapse-container" [attr.aria-hidden]="alertToggle">
        <div class="collapse-content">
          <p class="alert-message" [attr.aria-hidden]="alertToggle">
            If you think this receipt should have matched a credit card charge, you can match
            the two together by going to the automatically created credit card expense and
            pressing the '<b>Match to an existing expense</b>' button. 
            <br /><br />
            Only expenses marked 'Pending CC Match' are available to match. 
          </p>
          <button
            class="tuggle-button"
            [attr.aria-hidden]="alertToggle"
            (click)="alertToggle = !alertToggle"
          >
            Hide
          </button>
        </div>
      </div>
    </ng-container>
  </sis-alert-box>
  