import { createSelector } from '@ngrx/store';
import * as fromPerson from '../reducers/person.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';
import { Employee, EmployeeRoles } from '@ems-gui/shared/util-core';

export const selectDashboardPersonState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.person
);

export const {
  selectIds: selectPersonIds,
  selectEntities: selectPersonEntities,
  selectAll: selectAllPersons
} = fromPerson.adapter.getSelectors(selectDashboardPersonState);

export const filteredByRole = (role: EmployeeRoles) => createSelector(
  selectDashboardPersonState,
  (state) => state.ids
    .map((id) => id && state.entities && state.entities[id] as Employee)
    .filter((employeeEntity) => employeeEntity.role === role)
);

export const selectProxyUserPermissions = createSelector(
  selectDashboardPersonState,
  fromPerson.proxyUserPermissions
);

export const selectPersonFilters = createSelector(
  selectDashboardPersonState,
  fromPerson.filters
);

export const selectedUserId = createSelector(
  selectDashboardPersonState,
  fromPerson.selectedUserId
);

export const selectedUser = createSelector(
  selectPersonEntities,
  selectedUserId,
  (persons, id) => id && persons[id]
);

export const selectedProxyId = createSelector(
  selectDashboardPersonState,
  fromPerson.proxyId
);

export const selectedProxyUser = createSelector(
  selectPersonEntities,
  selectedProxyId,
  (persons, id) => id && persons[id]
);

export const selectAllUserPermissions = createSelector(
  selectDashboardPersonState,
  fromPerson.getAllUserPermissions
);

export const selectAllSearchedPersons = createSelector(
  selectDashboardPersonState,
  fromPerson.getSearchedPersons
);

export const selectSearchedString = createSelector(
  selectDashboardPersonState,
  fromPerson.getSearchedString
);

