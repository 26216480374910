import { FavoriteJobCode } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getFavorite = createAction('[Favorite Job Code] getFavorite');

export const getFavoriteComplete = createAction(
  '[Favorite Job Code] getFavoriteComplete',
  props<{ jobCode: FavoriteJobCode[] }>()
);

export const getFavoriteError = createAction(
  '[Favorite Job Code] getFavoriteError',
  props<{ error: string }>()
);

export const saveOneFavorite = createAction(
  '[Favorite Job Code] saveOneFavorite',
  props<{ id: string }>()
);

export const saveOneFavoriteComplete = createAction(
  '[Favorite Job Code] saveOneFavoriteComplete',
  props<{ jobCode: FavoriteJobCode }>()
);

export const saveOneFavoriteError = createAction(
  '[Favorite Job Code] saveOneFavoriteError',
  props<{ error: string }>()
);

export const deleteOneFavoriteComplete = createAction(
  '[Favorite Job Code] deleteOneFavoriteComplete',
  props<{ jobCode: FavoriteJobCode }>()
);

export const deleteOneFavoriteError = createAction(
  '[Favorite Job Code] deleteOneFavoriteError',
  props<{ error: string }>()
);
