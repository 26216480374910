import { FavoritePerson } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getFavoritePerson = createAction(
  '[Favorite Person] getFavoritesPerson'
);

export const getFavoritePersonComplete = createAction(
  '[Favorite Person] getFavoritePersonComplete',
  props<{ person: FavoritePerson[] }>()
);

export const getFavoritePersonError = createAction(
  '[Favorite Person] getFavoritePersonError',
  props<{ error: any }>()
);

export const saveFavoritePerson = createAction(
  '[Favorite Person] saveFavoritePerson',
  props<{ id: number }>()
);

export const saveFavoritePersonComplete = createAction(
  '[Favorite Person] saveFavoritePersonComplete',
  props<{ person: FavoritePerson }>()
);

export const saveFavoritePersonError = createAction(
  '[Favorite Person] saveFavoritePersonError',
  props<{ error: any }>()
);

export const deleteFavoritePerson = createAction(
  '[Favorite Person] deleteFavoritePerson',
  props<{ person: number }>()
);

export const deleteFavoritePersonComplete = createAction(
  '[Favorite Person] deleteFavoritePersonComplete',
  props<{ person: FavoritePerson }>()
);

export const deleteFavoritePersonError = createAction(
  '[Favorite Person] deleteFavoritePersonError',
  props<{ error: any }>()
);
