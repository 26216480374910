import {
  User,
  EmployeeRoles
} from '@ems-gui/shared/util-core';
import { createSelector, DefaultProjectorFn, Selector } from "@ngrx/store";
import { DashboardState, selectDashboardState } from '../reducers/state';
import * as fromUser from '../reducers/user.reducer';
import { PERMISSIONS } from '@ems-gui/shared/util-core';
import {
  selectDashboardPersonState,
  selectedProxyId,
  selectedUserId,
  selectProxyUserPermissions
} from '../selectors/person.selectors';
import { Dictionary } from "@ngrx/entity";
import { Person } from "@angular/cli/utilities/package-json";

export const selectDashboardUserState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.user
);

export const getUser = createSelector(
  selectDashboardUserState,
  fromUser.getUser
);
export const getLoggedIn = createSelector(
  getUser,
  (user) => user.firstName + ' ' + user.lastName
);

export const selectUserIsAdmin = createSelector(getUser, (user: User) => {
  const permissions = user?.permissions;
  return permissions?.length && permissions.find((p) => p === PERMISSIONS.admin)
    ? true
    : false;
});

export const selectUserCanAssignTDR = createSelector(getUser, (user: User) => {
  const permissions = user?.permissions;
  return permissions?.length && permissions
    .find((permission) => permission === PERMISSIONS.tdr) ? true : false;
});

export const selectUserHasProxySelfPermission = createSelector(
  getUser,
  (user: User) => {
    const permissions = user?.permissions;
    return permissions?.length &&
      permissions.find((p) => p === PERMISSIONS.proxyAll || p === PERMISSIONS.proxySelf)
      ? true
      : false;
  }
);

export const selectUserHasProxyAllPermission = createSelector(
  getUser,
  (user: User) => {
    const permissions = user?.permissions;
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.proxyAll)
      ? true
      : false;
  }
);

export const selectUserHasReceiptManagerPermission = createSelector(
  getUser,
  (user: User) => {
    const permissions = user?.permissions;
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.receiptMgr)
      ? true
      : false;
  }
);
export const selectUserHasSpenseSettingsPermission = createSelector(
  getUser,
  (user: User) => {
    const permissions = user?.permissions;
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.settings)
      ? true
      : false;
  }
);
export const selectUserHasSpenseUsersPermission = createSelector(
  getUser,
  (user: User) => {
    const permissions = user?.permissions;
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.users)
      ? true
      : false;
  }
);
export const selectUserHasSpenseLoginAsPermission = createSelector(
  getUser,
  (user: User) => {
    const permissions = user?.permissions;
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.loginAs)
      ? true
      : false;
  }
);

export const selectProxyUserIsAdmin = createSelector(
  selectProxyUserPermissions,
  (permissions: any) => {
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.admin)
      ? true
      : false;
  }
);

export const selectProxyUserIsProxyAll = createSelector(
  selectProxyUserPermissions,
  (permissions: any) => {
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.proxyAll)
      ? true
      : false;
  }
);
export const selectProxyUserHasReceiptManagerPermission = createSelector(
  selectProxyUserPermissions,
  (permissions: any) => {
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.receiptMgr)
      ? true
      : false;
  }
);

export const selectProxyUserHasSpenseSettingsPermission = createSelector(
  selectProxyUserPermissions,
  (permissions: any) => {
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.settings)
      ? true
      : false;
  }
);
export const selectProxyUserHasSpenseUsersPermission = createSelector(
  selectProxyUserPermissions,
  (permissions: any) => {
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.users)
      ? true
      : false;
  }
);

export const selectProxyUserCanAssignTDR= createSelector(
  selectProxyUserPermissions,
  (permissions: any) => {
    return permissions?.length && permissions.find((p) => p === PERMISSIONS.tdr)
      ? true
      : false;
  }
);

/**
 * This selector pulls from three different locations to get the actual employee
 * logged in.  First we check to see if we are proxied in as someone else.
 * Next we check to see if we have logged in as another employee.  If neither of
 * those are true then this will only leave the actual user that is logged in.
 * It returns the contactId of the first employee found
 */
export const selectLoggedInId = createSelector(
  getUser,
  selectedUserId,
  selectedProxyId,
  (actualUser, loggedInAsId, proxyUserId) => {
    if(proxyUserId) return proxyUserId;
    if(loggedInAsId) return loggedInAsId;
    return actualUser.contactId;
  });

/**
 * This users the selectLoggedInId to find the id of the current employee that
 * is being displayed.  Next it finds the employee from the Persons Entity and
 * returns it.
 */
export const selectLoggedInEmployee = createSelector(
  selectDashboardPersonState,
  selectLoggedInId,
  ({ entities }, id) => id && entities && entities[id]
);

/**
 * This checks the employee logged in using the selectLoggedInEmployee and
 * returns if they have credit_card_user = true.
 */
export const selectUserHasCreditCard = createSelector(
  selectLoggedInEmployee,
  (employee) => employee && !!employee.credit_card_user
);

export const isLoggedInAsAnApprover = createSelector(
  selectLoggedInEmployee,
  (entity) => entity !== undefined && [
    EmployeeRoles.SPENSE_ADMIN,
    EmployeeRoles.MASTER_APPROVER,
    EmployeeRoles.APPROVER
  ].includes(entity.role)
);
