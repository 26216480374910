import { Component, OnDestroy, OnInit } from '@angular/core';
import { CloseRequest } from '@ems-gui/expense/ui-web-components';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  FavoriteJobCodeActions,
  FavoriteSalesforceCaseActions,
  LayoutActions,
  NewMileageActions,
  newMileageExpenseReimbursement,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectJobCodesForDraftDropdown,
  selectAllSalesforceCasesWithGroupLabels,
  selectAutoSavedExpenseId,
  selectedProxyId,
  selectMileageExpenseType,
  selectModalDismiss,
  selectNewExpenseAutoSaving,
  State,
  MileageDraftActions,
  selectDraftIsSaveSuccessful,
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  JobCode,
  ModalInput,
  SalesforceCase,
} from '@ems-gui/shared/util-core';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AlertService } from '@src/app/services/alert.service';
import { FormManagerService } from '@src/app/services/form-manager.service';

const MODAL_ID = 'mileage-expense-modal-new';

@Component({
  selector: 'ems-mileage-expense-new-modal',
  templateUrl: './mileage-expense-new-modal.component.html',
  styleUrls: ['./mileage-expense-new-modal.component.scss'],
})
export class MileageExpenseNewModalComponent implements OnInit, OnDestroy {
  public formId = MODAL_ID
  modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Mileage Expense:',
    label: '',
    labelClass: '',
    caption: '',
    status: '',
    subtitle: 'New',
    receiptNotRequiredText: 'Receipt not required for mileage expense.',
    fieldsComplete: false,
    modalId: MODAL_ID,
  });
  modalInput$ = this.modalInputSubject.asObservable();
  form;
  private isNewMileageValidSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  isNewMileageValid$ = this.isNewMileageValidSubject.asObservable();
  autoSavedExpenseId$: Observable<number | string>;
  newMileageExpenseAutoSaving$: Observable<boolean>;
  dismiss$: Observable<boolean>;
  jobCodes$: Observable<JobCode[]>;
  favoriteJobCodes$: Observable<any>;
  salesforceCases$: Observable<any>;
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;
  newMileageReimbursement$: Observable<number>;
  proxy$: Observable<any>;
  mileageExpenseType$: Observable<ExpenseType>;
  unsubscribe$: Subject<void> = new Subject();
  submittedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  submitted$: Observable<boolean> = this.submittedSubject$.asObservable();
  currentExpense: Partial<Expense>;
  isSaveSuccessful$;
  changeCount = 0;

  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>,
    private toastrService: ToastrService,
    private alertService: AlertService,
    private readonly fm: FormManagerService
  ) {
    this.autoSavedExpenseId$ = this.store$.pipe(
      select(selectAutoSavedExpenseId)
    );
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
    this.jobCodes$ = this.store$.pipe(select(selectJobCodesForDraftDropdown));
    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );
    this.salesforceCases$ = this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels)
    );
    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.newMileageReimbursement$ = this.store$.pipe(
      select(newMileageExpenseReimbursement)
    );
    this.newMileageExpenseAutoSaving$ = this.store$.pipe(
      select(selectNewExpenseAutoSaving)
    );
    this.proxy$ = this.store$.pipe(select(selectedProxyId));
    this.mileageExpenseType$ = this.store$.pipe(
      select(selectMileageExpenseType)
    );
    this.isSaveSuccessful$ = this.store$.pipe(select(selectDraftIsSaveSuccessful));
  }

  ngOnInit() {
    this.autoSavedExpenseId$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((id) => {
          const currentModal = this.modalInputSubject.getValue();
          this.modalInputSubject.next({
            ...currentModal,
            subtitle: id ? `${id}` : 'New',
          });
        })
      )
      .subscribe();

    this.newMileageExpenseAutoSaving$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((bool) => {
          const currentModal = this.modalInputSubject.getValue();
          if (bool === true) {
            this.modalInputSubject.next({
              ...currentModal,
              caption: 'Saving...',
            });
          } else if (bool === false) {
            this.modalInputSubject.next({
              ...currentModal,
              caption: 'All Changes saved',
            });
          } else {
            this.modalInputSubject.next({
              ...currentModal,
              caption: '',
            });
          }
        })
      )
      .subscribe();

    const unsubscribeAfterRegisteringForm = new Subject<void>();
    this.fm.getFormManager$(this.formId).pipe(
      takeUntil(unsubscribeAfterRegisteringForm),
      tap((fm) => {
        if(!fm) {
          return;
        }
        unsubscribeAfterRegisteringForm.next();
        unsubscribeAfterRegisteringForm.complete();
        fm.callback('saveAndClose', () => this.saveAndClose());
      })
    ).subscribe()
  }

  onDismiss(closeRequest: CloseRequest) {
    if(!this.form || !Object.keys(this.form).length) {
      this.store$.dispatch(LayoutActions.dismissNewExpenseModal());
      return;
    }

    closeRequest.isClosing = false;
    this.alertService.getUnsavedChangesAlert(
      'mileage-expense-modal-new',
      () => this.saveAndClose()
    );
  }

  onTrashAutoSavedMileage() {
    this.store$.dispatch(NewMileageActions.trashAutoSaved());
  }

  onNewMileageFormChange(value) {
    this.form = {
      ...value,
    };

    this.currentExpense = this.form;
    this.changeCount++;
  }

  onNewMileageFormStatusChange(value) {
    this.isNewMileageValidSubject.next(value);
    const currentModal = this.modalInputSubject.getValue();
    this.modalInputSubject.next({
      ...currentModal,
      fieldsComplete: value,
      // statusText: this.setStatusText(value),
    });
  }

  onGetNewMileageReimbursement(mileage) {
    this.store$.dispatch(
      NewMileageActions.getEstimatedReimbursement({ mileage })
    );
  }

  onNewMileageSubmit() {
    this.submittedSubject$.next(true);
    if (this.form && this.isNewMileageValidSubject.getValue()) {
      this.store$.dispatch(NewMileageActions.submit({ expense: this.form }));
      this.modalService.close('mileage-expense-modal-new');
    }
  }

  onFavoriteJobCode(id) {
    this.store$.dispatch(FavoriteJobCodeActions.saveOneFavorite({ id }));
  }

  onFavoriteSalesforceCase(id) {
    this.store$.dispatch(
      FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase({ id })
    );
  }

  ngOnDestroy() {
    this.toastrService.clear();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.fm.deregister(this.formId);
  }

  saveAndClose() {
    // Save
    if (this.changeCount > 0) {
      this.store$.dispatch(NewMileageActions.save({expense: this.currentExpense}));

      // Close
      this.isSaveSuccessful$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((isSuccessful) => {
          if (isSuccessful) {
            this.changeCount = 0;
            this.modalService.close('mileage-expense-modal-new');
          }
        } )
      ).subscribe();
    }
  }
}
