import { createSelector } from '@ngrx/store';
import { DashboardState, selectDashboardState } from '../reducers/state';
import * as fromTransactionFeedItem from '../reducers/transaction-feed-item.reducer';

export const selectDashboardTransactionFeedItemState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.transactionFeedItem
);

export const {
  selectIds: selectTransactionFeedItemIds,
  selectEntities: selectTransactionFeedItemEntities,
  selectAll: selectAllTransactionFeedItem,
} = fromTransactionFeedItem.adapter.getSelectors(
  selectDashboardTransactionFeedItemState
);

export const selectTransactionFeedFilters = createSelector(
  selectDashboardTransactionFeedItemState,
  fromTransactionFeedItem.filters
);

export const selectTransactionFeedTotalCount = createSelector(
  selectDashboardTransactionFeedItemState,
  fromTransactionFeedItem.totalCount
);

export const selectedTransactionFeedDownloadUrl = createSelector(
  selectDashboardTransactionFeedItemState,
  fromTransactionFeedItem.feedItemDownloadUrl
);

export const selectTransactionFeedLoading = createSelector(
  selectDashboardTransactionFeedItemState,
  fromTransactionFeedItem.transactionFeedLoading
);
