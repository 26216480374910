import { FavoriteSalesforceCase } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const getFavoriteSalesforceCase = createAction(
  '[Favorite SalesForceCase] getFavoritesSalesForceCase'
);

export const getFavoriteSalesforceCaseComplete = createAction(
  '[Favorite SalesForceCase] getFavoriteSalesforceCaseComplete',
  props<{ salesforceCases: FavoriteSalesforceCase[] }>()
);

export const getFavoriteSalesforceCaseError = createAction(
  '[Favorite SalesForceCase] getFavoriteSalesforceCaseError',
  props<{ error: any }>()
);

export const saveFavoriteSalesforceCase = createAction(
  '[Favorite SalesForceCase] saveFavoriteSalesforceCase',
  props<{ id: number }>()
);

export const saveFavoriteSalesforceCaseComplete = createAction(
  '[Favorite SalesForceCase] saveFavoriteSalesforceCaseComplete',
  props<{ salesforceCase: FavoriteSalesforceCase }>()
);

export const saveFavoriteSalesforceCaseError = createAction(
  '[Favorite SalesForceCase] saveFavoriteSalesforceCaseError',
  props<{ error: any }>()
);

export const deleteFavoriteSalesforceCase = createAction(
  '[Favorite SalesForceCase] deleteFavoriteSalesforceCase',
  props<{ salesforceCase: number }>()
);

export const deleteFavoriteSalesforceCaseComplete = createAction(
  '[Favorite SalesForceCase] deleteFavoriteSalesforceCaseComplete',
  props<{ salesforceCase: FavoriteSalesforceCase }>()
);

export const deleteFavoriteSalesforceCaseError = createAction(
  '[Favorite SalesForceCase] deleteFavoriteSalesforceCaseError',
  props<{ error: any }>()
);
