import { Injectable } from '@angular/core';
import {
  ApiService,
  BaseModalService,
  UtilsService
} from '@ems-gui/expense/util-web-infrastructure';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ActivityActions,
  LayoutActions,
  MileageDraftActions,
  NewActions,
  NewMileageActions,
  SubmittedActions,
  TrashActions,
} from '../../actions';
import { errorHandler } from '../../error-handler';
import * as fromDashboard from '../../reducers';
import {
  selectDraftActivityId,
  selectedDraftId,
  selectFilters,
} from '../../selectors/expense.selectors';
import { selectJobCodeEntities } from '../../selectors/job-code.selectors';

@Injectable()
export class DraftMileageExpenseEffects {
  submit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.submit),
      withLatestFrom(
        this.store$.pipe(select(selectDraftActivityId)),
        this.store$.pipe(select(selectedDraftId))
      ),
      switchMap(([res, activityId, id]) =>
        this.apiService
          .submitMileageDraft({
            ...res.expense,
            activityId: activityId ? activityId : res.expense.activityId,
            id,
          })
          .pipe(
            map((response) =>
              MileageDraftActions.submitDraftComplete({
                expense: response.expense,
              })
            ),
            catchError(({ error }) => {
              const message = `Could not submit: ${error.message}`;
              return [LayoutActions.globalToastErrorMessage({ message })];
            })
          )
      )
    )
  );

  dismiss$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.submitDraftComplete),
      map(() => LayoutActions.dismissModal())
    )
  );

  getReimbursement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.getEstimatedReimbursement),
      switchMap((payload: any) =>
        this.apiService.getMileageReimbursement(payload.mileage).pipe(
          map((res) =>
            MileageDraftActions.getEstimatedReimbursementComplete({
              reimbursement: res.reimbursement,
            })
          ),
          catchError(
            errorHandler(MileageDraftActions.getEstimatedReimbursementError)
          )
        )
      )
    )
  );

  editDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.editDraft),
      withLatestFrom(this.store$.select(selectJobCodeEntities)),
      switchMap(([{ id }, store]) =>
        this.apiService.getActivity(id).pipe(
          map((response: any) => {
            const activity = response.map((a) => {
              const modifiedActivity = { ...a };
              modifiedActivity.jobCode = store[a?.jobCode]?.code;
              return modifiedActivity;
            });
            return ActivityActions.getMileageActivityComplete({ activity });
          }),
          catchError(errorHandler(ActivityActions.getMileageActivityError))
        )
      )
    )
  );

  displayMileageDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.getMileageActivityComplete),
      withLatestFrom(this.store$.pipe(select(selectedDraftId))),
      map(([action, id]) => {
        return LayoutActions.openExpenseModal({
          name: 'mileage-expense-modal-edit',
          id: typeof id === 'number' ? id : +id,
        });
      })
    )
  );

  save$ = createEffect(() =>
      this.actions$.pipe(
        ofType(MileageDraftActions.save),
        map((action: any) => {
          if (!action.expense.message) {
            const expense = JSON.parse(JSON.stringify(action.expense));
            delete expense.message;
            return MileageDraftActions.saveWithoutNote({expense});
          } else {
            return MileageDraftActions.saveWithNote({expense:action.expense});
          }
        })
      )
  );

  saveWithoutNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.saveWithoutNote),
      switchMap(({ expense }) =>
        this.apiService.saveMileageDraft(expense).pipe(
          map((response) =>
            MileageDraftActions.saveWithoutNoteComplete({
              expense: response,
            })
          ),
          catchError(errorHandler(MileageDraftActions.saveWithoutNoteError))
        )
      )
    )
  );

  saveWithoutNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.saveWithoutNoteComplete),
      withLatestFrom(this.store$.pipe(select(selectDraftActivityId))),
      filter(([action, id]) => id !== null),
      switchMap(([note, activityId]) =>
        this.apiService.deleteNote(activityId).pipe(
          map(({ activity }) =>
            ActivityActions.deleteDraftNoteComplete({ activity: activity })
          ),
          catchError(errorHandler(MileageDraftActions.saveWithoutNoteError))
        )
      )
    )
  );

  saveWithNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.saveWithNote),
      withLatestFrom(this.store$.pipe(select(selectDraftActivityId))),
      switchMap(([action, activityId]) =>
        this.apiService
          .saveMileageDraft({
            ...action.expense,
            activityId: activityId ? activityId : action.expense.activityId,
          })
          .pipe(
            map((response) =>
              MileageDraftActions.saveWithNoteComplete({
                expense: response,
              })
            ),
            catchError(errorHandler(MileageDraftActions.saveWithNoteError))
          )
      )
    )
  );

  saveWithNoteComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.saveWithNoteComplete),
      map(({ expense }) =>
        ActivityActions.getDraftActivityId({ activity: expense.activity.id })
      )
    )
  );

  trash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.trashDrafts),
      switchMap(({ expenses }) =>
        this.apiService.trashExpenses({ expenses }).pipe(
          map((response) =>
            MileageDraftActions.trashDraftsComplete({ expenses: response })
          ),
          catchError(errorHandler(MileageDraftActions.trashDraftsError))
        )
      )
    )
  );

  trashComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MileageDraftActions.trashDraftsComplete,
        NewMileageActions.trashAutoSavedComplete,
        NewActions.trashAutoSavedComplete
      ),
      withLatestFrom(this.store$.pipe(select(selectFilters))),
      tap(() => {
        this.modalService.close('mileage-expense-modal-edit');
        this.utils.displaySnackbar('Mileage', 'trashed', '/expenses/trash');
      }),
      switchMap(([, filters]) => [
        SubmittedActions.getUpdatedUnsubmitted({
          page: filters.unsubmitted.page,
          sort: filters.unsubmitted.sort,
          filters: filters.unsubmitted.filters,
        }),
        TrashActions.getUpdatedTrash({
          page: filters.trash.page,
          sort: filters.trash.sort,
          filters: filters.trash.filters,
        }),
        LayoutActions.dismissModal(),
      ])
    )
  );

  uploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageDraftActions.uploadComplete),
      switchMap(({ id }) =>
        this.apiService.getOneExpense(id).pipe(
          map((expense) => MileageDraftActions.getExpenseComplete({ expense })),
          catchError(errorHandler(MileageDraftActions.getExpenseError))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<fromDashboard.State>,
    private modalService: BaseModalService,
    private utils: UtilsService,
  ) {}
}
