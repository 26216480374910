import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  ActivityActions,
  DraftActions,
  FavoriteJobCodeActions,
  FavoriteSalesforceCaseActions,
  LayoutActions,
  selectAllExpenseTypesExceptMileage,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectJobCodesForDraftDropdown,
  selectAllSalesforceCasesWithGroupLabels,
  selectCurrentDraftReceipt,
  selectDraftAutoSaving,
  selectDraftItemizationStatus,
  selectedDraftForWebOnly,
  selectedDraftItemsWithInvalidStatus,
  selectedProxyId,
  selectModalDismiss,
  State,
  draftExpensesLoading,
  selectDraftError,
  selectFromCustomCaseApi,
  SalesforceCaseActions,
  NewActions,
  selectMatchedExpenseId,
  ExpenseMatchActions
} from '@ems-gui/expense/util-web-ngrx';
import {
  ConvertToDollarsPipe,
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  ModalInput,
  SalesforceCase,
} from '@ems-gui/shared/util-core';
import { ExpenseService } from '../../../../../app/services/expense.service';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
  withLatestFrom,
  firstValueFrom
} from 'rxjs';
import {
  delay,
  share,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { UtilsService } from '@ems-gui/expense/util-web-infrastructure';
import { CloseRequest } from '@ems-gui/expense/ui-web-components';
import { AlertService } from '@src/app/services/alert.service';
import { FormManagerService } from '@src/app/services/form-manager.service';
import { SaveExpensePayload } from '@libs/shared/util-core/src/lib/models/expense.model';

const MODAL_ID = 'expense-form';

@Component({
  selector: 'ems-expense-edit-modal',
  templateUrl: './expense-edit-modal.component.html',
  styleUrls: ['./expense-edit-modal.component.scss'],
  providers: [ConvertToDollarsPipe, CurrencyPipe],
})
export class ExpenseEditModalComponent implements OnInit, OnDestroy {
  public formId = MODAL_ID;
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Expense: ',
    label: '',
    labelClass: '',
    caption: '',
    status: '',
    subtitle: '',
    fieldsComplete: false,
    modalId: MODAL_ID,
    scrollToBottom: false
  });
  modalInput$ = this.modalInputSubject.asObservable();
  isFraudulent = false;
  isCardVerified = false;
  isCompanyCc = false;
  isForeignTransactionFee = false;
  isItemized;
  receiptAttached = false;
  invalidItemizationStatuses;
  public expense$ = <Observable<Expense>>this.store$
    .select(selectedDraftForWebOnly);
  public dismiss$ = this.store$.select(selectModalDismiss);
  public jobCodes$ = this.store$.select(selectJobCodesForDraftDropdown);
  public expenseTypes: ExpenseType[];
  favoriteJobCodes$: Observable<any>;
  salesforceCases: SalesforceCase[];
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;
  itemizedInvalidStatuses$: Observable<any>;
  itemizationStatus$: Observable<any>;
  receipt$: Observable<any>;
  unsubscribe$: Subject<void> = new Subject();
  autoSaving$: Observable<boolean>;
  proxy$: Observable<any>;
  statusSubject$: BehaviorSubject<string> = new BehaviorSubject(null);
  status$: Observable<string> = this.statusSubject$.asObservable();
  formSubject$: BehaviorSubject<Partial<Expense>> = new BehaviorSubject({});
  amountSubject$: ReplaySubject<Partial<Expense>> = new ReplaySubject();
  amountChangeSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
  form$: Observable<Partial<Expense>> = this.formSubject$
    .asObservable()
    .pipe(share());
  amountStatus$: Observable<any> = this.amountSubject$
    .asObservable()
    .pipe(share());
  amountChange$: Observable<any> = this.amountChangeSubject$
    .asObservable()
    .pipe(share());
  expenseStatus = null;
  submittedSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  submitted$: Observable<boolean> = this.submittedSubject$.asObservable();
  private originalForm: string;
  public isFormDirty = false;
  smallMaxScreenWidth = 767;
  isMobileView: boolean = false;
  screenWidth: number;
  showAdditionalDetails: boolean = false;
  activityTabOpen = false;
  file: File;
  rotationAngle: number;
  receiptToken: string;
  newReceipt: boolean = false;
  matchedExpenseId: number;
  private currentExpenseId: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
     this.screenWidth = window.innerWidth;
     this.isMobileView = this.screenWidth <= this.smallMaxScreenWidth;
  }

  constructor(
    private convertToDollarsPipe: ConvertToDollarsPipe,
    private currencyPipe: CurrencyPipe,
    public modalService: BaseModalService,
    private alertService: AlertService,
    private store$: Store<State>,
    private toastrService: ToastrService,
    private expenseService: ExpenseService,
    private utilsService: UtilsService,
    private formManager: FormManagerService
  ) {
    this.store$.select(selectAllExpenseTypesExceptMileage).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(expenseTypes => this.expenseTypes = expenseTypes);

    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );

    this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels),
      takeUntil(this.unsubscribe$)
      ).subscribe(salesforceCases => {
        this.salesforceCases = salesforceCases;
      });

    // IF the expense has an outdated customCase get the value and add to the list
    this.store$.select(selectFromCustomCaseApi).pipe(
      takeUntil(this.unsubscribe$),
      tap((outDatedSalesforceCase) => {
        if(outDatedSalesforceCase && this.salesforceCases) {
          const updateSalesforceCase = {
            ...outDatedSalesforceCase,
            label: outDatedSalesforceCase.case_number + ' ' + outDatedSalesforceCase.name,
            case_number:  outDatedSalesforceCase?.case_number?.substr(6),
            groupName: 'All Cases'
          }
          this.salesforceCases = [...this.salesforceCases, updateSalesforceCase];
        }
      })
    ).subscribe();

    this.store$.select(selectMatchedExpenseId).pipe(
      takeUntil(this.unsubscribe$),
      tap((matchedExpenseId) => this.matchedExpenseId = matchedExpenseId)
    ).subscribe();

    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.autoSaving$ = this.store$.pipe(select(selectDraftAutoSaving));
    this.itemizedInvalidStatuses$ = this.store$.pipe(
      select(selectedDraftItemsWithInvalidStatus)
    );
    this.itemizationStatus$ = this.store$.pipe(
      select(selectDraftItemizationStatus)
    );
    this.receipt$ = this.store$.pipe(select(selectCurrentDraftReceipt));
    this.proxy$ = this.store$.pipe(select(selectedProxyId));
    this.onResize();
  }

  ngOnInit() {
    this.expense$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.formManager.getFormManager$(this.formId)),
      tap(([expense, fm]) => {
        if(fm) {
          const newForm = fm.processExpense(expense);
          fm.update(newForm);
        }
      })
    ).subscribe();

    // check if the expense has an outdated custom case and if yes get the details
    this.expense$.pipe(
      takeUntil(this.unsubscribe$),
      tap(async (receivedExpense) => {
        const expense = <Expense>receivedExpense;
        if(expense.status && expense.id !== this.currentExpenseId) {
          this.currentExpenseId = expense.id;
          this.updateTitle(<Expense>expense, expense.status, false);
        }

        const salesforceCase =
          expense &&
          expense.salesforceId &&
          this.salesforceCases.some(caseObj => caseObj.id === expense.salesforceId);

        if(salesforceCase === undefined && expense.salesforceId) {
          const action = SalesforceCaseActions.getCustomCaseByExternalId({
            externalId: expense.salesforceId
          });
          this.store$.dispatch(action);
        }
      })
    ).subscribe();

    // This was added to ensure it only loads once after form registers.
    const callbackUnsubscribe = new Subject<void>();
    this.formManager.getFormManager$(this.formId).pipe(
      takeUntil(callbackUnsubscribe),
      tap((fm) => {
        if(!fm) return
        fm.callback('save', () => this.saveChanges())
          .callback('saveAndClose', () => this.saveAndClose());
        callbackUnsubscribe.next();
        callbackUnsubscribe.complete();
      }),
    ).subscribe();

    this.formManager.isFormDirty$(this.formId).pipe(
      takeUntil(this.unsubscribe$),
      tap(async (isFormDirty) => {
        this.isFormDirty = isFormDirty
      })
    ).subscribe();

    this.amountStatus$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.store$.dispatch(DraftActions.unableToItemizeReset());
        })
      )
      .subscribe();

    this.amountChange$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((amount) => {
          if (amount) {
            this.store$.dispatch(DraftActions.updateAmount({ amount }));
          }
        })
      )
      .subscribe();

    this.itemizedInvalidStatuses$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((statuses) => {
          this.invalidItemizationStatuses = statuses;
        })
      )
      .subscribe();


    this.form$.subscribe(formValue => {
      this.isFraudulent = formValue.fraudulent;
    });

    combineLatest([this.form$, this.status$, this.autoSaving$])
      .pipe(
        delay(0),
        // debounceTime(150),
        takeUntil(this.unsubscribe$),
        tap(([expense, status, autoSaving]) => {
          this.updateTitle(<Expense>expense, status, autoSaving);
        })
      )
      .subscribe();

    this.receipt$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((receipt) => {
          if (receipt) {
            this.receiptAttached = true;
            this.store$.dispatch(DraftActions.unableToItemizeReset());
          }
        })
      )
      .subscribe();
  }

  updateTitle(expense: Expense, status: string, autoSaving: boolean) {
    this.isCompanyCc = expense && expense.paymentType === 'company';
    this.isCardVerified = expense && expense.cardVerified;
    this.isItemized = expense && expense.parent;
    this.isForeignTransactionFee =
      this.utilsService.checkIfForeignTransactionType(expense, this.expenseTypes);

    // setCaption returns a string that will be used in the expense form's caption. When the draft/rejected
    // expense is first opened, either a string with the last time the expense was updated will be displayed if
    // the expense's last updated date is the same as the current date or a string with the full date of the last
    // expense update will be displayed
    const setCaption = () => {
      const lastUpdatedDate = expense.updatedAt;
      const fullExpenseDate = formatDate(
        lastUpdatedDate,
        'shortDate',
        'en-US'
      );

      /**
       * REFACTOR NEEDED:
       * WHY: This is a "create message" function! Extract!"
       */
      //   return `Last updated today at ${lastUpdatedTime}`;
      if (autoSaving === true) {
        return 'Saving...';
      }

      return `Last updated on ${fullExpenseDate}`;
    };

    const onModalInputLabel = ({ formStatus, formFraudulent }) => {
      if (formFraudulent === true) {
        return '[FRAUDULENT]';
      }
      if (formStatus === 'rejected') {
        return '[REJECTED]';
      }
      return '[' + formStatus + ']';
    };

    const onModalInputLabelClass = ({ formStatus, formFraudulent }) => {
      if (formFraudulent === true) {
        return 'danger';
      }
      switch (formStatus) {
        case 'rejected':
          return 'danger';

        case 'awaiting verification':
          return 'warn';

        default:
          return '';
      }
    };

    const currentModal = this.modalInputSubject.getValue();
    this.modalInputSubject.next({
      ...currentModal,
      title: this.expenseService.getExpenseModalTitle(expense.paymentType),
      subtitle: `${expense.id}`,
      label: onModalInputLabel({
        formFraudulent: expense.fraudulent,
        formStatus: expense.status,
      }),
      labelClass: onModalInputLabelClass({
        formFraudulent: expense.fraudulent,
        formStatus: expense.status
      }),
      caption: expense.updatedAt ? setCaption() : '',
      status:
        status &&
        ((this.isFraudulent ) ||
          (this.receiptAttached || this.isForeignTransactionFee)
        )
          ? 'positive'
          : '',
      fieldsComplete:
        status &&
        (this.isFraudulent || this.receiptAttached || this.isForeignTransactionFee)
          ? true
          : false,
    });
    this.expenseStatus = status;
  }

  onDeleteDraft() {
    if (!this.isItemized && !this.isCardVerified) {
      this.formSubject$
        .pipe(
          take(1),
          tap((form) => {
            this.store$.dispatch(
              DraftActions.trashDrafts({ expenses: [form.id] })
            );
            this.modalService.close('expense-form');
          })
        )
        .subscribe();
    } else if (this.isItemized) {
      this.toastrService.show(
        'Use Re-Itemize or De-Itemize to remove itemized expenses.',
        '',
        { timeOut: 10000 }
      );
    } else if (this.isCardVerified) {
      this.toastrService.show(
        'Company Credit Card expenses that have been verified cannot be deleted.',
        '',
        { timeOut: 10000 }
      );
    }
  }

  async onDismiss(closeRequest: CloseRequest) {
    const { modalId } = closeRequest;
    // Check if rotationAngle has a value and set isClosing to false if it does
    if (this.rotationAngle) {
      closeRequest.isClosing = false;
    } else {
      closeRequest.isClosing = !this.isFormDirty;
    }
    if(closeRequest.isClosing) {
      this.store$.dispatch(LayoutActions.dismissModal());
    } else {
      this.alertService.getUnsavedChangesAlert(
        modalId,
        () => this.saveAndClose()
      );
    }
  }

  onSubmit() {
    this.formSubject$
      .pipe(
        take(1),
        tap((form) => {
          const updatedForm = {
            ...form,
          };
          delete updatedForm.updatedAt;
          if (updatedForm.parent) {
            delete updatedForm.parent;
          }
          const payload: Partial<SaveExpensePayload> = {
            ...updatedForm,
            file: this.file,
            rotationAngle: this.rotationAngle,
            newReceipt: this.newReceipt
          }
          if (payload.travelTicket && typeof payload.travelTicket === 'object') {
            payload.travelTicket = payload.travelTicket.sfId;
          }
          if (payload.type && payload.jobCode) {
            this.store$.dispatch(DraftActions.submit({saveExpensePayload:payload}));
          }
          this.modalService.close('expense-form');
          if (this.matchedExpenseId) {
            this.store$.dispatch(
              DraftActions.trashDrafts({ expenses: [this.matchedExpenseId] })
            );
            this.store$.dispatch(
              ExpenseMatchActions.clearUserCreatedCreditCardExpenses()
            );
          }
        })
      )
      .subscribe();
  }

  onOpenModal() {
    this.submittedSubject$.next(true);
    if (this.expenseStatus) {
      if (this.isFraudulent === true) {
        this.formSubject$
          .pipe(
            take(1),
            tap((form) => {
              const updatedForm = { ...form };
              delete updatedForm.updatedAt;
              this.store$.dispatch(
                DraftActions.submitFraudDraft({ form: updatedForm })
              );
              this.modalService.open('expense-fraud-warn-modal');
            })
          )
          .subscribe();
        return;
      } else if (this.isCompanyCc && !this.isCardVerified) {
        this.toastrService.show(
          'Company Credit Card expenses cannot be submitted until verified.',
          '',
          { timeOut: 10000 }
        );
      } else if (!this.receiptAttached && !this.isForeignTransactionFee) {
        this.toastrService.show(
          'A receipt is required for this expense.',
          '',
          { timeOut: 10000 }
        );
      } else {
        this.onSubmit();
      }
    }
  }

  saveCompanyCreditCardExpense() {
    this.submittedSubject$.next(true);
    if (this.expenseStatus) {
      if (!this.receiptAttached && !this.isForeignTransactionFee) {
        this.toastrService.show(
          'A receipt is required for this expense.',
          '',
          { timeOut: 10000 }
        );
      }
      else {
        this.formSubject$
          .pipe(
            take(1),
            tap(() => {
              this.saveAndClose();
            })
          )
          .subscribe();
      }
    }
  }

  onFormChange(value) {
    this.formSubject$.next(value);
  }

  onAmountChange(value) {
    this.amountChangeSubject$.next(value);
  }

  onExistingStatusChange(value) {
    this.statusSubject$.next(value);
  }

  onFavoriteJobCode(id) {
    this.store$.dispatch(FavoriteJobCodeActions.saveOneFavorite({ id }));
  }

  onFavoriteSalesforceCase(id) {
    this.store$.dispatch(
      FavoriteSalesforceCaseActions.saveFavoriteSalesforceCase({ id })
    );
  }

  onDeleteNote(id) {
    this.store$.dispatch(ActivityActions.deleteDraftNote({ id }));
  }

  onItemizeDraft() {
    if(this.isFormDirty || this.file) {
      this.saveChanges();
    }
    if (this.isCompanyCc && !this.isCardVerified) {
      this.toastrService.show(
        'Only verified Company Credit Card expenses can be itemized.',
        '',
        { timeOut: 10000 }
      );
    } else {
      this.amountChangeSubject$
        .pipe(
          take(1),
          tap((amount) => {
            if(+amount <= 0) {
              return this.popupToast(
                'Amount must be more than zero to itemize.'
              );
            }

            if(!this.receiptAttached) {
              return this.receiptRequired();
            }

            return this.modalService.open('expense-itemize-draft-modal');
          })
        )
        .subscribe();
    }
  }

  onAmountStatusChange(value) {
    this.amountSubject$.next(value);
  }

  receiptRequired() {
    return this.popupToast('Receipt is required to itemize expense.');
  }

  popupToast(message: string , title: string = '', timeOut: number = 10000) {
    return this.toastrService.show(message, title, { timeOut });
  }

  onRedoItemization() {
    if (this.invalidItemizationStatuses) {
      this.popupToast('This itemization can no longer be Re-Itemized because part of it has already been submitted.')
    } else {
      this.store$.dispatch(
        DraftActions.getItemizedParentData({ id: +this.isItemized })
      );
    }
  }

  onUndoItemization() {
    if (this.invalidItemizationStatuses) {
      this.toastrService.show(
        'This itemization can no longer be De-Itemized because part of it has already been submitted.',
        '',
        { timeOut: 10000 }
      );
    } else {
      this.modalService.open('undo-itemization-alert-modal');
    }
  }

  onRemoveReceipt(id) {
    this.newReceipt = true;
    this.receiptAttached = false;
    this.store$.dispatch(NewActions.clearReceiptData());
  }

  onReceiptData(receiptData) {

    if(receiptData.file){
      this.receiptAttached = true;
    }
    this.file = receiptData.file;
    this.rotationAngle = receiptData.rotationAngle;
    this.receiptToken = receiptData.token;
    this.newReceipt = receiptData.newReceipt;
  }

  onFileUploadSizeError() {
    this.toastrService.show('Receipt file size cannot exceed 10MB.', '', {
      timeOut: 10000,
    });
  }

  onReceiptUploaded(draft) {
    this.store$.dispatch(DraftActions.uploadComplete({ draft }));
  }

  onRotateReceipt({ id, angle }) {
    this.store$.dispatch(DraftActions.rotateReceipt({ id, angle }));
  }

  ngOnDestroy() {
    this.toastrService.clear();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.formManager.deregister(this.formId);
  }

  saveChanges() {
    this.formManager.updatedFields$(this.formId).pipe(
      take(1),
      withLatestFrom(this.expense$),
      tap(([updatedFields, { id }]) => {
        const expense = { ...updatedFields, id };
        if (this.file || (this.file && this.rotationAngle)) {
          const saveExpensePayload: Partial<SaveExpensePayload> = {
            ...expense,
            rotationAngle: this.rotationAngle,
            file: this.file,
            token: this.receiptToken,
            newReceipt: this.newReceipt
          };
          this.store$.dispatch(
            DraftActions.saveExpenseWithReceipt({ saveExpensePayload })
          )
        }
        else {
          this.store$.dispatch(DraftActions.save({ expense }));
        }
        const oldForm = this.formManager.get(this.formId).original;
        const newExpense = { ...oldForm, ...updatedFields };
        this.formManager.get(this.formId).update(newExpense);
      })
    ).subscribe();
  }

  saveAndClose() {
    this.saveChanges();
    this.store$.select(draftExpensesLoading)
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.store$.select(selectDraftError)),
        tap(([isLoading, error]) => {
          if (!isLoading && !error) {
            this.modalService.close('expense-form');
          }
          else if(error) this.toastrService.show(error);
        })
      ).subscribe();
  }

  onTabSelected(tab: string) {
    if (tab === 'Activity Log') {
      this.activityTabOpen = true;
    } else {
      this.activityTabOpen = false;
    }
  }
    /**
   * REFACTOR NEEDED:
   * WHY: formatDate can be called once or not withinn string interpolation
   */
    getTimestamp(date: number) {
      const beginningTimeToday = new Date(
        formatDate(new Date(), 'mediumDate', 'en-US')
      ).getTime();
      const endingTimeToday = beginningTimeToday + 86399999;
      const beginningTimeYesterday = beginningTimeToday - 86400000;
      const endingTimeYesterday = beginningTimeToday - 1;

      if (date >= beginningTimeToday && date <= endingTimeToday) {
        return `Today at ${formatDate(date, 'shortTime', 'en-US')}`;
      } else if (date >= beginningTimeYesterday && date <= endingTimeYesterday) {
        return `Yesterday at ${formatDate(date, 'shortTime', 'en-US')}`;
      } else {
        return `${formatDate(date, 'MM/dd/yyyy', 'en-US')} ${formatDate(
          date,
          'shortTime',
          'en-US'
        )}`;
      }
    }

    getFormattedNote(item) {
      if (item.action === 'Reimbursed Expense') {
        return `${this.currencyPipe.transform(
          this.convertToDollarsPipe.transform(item.amount)
        )} has been reimbursed to ${item.author.author}.`;
      } else if (
        (item.action !== 'Reimbursed Expense' || item.action !== 'Recall')
        && item.message)
      {
        return item.message;
      } else {
        return '';
      }
    }
}
