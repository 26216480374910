<ems-base-modal
  [config]="modalConfig$ | async"
  [activityTabOpen]="activityTabOpen"
  class="approval-modal"
>
  <ems-base-button-group
    modal-actions
    *ngIf="(openApproval$ | async)?.status === 'pending' && !enableUploadReceipt && showEditSection"
    class="approval-actions"
  >
    <ems-base-button
      (click)="onSendTo()"
      label="Edit"
      [showLabel]='isMobileView()'
      class="approval-dropdown"
      buttonType="secondary"
      variant="brand"
    >
      <ems-base-icon
        icon-append
        iconName="pencil_edit"
        width="110%"
        height="110%"
      ></ems-base-icon>
    </ems-base-button>
    <ems-base-button
      (click)="modalService.open('reject-modal')"
      label="Reject"
      buttonType="primary"
      variant="destructive"
      class="approval-button"
    >
      <ems-base-icon
        icon-append
        iconName="times"
        width="110%"
        height="110%"
      ></ems-base-icon>
    </ems-base-button>
    <ems-base-button
      (click)="onApprove()"
      label="Approve"
      buttonType="primary"
      variant="success"
      class="approval-button"
    >
      <ems-base-icon
        icon-append
        iconName="check"
        width="130%"
        height="130%"
      ></ems-base-icon>
    </ems-base-button>
  </ems-base-button-group>
  <ems-expense-modal
    [expense]="openApproval$ | async"
    [isApproval]="true"
    [isDisabled]="false"
    [canUploadNew]="enableUploadReceipt"
    (rotateReceipt)="onRotateReceipt($event)"
    (tabSelected)="onTabSelected($event)"
    (fileSizeError)="onFileUploadSizeError()"
    (receiptUploaded)="onReceiptUploaded($event)"
    (removeReceipt)="onRemoveReceipt($event)"
  ></ems-expense-modal>
</ems-base-modal>
