import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
export class CustomValidators {
  static validAmount: ValidatorFn = (control: AbstractControl) => {
    if (
      (typeof control.value === 'string' && control.value?.trim() === '') ||
      control.value === null
    ) {
      return null;
    }
    const amountString =
      typeof control.value === 'string' && control.value?.replace(/[$,]/g, '');
    const validNumDecimals = /^\$?[,0-9]*(\.[0-9])?[0-9]?$/.test(control.value);
    const hasValidComma = /^\$?[1-9]\d*(((,\d{3})*)(\.\d{0,2})?)$/.test(
      control.value
    );
    const isNotNumbers = /[^.$0-9]/.test(amountString);
    const amountValue = parseFloat(amountString);
    const amountIsZero = amountValue === 0;
    const isNotFalsy = !!amountString;
    const amountNotValid =
      isNotNumbers ||
      !validNumDecimals ||
      amountIsZero ||
      !isNotFalsy ||
      (amountValue >= .01 && !hasValidComma);

    return amountNotValid ? { amountNotValid: true } : null;
  };
  static belowMaxAmount: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const valueToString = control.value.toString();
    const amountString = valueToString.replace(/[$,]/g, '');
    const amountValue = parseFloat(amountString);
    const maxAmountExceeded = amountValue > 9999999;
    return maxAmountExceeded ? { maxAmountExceeded: true } : null;
  };
  static validCompanyAmount: ValidatorFn = (control: AbstractControl) => {
    const INVALID_AMOUNT = { amountNotValid: true };
    if (!control.value) {
      return INVALID_AMOUNT;
    }

    const MAX_AMOUNT_ACCEPTED = 99999999;// $999999.99 in cents
    const valueToString = control.value.toString();
    // first replace is to replace first occurrence of $ and second it to replace ,
    const received = valueToString.replace(/\$/, '').replace(/,/g, '');

    const converted = +new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false
    }).formatToParts(received)
      .filter((part) => {
        return ['integer', 'fraction'].includes(part.type);
      }).map((part) => {
        return part.type === 'fraction' && part.value.length > 2 ?
          `${Math.floor(+part.value)}` :
          part.value;
      }).join('');

    return !converted || converted > MAX_AMOUNT_ACCEPTED ?
      INVALID_AMOUNT :
      null;
  };

  static validMileageRate: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return { amountNotValid: true };
    }
    const rateString = control.value.replace(/[$,]/g, '');
    const rateValue = parseFloat(rateString);
    const isLessThanOneWithInvalidDecimals = !/^\$?0*(\.[0-9])?[0-9]?[0-9]?$/.test(
      control.value
    );
    const isGreaterThanOneWithInvalidDecimals = !/^\$?[1-9]\d*(((,\d{3})*)(\.\d{0,3})?)$/.test(
      control.value
    );
    const isNotNumbers = /[^\-.$0-9]/.test(rateString);
    const amountNotValid =
      (rateValue < 1 && isLessThanOneWithInvalidDecimals) ||
      (rateValue > 1 && isGreaterThanOneWithInvalidDecimals) ||
      rateValue <= 0 ||
      isNaN(rateValue) ||
      isNotNumbers;

    return amountNotValid ? { amountNotValid } : null;
  };
  static validOdometerAmount: ValidatorFn = (control: AbstractControl) => {
    if (
      control.value === '' ||
      control.value === null ||
      control.value === '0'
    ) {
      return null;
    }
    const odometerString =
      typeof control.value === 'string'
        ? control?.value?.replace(/,/g, '')
        : control.value?.toString();
    const hasValidComma =
      typeof control.value === 'string'
        ? /^\$?[1-9]\d*(((,\d{3})*)(\.\d{0,2})?)$/.test(control.value)
        : true;
    return (odometerString && +odometerString < 0) ||
      !Number.isInteger(+odometerString) ||
      !hasValidComma
      ? { amountNotValid: true }
      : null;
  };
  static validExpenseIds: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    const idsValid = /^[\d,\s]+$/.test(control.value.trim());
    return idsValid ? null : { idsValid: true };
  };
  static validAmountOfExpenseIds: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const regex = /,+/g;
    let expenses = [];
    const expenseIds = control.value;
    if (regex.test(expenseIds)) {
      expenses = expenseIds.split(/,/);
    } else {
      expenses = expenseIds.split(/\s/);
    }

    expenses = expenses.map((i) => +i).filter((i) => i > 0);

    const validAmountOfIds = expenses.length <= 1000;

    return validAmountOfIds ? null : { validAmountOfIds: true };
  };
  static validDistance = (field1: string, field2: string): ValidatorFn => {
    return (form: FormGroup): ValidationErrors | null => {
      const startMileage = form.get(field1).value;
      const endMileage = form.get(field2).value;

      if (startMileage === null || endMileage === null) {
        return null;
      }

      if (!Number.isInteger(+startMileage) || !Number.isInteger(+endMileage)) {
        return null;
      }

      const mileage = endMileage - startMileage;
      const isValidDistance = mileage > 0;

      return isValidDistance ? null : { distanceInvalid: true };
    };
  };

}
