import { createSelector } from '@ngrx/store';
import * as fromFavoriteJobCode from '../reducers/favorite-job-code.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

// ---- Favorite Job Code

export const selectDashboardFavoriteJobCodeState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.favoriteJobCode
);

export const {
  selectIds: selectFavoriteJobCodeIds,
  selectEntities: selectFavoriteJobCodeEntities,
  selectAll: selectAllFavoriteJobCodes,
} = fromFavoriteJobCode.adapter.getSelectors(
  selectDashboardFavoriteJobCodeState
);
