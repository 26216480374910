<div class="user-chip">
  <div class="user-chip__avatar">
    <ems-user-popup
      type="avatar"
      [firstName]="firstName"
      [lastName]="lastName"
      [id]="id"
      [email]="email"
      [phone]="phone"
      [verticalLayout]="verticalPopup"
    ></ems-user-popup>
  </div>
  <div class="user-chip__content">
    <p class="user-chip__name">
      <ems-user-popup
        [firstName]="firstName"
        [lastName]="lastName"
        [id]="id"
        [email]="email"
        [phone]="phone"
        [verticalLayout]="verticalPopup"
      ></ems-user-popup>
    </p>
    <p class="user-chip__meta">#{{ id }}</p>
  </div>

  <button class="user-chip__dismiss" title="Dismiss" (click)="onDismissClick()">
    <ems-base-icon iconName="times"></ems-base-icon>
  </button>
</div>
