import { Injectable } from "@angular/core";
import { ApiService } from "@ems-gui/expense/util-web-infrastructure";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SalesforceCaseActions } from '@ems-gui/expense/util-web-ngrx';
import { map, switchMap } from 'rxjs/operators';
import { catchError, EMPTY, of } from 'rxjs';

const {
  getCustomCaseByExternalId,
  getCustomCaseByExternalIdComplete,
  getCustomCaseByExternalIdError
} = SalesforceCaseActions;

@Injectable()
export class SalesforceCaseEffects {
  public constructor(
    private apiService: ApiService,
    private actions$: Actions
  ) {}

  public onGetCustomCaseByExternalId$ = createEffect(
    () => this.actions$.pipe(
      ofType(getCustomCaseByExternalId),
      switchMap(({ externalId }) => {
        return this.apiService.getSalesforceCaseById(externalId).pipe(
          map((fromCustomCaseApi) =>
            getCustomCaseByExternalIdComplete({ fromCustomCaseApi })
          ),
          catchError((response) => {
            if(response.status === 404) {
              const error = `Custom Case: '${externalId}' Not Found`;
              return of(
                getCustomCaseByExternalIdError({ error })
              );
            }

            return EMPTY;
          })
        );
      })
    )
  );
}
