import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ems-base-button',
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss'],
})
export class BaseButtonComponent {
  /**
   * The label for the button
   */
  @Input() label = 'Button';

  /**
   * Sets button type Primary (filled), Secondary (outlined), Tertiary (plain text). Accepts `primary`, `secondary`, `tertiary`
   */
  @Input() buttonType = 'secondary';

  /**
   * Sets button variant (color). Accepts `brand`, `success` or `destructive`
   */
  @Input() variant = '';

  /**
   * Adds elevation to the button by adding a box-shadow
   */
  @Input() elevation = false;

  /**
   * Accepts `s`, `m`, `l`
   */
  @Input() size = 'l';

  /**
   * Visually hides the button label if true. Used for icon-only buttons.
   */
  @Input() showLabel = true;

  /**
   * Adds horizontal spacing to button if true.
   */
  @Input() space = false;

  /**
   * Accepts `active`, `disabled` or `loading`
   */
  @Input() state = '';

  @Input() isFilter: boolean;

  @Input() iconName = '';

  @Input() iconRight = false;

  /**
   * Emits an event when the button is clicked
   */
  @Output() buttonClick = new EventEmitter<boolean>();

  /**
   * Function that triggers the buttonClick event emitter
   */
  buttonClicked() {
    this.buttonClick.emit();
  }

  /**
   * Generates a list of classes used to style buttons
   */
  buttonClasses() {
    return {
      'is-primary': this.buttonType === 'primary',
      'is-secondary': this.buttonType === 'secondary',
      'is-tertiary': this.buttonType === 'tertiary',
      'is-tab': this.buttonType === 'tab',
      'is-dismiss': this.variant === 'dismiss',
      'is-brand': this.variant === 'brand',
      'is-inverted': this.variant === 'inverted',
      'is-success': this.variant === 'success',
      'is-destructive': this.variant === 'destructive',
      'is-cancel': this.variant === 'cancel',
      'is-active': this.state === 'active',
      'is-disabled': this.state === 'disabled',
      'is-loading': this.state === 'loading',
      'has-elevation': this.elevation === true,
      'has-space': this.space === true,
      'is-icon-only': this.showLabel === false,
      'is-size-s': this.size === 's',
      'is-size-m': this.size === 'm',
      'is-filter': this.isFilter,
    };
  }
}
