import { Component } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import { ApprovalActions, State } from '@ems-gui/expense/util-web-ngrx';
import { ModalInput } from '@ems-gui/shared/util-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ems-approver-fraud-warn-modal',
  templateUrl: './approver-fraud-warn-modal.component.html',
  styleUrls: ['./approver-fraud-warn-modal.component.scss'],
})
export class ApproverFraudWarnModalComponent {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'approver-fraud-warn-modal',
  };

  constructor(
    private store$: Store<State>,
    private modalService: BaseModalService
  ) {}

  onCancel() {
    this.modalService.close('approver-fraud-warn-modal');
  }

  onContinue() {
    this.store$.dispatch(
      ApprovalActions.verifyExpenses({ status: 'approved' })
    );
    this.modalService.close('approver-fraud-warn-modal');
  }
}
