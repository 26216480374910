import { Component, OnDestroy } from "@angular/core";
import { BaseModalService } from "@ems-gui/expense/util-web-infrastructure";
import { ModalInput } from "@ems-gui/shared/util-core";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { State, selectContinueTo, AllModalActions, LayoutActions } from "@ems-gui/expense/util-web-ngrx";

const THIS_MODAL_ID = 'credit-card-holder-alert';

@Component({
  selector: 'sis-credit-card-holder-alert-modal',
  templateUrl: './credit-card-holder-alert-modal.component.html',
  styleUrls: ['./credit-card-holder-alert-modal.component.scss']
})
export class CreditCardHolderAlertModalComponent implements OnDestroy {
  modalConfig: ModalInput = {
    title: '',
    modalId: 'credit-card-holder-alert',
  };
  private unsubscribe$: Subject<void> = new Subject();
  public continueTo = '';

  constructor(
    public modalService: BaseModalService,
    private store$: Store<State>
  ) {
    this.store$
      .select(selectContinueTo)
      .subscribe((continueTo) => this.continueTo = continueTo);
  }

  onContinue() {
    this.modalService.close(THIS_MODAL_ID);
    if(this.continueTo === 'file-upload')
      return this.store$.dispatch(AllModalActions.setAllowUploads({
        fileUploadAllowed: true
      }));

    return this.store$.dispatch(LayoutActions.openExpenseModal({ name: this.continueTo }));
  }

  onCancel() {
    this.modalService.close(THIS_MODAL_ID);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store$.dispatch(AllModalActions.resetContinueTo());
  }
}
