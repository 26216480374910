import { Injectable } from '@angular/core';
import {
  Router
} from '@angular/router';
import { AuthService } from '@sec-spec/lib-ng-oauth';
import { map, catchError, take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from '@ems-gui/shared/util-core';
import * as Sentry from '@sentry/angular-ivy'
import { OauthTokenService } from '../services/oauth-token.service';

/**
 * RoleGuardService
 */
@Injectable({
  providedIn: 'root'
})
export class ActiveUserGuardService {
  constructor(
    private router: Router,
    private tokenService: OauthTokenService,
    private authService: AuthService,
  ) {
    // TODO
  }

  /**
   * Verify the user is active to access spense
   */
  canActivate() {
    return this.tokenService.getAccessToken()
      .pipe(
        take(1),
        switchMap(
          (token) => !token ? of(false) : this.authService.getCurrentUser()
        ),
        map((user?: User) => {
          if(!user) return of(false);
          if(user.employeeStatus === 'Active') return true;

          this.goToAccessDeniedPage();
          return of(false);
        }),
        catchError(err => {
          console.error(err);
          this.goToAccessDeniedPage();
          return of(false);  // If there's an error, prevent navigation
        })
      );
  }

  /**
   * function to get Access Denied Screen
   */
  goToAccessDeniedPage() {
    this.router.navigate(['access-denied']).catch((err) => {
      Sentry.captureException(err);
    });
  }
}
