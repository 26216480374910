import { Component, Input, OnInit } from '@angular/core';
import { icons } from './icons';
@Component({
  selector: 'ems-base-icon',
  templateUrl: './base-icon.component.html',
  styleUrls: ['./base-icon.component.scss'],
})
export class BaseIconComponent implements OnInit {
  @Input() iconName: string;
  @Input() width = '100%';
  @Input() height = '100%';

  /* eslint-disable max-len */
  icons = icons;
  /* eslint-enable max-len */

  // Looks up an icon by name in the icons library array
  get icon(): any {
    return this.icons.find((o) => o.name === this.iconName);
  }

  constructor() {}

  ngOnInit() {}
}
