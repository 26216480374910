import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseModalService } from '@ems-gui/expense/util-web-infrastructure';
import {
  LayoutActions,
  selectAllExpenseTypesExceptMileage,
  selectAllFavoriteJobCodesPopulated,
  selectAllFavoriteSalesforceCasesPopulated,
  selectAllJobCodesWithGroupLabels,
  selectAllSalesforceCasesWithGroupLabels,
  selectCurrentDraftReceipt,
  selectDraftItemizationStatus,
  selectModalDismiss,
  selectTrashedExpenseWithSalesforceAndActivity,
  State,
  TrashActions,
  WebSubmitterFacade,
} from '@ems-gui/expense/util-web-ngrx';
import {
  Expense,
  ExpenseType,
  FavoriteSalesforceCase,
  JobCode,
  ModalInput,
  SalesforceCase,
} from '@ems-gui/shared/util-core';
import { ExpenseService } from '../../../../../../src/app/services/expense.service';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, delay, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ems-trash-detail-modal',
  templateUrl: './trash-detail-modal.component.html',
  styleUrls: ['./trash-detail-modal.component.scss'],
})
export class TrashDetailModalComponent implements OnInit, OnDestroy {
  private modalInputSubject: BehaviorSubject<ModalInput> = new BehaviorSubject({
    title: 'Expense:',
    label: '',
    labelClass: '',
    caption: '',
    status: '',
    subtitle: '',
    statusText: '',
    fieldsComplete: false,
    modalId: 'trash-detail',
    scrollToBottom: false,
  });
  modalInput$ = this.modalInputSubject.asObservable();
  isFraudulent = false;
  isCardVerified = false;
  isCompanyCc = false;
  isItemized;
  isTrashed = true;
  receiptAttached = false;
  expense$: Observable<Partial<Expense>>;
  expense;
  dismiss$: Observable<boolean>;
  jobCodes$: Observable<JobCode[]>;
  expenseTypes$: Observable<ExpenseType[]>;
  favoriteJobCodes$: Observable<any>;
  salesforceCases$: Observable<Partial<SalesforceCase>[]>;
  favoriteSalesforceCases$: Observable<FavoriteSalesforceCase[]>;
  itemizationStatus$: Observable<any>;
  receipt$: Observable<any>;
  unsubscribe$: Subject<void> = new Subject();

  constructor(
    public modalService: BaseModalService,
    public submitterState: WebSubmitterFacade,
    private expenseService: ExpenseService,
    private store$: Store<State>
  ) {
    this.expense$ = this.store$.pipe(
      select(selectTrashedExpenseWithSalesforceAndActivity)
    );
    this.dismiss$ = this.store$.pipe(select(selectModalDismiss));
    this.jobCodes$ = this.store$.pipe(select(selectAllJobCodesWithGroupLabels));
    this.expenseTypes$ = this.store$.pipe(
      select(selectAllExpenseTypesExceptMileage)
    );
    this.favoriteJobCodes$ = this.store$.pipe(
      select(selectAllFavoriteJobCodesPopulated)
    );
    this.salesforceCases$ = this.store$.pipe(
      select(selectAllSalesforceCasesWithGroupLabels)
    );
    this.favoriteSalesforceCases$ = this.store$.pipe(
      select(selectAllFavoriteSalesforceCasesPopulated)
    );
    this.itemizationStatus$ = this.store$.pipe(
      select(selectDraftItemizationStatus)
    );
    this.receipt$ = this.store$.pipe(select(selectCurrentDraftReceipt));
  }

  ngOnInit() {
    combineLatest([this.expense$])
      .pipe(
        delay(0),
        debounceTime(150),
        takeUntil(this.unsubscribe$),
        tap(([expense]) => {
          this.isCompanyCc = expense && expense.paymentType === 'company';
          this.isCardVerified = expense && expense.cardVerified;
          this.isItemized = expense && expense.parent;
          this.expense = expense;
          const lastUpdatedDate = expense.updatedAt;
          const lastUpdatedTime = formatDate(
            lastUpdatedDate,
            'shortTime',
            'en-US'
          );

          // setCaption returns a string that will be used in the expense form's caption.
          const setCaption = () => {
            const currentDate = new Date();
            const expenseDate = new Date(lastUpdatedDate);
            const fullExpenseDate = formatDate(
              lastUpdatedDate,
              'shortDate',
              'en-US'
            );
            const today = `${currentDate.getMonth()}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
            const shortExpenseDate = `${expenseDate.getMonth()}/${expenseDate.getDate()}/${expenseDate.getFullYear()}`;

            if (shortExpenseDate === today) {
              return `Last updated today at ${lastUpdatedTime}`;
            } else {
              return `Last updated on ${fullExpenseDate}`;
            }
          };

          const onModalInputLabel = ({ formStatus, formFraudulent }) => {
            if (formFraudulent === true) {
              return '[FRAUDULENT]';
            }
            if (formStatus === 'rejected') {
              return '[REJECTED]';
            }
            if (formStatus === 'awaiting verification') {
              return '[VERIFYING]';
            }
            return '[' + formStatus + ']';
          };

          const onModalInputLabelClass = ({ formStatus, formFraudulent }) => {
            if (formFraudulent === true) {
              return 'danger';
            }
            switch (formStatus) {
              case 'rejected':
                return 'danger';

              case 'awaiting verification':
                return 'warn';

              default:
                return '';
            }
          };

          this.isFraudulent = expense.fraudulent;

          const currentModal = this.modalInputSubject.getValue();
          this.modalInputSubject.next({
            ...currentModal,
            title: this.expenseService.getExpenseModalTitle(expense.paymentType),
            subtitle: `${expense.id}`,
            label: onModalInputLabel({
              formFraudulent: expense.fraudulent,
              formStatus: expense.status,
            }),
            labelClass: onModalInputLabelClass({
              formFraudulent: expense.fraudulent,
              formStatus: expense.status,
            }),
            caption: setCaption(),
          });
        })
      )
      .subscribe();

    this.receipt$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((receipt) => {
          if (receipt) {
            this.receiptAttached = true;
          }
        })
      )
      .subscribe();
  }

  onDismiss() {
    this.store$.dispatch(LayoutActions.dismissModal());
  }

  onOpenModal(modalId: string, expenseId: number) {
    this.submitterState.dispatch(
      TrashActions.selectTrashedExpenses({ expenses: [expenseId] })
    );
    this.modalService.open(modalId);
  }

  onRestore(expenseId: number) {
    const expenses = [expenseId];
    this.submitterState.dispatch(TrashActions.restoreExpenses({ expenses }));
    this.modalService.close('trash-detail');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
