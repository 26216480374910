/**
 * Sentry has several different jobs and plugins.  As
 */
import * as Sentry from "@sentry/angular-ivy";
import * as SentryBrowser from '@sentry/browser'
import { Environment } from '@libs/interfaces';
export function sentryInit({ sentry, version, environment }: Environment) {

  return Sentry.init({
   integrations: [
     new Sentry.Replay(),
     new Sentry.BrowserTracing()
   ],
   dsn: sentry.dsn,
   autoSessionTracking: true,
   release: version,
   tracesSampleRate: sentry.tracesSampleRate,
   replaysSessionSampleRate: sentry.replaysSessionSampleRate,
   replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
   environment
  });
}
