import { createSelector } from '@ngrx/store';
import * as fromExpenseType from '../reducers/expense-type.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardExpenseTypeState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.expenseType
);

export const {
  selectIds: selectExpenseTypeIds,
  selectEntities: selectExpenseTypeEntities,
  selectAll: selectAllExpenseTypes,
} = fromExpenseType.adapter.getSelectors(selectDashboardExpenseTypeState);

export const selectCurrentExpenseTypeId = createSelector(
  selectDashboardExpenseTypeState,
  fromExpenseType.getSelectedExpenseTypeId
);

export const selectCurrentExpenseType = createSelector(
  selectExpenseTypeEntities,
  selectCurrentExpenseTypeId,
  (expenseTypeEntities, expenseTypeId) => expenseTypeEntities[expenseTypeId]
);

export const selectMileageExpenseType = createSelector(
  selectAllExpenseTypes,
  (expenseTypes) => expenseTypes.find((t) => t.name === 'Mileage')
);

export const selectAllExpenseTypesExceptMileage = createSelector(
  selectAllExpenseTypes,
  (expenseTypes) => expenseTypes.filter((t) => t.name !== 'Mileage')
);

export const selectAllExpenseTypesForPersonalExpense = createSelector(
  selectAllExpenseTypes,
  (expenseTypes) => expenseTypes.filter((t) => t.displayForPersonalExpense)
);

export const selectAllExpenseTypesExceptFraudulent = createSelector(
  selectAllExpenseTypes,
  (expenseTypes) => expenseTypes.filter((t) => t.name !== 'Fraudulent Charge')
);
