import { createSelector } from '@ngrx/store';
import * as fromReceiptManager from '../reducers/receipt-manager.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardReceiptManagerState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.receiptManager
);

export const selectReceiptFiles = createSelector(
  selectDashboardReceiptManagerState,
  fromReceiptManager.receiptFiles
);

export const selectReceiptFilesLoading = createSelector(
  selectDashboardReceiptManagerState,
  fromReceiptManager.filesLoading
);

export const selectCurrentFileFormat = createSelector(
  selectDashboardReceiptManagerState,
  fromReceiptManager.format
);
