<ems-base-modal
  [config]="modalConfig"
  (dismiss)="onUndoItemizationDialogCancel()"
  class="dialog-modal"
>
  <ems-undo-itemization-dialog
    (cancel)="onUndoItemizationDialogCancel()"
    (confirm)="onConfirm()"
  >
  </ems-undo-itemization-dialog>
</ems-base-modal>
