import { Expense } from '@ems-gui/shared/util-core';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import * as fromApproval from '../reducers/approval.reducer';
import { DashboardState, selectDashboardState } from '../reducers/state';

export const selectDashboardApprovalState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.approval
);

export const {
  selectIds: selectApprovalIds,
  selectEntities: selectApprovalEntities,
  selectAll: selectAllApprovals,
} = fromApproval.adapter.getSelectors(selectDashboardApprovalState);

export const selectVerifyApprovalError = createSelector(
  selectDashboardApprovalState,
  fromApproval.getverifyExpenseError
);

export const selectUndoVerifyApprovalError = createSelector(
  selectDashboardApprovalState,
  fromApproval.getverifyExpenseError
);

export const selectApprovalSelectedId = createSelector(
  selectDashboardApprovalState,
  fromApproval.getSelectedApprovalId
);

export const selectApprovalUploadReceipt = createSelector(
  selectDashboardApprovalState,
  fromApproval.getSelectedCanUploadReceipt
);

export const selectApprovalSelected = createSelector<
  Dictionary<Expense>,
  unknown[],
  Partial<Expense>
>(
  [selectApprovalEntities, selectApprovalSelectedId],
  (reviewedEntities: Dictionary<Expense>, reviewedId: number) =>
    reviewedEntities[reviewedId]
);

export const selectApprovalSelectedIds = createSelector(
  selectDashboardApprovalState,
  fromApproval.getSelectedApprovalIds
);

export const selectApprovalStatus = createSelector(
  selectDashboardApprovalState,
  fromApproval.getApprovalStatus
);

// FIX: This will get all expenses with this status, not only those from the user as a approver but also from the user as submitter
export const selectAllPendingExpense = createSelector(
  selectAllApprovals,
  (expenses: Expense[]) => expenses.filter((e) => e.status === 'pending')
);

export const selectApprovalsTotalCount = createSelector(
  selectDashboardApprovalState,
  fromApproval.totalApprovalCount
);

export const selectPendingApprovalsTotalCount = createSelector(
  selectDashboardApprovalState,
  fromApproval.totalPendingApprovalsCount
);

export const selectReviewedApprovalsTotalCount = createSelector(
  selectDashboardApprovalState,
  fromApproval.totalReviewedApprovalsCount
);

export const selectPendingApprovalsLoading = createSelector(
  selectDashboardApprovalState,
  fromApproval.getPendingApprovalsLoading
);

export const selectReviewedApprovalsLoading = createSelector(
  selectDashboardApprovalState,
  fromApproval.getReviewedApprovalsLoading
);

export const selectApprovalSendToJobCodeId = createSelector(
  selectDashboardApprovalState,
  fromApproval.getSelectedJobCodeId
);

export const selectSendToAutoSavedNote = createSelector(
  selectDashboardApprovalState,
  fromApproval.getSendToNote
);

export const selectPendingApprovalsFilters = createSelector(
  selectDashboardApprovalState,
  fromApproval.getPendingApprovalsFilters
);

export const selectReviewedApprovalsFilters = createSelector(
  selectDashboardApprovalState,
  fromApproval.getReviewedApprovalsFilters
);

export const selectApprovalsFiltersState = createSelector(
  selectDashboardApprovalState,
  fromApproval.getApprovalsFiltersState
);

export const selectPendingPageSize = createSelector(
  selectDashboardApprovalState,
  fromApproval.getPendingPageSize
);

export const selectReviewedPageSize = createSelector(
  selectDashboardApprovalState,
  fromApproval.getReviewedPageSize
);
