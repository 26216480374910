import { ExpenseType } from '@ems-gui/shared/util-core';
import { createAction, props } from '@ngrx/store';

export const get = createAction('[Expense Type] get');

export const getComplete = createAction(
  '[Expense Type] getComplete',
  props<{ expenseType: ExpenseType[] }>()
);

export const getError = createAction(
  '[Expense Type] getError',
  props<{ error: any }>()
);

export const select = createAction(
  '[Expense Type] select',
  props<{ id: any }>()
);

export const resetExpenseType = createAction('[Expense Type] resetExpenseType');
